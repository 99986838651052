import { Row } from 'antd';
import OfflineCard from '../../../../../components/v2/card/offline-card';
import { OfflineConversationInterface } from '../../../../../shared/types/inbox.interface';

type AgentOfflineChatListProps = {
  offlineChatList: OfflineConversationInterface[];
  lastItemElementRef: React.Ref<any>;
};

const AgentOfflineChatList = ({
  offlineChatList,
  lastItemElementRef
}: AgentOfflineChatListProps) => {
  return (
    <Row className={`grid grid-cols-1 bg-white`}>
      {offlineChatList.map((chat, idx) => {
        if (offlineChatList.length === idx + 1) {
          return (
            <div key={chat.messageId} ref={lastItemElementRef}>
              <OfflineCard data={chat} />
            </div>
          );
        }

        return <OfflineCard key={chat.messageId} data={chat} />;
      })}
    </Row>
  );
};

export default AgentOfflineChatList;
