import React from 'react';
import { Flex, Form, FormInstance, Input, Select } from 'antd';
import phoneCountryCodes from '../../../../static/mock-data/phone-codes.json';
import { Paragraph } from '@squantumengine/horizon';

const { Option } = Select;

type PhoneInputProps = {
  form: FormInstance;
};

const PhoneInput: React.FC<PhoneInputProps> = (props: PhoneInputProps) => {
  const { form } = props;

  // Filter `option.label` match the user type `input`
  const filterOption = (input: string, option?: { key: string; value: string }) => {
    return (option?.key ?? '').toLowerCase().includes(input.toLowerCase());
  };

  return (
    <Flex align="bottom" className="mt-4" vertical>
      <Paragraph className="text-base font-semibold">
        No Telepon &nbsp;
        <span className="font-normal text-gray-500">(terhubung dengan WhatsApp)</span>
      </Paragraph>
      <Form
        form={form}
        name="phone-form"
        initialValues={{ phone_code: '+62' }}
        className="mt-2 flex flex-row">
        <Input.Group compact>
          <Form.Item name="phone_code" noStyle>
            <Select
              optionFilterProp="children"
              filterOption={filterOption}
              showSearch
              style={{ width: '30%' }}>
              {phoneCountryCodes.map((country) => (
                <Option key={country.code} value={country.dial_code}>
                  {country.code} ({country.dial_code})
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            rules={[
              { required: true, message: 'No Telepon wajib diisi.' },
              { max: 15, message: 'No Telepon maksimal 25 karakter.' }
            ]}
            name="phone_number">
            <Input
              type="number"
              className="rounded-l-none"
              style={{ width: '130%' }}
              placeholder="Masukkan No Telepon"
            />
          </Form.Item>
        </Input.Group>
      </Form>
    </Flex>
  );
};

export default PhoneInput;
