import { useQuery } from 'react-query';
import { useConversationStore } from '../store/new/conversation';
import { messageHistory } from '../config/message-history';
import { IChatMessage } from '../types/new/conversation';
import { Icon } from '@squantumengine/horizon';

const useLastChat = (conversationId: string, enabled: boolean = true) => {
  const { lastMessageMap, setLastMessageMap } = useConversationStore();

  const isConversationMessageExist = !!lastMessageMap[conversationId];

  const { refetch } = useQuery(['lastChat', conversationId], () => messageHistory(conversationId), {
    enabled: enabled && !isConversationMessageExist,
    onSuccess: (data) => {
      const convId = data.conversationId;
      if (data?.messageList[0]) setLastMessageMap(convId, data.messageList?.[0] as IChatMessage);
    }
  });

  const getLastMessageElement = () => {
    const lastMessage = lastMessageMap[conversationId];
    if (!lastMessage) return null;
    if (lastMessage?.attachment?.length! > 0) {
      return (
        <>
          <Icon name="paperclip" size="sm" />
          <span>{lastMessage?.attachment?.[0].name}</span>
        </>
      );
    }
    return lastMessage?.content;
  };

  return {
    conversationId: conversationId,
    message: lastMessageMap[conversationId],
    element: getLastMessageElement(),
    timestamp: lastMessageMap[conversationId]?.timestamp || 0,
    refetch
  };
};

export default useLastChat;
