import { HomeOutlined, LineChartOutlined, SettingOutlined, TeamOutlined } from '@ant-design/icons';
import { RoleEnum } from './role';
import { URLPathEnum, auditTrailDashboard, teamDashboard, userDashboard } from './routeConstructor';
import { Icon } from '@squantumengine/horizon';

const { ADMIN, SUPERVISOR, AGENT } = RoleEnum;

export enum SidebarItemEnum {
  HOME = 'home',
  TEAM = 'team',
  ANALYTICS = 'analytics',
  USER = 'user',
  AUDIT_TRAIL = 'audit-trail'
}

export const sideBarRoutes = [
  {
    key: SidebarItemEnum.HOME,
    label: 'Beranda',
    icon: <HomeOutlined />,
    url: `/${URLPathEnum.DASHBOARD}/${URLPathEnum.INBOXES}`,
    allow: [SUPERVISOR, AGENT]
  },
  {
    label: 'Tim',
    key: SidebarItemEnum.TEAM,
    icon: <TeamOutlined />,
    url: teamDashboard(),
    allow: [SUPERVISOR, ADMIN]
  },
  {
    label: 'Analisis',
    key: SidebarItemEnum.ANALYTICS,
    icon: <LineChartOutlined />,
    url: `/${URLPathEnum.DASHBOARD}/${URLPathEnum.ANALYTICS}`,
    allow: [SUPERVISOR]
  },
  {
    label: 'Riwayat Audit',
    key: SidebarItemEnum.AUDIT_TRAIL,
    icon: <TeamOutlined />,
    url: auditTrailDashboard(),
    allow: [SUPERVISOR, ADMIN]
  },
  {
    label: 'Personal',
    key: SidebarItemEnum.USER,
    icon: <SettingOutlined />,
    url: userDashboard(),
    allow: [SUPERVISOR, AGENT, ADMIN]
  }
];

export const sideBarRoutesV2 = [
  {
    id: SidebarItemEnum.HOME,
    text: 'Beranda',
    icon: <Icon name="home" />,
    url: `/${URLPathEnum.DASHBOARD}/${URLPathEnum.INBOXES}`,
    allow: [SUPERVISOR, AGENT]
  },
  {
    text: 'Tim',
    id: SidebarItemEnum.TEAM,
    icon: <Icon name="users" />,
    url: teamDashboard(),
    children: [
      {
        id: 'atur-team',
        text: 'Atur Tim',
        url: '/v2/dashboard/team/list'
      },
      {
        id: 'atur-agen',
        text: 'Atur Agen',
        url: '/v2/dashboard/team/users'
      }
    ],
    allow: [SUPERVISOR, ADMIN]
  },
  {
    text: 'Analisis',
    id: SidebarItemEnum.ANALYTICS,
    icon: <Icon name="chart-bar" />,
    url: `/${URLPathEnum.DASHBOARD}/${URLPathEnum.ANALYTICS}`,
    allow: [SUPERVISOR]
  },
  {
    text: 'Riwayat Audit',
    id: SidebarItemEnum.AUDIT_TRAIL,
    icon: <Icon name="users" />,
    iconName: 'users',
    url: auditTrailDashboard(true),
    allow: [SUPERVISOR, ADMIN]
  },
  {
    text: 'Personal',
    id: SidebarItemEnum.USER,
    icon: <Icon name="user" />,
    url: userDashboard(),
    allow: [SUPERVISOR, AGENT, ADMIN]
  }
];
