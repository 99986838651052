import BusinessUnitForm from '../../components/form/business-unit';
import InboxForm from '../../components/form/inbox';
import UserForm from '../../components/form/user';
import UserDetail from '../../components/form/user-detail';
import UserListForm from '../../components/form/user-list';
import { RoleEnum } from '../../static/role';
import { FormTypeEnum, FormActionEnum } from '../../static/team';

export const FormRenderer: (
  isOpenForm: boolean,
  formAction: FormActionEnum,
  formType: FormTypeEnum,
  accountRole: string
) => JSX.Element | null = (isOpenForm, formAction, formType, accountRole) => {
  const isOpenBUForm = isOpenForm && formType === FormTypeEnum.ACCOUNT;
  const isOpenInboxForm = isOpenForm && formType === FormTypeEnum.INBOX;
  const isOpenUserInfo = isOpenForm && formType === FormTypeEnum.USER_INFO;
  const isOpenUserForm =
    isOpenForm &&
    formType === FormTypeEnum.USER &&
    (formAction === FormActionEnum.CREATE || formAction === FormActionEnum.EDIT);
  const isOpenChooseUserForm =
    isOpenForm && formType === FormTypeEnum.USER && formAction === FormActionEnum.CHOOSE;

  const formRenderer = () => {
    if (accountRole === RoleEnum.SUPERVISOR) {
      switch (true) {
        case isOpenInboxForm:
          return <InboxForm />;
        case isOpenUserForm:
          return <UserForm />;
        case isOpenChooseUserForm:
          return <UserListForm />;
        case isOpenUserInfo:
          return <UserDetail />;
        default:
          return null;
      }
    } else {
      switch (true) {
        case isOpenBUForm:
          return <BusinessUnitForm />;
        case isOpenInboxForm:
          return <InboxForm />;
        case isOpenUserForm:
          return <UserForm />;
        case isOpenChooseUserForm:
          return <UserListForm />;
        case isOpenUserInfo:
          return <UserDetail />;
        default:
          return null;
      }
    }
  };

  return formRenderer();
};
