import { useToaster } from '@squantumengine/horizon';
import { WAITING_TIME_THRESHOLD } from '../../../../../static/dashboard';
import { stopVideoNotification } from '../../../../../utils/notification';
import { useChatListStore } from '../../../../../store/chat-list';
import { useConversationListStore } from '../../../../../store/conversation-list-state';
import IncomingNotificationItem from './incoming-notification-item';
import { ConversationInterface } from '../../../../../components/card/chat-message/chat-message.interface';

type Props = {
  handleActiveCall: (conversation: ConversationInterface) => Promise<void>;
};

export default function IncomingNotification({ handleActiveCall }: Props) {
  const { assignedConversationList } = useConversationListStore();
  const { clearUnreadMessages } = useChatListStore();

  const { contextHolder, open, close } = useToaster({
    position: 'top',
    timeout: WAITING_TIME_THRESHOLD
  });

  const _makeNotificationMessage = ({ name, isWarning }: { name: string; isWarning: boolean }) => {
    if (isWarning) {
      if (!name) return 'Pelanggan sudah menunggu. Mohon berikan respon segera.';
      return `${name || ''} sudah menunggu. Mohon berikan respon segera.`;
    }
    if (!name) return 'Pelanggan menunggu.';
    return `Pelanggan menunggu: ${name}`;
  };

  const handleCloseNotification = (notificationId: string) => {
    stopVideoNotification(notificationId);
    close(notificationId);
  };
  const handleClickNotification = (conversation: ConversationInterface) => {
    handleActiveCall(conversation);
    clearUnreadMessages(conversation.conversationId, conversation?.inboxId!);
    stopVideoNotification(conversation?.conversationId);
  };

  const showDefaultNotification = (conversation: ConversationInterface) => {
    open({
      id: conversation.conversationId,
      label: _makeNotificationMessage({ name: conversation?.name || '', isWarning: false }),
      buttonLabel: 'Buka Sesi',
      onActionClick: () => handleClickNotification(conversation)
    });
  };

  const showWarningNotification = (conversation: ConversationInterface) => {
    open({
      id: `${conversation.conversationId}-warning`,
      label: _makeNotificationMessage({ name: conversation?.name || '', isWarning: true }),
      buttonLabel: 'Buka Sesi',
      onActionClick: () => handleClickNotification(conversation)
    });
  };

  return (
    <div>
      {/* Temporary styling. will update after banner notification improvement deployed */}
      <div className="banner-notification [&>div>div>button]:font-bold [&>div>div>button]:text-sky-400 [&>div>div>p]:flex-1">
        {contextHolder}
      </div>
      {assignedConversationList.map((item) => (
        <IncomingNotificationItem
          key={item.id}
          conversation={item}
          enabled={!item.jawabAt}
          handleShowDefaultNotification={showDefaultNotification}
          handleShowWarningNotification={showWarningNotification}
          handleCloseNotification={handleCloseNotification}
        />
      ))}
    </div>
  );
}
