import React, { useEffect, useRef } from 'react';

interface WaveVisualizerProps {
  audioStream: MediaStream;
  className?: string;
  outputSound?: boolean;
}

const WaveVisualizer = ({ audioStream, className, outputSound = false }: WaveVisualizerProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const audioContext = useRef<AudioContext | null>(null);
  const analyserNode = useRef<AnalyserNode | null>(null);
  const sourceNode = useRef<MediaStreamAudioSourceNode | null>(null);
  const requestRef = useRef<number | null>(null);

  const sensitivityThreshold = 10; // Adjust this value to control sensitivity
  const numBars = 16; // Number of bars to display
  const barWidth = 10; // Width of each bar

  useEffect(() => {
    // @ts-ignore
    audioContext.current = new (window.AudioContext || window.webkitAudioContext)();
    analyserNode.current = audioContext.current.createAnalyser();
    analyserNode.current.minDecibels = -90; // Adjust this value if necessary
    analyserNode.current.maxDecibels = -10; // Adjust this value if necessary
    sourceNode.current = audioContext.current.createMediaStreamSource(audioStream);
    sourceNode.current.connect(analyserNode.current);
    outputSound && analyserNode.current.connect(audioContext.current.destination);
    startVisualization();

    return () => {
      stopVisualization();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioStream]);

  const drawWaveform = () => {
    const canvas = canvasRef.current;
    const context = canvas?.getContext('2d');
    const bufferLength = analyserNode.current?.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength || 0);

    if (context) {
      analyserNode.current?.getByteFrequencyData(dataArray);

      context.clearRect(0, 0, canvas!.width, canvas!.height);
      context.strokeStyle = 'rgb(255, 255, 255)';
      context.fillStyle = 'rgb(255, 255, 255)';

      const maxBarHeight = (canvas!.height - barWidth) / 4; // Adjust the denominator to increase/decrease the bar height
      const barSpacing = 14;
      const centerX = canvas!.width / 2;
      const heightScaleFactor = 5; // Adjust this value to increase/decrease the bar height
      const startX = centerX - (numBars * (barWidth + barSpacing)) / 2; // Calculate the starting x-coordinate for centering

      for (let i = 0; i < numBars; i++) {
        const barIndex = i < Math.ceil(numBars / 2) ? i : numBars - i - 1;
        const amplitude = dataArray[barIndex] / 255;
        const barHeight = amplitude * maxBarHeight * heightScaleFactor;

        if (barHeight > sensitivityThreshold) {
          const x = startX + i * (barWidth + barSpacing);
          const y = (canvas!.height - barHeight) / 2;
          context.beginPath();
          context.roundRect(x, y, barWidth, barHeight, [0, 0, 5, 5]); // Bottom bar
          context.fill();
          context.stroke();
          context.beginPath();
          context.roundRect(x, canvas!.height - y - barWidth, barWidth, -barHeight, [0, 0, 5, 5]); // Mirrored bar on the top
          context.fill();
          context.stroke();
        }
      }
    }

    requestRef.current = requestAnimationFrame(drawWaveform);
  };

  const startVisualization = () => {
    if (!requestRef.current) {
      drawWaveform();
    }
  };

  const stopVisualization = () => {
    if (requestRef.current) {
      cancelAnimationFrame(requestRef.current);
      requestRef.current = null;
    }
  };
  return <canvas className={className} ref={canvasRef} />;
};

export default WaveVisualizer;
