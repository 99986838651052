import { ConfigProvider, Form, Spin, message } from 'antd';
import TopicAgent from '../../form/inbox/topic-agent';
import { getUserJSON } from '../../../utils/user-json';
import { useChatListStore } from '../../../store/chat-list';
import { WorkspaceSummary } from '../../../api/param.interface';
import { updateWorkspaceSummary } from '../../../api/workspace';
import { useEffect, useState } from 'react';
import { getIconUrl } from '../../../utils/getIconUrl';
import { useWorkspaceSummary } from '../../../api/hooks/workspace/useWorkspaceSummary';
import { useUserStatusStore } from '../../../store/user-state';
import { RoleEnum } from '../../../static/role';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { ChatFooterSummaryEnum } from '../../../static/tab-list';

export default function Summary() {
  const [selectedTopic, setSelectedTopic] = useState<string>('');
  const [selectedNotes, setSelectedNotes] = useState<string>('');
  const [selectedMenus, setSelectedMenus] = useState<ChatFooterSummaryEnum>();
  const { userRole } = useUserStatusStore();
  const [summaryForm] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const { selectedCall, setIsReadyToClose } = useChatListStore();
  const { account } = getUserJSON();
  const conversationId = selectedCall?.conversationId;
  const { data, isLoading } = useWorkspaceSummary(conversationId as string);
  const isAgent = userRole === RoleEnum.AGENT;
  const accountId = account?.accountId;
  const diabledNextButton = !selectedTopic || selectedTopic === '-' || selectedTopic === null;
  const msgCounter = selectedNotes!.length;
  const maxCharacter = 1000;
  const isLimitCharacter = msgCounter > maxCharacter;

  const onFinish = (values: any) => {
    const { background, solution, topic, notes, request } = values;
    topic && setSelectedTopic(topic);
    topic && setSelectedMenus(ChatFooterSummaryEnum.TOPIC);
    selectedTopic &&
      selectedMenus === ChatFooterSummaryEnum.NOTES &&
      setSelectedMenus(ChatFooterSummaryEnum.DONE);

    if (accountId && conversationId) {
      const payload: WorkspaceSummary = {
        topic: topic || selectedTopic || '',
        background: background || '-',
        specificRequest: request || '-',
        solution: solution || '-',
        note: notes || selectedNotes || '-'
      };
      try {
        selectedTopic && setIsReadyToClose(true);
        updateWorkspaceSummary(accountId, conversationId, payload);
      } catch {
        messageApi.open({
          type: 'error',
          content: 'Update data gagal!'
        });
      }

      const localStorageSummaryString = JSON.stringify({
        topic,
        background,
        specificRequest: request,
        solution,
        note: notes
      });
      localStorage.setItem('customerDetails', localStorageSummaryString);
    }
  };

  const handleFormChange = (values: any) => {
    values.topic && setSelectedTopic(values?.topic);
    values.notes && setSelectedNotes(values.notes);
  };

  useEffect(() => {
    const existedTopic = data?.topic;
    const existedNote = data?.note;
    const isDataExisted = existedTopic && existedTopic !== '-';
    setSelectedMenus(isDataExisted ? ChatFooterSummaryEnum.DONE : ChatFooterSummaryEnum.TOPIC);
    if (isDataExisted) {
      summaryForm.setFieldsValue({
        topic: existedTopic,
        notes: existedNote
      });
      setSelectedTopic(existedTopic || '');
      setIsReadyToClose(true);
    } else {
      summaryForm.resetFields();
      setSelectedNotes('');
      setIsReadyToClose(false);
    }
    // eslint-disable-next-line
  }, [data, summaryForm, selectedCall]);

  return (
    <div className="w-full">
      {isLoading && (
        <div className="flex justify-center pt-12">
          <Spin />
        </div>
      )}
      {contextHolder}
      <Form
        id="customer-summary-form"
        layout="vertical"
        initialValues={{ size: 'default' }}
        onValuesChange={handleFormChange}
        onFinish={onFinish}
        form={summaryForm}
        disabled={!isAgent}
        className={`customer-form w-full ${isLoading && 'hidden'}`}>
        <ConfigProvider
          theme={{
            token: { colorBgContainer: 'transparent' }
          }}>
          {selectedMenus === ChatFooterSummaryEnum.TOPIC ? (
            <div className="flex justify-center gap-4">
              <div className="w-full">
                <TopicAgent form={summaryForm} isZoom={true} />
                <p className="text-orange-danger">
                  {!summaryForm?.getFieldValue('topic') && '* Wajib Diisi'}
                </p>
              </div>
              <span className="mt-10 flex h-full items-center">
                <button
                  disabled={diabledNextButton}
                  className={`mt-2 flex aspect-square h-8 w-8 cursor-pointer items-center justify-center rounded-full border-none ${
                    diabledNextButton ? 'cursor-not-allowed bg-gray-400' : 'bg-bold-green'
                  }`}
                  onClick={() => setSelectedMenus(ChatFooterSummaryEnum.NOTES)}
                  type="submit">
                  <img
                    src={getIconUrl('paper-plane.svg')}
                    className="-translate-x-0.5"
                    alt="PaperPlane"
                  />
                </button>
              </span>
            </div>
          ) : selectedMenus === ChatFooterSummaryEnum.NOTES ? (
            <div>
              <button
                className="flex h-10 items-center justify-start gap-2 border-none bg-transparent p-0 text-left text-black"
                onClick={() => setSelectedMenus(ChatFooterSummaryEnum.TOPIC)}>
                <ArrowLeftOutlined className="text-black" />
                Catatan ({selectedTopic})
              </button>
              <div
                className={`flex h-max flex-1 flex-col items-center overflow-hidden rounded-xl border border-solid ${
                  isLimitCharacter ? 'border-red-700' : 'border-border-gray'
                } bg-white`}>
                <textarea
                  style={{ resize: 'none' }}
                  className="mb-11 h-16 w-full overflow-y-scroll border-none bg-white px-6 py-4 font-['Inter'] outline-none focus:outline-none"
                  id="notes"
                  value={selectedNotes}
                  onChange={(e) => setSelectedNotes(e.target.value)}
                  placeholder="Tulis permintaan / pertanyaan / keluhan pelanggan"
                />
                {msgCounter > maxCharacter - 50 && (
                  <span
                    className={`absolute bottom-8 right-16 text-xs ${
                      isLimitCharacter && 'text-red-700'
                    }`}>
                    {msgCounter}/{maxCharacter}
                  </span>
                )}
                <button
                  className={`absolute bottom-6 right-6  flex aspect-square h-8 w-8 cursor-pointer items-center justify-center rounded-full border-none  ${
                    isLimitCharacter ? 'cursor-not-allowed bg-gray-400' : 'bg-bold-green'
                  }`}
                  type="submit"
                  disabled={isLimitCharacter}>
                  <img
                    src={getIconUrl('paper-plane.svg')}
                    className="-translate-x-0.5"
                    alt="PaperPlane"
                  />
                </button>
              </div>
            </div>
          ) : (
            <div className="flex flex-col items-center gap-2 pt-4">
              <img src={getIconUrl('check-splash.svg')} alt="check-splash" className="w-16" />
              <p className="m-0 p-0 text-2xl font-semibold">Data berhasil disimpan</p>
              <p className="m-0 p-0 text-base">Terima kasih telah melengkapi data pelanggan</p>
            </div>
          )}
        </ConfigProvider>
      </Form>
    </div>
  );
}
