import React from 'react';
import { Result } from '../components/commons/Result';
import { useNavigate, useParams } from 'react-router-dom';

// Height fit content without navbar height
const styles = { height: 'calc(100vh)' };

const PageError = () => {
  const { status } = useParams();
  const navigate = useNavigate();
  const handleGoBack = () => navigate(-1);

  return (
    <div className="container mx-auto">
      <div className="flex flex-col items-center justify-center space-y-4" style={styles}>
        <Result status={status || ''} okText="Kembali" onOk={handleGoBack} />
      </div>
    </div>
  );
};

export default PageError;
