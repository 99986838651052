import { useState, useEffect, useRef } from 'react';
import { Icon } from '@squantumengine/horizon';

type Props = {
  options?: string[];
  placeholder: string;
  value: string;
  onChange: React.Dispatch<React.SetStateAction<string>>;
};

function Dropdown({ options, placeholder, value, onChange }: Readonly<Props>) {
  const [summaryState, setSummaryState] = useState<{
    open: boolean;
    search: string;
  }>({
    open: false,
    search: ''
  });

  const [direction, setDirection] = useState<'top' | 'bottom'>('bottom');

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (summaryState.open) {
      const dropdownRect = dropdownRef.current?.getBoundingClientRect();
      const viewportHeight = window.innerHeight;

      if (dropdownRect) {
        const spaceBelow = viewportHeight - dropdownRect.bottom;
        const spaceAbove = dropdownRect.top;

        setDirection(spaceBelow < 240 && spaceAbove > spaceBelow ? 'top' : 'bottom');
      }
    }
  }, [summaryState.open]);

  const filteredOptions = options?.filter((option) =>
    option.toLowerCase().includes(summaryState.search.toLowerCase())
  );

  return (
    <>
      <div className="relative w-full" ref={dropdownRef}>
        <button
          className="h-fit w-full cursor-default items-center justify-center rounded-lg border border-solid border-[#BAC3D1] bg-white p-1 pl-3 text-base leading-6 hover:border-gray-400 focus:border-blue-600"
          onMouseDown={() =>
            setSummaryState((prev) => ({
              ...prev,
              open: !prev.open
            }))
          }>
          <div className="flex w-full justify-between text-justify">
            <span
              className={`flex items-center justify-center truncate text-sm ${
                value ? 'text-black' : 'text-neutral-300'
              }`}>
              {value ? value : placeholder}
            </span>
            <div className="flex h-10 w-10 items-center justify-center">
              <Icon
                name={summaryState.open ? 'chevron-small-up' : 'chevron-small-down'}
                size="md"
                color={value ? 'black' : '#BAC3D1'}
              />
            </div>
          </div>
        </button>

        <div
          className={`absolute z-50 w-full overflow-auto rounded-lg border border-solid border-[#BAC3D1] bg-white ${
            summaryState.open ? 'block' : 'hidden'
          } ${direction === 'bottom' ? 'mt-2' : 'mb-2'} h-[17rem] ${
            direction === 'bottom' ? '' : 'bottom-full'
          }`}>
          <div className="sticky top-0 z-10 bg-white p-3">
            <div className="relative flex h-fit gap-1 rounded-lg border border-solid border-[#BAC3D1] p-3">
              <Icon name="search" size="md" />
              <input
                placeholder="Cari Topik"
                className="w-full border-0 text-sm focus:outline-none"
                onChange={(e) => setSummaryState({ ...summaryState, search: e.target.value })}
                value={summaryState.search}
              />
            </div>
          </div>
          <div className="overflow-auto">
            {filteredOptions &&
              filteredOptions.map((option, index) => (
                <div
                  key={`${option}_${Math.random()}`}
                  className={`${
                    index === filteredOptions.length - 1
                      ? ''
                      : 'border-0 border-b border-solid border-[#BAC3D1]'
                  } cursor-default`}>
                  <div
                    className="rounded-lg p-3 text-sm leading-6 hover:bg-emerald-50"
                    onMouseDown={() => {
                      onChange(option);
                      setSummaryState({
                        open: false,
                        search: ''
                      });
                    }}>
                    {option}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Dropdown;
