import {
  CloseOutlined,
  LeftCircleOutlined,
  QuestionCircleOutlined,
  RightCircleOutlined
} from '@ant-design/icons';
import { Pagination, PaginationProps, Tooltip } from 'antd';
import { useState } from 'react';
import { getIconUrl } from '../../../utils/getIconUrl';
import { Token } from '@squantumengine/horizon';

const { COLORS } = Token;

interface InboxTooltipInterface {
  title: string;
  images: Array<string>;
}

function InboxTooltip({ title, images }: InboxTooltipInterface) {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [open, setOpen] = useState<boolean>(false);

  const onChange: PaginationProps['onChange'] = (page) => {
    setCurrentPage(page);
  };

  const itemRender: PaginationProps['itemRender'] = (_, type, originalElement) => {
    if (type === 'next') {
      return (
        <RightCircleOutlined
          className={`${currentPage === images.length && 'opacity-20'} text-[1.5rem] text-white`}
        />
      );
    }

    if (type === 'prev') {
      return (
        <LeftCircleOutlined
          className={`${currentPage === 1 && 'opacity-20'} text-[1.5rem] text-white`}
        />
      );
    }

    return originalElement;
  };

  return (
    <Tooltip
      rootClassName="custom-tooltip"
      placement="top"
      defaultOpen={false}
      color={`${COLORS.neutral[950]}`}
      open={open}
      onOpenChange={(open) => setOpen(open)}
      overlayInnerStyle={{ width: '20rem', fontSize: 16 }}
      title={
        <div className="flex flex-col items-center justify-center gap-4 rounded-xl p-4">
          <div className="flex w-full flex-row items-start justify-between gap-2">
            <p className="m-0 p-0 text-left">{title}</p>
            <CloseOutlined
              onClick={() => {
                setOpen(false);
              }}
              className="mt-[0.1rem] cursor-pointer text-xl transition-all hover:scale-125"
            />
          </div>
          <img
            className="max-h-full max-w-full"
            src={getIconUrl(images[currentPage - 1])}
            alt="tooltip"
          />
          <Pagination
            rootClassName="custom-tooltip"
            showTitle={false}
            pageSize={1}
            hideOnSinglePage={true}
            className="self-end"
            current={currentPage}
            onChange={onChange}
            total={images.length}
            itemRender={itemRender}
          />
        </div>
      }>
      <QuestionCircleOutlined className="ml-2 text-green-emerald-light" />
    </Tooltip>
  );
}

export default InboxTooltip;
