import { useTimer } from '../../../hooks/useTimer';
import { DurationToString } from '../../../utils/timestamp';

type QueueListItemProps = {
  customerName?: string;
  startTimer: number;
};

export default function QueueItem({ customerName, startTimer }: QueueListItemProps) {
  const { second } = useTimer(Math.round((Date.now() - startTimer) / 1000));
  return (
    <div className="flex flex-row items-center justify-between">
      <div className="truncate text-base font-semibold">{customerName}</div>
      <div className="font-semibold text-grey-100">{DurationToString(second)}</div>
    </div>
  );
}
