import {
  Button,
  Label,
  Paragraph,
  TableColumnTypeDef,
  TableRowTypeModifier,
  Token
} from '@squantumengine/horizon';
import { UserResponse } from '../../../components/card/user/user.interface';
import { RoleEnum } from '../../../static/role';
import { InvTeamStatusEnum } from './users.interface';
import { UseMutateAsyncFunction } from 'react-query';
import { requestResetPasswordNATIVE } from '../../../api/native-login';

export type ModifiedTeamType = TableRowTypeModifier<UserResponse>;

const { TABLE_HORIZONTAL_ALIGN, TABLE_VERTICAL_ALIGN } = Token;

function extractAndDisplayInboxNamesAsString(data: Array<any>) {
  let inboxNames: Array<string> = [];
  data.forEach((item) => {
    if (data.length > 0) {
      inboxNames.push(item.inboxName);
    }
  });

  return inboxNames.join(', ');
}

export const _renderTeam = (val: Array<any>) => (
  <>
    {val?.length > 0 ? (
      <Paragraph className="text-black">{extractAndDisplayInboxNamesAsString(val)}</Paragraph>
    ) : (
      <Paragraph className="text-gray-400">Belum ditugaskan</Paragraph>
    )}
  </>
);

export const _renderBUName = (val: any) => (
  <Paragraph className="text-gray-500">{val?.accountName || '-'}</Paragraph>
);

export const _renderAgentName = (val: string, index: number) => {
  const nameMap: any = {
    [RoleEnum.AGENT]: 'Agen',
    [RoleEnum.SUPERVISOR]: 'Supervisor',
    [RoleEnum.ADMIN]: 'Admin'
  };

  return (
    <Paragraph key={index} className="text-gray-500">
      {nameMap[val]}
    </Paragraph>
  );
};

export const _renderStatus = (val: string) => {
  const tagColorMap = {
    [InvTeamStatusEnum.INVITED]: 'warning',
    [InvTeamStatusEnum.ACTIVE]: 'success',
    [InvTeamStatusEnum.NON_ACTIVE]: 'default',
    [InvTeamStatusEnum.EXPIRED]: 'danger',
    [InvTeamStatusEnum.BLOCKED]: 'danger',
    [InvTeamStatusEnum.NULL]: 'info'
  };

  const nameMap = {
    [InvTeamStatusEnum.INVITED]: 'Undangan Terkirim',
    [InvTeamStatusEnum.ACTIVE]: 'Aktif',
    [InvTeamStatusEnum.NON_ACTIVE]: 'Non Aktif',
    [InvTeamStatusEnum.EXPIRED]: 'Undangan Kadaluarsa',
    [InvTeamStatusEnum.BLOCKED]: 'Terblokir Sementara',
    [InvTeamStatusEnum.NULL]: 'Tidak Terdefinisi'
  };

  return (
    <Label
      type={`${(tagColorMap[val] as 'success') || 'danger' || 'default' || 'warning' || 'info'}`}
      border
      label={nameMap[val]}
    />
  );
};

export const invUserSQEIDColumns = (
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setSelectedData: React.Dispatch<React.SetStateAction<UserResponse>>,
  isAdmin: boolean
): TableColumnTypeDef<ModifiedTeamType> => {
  return [
    {
      header: 'Nama User',
      accessorKey: 'firstName',
      cell: ({ cell, row }) => {
        return <Paragraph className=" text-gray-800">{row?.original?.firstName}</Paragraph>;
      },
      width: 200,
      horizontalAlign: TABLE_HORIZONTAL_ALIGN.left,
      headerVerticalAlign: TABLE_VERTICAL_ALIGN.middle
    },
    {
      header: 'Tim',
      accessorKey: 'inboxes',
      cell: ({ cell, row }) => {
        return _renderTeam(row?.original?.inboxes);
      },
      width: 200,
      horizontalAlign: TABLE_HORIZONTAL_ALIGN.left,
      headerVerticalAlign: TABLE_VERTICAL_ALIGN.middle,
      isIgnoreLoading: true,
      customSkeletonWidth: 20,
      enableSorting: false
    },
    ...(isAdmin
      ? [
          {
            header: 'Nama BU',
            cell: ({ cell, row }: any) => {
              return _renderBUName(row?.original?.account);
            },
            width: 200,
            horizontalAlign: TABLE_HORIZONTAL_ALIGN.left,
            headerVerticalAlign: TABLE_VERTICAL_ALIGN.middle,
            isIgnoreLoading: true,
            customSkeletonWidth: 20,
            enableSorting: false
          },
          {
            header: 'Jabatan',
            accessorKey: 'role',
            cell: ({ cell, row }: any) => {
              return _renderAgentName(row?.original?.role, row?.index);
            },
            width: 200,
            horizontalAlign: TABLE_HORIZONTAL_ALIGN.left,
            headerVerticalAlign: TABLE_VERTICAL_ALIGN.middle,
            isIgnoreLoading: true,
            customSkeletonWidth: 20,
            enableSorting: false
          }
        ]
      : []),
    {
      header: 'Email',
      accessorKey: 'email',
      cell: ({ cell, row }) => {
        return <Paragraph className=" text-gray-800">{row?.original?.email}</Paragraph>;
      },
      width: 200,
      horizontalAlign: TABLE_HORIZONTAL_ALIGN.left,
      headerVerticalAlign: TABLE_VERTICAL_ALIGN.middle,
      isIgnoreLoading: true,
      customSkeletonWidth: 20,
      enableSorting: false
    },
    {
      header: 'Status',
      accessorKey: 'status',
      cell: ({ cell, row }) => {
        return _renderStatus(row?.original?.status);
      },
      width: 200,
      horizontalAlign: TABLE_HORIZONTAL_ALIGN.left,
      headerVerticalAlign: TABLE_VERTICAL_ALIGN.middle,
      isIgnoreLoading: true,
      customSkeletonWidth: 20,
      enableSorting: false
    },
    {
      header: 'Action',
      accessorKey: 'action',
      cell: ({ cell, row }) => {
        return (
          <Button
            variant="text"
            onClick={() => {
              setSelectedData(row?.original);
              setIsModalOpen(true);
            }}
            children={<Paragraph>Edit</Paragraph>}
          />
        );
      },
      width: 200,
      horizontalAlign: TABLE_HORIZONTAL_ALIGN.left,
      headerVerticalAlign: TABLE_VERTICAL_ALIGN.middle,
      isIgnoreLoading: true,
      customSkeletonWidth: 20,
      enableSorting: false
    }
  ];
};

export const invUserNATIVEColumns = (
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setSelectedData: React.Dispatch<React.SetStateAction<UserResponse>>,
  isAdmin: boolean,
  unblockUser: UseMutateAsyncFunction<any[], unknown, string, unknown>,
  openNotification: any
): TableColumnTypeDef<ModifiedTeamType> => {
  return [
    {
      header: 'Nama User',
      accessorKey: 'firstName',
      cell: ({ cell, row }) => {
        return <Paragraph className=" text-gray-800">{row?.original?.firstName}</Paragraph>;
      },
      width: 200,
      horizontalAlign: TABLE_HORIZONTAL_ALIGN.left,
      headerVerticalAlign: TABLE_VERTICAL_ALIGN.middle
    },
    {
      header: 'Tim',
      accessorKey: 'inboxes',
      cell: ({ cell, row }) => {
        return _renderTeam(row?.original?.inboxes);
      },
      width: 200,
      horizontalAlign: TABLE_HORIZONTAL_ALIGN.left,
      headerVerticalAlign: TABLE_VERTICAL_ALIGN.middle,
      isIgnoreLoading: true,
      customSkeletonWidth: 20,
      enableSorting: false
    },
    ...(isAdmin
      ? [
          {
            header: 'Nama BU',
            cell: ({ cell, row }: any) => {
              return _renderBUName(row?.original?.account);
            },
            width: 200,
            horizontalAlign: TABLE_HORIZONTAL_ALIGN.left,
            headerVerticalAlign: TABLE_VERTICAL_ALIGN.middle,
            isIgnoreLoading: true,
            customSkeletonWidth: 20,
            enableSorting: false
          },
          {
            header: 'Jabatan',
            accessorKey: 'role',
            cell: ({ cell, row }: any) => {
              return _renderAgentName(row?.original?.role, row?.index);
            },
            width: 200,
            horizontalAlign: TABLE_HORIZONTAL_ALIGN.left,
            headerVerticalAlign: TABLE_VERTICAL_ALIGN.middle,
            isIgnoreLoading: true,
            customSkeletonWidth: 20,
            enableSorting: false
          }
        ]
      : []),
    {
      header: 'Email/Username',
      accessorKey: 'email',
      cell: ({ cell, row }) => {
        return <Paragraph className=" text-gray-800">{row?.original?.email}</Paragraph>;
      },
      width: 200,
      horizontalAlign: TABLE_HORIZONTAL_ALIGN.left,
      headerVerticalAlign: TABLE_VERTICAL_ALIGN.middle,
      isIgnoreLoading: true,
      customSkeletonWidth: 20,
      enableSorting: false
    },
    {
      header: 'Maksimal Chat',
      accessorKey: 'capacity',
      cell: ({ cell, row }) => {
        return (
          <Paragraph className=" text-gray-800">
            {row?.original?.capacity?.maxChatCapacity || '-'}
          </Paragraph>
        );
      },
      width: 200,
      horizontalAlign: TABLE_HORIZONTAL_ALIGN.left,
      headerVerticalAlign: TABLE_VERTICAL_ALIGN.middle,
      isIgnoreLoading: true,
      customSkeletonWidth: 20,
      enableSorting: false
    },
    {
      header: 'Status',
      accessorKey: 'status',
      cell: ({ cell, row }) => {
        return _renderStatus(row?.original?.status);
      },
      width: 200,
      horizontalAlign: TABLE_HORIZONTAL_ALIGN.left,
      headerVerticalAlign: TABLE_VERTICAL_ALIGN.middle,
      isIgnoreLoading: true,
      customSkeletonWidth: 20,
      enableSorting: false
    },
    {
      header: 'Action',
      accessorKey: 'action',
      cell: ({ cell, row }) => {
        const { role, capacity, employeeId, status, email } = row?.original || {};
        const hasCapacity = Object.keys(capacity ?? {})?.length > 0;
        const hasEmployeeId = !!employeeId;

        const isAgent = role === RoleEnum.AGENT;
        const isSupervisor = role === RoleEnum.SUPERVISOR;

        let statusLabel = '';

        if (isAgent) {
          statusLabel = hasCapacity ? 'Edit' : 'Lengkapi';
        } else if (isSupervisor) {
          statusLabel = hasEmployeeId ? 'Edit' : 'Lengkapi';
        }

        if (status === InvTeamStatusEnum.BLOCKED) {
          statusLabel = 'Unblock';
        } else if (status === InvTeamStatusEnum.EXPIRED) {
          statusLabel = 'Kirim Ulang';
        }

        return (
          <Button
            variant="text"
            onClick={async () => {
              if (status === InvTeamStatusEnum.BLOCKED) {
                await unblockUser(email).catch((error) => {
                  // eslint-disable-next-line no-console
                  console.log(error);
                });
              } else if (status === InvTeamStatusEnum.EXPIRED) {
                try {
                  await requestResetPasswordNATIVE(email, 'email');
                  openNotification({
                    label: 'Resend link sudah dikirim ulang',
                    variant: 'primary'
                  });
                } catch (_) {
                  openNotification({
                    label: 'Gagal mengirim ulang link',
                    variant: 'secondary'
                  });
                }
              } else {
                setSelectedData(row?.original);
                setIsModalOpen(true);
              }
            }}
            children={<Paragraph>{statusLabel}</Paragraph>}
          />
        );
      },
      width: 200,
      horizontalAlign: TABLE_HORIZONTAL_ALIGN.left,
      headerVerticalAlign: TABLE_VERTICAL_ALIGN.middle,
      isIgnoreLoading: true,
      customSkeletonWidth: 20,
      enableSorting: false
    }
  ];
};
