import { createContext, useContext, useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from './useLocalStorage';

import { RoleEnum } from '../static/role';
import { useUserStatusStore } from '../store/user-state';
import { useChatListStore } from '../store/chat-list';
import { useCallStore } from '../store/call-state';
import { useInboxDetailStore } from '../store/inbox-state';
import { CloseWebSocket } from '../utils/webSocket';
import { logoutNATIVE } from '../api';
import { useConversationListStore } from '../store/conversation-list-state';
import { AuthNATIVEContextProps } from '../shared/types/user.interface';
import { fastLoginInterface } from '../shared/types/fasLogin.iterface';
import { deleteCookie, setCookie } from '../utils/cookie-management';
import { CookieNameEnum } from '../static/cookie-management';
import { local } from '../utils/storage';
import { LocalStorageKey } from '../static/local-storage';
import { LoginRes } from '../shared/types/login.interface';
import { useAuthStore } from '../store/new/auth';
import { setAuthorizationHeader } from '../config/axios';
import { GetProfilePictureUrl } from '../api/user';
import { generateAvatarImage } from '../utils/fullNameParse';
import { clearAuthLocalStorage } from '../utils/local-storage';

export const AuthNativeContext = createContext<AuthNATIVEContextProps>({
  user: {
    id: '',
    agentId: '',
    username: '',
    email: '',
    name: '',
    phone: '',
    role: '',
    accessToken: '',
    image: ''
  },
  accessToken: '',
  login: () => {},
  logout: () => {}
});

export const AuthNativeProvider = ({ children }: { children: any }) => {
  const [, setUserLocal] = useLocalStorage(LocalStorageKey.USER, {
    id: '',
    agentId: '',
    username: '',
    email: '',
    name: '',
    phone: '',
    role: '',
    accessToken: '',
    image: '',
    account: {
      accountId: '',
      accountName: ''
    }
  });
  const [fastLogin, setFastLogin] = useLocalStorage(
    LocalStorageKey.FAST_LOGIN,
    [] as fastLoginInterface[]
  );
  const [accessToken, setAccessToken] = useLocalStorage(LocalStorageKey.ACCESS_TOKEN, '');
  const navigate = useNavigate();
  const { setUserRole, setName, setImageUrl } = useUserStatusStore();
  const { clearChatListStore, setOfflineChatList, setSelectedCall, setSelectedOfflineChat } =
    useChatListStore();
  const { setMeetingRoomId, resetAttentionChat } = useCallStore();
  const { setInboxList, setInboxId } = useInboxDetailStore();
  const { clearConversationList } = useConversationListStore();
  const {
    user,
    appendPrevUserList,
    setUser,
    setUserPhoto,
    setAccount,
    setIsPasswordExpired,
    setShouldUpdatePassword,
    setDaysBeforeExpired,
    setMaxExpiredDays,
    reset
  } = useAuthStore();

  function updateFastLogin(data: fastLoginInterface): void {
    if (fastLogin) {
      // Find the index of the object with matching id in the fastLogin array
      const index = fastLogin.findIndex((item) => item.agentId === data.agentId);
      if (index !== -1) {
        // Replace the old data with new data at the found index
        let newArr = fastLogin;
        newArr[index] = data;
        setFastLogin(newArr);
      } else if (fastLogin.length > 4) {
        // Remove the oldest data (first element)
        fastLogin.shift();
        setFastLogin([data, ...fastLogin]);
      } else {
        // Append the new data to the fastLogin array
        setFastLogin([data, ...fastLogin]);
      }
      // Update localStorage with the modified fastLogin array
    } else {
      // If fastLogin does not exist in localStorage, create a new array with the data
      setFastLogin([data, ...fastLogin]);
    }
  }

  // call this function when you want to authenticate the user
  const login = async (data: LoginRes) => {
    const { agentProfile, token } = data;
    const { account, lastReset, maxExpiredDays, daysBeforeExpired, tokenJwtExpired, ...userData } =
      agentProfile;

    const isPasswordExpired = daysBeforeExpired < 1;
    const shouldUpdatePassword = daysBeforeExpired < 8;
    const cookieDuration = tokenJwtExpired * 3600; // tokenJwtExpired is hours before expired

    local.setItem(LocalStorageKey.ACCESS_TOKEN, token);
    setCookie(CookieNameEnum.NATIVE_TOKEN, token, cookieDuration);

    let imageUrl = await GetProfilePictureUrl(account?.accountId || '', userData?.agentId || '');

    imageUrl = imageUrl.includes('agent_profile_photo/null')
      ? generateAvatarImage(`${userData?.firstName} ${userData?.lastName}`)
      : imageUrl;

    setUserLocal({
      id: userData.agentId,
      username: userData.email,
      name: userData.firstName,
      accessToken: token,
      image: imageUrl,
      account,
      ...userData
    });
    updateFastLogin({
      id: userData.agentId,
      username: userData.email,
      name: userData.firstName,
      accessToken: token,
      image: imageUrl,
      account,
      ...userData
    });
    setAccessToken(token);

    setUser({ ...userData, photoUrl: imageUrl });
    setAccount(account);
    setUserPhoto(imageUrl);
    appendPrevUserList({ ...userData, photoUrl: imageUrl });
    setImageUrl(imageUrl);
    setAuthorizationHeader(token);

    setIsPasswordExpired(isPasswordExpired);
    setShouldUpdatePassword(shouldUpdatePassword);
    setDaysBeforeExpired(daysBeforeExpired);
    setMaxExpiredDays(maxExpiredDays);

    if (lastReset) {
      if (isPasswordExpired) {
        navigate('/reset-password', {
          state: { userEmail: userData.email, isPasswordExpired: true }
        });
        return;
      }
      if (shouldUpdatePassword) {
        navigate('/should-update-password');
        return;
      }
    }

    navigate(`${userData.role === RoleEnum.ADMIN ? '/dashboard/team' : '/dashboard'}`);
  };

  // call this function to sign out logged in user
  const logout = async () => {
    try {
      await logoutNATIVE();
    } catch {
      return;
    }
    clearChatListStore();
    clearConversationList();
    setUserLocal({
      id: '',
      agentId: '',
      username: '',
      email: '',
      name: '',
      phone: '',
      role: '',
      accessToken: '',
      image: '',
      account: {
        accountId: '',
        accountName: ''
      }
    });
    reset();
    setIsPasswordExpired(false);
    setShouldUpdatePassword(false);
    setDaysBeforeExpired(0);
    setAccessToken('');
    resetAttentionChat();
    setInboxList([]);
    setOfflineChatList([]);
    setSelectedCall(null);
    setSelectedOfflineChat(null);
    setMeetingRoomId('');
    setInboxId('');

    useCallStore.getState().setShowVideo(false);
    localStorage.removeItem('meetingRoomId');
    localStorage.removeItem('isSwicthed');
    localStorage.removeItem('selectedCall');

    clearAuthLocalStorage();
    deleteCookie(CookieNameEnum.NATIVE_TOKEN);

    CloseWebSocket();

    const clientCode = local.getItem(LocalStorageKey.SQECC_CLIENT_CODE);
    navigate(`/login?client=${clientCode}`, { replace: true });
  };

  const value = useMemo(
    () => ({
      user: {
        ...user,
        id: user.agentId,
        username: user.firstName,
        name: user.firstName,
        accessToken,
        image: user.photoUrl
      },
      accessToken,
      login,
      logout
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user, accessToken]
  );

  useEffect(() => {
    if (!user.role) {
      return;
    }

    setUserRole(user.role as RoleEnum);
  }, [user.role, setUserRole]);

  useEffect(() => {
    if (user.firstName) {
      setName(user.firstName);
    }
  }, [setName, user.firstName]);

  useEffect(() => {
    if (user.photoUrl) {
      setImageUrl(user.photoUrl);
    }
  }, [setImageUrl, user.photoUrl]);
  return <AuthNativeContext.Provider value={value}>{children}</AuthNativeContext.Provider>;
};

export const useAuthNative = () => {
  return useContext(AuthNativeContext);
};
