import { Outlet, useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { RoleEnum } from '../static/role';
import { useAuthStore } from '../store/new/auth';
import useMount from '../hooks/useMounth';

export const HomeLayout = () => {
  const { accessToken, user } = useAuth();
  const { isNativeLoggedIn, isSQEIDLoggedIn } = useAuthStore();
  const navigate = useNavigate();

  const isAlreadyOnDashboard = window.location.href.includes('/dashboard');

  useMount(() => {
    if (accessToken && (isNativeLoggedIn() || isSQEIDLoggedIn()) && !isAlreadyOnDashboard) {
      const redirectUrl = user?.role === RoleEnum.ADMIN ? '/dashboard/team' : '/dashboard';
      navigate(redirectUrl);
    }
  });

  return (
    <div>
      <Outlet />
    </div>
  );
};
