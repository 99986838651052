import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  addInboxMember,
  generateTokenSQEID,
  receiveAgentStatus,
  getUserSQEID,
  removeInboxMember,
  updateUserProfile,
  delUserSQEID
} from '../../user';
import { fetchInboxList } from '../../inbox';
import { getAccountId } from '../../../utils/getAccountId';
import { UpdateUserPayloadParam } from '../../../pages/v2/users/users.interface';

export const useInvUsrSQEID = () => {
  const queryClient = useQueryClient();

  return useMutation(generateTokenSQEID, {
    onSuccess: () => {
      queryClient.invalidateQueries('get-inv-user');
    }
  });
};

export const useUpdateOwnProfile = () => {
  return useMutation(generateTokenSQEID);
};

export const useGetUsers = (role?: string, email?: string, username?: string) => {
  return useQuery({
    queryKey: ['get-inv-user', role, email, username],
    queryFn: () => getUserSQEID(),
    refetchOnWindowFocus: true
  });
};

export const useGetUsrProfileSQEID = (config: any) => {
  const accountId = getAccountId();

  return useQuery(['get-profile-user'], () => receiveAgentStatus(accountId), config);
};

export const useGetInboxes = (accountId: string) => {
  return useQuery(['get-inboxes', accountId], () => fetchInboxList(accountId), {
    refetchOnWindowFocus: true,
    enabled: !!accountId
  });
};

export const useUpdateUsrProfileSQEID = (openNotification: any) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (payload: UpdateUserPayloadParam) => {
      await updateUserProfile(payload);
      return payload;
    },
    {
      onSuccess: (data: UpdateUserPayloadParam) => {
        openNotification({
          label: `Data user ${data?.firstName} ${data?.lastName || ''} berhasil dilengkapi`,
          variant: 'primary'
        });

        queryClient.invalidateQueries('get-inv-user');
      },
      onError: () => {
        openNotification({
          label: 'Gagal mengubah data pengguna',
          variant: 'secondary'
        });
      }
    }
  );
};

export const useDeleteUsrSQEID = (openNotification: any) => {
  const queryClient = useQueryClient();

  return useMutation(delUserSQEID, {
    onSuccess: () => {
      openNotification({
        label: 'Berhasil menghapus pengguna',
        variant: 'primary'
      });
      queryClient.invalidateQueries('get-inv-user');
    },
    onError: () => {
      openNotification({
        label: 'Gagal menghapus data pengguna',
        variant: 'secondary'
      });
    }
  });
};

export const useAddInboxMemberSQEID = () => {
  const queryClient = useQueryClient();

  return useMutation(addInboxMember, {
    onSuccess: () => {
      queryClient.invalidateQueries('get-inv-user');
    }
  });
};

export const useRemoveInboxMemberSQEID = () => {
  const queryClient = useQueryClient();

  return useMutation(removeInboxMember, {
    onSuccess: () => {
      queryClient.invalidateQueries('get-inv-user');
    }
  });
};
