import { create } from 'zustand';
import { TourStoreInterface } from './tour-state.interface';
import { Step } from 'react-joyride';

export const useTourStore = create<TourStoreInterface>((set) => ({
  run: false,
  stepIndex: 0,
  steps: [] as Step[],
  tourActive: false,
  setRun: (run: boolean) => set({ run }),
  setStepIndex: (stepIndex: number) => set({ stepIndex }),
  setSteps: (steps: Step[]) => set({ steps })
}));
