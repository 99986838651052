import { useMemo, useState } from 'react';
import {
  Button,
  ColumnFiltersState,
  Hook,
  Icon,
  PaginationState,
  Paragraph,
  Table,
  TableColumnTypeDef,
  TableRowTypeModifier,
  Token
} from '@squantumengine/horizon';
import DetailCardLayout from './components/DetailCardLayout';
import { useQuery } from 'react-query';
import { fetchUserList } from '../../../api';
import useTeamCreateEditStore, { CreateEditSections } from '../create-edit/store';
import { CreateTeamFormSection } from '../create-edit/type';
import { RoleEnum } from '../../../static/role';
import { useAuthStore } from '../../../store/new/auth';
import { fetchInboxUserList } from '../../../services/inbox';
import { useNavigate, useParams } from 'react-router-dom';
import { ConfigProvider, Pagination, PaginationProps } from 'antd';

type AgentTableType = {
  name: string;
  email: string;
  phone: string;
  maxChat: number;
  isCallAvailable: boolean;
};

type ModifiedAgentTableType = TableRowTypeModifier<AgentTableType>;

const { COLORS } = Token;

export default function AgentTeamDetail() {
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 5
  });
  const store = useTeamCreateEditStore();
  const { assignAgent, setSelectedSection } = store;
  const { inboxId } = useParams();
  const { account } = useAuthStore();

  const navigate = useNavigate();
  const handleEdit = () => {
    setSelectedSection(
      CreateEditSections.findIndex(
        (section) => section.key === CreateTeamFormSection.FORM_ASSIGN_AGENT
      )
    );
    navigate(`/v2/dashboard/team/edit/${inboxId}`);
  };

  const { data: agentList } = useQuery(['get-agents'], () =>
    fetchUserList(account.accountId, [RoleEnum.AGENT])
  );
  const { data: agentInboxList } = useQuery(
    ['inbox', inboxId, 'members'],
    () => fetchInboxUserList(account.accountId, inboxId ?? ''),
    {
      enabled: !!inboxId
    }
  );

  const agentsDataTable = useMemo(
    () =>
      assignAgent.map((agentId) => {
        const agentData = agentInboxList?.inboxMembersList.find(
          (agent) => agent.agentId === agentId
        );
        return {
          name: `${agentData?.firstName ?? ''} ${agentData?.lastName ?? ''}`,
          maxChat: agentData?.capacity?.maxChatCapacity ?? 0,
          isCallAvailable: agentData?.capacity?.maxCallCapacity === 1 ?? false,
          phone: agentList?.find((agent) => agent.agentId === agentId)?.phone ?? '',
          email: agentList?.find((agent) => agent.agentId === agentId)?.email ?? ''
        };
      }),
    [agentInboxList?.inboxMembersList, agentList, assignAgent]
  );

  const tableColumns = useMemo(() => {
    const isEmailAvailable = agentsDataTable?.every((agent) => !!agent.email);
    const tableColumnsMain: TableColumnTypeDef<ModifiedAgentTableType> = [
      {
        header: 'Nama Agen',
        accessorKey: 'name'
      },
      {
        header: 'Maks. Chat',
        accessorKey: 'maxChat',
        enableSorting: false,
        width: 150
      },
      {
        header: 'Panggilan',
        accessorKey: 'isCallAvailable',
        cell: ({ cell }) => {
          return cell.getValue() ? <Icon name="check" /> : <Icon name="close-small" />;
        },
        enableSorting: false,
        width: 150
      }
    ];
    const tableColumnsEmail: TableColumnTypeDef<ModifiedAgentTableType> = [
      {
        header: 'E-mail',
        accessorKey: 'email',
        enableSorting: false
      }
    ];
    const tableColumnsPhone: TableColumnTypeDef<ModifiedAgentTableType> = [
      {
        header: 'Nomor Telepon',
        accessorKey: 'phone',
        enableSorting: false
      }
    ];
    return isEmailAvailable
      ? [...tableColumnsMain, ...tableColumnsEmail]
      : [...tableColumnsMain, ...tableColumnsPhone];
  }, [agentsDataTable]);

  const table = Hook.useTable({
    data: agentsDataTable,
    columns: tableColumns,
    pagination,
    setPagination,
    columnFilters,
    setColumnFilters
  });

  const onShowSizeChange: PaginationProps['onShowSizeChange'] = (current, pageSize) => {
    setPagination({
      pageIndex: current - 1,
      pageSize
    });
  };

  const onChange: PaginationProps['onChange'] = (current, pageSize) => {
    setPagination({
      pageIndex: current - 1,
      pageSize
    });
  };

  return (
    <ConfigProvider
      prefixCls="cc"
      theme={{
        token: {
          colorPrimary: `${COLORS.blue[500]}`
        },
        components: {
          Pagination: {
            colorBgContainer: `${COLORS.blue[500]}`
          }
        }
      }}>
      <DetailCardLayout title="Daftar Agen" buttonAction={handleEdit}>
        {agentsDataTable.length > 0 ? (
          <div className="flex h-full w-full flex-col items-center justify-center gap-4">
            <Table data={agentsDataTable} table={table} columns={tableColumns} />
            <Pagination
              showSizeChanger
              onShowSizeChange={onShowSizeChange}
              defaultPageSize={5}
              pageSize={pagination.pageSize}
              pageSizeOptions={['5', '10', '15']}
              onChange={onChange}
              defaultCurrent={1}
              total={agentsDataTable?.length}
            />
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center gap-2 rounded-xl border-[1px] border-solid border-neutral-200 p-10 text-center text-neutral-600">
            <Paragraph>Belum ada agen yang bertugas.</Paragraph>
            <Paragraph>
              Silakan{' '}
              <Button
                onClick={() => handleEdit()}
                variant={'text'}
                className="h-fit p-0 hover:bg-transparent"
                size="sm">
                Tugaskan Agen
              </Button>
              .
            </Paragraph>
          </div>
        )}
      </DetailCardLayout>
    </ConfigProvider>
  );
}
