export enum ButtonEnum {
  SPEAKER = 'Speaker',
  CAMERA = 'Camera',
  CHAT = 'Chat',
  SHARE = 'Share',
  ZOOM = 'Zoom',
  DROP_CALL = 'Drop Call',
  END_SESSION = 'Akhiri Tiket'
}

export enum ButtonStateEnum {
  ON = 'On',
  OFF = 'Off'
}

export interface ButtonInterface {
  name: ButtonEnum;
  color: string;
  onClick: () => void;
  imageSrc: string;
  label: string;
  border?: boolean;
}
