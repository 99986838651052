import { Paragraph } from '@squantumengine/horizon';
import dayjs from 'dayjs';
import { contactConversationHistory } from '../../../../../static/routeConstructor';

type DeviderProps = {
  time: string | number;
  isOfflineOrResolverChat?: boolean;
  conversationId?: string;
  inboxId?: string;
  contactId?: string;
  className?: string;
};

const Devider = ({
  time,
  isOfflineOrResolverChat,
  conversationId,
  inboxId,
  contactId,
  className
}: DeviderProps) => {
  const isToday = dayjs(time).isSame(dayjs(), 'day');

  return (
    <div className={`relative ${className}`}>
      <div className="relative z-10 flex flex-row items-center justify-center">
        <div className="flex flex-col items-center justify-center bg-white">
          {isOfflineOrResolverChat && (
            <>
              <Paragraph size="s" className="px-2 text-neutral-600">
                {dayjs(time).locale('id').format('dddd, DD MMM YYYY (HH:mm)')}
              </Paragraph>
              <Paragraph size="s" className="w-[100px] truncate bg-white px-2 text-neutral-600">
                ID #
                <a
                  className="cursor-pointer text-[14px] font-semibold text-blue-500"
                  href={contactConversationHistory({
                    inboxId: inboxId || '',
                    conversationId: conversationId || '',
                    contactId: contactId || ''
                  })}
                  target="_blank"
                  rel="noreferrer">
                  {conversationId?.substring(conversationId.length - 5)}
                </a>
              </Paragraph>
            </>
          )}

          {!isOfflineOrResolverChat && (
            <Paragraph size="s" className="px-2 text-neutral-600">
              {isToday && 'Hari ini '}
              {dayjs(time)
                .locale('id')
                .format(isToday ? '(HH:mm)' : 'dddd, DD MMM YYYY (HH:mm)')}
            </Paragraph>
          )}
        </div>
      </div>

      <div className="absolute top-1/2 w-full border-0 border-b border-solid border-neutral-100"></div>
    </div>
  );
};

export default Devider;
