import { useEffect, useState } from 'react';
import { Typography, Button, Table, Form, Flex, message } from 'antd';
import { invUserNATIVEColumns } from '../../static/teams';
import InvUsersModal from './modal/inv-users-modal';
import { useGetUsers } from '../../api/hooks/sqeid/useSQEID';
import { UserResponse } from '../card/user/user.interface';
import SearchFilter from './search-filter';
import { useUserStatusStore } from '../../store/user-state';
import { RoleEnum } from '../../static/role';
import { itemsInvUsr } from './team-users.interface';
import TeamConfirmationModal from './modal/team-confirmation-modal';
import EditUsersModal from './modal/edit-users-modal';
import TeamBUModal from './modal/team-bu-modal';
import { useUnblockUser } from '../../api/hooks/native/useUnblockUser';

const { Title } = Typography;

const ManageUsersNATIVE = () => {
  const [isModalEditOpen, setIsModalEditOpen] = useState<boolean>(false);
  const [isModalConfirmOpen, setIsModalConfirmOpen] = useState<boolean>(false);
  const [isModalInvUserOpen, setIsModalInvUserOpen] = useState<boolean>(false);
  const [isModalBUOpen, setIsModalBUOpen] = useState<boolean>(false);
  const [selectedData, setSelectedData] = useState<UserResponse>({} as UserResponse);
  const [filterData, setFilterData] = useState<UserResponse[] | undefined>();
  const [organizationId, setOrganizationId] = useState<string>('');
  const [selectedBU, setSelectedBU] = useState<{ key: string; label: string }>();
  const { userRole } = useUserStatusStore();
  const [notification, contextHolder] = message.useMessage();

  const { data: invUserData, isLoading: isInvUserDataLoading } = useGetUsers();
  const { mutateAsync: unblockUser } = useUnblockUser(notification);

  const [formInvite] = Form.useForm();
  const [formEdit] = Form.useForm();

  const handleInviteUser = async () => {
    if (userRole === RoleEnum.SUPERVISOR) {
      setIsModalInvUserOpen(true);
    } else {
      setIsModalBUOpen(true);
    }
  };

  const doInviteUser = async (organizationId?: string) => {
    setIsModalInvUserOpen(true);
    setOrganizationId(organizationId!);
  };

  useEffect(() => {
    setFilterData(invUserData);
  }, [invUserData]);

  return (
    <>
      {contextHolder}
      <InvUsersModal
        form={formInvite}
        setIsModalOpen={setIsModalInvUserOpen}
        setIsModalConfirmOpen={setIsModalConfirmOpen}
        isModalOpen={isModalInvUserOpen}
        organizationId={organizationId}
        selectedBU={selectedBU}
      />
      <TeamBUModal
        inviteUser={doInviteUser}
        setIsModalOpen={setIsModalBUOpen}
        isModalOpen={isModalBUOpen}
        setSelectedBU={setSelectedBU}
      />
      <EditUsersModal
        form={formEdit}
        setIsModalOpen={setIsModalEditOpen}
        isModalOpen={isModalEditOpen}
        selectedUser={selectedData}
      />
      <TeamConfirmationModal
        setIsModalOpen={setIsModalConfirmOpen}
        setIsModalEditOpen={setIsModalEditOpen}
        isModalOpen={isModalConfirmOpen}
        selectedData={selectedData}
        setSelectedData={setSelectedData}
      />
      <Flex gap="middle" vertical>
        <Flex justify="space-between" align="center">
          <Title className="m-0 p-0" level={2}>
            Manage Users
          </Title>
          <Flex justify="space-between" align="center" gap="middle" className="w-5/12">
            <div className="w-8/12">
              <SearchFilter setData={setFilterData} data={invUserData} items={itemsInvUsr} />
            </div>
            <Button
              onClick={handleInviteUser}
              className={`h-10 w-4/12 rounded-lg border-none bg-green-500 text-base font-semibold text-white`}>
              Tambah User Baru
            </Button>
          </Flex>
        </Flex>
        <Table
          loading={isInvUserDataLoading}
          columns={invUserNATIVEColumns(
            setIsModalEditOpen,
            setSelectedData,
            userRole === RoleEnum.ADMIN,
            unblockUser,
            notification
          )}
          dataSource={filterData}
        />
      </Flex>
    </>
  );
};

export default ManageUsersNATIVE;
