import React, { useEffect, useMemo, useState } from 'react';
import { ArrowDownOutlined, RightOutlined } from '@ant-design/icons';
import {
  Breadcrumb,
  Col,
  Divider,
  List,
  Row,
  Skeleton,
  Typography,
  Image,
  Button,
  Avatar,
  Popover,
  notification
} from 'antd';
import { useParams, useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';

import ChatIcon from '../../assets/icons/chat';
import VideoIcon from '../../assets/icons/video';
import ChatBox from '../../components/card/chatbox/conversation';
import Copy from '../../components/copy';
import { DurationToString, TimestampToStringDateAndTime } from '../../utils/timestamp';
import { _renderRating } from '../../static/analysis';
import { useCustomerDetails } from '../../api/hooks/customer/useCustomerDetails';
import { useWorkspaceSummary } from '../../api/hooks/workspace/useWorkspaceSummary';
import { useWorkspaceDocuments } from '../../api/hooks/workspace/useWorkspaceDocuments';
import { downloadFileFromUrl } from '../../config/file-api-handler';
import { FetchedConversationInterface } from '../../utils/webSocket/websocket-interfaces';
import Collapsible from '../../components/commons/Collapsible';
import { useInfiniteQuery, useMutation, useQuery } from 'react-query';
import { useChatListStore } from '../../store/chat-list';
import { ConversationInterface } from '../../components/card/chat-message/chat-message.interface';
import { InboxListItemInterface } from '../../shared/types/inbox.interface';
import { getIconUrl } from '../../utils/getIconUrl';
import { pageLimit } from '../../utils/pageLimit';
import { fetchHistoryChat } from '../../api/conversation';
import { CommunicationTypeEnum } from '../../static/communication-type';
import { getVideoRecordingUrl } from '../../api/workspace';
import { CurrentUserObjectInterface } from '../../shared/types/user.interface';
import { RoleEnum } from '../../static/role';
import { fetchInboxByAgent, fetchInboxList } from '../../api/inbox';

enum CollapseKey {
  CHAT = 'chat',
  DATA_PELANGGAN = 'data_pelanggan',
  RINGKASAN = 'ringkasan',
  BERKAS = 'berkas',
  VIDEO_RECORDING = 'video_recording'
}
type FormattedConversationInterface = Partial<FetchedConversationInterface> & {
  date: string;
  duration: string;
  rating: number;
};
const FIELDS_INFO_DASAR = [
  ['name', 'Nama'],
  ['contactId', 'ID Pelanggan'],
  ['email', 'Email'],
  ['phone', 'No Telepon'],
  ['dateOfBirth', 'Tanggal Lahir']
];

const ConversationHistory = () => {
  const [currentUser, setCurrentUser] = useState<CurrentUserObjectInterface>();
  const { inboxId } = useParams();
  const [conversationId, setConversationId] = useState('');
  const [contactId, setContactId] = useState('');
  const [searchParams] = useSearchParams();
  const { setSelectedCall } = useChatListStore();
  const isAgent = currentUser?.role === RoleEnum.AGENT;
  const { data: inboxList } = useQuery(
    ['inboxList', currentUser],
    async () => {
      const accountId = currentUser?.account?.accountId;
      return isAgent
        ? fetchInboxByAgent(accountId!, currentUser?.agentId!)
        : fetchInboxList(accountId!);
    },
    { enabled: !!currentUser, select: (data) => data?.inboxList || [] }
  );
  const currentInbox = inboxList?.find(
    ({ inboxId: id }) => id === inboxId
  ) as InboxListItemInterface;

  const {
    data: conversationData,
    isLoading,
    hasNextPage,
    fetchNextPage
  } = useInfiniteQuery<ConversationInterface[]>(
    ['useHistory', contactId],
    async ({ pageParam = 1 }) => {
      const res = await fetchHistoryChat(String(contactId), {
        'page-no': pageParam,
        active: false,
        'rating-required': true
      });
      const conversations: ConversationInterface[] = res.data.data || [];
      return conversations;
    },
    {
      retry: false,
      enabled: !!contactId,
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = lastPage?.length === pageLimit ? allPages?.length + 1 : undefined;
        return nextPage;
      }
    }
  );

  const { mutate: handleDownloadVideoRecording, isLoading: isLoadingDownloadVideoRecording } =
    useMutation(async (conversationId: string) => {
      const res = await getVideoRecordingUrl(conversationId);
      const { downloadUrl, filename, message } = res.data || {};
      if (downloadUrl) await downloadFileFromUrl(downloadUrl, filename);
      else if (message) notification.info({ message });
    });

  // @ts-ignore
  const conversationList: FormattedConversationInterface[] = useMemo(() => {
    return conversationData?.pages?.reduce(
      (acc, curr) => [
        ...acc,
        ...curr.map(
          ({ startTime, endTime, conversationId, communicationType, createdAt, ...rest }: any) => ({
            ...rest,
            createdAt,
            conversationId,
            communicationType,
            duration: DurationToString(
              Math.round((new Date(endTime).getTime() - new Date(startTime).getTime()) / 1000)
            ),
            date: dayjs(createdAt).format('DD MMM YYYY')
          })
        )
      ],
      []
    );
  }, [conversationData]);

  const isEmptyConversationList = !isLoading && conversationList?.length === 0;

  const currentSelectedCall = conversationList?.find(
    ({ conversationId: id }: any) => id === conversationId
  );

  const { data: customerDetail, isLoading: isLoadingCustomerDetail } = useCustomerDetails(
    contactId,
    !!contactId
  );
  const { data: summaryDetail } = useWorkspaceSummary(conversationId, !!conversationId);
  const { data: documentDetail, isLoading: isLoadingDocument } =
    useWorkspaceDocuments(conversationId);

  const breadcrumbItems = useMemo(() => {
    const _renderItem = (title: string, href?: string) => {
      if (href)
        return (
          <a className="cursor-pointer px-1 font-semibold text-black underline" href={href}>
            {title}
          </a>
        );
      return <div>{title}</div>;
    };

    return [
      { title: _renderItem(currentInbox?.name!!, `/dashboard/${inboxId}`) },
      { title: _renderItem('Riwayat') },
      { title: _renderItem('Detail Percakapan') }
    ];
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentInbox]);

  const handleClickConversation = ({ conversationId, inbox }: FormattedConversationInterface) => {
    setConversationId(conversationId!!);
    const selectedCall = {
      conversationId,
      inboxConfig: inbox?.inboxConfig
    } as ConversationInterface;
    setSelectedCall(selectedCall);
  };

  const _renderSingleConversationItem = (item?: FormattedConversationInterface) => {
    if (!item) return;
    return (
      <div className="flex items-center space-x-4 max-xl:text-xs">
        <div className="flex space-x-1 text-sm font-bold">
          <div className="!w-[68px] truncate text-sm xl:text-base">#{item?.conversationId}</div>
          <div>&#8226;</div>
          <div className="flex items-center">{item.date}</div>
        </div>
        <span>{item?.duration}</span>
        <span className="[&>*]:!inline">Rating: {_renderRating(item.rating)}</span>
        <span className="space-x-1">
          {
            {
              CHAT: <ChatIcon className="!text-gray-500" />,
              AUDIO_VIDEO: <VideoIcon className="!text-gray-500" />
            }[item?.communicationType as string]
          }
        </span>
      </div>
    );
  };
  const _renderConversationItem = (item: FormattedConversationInterface) => {
    const isActive = conversationId === item.conversationId;
    return (
      <div
        className={`min-w-[208px] cursor-pointer space-y-2 rounded-2xl border-solid p-4 hover:border-emerald-light-green ${
          isActive ? 'border-emerald-light-green' : 'border border-gray-400'
        }`}
        onClick={handleClickConversation.bind(this, item)}>
        <div className="flex text-sm font-bold">
          <div className="!w-[68px] truncate">#{item?.conversationId}</div>
          <div>&#8226;</div>
          <div>{item.date}</div>
        </div>
        <div className="flex items-center space-x-2">
          <span>{item?.duration}</span>
          <span className="[&>*]:!inline">Rating: {_renderRating(item.rating)}</span>
          <span className="space-x-1">
            {
              {
                CHAT: <ChatIcon className="!text-gray-500" />,
                AUDIO_VIDEO: <VideoIcon className="!text-gray-500" />
              }[item?.communicationType as string]
            }
          </span>
        </div>
      </div>
    );
  };

  const handlePreviewDocument = (url: string) => window.open(url, '_blank');
  const handleDownloadDocument = (url: string, fileName: string) =>
    downloadFileFromUrl(url, fileName);

  useEffect(() => {
    setConversationId(searchParams.get('conversationId') || '');
    setContactId(searchParams.get('contactId') || '');
  }, [searchParams]);

  useEffect(() => {
    if (currentInbox) {
      const selectedCall = {
        conversationId,
        inboxConfig: currentInbox.inboxConfig
      } as unknown as ConversationInterface;
      setSelectedCall(selectedCall);
    }
    //eslint-disable-next-line
  }, [currentInbox]);

  useEffect(() => {
    const currentUser = JSON.parse(localStorage.getItem('user') || '{}');
    setCurrentUser(currentUser);
  }, []);

  return (
    <div>
      <Row className="flex h-[calc(100vh-64px)] flex-wrap">
        <Col span={16} className="flex h-full flex-col space-y-2 p-4">
          <Breadcrumb items={breadcrumbItems} separator={<RightOutlined className="text-xs" />} />
          <Collapsible
            key={CollapseKey.CHAT}
            label={
              <>
                <h2 className="heading m-0 max-xl:!text-lg">{customerDetail?.name}</h2>
                {_renderSingleConversationItem(currentSelectedCall)}
              </>
            }
            trailing={
              <h4 className="heading max-xl:text-xs">
                Riwayat tiket pelanggan ({conversationList?.length})
              </h4>
            }
            children={
              <div className="scrollbar-main flex w-full items-center space-x-4 overflow-x-scroll pb-4">
                {conversationList?.map(_renderConversationItem)}
                {hasNextPage && !isEmptyConversationList && (
                  <Button
                    icon={<RightOutlined className="text-green-emerald" />}
                    className="flex items-center justify-center border border-green-emerald p-1"
                    onClick={() => fetchNextPage()}
                  />
                )}
              </div>
            }
          />
          {/* Chatbox */}
          <div className="max-h-[480px] flex-1">
            <ChatBox showHeading={false} showCustomerAction={false} />
          </div>
        </Col>
        <Col
          span={8}
          className="flex h-full flex-col overflow-y-scroll border-b-0 border-l border-r-0 border-t-0 border-solid border-grey-50 px-4 pb-16">
          <div className="space-y-0">
            <div>
              {/* Data Pelanggan */}
              <Collapsible
                key={CollapseKey.DATA_PELANGGAN}
                label={<h3 className="heading m-0">Info Dasar</h3>}
                children={
                  <div className="grid gap-2 overflow-hidden lg:grid-cols-2 lg:gap-4">
                    {FIELDS_INFO_DASAR.map(([key, label]) => {
                      // @ts-ignore: unparseable key
                      const value = (customerDetail && customerDetail[key]) || '-';
                      return (
                        <div key={key}>
                          <h5 className="heading my-0 text-xs xl:text-sm">
                            {{
                              contactId: (
                                <div className="flex items-center justify-between">
                                  <span>{label}</span>
                                  <span className="text-xs">
                                    <Copy className="text-xs" value={value} />
                                  </span>
                                </div>
                              )
                            }[key] || label}
                          </h5>
                          <div className="truncate">
                            {(isLoadingCustomerDetail && (
                              <Skeleton
                                active
                                title={{ width: '100%', className: 'my-1' }}
                                paragraph={false}
                              />
                            )) ||
                              value}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                }
              />
            </div>
            <Divider />
            <div>
              {/* Ringkasan */}
              <Collapsible
                key={CollapseKey.RINGKASAN}
                label={<h3 className="heading m-0">Ringkasan</h3>}
                children={
                  <div className="space-y-4">
                    {[
                      ['topic', 'Topik'],
                      ['note', 'Catatan']
                    ].map(([key, label]) => (
                      <div>
                        <div className="my-0 font-semibold">{label}</div>
                        <Typography.Paragraph ellipsis={{ rows: 10, expandable: true }}>
                          {/* @ts-ignore: unparseable key */}
                          {(summaryDetail && summaryDetail[key]) || '-'}
                        </Typography.Paragraph>
                      </div>
                    ))}
                  </div>
                }
              />
            </div>
            <Divider />
            {
              // NOTE: Interaksi percakapan belum tersedia
              /* <div className="py-4">
              <div
                className="cursor-pointer font-bold underline"
                onClick={handleInteractionHistory}>
                Lihat Riwayat Interaksi Percakapan
              </div>
            </div> */
            }
            <div>
              {/* Berkas */}
              <Collapsible
                key={CollapseKey.BERKAS}
                label={
                  <h3 className="heading m-0">Berkas ({documentDetail?.documents?.length || 0})</h3>
                }
                children={
                  <List
                    className={`[&>.ant-spin-nested-loading>.ant-spin-container>.ant-row]:gap-y-2 ${
                      documentDetail?.documents?.length > 4 &&
                      '[&>.ant-spin-nested-loading>.ant-spin-container]:min-h-[244px]'
                    }`}
                    pagination={
                      documentDetail?.documents?.length && {
                        position: 'bottom',
                        align: 'center',
                        pageSize: 4
                      }
                    }
                    dataSource={documentDetail?.documents}
                    grid={{ xs: 1, lg: 2, xl: 2, xxl: 3, column: 1 }}
                    loading={isLoadingDocument}
                    locale={{ emptyText: 'Belum ada berkas' }}
                    renderItem={(item: any) => (
                      <div className="w-[95%] space-y-2 rounded-xl border border-solid border-grey-50 p-2">
                        <div className="flex items-center justify-between">
                          <div className="flex-1 truncate font-semibold">{item?.documentName}</div>
                          <div className="flex w-8 justify-end">
                            <div
                              className="flex h-5 w-5 cursor-pointer items-center justify-center rounded-full border border-solid border-gray-500 p-2"
                              onClick={() =>
                                handleDownloadDocument(item?.documentUrl, item?.documentName)
                              }>
                              <ArrowDownOutlined className="text-[10px] text-gray-500" />
                            </div>
                          </div>
                        </div>
                        <div
                          className="overflow-hidden rounded-xl"
                          onClick={() => handlePreviewDocument(item?.documentUrl)}>
                          {/* Preview */}
                          <Image
                            className="h-[64px] w-full cursor-pointer object-cover xl:h-[80px]"
                            width={'100%'}
                            preview={false}
                            src={item?.documentUrl}
                            alt={item?.documentName}
                            fallback={getIconUrl('img-fallback.png')}
                          />
                        </div>
                      </div>
                    )}
                  />
                }
              />
            </div>
            {currentSelectedCall?.communicationType === CommunicationTypeEnum.VIDEO && (
              <div>
                <Collapsible
                  key={CollapseKey.VIDEO_RECORDING}
                  label={<h3 className="heading m-0">Video Recording</h3>}
                  children={
                    <div className="space-y-2">
                      <div className="flex items-center space-x-2">
                        <Avatar
                          size={36}
                          className={`flex flex-row justify-center bg-blue-glaucaus`}
                          icon={<VideoIcon />}
                        />
                        <div className="flex-1">
                          <Popover
                            content={
                              <Copy value={currentSelectedCall?.conversationId!!}>
                                {currentSelectedCall?.conversationId}
                              </Copy>
                            }>
                            <a className="cursor-pointer text-base text-green-emerald underline">
                              ID #
                              {currentSelectedCall?.conversationId?.substring(
                                conversationId?.length - 5
                              )}
                            </a>
                          </Popover>

                          <div className="space-x-2 text-xs xl:text-base">
                            <span>
                              {TimestampToStringDateAndTime(
                                new Date(currentSelectedCall?.createdAt!!)
                              )}
                            </span>
                            <span>-</span>
                            <span className="font-semibold capitalize">
                              {currentSelectedCall?.agent?.firstName}
                            </span>
                          </div>
                        </div>
                      </div>
                      <Button
                        onClick={() =>
                          handleDownloadVideoRecording(currentSelectedCall?.conversationId!!)
                        }
                        className="h-auto w-full rounded-3xl border border-solid border-black py-2 font-semibold hover:text-green-500"
                        disabled={isLoadingDownloadVideoRecording}>
                        <ArrowDownOutlined />
                        Unduh Video Recording
                      </Button>
                    </div>
                  }
                />
              </div>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ConversationHistory;
