import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const RedoSVG = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.6252 4.25H18.7502C18.5315 4.25 18.3752 4.4375 18.3752 4.65625L18.4377 7.71875C17.0627 5.625 14.6565 4.25 11.969 4.28125C7.68771 4.28125 4.21896 7.78125 4.25021 12.0312C4.25021 16.3125 7.71896 19.75 12.0002 19.75C13.969 19.75 15.8127 19 17.1877 17.7812C17.344 17.625 17.344 17.375 17.1877 17.2188L16.5627 16.5938C16.4377 16.4688 16.219 16.4688 16.0627 16.5938C14.969 17.5625 13.5627 18.125 12.0002 18.125C8.59396 18.125 5.87521 15.4062 5.87521 12C5.87521 8.625 8.59396 5.875 12.0002 5.875C14.469 5.875 16.594 7.375 17.5627 9.5L13.6252 9.40625C13.4065 9.40625 13.2502 9.5625 13.2502 9.78125V10.625C13.2502 10.8438 13.4065 11 13.6252 11H19.6252C19.8127 11 20.0002 10.8438 20.0002 10.625V4.625C20.0002 4.4375 19.8127 4.25 19.6252 4.25Z"
      fill="#313131"
    />
  </svg>
);

const RedoIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={RedoSVG} {...props} />
);

export default RedoIcon;
