import { Col, FormInstance, Row, Select, Form, Typography } from 'antd';
import { useGetTopic } from '../../../api/hooks/inbox/useTopic';
import { useChatListStore } from '../../../store/chat-list';

function TopicAgent(props: { form?: FormInstance<any>; isZoom?: boolean }) {
  const { Text } = Typography;
  const { form, isZoom } = props;

  const { selectedCall } = useChatListStore();
  const { data } = useGetTopic(selectedCall!?.inboxId!);

  return (
    <Form.Item
      name="topic"
      className="inbox_topic mb-0 w-full"
      label={<Text className="text-base font-semibold">Topik</Text>}>
      <Select
        allowClear
        showArrow
        dropdownStyle={isZoom ? { zoom: '75%' } : {}}
        placeholder="Pilih Topik"
        onClear={() => form?.setFieldValue('topic', null)}
        onSelect={(_, option) => {
          form?.setFieldValue('topic', option!?.value);
        }}
        filterOption={(input, option) => {
          // @ts-ignore
          return (
            option!
              ?.value!?.toString()
              ?.toLowerCase()
              ?.indexOf(input!?.toString()?.toLowerCase()) >= 0
          );
        }}>
        {data?.conversationTopics.map((option) => {
          return (
            <Select.Option key={option!?.uuid} value={option?.topic}>
              <Row align="middle" justify="space-between">
                <Col span={20}>
                  <Text>{option!?.topic}</Text>
                </Col>
              </Row>
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );
}

export default TopicAgent;
