import { useEffect, useState } from 'react';

import { Col, Collapse, Row } from 'antd';
import { DownOutlined } from '@ant-design/icons';

import PencilIcon from '../../../assets/icons/pencil';
import UserIcon from '../../../assets/icons/user';
import { ListInboxInterface } from './list-inbox.interface';
import {
  TeamActiveHandlerInterface,
  TeamActiveHandlerKeysEnum,
  TeamInboxListInterface
} from '../../../shared/types/team.interface';
import { useTeamStore } from '../../../store/team-state';
import { FormTypeEnum, FormActionEnum } from '../../../static/team';

import ListUserInbox from '../list-user-inbox';
import { CurrentUserObjectInterface } from '../../../shared/types/user.interface';
import { RoleEnum } from '../../../static/role';

export default function ListInbox(props: ListInboxInterface) {
  const { Panel } = Collapse;
  const {
    isOpenForm,
    formAction,
    formType,
    selectedInbox,
    teamActiveHandler,
    setIsOpenForm,
    setFormAction,
    setFormType,
    setSelectedBU,
    setSelectedInbox,
    setTeamActiveHandler,
    resetTeamActiveHandler
  } = useTeamStore();
  const { IS_EDIT, IS_EDIT_SCHEDULE } = TeamActiveHandlerKeysEnum;
  const accountItem = useTeamStore((state) => state.account);
  const inboxItem = useTeamStore((state) => state.inbox);
  const userItem = useTeamStore((state) => state.user);
  const { accountId } = props;
  const [inboxList, setInboxList] = useState<TeamInboxListInterface>({} as TeamInboxListInterface);
  const [defaultActiveKey, setDefaultActiveKey] = useState<string[]>([]);

  const copyWebsiteToken = (e: any, websiteToken: string) => {
    // Copy website token when hold shift key
    if (e.shiftKey) {
      navigator.clipboard.writeText(websiteToken);
    }
  };

  const getUserCount = (inboxId: string) => {
    const userIndex = userItem.findIndex(
      (userItem) => userItem.accountId === accountId && userItem.inboxId === inboxId
    );
    if (userIndex >= 0) {
      return userItem[userIndex]?.userList?.length;
    }
    return 0;
  };

  const editInbox = (e: any, inboxId: string, type: 'inbox' | 'schedule') => {
    e.stopPropagation();

    const editType = type === 'inbox' ? FormTypeEnum.INBOX : FormTypeEnum.SCHEDULE;
    const toggleKey = type === 'inbox' ? IS_EDIT : IS_EDIT_SCHEDULE;
    const inboxIndex = useTeamStore
      .getState()
      .inbox.findIndex((inboxItem) => inboxItem.accountId === accountId);
    const openForm = !(
      isOpenForm &&
      formAction === FormActionEnum.EDIT &&
      formType === editType &&
      selectedInbox.inboxId === inboxId
    );

    resetTeamActiveHandler();

    const newTeamActiveHandler: TeamActiveHandlerInterface = {
      ...useTeamStore.getState().teamActiveHandler,
      activeInboxId: openForm ? inboxId : ''
    };
    newTeamActiveHandler[toggleKey] = openForm;

    setTeamActiveHandler(newTeamActiveHandler);

    // Close form only if the user is create inbox
    // Close form only if the user is edit business unit
    setIsOpenForm(openForm);
    setFormAction(FormActionEnum.EDIT);
    setFormType(editType);
    // Set selected account and inbox
    const newSelectedInbox = inboxItem[inboxIndex].inboxList.find(
      (inbox) => inbox.inboxId === inboxId
    );
    const newSelectedBU = accountItem.accountList.find(
      (account) => account.accountId === accountId
    );
    newSelectedInbox && setSelectedInbox(newSelectedInbox);
    newSelectedBU && setSelectedBU(newSelectedBU);
  };

  useEffect(() => {
    const currentInbox = inboxItem.find((inbox) => inbox.accountId === accountId);
    if (currentInbox) {
      setInboxList(currentInbox);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inboxItem]);

  useEffect(() => {
    const isTourDone = localStorage.getItem('isTourDone');
    const user: CurrentUserObjectInterface = JSON.parse(localStorage.getItem('user') || '{}');
    if (!isTourDone && user.role === RoleEnum.SUPERVISOR) {
      setDefaultActiveKey(['0']);
    }
  }, []);

  return (
    <>
      {!!inboxList?.inboxList?.length &&
        inboxList.inboxList.map((inbox, idx) => {
          return (
            <Collapse
              key={`inbox_list-${idx}`}
              defaultActiveKey={defaultActiveKey}
              className={`${
                teamActiveHandler.activeInboxId === inbox.inboxId &&
                !teamActiveHandler.activeUserId &&
                !teamActiveHandler.isAddAgent &&
                'border-4 border-solid border-green-emerald-light'
              } mt-4 transition-all duration-100`}
              ghost
              expandIconPosition="end"
              expandIcon={(panelProps) => {
                return (
                  <div className="flex flex-row align-middle text-lg font-semibold">
                    <span
                      role="button"
                      aria-label={`edit-inbox-${inbox.inboxId}`}
                      onClick={(e) => editInbox(e, inbox.inboxId, 'inbox')}>
                      <PencilIcon
                        id={`inbox_detail-edit_button-${inbox.inboxId}`}
                        className="mr-5 transition-all duration-100 active:scale-90"
                        color={
                          teamActiveHandler.activeInboxId === inbox.inboxId &&
                          !teamActiveHandler.activeUserId &&
                          teamActiveHandler.isEdit
                            ? '#2DB089'
                            : undefined
                        }
                      />
                    </span>
                    <div className="rounded-full border border-solid border-black px-3 py-1">
                      <span
                        className="mr-5"
                        id={`inbox_detail-user_counter-${inbox.inboxId}`}
                        role="button"
                        aria-label={`copy-website-token-${inbox.inboxId}`}
                        onClick={(e) => copyWebsiteToken(e, inbox.channel.websiteToken)}>
                        <UserIcon className="mr-2" />
                        &nbsp;
                        {getUserCount(inbox.inboxId)}
                      </span>
                      <span className="text-xs font-bold">
                        <DownOutlined rotate={panelProps.isActive ? 180 : 0} />
                      </span>
                    </div>
                  </div>
                );
              }}>
              <Panel
                className="rounded-xl bg-grey-10 p-4"
                key={idx}
                header={
                  <>
                    <Row justify="space-between" className="items-start" gutter={16}>
                      <Col span={24}>
                        <div className="text-lg font-semibold">{inbox.name}</div>
                        <div className="text-base text-black-90">{inbox.channel?.websiteUrl}</div>
                      </Col>
                    </Row>
                  </>
                }>
                <ListUserInbox accountId={accountId} inboxId={inbox.inboxId} />
              </Panel>
            </Collapse>
          );
        })}
    </>
  );
}
