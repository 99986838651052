import { Modal } from 'antd';
import { ConfirmationModalProps } from './confirmation-modal.interface';

import { getIconUrl } from '../../../utils/getIconUrl';

const ConfirmationModal = ({
  modalState,
  setModalState,
  handleConfirm
}: ConfirmationModalProps) => {
  return (
    <Modal centered open={modalState} footer={null} closable={false} width={360}>
      <div className="relative flex flex-col gap-8">
        <span
          className="absolute top-2 right-0 cursor-pointer"
          onClick={() => setModalState(false)}>
          <img src={getIconUrl('times.svg')} alt="times-icon" />
        </span>
        <p className="m-0 p-0 text-2xl font-bold">Anda akan menutup panggilan</p>
        <p className="m-0 p-0 text-lg">
          Anda akan menutup panggilan dengan pelanggan, apakah Anda yakin?
        </p>
        <div className="flex flex-col gap-4">
          <button
            className="cursor-pointer rounded-3xl bg-transparent py-2 font-sans text-lg font-semibold duration-200 ease-out hover:bg-slate-200"
            onClick={() => setModalState(false)}>
            Kembali ke Panggilan
          </button>
          <button
            className="cursor-pointer rounded-3xl border-0 bg-orange-danger py-2 font-sans text-lg font-semibold text-white outline-none duration-200 ease-out hover:bg-[#b34307]"
            onClick={handleConfirm}>
            Tutup Panggilan
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
