import useTeamCreateEditStore from '../../store';
import { ChannelEnum } from '../../type';
import FormWebWidget from './form-web-widget';
import FormWhatsAppWidget from './form-whatsapp-widget';

type Props = {
  getFormRef: (form: any) => void;
};

export default function FormDisplayConfiguration(props: Props) {
  const store = useTeamCreateEditStore();
  switch (store.channelType) {
    case ChannelEnum.WEB_WIDGET:
      return <FormWebWidget {...props} />;
    case ChannelEnum.WHATSAPP:
      return <FormWhatsAppWidget {...props} />;
    default:
      return null;
  }
}
