/* eslint-disable no-console */
import { Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Button } from '@squantumengine/horizon';
import { RoleEnum } from '../../static/role';
import { useRef, useState } from 'react';
import useMount from '../../hooks/useMounth';
import CardHeading from '../../components/login/card-heading';
import { useAuthStore } from '../../store/new/auth';
import { NativeLoginWordingEnum } from '../../static/native-login';

function ShouldUpdatePassword() {
  const navigate = useNavigate();
  const { user, passwordStatus } = useAuthStore();
  const { isPasswordExpired, daysBeforeExpired, maxExpiredDays } = passwordStatus;
  const interval = useRef<any>();

  const [count, setCount] = useState(10);

  const handleResetPassword = () => {
    if (isPasswordExpired) {
      navigate('/reset-password', { state: { userEmail: user.email } });
      return;
    }
    navigate('/reset');
  };

  const navigateToDashboard = () => {
    navigate(`${user.role === RoleEnum.ADMIN ? '/dashboard/team' : '/dashboard'}`);
  };

  const getDescription = (text1: string, text2: string) => {
    return (
      <>
        {text1}
        <br />
        <br /> {text2}
      </>
    );
  };

  useMount(() => {
    console.log('SHOULD UPDAET PASSWORD');

    if (isPasswordExpired) return;

    interval.current = setInterval(() => {
      setCount((prev) => {
        const newCount = prev - 1;
        if (newCount === 0) {
          clearInterval(interval.current);
          navigateToDashboard();
        }
        return newCount;
      });
    }, 1000);
  });

  return (
    <Row
      className="login-form flex min-h-screen flex-col bg-alice-blue"
      justify="center"
      align="middle">
      <div className="w-96 rounded-lg bg-white p-6 pb-[24px]">
        <CardHeading>
          {!isPasswordExpired && (
            <CardHeading.Heading
              title={`${daysBeforeExpired} hari lagi password harus diubah`}
              description={getDescription(
                `${NativeLoginWordingEnum.forSecurityReason} ${maxExpiredDays} hari.`,
                `Mengalihkan ke Dashboard dalam ${count} detik.`
              )}
            />
          )}

          {isPasswordExpired && (
            <CardHeading.Heading
              title={NativeLoginWordingEnum.updatePassword}
              description={
                <span>
                  {NativeLoginWordingEnum.updatePassword1}
                  <br />
                  {NativeLoginWordingEnum.updatePassword2}
                </span>
              }
            />
          )}
        </CardHeading>

        <Button
          data-testid="button-change-password"
          variant="primary"
          type="submit"
          full
          onClick={handleResetPassword}>
          {NativeLoginWordingEnum.changePasswordNow}
        </Button>

        {!isPasswordExpired && (
          <Button
            data-testid="button-skip"
            className="mt-4"
            variant="secondary"
            type="submit"
            full
            onClick={navigateToDashboard}>
            {NativeLoginWordingEnum.skip}
          </Button>
        )}
      </div>
    </Row>
  );
}

export default ShouldUpdatePassword;
