import { FC } from 'react';
import { getIconUrl } from '../../../utils/getIconUrl';
import { ArrowPaginationProps } from './arrow-pagination.interface';

export const ArrowPagination: FC<ArrowPaginationProps> = (props) => {
  const { disableNext, disablePrev, onClickNext, onClickPrev } = props;

  return (
    <div className="flex gap-2">
      <img
        src={getIconUrl('arrow-down.svg')}
        className={`${disablePrev ? 'opacity-50' : 'cursor-pointer'} rotate-90`}
        alt="previous page"
        onClick={onClickPrev}
      />
      <img
        src={getIconUrl('arrow-down.svg')}
        className={`${disableNext ? 'opacity-50' : 'cursor-pointer'} -rotate-90`}
        alt="next page"
        onClick={onClickNext}
      />
    </div>
  );
};

export default ArrowPagination;
