import dayjs from 'dayjs';
import { InputData, OutputData } from './total-conversation.inteface';

const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  const weekdays = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'];
  const weekdayName = weekdays[date.getDay()];
  const formattedDate = `${String(date.getDate()).padStart(2, '0')}/${String(
    date.getMonth() + 1
  ).padStart(2, '0')}`;
  const result = `${weekdayName} ${formattedDate}`;
  const now = dayjs(dateString);
  const startDate = now.startOf('day').format('YYYY-MM-DDTHH:mm:ss.sssZ');
  const endDate = now.endOf('day').format('YYYY-MM-DDTHH:mm:ss.sssZ');
  return { result, startDate, endDate };
};

export const convertToDay = (obj: any) => {
  return Object.keys(obj).map((key) => ({
    name: formatDate(key).result,
    startDate: formatDate(key).startDate,
    endDate: formatDate(key).endDate,
    Arm1: obj[key].rating[0],
    Arm2: obj[key].rating[1],
    Arm3: obj[key].rating[2],
    Arm4: obj[key].rating[3],
    Arm5: obj[key].rating[4],
    Arm6: obj[key].rating[5],
    Arm7: obj[key].averageDuration
  }));
};

const getWeekRange = (start: string, end: string): string => {
  return `${start} - ${end}`;
};
export const convertToWeeklyData = (inputData: InputData[]) => {
  const weeklyData: OutputData[] = [];
  let currentWeek: OutputData = {
    name: '',
    startDate: '',
    endDate: '',
    Arm1: 0,
    Arm2: 0,
    Arm3: 0,
    Arm4: 0,
    Arm5: 0,
    Arm6: 0,
    Arm7: 0
  };
  let weekStart = '';
  let weekEnd = '';
  let weekStartDate: string | null | undefined = null;
  let weekEndDate: string | null | undefined = null;
  inputData?.forEach((entry, index) => {
    const dayIndex = index % 7;

    if (dayIndex === 0) {
      weekStart = entry.name;
      weekStartDate = entry.startDate;
    }
    if (dayIndex === 6 || index === inputData.length - 1) {
      weekEnd = entry.name;
      weekEndDate = entry.endDate;
      currentWeek.name = getWeekRange(weekStart, weekEnd);
      currentWeek.startDate = weekStartDate || '';
      currentWeek.endDate = weekEndDate || '';
      weeklyData.push({ ...currentWeek });
      currentWeek = {
        name: '',
        Arm1: 0,
        Arm2: 0,
        Arm3: 0,
        Arm4: 0,
        Arm5: 0,
        Arm6: 0,
        Arm7: 0
      };
    }

    currentWeek.Arm1 += entry.Arm1;
    currentWeek.Arm2 += entry.Arm2;
    currentWeek.Arm3 += entry.Arm3;
    currentWeek.Arm4 += entry.Arm4;
    currentWeek.Arm5 += entry.Arm5;
    currentWeek.Arm6 += entry.Arm6;
    currentWeek.Arm7 += entry.Arm7 ?? 0;
  });
  return weeklyData;
};

export const convertToMonthlyData = (weeklyData: InputData[]): OutputData[] => {
  const monthlyData: { [key: string]: OutputData } = {};

  weeklyData.forEach((week) => {
    const month = dayjs(week.startDate).format('MMM');
    if (!monthlyData[month]) {
      monthlyData[month] = {
        name: month,
        startDate: week.startDate,
        endDate: week.endDate,
        Arm1: 0,
        Arm2: 0,
        Arm3: 0,
        Arm4: 0,
        Arm5: 0,
        Arm6: 0,
        Arm7: 0
      };
    }
    if (dayjs(week.startDate).isBefore(dayjs(monthlyData[month].startDate))) {
      monthlyData[month].startDate = week.startDate;
    }
    monthlyData[month].endDate = week.endDate;
    monthlyData[month].endDate = week.endDate;
    monthlyData[month].Arm1 += week.Arm1 || 0;
    monthlyData[month].Arm2 += week.Arm2 || 0;
    monthlyData[month].Arm3 += week.Arm3 || 0;
    monthlyData[month].Arm4 += week.Arm4 || 0;
    monthlyData[month].Arm5 += week.Arm5 || 0;
    monthlyData[month].Arm6 += week.Arm6 || 0;
    monthlyData[month].Arm7 += week.Arm7 || 0;
  });
  return Object.values(monthlyData);
};

export const convertSeconds = (seconds: number = 0): string => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  let result = '';
  if (hours > 0) {
    result += `${hours} jam ${minutes} mnt`;
  } else {
    result += `${minutes} mnt ${remainingSeconds} dtk`;
  }

  return result;
};
