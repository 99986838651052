import { useQuery } from 'react-query';
import { getTopRejectReason } from '../../kyc';
import { getAccountId } from '../../../utils/getAccountId';

export const useGetTopRejectReason = (inboxId: string, params: Record<string, any>) => {
  const accountId = getAccountId();

  return useQuery(
    ['get-top-reject-reason', accountId, inboxId, params],
    () => getTopRejectReason(accountId, inboxId, params),
    {
      enabled: !!accountId && !!inboxId
    }
  );
};
