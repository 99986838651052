import { RoleEnum } from '../../../static/role';
import { useUserStatusStore } from '../../../store/user-state';
import DashboardAgent from './dashboard-agent';
import DashboardSupervisor from './dashboard-supervisor';

function DashboardPage() {
  const { userRole } = useUserStatusStore();
  switch (userRole) {
    case RoleEnum.AGENT:
      return <DashboardAgent />;
    case RoleEnum.SUPERVISOR:
      return <DashboardSupervisor />;
    default:
      return <></>;
  }
}

export default DashboardPage;
