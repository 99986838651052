import { useEffect, useState } from 'react';
import { ConfigProvider, Switch, Radio, Space, Button } from 'antd';

import {
  useUserStatusStore,
  useIdleTimeStore,
  useMaxIdleTimeStore
} from '../../../../store/user-state';
import { offlineDuration } from '../../../../static/user-state';
import { parseUserOnlineStatus } from '../../../../utils/parseUserOnlineStatus';
import { updateAgentStatus } from '../../../../api';
import { getUserJSON } from '../../../../utils/user-json';

import './status.css';

function StatusBox(props: { switchOnly?: boolean }) {
  const { switchOnly } = props;
  const [isIdle, setIsIdle] = useState<'loading' | 'start' | 'stop'>('stop');

  const { isUserOnline, setIsUserOnline } = useUserStatusStore();
  const { idleTime, incrementIdleTime, resetIdleTime } = useIdleTimeStore();
  const { maxIdleTime, setMaxIdleTime } = useMaxIdleTimeStore();
  const user = getUserJSON();

  const onIdle = async () => {
    setIsUserOnline(false);
    resetIdleTime();
    await updateAgentStatus(user.account?.accountId!, 'false');
  };

  const offIdle = async () => {
    setIsUserOnline(true);
    await updateAgentStatus(user.account?.accountId!, 'true');
  };

  useEffect(() => {
    const idleStatus = isUserOnline ? 'stop' : 'start';
    setIsIdle(idleStatus);
  }, [isUserOnline]);

  useEffect(() => {
    if (isIdle !== 'start') {
      return;
    }

    const myInterval = setInterval(() => {
      incrementIdleTime();
    }, 1000);

    return () => clearInterval(myInterval);
  }, [isIdle, idleTime, incrementIdleTime]);
  if (switchOnly) {
    return (
      <div className="agent-status-box-v2 flex flex-row items-center justify-between gap-2 text-base font-semibold">
        <ConfigProvider theme={{ token: { colorPrimary: '#006CEB' } }}>
          <Switch
            checked={isUserOnline}
            // defaultChecked
            onChange={(checked) => {
              isIdle === 'stop' ? onIdle() : offIdle();
            }}
          />
        </ConfigProvider>
        <div className="flex items-center">
          <div className={`${isUserOnline ? 'text-[#006CEB]' : 'text-[#677485]'}`}>
            {parseUserOnlineStatus(isUserOnline)}
          </div>
        </div>
      </div>
    );
  }
  return (
    <>
      <div className="rounded-2xl border border-solid border-grey-50 bg-white p-4 transition-all duration-100">
        <div className="agent-status-box-v2 flex flex-row items-center justify-between pb-4 text-base font-semibold">
          <div className="flex items-center">
            Status:&nbsp;
            <div className={`${isUserOnline ? 'text-[#006CEB]' : 'text-[#677485]'}`}>
              {parseUserOnlineStatus(isUserOnline)}
            </div>
          </div>
          <ConfigProvider theme={{ token: { colorPrimary: '#006CEB' } }}>
            <Switch
              checked={isUserOnline}
              // defaultChecked
              onChange={(checked) => {
                isIdle === 'stop' ? onIdle() : offIdle();
              }}
            />
          </ConfigProvider>
        </div>
        <div className="ml-5 flex flex-col gap-2 text-left text-xs">
          <li className="-indent-5">Jika perlu istirahat, klik toggle hingga menjadi offline. </li>
          <li className="-indent-5">
            Bagi percakapan yang sedang berlangsung, status akan tetap online.
          </li>
        </div>
        <div
          className={` overflow-hidden pt-4 text-sm transition-all duration-500 ${
            isIdle === 'loading' ? 'max-h-screen' : 'max-h-0'
          }`}>
          <hr className="my-4 h-px border-none bg-grey-20" />
          <div className="pb-4 text-2xl font-semibold">Istirahat berapa lama?</div>
          <Radio.Group onChange={(e) => setMaxIdleTime(e.target.value)} value={maxIdleTime}>
            <Space direction="vertical">
              {offlineDuration.map((item, idx) => (
                <Radio value={item.value} key={idx}>
                  {item.text}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
          <div className="pt-4">
            {maxIdleTime > 1 && (
              <Button className="bg-[#19936F]" type="primary" onClick={onIdle}>
                Submit
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default StatusBox;
