export enum ChatFilterEnum {
  ALL = 'Semua',
  UNANSWERED = 'Belum Terjawab',
  ANSWERED = 'Terjawab',
  OFFLINE = 'Pesan Offline'
}

export const filterOption: ChatFilterEnum[] = [
  ChatFilterEnum.ALL,
  ChatFilterEnum.UNANSWERED,
  ChatFilterEnum.ANSWERED,
  ChatFilterEnum.OFFLINE
];
