import { useQuery } from 'react-query';
import axiosInstance from '../../../config/axios';

export const checkUsernameNATIVE: (email: string) => Promise<any> = async (email) => {
  const response = await axiosInstance.get(`agent/${email}/check`);

  return response.data;
};

export const useCheckUsernameNATIVE = (email: string) => {
  return useQuery({
    queryFn: () => checkUsernameNATIVE(email),
    queryKey: ['get-check-username', email],
    enabled: !!email,
    staleTime: 0,
    retry: 0
  });
};
