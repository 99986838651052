import { local } from './storage';
import { LocalStorageKey } from '../static/local-storage';

export const clearAuthLocalStorage = () => {
  local.removeItem(LocalStorageKey.USER);
  local.removeItem(LocalStorageKey.SQEID_TOKEN_OBJECT);
  local.removeItem(LocalStorageKey.SQEID_AUTH_CODE);
  local.removeItem(LocalStorageKey.SQEID_OA_CODE_VERIFIER);
  local.removeItem(LocalStorageKey.SQEID_OA_STATE);
  local.removeItem(LocalStorageKey.ACCESS_TOKEN);
  local.removeItem(LocalStorageKey.TOKEN_CREATED_AT);
};
