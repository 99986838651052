import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';

import { TeamStoreActionsInterface, TeamStoreStateInterface } from './team-state.interface';
import {
  TeamAccountListInterface,
  TeamInboxListInterface,
  TeamUserListInterface,
  TeamActiveHandlerInterface
} from '../shared/types/team.interface';
import { SupervisorInterface, SupervisorItemListInterface } from '../shared/types/user.interface';
import { FormTypeEnum, FormActionEnum } from '../static/team';
import {
  fetchAccountList,
  fetchAccountDetail,
  fetchInboxList,
  fetchUserList,
  fetchInboxUserList,
  validateCCAgent
} from '../api';
import { RoleEnum } from '../static/role';
import { InboxListItemInterface, InboxMemberListInterface } from '../shared/types/inbox.interface';
import { AccountInterface } from '../shared/types/account.interface';
import { AgentListItemInterface } from '../shared/types/agent.interface';
import { useInboxDetailStore } from './inbox-state';

export const useTeamStore = create(
  subscribeWithSelector<TeamStoreActionsInterface & TeamStoreStateInterface>((set, get) => ({
    isOpenForm: false,
    formAction: FormActionEnum.CREATE,
    formType: FormTypeEnum.ACCOUNT,
    selectedBU: {} as AccountInterface,
    selectedInbox: {} as InboxListItemInterface,
    selectedUser: {} as InboxMemberListInterface,
    account: {} as TeamAccountListInterface,
    inbox: [] as TeamInboxListInterface[],
    user: [] as TeamUserListInterface[],
    availableAgent: [] as AgentListItemInterface[],
    supervisorItemList: [] as SupervisorItemListInterface[],
    teamActiveHandler: {
      activeAccountId: '',
      activeInboxId: '',
      activeUserId: '',
      isCreate: false,
      isEdit: false,
      isCreateInbox: false,
      isCreateUser: false,
      isEditSchedule: false
    } as TeamActiveHandlerInterface,
    inboxResponse: {
      oooMessage: '',
      inboxId: ''
    },
    setInboxResponse: (inboxResponse) => set({ inboxResponse }),
    setIsOpenForm: (isOpenForm: boolean) => set({ isOpenForm }),
    setFormAction: (
      formAction: FormActionEnum.CREATE | FormActionEnum.EDIT | FormActionEnum.CHOOSE
    ) => set({ formAction }),
    setFormType: (
      formType:
        | FormTypeEnum.ACCOUNT
        | FormTypeEnum.INBOX
        | FormTypeEnum.USER
        | FormTypeEnum.SCHEDULE
        | FormTypeEnum.USER_INFO
    ) => set({ formType }),
    setSelectedBU: (selectedBU: AccountInterface) => set({ selectedBU }),
    setSelectedInbox: (selectedInbox: InboxListItemInterface) => set({ selectedInbox }),
    setSelectedUser: (selectedUser: InboxMemberListInterface) => set({ selectedUser }),
    setAccount: (account: TeamAccountListInterface) => set({ account }),
    setInbox: (inbox: TeamInboxListInterface[]) => set({ inbox }),
    setUser: (user: TeamUserListInterface[]) => set({ user }),
    setAvailableAgent: (availableAgent: AgentListItemInterface[]) => set({ availableAgent }),
    setSupervisorItemList: (supervisorList: SupervisorItemListInterface[]) =>
      set({ supervisorItemList: supervisorList }),
    setTeamActiveHandler: (teamActiveHandler: TeamActiveHandlerInterface) =>
      set({ teamActiveHandler }),
    fetchAccount: async () => {
      const response = await fetchAccountList();
      const accounts: AccountInterface[] = response.data.map((item) => ({
        ...item
      }));
      // only get first 20 accounts
      // const accountList = accounts.slice(0, 20);
      const accountList = accounts;
      set({ account: { accountList } });
    },
    fetchSupervisorItemList: async (accountId: string) => {
      const response = await fetchUserList(accountId, [RoleEnum.SUPERVISOR]);
      const newSupervisorList: SupervisorInterface[] = response.map((item) => ({
        agentId: item.agentId,
        firstName: item.firstName,
        lastName: item.lastName,
        isEdit: false
      }));

      const newSupervisorItem: SupervisorItemListInterface = {
        accountId,
        supervisorList: newSupervisorList
      };

      const supervisorItemList = [...useTeamStore.getState().supervisorItemList];
      const index = supervisorItemList.findIndex((item) => item.accountId === accountId);
      if (index === -1) {
        supervisorItemList.push(newSupervisorItem);
        set({ supervisorItemList: supervisorItemList });
      } else {
        const existingSupervisorList = supervisorItemList[index]?.supervisorList;
        if (existingSupervisorList.length !== newSupervisorList.length) {
          supervisorItemList[index] = newSupervisorItem;
          set({ supervisorItemList });
        }
      }
    },
    fetchDetailBU: async (accountId: string) => {
      const response = await fetchAccountDetail(accountId);
      set({
        selectedBU: response,
        account: {
          accountList: [
            {
              accountId: accountId || '',
              name: response.name || '',
              description: '',
              countryCode: '',
              target: '0',
              accountIdentity: ''
            }
          ]
        }
      });
    },
    fetchInbox: async (accountId: string) => {
      const response = await fetchInboxList(accountId);
      useInboxDetailStore.setState({ inboxList: response.inboxList });
      const newInbox = [...useTeamStore.getState().inbox];
      const accountIndex = get().inbox.findIndex((inboxItem) => inboxItem.accountId === accountId);
      if (accountIndex >= 0) {
        response.inboxList.forEach((resInbox) => {
          // compare resInbox with inbox
          const inboxIndex = get().inbox[accountIndex].inboxList.findIndex(
            (stateInbox) => stateInbox.inboxId === resInbox.inboxId
          );
          if (inboxIndex < 0) {
            // add new inbox
            newInbox[accountIndex].inboxList.push(resInbox);
          } else {
            // update inbox
            newInbox[accountIndex].inboxList[inboxIndex] = resInbox;
          }
        });
      } else {
        newInbox.push({
          accountId,
          inboxList: response.inboxList.map((resInbox) => {
            return resInbox;
          })
        });
      }
      set({ inbox: newInbox });
    },
    fetchUserInbox: async (accountId: string, inboxId: string) => {
      const newTeamUserList = [...get().user];
      const teamUserListIdx = newTeamUserList.findIndex(
        (userItem) => userItem.accountId === accountId && userItem.inboxId === inboxId
      );

      const userListResponse = await fetchInboxUserList(accountId, inboxId);

      // Remove old user list if exist
      if (teamUserListIdx >= 0) {
        newTeamUserList.splice(teamUserListIdx, 1);
      }
      newTeamUserList.push({
        accountId,
        inboxId,
        userList: userListResponse?.inboxMembersList
      });
      set({ user: newTeamUserList });
    },
    fetchAvailableAgentInbox: async (accountId: string, inboxId: string) => {
      const currentUserListItem = get().user.find(
        (userItem) => userItem.accountId === accountId && userItem.inboxId === inboxId
      );
      const userResponse = await fetchUserList(accountId, [RoleEnum.AGENT]);
      const agentIdArr = userResponse.map((user: AgentListItemInterface) => user.agentId);
      const validatedUserRes = await validateCCAgent(agentIdArr);
      const accountListRes = validatedUserRes.map((user: any) => {
        // get array of user that already CC-onboarded
        const userRes = userResponse.find((res: AgentListItemInterface) => res.agentId === user);
        if (userRes) return userRes;
        return {} as AgentListItemInterface;
      });
      const availableAgent = accountListRes.filter(
        (user: AgentListItemInterface) =>
          // get array of user that not in the inbox yet
          !currentUserListItem?.userList.filter((userItem) => userItem.agentId === user.agentId)
            .length
      );
      set({ availableAgent });
    },
    resetTeamActiveHandler: () => {
      set((state) => {
        return {
          teamActiveHandler: {
            activeAccountId: '',
            activeInboxId: '',
            activeUserId: '',
            isCreate: false,
            isEdit: false,
            isCreateInbox: false,
            isCreateUser: false,
            isEditSchedule: false,
            isAddAgent: false
          }
        };
      });
    }
  }))
);
