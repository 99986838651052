import { useMutation, useQuery, useQueryClient } from 'react-query';
import { createReason, deleteReason, getReason } from '../../workspace';

export const useCreateReason = () => {
  let queryClient = useQueryClient();
  return useMutation(createReason, {
    onSuccess: () => queryClient.invalidateQueries(['get-reason'])
  });
};

export const useGetReason = (inboxId: string) => {
  return useQuery(['get-reason', inboxId], () => getReason(inboxId), { enabled: inboxId !== null });
};

export const useDeleteReason = () => {
  let queryClient = useQueryClient();
  return useMutation(deleteReason, {
    onSuccess: () => queryClient.invalidateQueries(['get-reason'])
  });
};
