import { FormInstance } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { NativeLoginWordingEnum } from '../../static/native-login';

type LinkForgotPasswordProps = {
  form: FormInstance;
  onError: (msg: string) => void;
};

const LinkForgotPassword: React.FC<LinkForgotPasswordProps> = ({ form, onError }) => {
  const navigate = useNavigate();

  const handleForgotPassword = async (e: React.MouseEvent) => {
    e.preventDefault();

    let errorFields: { name: string; errors: string[] }[] = [];

    await form
      .validateFields()
      .then()
      .catch((val) => {
        errorFields = val.errorFields;
      });

    const emailError = errorFields.find((e: any) => e.name[0] === 'email')!;

    if (!emailError) {
      navigate('/reset-password', { state: { userEmail: form.getFieldValue('email') } });
      return;
    }

    onError(emailError.errors[0]);
  };

  return (
    <Link
      data-testid="forgot-password"
      to="/reset-password"
      className="text-14 mb-[24px] text-blue-500"
      onClick={handleForgotPassword}>
      {NativeLoginWordingEnum.forgotPassword}
    </Link>
  );
};

export default LinkForgotPassword;
