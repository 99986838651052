import { useState } from 'react';
import { Avatar, Button, ConfigProvider, message, notification } from 'antd';
import { DownOutlined, DownloadOutlined, UpOutlined } from '@ant-design/icons';
import { TimestampToStringDateAndTime } from '../../../utils/timestamp';
import ChatIcon from '../../../assets/icons/chat';
import { CommunicationTypeEnum } from '../../../static/communication-type';
import { useConversationSummary } from '../../../api/hooks/workspace/useWorkspaceSummary';
import { HistoryCardProps } from '../../form/workspace-customer-files/history/workspace-customer-files-history.interface';
import VideoIcon from '../../../assets/icons/video';
import { getVideoRecordingUrl } from '../../../api/workspace';
import { downloadFileFromUrl } from '../../../config/file-api-handler';
import { downloadChat } from '../../form/workspace-customer-files/history/workspace-customer-files-history.helper';
import { contactConversationHistory } from '../../../static/routeConstructor';
import EditSummaryModal from '../../modal/edit-summary-modal';

const HistoryItem = (props: HistoryCardProps) => {
  const { communicationType, createdAt, agent, conversationId, contact, inbox } = props;
  const [loadingDownloadConv, setLoadingDownloadConv] = useState<Record<string, any>>({});
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [isEditModalShown, setEditModalShown] = useState(false);
  const [loadingNotification, loadingNotifContext] = message.useMessage();
  const key = 'updatable';

  const toggleExpanded = () => setIsExpanded((prev) => !prev);
  const { data: summary } = useConversationSummary(conversationId, isExpanded);

  const isChat = communicationType === CommunicationTypeEnum.CHAT;
  const agentName = `${agent?.firstName || '-'} ${agent?.lastName || ''}`;
  const inboxId = inbox?.inboxId || '';

  const handleDownloadChat = () => downloadChat(conversationId, createdAt, contact.firstName);
  const handleDownloadVideo = async () => {
    setLoadingDownloadConv((prev) => ({ ...prev, [conversationId]: true }));

    try {
      const res = await getVideoRecordingUrl(conversationId);
      const { downloadUrl, filename, message } = res.data || {};
      if (downloadUrl) {
        loadingNotification.open({
          key,
          type: 'loading',
          content: 'Sedang mengunduh video...',
          duration: 0
        });
        await downloadFileFromUrl(downloadUrl, filename);
        loadingNotification.open({
          key,
          type: 'success',
          content: 'Unduh video selesai',
          duration: 2
        });
      } else if (message) notification.info({ message });
    } catch (error: any) {
      notification.error({
        message: error?.response?.data?.message || 'Failed to download video recording.'
      });
    }
    setLoadingDownloadConv((prev) => ({ ...prev, [conversationId]: false }));
  };

  return (
    <>
      {loadingNotifContext}
      <EditSummaryModal
        conversationId={conversationId}
        open={isEditModalShown}
        setOpen={setEditModalShown}
        data={summary}
        inboxId={inboxId}
      />
      <div className="w-full items-start space-y-4 rounded-2xl border-x-0 p-2">
        <div className="flex items-start justify-between">
          <div className="flex items-center space-x-2">
            <Avatar
              size={36}
              className={`flex flex-row justify-center ${
                isChat ? 'bg-emerald-light-green' : 'bg-blue-glaucaus'
              }`}
              icon={isChat ? <ChatIcon /> : <VideoIcon />}
            />
            <div className="flex-1">
              <a
                className="cursor-pointer text-sm text-green-emerald underline"
                href={contactConversationHistory({
                  inboxId,
                  conversationId,
                  contactId: contact?.id!!
                })}
                target="_blank"
                rel="noreferrer">
                ID #{conversationId?.substring(conversationId?.length - 5)}
              </a>
              <div className="space-x-2 text-xs xl:text-base">
                <span>{TimestampToStringDateAndTime(new Date(createdAt))}</span>
                <span>-</span>
                <span className="font-semibold capitalize">{agentName}</span>
              </div>
            </div>
          </div>
          <div data-testid="button-expand" className="cursor-pointer pt-1" onClick={toggleExpanded}>
            {isExpanded ? <UpOutlined /> : <DownOutlined />}
          </div>
        </div>
        {isExpanded && (
          <div className="space-y-4">
            <div>
              <div className="flex justify-between">
                <div className="text-sm font-semibold max-xl:text-xs">Topik</div>
                <button
                  onMouseDown={() => setEditModalShown(true)}
                  className="cursor-pointer border-none bg-transparent text-sm font-semibold text-green-emerald max-xl:text-xs">
                  Edit
                </button>
              </div>
              <div className="break-words text-sm max-xl:text-xs">{summary?.topic || '-'}</div>
            </div>
            <div>
              <div className="text-sm font-semibold max-xl:text-xs">Catatan</div>
              <div className="break-words text-sm max-xl:text-xs">{summary?.note || '-'}</div>
            </div>
            <ConfigProvider
              theme={{
                components: {
                  Button: { colorPrimaryHover: '#2DB089', colorPrimaryActive: '#2DB089' }
                }
              }}>
              <div>
                <Button
                  data-testid="button-download-chat"
                  className="w-full rounded-3xl"
                  onClick={handleDownloadChat}>
                  Unduh Percakapan <DownloadOutlined />
                </Button>
              </div>
              {!isChat && (
                <div>
                  <Button
                    onClick={handleDownloadVideo}
                    className="w-full rounded-3xl"
                    disabled={loadingDownloadConv[conversationId]}>
                    Unduh Video <DownloadOutlined />
                  </Button>
                </div>
              )}
            </ConfigProvider>
          </div>
        )}
        <div className="border-x-0 border-b border-t-0 border-solid border-grey-50" />
      </div>
    </>
  );
};

export default HistoryItem;
