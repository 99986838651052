export enum CommunicationTypeEnum {
  CHAT = 'CHAT',
  VIDEO = 'AUDIO_VIDEO',
  VOICE = 'VOICE_CALL',
  CHAT_INA = 'Chat',
  VIDEO_INA = 'Panggilan video'
}

export enum ConversationContextEnum {
  GENERAL = 'GENERAL',
  KYC = 'MANUAL_KYC',
  GENERAL_INA = 'Umum',
  KYC_INA = 'Manual KYC'
}

export enum AccountIdentityEnum {
  EMAIL = 'EMAIL',
  PHONE = 'PHONE_NUMBER',
  EMAIL_INA = 'Email',
  PHONE_INA = 'Nomor HP'
}

export enum WebhookIdentifierEnum {
  NONE = 'NONE',
  SMMF_MANUAL_KYC = 'SMMF_MANUAL_KYC',
  NONE_INA = 'Tidak ada',
  SMMF_MANUAL_KYC_INA = 'SMMF Manual KYC',
  ASJ_MANUAL_KYC = 'ASJ_MANUAL_KYC',
  ASJ_MANUAL_KYC_INA = 'ASJ Manual KYC',
  BSIM_MANUAL_KYC = 'BSIM_MANUAL_KYC',
  BSIM_MANUAL_KYC_INA = 'BSIM Manual KYC'
}

export enum CredentialEnum {
  NONE = 'NONE',
  SMMF_MANUAL_KYC = 'SMMF_MANUAL_KYC',
  NONE_INA = 'Tidak ada',
  SMMF_MANUAL_KYC_INA = 'SMMF Manual KYC',
  ASJ_MANUAL_KYC = 'ASJ_MANUAL_KYC',
  ASJ_MANUAL_KYC_INA = 'ASJ Manual KYC'
}

export enum AllowTakePicEnum {
  SWITCH_CAMERA_ON = 'SWITCH_CAMERA_ON',
  SWITCH_CAMERA_OFF = 'SWITCH_CAMERA_OFF'
}

export enum defaultCommunicationTypeEnum {
  AUDIO = 'VOICE_CALL',
  VIDEO = 'VIDEO_CALL',
  AUDIO_VIDEO = 'AUDIO_VIDEO',
  AUDIO_INA = 'Hanya panggilan suara',
  VIDEO_INA = 'Hanya panggilan video',
  AUDIO_VIDEO_INA = 'Panggilan suara + video'
}

export const conversationCtxOptions = [
  { label: ConversationContextEnum.GENERAL_INA, value: ConversationContextEnum.GENERAL },
  { label: ConversationContextEnum.KYC_INA, value: ConversationContextEnum.KYC }
];

export const webhookIdentifierOptions = [
  {
    label: WebhookIdentifierEnum.NONE_INA,
    value: WebhookIdentifierEnum.NONE
  },
  {
    label: WebhookIdentifierEnum.SMMF_MANUAL_KYC_INA,
    value: WebhookIdentifierEnum.SMMF_MANUAL_KYC
  },
  {
    label: WebhookIdentifierEnum.ASJ_MANUAL_KYC_INA,
    value: WebhookIdentifierEnum.ASJ_MANUAL_KYC
  },
  {
    label: WebhookIdentifierEnum.BSIM_MANUAL_KYC_INA,
    value: WebhookIdentifierEnum.BSIM_MANUAL_KYC
  }
];

export const credentialOptions = [
  {
    label: WebhookIdentifierEnum.NONE_INA,
    value: WebhookIdentifierEnum.NONE
  },
  {
    label: WebhookIdentifierEnum.SMMF_MANUAL_KYC_INA,
    value: WebhookIdentifierEnum.SMMF_MANUAL_KYC
  },
  {
    label: WebhookIdentifierEnum.ASJ_MANUAL_KYC_INA,
    value: WebhookIdentifierEnum.ASJ_MANUAL_KYC
  },
  {
    label: WebhookIdentifierEnum.BSIM_MANUAL_KYC_INA,
    value: WebhookIdentifierEnum.BSIM_MANUAL_KYC
  }
];

export const accountIdentityOptions = [
  {
    label: AccountIdentityEnum.EMAIL_INA,
    value: AccountIdentityEnum.EMAIL
  },
  {
    label: AccountIdentityEnum.PHONE_INA,
    value: AccountIdentityEnum.PHONE
  }
];

export const communicationModeOptions = (isManualKyc: boolean) => [
  {
    label: CommunicationTypeEnum.CHAT_INA,
    value: CommunicationTypeEnum.CHAT,
    disabled: isManualKyc
  },
  {
    label: CommunicationTypeEnum.VIDEO_INA,
    value: CommunicationTypeEnum.VIDEO
  }
];

export const defaultCommunicationTypeOptions = (isManualKyc: boolean) => [
  {
    label: defaultCommunicationTypeEnum.AUDIO_INA,
    value: defaultCommunicationTypeEnum.AUDIO,
    disabled: isManualKyc
  },
  { label: defaultCommunicationTypeEnum.VIDEO_INA, value: defaultCommunicationTypeEnum.VIDEO },
  {
    label: defaultCommunicationTypeEnum.AUDIO_VIDEO_INA,
    value: defaultCommunicationTypeEnum.AUDIO_VIDEO,
    disabled: isManualKyc
  }
];

export const confirmModalPropsKYCVideo = {
  title: 'Akhiri Video',
  description: 'Yakin ingin menutup panggilan video dengan pelanggan?',
  okText: 'Akhiri Panggilan Video',
  cancelText: 'Kembali ke panggilan'
};

export const confirmModalPropsKYCTicket = {
  title: 'Akhiri Tiket',
  description: 'Pastikan Anda sudah memiliki semua informasi yang dibutuhkan?',
  okText: 'Akhiri Tiket',
  cancelText: 'Kembali ke panggilan'
};

export const confirmModalPropsGE = {
  title: 'Akhiri Panggilan Video',
  voiceCall: 'Akhiri Panggilan Suara',
  description: 'Anda yakin ingin mengakhiri panggilan video dengan pelanggan ?',
  okText: 'Akhiri Panggilan Video',
  cancelText: 'Kembali ke panggilan'
};

export const confirmModalEndSession = {
  title: 'Akhiri Sesi?',
  description: 'Sesi akan diakhiri dan tiket pelanggan akan ditutup.',
  okText: 'Akhiri',
  cancelText: 'Batalkan'
};

export const confirmModalCustomerDisconnected = {
  title: 'Apakah sesi dengan Dimas Aditya masih belangsung?',
  okText: 'Akhiri Sesi',
  cancelText: 'Masih Berlangsung'
};

//description for confirmModalCustomerDisconnected
export const confirmMinutesDescription = (minutes: number, name: string) => {
  return `Tidak ada aktivitas percakapan dengan ${name} selama ${minutes} menit. Apakah Anda masih melayani pelanggan ini?`;
};

export enum SenderType {
  AGENT = 'AGENT',
  CONTACT = 'CONTACT'
}
export const confirmModalChangeCard = {
  title: 'Anda yakin meinggalkan percakapan ini?',
  description: 'Hasil screenshoot akan hilang',
  okText: 'Ya, saya yakin',
  cancelText: 'Batalkan'
};
