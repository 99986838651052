import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { PasswordStatus, User } from '../../../types/new/auth';
import { Account } from '../../../types/new/account';
import { AuthStoreInterface, Provider } from './interface';
import { local } from '../../../utils/storage';
import { LocalStorageKey } from '../../../static/local-storage';
import { getCookie } from '../../../utils/cookie-management';
import { CookieNameEnum } from '../../../static/cookie-management';
import { LoginPlatform } from '../../../shared/types/auth.interface';

const defaultUser: User = {
  agentId: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  active: false,
  employeeId: '',
  role: '',
  supervisor: '',
  manager: '',
  photo: '',
  photoUrl: ''
};

const defaultAccount: Account = {
  accountId: '',
  accountName: ''
};

const defaultPasswordStatus: PasswordStatus = {
  isPasswordExpired: false,
  shouldUpdatePassword: false,
  daysBeforeExpired: 0,
  maxExpiredDays: 0,
};

const initialValue = {
  user: defaultUser,
  account: defaultAccount,
  passwordStatus: defaultPasswordStatus
};

export const useAuthStore = create<AuthStoreInterface, any>(
  persist(
    (set, get) => ({
      ...initialValue,
      authProvider: LoginPlatform.LOADING,
      prevUserList: [],
      getUserPhoto: () => get().user.photoUrl,
      setUserPhoto: (photoUrl: string) => set({ user: { ...get().user, photoUrl } }),
      setUser: (user: User) => set({ user }),
      setAccount: (account: Account) => set({ account }),
      setPrevUserList: (prevUserList: User[]) => set({ prevUserList }),
      appendPrevUserList: (user: User) => {
        const isUserExist = get().prevUserList.find((u) => u.email === user.email);
        if (isUserExist)
          set({
            prevUserList: [user, ...get().prevUserList.filter((u) => u.agentId !== user.agentId)]
          });
        else return set({ prevUserList: [user, ...get().prevUserList] });
      },
      setAuthProvider: (authProvider: Provider) => set({ authProvider }),
      setIsPasswordExpired: (isPasswordExpired: boolean) =>
        set((state) => ({ passwordStatus: { ...state.passwordStatus, isPasswordExpired } })),
      setShouldUpdatePassword: (shouldUpdatePassword: boolean) =>
        set((state) => ({ passwordStatus: { ...state.passwordStatus, shouldUpdatePassword } })),
      setDaysBeforeExpired: (daysBeforeExpired: number) =>
        set((state) => ({ passwordStatus: { ...state.passwordStatus, daysBeforeExpired } })),
      setMaxExpiredDays: (maxExpiredDays: number) =>
        set((state) => ({ passwordStatus: { ...state.passwordStatus, maxExpiredDays } })),
      isSQEIDLoggedIn: () => {
        const tokenExist = local.getItem(LocalStorageKey.SQEID_TOKEN_OBJECT);
        return !!tokenExist;
      },
      isNativeLoggedIn: () => {
        const tokenExist = getCookie(CookieNameEnum.NATIVE_TOKEN);
        return !!tokenExist;
      },
      reset: () => set(initialValue)
    }),
    { name: 'auth-store' }
  )
);
