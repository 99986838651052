import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import SearchDropDown from './search-dropdown';
import { searchFilterInterface } from './filter.interface';
import { useEffect, useState } from 'react';
import { ItemKey } from '../../static/search-filter';
import { InputProps } from 'antd/lib/input';

const SearchFilter = (props: searchFilterInterface) => {
  const { setEmail, setTicketNumber, setPhoneNumber, setName, items, value } = props;
  const [selectedSearch, setSelectedSearch] = useState<number>(0);
  const resetFields = () => {
    setEmail('');
    setTicketNumber('');
    setPhoneNumber('');
    setName('');
  };

  const setField = (key: ItemKey, value: string) => {
    switch (key) {
      case ItemKey.EMAIL:
        setEmail(value);
        break;
      case ItemKey.TICKET_NUMBER:
        setTicketNumber(value);
        break;
      case ItemKey.PHONE_NUMBER:
        setPhoneNumber(value);
        break;
      case ItemKey.NAME:
        setName(value);
        break;
      default:
        break;
    }
  };

  const handleOnchange: InputProps['onChange'] = (e) => {
    e.preventDefault();
    resetFields();
    setField(selectedSearch, e.target.value);
  };

  useEffect(() => {
    resetFields();
    setField(selectedSearch, value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSearch]);

  return (
    <div className="flex h-10 w-full items-center rounded-2xl border border-solid border-grey-50">
      <Input
        value={value}
        allowClear
        onChange={handleOnchange}
        placeholder="cari"
        variant="borderless"
        prefix={<SearchOutlined className="text-grey-80" />}
      />
      <SearchDropDown
        items={items}
        selectedSearch={selectedSearch}
        setSelectedSearch={setSelectedSearch}
      />
    </div>
  );
};
export default SearchFilter;
