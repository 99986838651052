import { datadogLogs } from '@datadog/browser-logs';

const { REACT_APP_DATADOG_RUM_APP_ID } = process.env;

enum LogType {
  INFO = 'info',
  ERROR = 'error'
}
type LogData = {
  host: string;
  path: string;
  method: string;
  api_path: string;
  status_code?: string | number;
  request_body?: any;
};

type LogCommon = {
  message: string;
  data: any;
};

export const isDatadogLogEnabled =
  REACT_APP_DATADOG_RUM_APP_ID && REACT_APP_DATADOG_RUM_APP_ID !== '';

export const sendLog = (logType: LogType, description: string, data: LogData) => {
  const { host, path, method, api_path, status_code, request_body } = data;
  datadogLogs.logger[logType](description, {
    host,
    http: {
      url_details: { path, method },
      api_path,
      status_code,
      request_body
    }
  });
};

export const sendCommonLog = (logType: LogType, description: string, data: LogCommon) => {
  datadogLogs.logger[logType](description, data);
};

export const sendLogInfo = (description: string, data: LogData) =>
  sendLog(LogType.INFO, description, data);

export const sendLogError = (description: string, data: LogData) =>
  sendLog(LogType.ERROR, description, data);

export const sendLogCommonError = (description: string, data: LogCommon) =>
  sendCommonLog(LogType.ERROR, description, data);
