import { ArrowDownOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import TabFilter from '../../../components/filter/tab-filter';
import Table from '../../../components/table/table';

import {
  ActiveTablist,
  ActivityTabEnum,
  GENERAL_INQUIRY_ACTIVITY_COLUMNS,
  MANUAL_KYC_ACTIVITY_COLUMNS,
  AgentListColumns
} from '../../../static/analysis';
import { useEffect, useMemo, useState } from 'react';
import { useCallStore } from '../../../store/call-state';
import {
  downloadAgentCustActivityAnalytics,
  getAgentActivityAnalytics,
  getCustomerActivityAnalytics
} from '../../../api/analytics';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

type Props = { filterValues: Record<string, any> };
const USER_ACTIVITY_REPORT_FILE = 'user-activity-report.xlsx';

const UserActivity = (props: Props) => {
  const { filterValues } = props;
  const [activeTab, setActiveTab] = useState<string>(ActivityTabEnum.AGENT);
  const [resetPagination, setResetPagination] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [filter, setFilter] = useState<Record<string, any>>(filterValues);

  const { isManualKYCMode } = useCallStore();
  const { inboxId } = useParams();

  const handleOnSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setFilter!({ ...filterValues, q: e.target.value });
  };

  useEffect(() => {
    setResetPagination(!resetPagination);
  }, [activeTab]);

  const { activityCustomerColumns } = useMemo(() => {
    return {
      activityCustomerColumns: isManualKYCMode
        ? MANUAL_KYC_ACTIVITY_COLUMNS
        : GENERAL_INQUIRY_ACTIVITY_COLUMNS
    };
  }, [isManualKYCMode]);

  const activeActivityTab =
    activeTab === ActivityTabEnum.AGENT ? AgentListColumns : activityCustomerColumns;

  const { data: customerListData } = useQuery(
    ['customerListData', filter, inboxId],
    () => getCustomerActivityAnalytics(String(inboxId), filter),
    {
      enabled: !!inboxId && activeTab === ActivityTabEnum.CUSTOMER
    }
  );

  const { data: agentListData } = useQuery(
    ['agentListData', filter, inboxId],
    () => getAgentActivityAnalytics(String(inboxId), filter),
    {
      enabled: !!inboxId && activeTab === ActivityTabEnum.AGENT,
      retry: 0
    }
  );

  const data = activeTab === ActivityTabEnum.AGENT ? agentListData?.data : customerListData?.data;
  const metadata =
    activeTab === ActivityTabEnum.AGENT ? agentListData?.metadata : customerListData?.metadata;

  useEffect(() => {
    setFilter(filterValues);
  }, [filterValues]);

  useEffect(() => {
    const startDate = filterValues['start'];
    const endDate = filterValues['end'];

    setFilter({
      start: startDate,
      end: endDate
    });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeActivityTab]);

  const downloadUserActivityData = async () => {
    await downloadAgentCustActivityAnalytics(String(inboxId), filter, USER_ACTIVITY_REPORT_FILE);
  };

  return (
    <div className="rounded-xl border border-solid border-grey-80 bg-white p-4">
      <div className="flex items-center justify-between px-4">
        <h2>Aktivitas</h2>
        <Button
          onClick={downloadUserActivityData}
          size="large"
          type="primary"
          className="flex w-max items-center justify-center rounded-lg border-black bg-white font-semibold text-black-90">
          <span className="mr-4 flex h-6 w-6 items-center justify-center overflow-hidden rounded-full border border-solid border-black-90">
            <ArrowDownOutlined className="bg-white" />
          </span>
          Unduh
        </Button>
      </div>
      <div className="flex justify-between p-4">
        <TabFilter Tablist={ActiveTablist} setSelectedTab={setActiveTab} selectedTab={activeTab} />
        <div className="flex h-10 w-[483px] items-center rounded-2xl border border-solid border-grey-50">
          <Input
            value={search}
            allowClear
            onChange={handleOnSearch}
            placeholder="Cari"
            variant="borderless"
            prefix={<SearchOutlined className="text-grey-80" />}
          />
        </div>
      </div>
      <Table
        filter={{
          filtering: search,
          setFiltering: setSearch,
          filterData: filter,
          setFilterData: setFilter
        }}
        headerColumns={activeActivityTab}
        data={data || []}
        totalData={metadata?.totalData}
        showPagination
        resetPagination={resetPagination}
      />
    </div>
  );
};

export default UserActivity;
