import {
  Flex,
  Typography,
  Modal,
  Form,
  Row,
  Col,
  Input,
  Select,
  Radio,
  RadioChangeEvent,
  Space,
  Button,
  FormInstance,
  message
} from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { roleOptions } from '../team-users.interface';
import { useEffect, useState } from 'react';
import { useUserStatusStore } from '../../../store/user-state';
import { RoleEnum } from '../../../static/role';
import { CurrentUserObjectInterface } from '../../../shared/types/user.interface';
import { useInvUserNATIVE } from '../../../api/hooks/native/useInviteUser';
import { useGetAllSPV } from '../../../api/hooks/native/useGetSPV';
import { useCheckUsernameNATIVE } from '../../../api/hooks/native/useCheckUsername';
import useDebounce from '../../../utils/debounce';
import PhoneInput from '../phone-input';
import { removeLeadingZero } from '../../../utils/phone-codes';

const { Title } = Typography;

export const LoginOptionsEnum = {
  EMAIL: 'email',
  USERNAME: 'username'
};

export const SendLoginOptionsEnum = {
  EMAIL_AGENT: 'email',
  WA_AGENT: 'whatsapp',
  EMAIL_SUPERVISOR: 'email_spv'
};

interface InvUserModalInterface {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsModalConfirmOpen: React.Dispatch<React.SetStateAction<boolean>>;
  form: FormInstance<any>;
  organizationId: string;
  selectedBU:
    | {
        key: string;
        label: string;
      }
    | undefined;
}

const InvUsersModal = ({
  setIsModalConfirmOpen,
  setIsModalOpen,
  isModalOpen,
  form,
  organizationId,
  selectedBU
}: InvUserModalInterface) => {
  const { mutateAsync: invUserNATIVE } = useInvUserNATIVE();

  const [checkUsername, setCheckUsername] = useState<string>('');
  const debouncedCheckUsername = useDebounce(checkUsername, 500);
  const { isError: isUsernameTaken } = useCheckUsernameNATIVE(debouncedCheckUsername);

  const { userRole } = useUserStatusStore();
  const [currentUser] = useState<CurrentUserObjectInterface>(
    JSON.parse(localStorage.getItem('user') || '{}')
  );

  const accountId = userRole === RoleEnum.ADMIN ? organizationId : currentUser?.account?.accountId;
  const { data: spvData } = useGetAllSPV(accountId!);
  const isCurrentUserSPV = userRole === RoleEnum.SUPERVISOR;
  const [notification, contextHolder] = message.useMessage();

  const role = Form.useWatch('role', form);

  useEffect(() => {
    form.setFieldsValue({
      business_unit:
        userRole === RoleEnum.ADMIN ? selectedBU?.label : currentUser?.account?.accountName
    });
  }, [isModalOpen]);

  const [loginOption, setLoginOption] = useState<string>(LoginOptionsEnum.EMAIL);
  const [sendLoginOption, setSendLoginOption] = useState<string>(SendLoginOptionsEnum.EMAIL_AGENT);

  const transformEmailKey = (data: any[]) => {
    return data?.map(({ email: label, email: value, ...rest }: { email: string }) => ({
      value,
      label,
      ...rest
    }));
  };

  const onChangeLoginOption = (e: RadioChangeEvent) => {
    setLoginOption(e.target.value);
  };

  const onChangeSendLoginOption = (e: RadioChangeEvent) => {
    setSendLoginOption(e.target.value);
  };

  const filterOption = (input: string, option?: { label: string; value: string }) => {
    return (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  };

  const onFinish = async (values: any) => {
    let {
      login_settings,
      email,
      username,
      phone_code,
      phone_number,
      login_options,
      spv_email,
      name
    } = values;

    if (login_settings === 'email' || login_settings === 'email_spv') {
      login_settings = 'email';
    }

    const payload = {
      username: email ?? username,
      role: role,
      accountId: isCurrentUserSPV ? currentUser?.account?.accountId ?? '' : organizationId,
      phoneNo: `${phone_code}${removeLeadingZero(phone_number)}`,
      fullName: name,
      loginOptions: login_options,
      notificationType: login_settings,
      notificationReceivers: spv_email
    };

    try {
      await invUserNATIVE(payload);
      setIsModalOpen(false);
      setIsModalConfirmOpen(true);
    } catch (error) {
      notification.error({ content: 'Error inviting user' });
    } finally {
      form.resetFields();
      setLoginOption(LoginOptionsEnum.EMAIL);
    }
  };

  const _renderSendLoginOptions = (loginOption: string, sendLoginOptions: string) => {
    const isEmailLoginOptions = loginOption === LoginOptionsEnum.EMAIL;
    const isSendLoginOptionsSPV = sendLoginOptions === SendLoginOptionsEnum.EMAIL_SUPERVISOR;

    return (
      <>
        <Col className="gutter-row" span={8}>
          <Form.Item
            className="mb-0"
            name={`${isEmailLoginOptions ? 'email' : 'username'}`}
            rules={[{ required: true, message: 'Username/Email wajib diisi' }]}
            label={
              <Title className="m-0 text-base font-semibold">{`${
                isEmailLoginOptions ? 'Email' : 'Username'
              }`}</Title>
            }>
            <Input
              onChange={(e) => {
                setCheckUsername(e.target.value);
              }}
              placeholder={`Masukkan ${isEmailLoginOptions ? 'Email' : 'Username'}`}
            />
          </Form.Item>
          <p className="m-1 p-0 text-red-500">
            {isUsernameTaken ? 'Username/Email sudah terdaftar' : ''}
          </p>
        </Col>
        <Col className="gutter-row" span={8}>
          <Form.Item
            initialValue={sendLoginOption}
            name="login_settings"
            rules={[{ required: true, message: 'Kirim Pengaturan Login wajib diisi' }]}
            label={
              <Title className="m-0 text-base font-semibold">Kirim Pengaturan Login ke</Title>
            }>
            <Radio.Group value={sendLoginOption} onChange={onChangeSendLoginOption}>
              <Space direction="vertical">
                {isEmailLoginOptions && (
                  <Radio value={'email'}>Email {`${role === RoleEnum.AGENT ? 'Agen' : ''}`}</Radio>
                )}
                <Radio value={'whatsapp'}>{`${
                  isCurrentUserSPV ? 'WhatsApp Agen' : 'WhatsApp'
                }`}</Radio>
                {role === RoleEnum.AGENT && <Radio value={'email_spv'}>Email Supervisor</Radio>}
              </Space>
            </Radio.Group>
          </Form.Item>
          {isSendLoginOptionsSPV && (
            <Form.Item
              name="spv_email"
              rules={[{ required: true, message: 'Email Supervisor wajib diisi' }]}>
              <Select
                filterOption={filterOption}
                showSearch
                mode="multiple"
                options={transformEmailKey(spvData)}
              />
            </Form.Item>
          )}
        </Col>
      </>
    );
  };

  return (
    <>
      {contextHolder}
      <Modal
        width={1200}
        footer={
          <Form
            className="mt-10"
            name="invite_user"
            layout="vertical"
            form={form}
            autoComplete="off"
            onFinish={onFinish}
            size="large">
            <Flex justify="end" gap="middle">
              <Form.Item>
                <Button
                  onClick={() => {
                    setIsModalOpen(false);
                    form.resetFields();
                  }}
                  className={`h-10 w-32 rounded-lg border-2 border-solid border-green-500 text-base font-semibold text-green-500`}>
                  Batal
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  disabled={isUsernameTaken}
                  form="invite_user"
                  key="submit"
                  htmlType="submit"
                  className={`h-10 w-32 rounded-lg border-none ${
                    isUsernameTaken ? 'cursor-not-allowed bg-gray-500' : 'bg-green-500'
                  } text-base font-semibold text-white`}>
                  Submit
                </Button>
              </Form.Item>
            </Flex>
          </Form>
        }
        title={
          <Flex justify="space-between" align="center">
            <Title className="m-0 p-0" level={3}>
              Undang User
            </Title>
            <CloseOutlined data-testid="close-btn-modal" onClick={() => setIsModalOpen(false)} />
          </Flex>
        }
        closable={false}
        open={isModalOpen}
        onOk={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}>
        <Form
          className="mt-10"
          name="invite_user"
          layout="vertical"
          form={form}
          autoComplete="off"
          size="large">
          <Title className="p-0 font-semibold" level={4}>
            Informasi Dasar
          </Title>
          <Row gutter={16}>
            <Col className="gutter-row" span={8}>
              <Form.Item
                name="name"
                rules={[{ required: true, message: 'Nama wajib diisi' }]}
                label={<Title className="m-0 text-base font-semibold">Nama</Title>}>
                <Input placeholder="Masukkan Nama" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <PhoneInput form={form} />
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item
                name="role"
                initialValue={'AGENT'}
                rules={[{ required: true, message: 'Jabatan wajib diisi' }]}
                label={<Title className="m-0 text-base font-semibold">Jabatan</Title>}>
                <Select disabled={isCurrentUserSPV} options={roleOptions} />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item
                name="business_unit"
                rules={[{ required: true, message: 'Bisnis Unit wajib diisi' }]}
                label={<Title className="m-0 text-base font-semibold">Bisnis Unit</Title>}>
                <Select disabled />
              </Form.Item>
            </Col>
          </Row>
          <Title className="p-0 font-semibold" level={4}>
            Pengaturan Login
          </Title>
          <Row gutter={16}>
            <Col className="gutter-row" span={8}>
              <Form.Item
                initialValue={LoginOptionsEnum.EMAIL}
                name="login_options"
                rules={[{ required: true, message: 'Login Menggunakan wajib diisi' }]}
                label={<Title className="m-0 text-base font-semibold">Login Menggunakan</Title>}>
                <Radio.Group value={loginOption} onChange={onChangeLoginOption}>
                  <Space direction="vertical">
                    <Radio value={'email'}>Email</Radio>
                    <Radio value={'username'}>Username</Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
            </Col>
            {_renderSendLoginOptions(loginOption, sendLoginOption)}
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default InvUsersModal;
