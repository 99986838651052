import { useCallback, useState } from 'react';
import { Button, Input, notification, Pagination, Select } from 'antd';
import DateFilter, { DEFAULT_DATE_RANGE } from '../../components/filter/date-filter';
import { getUserJSON } from '../../utils/user-json';
import { DownOutlined, DownloadOutlined, SearchOutlined } from '@ant-design/icons';
import { AUDIT_TRAIL_ACTION_TYPE_ENUM, AUDIT_TRAIL_TABLE_COLUMNS } from '../../static/audit-trail';
import Table from '../../components/commons/Table';
import { useQuery } from 'react-query';
import { fetchAccountList } from '../../api';
import { RoleEnum } from '../../static/role';
import { fetchAuditTrail, downloadAuditTrail } from '../../api/audit-trail';
import { debounce } from '../../utils/rate-limit';
import { downloadFromBlob } from '../../utils/file';
import { toSentenceCase } from '../../utils/formatter';

export const AuditTrail = () => {
  const DATE_FILTER_FORMAT = 'YYYY-MM-DDTHH:mm:ss.sssZ';
  const { account, role } = getUserJSON();
  const isAdmin = role === RoleEnum.ADMIN;
  const [selectedAccount, setSelectedAccount] = useState(account?.accountId || '');
  const [filters, setFilters] = useState({
    'page-no': 1,
    'page-size': 10,
    startDate: DEFAULT_DATE_RANGE[0].format(DATE_FILTER_FORMAT),
    endDate: DEFAULT_DATE_RANGE[1].format(DATE_FILTER_FORMAT),
    email: '',
    action: ''
  });

  const { data: accountList = [] } = useQuery('accountList', () => fetchAccountList(), {
    enabled: isAdmin,
    select: (res) => res.data,
    onSuccess: (data) => isAdmin && setSelectedAccount(data[0]?.accountId)
  });
  const { refetch: handleDownload, isFetching: isDownloading } = useQuery(
    'downloadAuditTrail',
    () => {
      const { 'page-no': _, 'page-size': __, ...rest } = filters;
      return downloadAuditTrail(selectedAccount, rest);
    },
    {
      enabled: false,
      onSuccess: (blob) => downloadFromBlob(blob, `report-audit-trail.xlsx`),
      onError: (error: any) => {
        notification.error({
          message: error?.response?.data?.message || 'Gagal memuat data',
          description: `Status: ${error?.response?.status}`
        });
      }
    }
  );

  const { data: auditTrailData, isLoading: isLoadingAuditTrail } = useQuery(
    ['auditTrailData', selectedAccount, filters],
    () => fetchAuditTrail(selectedAccount || '', filters),
    {
      onError: (error: any) => {
        notification.error({
          message: error?.response?.data?.message || 'Gagal memuat data',
          description: `Status: ${error?.response?.status}`
        });
      }
    }
  );

  const auditTrailList = auditTrailData?.data || [];
  const auditTrailTotalData = auditTrailData?.count || 0;

  const accountOptions = (accountList || []).map((item) => ({
    label: <span>{item.name}</span>,
    value: item.accountId
  }));

  const activityFilterOptions = [
    {
      label: <span>Filter Aktivitas</span>,
      value: ''
    },
    ...Object.values(AUDIT_TRAIL_ACTION_TYPE_ENUM).map((item) => ({
      label: <span>{toSentenceCase(item)}</span>,
      value: item
    }))
  ];
  const sizeChangerOptions = [
    { label: '10', value: 10 },
    { label: '20', value: 20 },
    { label: '50', value: 50 },
    { label: '100', value: 100 }
  ];

  /**
   * handle filter options for select Account and Inbox list
   * @param input
   * @param option
   * @returns
   */
  const handleFilterOptions = (input: string, option?: Record<string, any>) =>
    String(option?.label?.props?.children).toLowerCase().includes(input.toLowerCase());
  const handleFilterEmail = useCallback(
    debounce((value: string) => {
      setFilters((prev) => ({ ...prev, email: value, 'page-no': 1 }));
    }, 300),
    []
  );

  return (
    <section className="space-y-6 p-6">
      <div className="flex items-center justify-between">
        <h2 className="heading my-0">Riwayat Audit</h2>
        <div className="flex space-x-4">
          {isAdmin && (
            <div className="flex items-center rounded-full border border-solid">
              <Select
                className="min-w-[144px] [&>.ant-select-selector>.ant-select-selection-item]:!font-semibold [&_.ant-select-arrow]:!text-black"
                options={accountOptions}
                optionFilterProp="children"
                showSearch
                variant="borderless"
                value={selectedAccount}
                popupMatchSelectWidth={false}
                suffixIcon={<DownOutlined />}
                filterOption={handleFilterOptions}
                onChange={setSelectedAccount}
              />
            </div>
          )}
          <DateFilter
            startDate={DEFAULT_DATE_RANGE[0]}
            endDate={DEFAULT_DATE_RANGE[1]}
            onChangeDate={(dateValues) => {
              setFilters((prev) => ({
                ...prev,
                startDate: dateValues[0].format(DATE_FILTER_FORMAT),
                endDate: dateValues[1].format(DATE_FILTER_FORMAT),
                'page-no': 1
              }));
            }}
          />
          <Button
            className="h-auto rounded-full border border-solid border-black px-6 font-semibold hover:text-black"
            disabled={isDownloading}
            loading={isDownloading}
            icon={<DownloadOutlined />}
            onClick={() => handleDownload()}>
            <span>Download</span>
          </Button>
        </div>
      </div>
      <div className="flex items-center justify-between">
        <h3 className="heading my-0">List Aktivitas</h3>
        <div className="flex space-x-2">
          <div className="flex items-center rounded-full border border-solid border-black">
            <Select
              className="min-w-[144px] [&>.ant-select-selector>.ant-select-selection-item]:!font-semibold [&_.ant-select-arrow]:!text-black"
              options={activityFilterOptions}
              value={filters.action}
              variant="borderless"
              showSearch
              popupMatchSelectWidth={false}
              suffixIcon={<DownOutlined />}
              filterOption={handleFilterOptions}
              onChange={(value) => setFilters((prev) => ({ ...prev, action: value, 'page-no': 1 }))}
            />
          </div>
          <div className="max-w-[180px] ">
            <Input
              className="rounded-xl"
              placeholder="Cari email"
              prefix={<SearchOutlined className="text-gray-300" />}
              onChange={(e) => handleFilterEmail(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="space-y-4">
        <Table
          columns={AUDIT_TRAIL_TABLE_COLUMNS}
          dataSource={auditTrailList}
          pagination={false}
          loading={isLoadingAuditTrail}
        />
        <div className="flex items-center justify-center space-x-2">
          <span className="text-gray-500">Tampilan baris</span>
          <div className="rounded-xl border border-solid border-gray-300">
            <Select
              variant="borderless"
              options={sizeChangerOptions}
              value={filters['page-size']}
              onChange={(value) =>
                setFilters((prev) => ({ ...prev, 'page-size': value, 'page-no': 1 }))
              }
              popupMatchSelectWidth={false}
            />
          </div>
          <Pagination
            current={filters['page-no']}
            total={auditTrailTotalData}
            showSizeChanger={false}
            pageSize={filters['page-size']}
            onChange={(page, pageSize) => {
              if (page !== filters['page-no']) setFilters((prev) => ({ ...prev, 'page-no': page }));
            }}
          />
        </div>
      </div>
    </section>
  );
};

export default AuditTrail;
