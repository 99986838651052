/* eslint-disable no-console */
import { FormInstance, RadioChangeEvent } from 'antd';
import {
  CommunicationTypeEnum,
  ConversationContextEnum,
  WebhookIdentifierEnum,
  defaultCommunicationTypeEnum,
  conversationCtxOptions,
  credentialOptions,
  webhookIdentifierOptions
} from './communication-type';
import { CreateInboxParam } from '../api/param.interface';
import { AccountInterface } from '../shared/types/account.interface';

export const defaultInboxValues = {
  communicationModes: [CommunicationTypeEnum.CHAT, CommunicationTypeEnum.VIDEO],
  conversationContext: ConversationContextEnum.GENERAL,
  defaultCommunicationType: defaultCommunicationTypeEnum.AUDIO_VIDEO,
  allowTextMessage: true,
  allowMediaCall: true,
  allowAttachment: false,
  allowRefId: false,
  allowConversationSummary: false,
  allowConversationRating: false,
  webhookIdentifier: 'NONE',
  credential: 'NONE',
  showVideoCallButton: true,
  showAudioCallButton: false,
  showScreenShareButton: false,
  showCapturePhoto: false,
  autoConnect: false,
  reminderIdleConversation: true,
  durationReminder: 15,
  longQueueMessage: 'Mohon maaf, Advisor kami masih sibuk. Apakah Anda bersedia menunggu?',
  waitingRoomMessage: 'Mikrofon dan kamera Anda akan aktif untuk berbicara dengan staf kami',
  outsideWorkingHourMessage:
    'Mohon maaf, kami belum dapat melayani Anda di luar jam operasional hari ini.'
};

export const defaultWorkingHourValues = {
  closedAllDay: false,
  openAllDay: false,
  openHour: 9,
  openMinutes: 0,
  closeHour: 17,
  closeMinutes: 0
};

export const mainToggleList = (form: FormInstance<any>) => [
  {
    title: 'allowTextMessage',
    value: 'Mengirim chat/teks',
    formInstance: form,
    event: (checked: boolean) => {
      form.setFieldsValue({ allowTextMessage: checked });
    },
    tooltip: 'Pelanggan dapat mengirim chat atau teks.'
  },
  {
    title: 'allowAttachment',
    value: 'Mengirim lampiran',
    formInstance: form,
    event: (checked: boolean) => form.setFieldsValue({ allowAttachment: checked }),
    tooltip: 'Pelanggan dapat mengirim lampiran berupa gambar atau file.'
  },
  {
    title: 'allowConversationSummary',
    value: 'Meminta ringkasan percakapan',
    formInstance: form,
    event: (checked: boolean) => form.setFieldsValue({ allowConversationSummary: checked }),
    tooltip: 'Pelanggan dapat meminta untuk dikirimkan ringkasan percakapan.'
  },
  {
    title: 'allowConversationRating',
    value: 'Mengirim ulasan & rating',
    formInstance: form,
    event: (checked: boolean) => form.setFieldsValue({ allowConversationRating: checked }),
    tooltip: 'Pelanggan dapat mengirim ulasan dan rating untuk tiap percakapan.'
  },
  {
    title: 'allowRefId',
    value: 'Izinkan Pengiriman ID Referensi',
    formInstance: form,
    event: (checked: boolean) => form.setFieldsValue({ allowRefId: checked }),
    tooltip: 'Tim atau Business Unit dapat mengirimkan data tambahan ke sistem contact center.'
  }
];

export const mainRadioList = (form: FormInstance<any>) => [
  {
    title: 'conversationContext',
    value: 'Tipe percakapan',
    options: conversationCtxOptions,
    event: (e: RadioChangeEvent) => {
      const communicationModes = [
        ...form.getFieldValue('communicationModes'),
        e.target.value === ConversationContextEnum.KYC && CommunicationTypeEnum.VIDEO
      ];
      if (e.target.value !== ConversationContextEnum.KYC) communicationModes.pop();
      form.setFieldsValue({
        conversationContext: e.target.value,
        communicationModes
      });
      if (e.target.value === ConversationContextEnum.KYC) {
        form.setFieldsValue({
          communicationModes: communicationModes.filter((m) => m !== CommunicationTypeEnum.CHAT),
          defaultCommunicationType: defaultCommunicationTypeEnum.VIDEO,
          showVideoCallButton: true
        });
      }
    },
    tooltip: 'Pilih tipe percakapan yang dapat dilakukan oleh tim ini.'
  }
];

export const mainDropdownList = (form: FormInstance<any>) => [
  {
    title: 'webhookIdentifier',
    value: 'Penggunaan Webhook',
    options: webhookIdentifierOptions,
    tooltip: 'Webhook dapat digunakan untuk mengirim informasi secara real-time.'
  },
  {
    title: 'credential',
    value: 'Identitas Kredensial',
    options: credentialOptions,
    tooltip: 'Identitas yang digunakan saat melakukan permintaan ke service lainnya.'
  }
];

export const mediaToggleList = (form: FormInstance<any>) => [
  {
    title: 'showVideoCallButton',
    value: 'Tampilkan tombol video',
    formInstance: form,
    event: (checked: boolean) => {
      form.setFieldsValue({ showVideoCallButton: checked });
    },
    disabled: form.getFieldValue('defaultCommunicationType') === defaultCommunicationTypeEnum.AUDIO
  },
  {
    title: 'showAudioCallButton',
    value: 'Tampilkan tombol audio',
    formInstance: form,
    event: (checked: boolean) => form.setFieldsValue({ showAudioCallButton: checked })
  },
  {
    title: 'showScreenShareButton',
    value: 'Tampilkan tombol bagikan layar',
    formInstance: form,
    event: (checked: boolean) => form.setFieldsValue({ showScreenShareButton: checked })
  },
  {
    title: 'showCapturePhoto',
    value: 'Tampilkan tombol mengambil foto',
    formInstance: form,
    event: (checked: boolean) => form.setFieldsValue({ showCapturePhoto: checked })
  },
  {
    title: 'showSwitchCamera',
    value: 'Tampilkan tombol mengubah kamera',
    formInstance: form,
    event: (checked: boolean) => form.setFieldsValue({ showSwitchCamera: checked })
  },
  {
    title: 'autoConnect',
    value: 'Terhubung otomatis',
    formInstance: form,
    event: (checked: boolean) => form.setFieldsValue({ autoConnect: checked })
  }
];

export const toggleReminderIdleConversation = (form: FormInstance<any>) => [
  {
    title: 'reminderIdleConversation',
    value: 'Pengingat Penyelesaian Tiket',
    formInstance: form,
    event: (checked: boolean) => {
      form.setFieldsValue({ reminderIdleConversation: checked });
    }
  }
];

export const durationReminderInput = (form: FormInstance<any>) => [
  {
    title: 'durationReminder',
    value: 'Pengingat Penyelesaian Tiket',
    formInstance: form,
    event: (duration: number) => {
      form.setFieldsValue({ durationReminder: duration });
    }
  }
];

export const constructPayload = (
  form: FormInstance,
  showOperationalHour: boolean,
  selectedBU: AccountInterface
) => {
  const payload: CreateInboxParam = {
    accountId: selectedBU.accountId,
    inboxName: form.getFieldValue('inboxName'),
    websiteUrl: form.getFieldValue('websiteUrl'),
    greetingEnabled: form.getFieldValue('greeting'),
    greetingMessage: form.getFieldValue('greetingMessage'),
    target: form.getFieldValue('agentTarget'),
    inboxConfig: {
      allowTextMessage: form.getFieldValue('allowTextMessage'),
      allowMediaCall: false,
      allowAttachment: form.getFieldValue('allowAttachment'),
      allowRefId: form.getFieldValue('allowRefId'),
      allowConversationSummary: form.getFieldValue('allowConversationSummary'),
      allowConversationRating: form.getFieldValue('allowConversationRating'),
      communicationModes: form.getFieldValue('communicationModes'),
      conversationContext: form.getFieldValue('conversationContext'),
      webhookIdentifier: form.getFieldValue('webhookIdentifier'),
      credential: form.getFieldValue('credential'),
      reminderIdleConversation: form.getFieldValue('reminderIdleConversation'),
      durationReminder: form.getFieldValue('durationReminder'),
      mediaCallConfiguration: {
        showVideoCallButton: form.getFieldValue('showVideoCallButton'),
        showAudioCallButton: form.getFieldValue('showAudioCallButton'),
        showScreenShareButton: form.getFieldValue('showScreenShareButton'),
        switchCamera: form.getFieldValue('showSwitchCamera'),
        showCapturePhoto: form.getFieldValue('showCapturePhoto'),
        autoConnect: form.getFieldValue('autoConnect'),
        defaultCommunicationType: form.getFieldValue('defaultCommunicationType')
      }
    },
    longQueueMessage: form.getFieldValue('longQueueMessage'),
    waitingRoomMessage: form.getFieldValue('waitingRoomMessage'),
    oooMessage: `${form.getFieldValue('outsideWorkingHourMessage')} ${
      showOperationalHour ? 'Jam kerja hari ini adalah [jam operational].' : ''
    }`
  };

  if (payload?.inboxConfig?.webhookIdentifier === WebhookIdentifierEnum.NONE)
    delete payload.inboxConfig.webhookIdentifier;
  if (payload?.inboxConfig?.credential === WebhookIdentifierEnum.NONE)
    delete payload.inboxConfig.credential;

  return payload;
};

export enum InboxConfigEnum {
  DETAIL = 'DETAIL',
  SETTING = 'SETTING',
  SCHEDULE = 'SCHEDULE'
}

export const inboxConfigList = [
  {
    key: InboxConfigEnum.DETAIL,
    name: 'Info',
    icon: 'pencil-black.svg'
  },
  {
    key: InboxConfigEnum.SETTING,
    name: 'Pengaturan',
    icon: 'cog.svg'
  },
  {
    key: InboxConfigEnum.SCHEDULE,
    name: 'Jam',
    icon: 'calendar.svg'
  }
];

export const errMsgInbox = {
  longQueueMessage: 'Antrean panjang wajib diisi',
  waitingRoomMessage: 'Ruang tunggu wajib diisi',
  outsideWorkingHourMessage: 'Diluar jam kerja wajib diisi',
  greetingMessage: 'Kata sambutan wajib diisi'
};

export const counterCloseConnection = {
  maxDuration: 15,
  minDuration: 1
};
