import { getIconUrl } from '../../../utils/getIconUrl';

const WorkspaceSubmitted = () => {
  return (
    <div className="flex flex-col items-center justify-center gap-5">
      <img src={getIconUrl('check-splash.svg')} alt="check-splash" className="w-32" />
      <p className="m-0 p-0 text-2xl font-semibold">Data berhasil disimpan</p>
      <p className="m-0 p-0 text-base">Terima kasih telah melengkapi data pelanggan</p>
    </div>
  );
};

export default WorkspaceSubmitted;
