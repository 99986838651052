import { Hook } from '@squantumengine/horizon';

type Variants = 'primary' | 'secondary';
type Positions = 'top' | 'topLeft' | 'topRight' | 'bottom' | 'bottomLeft' | 'bottomRight';

type UseCCToasterParams = {
  timeout?: number;
  position?: Positions;
};

type OpenCCToasterParams = {
  onActionClick?: () => any;
  label: string;
  buttonLabel?: string;
  variant: Variants;
};

const useCCToaster = (settings: UseCCToasterParams) => {
  const { contextHolder, open, close } = Hook.useToaster(settings);

  const openNotification = (payload: OpenCCToasterParams) => {
    const id = new Date().getTime().toString(); // create unique UUID
    open({
      id,
      label: payload.label || 'Notification Title',
      buttonLabel: payload.buttonLabel || 'lihat',
      onActionClick: () => payload.onActionClick?.(),
      variant: payload.variant
    });
  };

  return {
    contextHolder,
    openNotification,
    close
  };
};

export default useCCToaster;
