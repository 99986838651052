import { getIconUrl } from '../../../utils/getIconUrl';
import FileUploadInterface from './chat-file-upload.interface';

export default function FileUpload(props: FileUploadInterface) {
  const { data } = props;
  const newUrl = data?.url;
  return (
    <div className={`relative h-[56px] rounded-lg  bg-green-sea-light text-black`}>
      <a className="w-full" href={newUrl} rel="noreferrer" target="_blank">
        <div className="absolute  h-[56px] gap-4 rounded-lg bg-green-pastel-dark "></div>
        <div className="absolute flex w-full  place-items-center gap-4 px-5">
          <div className="flex w-full gap-4 justify-self-start truncate">
            <img src={getIconUrl('file.svg')} alt="file" />
            <p className="w-full justify-self-start truncate text-ellipsis whitespace-nowrap  text-black">
              {data?.name}
            </p>
          </div>
          <img className="min-w-4 justify-self-end" src={getIconUrl('check.svg')} alt="check" />
        </div>
      </a>
    </div>
  );
}
