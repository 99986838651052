import { FormInstance } from 'antd';

export const mainToggleList = (form: FormInstance<any>, onChange?: () => void) => ({
  title: 'allowTakePicture',
  value: 'Izinkan pelanggan ambil foto',
  formInstance: form,
  event: (checked: boolean) => {
    onChange!();
    form.setFieldsValue({ allowTakePicture: checked });
  },
  tooltip: 'Pelanggan dapat mengambil dan mengirim foto ktp atau selfie'
});
