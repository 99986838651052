const parseFullName = (fullName: string) => {
  const firstName = fullName.includes(' ')
    ? fullName.substring(0, fullName.indexOf(' '))
    : fullName;
  const lastName = fullName.includes(' ') ? fullName.substring(fullName.indexOf(' ') + 1) : '';
  return [firstName, lastName];
};

export default parseFullName;

export const generateAvatarImage = (
  name: string,
  backgroundColor: string = '#0B2730',
  color: string = '#fff',
  size: number = 64
) => {
  const normalizedBgColor = backgroundColor.replace('#', '');
  const normalizedColor = color.replace('#', '');
  return `https://ui-avatars.com/api/?name=${name}&background=${normalizedBgColor}&color=${normalizedColor}&size=${size}`;
};
