import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { HEIGHT_BAR, KYCActivityStepChartInterface } from '../../../../static/analysis';
import { FunnelHover } from '../../../../components/kyc/kyc.interface';

const KYCActivityStepChart = ({
  indexItem,
  data,
  setHover,
  maxVal: MAX_VAL
}: KYCActivityStepChartInterface) => {
  const prevValue = data[(indexItem - 1) / 2]?.acc;
  const nextValue = data[(indexItem - 1) / 2 + 1]?.acc;
  const diffValue = prevValue - nextValue;

  const prevHeightBar = parseFloat(((prevValue * HEIGHT_BAR) / MAX_VAL).toFixed(1));
  const nextHeightBar = parseFloat(((nextValue * HEIGHT_BAR) / MAX_VAL).toFixed(1));

  const barHeight = `${parseFloat(
    ((data[(indexItem - 1) / 2]?.acc * HEIGHT_BAR) / MAX_VAL).toFixed(1)
  )}rem`;
  const clipPathPercentage = parseFloat(
    (((prevHeightBar - nextHeightBar) / prevHeightBar) * 100).toFixed(1)
  );

  const handleMouseOver = () => {
    const hoverTypes: Record<number, FunnelHover> = {
      1: { type: 'avg-wait', totalDrop: diffValue, isShown: true },
      3: { type: 'avg-verif', totalDrop: diffValue, isShown: true },
      5: { type: 'approve', totalDrop: 0, isShown: true }
    };

    const hoverType = hoverTypes[indexItem as number];
    if (hoverType) {
      setHover(hoverType);
    }
  };

  const handleMouseLeave = () => setHover({ isShown: false, type: 'avg-verif', totalDrop: 0 });

  const isZeroHeight = prevHeightBar === 0 && nextHeightBar === 0;

  const getPercentage = () => {
    const diff = prevValue - nextValue;
    if (diff === 0) return 0;

    const percentageValue = Math.round((diff / prevValue) * 100);
    return percentageValue;
  };

  return (
    <div
      key={indexItem}
      className="relative z-20 flex h-[30rem] w-[15rem] flex-col justify-end lg:w-[20rem]">
      <div className="relative">
        <div
          key={indexItem}
          className="flex w-full flex-col items-center"
          style={{
            height: barHeight,
            backgroundColor: 'rgba(252, 203, 107, 0.2)',
            clipPath: `polygon(0 0, 100% ${clipPathPercentage}%, 100% 100%, 0% 100%)`
          }}></div>
        <div
          data-testid="bar"
          onMouseOver={handleMouseOver}
          onMouseLeave={handleMouseLeave}
          style={{ opacity: 1 }}
          className={`absolute ${
            isZeroHeight ? '-top-20' : 'top-4'
          } left-0 right-0 z-20 ml-auto mr-auto w-20 cursor-pointer rounded-xl bg-white py-3 text-center shadow-xl`}>
          {getPercentage() < 0 ? (
            <ArrowUpOutlined style={{ fontSize: '1.5rem' }} />
          ) : (
            <ArrowDownOutlined style={{ fontSize: '1.5rem' }} />
          )}
          <p className="m-0 p-0 text-2xl font-bold">
            {getPercentage() < 0 ? Math.abs(getPercentage()) : getPercentage()}%
          </p>
        </div>
      </div>
    </div>
  );
};

export default KYCActivityStepChart;
