import { useQuery } from 'react-query';
import { fetchAccountDetail } from '../../account';
import { useAuthStore } from '../../../store/new/auth';

export const useGetAccountDetail = () => {
  const {
    account: { accountId }
  } = useAuthStore();

  return useQuery(['get-account-detail', accountId], () => fetchAccountDetail(accountId), {
    enabled: !!accountId
  });
};
