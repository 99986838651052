import { CloseOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { useState } from 'react';

function CustomSmallTooltip({ text }: { text: string | JSX.Element }) {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <Tooltip
      open={open}
      onOpenChange={(open) => setOpen(open)}
      overlayInnerStyle={{ width: '20rem', padding: '0.8rem', fontSize: 16 }}
      title={
        <div className="flex w-full flex-row items-start justify-between gap-4 rounded-xl">
          <p className="m-0 p-0 text-left">{text}</p>
          <CloseOutlined
            onClick={() => {
              setOpen(false);
            }}
            className="mt-[0.125rem] cursor-pointer text-xl transition-all hover:scale-125"
          />
        </div>
      }
      color={'#1E252E'}>
      <QuestionCircleOutlined className="ml-2 text-gray-900" data-testid="connection-sign" />
    </Tooltip>
  );
}

export default CustomSmallTooltip;
