import { useEffect, useRef, useState } from 'react';
import { Input, InputProps, Modal } from 'antd';
import { Button } from '@squantumengine/horizon';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import useTeamCreateEditStore, { FormTypeEnum } from '../../store';
import { useParams } from 'react-router-dom';
import {
  useCreateTopic,
  useDeleteTopic,
  useGetTopic
} from '../../../../../api/hooks/inbox/useTopic';
import {
  useCreateReason,
  useDeleteReason,
  useGetReason
} from '../../../../../api/hooks/inbox/useRejectReason';

export enum FormListItemTypeEnum {
  TOPIC,
  REJECT_REASON
}

export type ListItemProps = {
  uuid?: string;
  name: string;
  isEdit?: boolean;
};
type Props = {
  inputProps?: InputProps;
  itemName?: string;
  onChange?: (value: ListItemProps[]) => void;
  mode: FormListItemTypeEnum;
};

const FormListItem = ({ itemName, inputProps, onChange, mode }: Props) => {
  const [fieldName, setFieldName] = useState('');
  const [items, setItems] = useState<ListItemProps[]>([]);
  const { type } = useTeamCreateEditStore();
  const { inboxId } = useParams();

  const { mutate: addTopic } = useCreateTopic();
  const { mutate: deleteTopic } = useDeleteTopic();
  const { data: topicData, isLoading: isTopicLoading } = useGetTopic(inboxId!);
  const { mutate: addReason } = useCreateReason();
  const { mutate: deleteReason } = useDeleteReason();
  const { data: reasonData, isLoading: isReasonLoading } = useGetReason(inboxId!);

  const isRendered = useRef(false);
  useEffect(() => {
    if (isRendered.current || isTopicLoading || isReasonLoading) return;
    if (type === FormTypeEnum.EDIT) {
      isRendered.current = true;
      const data = mode === FormListItemTypeEnum.TOPIC ? topicData?.conversationTopics : reasonData;
      if (data) {
        const newItems = data.map((obj: any) => ({
          uuid: obj.uuid,
          name: mode === FormListItemTypeEnum.TOPIC ? obj.topic : obj.reason
        }));
        setItems(newItems);
      }
    }
  }, [topicData, reasonData, type, mode, isTopicLoading, isReasonLoading]);

  const handleAdd = () => {
    if (fieldName) {
      let newItems = [...items, { uuid: Math.random().toString(), name: fieldName }];
      setItems(newItems);
      setFieldName('');
      onChange && onChange(newItems);

      if (type === FormTypeEnum.EDIT) {
        if (mode === FormListItemTypeEnum.TOPIC) {
          addTopic({ inboxId, topic: fieldName });
        } else {
          addReason({ inboxId, reason: fieldName });
        }
      }
    }
  };

  const handleEdit = (index: number) => {
    setItems((prevItems) => {
      const newItems = [...prevItems];
      newItems[index].isEdit = true;
      onChange && onChange(newItems);
      return newItems;
    });
  };

  const handleEditChange = (index: number, value: string) => {
    setItems((prevItems) => {
      const newItems = [...prevItems];
      newItems[index].name = value;
      onChange && onChange(newItems);
      return newItems;
    });
  };

  const handleSave = (index: number) => {
    if (type === FormTypeEnum.EDIT) {
      const item = items[index];
      if (item) {
        if (mode === FormListItemTypeEnum.TOPIC) {
          deleteTopic({ inboxId, topicId: item.uuid });
          addTopic({ inboxId, topic: item.name });
        } else {
          deleteReason({ inboxId, reasonId: item.uuid });
          addReason({ inboxId, reason: item.name });
        }
      }
    }

    setItems((prevItems) => {
      const newItems = [...prevItems];
      if (newItems[index]) {
        newItems[index].isEdit = false;
        onChange && onChange(newItems);
      }
      return newItems;
    });
  };

  const handleDelete = (item: ListItemProps, index: number) => {
    const modal = Modal.confirm({
      closable: true,
      icon: null,
      title: `Hapus ${itemName}`,
      content: `Apakah Anda yakin ingin menghapus ${itemName} “${item.name}”?`,
      footer: (
        <div className="flex flex-col space-y-2 pt-4">
          <Button
            variant="primary"
            onClick={() => {
              setItems((prevItems) => prevItems.filter((_, i) => i !== index));
              onChange && onChange(items); // Consider using `prevItems` instead of `items`
              modal.destroy();

              if (type === FormTypeEnum.EDIT) {
                if (mode === FormListItemTypeEnum.TOPIC) {
                  deleteTopic({ inboxId, topicId: item.uuid });
                } else {
                  deleteReason({ inboxId, reasonId: item.uuid });
                }
              }
            }}>
            Hapus
          </Button>
          <Button variant="secondary" onClick={() => modal.destroy()}>
            Kembali
          </Button>
        </div>
      )
    });
  };

  return (
    <div className="w-full max-w-sm rounded-lg border border-solid border-gray-300 bg-white">
      <div className="flex items-center justify-between border border-b border-l-0 border-r-0 border-t-0 border-solid border-gray-300">
        <Input
          data-testid="form-list-item-input"
          className="w-full border-none px-3 text-sm hover:border-none hover:ring-0 focus:border-none focus:outline-none focus:ring-0"
          value={fieldName}
          onChange={(e) => setFieldName(e.target.value)}
          {...inputProps}
        />
        <Button data-testid="button-add" variant="text" onClick={handleAdd}>
          <PlusOutlined className="text-xs text-black" />
        </Button>
      </div>
      <div>
        {items?.map((item, index) => {
          if (item.isEdit) {
            return (
              <div key={item.uuid} className="flex items-center justify-between px-3 py-1">
                <Input
                  className="border-none p-0 text-sm hover:border-none hover:ring-0 focus:border-none focus:outline-none focus:ring-0"
                  value={item.name}
                  onChange={(e) => handleEditChange(index, e.target.value)}
                />
                <Button
                  variant="secondary"
                  size="sm"
                  className="inline-block w-16 !px-0 text-xs"
                  onClick={() => handleSave(index)}>
                  Simpan
                </Button>
              </div>
            );
          }
          return (
            <div key={item.uuid} className="flex items-center justify-between px-3 py-1">
              <div className="flex-1 truncate text-ellipsis">{item.name}</div>
              <div className="flex w-16 items-center justify-end space-x-3">
                <EditOutlined onClick={() => handleEdit(index)} />
                <DeleteOutlined onClick={() => handleDelete(item, index)} />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FormListItem;
