import { useCoordinates } from '../../../../hooks/useCoordinates';

interface Props {
  averageTime: number;
  totalDrop: number;
  label: string;
}

const KYCActivityStepTooltip = ({ averageTime, totalDrop, label }: Props) => {
  const coords = useCoordinates();

  const left = `${coords.x - 375}px`;
  const top = `${coords.y - 100}px`;

  function formatSecondsToHHMMSS(seconds: number) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(remainingSeconds).padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }

  return (
    <div
      style={{ left, top }}
      className="absolute z-50 flex w-[22rem] flex-col items-center justify-center gap-4 rounded-2xl bg-white px-8 py-4 shadow-lg">
      <div className="flex w-full flex-row items-center justify-between">
        <p className="m-0 p-0 text-lg font-semibold">Presentase Penurunan</p>
      </div>
      <div className="grid w-full grid-cols-6 place-items-center gap-4 rounded-xl border border-solid border-gray-400 p-4">
        <div className="col-span-2">
          <p className="m-0 p-0 font-semibold text-gray-400">Total Drop</p>
          <p className="m-0 p-0 text-lg font-bold text-gray-400">{totalDrop}</p>
        </div>
        <div className="col-span-4">
          <p className="m-0 p-0 font-semibold text-gray-400">{label}</p>
          <p className="m-0 p-0 text-lg font-bold text-gray-400">
            {formatSecondsToHHMMSS(averageTime)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default KYCActivityStepTooltip;
