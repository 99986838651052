export enum ResponseErrorEnum {
  AGENT_NOT_FOUND = 'agent not found',
  WRONG_PASSWORD = 'Wrong password, try again',
  AGENT_NEED_UPDATED = 'CC Agent needs to be updated'
}

export enum ResultErrorEnum {
  AGENT_NOT_FOUND = 'Email Anda belum terdaftar.',
  WRONG_PASSWORD = 'Kata sandi salah. Silakan cek dan coba lagi.',
  GENERAL_ERROR = 'Terjadi kesalahan. Silakan coba lagi.',
  AGENT_NEED_UPDATED = 'Isi data capacity agen dan coba lagi.'
}
