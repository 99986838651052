export enum RoleEnum {
  ADMIN = 'ADMIN',
  AGENT = 'AGENT',
  SUPERVISOR = 'SUPERVISOR'
}

export enum SidebarEnum {
  DASHBOARD = '1',
  TEAM = '2',
  ANALYTICS = '3',
  PERSONAL = '4'
}

export enum SidebarIndexEnum {
  DASHBOARD = 'home',
  TEAM = 'team',
  ANALYTICS = 'analytics',
  PERSONAL = 'user'
}
