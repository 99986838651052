import { useEffect, useRef, useState } from 'react';

const styleBorderLeft = { borderLeft: 'solid 1px #67727e' };
const pctSizeThreshold = 20;

type ProgressBarItem = {
  label: string;
  value: number;
  isTarget?: boolean;
};

type TimeProgressBarProps = {
  labels: ProgressBarItem[];
  value: number;
  min?: number;
  max?: number;
};

const TimeProgressBar = ({ labels, value = 0, min = 0, max = 100 }: TimeProgressBarProps) => {
  const containerRef = useRef<any>();
  const [containerWidth, setContainerWidth] = useState(0);
  const pctValue = Math.ceil(((value - min) / max) * 100);

  const _formatValue = (value: number) => {
    const hours = Math.floor(value / 3600);
    const minutes = Math.floor((value % 3600) / 60);
    const seconds = value % 60;

    return (
      `${hours ? `${hours} jam` : ''} ${minutes ? `${minutes} menit` : ''} ${
        seconds ? `${seconds} detik` : ''
      }`.trim() || '0 detik'
    );
  };

  const _renderLabel = (item: ProgressBarItem) => {
    const pctLabel = Math.floor(((item.value - min) / max) * 100);
    const stylePosLeft = { left: `${pctLabel}%` };
    return (
      <div
        key={item.label}
        className={`absolute ${
          item.isTarget ? '!top-[-4px]' : 'top-0'
        } flex min-w-[50px] flex-col items-center `}
        style={stylePosLeft}>
        <div
          className={`h-[53px] ${item.isTarget && 'z-20 h-[57px] border-2'}`}
          style={styleBorderLeft}
        />
        <div id="text-progress-1" className="w-full text-xs text-gray-500" style={stylePosLeft}>
          {item.label}
        </div>
      </div>
    );
  };

  const pctTarget = Math.floor(
    ((labels.find((item) => item.isTarget)?.value || 0 - min) / max) * 100
  );

  const pctWidth =
    Math.floor((pctTarget * containerWidth) / 100) +
    (containerWidth / (labels.length * 2)) * 0.25 +
    (1440 - window.screen.width) * 0.02;
  const styleLinearBackground = {
    background: `linear-gradient(90deg, #e4fff6 ${Math.floor(pctWidth)}px, #ffeced ${Math.floor(
      100 - pctWidth
    )}px)`
  };

  const isAchiveTarget = pctValue >= pctTarget;

  useEffect(() => {
    const target = containerRef.current;
    if (target) {
      const resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) setContainerWidth(entry.contentRect.width);
      });
      resizeObserver.observe(target);
      return () => resizeObserver.disconnect();
    }
  }, []);

  return (
    <div ref={containerRef} className="relative w-full min-w-fit pb-8">
      <div
        className="relative z-10 mb-2.5 flex h-11 w-full items-center rounded-3xl border border-solid border-gray-300 px-2"
        style={styleLinearBackground}>
        <div
          className={`z-10 flex h-[26px] items-center justify-end rounded-3xl ${
            isAchiveTarget ? 'bg-red-400' : 'bg-[#a84c7c] mix-blend-difference'
          }`}
          style={{
            width: `calc(${pctValue}% + ${pctValue < pctSizeThreshold ? '4px' : '24px'})`
          }}>
          <span
            className={`absolute left-0 right-0 px-2 text-center text-base font-semibold  ${
              isAchiveTarget ? 'text-white' : 'text-[#a84c7c] mix-blend-difference'
            }`}>
            {_formatValue(value)}
          </span>
        </div>
      </div>

      {labels?.map(_renderLabel)}
    </div>
  );
};

export default TimeProgressBar;
