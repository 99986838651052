import React, { useEffect, useState } from 'react';
import { ConfigProvider, Typography, Input, Flex, Button, Pagination, PaginationProps } from 'antd';
import { tableColumns } from './helper';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { ColumnFiltersState, Hook, PaginationState, Token } from '@squantumengine/horizon';
import { InboxListItemInterface } from '../../../shared/types/inbox.interface';
import { useGetTeamList } from '../../../api/hooks/team/useGetTeamList';
import { getIconUrl } from '../../../utils/getIconUrl';
import { Table } from '@squantumengine/horizon';
import { Link } from 'react-router-dom';
import { getAccountId } from '../../../utils/getAccountId';
import { useFetchTeamWithMember } from '../../../api/hooks/inbox/useFetchTeamWithMember';

const { COLORS } = Token;

const ListTeam: React.FC = () => {
  const { data: teamData, isLoading } = useGetTeamList();
  const accountId = getAccountId();

  const { data: newData } = useFetchTeamWithMember(teamData!, accountId);

  const [filterData, setFilterData] = useState<InboxListItemInterface[] | undefined>(newData);
  const [search, setSearch] = useState<string>('');

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10
  });

  const table = Hook.useTable({
    data: filterData,
    columns: tableColumns,
    pagination,
    setPagination,
    columnFilters,
    setColumnFilters,
    loadingSkeletonRowCount: 10,
    isLoading
  });

  const handleOnSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);

    const searchName = newData?.filter((item: InboxListItemInterface) => {
      return item?.name?.toLowerCase().includes(e.target.value?.toLowerCase());
    });
    setFilterData(searchName);
  };

  useEffect(() => {
    if (newData) {
      setFilterData(newData);
    }
  }, [newData]);

  const onShowSizeChange: PaginationProps['onShowSizeChange'] = (current, pageSize) => {
    setPagination({
      pageIndex: current - 1,
      pageSize
    });
  };

  const onChange: PaginationProps['onChange'] = (current, pageSize) => {
    setPagination({
      pageIndex: current - 1,
      pageSize
    });
  };

  return (
    <ConfigProvider
      prefixCls="cc"
      theme={{
        token: {
          colorPrimary: `${COLORS.blue[500]}`
        },
        components: {
          Pagination: {
            colorBgContainer: `${COLORS.blue[500]}`
          }
        }
      }}>
      <div className="flex flex-col gap-4 bg-[#F9FBFF] p-8">
        <Flex align="center" justify="space-between">
          <Typography.Title className="m-0 p-0" level={2}>
            Atur Tim
          </Typography.Title>
          <Link to={`/v2/dashboard/team/create`}>
            <Button type="primary" icon={<PlusOutlined />}>
              Tim Baru
            </Button>
          </Link>
        </Flex>
        <Flex align="start" vertical className="w-full rounded-xl bg-white p-8 shadow-sm">
          {!isLoading && teamData?.inboxList?.length! === 0 ? (
            <Flex
              vertical
              align="center"
              gap={4}
              justify="center"
              className="w-full bg-gray-50 p-16">
              <img src={getIconUrl('empty-folder.svg')} />
              <Typography.Title level={3}>Belum ada Tim</Typography.Title>
              <Link to={`/v2/dashboard/team/create`}>
                <Button type="primary" ghost icon={<PlusOutlined />}>
                  Buat Tim Baru
                </Button>
              </Link>
            </Flex>
          ) : (
            <>
              <div className="mb-4 flex w-1/4 items-center rounded-lg border border-solid border-grey-50 py-1">
                <Input
                  value={search}
                  allowClear
                  onChange={handleOnSearch}
                  placeholder="Cari nama tim"
                  variant="borderless"
                  prefix={<SearchOutlined className="text-xl text-black" />}
                />
              </div>
              <div className="flex w-full flex-col items-center justify-center gap-4">
                <Table
                  data={filterData}
                  table={table}
                  columns={tableColumns}
                  isLoading={isLoading}
                />
                <Pagination
                  showSizeChanger
                  onShowSizeChange={onShowSizeChange}
                  onChange={onChange}
                  defaultCurrent={1}
                  total={filterData?.length}
                />
              </div>
            </>
          )}
        </Flex>
      </div>
    </ConfigProvider>
  );
};

export default ListTeam;
