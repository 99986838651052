import { useEffect, useRef } from 'react';

const useMount = (callback: () => void) => {
  const firstRender = useRef(true);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      callback();
    }
  }, []);
};

export default useMount;
