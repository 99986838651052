import ChooseResult from './choose-result';
import {
  ChooseTypeEnum,
  KYCVerdictResponse,
  StatusKYCEnum,
  StatusKYCResultEnum
} from '../../kyc/kyc.interface';
import { useSelectedCustomerStore } from '../../../store/customer-state';
import { useEffect } from 'react';
import { useChatListStore } from '../../../store/chat-list';
import { isNullOrUndefined } from '../../../utils/isNullUndefined';
import { Paragraph } from '@squantumengine/horizon';

function KYCResult({ kycVerdict }: { kycVerdict: KYCVerdictResponse | undefined }) {
  const {
    chooseKTP,
    chooseSelfie,
    setChooseKTP,
    setChooseSelfie,
    resetKYCState,
    setIsManualKYCSubmited
  } = useSelectedCustomerStore();

  const { selectedCall } = useChatListStore();

  useEffect(() => {
    resetKYCState();
    setChooseKTP({
      documentName: 'ktp.png',
      documentUrl: kycVerdict?.ktpObjectName,
      documentId: kycVerdict?.ktpObjectName!
    });

    setChooseSelfie({
      documentName: 'selfie.png',
      documentUrl: kycVerdict?.selfieObjectName,
      documentId: kycVerdict?.selfieObjectName!
    });
    setIsManualKYCSubmited(true);

    // eslint-disable-next-line
  }, [kycVerdict, selectedCall]);

  return (
    <div>
      <ChooseResult
        data={chooseKTP!}
        setData={() => {}}
        isFinishKYC={true}
        name={ChooseTypeEnum.KTP}
      />
      <ChooseResult
        data={chooseSelfie!}
        setData={() => {}}
        isFinishKYC={true}
        name={ChooseTypeEnum.SELFIE}
      />
      <div className="flex flex-row justify-between gap-2">
        <Paragraph className="text-nowrap text-neutral-600">Status KYC:</Paragraph>
        <Paragraph
          className={`truncate ${
            kycVerdict?.status === StatusKYCEnum.COMPLETED ? 'text-blue-500' : 'text-red-500'
          }`}>
          {kycVerdict?.status === StatusKYCEnum.COMPLETED
            ? StatusKYCResultEnum.APPROVED
            : StatusKYCResultEnum.FAILED}
        </Paragraph>
      </div>
      {!isNullOrUndefined(kycVerdict?.reason) && (
        <div className="flex flex-row justify-between gap-2">
          <Paragraph className="text-nowrap text-neutral-600">Alasan:</Paragraph>
          <Paragraph className="truncate">{kycVerdict?.reason}</Paragraph>
        </div>
      )}
    </div>
  );
}

export default KYCResult;
