import { useQuery } from 'react-query';
import {
  InboxListInterface,
  InboxListItemInterface,
  InboxMemberInterface
} from '../../../shared/types/inbox.interface';
import { fetchInboxUserList } from '../../user';

const fetchTeamDataWithMembers = async (teamData: InboxListInterface, accountId: string) => {
  if (teamData?.inboxList) {
    const newData = await Promise.all(
      teamData.inboxList.map(async (item: InboxListItemInterface) => {
        const inboxMembers: InboxMemberInterface = await fetchInboxUserList(
          accountId,
          item?.inboxId
        );
        return {
          ...item,
          totalAgent: inboxMembers?.inboxMembersList?.length
        };
      })
    );
    return newData;
  }
};

export const useFetchTeamWithMember = (teamData: InboxListInterface, accountId: string) => {
  return useQuery(
    ['teamDataWithMembers', teamData, accountId],
    () => fetchTeamDataWithMembers(teamData, accountId),
    {
      enabled: !!teamData, // only run the query if teamData is available
      refetchOnWindowFocus: false
    }
  );
};
