import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import { useState } from 'react';
import SearchDropDown from '../filter/search-dropdown';
import { UserResponse } from '../card/user/user.interface';
import { ItemInvUsrKey, searchFilterInterface } from './team-users.interface';

const SearchFilter = (props: searchFilterInterface) => {
  const { setData, data, items } = props;
  const [selectedSearch, setSelectedSearch] = useState<number>(0);
  const [value, setValue] = useState<string>('');

  const handleOnchange = (e: any) => {
    e.preventDefault();
    switch (selectedSearch) {
      case ItemInvUsrKey.EMAIL:
        const searchEmail = data?.filter((item: UserResponse) => {
          return item?.email?.toLowerCase().includes(e.target.value?.toLowerCase());
        });
        setData(searchEmail);
        break;
      case ItemInvUsrKey.NAME:
        const searchName = data?.filter((item: UserResponse) => {
          return item?.firstName?.toLowerCase().includes(e.target.value?.toLowerCase());
        });
        setData(searchName);
        break;
    }
    setValue(e.target.value);
  };

  return (
    <div className="flex h-10 w-full items-center rounded-2xl border border-solid border-grey-50">
      <Input
        value={value}
        allowClear
        onChange={handleOnchange}
        placeholder="cari"
        variant="borderless"
        prefix={<SearchOutlined className="text-grey-80" />}
      />
      <SearchDropDown
        items={items}
        selectedSearch={selectedSearch}
        setSelectedSearch={setSelectedSearch}
      />
    </div>
  );
};
export default SearchFilter;
