import {
  TemplateMessage,
  WhatsAppSupportModeEnum,
  WhatsappAttribute,
  WhatsappCommunicationTypeEnum
} from '../../../shared/types/whatsapp.interface';
import { InboxInterface } from '../../../types/new/inbox';
import { FormWhatsAppWidget } from './store';

export const initialFormWhatsappValue = (inbox: InboxInterface): FormWhatsAppWidget => {
  const { automatic_messages } = inbox;
  const { supportMode, decisionTreeId } = inbox.inboxConfig;

  const campaignConfigurations: WhatsappAttribute[] = [];
  const templateMessages: TemplateMessage[] = [];

  if (automatic_messages) {
    Object.keys(automatic_messages).forEach((key) => {
      const dataAttributes: Record<string, string> = {};

      automatic_messages[key as keyof typeof automatic_messages].precodings.forEach((p) => {
        if (p.templatePrecodingKey && p.templatePrecodingValueKey)
        dataAttributes[p.templatePrecodingKey] = p.templatePrecodingValueKey;
      });

      campaignConfigurations.push({
        campaignId: automatic_messages[key as keyof typeof automatic_messages].id,
        dataAttributes,
        campaignType: key.toUpperCase() as WhatsappCommunicationTypeEnum
      });
    });
  }

  return {
    greetingEnabled: true,
    inboxName: inbox.name,
    channel: 'WHATSAPP',
    target: 10,
    templateMessages,
    campaignConfigurations,
    inboxConfig: {
      supportMode: (supportMode as WhatsAppSupportModeEnum) || WhatsAppSupportModeEnum.BOT,
      decisionTreeId: decisionTreeId || ''
    }
  };
};
