import { useEffect } from 'react';
import { Row } from 'antd';
import '../login.css';
import { checkVideoAudio } from '../../utils/webSocket/videoRequest';
import { useLocation } from 'react-router-dom';
import useMount from '../../hooks/useMounth';
import FormLogin from '../../components/login/form-login';
import { NativeLoginWordingEnum } from '../../static/native-login';
import useCCToaster from '../../hooks/useCCToaster';

function NativeLogin() {
  const location = useLocation();
  const { contextHolder, openNotification } = useCCToaster({ timeout: 3 });

  useMount(() => {
    if (location?.state?.resetPassword) {
      openNotification({
        label: NativeLoginWordingEnum.changePasswordSuccess,
        buttonLabel: 'Ok',
        variant: 'primary'
      });
      window.history.replaceState({}, '');
    }
  });

  useEffect(() => {
    checkVideoAudio();
    //eslint-disable-next-line
  }, []);
  return (
    <Row
      className="login-form flex min-h-screen flex-col bg-alice-blue"
      justify="center"
      align="middle">
      {contextHolder}
      <FormLogin />
    </Row>
  );
}

export default NativeLogin;
