import { Modal } from 'antd';
import { ChooseResultInterface } from '../../kyc/kyc.interface';
import { Icon, Paragraph, Title } from '@squantumengine/horizon';
import { useState } from 'react';

function ChooseResult(item: ChooseResultInterface) {
  const { data, name } = item;
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      {data && (
        <div
          data-testid="choose-result"
          className="mb-[16px] flex w-full flex-col items-start gap-[14px]">
          <div className="flex w-full flex-row items-center gap-4 overflow-hidden rounded-lg bg-neutral-100 pr-[14px]">
            <img
              src={data?.documentUrl}
              alt="choose-item"
              className="h-[48px] w-[48px] object-cover"
            />
            <Paragraph className="flex-1 text-neutral-950">{name}</Paragraph>
            <div
              data-testid="button-expand"
              className="flex cursor-pointer items-center"
              onClick={() => setIsModalOpen(true)}>
              <Icon name="expand"></Icon>
            </div>
          </div>

          <Modal
            title={
              <div className="flex flex-row items-center gap-[24px]">
                <div
                  data-testid="button-close"
                  className="flex cursor-pointer items-center"
                  onClick={() => setIsModalOpen(false)}>
                  <Icon name="close-large"></Icon>
                </div>
                <Title level={5}>Foto {name}</Title>
              </div>
            }
            open={isModalOpen}
            footer={null}
            closable={false}
            onCancel={() => setIsModalOpen(false)}>
            <img src={data?.documentUrl} alt="choose-item" className="w-full object-cover" />
          </Modal>
        </div>
      )}
    </>
  );
}

export default ChooseResult;
