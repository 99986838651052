import { Button, Col, Row, Spin } from 'antd';
import { useEffect, useRef, useState } from 'react';
import ChatBox from '../../components/card/chatbox/conversation';
import {
  createCallListComponent,
  createIncomingCallComponents,
  parsedConversation
} from './dashboard.helper';
import { useDashboardPage } from '../../hooks/useDashboardPage';
import RedoIcon from '../../assets/icons/redo';
import TabFilter from '../../components/filter/tab-filter';
import DateFilter from '../../components/filter/date-filter';
import OfflineCard from '../../components/card/offline-card';
import SearchFilter from '../../components/filter/search-filter';
import RecentActivity from '../../components/card/recent-activity';
import ArrowPagination from '../../components/pagination/arrow-pagination';
import OfflineMessageChatBox from '../../components/card/chatbox/offline-message';
import { ConversationInterface } from '../../components/card/chat-message/chat-message.interface';
import { useOfflineconversation } from '../../api/hooks/inbox/useOfflineconversation';
import { useAllConversationInInboxQuery } from '../../api/hooks/inbox/useAllConversationInInboxQuery';
import { useChatListStore } from '../../store/chat-list';
import { useInboxDetailStore } from '../../store/inbox-state';
import {
  OFFLINE_CONVERSATION_PAGE_SIZE,
  SUPERVISOR_COLUMN_SIZE,
  SUPERVISOR_CONVERSATION_PER_PAGE
} from '../../static/dashboard';
import { Tablist, TablistEnum } from '../../static/tab-list';
import useDebounce from '../../utils/debounce';
import { getIconUrl } from '../../utils/getIconUrl';
import { useConversationListStore } from '../../store/conversation-list-state';
import { MessageEventEnum } from '../../static/message-event';
import IncomingCallNotification from '../../components/notification/incoming-call';
import SPVWorkspace from '../../components/workspace/supervisor';
import dayjs from 'dayjs';
import { items } from '../../static/search-filter';
import AssignAgentModalV2 from '../../components/v2/modal/assign-agent-modal';

function DashboardSupervisor() {
  const { inboxId } = useInboxDetailStore();
  const { selectedOfflineChat } = useChatListStore();
  const [assignAgentConversation, setAssignAgentConversation] =
    useState<ConversationInterface | null>(null);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const { handleActiveCall, handleEndCall } = useDashboardPage();
  const [page, setPage] = useState<number>(1);
  const [offlinePage, setOfflinePage] = useState<number>(1);
  const [InactivePage, setInactivePage] = useState<number>(1);
  const [selectedTab, setSelectedTab] = useState<string>('Semua');
  const [email, setEmail] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [ticketNumber, setTickeNumber] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [startDate, setStartdate] = useState<string>('');
  const [endDate, setEnddate] = useState<string>('');

  const {
    assignedConversationList: assignedCallList,
    setConversationList,
    createdConversationList,
    closedConversationList
  } = useConversationListStore();
  const filteredAssigned = assignedCallList.filter((data) => data.inboxId === inboxId);
  const filteredCreated = createdConversationList.filter((data) => data.inboxId === inboxId);
  const filteredClosed = closedConversationList.filter((data) => data.inboxId === inboxId);
  const handleAssignAgent = (conversation: ConversationInterface) => {
    setAssignAgentConversation(conversation);
    setModalOpen(true);
  };

  const handlePrevPage = () => {
    if (isPrevDisabled) return;
    setPage((prev) => prev - 1);
  };

  const handleNextPage = () => {
    if (isNextDisabled) return;
    setPage((prev) => prev + 1);
  };

  const handlePrevOfflinePage = () => {
    if (isPrevOfflineDisabled) return;
    setOfflinePage((prev) => prev - 1);
  };

  const handleNextOfflinePage = () => {
    if (isNextOfflineDisabled) return;
    setOfflinePage((prev) => prev + 1);
  };

  const handlePrevInactivePage = () => {
    if (isPrevInactiveDisabled) return;
    setInactivePage((prev) => prev - 1);
  };

  const handleNextInactivePage = () => {
    if (isNextInactiveDisabled) return;
    setInactivePage((prev) => prev + 1);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };
  const refetchInterval = 0; //10seconds
  const {
    data: activeData,
    isLoading: acitveDataLoading,
    isSuccess: activeConversationSuccess,
    refetch: refetchActiveData
  } = useAllConversationInInboxQuery(
    inboxId,
    true,
    page,
    refetchInterval,
    useDebounce(email, 500) as string,
    useDebounce(name, 500) as string,
    useDebounce(ticketNumber, 500) as string,
    useDebounce(phoneNumber, 500) as string,
    startDate,
    endDate
  );
  const { data: inActiveData, refetch: refetchInactiveData } = useAllConversationInInboxQuery(
    inboxId,
    false,
    InactivePage,
    refetchInterval,
    useDebounce(email, 500) as string,
    useDebounce(name, 500) as string,
    useDebounce(ticketNumber, 500) as string,
    useDebounce(phoneNumber, 500) as string,
    startDate,
    endDate
  );
  const activeCallListArr = parsedConversation(activeData?.data, inboxId, true);
  const inActiveCallListArr = parsedConversation(inActiveData?.data, inboxId, false);

  const getConversationList = () => {
    refetchActiveData();
    refetchInactiveData();
  };
  const { data: offlineData, isSuccess: offlineDatasuccess } = useOfflineconversation({
    page: offlinePage,
    email: useDebounce(email, 500) as string,
    name: useDebounce(name, 500) as string,
    ticketNumber: useDebounce(ticketNumber, 500) as string,
    phoneNumber: useDebounce(phoneNumber, 500) as string,
    startDate,
    endDate
  });
  const firstRender = useRef(false);
  useEffect(() => {
    if (firstRender && (activeData || inActiveData)) {
      setConversationList(MessageEventEnum.ASSIGNED, activeCallListArr.assignedConversationList);
      setConversationList(MessageEventEnum.CREATED, activeCallListArr.createdConversationListArr);
      setConversationList(MessageEventEnum.INACTIVE, inActiveCallListArr.inactiveList);
      firstRender.current = true;
    }
    //eslint-disable-next-line
  }, [activeData, inActiveData]);

  const offlineChatList = offlineData?.offlineMessages || [];
  const isNextDisabled =
    page >=
    Math.ceil(activeCallListArr.assignedConversationList.length / SUPERVISOR_COLUMN_SIZE) - 1;
  const isPrevDisabled = page === 1;
  const isNextOfflineDisabled =
    offlineChatList?.length <= 0 || offlineChatList?.length < OFFLINE_CONVERSATION_PAGE_SIZE;
  const isPrevOfflineDisabled = offlinePage === 1;
  const isNextInactiveDisabled =
    inActiveCallListArr.inactiveList.length <= 0 ||
    inActiveCallListArr.inactiveList.length < SUPERVISOR_CONVERSATION_PER_PAGE;
  const isPrevInactiveDisabled = InactivePage === 1;
  const onChangeDate = (e: any) => {
    if (!e) return;
    setStartdate(e[0].format('YYYY-MM-DD'));
    setEnddate(e[1].format('YYYY-MM-DD'));
  };
  const isSearchFilterApplied = name || ticketNumber || email || phoneNumber;
  const isNoSearchresult =
    Boolean(isSearchFilterApplied) &&
    activeData?.data?.length === 0 &&
    (inActiveData?.data?.length === 0 || inActiveData?.data === undefined) &&
    (offlineData?.offlineMessages?.length === 0 || offlineData?.offlineMessages === undefined);

  useEffect(() => {
    setOfflinePage(1);
    setInactivePage(1);
    setPage(1);
  }, [inboxId]);

  return (
    <Row className="flex flex-wrap gap-4 bg-white">
      <Col className="flex h-[calc(100vh-64px)] flex-1 flex-col gap-4 overflow-y-auto p-6">
        <>
          <RecentActivity />
          <IncomingCallNotification handleActiveCall={handleActiveCall} />
          <Row className="flex h-max w-full items-center space-x-3">
            <div className="flex-1">
              <SearchFilter
                items={items}
                value={name || ticketNumber || email || phoneNumber}
                setName={setName}
                setTicketNumber={setTickeNumber}
                setEmail={setEmail}
                setPhoneNumber={setPhoneNumber}
              />
            </div>
            <DateFilter
              startDate={dayjs(startDate)}
              endDate={dayjs(endDate)}
              onChangeDate={onChangeDate}
            />

            <div className="cursor-pointer" onClick={() => getConversationList()}>
              <RedoIcon />
            </div>
          </Row>
          <TabFilter Tablist={Tablist} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
          {acitveDataLoading && <Spin spinning={acitveDataLoading} />}
          {isNoSearchresult && (
            <div className="flex w-full flex-col items-center">
              <img src={getIconUrl('ic-page-error.png')} alt="no search result" />
              <h1>Data yang anda cari tidak ada</h1>
              <Button
                className="rounded-full bg-green-emerald"
                type="primary"
                onClick={() => {
                  setName('');
                  setTickeNumber('');
                  setEmail('');
                  setStartdate('');
                  setEnddate('');
                  setPhoneNumber('');
                }}>
                Hapus Pencarian
              </Button>
            </div>
          )}
        </>
        {activeConversationSuccess &&
          !isNoSearchresult &&
          (selectedTab === TablistEnum.ALL || selectedTab === TablistEnum.INCOMING) && (
            <div>
              <div className="mb-4 flex items-center justify-between">
                <p className="my-0 text-xl font-bold text-grey-100">Percakapan Masuk</p>
                <div className="flex gap-2">
                  <ArrowPagination
                    disableNext={isNextDisabled}
                    disablePrev={isPrevDisabled}
                    onClickNext={handleNextPage}
                    onClickPrev={handlePrevPage}
                  />
                </div>
              </div>
              <Row className="grid grid-cols-3 gap-4">
                {createCallListComponent(filteredAssigned, handleAssignAgent)}
              </Row>
            </div>
          )}
        {!acitveDataLoading && !activeData && (
          <div
            className="mx-auto flex cursor-pointer items-center hover:underline"
            onClick={() => getConversationList()}>
            <RedoIcon />
            <p className="ml-2">Muat ulang</p>
          </div>
        )}
        {Boolean(
          inActiveCallListArr.inactiveList?.length ||
            activeCallListArr.createdConversationListArr.length
        ) &&
          (selectedTab === TablistEnum.ALL || selectedTab === TablistEnum.QUEUE) && (
            <div>
              <div className="mb-4 flex flex-wrap items-center justify-between">
                <p className="my-0 text-xl font-bold text-grey-100">Percakapan Dalam Antrean</p>
              </div>
              <Row className="grid grid-cols-3 gap-4">
                {createIncomingCallComponents(handleActiveCall, handleAssignAgent, filteredCreated)}
              </Row>
            </div>
          )}
        {offlineDatasuccess &&
          !isNoSearchresult &&
          !name &&
          (selectedTab === TablistEnum.ALL || selectedTab === TablistEnum.OFFLINE_MESSAGE) && (
            <>
              <div className="flex items-center justify-between">
                <p className="my-0 text-xl font-bold text-grey-100">Pesan Offline</p>
                <ArrowPagination
                  disableNext={isNextOfflineDisabled}
                  disablePrev={isPrevOfflineDisabled}
                  onClickNext={handleNextOfflinePage}
                  onClickPrev={handlePrevOfflinePage}
                />
              </div>
              <Row className="grid grid-cols-3 gap-4">
                {offlineChatList?.map((chat) => (
                  <OfflineCard key={chat.messageId} data={chat} />
                ))}
              </Row>
            </>
          )}
        {Boolean(
          inActiveCallListArr.inactiveList?.length ||
            activeCallListArr.createdConversationListArr.length
        ) &&
          (selectedTab === TablistEnum.ALL || selectedTab === TablistEnum.END) && (
            <div>
              <div className="mb-4 flex flex-wrap items-center justify-between">
                <p className="my-0 text-xl font-bold text-grey-100">Percakapan Selesai</p>
                <ArrowPagination
                  disableNext={isNextInactiveDisabled}
                  disablePrev={isPrevInactiveDisabled}
                  onClickNext={handleNextInactivePage}
                  onClickPrev={handlePrevInactivePage}
                />
              </div>
              <Row className="grid grid-cols-3 gap-4">
                {createCallListComponent(filteredClosed)}
              </Row>
            </div>
          )}
      </Col>
      <Col
        span={8}
        className="flex h-[calc(100vh-64px)] flex-col gap-4 overflow-y-auto border-y-0 border-l border-r-0 border-solid border-l-grey-50">
        <div className="flex h-[480px] min-h-[480px] flex-col gap-4 p-6 pb-0">
          {selectedOfflineChat ? (
            <OfflineMessageChatBox />
          ) : (
            <ChatBox onClose={handleEndCall} allowRefresh withoutVideo />
          )}
        </div>
        <div className="flex-1 px-6">
          <SPVWorkspace />
        </div>
      </Col>
      <AssignAgentModalV2
        conversation={assignAgentConversation}
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        refetch={getConversationList}
      />
    </Row>
  );
}

export default DashboardSupervisor;
