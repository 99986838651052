import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Space } from 'antd';
const SearchDropDown = (props: {
  setSelectedSearch: (selectedSearch: number) => void;
  selectedSearch: number;
  items: any;
}) => {
  const { selectedSearch, setSelectedSearch, items } = props;

  const onClick = (e: any) => {
    setSelectedSearch(JSON.parse(e.key));
  };

  return (
    <Dropdown
      className="mr-2 h-7 max-w-[140px] cursor-pointer rounded-full border border-solid border-grey-100 px-4"
      menu={{ items, onClick }}
      trigger={['click']}>
      <Space className="w-[140px]">
        <div
          data-testid="dropdownSearch"
          className="w-[90px] overflow-hidden truncate whitespace-nowrap text-base">
          {items?.[selectedSearch].label}
        </div>
        <DownOutlined />
      </Space>
    </Dropdown>
  );
};
export default SearchDropDown;
