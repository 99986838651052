import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const copy = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_18_4)">
      <path
        d="M17.1423 0H5.17805C5.07983 0 4.99948 0.0803571 4.99948 0.178571V1.42857C4.99948 1.52679 5.07983 1.60714 5.17805 1.60714H16.2495V16.9643C16.2495 17.0625 16.3298 17.1429 16.428 17.1429H17.678C17.7763 17.1429 17.8566 17.0625 17.8566 16.9643V0.714286C17.8566 0.319196 17.5374 0 17.1423 0ZM14.2852 2.85714H2.85662C2.46153 2.85714 2.14233 3.17634 2.14233 3.57143V15.4174C2.14233 15.6071 2.21823 15.7879 2.35216 15.9219L6.22046 19.7902C6.26957 19.8393 6.32537 19.8795 6.38564 19.9129V19.9554H6.47939C6.55751 19.9844 6.6401 20 6.72492 20H14.2852C14.6803 20 14.9995 19.6808 14.9995 19.2857V3.57143C14.9995 3.17634 14.6803 2.85714 14.2852 2.85714ZM6.38341 17.683L4.46153 15.7589H6.38341V17.683ZM13.3923 18.3929H7.81198V15.2232C7.81198 14.7299 7.41242 14.3304 6.91912 14.3304H3.74948V4.46429H13.3923V18.3929Z"
        fill="black"
        fillOpacity="0.85"
      />
    </g>
    <defs>
      <clipPath id="clip0_18_4">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const CopyIcon = (props: Partial<CustomIconComponentProps>) => <Icon component={copy} {...props} />;

export default CopyIcon;
