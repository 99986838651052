import { Token } from '@squantumengine/horizon';
import { useTimer } from '../../../../../hooks/useTimer';
import { DurationToString } from '../../../../../utils/timestamp';
import { ConversationInterface } from '../../../../../components/card/chat-message/chat-message.interface';
import EngineWarning from '../../../../../assets/icons/engine-warning';
import { WAITING_TIME_THRESHOLD } from '../../../../../static/dashboard';

type Props = {
  conversation: ConversationInterface;
};

const ConversationWaitingTime = ({ conversation }: Props) => {
  const { second } = useTimer(Math.round((Date.now() - Number(conversation.startTime)) / 1000));
  const isWarning = second > WAITING_TIME_THRESHOLD;

  return (
    <>
      <div
        className={`flex items-center space-x-1 text-xs ${
          isWarning ? '!text-red-500' : 'text-gray-400'
        }`}>
        <EngineWarning color={isWarning ? Token.COLORS.red[500] : ''} />
        <span>waktu tunggu</span>
        <span>{DurationToString(second)}</span>
      </div>
    </>
  );
};

export default ConversationWaitingTime;
