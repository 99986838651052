import { Modal } from 'antd';
import CommentSlashIcon from '../../../assets/icons/comment-slash';
import { MessageEventEnum } from '../../../static/message-event';
import { useChatListStore } from '../../../store/chat-list';
import { CloseSessionProps, FilterListProps } from './close-session.interface';
import { CloseOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { confirmModalEndSession } from '../../../static/communication-type';
import { useWorkspaceSummary } from '../../../api/hooks/workspace/useWorkspaceSummary';

const CloseSessionButton = ({ onClickAddNotes, onClickEndTicket }: CloseSessionProps) => {
  const { selectedCall, isReadyToClose, setIsReadyToClose } = useChatListStore();
  const { data } = useWorkspaceSummary(selectedCall?.conversationId as string);
  const [openModalConfirm, setOpenModalConfirm] = useState<boolean>(false);
  const isActive = selectedCall?.event === MessageEventEnum.ACTIVE;
  const isPreClosure = selectedCall?.event === MessageEventEnum.SESSION_CLOSED;
  const isInactive = selectedCall?.event === MessageEventEnum.INACTIVE;
  const handleShowModalConfirm = () => setOpenModalConfirm(true);
  const handleCloseModalConfirm = () => setOpenModalConfirm(false);
  const handleConfirmEndCall = () => {
    handleCloseModalConfirm();
    onClickAddNotes();
  };

  useEffect(() => {
    setIsReadyToClose(Boolean(data?.topic && data?.topic !== '-') && isPreClosure);
    // eslint-disable-next-line
  }, [selectedCall]);

  return (
    <div className="group relative flex h-full min-w-fit cursor-pointer items-center">
      <div className="flex items-center justify-between gap-2 rounded-3xl text-base font-semibold duration-200 ease-out hover:border-bold-green hover:text-bold-green">
        <button
          className={`flex cursor-pointer items-center gap-3 rounded-full border-none ${
            !isInactive ? 'bg-orange-danger' : 'cursor-default bg-grey-100'
          } px-5 py-2 text-xs font-semibold text-white`}
          disabled={!isActive}>
          Akhiri Sesi
          <CommentSlashIcon />
        </button>
      </div>
      <ul className="absolute right-0 top-[100%] z-50 m-0 hidden w-[200px] list-none flex-col rounded-2xl rounded-tr-none bg-white p-0 px-5 shadow-xl group-hover:flex">
        <FilterList isActive={isActive} label="Tambah Catatan" onClick={handleShowModalConfirm} />
        <FilterList
          isActive={isReadyToClose && isPreClosure}
          label="Selesaikan Tiket"
          onClick={onClickEndTicket}
          isLast
        />
      </ul>
      <Modal
        title={
          <div className="flex items-center justify-between">
            <h3 className="m-0 p-0 text-2xl font-semibold">{confirmModalEndSession.title}</h3>
            <div onClick={handleCloseModalConfirm}>
              <CloseOutlined />
            </div>
          </div>
        }
        open={openModalConfirm}
        closable={false}
        footer={false}
        width={336}>
        <div className="space-y-2">
          <div className="text-md m-0 py-2">{confirmModalEndSession.description} </div>
          <div className="flex flex-col space-y-3">
            <button
              className="cursor-pointer rounded-3xl border-0 bg-orange-danger py-2 font-sans text-base font-semibold text-white outline-none duration-200 ease-out hover:bg-[#b34307]"
              onClick={handleConfirmEndCall}>
              {confirmModalEndSession.okText}
            </button>
            <button
              className="cursor-pointer rounded-3xl bg-transparent py-2 font-sans text-base font-semibold duration-200 ease-out hover:bg-slate-200"
              onClick={handleCloseModalConfirm}>
              {confirmModalEndSession.cancelText}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const FilterList = ({ isActive, label, onClick, isLast }: FilterListProps) => {
  return (
    <li
      className={`flex w-full cursor-pointer items-center justify-between bg-white py-4 font-medium duration-200 ease-out
        ${!isLast && 'border-0 border-b-[1px] border-solid border-b-black/40'}
        ${isActive ? 'text-black hover:text-bold-green' : 'text-grey-100'}
      `}
      onClick={() => isActive && onClick()}>
      {label}
    </li>
  );
};

export default CloseSessionButton;
