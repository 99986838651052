import React from 'react';
import { StatusKYCEnum } from './kyc.interface';
import { ConversationStatusEnum } from '../../static/message-event';
import { ConversationContextEnum } from '../../static/communication-type';

interface Props {
  kycStatus: StatusKYCEnum;
  conversationStatus: ConversationStatusEnum;
  conversationContext: ConversationContextEnum;
}

const KYCStatus: React.FC<Props> = ({ kycStatus, conversationStatus, conversationContext }) => {
  const getStatusText = () => {
    if (
      conversationStatus === ConversationStatusEnum.RESOLVED &&
      conversationContext === ConversationContextEnum.KYC
    ) {
      switch (kycStatus) {
        case StatusKYCEnum.COMPLETED:
          return 'KYC Disetujui';
        case StatusKYCEnum.FAILED:
          return 'KYC Ditolak';
        default:
          return 'KYC Ditunda';
      }
    }
    return '';
  };

  const getStatusClass = () => {
    switch (kycStatus) {
      case StatusKYCEnum.COMPLETED:
        return 'text-green-emerald';
      case StatusKYCEnum.FAILED:
        return 'text-red-imperial';
      default:
        return 'text-yellow-500';
    }
  };

  return (
    <div className={`${getStatusClass()} text-base font-semibold`}>
      <b>{getStatusText()}</b>
    </div>
  );
};

export default KYCStatus;
