import { useEffect, useState } from 'react';
import { Popover, Row } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import SignalStreamIcon from '../../../assets/icons/signal-stream';
import { useConversationListStore } from '../../../store/conversation-list-state';
import { getAllActiveAgents } from '../../../api/inbox';
import { fetchInboxUserList } from '../../../api';
import { useParams } from 'react-router-dom';
import VideoIcon from '../../../assets/icons/video';
import ChatIcon from '../../../assets/icons/chat';
import QueueListItem from './QueueItem';
import useAgentActivity from '../../../hooks/useAgentActivity';
import { useInboxDetailStore } from '../../../store/inbox-state';
import { getAccountId } from '../../../utils/getAccountId';
import './recent-activity.css';

function RecentActivity() {
  const { agentCapacityList, setAgentList, setAgentCapacityList } = useAgentActivity();
  const [onlineAgentPopover, setOnlineAgentPopover] = useState<boolean>(false);
  const [queuePopover, setQueuePopover] = useState<boolean>(false);
  const { createdConversationList } = useConversationListStore();
  const { inboxId: inboxIdStore } = useInboxDetailStore();
  const { inboxId: inboxIdParam } = useParams();
  const inboxId = inboxIdParam || inboxIdStore;
  const accountId = getAccountId();

  const AgentList = () => (
    <div className="flex max-h-44 flex-col gap-2 overflow-y-scroll">
      {agentCapacityList?.map((agent) => (
        <div className="flex flex-row justify-between">
          <div className="truncate text-base font-semibold">
            {agent?.firstName ?? ''} {agent?.lastName ?? ''}
          </div>
          <div className="flex flex-row items-center gap-2">
            <div className="flex flex-row justify-start gap-2">
              <VideoIcon className="text-grey-100" />
              <div
                className={`${
                  agent?.capacity?.onGoingCallCapacity > 0 ? 'text-green-emerald' : 'text-grey-100'
                } w-fit min-w-[4px] text-right text-sm font-semibold`}>
                {agent?.capacity?.onGoingCallCapacity}
              </div>
            </div>
            <div className="flex flex-row justify-start gap-2">
              <ChatIcon className="text-grey-100" />
              <div
                className={`${
                  agent?.capacity?.onGoingChatCapacity > 0 ? 'text-green-emerald' : 'text-grey-100'
                } w-fit min-w-[4px] text-sm font-semibold`}>
                {agent?.capacity?.onGoingChatCapacity}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );

  const waitingList = () => (
    <div className="flex max-h-44 flex-col gap-2 overflow-y-scroll">
      {createdConversationList?.map(({ id, name, time }) => (
        <QueueListItem key={id} customerName={name} startTimer={time} />
      ))}
    </div>
  );

  const initialize = async () => {
    try {
      const resActiveAgent = await getAllActiveAgents(accountId);
      const resInboxUserList = await fetchInboxUserList(accountId, inboxId!);
      const allAgentInInbox = resInboxUserList?.inboxMembersList;
      const currentAgentList =
        allAgentInInbox?.filter((agent) =>
          resActiveAgent?.agentList?.find((a: any) => a.agentId === agent.agentId)
        ) || [];
      setAgentList(allAgentInInbox || []);
      setAgentCapacityList(currentAgentList);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  useEffect(() => {
    if (inboxId) initialize();
  }, [inboxId]);

  return (
    <Row className="flex h-max w-full items-center rounded-2xl bg-grey-10 p-6">
      <div className="flex w-full flex-row justify-between">
        <div className="flex flex-row gap-4">
          <div className="flex flex-col justify-center">
            <div className="rounded-full bg-green-emerald-light p-1">
              <SignalStreamIcon />
            </div>
          </div>
          <div className="flex flex-col">
            <div className="text-base font-semibold">Aktivitas saat ini</div>
            <div className="text-sm">Real-time dan diperbarui berkala</div>
          </div>
        </div>
        <div className="flex flex-row gap-6">
          <Popover
            zIndex={9}
            overlayClassName="recent-activity-popover"
            title={<div className="mb-4 text-2xl font-semibold">Agen online</div>}
            content={AgentList}
            trigger="click"
            arrow={false}
            placement="bottom"
            open={onlineAgentPopover}
            onOpenChange={setOnlineAgentPopover}>
            <div className="flex cursor-pointer flex-col space-y-1">
              <div className="text-sm font-semibold">Agen online</div>
              <div className="flex flex-row items-center gap-3">
                <div className="text-base font-semibold text-green-emerald">
                  {agentCapacityList?.length ?? 0}
                </div>
                <div>
                  <RightOutlined />
                </div>
              </div>
            </div>
          </Popover>
          <Popover
            zIndex={9}
            overlayClassName="recent-activity-popover"
            title={<div className="mb-4 text-2xl font-semibold">Dalam antrean</div>}
            content={waitingList}
            trigger="click"
            placement="bottom"
            arrow={false}
            open={queuePopover}
            onOpenChange={setQueuePopover}>
            <div className="flex cursor-pointer flex-col space-y-1">
              <div className="text-sm font-semibold">Dalam antrean</div>
              <div className="flex flex-row items-center gap-3">
                <div className="text-base font-semibold text-orange-danger">
                  {createdConversationList?.length}
                </div>
                <div>
                  <RightOutlined />
                </div>
              </div>
            </div>
          </Popover>
        </div>
      </div>
    </Row>
  );
}

export default RecentActivity;
