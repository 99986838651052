import { useEffect, useState } from 'react';

const useRealtime = () => {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const tick = () => setCurrentTime(new Date());
    const interval = setInterval(tick, 1000);

    return () => clearInterval(interval);
  }, []);

  return {
    currentTime
  };
};

export default useRealtime;
