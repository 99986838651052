import { Avatar, Button } from 'antd';
import ChatIcon from '../../../assets/icons/chat';
import VideoIcon from '../../../assets/icons/video';
import IncomingCallInterface from './incoming-call.interface';
import ExclamationTriangleIcon from '../../../assets/icons/exclamation-triangle';
import { useCallStore } from '../../../store/call-state';
import { useEffect, useState } from 'react';
import { useUserStatusStore } from '../../../store/user-state';
import { RoleEnum } from '../../../static/role';
import { DurationToString } from '../../../utils/timestamp';
import { useTimer } from '../../../hooks/useTimer';
import { CommunicationTypeEnum } from '../../../static/communication-type';
import { getUserJSON } from '../../../utils/user-json';
import Copy from '../../copy';
import dayjs from 'dayjs';

const MAXIMUM_UNOPENED_SECOND = 10;

export default function IncomingCall(props: IncomingCallInterface) {
  const { data, handleActiveCall, startTimer } = props;
  const { communicationType, conversationId, agent, createdAt } = data;
  const { addAttentionChat } = useCallStore();
  const { userRole } = useUserStatusStore();
  const { second } = useTimer(startTimer);

  const [requiresAttention, setRequiresAttention] = useState<boolean>(
    startTimer > MAXIMUM_UNOPENED_SECOND
  );

  const { account } = getUserJSON();
  const { accountName } = account || {};

  const agentName = `${agent?.firstName ?? ''} ${agent?.lastName ?? ''}`;
  const createdAtString = dayjs(createdAt).format('DD/MM/YYYY');

  const AvatarIcon = communicationType === CommunicationTypeEnum.VIDEO ? VideoIcon : ChatIcon;
  const PrimaryButtonIcon = requiresAttention ? (
    <ExclamationTriangleIcon className="text-black-90 [&>*]:h-[18px] [&>*]:w-[22px]" />
  ) : (
    <ChatIcon className="text-base text-black-90" />
  );

  useEffect(() => {
    if (second < MAXIMUM_UNOPENED_SECOND || requiresAttention) {
      return;
    }

    setRequiresAttention(true);
    addAttentionChat(conversationId);
  }, [second, requiresAttention, addAttentionChat, conversationId]);

  const onHandleAgent = () => {
    props.handleAssignAgent?.(data);
  };

  const durationText = DurationToString(second);

  return (
    <div
      className={`${
        requiresAttention ? 'bg-orange-danger' : 'bg-bold-green'
      } flex w-full flex-col rounded-lg p-6 shadow-lg`}>
      <div className="flex items-center gap-4 text-white">
        <Avatar
          size={48}
          className={`flex h-12 w-12 flex-row justify-center bg-white`}
          icon={
            <AvatarIcon
              className={`${requiresAttention ? 'text-orange-danger' : 'text-bold-green'} text-lg`}
            />
          }
        />
        <p className="m-0 flex-1 truncate p-0 text-lg font-semibold">{data?.name}</p>
      </div>
      <div className="my-4 h-[1px] w-full bg-white" />
      <div className="mb-2 flex flex-col items-start gap-1 truncate text-base text-white">
        <b className="m-0 p-0 text-base">{agentName.trim() || accountName}</b>
        <div className="m-0 inline-block p-0 text-base">
          <Copy value={conversationId}>Salin Tiket ID</Copy>
        </div>
        <div className="text-base font-normal">{durationText}</div>
        <div className="text-base">{createdAtString}</div>
      </div>
      <div className="flex flex-col gap-3">
        {userRole === RoleEnum.AGENT && (
          <Button
            onClick={async () => handleActiveCall(data)}
            className="h-12 w-full rounded-full border-none bg-white text-base font-semibold">
            Jawab
            {PrimaryButtonIcon}
          </Button>
        )}
        {userRole === RoleEnum.SUPERVISOR && (
          <div className="flex w-full justify-between gap-2">
            <Button
              onClick={onHandleAgent}
              className={`h-12 w-full rounded-full border-none bg-white text-base font-semibold`}>
              Tugaskan Advisor
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
