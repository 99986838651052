import { useEffect, useMemo, useRef, useState } from 'react';
import { Layout, Divider, Menu, ConfigProvider } from 'antd';
import { useQuery } from 'react-query';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { RoleEnum } from '../../static/role';
import { monthNamesShort, dayNames } from '../../static/date';
import { CurrentUserObjectInterface } from '../../shared/types/user.interface';
import { InboxListItemInterface } from '../../shared/types/inbox.interface';
import { useCallStore } from '../../store/call-state';
import { ConversationContextEnum } from '../../static/communication-type';
import { fetchInboxByAgent, fetchInboxList } from '../../api';
import { SidebarItemEnum, sideBarRoutes } from '../../static/routes';
import { useInboxDetailStore } from '../../store/inbox-state';
import { useChatListStore } from '../../store/chat-list';
import { useTeamStore } from '../../store/team-state';
import { URLPathEnum, analyticsDashboard, inboxDashboard } from '../../static/routeConstructor';
import SidebarV2 from '../v2/sidebar';

import './index.css';
import { Environment } from '../../config/app';
import { useInboxStore } from '../../store/new/inbox';
import { InboxInterface } from '../../types/new/inbox';

const { Sider } = Layout;
const { version } = require('../../../package.json');

function SidebarComponent() {
  const [currentUser, setCurrentUser] = useState<CurrentUserObjectInterface>();
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();

  const { inboxId, setInboxId } = useInboxDetailStore();
  const { resetSelectedCall, unreadMessagesInboxId } = useChatListStore();
  const { setIsManualKYCMode, setShowVideo } = useCallStore();
  const { setSelectedInbox } = useTeamStore();
  const { setSelectedInboxId, setInboxes } = useInboxStore();

  const isAgent = currentUser?.role === RoleEnum.AGENT;

  const { data: inboxList, isFetched: isFetchedInboxList } = useQuery(
    ['inboxList', currentUser],
    async () => {
      const accountId = currentUser?.account?.accountId;
      const inboxes = isAgent
        ? await fetchInboxByAgent(accountId!, currentUser?.agentId!)
        : await fetchInboxList(accountId!);
      setInboxes(inboxes.inboxList as unknown as InboxInterface[]);
      return inboxes;
    },
    { enabled: !!currentUser, select: (data) => data?.inboxList || [] }
  );

  const [selectedSidebarKey, setSelectedSidebarKey] = useState<string[]>([]);
  const v2 = location.pathname.includes('v2');

  const handleInboxItemClick = (
    menu: SidebarItemEnum,
    inbox: InboxListItemInterface,
    withRedirect: boolean = true
  ) => {
    if (inboxId && inboxId !== inbox.inboxId) {
      setShowVideo(false);
      localStorage.removeItem('meetingRoomId');
      localStorage.removeItem('isSwicthed');
      resetSelectedCall();
    }
    // user switch to another inbox, reset selected call
    setInboxId(inbox.inboxId);
    setIsManualKYCMode(inbox.inboxConfig.conversationContext === ConversationContextEnum.KYC);
    setSelectedSidebarKey([menu, `${menu}-${inbox.inboxId}`]);
    setSelectedInbox(inbox);
    setSelectedInboxId(inbox.inboxId);
    if (!withRedirect) return;
    if (menu === SidebarItemEnum.HOME) navigate(`${v2 ? '/v2' : ''}${inboxDashboard(inbox)}`);
    else if (menu === SidebarItemEnum.ANALYTICS) navigate(analyticsDashboard(inbox));
  };

  const _renderWithUnreadMessage = (inboxName: string, unreadMessageCount: number) => (
    <div className="flex gap-x-2 whitespace-break-spaces">
      {inboxName}
      {unreadMessageCount > 0 && (
        <span className="mt-1 flex h-[18px] w-[18px] items-center justify-center rounded-full bg-orange-danger text-xs">
          {unreadMessageCount}
        </span>
      )}
    </div>
  );
  const sidebarItems = useMemo(
    () =>
      sideBarRoutes
        .filter((item) => item.allow?.includes(currentUser?.role as RoleEnum))
        .map((item) => {
          if ([SidebarItemEnum.HOME, SidebarItemEnum.ANALYTICS].includes(item.key)) {
            /**
             * for analytics only show General Inquiry inbox since KYC related analytics still in development
             */
            const currentInboxList = inboxList;
            return {
              ...item,
              children: currentInboxList?.map((inbox) => {
                const subMenuItem = {
                  key: `${item.key}-${inbox.inboxId}`,
                  label: <>{inbox.name}</>,
                  onClick: () => handleInboxItemClick(item.key, inbox)
                };
                if (item.key === SidebarItemEnum.HOME && unreadMessagesInboxId[inbox.inboxId]) {
                  const unreadMessageCount = unreadMessagesInboxId[inbox.inboxId] || 0;
                  if (unreadMessageCount > 0)
                    subMenuItem.label = _renderWithUnreadMessage(inbox.name, unreadMessageCount);
                }
                return subMenuItem;
              })
            };
          }
          return {
            ...item,
            onClick: () => {
              if (!selectedSidebarKey.some((key) => key.includes(item.key))) {
                setSelectedSidebarKey([item.key]);
                navigate(item.url);
              }
            }
          };
        }),
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [sideBarRoutes, inboxList, currentUser, unreadMessagesInboxId, selectedSidebarKey]
  );

  useEffect(() => {
    const currentUser = JSON.parse(localStorage.getItem('user') || '{}');
    setCurrentUser(currentUser);
  }, []);

  /**
   * Set selected sidebar key based on the current location on first render
   */
  const firstRender = useRef(true);
  useEffect(() => {
    if (isFetchedInboxList && firstRender.current) {
      firstRender.current = false;
      const currentActiveMenu = sidebarItems.find((item) => location.pathname.includes(item.url));
      if (
        !currentActiveMenu &&
        location.pathname.includes(`/${URLPathEnum.DASHBOARD}`) &&
        inboxList?.length
      )
        handleInboxItemClick(SidebarItemEnum.HOME, inboxList[0]);
      else if (currentActiveMenu) {
        const keys: string[] = [currentActiveMenu.key];
        if (params.inboxId) keys.push(`${currentActiveMenu.key}-${params.inboxId}`);
        setSelectedSidebarKey(keys);
        if ([SidebarItemEnum.HOME, SidebarItemEnum.ANALYTICS].includes(currentActiveMenu.key)) {
          const inboxItem = inboxList?.find((item) => item.inboxId === params.inboxId);
          if (!inboxItem) return;
          handleInboxItemClick(currentActiveMenu.key, inboxItem, false);
        }
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, params, isFetchedInboxList, firstRender]);

  /*
   * Set selected sidebar key based on the current location change
   * 1. user click "Jawab" notification and redirect to corresponding inbox
   */
  useEffect(() => {
    if (isFetchedInboxList && params.inboxId && !firstRender.current) {
      const currentActiveMenu = sidebarItems.find((item) => location.pathname.includes(item.url));
      const isCurrentInboxChange = selectedSidebarKey.every(
        (key) => key.includes(params.inboxId!!) || key === currentActiveMenu?.key
      );
      if (currentActiveMenu && !isCurrentInboxChange) {
        const keys: string[] = [currentActiveMenu.key];
        if (params.inboxId) keys.push(`${currentActiveMenu.key}-${params.inboxId}`);
        setSelectedSidebarKey(keys);
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, firstRender]);

  const defaultOpenKeys = location.pathname.includes(
    `${URLPathEnum.DASHBOARD}/${URLPathEnum.ANALYTICS}`
  )
    ? SidebarItemEnum.ANALYTICS
    : SidebarItemEnum.HOME;
  const today: Date = new Date();

  if (v2) {
    return (
      <div className="sidebar-v2 fixed left-0 top-[64px] z-50 drop-shadow-md">
        <SidebarV2
          inboxList={inboxList}
          currentUser={currentUser}
          handleInboxItemClick={handleInboxItemClick}
        />
      </div>
    );
  }

  return (
    <ConfigProvider
      theme={{
        token: { colorBgBase: '#0A2730', colorTextBase: '#FFFFFF', colorPrimary: '#FFFFFF' }
      }}>
      <Sider width={200} className="fixed left-0 top-16 h-full overflow-auto bg-black-rich">
        <div className="p-6">
          <div className="text-2xl font-semibold text-white">{dayNames[today.getDay()]},</div>
          <div className="text-2xl font-semibold text-white">
            {today.getDate()} {monthNamesShort[today.getMonth()]}
          </div>
          {process.env.REACT_APP_ENV === Environment.STAGING && (
            <div className="italic text-white">version: {version}</div>
          )}
          <Divider className="bg-grey-50" />
          <Menu
            mode="inline"
            inlineIndent={4}
            items={sidebarItems}
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={selectedSidebarKey}
            style={{ borderInlineEnd: 'none' }}
          />
        </div>
      </Sider>
    </ConfigProvider>
  );
}

export default SidebarComponent;
