import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Row } from 'antd';
import { Paragraph } from '@squantumengine/horizon';

import { createCallListComponent } from '../dashboard.helper';
import useConversation from '../../../../hooks/useConversation';

import ArrowPagination from '../../../../components/v2/pagination/arrow-pagination';

import { ConversationInterface } from '../../../../components/card/chat-message/chat-message.interface';
import { useConversationListStore } from '../../../../store/conversation-list-state';
import { useUserStatusStore } from '../../../../store/user-state';
import { RoleEnum } from '../../../../static/role';
import SkeletonTicket from './skeleton-ticket';

type TicketOngoingProps = {
  setAssignAgentConversation?: React.Dispatch<React.SetStateAction<any>>;
  setModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  filter: {
    email: string;
    name: string;
    ticketNumber: string;
    phoneNumber: string;
    startDate: string;
    endDate: string;
  };
};

export type TicketOngoingComponent = {
  refetchTicketOngoing: () => any;
  isFetching: () => boolean;
};

const TicketOngoing = forwardRef<TicketOngoingComponent, TicketOngoingProps>(
  ({ setAssignAgentConversation, setModalOpen, filter: propsFilter }: TicketOngoingProps, ref) => {
    const [page, setPage] = useState(1);
    const [filter, setFilter] = useState(propsFilter);
    const { assignedConversationList } = useConversationListStore();
    const { userRole } = useUserStatusStore();
    const isAgent = userRole === RoleEnum.AGENT;

    const { refetch, isFetching } = useConversation({
      filter,
      active: true,
      page,
      limit: 9
    });

    const isPrevDisabled = page === 1;
    const isNextDisabled = assignedConversationList.length < 9;

    const handlePrevPage = () => {
      if (isPrevDisabled) return;
      setPage((prev) => prev - 1);
    };

    const handleNextPage = () => {
      if (isNextDisabled) return;
      setPage((prev) => prev + 1);
    };

    const handleAssignAgent = (conversation: ConversationInterface) => {
      setAssignAgentConversation && setAssignAgentConversation(conversation);
      setModalOpen && setModalOpen(true);
    };

    const reInitialize = () => {
      setPage(() => {
        refetch();
        return 1;
      });
    };

    useImperativeHandle(ref, () => ({
      refetchTicketOngoing: reInitialize,
      isFetching: () => isFetching
    }));

    useEffect(() => {
      setPage(1);
      setFilter(propsFilter);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      propsFilter.startDate,
      propsFilter.endDate,
      propsFilter.email,
      propsFilter.ticketNumber,
      propsFilter.phoneNumber,
      propsFilter.name
    ]);

    return (
      <div>
        <div className={`flex items-center justify-between bg-neutral-50 px-4 py-2`}>
          <Paragraph size="r" weight="bold">
            Tiket Berlangsung ({assignedConversationList.length})
          </Paragraph>

          <ArrowPagination
            disableNext={isNextDisabled}
            disablePrev={isPrevDisabled}
            onClickNext={handleNextPage}
            onClickPrev={handlePrevPage}
          />
        </div>

        {isFetching && !isAgent && <SkeletonTicket />}

        {assignedConversationList.length > 0 && (
          <Row className={`${isAgent ? 'grid-cols-1' : 'grid-cols-3 gap-4 p-4'} grid bg-white`}>
            {createCallListComponent(assignedConversationList, handleAssignAgent)}
          </Row>
        )}
      </div>
    );
  }
);

export default TicketOngoing;
