import { Checkbox, Col, Flex, Form, FormInstance, Input, Radio, Row, Select } from 'antd';
import {
  MethodConvEnum,
  TypeCallEnum,
  TypeConvEnum,
  typeConvOptions,
  typeWhatsappConvOptions
} from '.';
import FormListItem, { FormListItemTypeEnum, ListItemProps } from './form-list-item';
import useTeamCreateEditStore from '../../store';
import { ChannelEnum } from '../../type';
import { useEffect } from 'react';
import { CommunicationTypeEnum } from '../../../../../static/communication-type';
import CustomSmallTooltip from '../../../../../components/form/inbox/custom-small-tooltip';

type Props = {
  form: FormInstance;
  typeCallOptions: (
    | {
        label: string;
        value: string;
        disabled: boolean;
      }
    | {
        label: string;
        value: string;
        disabled?: undefined;
      }
  )[];
};

export const channelOptions = [
  { value: ChannelEnum.WEB_WIDGET, label: 'Web Widget' },
  { value: ChannelEnum.WHATSAPP, label: 'WhatsApp' }
];

const whatsappServiceOptions = [{ value: 'garasi', label: 'Garasi WhatsApp Service' }];

export default function TeamInformation(props: Props) {
  const { form, typeCallOptions } = props;
  const { setTopicList, setRejectReasonList, formTeamConfiguration } = useTeamCreateEditStore();

  const channelForm = Form.useWatch('channelType', form);
  const methodConvForm = Form.useWatch('communicationModes', form);
  const typeConversationForm = Form.useWatch('conversationContext', form);

  const isWebWidget = channelForm === ChannelEnum.WEB_WIDGET;
  const isWhatsApp = channelForm === ChannelEnum.WHATSAPP;
  const isManualKYC = typeConversationForm === TypeConvEnum.MANUAL_KYC;
  const isChannelFilled = !!channelForm;

  const methodConvOptions = [
    { label: 'Chat', value: 'CHAT', disabled: isManualKYC },
    {
      label: (
        <Flex align="center" gap="4">
          <p className="m-0 p-0">Panggilan</p>
          <CustomSmallTooltip text="Metode Percakapan Panggilan belum tersedia untuk channel WhatsApp" />
        </Flex>
      ),
      value: 'AUDIO_VIDEO',
      disabled: channelForm === ChannelEnum.WHATSAPP
    }
  ];

  useEffect(() => {
    if (isManualKYC) {
      form.setFieldsValue({
        communicationModes: [CommunicationTypeEnum.VIDEO],
        defaultCommunicationType: TypeCallEnum.VIDEO
      });
      useTeamCreateEditStore.setState((state) => ({
        ...state,
        formTeamConfiguration: {
          ...state.formTeamConfiguration,
          communicationModes: [CommunicationTypeEnum.VIDEO],
          defaultCommunicationType: TypeCallEnum.VIDEO
        }
      }));
    }
    if (channelForm === ChannelEnum.WHATSAPP) {
      form.setFieldsValue({
        communicationModes: [CommunicationTypeEnum.CHAT]
      });
      useTeamCreateEditStore.setState((state) => ({
        ...state,
        formTeamConfiguration: {
          ...state.formTeamConfiguration,
          communicationModes: [CommunicationTypeEnum.CHAT]
        }
      }));
    }
  }, [isManualKYC, channelForm, form]);

  return (
    <>
      <Row gutter={16}>
        <Col span={24} className="p-0">
          <Form.Item
            name="inboxName"
            rules={[{ required: true, message: 'Nama Tim wajib diisi' }]}
            label={<span className="text-base font-semibold">Nama Tim</span>}>
            <Input placeholder="Masukkan Nama Tim" />
          </Form.Item>
        </Col>
        <Col span={24} className="p-0">
          <Form.Item
            rules={[{ required: true, message: 'Channel wajib dipilih' }]}
            name="channelType"
            label={<span className="text-base font-semibold">Channel</span>}>
            <Select placeholder="Pilih Channel" options={channelOptions} />
          </Form.Item>
        </Col>
        {isWebWidget && (
          <Col span={24}>
            <Form.Item
              className="m-0 p-0"
              initialValue={typeConvOptions[0]?.value}
              name="conversationContext"
              rules={[{ required: true, message: 'Tipe Percakapan wajib diisi' }]}
              label={<span className="text-base font-semibold">Tipe Percakapan</span>}>
              <Radio.Group options={typeConvOptions} />
            </Form.Item>
          </Col>
        )}
        {isChannelFilled && (
          <Col span={24}>
            <Form.Item
              className="m-0 p-0"
              initialValue={methodConvOptions[0]?.value}
              name="communicationModes"
              rules={[{ required: true, message: 'Metode Percakapan wajib diisi' }]}
              label={<span className="text-base font-semibold">Metode Percakapan</span>}>
              <Checkbox.Group options={methodConvOptions} />
            </Form.Item>
          </Col>
        )}
        {isWhatsApp && (
          <Col span={24}>
            <Form.Item
              initialValue={whatsappServiceOptions[0]?.value}
              name="whatsapp_service"
              required
              label={<span className="text-base font-semibold">Penggunaan WhatsApp Service</span>}>
              <Select options={whatsappServiceOptions} />
            </Form.Item>
            <Col span={24}>
              <Form.Item
                className="m-0 p-0"
                initialValue={typeWhatsappConvOptions[1]?.value}
                name="supportMode"
                rules={[{ required: true, message: 'Percapakan akan dibalas oleh wajib diisi' }]}
                label={
                  <span className="text-base font-semibold">Percapakan akan dibalas oleh</span>
                }>
                <Radio.Group options={typeWhatsappConvOptions} />
              </Form.Item>
            </Col>
          </Col>
        )}
        {isWebWidget && methodConvForm?.includes(MethodConvEnum.CALL) && (
          <Col span={24}>
            <Form.Item
              initialValue={formTeamConfiguration.defaultCommunicationType}
              name="defaultCommunicationType"
              required
              label={<span className="text-base font-semibold">Tipe Panggilan</span>}>
              <Radio.Group options={typeCallOptions} />
            </Form.Item>
          </Col>
        )}
        <div className="flex w-full justify-start space-x-4">
          <Form.Item
            name="topic"
            label={<span className="text-base font-semibold">Topik Percakapan</span>}
            className="flex-1">
            <FormListItem
              mode={FormListItemTypeEnum.TOPIC}
              onChange={(value: ListItemProps[]) => {
                setTopicList(value);
              }}
              itemName="Topic"
              inputProps={{ placeholder: 'Isi Topik Baru' }}
            />
          </Form.Item>
          {isManualKYC && (
            <Form.Item
              name="reject_reason"
              label={<span className="text-base font-semibold">Alasan Penolakan KYC</span>}
              className="flex-1">
              <FormListItem
                mode={FormListItemTypeEnum.REJECT_REASON}
                onChange={(value: ListItemProps[]) => {
                  setRejectReasonList(value);
                }}
                itemName="Alasan Penolakan"
                inputProps={{ placeholder: 'Isi Alasan Penolakan Baru' }}
              />
            </Form.Item>
          )}
        </div>
      </Row>
    </>
  );
}
