import { useRef, useEffect, useState } from 'react';
import { MessageEventEnum } from '../../../static/message-event';
import TimestampToString from '../../../utils/timestamp';
import IncomingChatCall from '../incoming-call/incomingChatcall';
import { ChatMessageInterface } from './chat-message.interface';
import { useChatListStore } from '../../../store/chat-list';
import FileUpload from '../chat-file-upload';
import { addInitialHeight, expandedChatAnimation, isActiveCall } from '../../../utils/chat-message';

function ChatMessage({
  id,
  isUser,
  message,
  time,
  isBusy,
  isBot,
  type,
  documents,
  conversationId,
  event
}: ChatMessageInterface) {
  const firstUpdate = useRef(true);
  const [show, setShow] = useState<boolean>(true);

  useEffect(() => {
    if (firstUpdate.current) {
      addInitialHeight(id);
      firstUpdate.current = false;
      return;
    }
    expandedChatAnimation(id);
  }, [isBusy]); // eslint-disable-line react-hooks/exhaustive-deps
  const { selectedCall } = useChatListStore();
  const activeCall = isActiveCall(selectedCall?.event || '');

  if (type === MessageEventEnum.CALL) {
    if (!show || !activeCall) {
      return <></>;
    }

    return (
      <IncomingChatCall
        setShow={setShow}
        messageId={id}
        conversationId={conversationId}
        startTime={time}
      />
    );
  }

  return (
    <>
      {(message || documents) && show && (
        <div
          id={id}
          className={`
        mb-2 flex h-auto 
        flex-col p-4 text-left transition-all duration-200
        ${
          isUser
            ? 'ml-6 mr-[72px] rounded-b-2xl rounded-r-2xl bg-white'
            : `ml-[72px] mr-6 rounded-l-2xl rounded-t-2xl bg-chat-green`
        }
          border border-solid border-border-gray`}>
          <div className="mb-2 flex flex-col gap-4 whitespace-pre-line break-normal break-words text-base	font-normal">
            {type === MessageEventEnum.TEXT
              ? isBusy
                ? '• • •'
                : message
              : documents?.map((items: any, key) => (
                  //TODO: need file validation image file or not
                  <div key={key} className=" overflow-hidden">
                    <FileUpload data={items} />
                  </div>
                ))}
          </div>
          <div className="itmes-center flex flex-row justify-between text-sm font-normal text-chat-gray">
            <div>{isBot ? 'pesan otomatis' : ''}</div>
            <div>{TimestampToString(time)}</div>
          </div>
        </div>
      )}
    </>
  );
}

export default ChatMessage;
