import { Row, Col, Form, ConfigProvider, Switch, Alert, notification } from 'antd';
import { useEffect, useState } from 'react';

import { workingHour } from '../../../shared/types/inbox.interface';
import { NumberToTimeString } from '../../../utils/timestamp';

import TimeSelector from './time-selector';
import { defaultOperationalHour } from '../../../static/working-hour';
import { InfoCircleOutlined } from '@ant-design/icons';
import { defaultWorkingHourValues } from '../../../static/inbox-config';

function OperationalHourSelectorForm(
  props: workingHour & {
    selectedDays: number[];
    updateDayWorkingHour: (workingHour: workingHour[]) => void;
  }
) {
  const [form] = Form.useForm();
  const [api, contextHolder] = notification.useNotification();
  const { setFieldsValue } = form;
  const { closedAllDay, openAllDay, selectedDays, updateDayWorkingHour } = props;
  const [openForOperation, setOpenForOperation] = useState<boolean>(!closedAllDay);
  const [open24Hour, setOpen24Hour] = useState<boolean>(closedAllDay || openAllDay);
  const [invalidTime, setInvalidTime] = useState<boolean>(false);

  const saveOperationHours = () => {
    const { openHour, openMinutes, closeHour, closeMinutes } = form.getFieldsValue(true);
    if (!invalidTime || open24Hour) {
      const closedAllDay = !openForOperation;
      const openAllDay = open24Hour && openForOperation;
      const requestWorkingHour = selectedDays.map((day) => {
        const currentDayData = {
          dayOfWeek: day,
          openAllDay,
          closedAllDay
        };

        if (!closedAllDay || !openAllDay) {
          return {
            ...currentDayData,
            openHour: parseInt(openHour),
            openMinutes: parseInt(openMinutes),
            closeHour: parseInt(closeHour),
            closeMinutes: parseInt(closeMinutes)
          } as workingHour;
        }

        return currentDayData as workingHour;
      });
      updateDayWorkingHour(requestWorkingHour);
    }
  };

  const onChangeTime = (name: string, value: number) => {
    setFieldsValue({ [name]: value });
    const { openHour, openMinutes, closeHour, closeMinutes } = form.getFieldsValue(true);
    const openTime = NumberToTimeString(openHour) + NumberToTimeString(openMinutes);
    const closeTime = NumberToTimeString(closeHour) + NumberToTimeString(closeMinutes);
    setInvalidTime(+openTime > +closeTime);
  };

  useEffect(() => {
    form.setFieldsValue({ ...props });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  return (
    <>
      <ConfigProvider
        theme={{
          token: { colorText: 'white', colorBgElevated: '#2DB089', colorIcon: 'white' }
        }}>
        {contextHolder}
      </ConfigProvider>
      <Form form={form} autoComplete="off" size="large" initialValues={defaultWorkingHourValues}>
        <Row className={`${open24Hour && 'text-grey-100'}`}>
          <Col span={11}>
            <div className="text-base">Waktu mulai</div>
            <Row gutter={16} className="mt-2">
              <Col span={12}>
                <TimeSelector
                  form={form}
                  disabled={open24Hour}
                  timeName="openHour"
                  onChange={onChangeTime}
                />
              </Col>
              <Col span={12}>
                <TimeSelector
                  form={form}
                  disabled={open24Hour}
                  timeName="openMinutes"
                  onChange={onChangeTime}
                />
              </Col>
            </Row>
          </Col>
          <Col span={2} className="flex flex-col items-center justify-center">
            -
          </Col>
          <Col span={11}>
            <div className="text-base">Waktu selesai</div>
            <Row gutter={16} className="mt-2">
              <Col span={12}>
                <TimeSelector
                  form={form}
                  disabled={open24Hour}
                  timeName="closeHour"
                  onChange={onChangeTime}
                />
              </Col>
              <Col span={12}>
                <TimeSelector
                  form={form}
                  disabled={open24Hour}
                  timeName="closeMinutes"
                  onChange={onChangeTime}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {invalidTime && !open24Hour && (
              <Row className="mb-4">
                <Col span={24}>
                  <Alert
                    className="error-alert bg-grey-10 text-base"
                    message="Waktu mulai harus lebih awal dari waktu selesai"
                    type="warning"
                    showIcon
                  />
                </Col>
              </Row>
            )}
          </Col>
        </Row>
        <Row>
          <ConfigProvider theme={{ token: { colorPrimary: '#1A936F' } }}>
            <div className="flex flex-row items-center pr-6">
              <Switch
                checked={openForOperation}
                onChange={(checked) => {
                  setOpenForOperation(checked);
                  if (!checked) {
                    if (!open24Hour)
                      api.open({
                        icon: <InfoCircleOutlined style={{ color: 'white' }} />,
                        message: 'Layanan 24 jam diaktifkan',
                        description:
                          'Layanan 24 jam akan diaktifkan secara otomatis karena anda menonaktifkan layanan',
                        placement: 'topRight'
                      });
                    setOpen24Hour(true);
                    setInvalidTime(false);
                    form.setFieldsValue(defaultOperationalHour);
                  }
                }}
              />
              <div className="ml-2 text-base">Buka</div>
            </div>
            <div className="flex flex-row items-center">
              <Switch
                checked={open24Hour}
                onChange={(checked) => {
                  setOpen24Hour(checked);
                  if (!checked) {
                    setOpenForOperation(true);
                    if (!openForOperation)
                      api.open({
                        icon: <InfoCircleOutlined style={{ color: 'white' }} />,
                        message: 'Jam operasional diaktifkan',
                        description:
                          'Jam operasional akan diaktifkan secara otomatis karena anda menonaktifkan layanan 24 jam',
                        placement: 'topRight'
                      });
                  } else {
                    setInvalidTime(false);
                    form.setFieldsValue(defaultOperationalHour);
                  }
                }}
              />
              <div className="ml-2 text-base">Layanan 24 Jam</div>
            </div>
          </ConfigProvider>
        </Row>
        <Row>
          <Col span={24} className="mt-4">
            <div
              id="workhour-edit_all_schedule"
              className={`team-action-confirm-button ${
                invalidTime && !open24Hour && 'cursor-not-allowed bg-grey-50'
              }`}
              onClick={saveOperationHours}>
              Simpan
            </div>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default OperationalHourSelectorForm;
