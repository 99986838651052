import { create } from 'zustand';
import { InboxDetailStoreInterface } from './inbox-state.interface';
import { InboxListItemInterface, InboxMemberListInterface } from '../shared/types/inbox.interface';

export const useInboxDetailStore = create<InboxDetailStoreInterface>((set) => ({
  inboxList: [],
  inboxDetail: {},
  setInboxDetail: (inboxDetail: any) => set({ inboxDetail }),
  setInboxList: (inboxList: InboxListItemInterface[]) => set({ inboxList }),
  inboxId: '',
  setInboxId: (inboxId: string) => set({ inboxId }),
  activeAgentList: [],
  setActiveAgentList: (activeAgentList: InboxMemberListInterface[]) => set({ activeAgentList }),
  addActiveAgent: (agent: InboxMemberListInterface) =>
    set((state) => ({ activeAgentList: [...state.activeAgentList, agent] })),
  removeActiveAgent: (agentId: string) =>
    set((state) => ({
      activeAgentList: state.activeAgentList.filter((a) => a.agentId !== agentId)
    }))
}));
