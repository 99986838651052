import { HEIGHT_BAR, KYCActivityBarChartInterface } from '../../../../static/analysis';

const KYCActivityBarChart = ({
  indexItem,
  data,
  maxVal: MAX_VAL,
  items
}: KYCActivityBarChartInterface) => {
  const kycDataItem = data[indexItem / 2];
  const heightAccepted = (kycDataItem?.acc * HEIGHT_BAR) / MAX_VAL;
  const heightRejected = (kycDataItem?.rejected * HEIGHT_BAR) / MAX_VAL;
  const heightPadding = HEIGHT_BAR - heightAccepted - heightRejected;

  return (
    <div key={indexItem} className="z-30 flex w-[15rem] flex-col lg:w-[20rem]">
      <div className="h-[5rem] w-full">
        <p className="m-0 p-0 text-lg">{kycDataItem?.label}</p>
        <p className="m-0 p-0 text-2xl font-semibold">{kycDataItem?.count}</p>
      </div>
      <div className="flex h-[20rem] w-full flex-col justify-end">
        <div
          style={{ height: `${heightPadding}rem`, backgroundColor: 'rgba(233, 242, 253, 1)' }}
          className="cursor-pointer"></div>
        <div
          style={{
            height: `${heightRejected}rem`,
            backgroundColor: `${indexItem !== items!?.length - 1 && 'rgba(233, 242, 253, 1)'}`
          }}
          className={`cursor-pointer ${indexItem === items!?.length - 1 && 'bg-red-100'}`}></div>
        <div
          style={{ height: `${heightAccepted}rem` }}
          className="cursor-pointer bg-yellow-200"></div>
      </div>
    </div>
  );
};

export default KYCActivityBarChart;
