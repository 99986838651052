import { PlusOutlined } from '@ant-design/icons';
import {
  Button,
  ColumnFiltersState,
  Hook,
  PaginationState,
  Paragraph,
  Title,
  Table,
  Token
} from '@squantumengine/horizon';
import { ConfigProvider, Flex, Form, Pagination, PaginationProps } from 'antd';
import { useEffect, useState } from 'react';
import { UserResponse } from '../../../../components/card/user/user.interface';
import { useUserStatusStore } from '../../../../store/user-state';
import useCCToaster from '../../../../hooks/useCCToaster';
import { useGetUsers } from '../../../../api/hooks/sqeid/useSQEID';
import SearchFilter from '../../../../components/team-users/search-filter';
import { itemsInvUsr } from '../../../../static/search-filter';
import { RoleEnum } from '../../../../static/role';
import { invUserNATIVEColumns } from '../helper';
import { useUnblockUser } from '../../../../api/hooks/native/useUnblockUser';
import UserBUModal from '../bu-modal';
import UserConfirmationModal from './user-confirmation-modal';
import { useTeamStore } from '../../../../store/team-state';
import UserInviteModal from './user-inv-modal';
import UserEditModal from './user-edit-modal';

const { COLORS } = Token;

const ListUserNATIVE = () => {
  const [isModalEditOpen, setIsModalEditOpen] = useState<boolean>(false);
  const [isModalConfirmOpen, setIsModalConfirmOpen] = useState<boolean>(false);
  const [isModalInvUserOpen, setIsModalInvUserOpen] = useState<boolean>(false);
  const [isModalBUOpen, setIsModalBUOpen] = useState<boolean>(false);
  const [selectedData, setSelectedData] = useState<UserResponse>({} as UserResponse);
  const [filterData, setFilterData] = useState<UserResponse[] | undefined>();
  const [organizationId, setOrganizationId] = useState<string>('');
  const [selectedBU, setSelectedBU] = useState<{ key: string; label: string }>();
  const { fetchAccount } = useTeamStore();

  const { userRole } = useUserStatusStore();

  const { contextHolder, openNotification } = useCCToaster({ timeout: 6 });

  const { data: invUserData, isLoading } = useGetUsers();
  const { mutateAsync: unblockUser } = useUnblockUser(openNotification);

  const [formInvite] = Form.useForm();
  const [formEdit] = Form.useForm();

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10
  });

  const table = Hook.useTable({
    data: filterData,
    columns: invUserNATIVEColumns(
      setIsModalEditOpen,
      setSelectedData,
      userRole === RoleEnum.ADMIN,
      unblockUser,
      openNotification
    ),
    pagination,
    setPagination,
    columnFilters,
    setColumnFilters,
    loadingSkeletonRowCount: 10,
    isLoading
  });

  const onShowSizeChange: PaginationProps['onShowSizeChange'] = (current, pageSize) => {
    setPagination({
      pageIndex: current - 1,
      pageSize
    });
  };

  const onChange: PaginationProps['onChange'] = (current, pageSize) => {
    setPagination({
      pageIndex: current - 1,
      pageSize
    });
  };

  const handleInviteUser = async () => {
    if (userRole === RoleEnum.SUPERVISOR) {
      setIsModalInvUserOpen(true);
    } else {
      setIsModalBUOpen(true);
    }
  };

  const doInviteUser = async (organizationId?: string) => {
    setIsModalInvUserOpen(true);
    setOrganizationId(organizationId!);
  };

  useEffect(() => {
    setFilterData(invUserData);
  }, [invUserData]);

  useEffect(() => {
    const initAccount = async () => {
      if (userRole === RoleEnum.ADMIN) {
        await fetchAccount();
      }
    };

    initAccount();
  }, [isModalBUOpen]);

  return (
    <ConfigProvider
      prefixCls="cc"
      theme={{
        token: {
          colorPrimary: `${COLORS.blue[500]}`
        },
        components: {
          Pagination: {
            colorBgContainer: `${COLORS.blue[500]}`
          }
        }
      }}>
      {contextHolder}
      <UserInviteModal
        form={formInvite}
        setIsModalOpen={setIsModalInvUserOpen}
        setIsModalConfirmOpen={setIsModalConfirmOpen}
        isModalOpen={isModalInvUserOpen}
        organizationId={organizationId}
        selectedBU={selectedBU}
      />
      <UserBUModal
        inviteUser={doInviteUser}
        setIsModalOpen={setIsModalBUOpen}
        isModalOpen={isModalBUOpen}
        setSelectedBU={setSelectedBU}
      />
      <UserEditModal
        form={formEdit}
        setIsModalOpen={setIsModalEditOpen}
        isModalOpen={isModalEditOpen}
        selectedUser={selectedData}
      />
      <UserConfirmationModal
        setIsModalOpen={setIsModalConfirmOpen}
        setIsModalEditOpen={setIsModalEditOpen}
        isModalOpen={isModalConfirmOpen}
        selectedData={selectedData}
        setSelectedData={setSelectedData}
      />
      <div className="flex flex-col gap-4 bg-[#F9FBFF] p-8">
        <Flex align="center" justify="space-between">
          <Title level={3}>Atur Agen</Title>
          <Button
            onClick={handleInviteUser}
            children={
              <Flex gap={4} align="center">
                <PlusOutlined />
                <Paragraph>Tambah Agen</Paragraph>
              </Flex>
            }
          />
        </Flex>
        <Flex gap={10} align="start" vertical className="w-full rounded-xl bg-white p-8 shadow-sm">
          <Flex className="w-1/2">
            <SearchFilter setData={setFilterData} data={invUserData} items={itemsInvUsr} />
          </Flex>

          <Flex className="w-full" vertical align="center" justify="center" gap={8}>
            <Table
              data={filterData}
              table={table}
              columns={invUserNATIVEColumns(
                setIsModalEditOpen,
                setSelectedData,
                userRole === RoleEnum.ADMIN,
                unblockUser,
                openNotification
              )}
              isLoading={isLoading}
            />
            <Pagination
              showSizeChanger
              onShowSizeChange={onShowSizeChange}
              onChange={onChange}
              defaultCurrent={1}
              total={filterData?.length}
            />
          </Flex>
        </Flex>
      </div>
    </ConfigProvider>
  );
};

export default ListUserNATIVE;
