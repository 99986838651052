import { Col, Form, Typography, Radio } from 'antd';
import { RadioBtnInterface } from '../../../shared/types/inbox.interface';
import CustomSmallTooltip from './custom-small-tooltip';

function RadioBtn(props: RadioBtnInterface) {
  const { value, title, event, options, tooltip, disabled } = props;
  const { Text } = Typography;

  return (
    <>
      <Col span={24}>
        <Text className="text-base font-semibold">{value}</Text>
        {!!tooltip && <CustomSmallTooltip text={tooltip} />}
      </Col>
      <Col>
        <Form.Item name={title} style={{ display: 'inline' }} required>
          <Radio.Group name={title} options={options} onChange={event} disabled={disabled} size="large" />
        </Form.Item>
      </Col>
    </>
  );
}

export default RadioBtn;
