import axiosInstance from '../config/axios';
import { SelectedCustomerInterface } from '../shared/types/customer.interface';
import { CurrentUserObjectInterface } from '../shared/types/user.interface';
import { UpdateCustomerDetails } from './param.interface';

const currentUser: CurrentUserObjectInterface = JSON.parse(localStorage.getItem('user') || '{}');
const accountId: string = currentUser.account?.accountId || '';

export const fetchCustomerDetails: (
  accountId: string,
  contactId: string
) => Promise<SelectedCustomerInterface> = async (accountId, contactId) => {
  const response = await axiosInstance.get(`account/${accountId}/contact/${contactId}`);
  return response.data;
};

export const updateCustomerDetails = async ({
  accountId,
  contactId,
  gender,
  dateOfBirth,
  motherMaidenName
}: UpdateCustomerDetails) => {
  const response = await axiosInstance.put(`account/${accountId}/contact/${contactId}`, {
    gender,
    dateOfBirth,
    motherMaidenName
  });
  return response?.data;
};

export const patchCustomerDetails = async (contactId: string, payload: Record<string, any>) =>
  axiosInstance.put(`account/${accountId}/contact/${contactId}`, payload);
