import { useEffect, useState } from 'react';
import {
  Col,
  Row,
  Spin,
  Typography,
  Modal,
  Form,
  Input,
  Select,
  Alert,
  Button,
  message
} from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useTeamStore } from '../../../store/team-state';
import UserInfo from './user-info';
import { deleteUser, getAgentMaxChat, getUserDetail, updateAgent } from '../../../api/user';
import { setUserPassword } from '../../../api';
import { DISABLE_BTN_DURATION, maxChatSelection } from '../../../static/dashboard';
import { getIconUrl } from '../../../utils/getIconUrl';
import { AgentMaxChatInterface } from '../../../shared/types/agent.interface';
import handleSetTimeout from '../../../utils/handleSetimeout';
import { RoleEnum } from '../../../static/role';

function UserDetail() {
  const { Title, Text } = Typography;
  const [messageApi, contextHolder] = message.useMessage();

  const [form] = Form.useForm();

  const { selectedUser, setIsOpenForm, resetTeamActiveHandler, fetchSupervisorItemList } =
    useTeamStore();
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [user, setUser] = useState<any>({});
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [maxChat, setMaxChat] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(false);

  const showModal = () => {
    setOpen(true);
  };
  const userListItem = useTeamStore((state) => state.user);
  const setUserListItem = useTeamStore((state) => state.setUser);

  const handleOk = async () => {
    try {
      setConfirmLoading(true);
      await delUser();

      const newUser = userListItem.map((data) => ({
        accountId: data.accountId,
        inboxId: data.inboxId,
        userList: data.userList.filter((item) => item.agentId !== selectedUser.agentId)
      }));
      setUserListItem(newUser);
    } catch (err) {
      // handle error
      // eslint-disable-next-line no-console
      console.error(err);
    } finally {
      setOpen(false);
      setConfirmLoading(false);
    }
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const closeForm = () => {
    setIsOpenForm(false);
  };

  const delUser = async () => {
    setLoading(true);
    try {
      await deleteUser(selectedUser?.agentId);
      setIsOpenForm(false);
      resetTeamActiveHandler();
      fetchSupervisorItemList(user.account.accountId);
    } catch (error: any) {
      messageApi.open({
        type: 'error',
        content: error
      });
    } finally {
      setLoading(false);
    }
  };

  const updateMaxChat = async () => {
    const isNotPrevValue = form.getFieldValue('maxChatCap') !== maxChat;

    try {
      if (isNotPrevValue) {
        await updateAgent({
          accountId: user.account.accountId,
          agentId: user?.agentId,
          maxChat: form.getFieldValue('maxChatCap').split(' ')[0]
        });

        messageApi.open({
          type: 'success',
          content: 'Update maximum chat berhasil'
        });
      }
    } catch (error: any) {
      if (error.response) {
        switch (error.response?.data?.status) {
          case 400:
            setErrorMessage('Tidak bisa update capacity ketika active conversation');
            break;
          default:
            setErrorMessage('Terjadi kesalahan, silahkan coba lagi nanti');
            break;
        }
      }
    } finally {
      setLoading(false);
      handleSetTimeout({
        onSetTimeout: () => setIsSubmitDisabled(false),
        interval: DISABLE_BTN_DURATION
      });
    }
  };

  const updatePassword = async () => {
    const newPassword = form.getFieldValue('password');
    const isNotPrevValue = newPassword !== password;

    try {
      if (isNotPrevValue && newPassword) {
        await setUserPassword({
          username: user?.email,
          password: form.getFieldValue('password')
        });

        form.setFieldsValue({ password: '' });
        setPassword('');
        messageApi.open({
          type: 'success',
          content: 'Update password berhasil'
        });
      }
    } catch (error: any) {
      if (error.response) {
        switch (error.response?.data?.status) {
          case 409:
            setErrorMessage('User sudah terdaftar');
            break;
          default:
            setErrorMessage('Terjadi kesalahan, silahkan coba lagi nanti');
            break;
        }
      }
    } finally {
      setLoading(false);
      handleSetTimeout({
        onSetTimeout: () => setIsSubmitDisabled(false),
        interval: DISABLE_BTN_DURATION
      });
    }
  };

  const submitAccount = async () => {
    setLoading(true);
    setIsSubmitDisabled(true);
    setLoading(true);
    setErrorMessage('');
    setMaxChat(form.getFieldValue('maxChatCap'));
    setPassword(form.getFieldValue('password'));
    updatePassword();
    updateMaxChat();
  };

  const disableFormSubmit = () => {
    const maxChat = form.getFieldValue('maxChatCap');
    const password = form.getFieldValue('password');

    return !maxChat && !password;
  };

  useEffect(() => {
    const getUser = async () => {
      setLoading(true);

      try {
        const response = await getUserDetail(selectedUser?.agentId);
        if (response?.role === RoleEnum.AGENT) {
          const agentMaxChat: AgentMaxChatInterface = await getAgentMaxChat(selectedUser?.agentId);
          const maxChatValue = agentMaxChat?.capacity?.maxChatCapacity;
          form.setFieldsValue({ maxChatCap: `${maxChatValue} Chat` });
          setMaxChat(`${maxChatValue} Chat`);
        }
        setUser(response);
      } catch (error: any) {
        messageApi.open({
          type: 'error',
          content: error
        });
      } finally {
        setLoading(false);
      }
    };

    getUser();
    // eslint-disable-next-line
  }, [selectedUser]);

  return (
    <>
      {contextHolder}
      <Modal
        title={
          <Title level={4} className="m-0 p-0">
            Hapus User
          </Title>
        }
        open={open}
        onOk={handleOk}
        width={300}
        confirmLoading={confirmLoading}
        footer={null}
        centered
        onCancel={handleCancel}>
        <Text className="text-md">Yakin ingin menghapus User secara permanen dari daftar?</Text>
        <button
          onClick={handleOk}
          className={`my-3 flex w-full cursor-pointer flex-row items-center justify-center rounded-full border-none bg-orange-danger py-3 text-white transition-[transform] duration-100 hover:scale-105 active:scale-90`}>
          Hapus User
        </button>
        <button
          onClick={handleCancel}
          className={`my-3 flex w-full cursor-pointer flex-row items-center justify-center rounded-full  border-2 bg-white-background  py-3 text-black transition-[transform] duration-100 hover:scale-105 active:scale-90`}>
          Batalkan
        </button>
      </Modal>

      <Spin spinning={loading}>
        <Row className="rounded-xl bg-white p-6">
          <Col span={24}>
            <Row justify="space-between" className="mb-4">
              <Title level={3} className="m-0 p-0">
                {user?.firstName}&nbsp;{user?.lastName}
              </Title>
              <CloseOutlined className="cursor-pointer" onClick={() => closeForm()} />
            </Row>
            <UserInfo label="Nama Depan" value={user?.firstName} />
            <UserInfo label="Nama Belakang" value={user?.lastName} />
            <UserInfo label="Jabatan" value={user?.role} />
            <UserInfo label="No Telepon" value={user?.phone} />
            <UserInfo label="Email" value={user?.email} />
            <UserInfo label="ID Pegawai" value={user?.employeeId} />
            <UserInfo label="Manager" value={user?.manager} />
            <UserInfo label="Unit Bisnis" value={user?.account?.accountName} />

            <Form
              name="basic"
              layout="vertical"
              form={form}
              autoComplete="off"
              requiredMark={false}
              size="large">
              {user?.role === RoleEnum.AGENT && (
                <Row>
                  <Col span={24}>
                    <Form.Item
                      name="maxChatCap"
                      required
                      className="user_form-max_chat_capacity mb-2"
                      label={<div className="text-base font-semibold">Maksimal Chat</div>}>
                      <Select
                        placeholder="5 Chat"
                        onChange={(value) => {
                          form.setFieldsValue({ maxChatCap: `${value} Chat` });
                        }}
                        options={maxChatSelection}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              )}

              <Row>
                <Col span={24}>
                  <Form.Item
                    name="password"
                    required
                    className="user_form-password mb-2"
                    label={<div className="text-base font-semibold">Password</div>}>
                    <Input.Password
                      placeholder="Masukkan Password"
                      onChange={(event) => {
                        form.setFieldsValue({ password: event.target.value });
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              {errorMessage !== '' && (
                <Row className="mb-4">
                  <Col span={24}>
                    <Alert
                      className="error-alert text-base font-semibold capitalize"
                      message={errorMessage}
                      type="error"
                      showIcon
                      closable
                    />
                  </Col>
                </Row>
              )}
              <Row>
                <Col span={24}>
                  <Form.Item shouldUpdate>
                    {() => (
                      <Button
                        className={`-mb-5 mt-5 rounded-3xl ${
                          disableFormSubmit() || isSubmitDisabled
                            ? 'bg-grey-50 text-grey-20'
                            : 'bg-green-emerald transition-colors duration-100 hover:scale-105 hover:bg-green-emerald-light active:scale-90 active:bg-green-emerald'
                        }`}
                        disabled={disableFormSubmit() || isSubmitDisabled}
                        block
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                        onClick={submitAccount}>
                        <Text className="text-lg font-semibold text-white">Ubah dan Simpan</Text>
                      </Button>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form>

            <div
              onClick={showModal}
              className="flex cursor-pointer flex-row items-center justify-center space-x-4 rounded-full border-2 border-solid border-orange-danger py-1 transition-[transform] duration-100 hover:scale-105 active:scale-90">
              <Text className="text-lg font-semibold text-orange-danger">Hapus User</Text>
              <img src={getIconUrl('trash.svg')} alt="Hapus User" />
            </div>
          </Col>
        </Row>
      </Spin>
    </>
  );
}

export default UserDetail;
