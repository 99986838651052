import axiosInstance from '../config/axios';
import {
  FullWorkspaceSummary,
  PayloadReasonParam,
  PayloadTopicParam,
  ResponseGetReason,
  ResponseGetTopic,
  SaveWorkspaceParam,
  WorkspaceSummary
} from './param.interface';

export const saveSummary: ({
  accountId,
  conversationId,
  payload
}: SaveWorkspaceParam) => Promise<void> = async ({
  accountId,
  conversationId,
  payload
}: SaveWorkspaceParam) => {
  try {
    await axiosInstance.post(
      `account/${accountId}/conversation/${conversationId}/summary`,
      payload
    );
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const getWorkspaceSummary: (
  accountId: string,
  conversationId: string
) => Promise<FullWorkspaceSummary> = async (accountId, conversationId) => {
  try {
    const response = await axiosInstance.get(
      `account/${accountId}/conversation/${conversationId}/summary`
    );
    return response.data;
  } catch {
    return {
      conversationId,
      topic: undefined,
      background: undefined,
      solution: undefined,
      note: undefined,
      specificRequest: undefined,
      documents: null
    };
  }
};

export const updateWorkspaceSummary: (
  accountId: string,
  conversationId: string,
  payload: WorkspaceSummary
) => Promise<void> = async (accountId, conversationId, payload) => {
  try {
    await axiosInstance.put(`account/${accountId}/conversation/${conversationId}/summary`, payload);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const createTopic: ({ topic, inboxId }: PayloadTopicParam) => Promise<void> = async ({
  topic,
  inboxId
}) => {
  try {
    await axiosInstance.post(`/inbox/${inboxId}/conversation-topic`, {
      topic
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const getTopic: (inboxId: string) => Promise<ResponseGetTopic> = async (inboxId) => {
  try {
    const response = await axiosInstance.get(`/inbox/${inboxId}/conversation-topic`);

    return response.data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const deleteTopic: ({ inboxId, topicId }: PayloadTopicParam) => Promise<void> = async ({
  topicId,
  inboxId
}) => {
  try {
    await axiosInstance.delete(`/inbox/${inboxId}/conversation-topic/${topicId}`);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const getVideoRecordingUrl = async (conversationId: string) =>
  axiosInstance.post<{ filename: string; downloadUrl: string; message?: string }>(
    `/conversation/${conversationId}/call-recording/url`
  );

export const createReason: ({ reason, inboxId }: PayloadReasonParam) => Promise<void> = async ({
  reason,
  inboxId
}) => {
  try {
    await axiosInstance.post(`/inbox/${inboxId}/reject/reason`, {
      reason
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const getReason: (inboxId: string) => Promise<Array<ResponseGetReason>> = async (
  inboxId
) => {
  try {
    const response = await axiosInstance.get(`/inbox/${inboxId}/reject/reason`);

    return response.data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const deleteReason: ({ inboxId, reasonId }: PayloadReasonParam) => Promise<void> = async ({
  reasonId,
  inboxId
}) => {
  try {
    await axiosInstance.delete(`/inbox/${inboxId}/reject/reason/${reasonId}`);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};
