import { useWorkspaceDocuments } from '../api/hooks/workspace/useWorkspaceDocuments';
import { useChatListStore } from '../store/chat-list';

type UseLoadBerkas = () => {
  handleReloadBerkas: () => void;
  isRefetchBerkas: boolean;
};

const useLoadBerkas: UseLoadBerkas = () => {
  const { selectedCall, setIsNewAttachment } = useChatListStore();
  const { isRefetching, refetch } = useWorkspaceDocuments(selectedCall?.conversationId || '');

  const handleReloadBerkas = () => {
    if (!isRefetching) {
      setIsNewAttachment(false);
      refetch();
    }
  };

  return {
    handleReloadBerkas,
    isRefetchBerkas: isRefetching
  };
};

export default useLoadBerkas;
