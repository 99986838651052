import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Row } from 'antd';
import { Paragraph } from '@squantumengine/horizon';

import ArrowPagination from '../../../../components/v2/pagination/arrow-pagination';

import OfflineCard from '../../../../components/v2/card/offline-card';
import { useOfflineconversation } from '../../../../api/hooks/inbox/useOfflineconversation';
import { useUserStatusStore } from '../../../../store/user-state';
import { RoleEnum } from '../../../../static/role';
import SkeletonTicket from './skeleton-ticket';
import useInfiniteScroll from '../../../../hooks/useInfiniteScroll';
import CollapseAgent from './collapse-agent';
import { OfflineConversationInterface } from '../../../../shared/types/inbox.interface';
import LabelAgentTicketOffline from './label-ticket-offline';
import AgentOfflineChatList from './agent-offline-chat-list';

type TicketOfflineProps = {
  filter: {
    email: string;
    name: string;
    ticketNumber: string;
    phoneNumber: string;
    startDate: string;
    endDate: string;
  };
};
export type TicketOfflineComponent = {
  total: () => number;
  refetchOfflineChat: () => void;
  isFetching: () => boolean;
};

const TicketOffline = forwardRef<TicketOfflineComponent, TicketOfflineProps>(
  ({ filter: propsFilter }: TicketOfflineProps, ref) => {
    const [page, setPage] = useState(1);
    const [filter, setFilter] = useState(propsFilter);
    const [expand, setExpand] = useState(true);
    const [offlineChatList, setOfflineChatList] = useState<OfflineConversationInterface[]>([]);
    const { email, endDate, name, phoneNumber, startDate, ticketNumber } = filter;
    const { userRole } = useUserStatusStore();
    const { data, refetch, isFetching, isFetched } = useOfflineconversation({
      page,
      email,
      name,
      ticketNumber,
      phoneNumber,
      startDate,
      endDate,
      limit: 9
    });

    const isAgent = userRole === RoleEnum.AGENT;

    const isPrevDisabled = page === 1;
    const isNextDisabled = offlineChatList.length < 9;

    const { lastItemElementRef } = useInfiniteScroll({
      isFetching,
      hasMore: !isNextDisabled,
      loadMore: () => {
        setPage((prev) => {
          return prev + 1;
        });
      }
    });

    const handlePrevPage = () => {
      if (isPrevDisabled) return;
      setPage((prev) => prev - 1);
    };

    const handleNextPage = () => {
      if (isNextDisabled) return;
      setPage((prev) => prev + 1);
    };

    const reInitialize = () => {
      setPage(() => {
        refetch();
        return 1;
      });
    };

    useImperativeHandle(ref, () => ({
      total: () => offlineChatList.length,
      refetchOfflineChat: reInitialize,
      isFetching: () => isFetching
    }));

    useEffect(() => {
      if (data) {
        if (isFetched && isAgent) {
          setOfflineChatList((prev) => [...prev, ...data.offlineMessages]);
        }
        if (offlineChatList.length === 0) {
          setOfflineChatList(data.offlineMessages);
        }
      }
    }, [isFetched, isAgent, data]);

    useEffect(() => {
      setPage(1);
      setFilter(propsFilter);
      setOfflineChatList([]);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      propsFilter.startDate,
      propsFilter.endDate,
      propsFilter.email,
      propsFilter.ticketNumber,
      propsFilter.phoneNumber,
      propsFilter.name
    ]);

    if (isAgent) {
      return (
        <CollapseAgent
          onChange={() => {
            if (isAgent) setExpand(!expand);
          }}
          label={<LabelAgentTicketOffline expand={expand} count={data?.count || 0} />}
          children={
            <AgentOfflineChatList
              lastItemElementRef={lastItemElementRef}
              offlineChatList={offlineChatList}
            />
          }
        />
      );
    }

    return (
      <div>
        <div className="flex items-center justify-between bg-neutral-50 px-4 py-2 ">
          <Paragraph size="r" weight="bold">
            Ticket Offline ({offlineChatList.length})
          </Paragraph>
          {!isAgent && (
            <ArrowPagination
              disableNext={isNextDisabled}
              disablePrev={isPrevDisabled}
              onClickNext={handleNextPage}
              onClickPrev={handlePrevPage}
            />
          )}
        </div>

        {isFetching && !isAgent && <SkeletonTicket isOfflineTicket />}

        {offlineChatList.length > 0 && (
          <Row className={`grid grid-cols-3 gap-4 bg-white p-4`}>
            {offlineChatList.map((chat) => (
              <OfflineCard key={chat.messageId} data={chat} />
            ))}
          </Row>
        )}
      </div>
    );
  }
);

export default TicketOffline;
