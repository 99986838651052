import { Button, DatePicker, Dropdown, Space, TimeRangePickerProps } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { ItemKey, items } from '../../static/date-filter';
import { DownOutlined } from '@ant-design/icons';
import { useEffect, useRef, useState } from 'react';
import ExclamationCircleIcon from '../../assets/icons/exclamation-circle';
import { useInboxDetailStore } from '../../store/inbox-state';

export const DEFAULT_DATE_RANGE = [dayjs().add(-6, 'd').startOf('day'), dayjs().endOf('day')];

export default function DateFilter({
  startDate,
  endDate,
  onChangeDate
}: {
  startDate: Dayjs;
  endDate: Dayjs;
  onChangeDate: (dateValues: [Dayjs, Dayjs]) => void;
}) {
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState(1);
  const [startDateCurrent, setStartDateCurrent] = useState(DEFAULT_DATE_RANGE[0]);
  const [endDateCurrent, setEndDateCurrent] = useState(DEFAULT_DATE_RANGE[1]);

  const inboxId = useInboxDetailStore.getState().inboxId;

  useEffect(() => {
    setShowCalendar(false);
  }, [inboxId]);

  const { RangePicker } = DatePicker;
  const onClick = (e: any) => {
    switch (JSON.parse(e.key)) {
      case ItemKey.TODAY:
        onChangeDate([dayjs().startOf('day'), dayjs().endOf('day')]);
        break;
      case ItemKey.WEEK_AGO:
        onChangeDate([dayjs().add(-6, 'd').startOf('day'), dayjs().endOf('day')]);
        break;
      case ItemKey.MONTH_AGO:
        onChangeDate([dayjs().add(-29, 'd').startOf('day'), dayjs().endOf('day')]);
        break;
      case ItemKey.THREE_MONTH_AGO:
        onChangeDate([dayjs().add(-89, 'd').startOf('day'), dayjs().endOf('day')]);
        break;
      case ItemKey.CHOSEN_DATE:
        if (!showCalendar) {
          inputRef?.current?.focus();
          const element = document.activeElement as HTMLElement;
          const grandParent = element.parentNode?.parentNode;
          if (grandParent instanceof HTMLElement) {
            grandParent.click();
          }
          setShowCalendar(true);
        }
        break;
      default:
        break;
    }
    setSelectedMenu(Number(e.key));
  };

  const onChange = (e: any) => {
    setStartDateCurrent(e[0]);
    setEndDateCurrent(e[1]);
  };
  const diffDates = dayjs(endDateCurrent).diff(dayjs(startDateCurrent), 'day');
  const showWarningMaxDate = diffDates > 90;
  const CalendarFooter = (e: any) => {
    return (
      <div className="flex justify-between gap-2">
        {showWarningMaxDate ? (
          <span className="flex items-center text-red-imperial">
            <ExclamationCircleIcon /> maksimal 90 hari
          </span>
        ) : (
          <span>
            Waktu terpilih {startDateCurrent.format('YYYY-MM-DD')} -{' '}
            {endDateCurrent.format('YYYY-MM-DD')} ({diffDates + 1 || 0} hari)
          </span>
        )}
        <div className="flex gap-4 p-2">
          <Button
            onClick={() => {
              setShowCalendar(false);
              setEndDateCurrent(endDate);
              setStartDateCurrent(startDate);
            }}>
            Batalkan
          </Button>
          <Button
            disabled={showWarningMaxDate}
            onClick={() => {
              setShowCalendar(false);
              onChangeDate([startDateCurrent.startOf('day'), endDateCurrent.endOf('day')]);
            }}>
            Proses
          </Button>
        </div>
      </div>
    );
  };
  const inputRef: any = useRef(null);
  const rangePresets: TimeRangePickerProps['presets'] = [
    { label: items[0].label, value: [dayjs().add(-1, 'd'), dayjs()] },
    { label: items[1].label, value: [dayjs().add(-6, 'd'), dayjs()] },
    { label: items[2].label, value: [dayjs().add(-29, 'd'), dayjs()] },
    { label: items[3].label, value: [dayjs().add(-89, 'd'), dayjs()] },
    { label: items[4].label, value: [null, null] }
  ];
  return (
    <div className="w-fit">
      <Dropdown
        className="h-9 cursor-pointer rounded-full border border-solid border-black-90 px-4"
        menu={{ items, onClick }}>
        <Space className="flex w-full justify-between">
          <div className="w-full overflow-hidden truncate whitespace-nowrap text-sm font-semibold">
            {items?.[selectedMenu]?.label}
          </div>

          <DownOutlined />
        </Space>
      </Dropdown>
      <RangePicker
        renderExtraFooter={CalendarFooter}
        value={
          dayjs(startDateCurrent).isValid() && dayjs(endDateCurrent).isValid()
            ? [dayjs(startDateCurrent), dayjs(endDateCurrent)]
            : [dayjs().add(-7, 'd'), dayjs()]
        }
        disabledDate={(current) => {
          return current && current.valueOf() > Date.now();
        }}
        defaultValue={[dayjs(startDate), dayjs(endDate)] || DEFAULT_DATE_RANGE}
        presets={rangePresets}
        ref={inputRef}
        open={showCalendar}
        defaultOpen={true}
        onChange={onChange}
        format="DD-MMM-YYYY"
        inputReadOnly={true}
        className="absolute -top-8 right-4 w-max rounded-full border-[1.5px] border-solid border-grey-80 text-center opacity-0 hover:outline-none"
      />
    </div>
  );
}
