import { useQuery } from 'react-query';
import axiosInstance from '../../../config/axios';

export const getAllSPV: (accountId: string) => Promise<any> = async (accountId) => {
  const response = await axiosInstance.get(`/agent/account/${accountId}/supervisors`);
  return response.data;
};

export const useGetAllSPV = (accountId: string) => {
  return useQuery(['get-spv', accountId], () => getAllSPV(accountId), {
    refetchOnWindowFocus: true,
    enabled: !!accountId
  });
};
