import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { ConversationStoreInterface } from './interface';
import { ConversationInterface } from '../../../types/conversation';

export const useConversationStore = create<ConversationStoreInterface, any>(
  persist(
    (set, get) => ({
      activeConversationList: [],
      inactiveConversationList: [],
      offlineConversationList: [],
      selectedConversationId: '',
      lastMessageMap: {},
      setActiveConversationList: (activeConversationList) => set({ activeConversationList }),
      setInactiveConversationList: (inactiveConversationList) => set({ inactiveConversationList }),
      setOfflineConversationList: (offlineConversationList) => set({ offlineConversationList }),
      setSelectedConversationId: (selectedConversationId: string) =>
        set({ selectedConversationId }),
      getSelectedConversation: () => {
        const { activeConversationList, inactiveConversationList, selectedConversationId } = get();
        return [...activeConversationList, ...inactiveConversationList].find(
          (conversation) => conversation.conversationId === selectedConversationId
        );
      },
      updateConversationById: (conversationId: string, params: Partial<ConversationInterface>) => {
        const { activeConversationList, inactiveConversationList } = get();
        const updatedActiveConversationList = activeConversationList.map((conversation) => {
          if (conversation.conversationId === conversationId) return { ...conversation, ...params };
          return conversation;
        });
        const updatedInactiveConversationList = inactiveConversationList.map((conversation) => {
          if (conversation.conversationId === conversationId) return { ...conversation, ...params };
          return conversation;
        });
        set({
          activeConversationList: updatedActiveConversationList,
          inactiveConversationList: updatedInactiveConversationList
        });
      },
      appendActiveConversationList: (conversations: ConversationInterface[]) => {
        const { activeConversationList } = get();
        set({ activeConversationList: [...activeConversationList, ...conversations] });
      },
      appendInactiveConversationList(conversations) {
        const { inactiveConversationList } = get();
        set({ inactiveConversationList: [...inactiveConversationList, ...conversations] });
      },
      setLastMessageMap: (conversationId, message) => {
        const { lastMessageMap } = get();
        set({
          lastMessageMap: {
            ...lastMessageMap,
            [conversationId]: message
          }
        });
      }
    }),
    {
      name: 'conversation-store',
      partialize: (state) => ({ lastMessageMap: state.lastMessageMap })
    }
  )
);
