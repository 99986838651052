import { Spinner, Typography } from '@squantumengine/horizon';

const Loading = () => {
  return (
    <div className="flex h-screen w-full flex-col items-center justify-center">
      <div className="mb-[60px]">
        <Spinner size="lg" />
      </div>

      <Typography.Title level={4}>Mohon tunggu sebentar...</Typography.Title>

      <Typography.Paragraph>sedang memuat halaman</Typography.Paragraph>
    </div>
  );
};

export default Loading;
