import { useMemo, useState } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Checkbox, Dropdown, Space } from 'antd';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip
} from 'recharts';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import dayjs from 'dayjs';

import { itemsBusyHour } from '../busy-hour/busy-hour.interface';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getTotalConversationsAnalytics } from '../../../../api/analytics';

type Props = { filterValues: Record<string, any> };

function TypeConversation(props: Props) {
  const { inboxId } = useParams();
  const [selectedMenu, setSelectedMenu] = useState<number>(0);
  const { data } = useQuery(
    ['totalConversations', inboxId, props.filterValues],
    () => getTotalConversationsAnalytics(String(inboxId), props.filterValues),
    { enabled: !!inboxId }
  );

  const summaryTotalConversation = useMemo(() => {
    return {
      all: data?.totalConversation || 0,
      online: data?.totalOnlineConversation || 0,
      offline: data?.totalOfflineConversation || 0
    };
  }, [data]);

  /**
   * Formats the data to be used in the line charts. The data is formatted into 3 different types:
   * daily, weekly, and monthly.
   */
  const selectedData = useMemo(() => {
    if (!data || !data?.dailyConversationType) return [[], [], []];
    let weekly: any = {};
    let monthly: any = {};

    function formatData(name: string, online: number, offline: number) {
      return { name, all: online + offline, online, offline };
    }

    Object.keys(data?.dailyConversationType).forEach((date) => {
      const currentDate = dayjs(date);
      const yearMonth = currentDate.format('YYYY-MM');
      const weekKey = `${currentDate.startOf('week').format('DD/MM')}-${currentDate
        .endOf('week')
        .format('DD/MM')}`;
      weekly[weekKey] = weekly[weekKey] || { online: 0, offline: 0 };
      weekly[weekKey].online += data?.dailyConversationType[date].online;
      weekly[weekKey].offline += data?.dailyConversationType[date].offline;

      monthly[yearMonth] = monthly[yearMonth] || { online: 0, offline: 0 };
      monthly[yearMonth].online += data?.dailyConversationType[date].online;
      monthly[yearMonth].offline += data?.dailyConversationType[date].offline;
    });

    let formattedDailyData = Object.keys(data?.dailyConversationType).map((date) => {
      let item = data?.dailyConversationType[date];
      let formattedName = dayjs(date).locale('id').format('dddd DD/MM');
      return formatData(formattedName, item.online, item.offline);
    });

    let formattedWeeklyData = Object.keys(weekly).map((week) => {
      let data = weekly[week];
      return formatData(week, data.online, data.offline);
    });

    let formattedMonthlyData = Object.keys(monthly).map((month) => {
      let data = monthly[month];
      const monthName = dayjs(new Date(month)).locale('id').format('MMM YYYY');
      return formatData(monthName, data.online, data.offline);
    });

    return [formattedDailyData, formattedWeeklyData, formattedMonthlyData];
  }, [data]);

  const [hideConvType, setHideConvType] = useState<any>({
    all: false,
    online: false,
    offline: false
  });

  /**
   * Calculates the total value of a given key in an array of objects.
   *
   * @param data - The array of objects.
   * @param key - The key to calculate the total value of.
   * @returns The total value of the given key.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const calTotalData = (data: Array<any>, key: string): number => {
    return data.reduce((total, item) => total + item[key], 0);
  };

  /**
   * Renders the legend component.
   *
   * @param payload - The component payload on the line charts.
   * @returns The rendered legend component in JSX.Element.
   */
  const renderLegend = ({ payload }: any): JSX.Element => {
    const txtColors = {
      all: 'text-[#47bdff]',
      online: 'text-[#7961ff]',
      offline: 'text-magenta-400'
    } as any;

    return (
      <div className="mb-12 mt-5 flex w-1/2 flex-row items-center gap-20">
        {payload.map((entry: any, index: number) => {
          return (
            <div key={index} className="flex flex-col items-start justify-start gap-2">
              <p className={`m-0 p-0 text-xl`}>{entry.value}</p>
              <p className={`m-0 p-0 text-3xl font-semibold ${txtColors[entry.dataKey]}`}>
                {String(
                  summaryTotalConversation[entry.dataKey as keyof typeof summaryTotalConversation]
                )}
              </p>
            </div>
          );
        })}
      </div>
    );
  };

  const onClick = (e: any): void => setSelectedMenu(JSON.parse(e.key));

  /**
   * Renders the footer conversation element.
   * @param text The text to display.
   * @param dataKey The data key.
   * @param bgColor The background color.
   * @returns The JSX element.
   */
  const renderFooterConversation = (text: string, dataKey: string): JSX.Element => {
    const bgColors = {
      all: 'bg-blue-400',
      online: 'bg-purple-400',
      offline: 'bg-magenta-400'
    } as any;

    const handleCheckboxChange = (e: CheckboxChangeEvent) => {
      setHideConvType({
        ...hideConvType,
        [dataKey]: !e.target.checked
      });
    };

    return (
      <div className="flex flex-row items-center gap-2">
        <div className={`h-3 w-3 rounded-full ${bgColors[dataKey]}`} />
        <p>{text}</p>
        <Checkbox defaultChecked onChange={handleCheckboxChange} />
      </div>
    );
  };

  return (
    <div>
      <h1 className="m-0 p-0 text-2xl">Tipe Percakapan</h1>
      <ResponsiveContainer width="100%" height={512}>
        <LineChart data={selectedData[selectedMenu]} margin={{ top: 5, bottom: 5, left: 0 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" tickMargin={10} />
          <YAxis />
          <Tooltip />
          <Legend
            className="flex flex-row items-center gap-4"
            content={renderLegend}
            align="left"
            verticalAlign="top"
          />
          <Line
            hide={hideConvType['all']}
            name="Semua"
            type="linear"
            dataKey="all"
            stroke="#47bdff"
            strokeWidth={4}
          />
          <Line
            hide={hideConvType['online']}
            name="Percakapan Online"
            type="linear"
            dataKey="online"
            stroke="#7961ff"
            strokeWidth={4}
          />
          <Line
            hide={hideConvType['offline']}
            name="Pesan Offline"
            type="linear"
            dataKey="offline"
            stroke="#ff61c0"
            strokeWidth={4}
          />
        </LineChart>
      </ResponsiveContainer>
      <div className="mt-5 flex w-full flex-row items-center justify-between">
        <div className="flex w-8/12 flex-row items-center gap-4">
          {renderFooterConversation('Semua', 'all')}
          {renderFooterConversation('Percakapan Online', 'online')}
          {renderFooterConversation('Pesan Offline', 'offline')}
        </div>
        <div className="flex w-4/12 flex-row items-center justify-end gap-2">
          <p className="m-0 p-0">Tampilan Data</p>
          <Dropdown
            className="mx-2 h-10 w-full max-w-[159px] cursor-pointer rounded-full border-solid border-grey-80 px-4"
            menu={{ items: itemsBusyHour, onClick }}>
            <Space className="flex w-full justify-between">
              <div className="w-full overflow-hidden truncate whitespace-nowrap">
                {itemsBusyHour?.[selectedMenu]?.label}
              </div>
              <DownOutlined />
            </Space>
          </Dropdown>
        </div>
      </div>
    </div>
  );
}
export default TypeConversation;
