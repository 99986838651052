import { create } from 'zustand';
import { SelectedCustomerInterface } from '../shared/types/customer.interface';
import { SelectedCustomerStoreInterface } from './customer-state.interface';
import { DocumentInterface } from '../components/form/workspace/workspace.interface';

const { name, email, phone, gender, dateOfBirth } = JSON.parse(
  localStorage.getItem('customerDetails') || '{}'
);

export const useSelectedCustomerStore = create<SelectedCustomerStoreInterface>((set) => ({
  customerDetail: {
    contactId: '',
    name: name || '',
    email: email || '',
    phone: phone || '',
    gender: gender || '',
    dateOfBirth: dateOfBirth || '',
    motherMaidenName: '',
    note: ''
  },
  isPreClosedAvailable: false,
  isFinishKYC: false,
  isApprovedKYC: false,
  chooseSelfie: null,
  chooseKTP: null,
  fileKTP: null,
  fileSelfie: null,
  isLoadingUploadKYC: true,
  isFinishChooseKYC: false,
  metadata: { referenceId: '', communicationType: '' },
  dob: '',
  isManualKYCSubmited: false,
  setIsManualKYCSubmited: (isManualKYCSubmited: boolean) => set({ isManualKYCSubmited }),
  setCustomerDetail: (customerDetail: SelectedCustomerInterface) => set({ customerDetail }),
  setIsPreClosedAvailable: (isPreClosedAvailable: boolean) => set({ isPreClosedAvailable }),
  setIsFinishKYC: (isFinishKYC: boolean) => set({ isFinishKYC }),
  setIsApprovedKYC: (isApprovedKYC: boolean) => set({ isApprovedKYC }),
  setChooseKTP: (chooseKTP: DocumentInterface | null) => set({ chooseKTP }),
  setChooseSelfie: (chooseSelfie: DocumentInterface | null) => set({ chooseSelfie }),
  setFileKTP: (fileKTP: File | null) => set({ fileKTP }),
  setFileSelfie: (fileSelfie: File | null) => set({ fileSelfie }),
  setIsLoadingUploadKYC: (isLoadingUploadKYC: boolean) => set({ isLoadingUploadKYC }),
  setIsFinishChooseKYC: (isFinishChooseKYC: boolean) => set({ isFinishChooseKYC }),
  setMetadata: (metadata: any) => set({ metadata }),
  setDob: (dob: string) => set({ dob }),
  resetKYCState: async () => {
    set({ chooseSelfie: null });
    set({ chooseKTP: null });
    set({ fileKTP: null });
    set({ fileSelfie: null });
    set({ isFinishKYC: false });
    set({ isApprovedKYC: false });
    set({ isFinishChooseKYC: false });
    set({ isLoadingUploadKYC: true });
  }
}));
