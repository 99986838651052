import { PlusOutlined } from '@ant-design/icons';
import { Button, Icon, Paragraph } from '@squantumengine/horizon';
import { Flex, Form, Select } from 'antd';
import { Precoding, WhatsAppMessage } from '../../../../../../shared/types/whatsapp.interface';

interface TemplateItemProps {
  name: string;
  campaign?: WhatsAppMessage;
  index: number;
  form: any;
  templateOptions: any;
  SQEMPPrecodings: any;
  handleAddSQEMPTemplate: any;
  handleEditSQEMPTemplate: any;
  handleSelectTemplate: any;
  handleDeleteTemplate: any;
  handleSelectPrecodingTemplate: any;
}

const TemplateItem = ({
  name,
  campaign,
  index: templateIndex,
  templateOptions,
  SQEMPPrecodings,
  handleAddSQEMPTemplate,
  handleEditSQEMPTemplate,
  handleDeleteTemplate,
  handleSelectTemplate,
  handleSelectPrecodingTemplate
}: TemplateItemProps) => {
  return (
    <div className="rounded-xl border border-solid border-gray-100 p-4">
      <div className="flex items-center gap-2">
        <span className="font-semibold">{name}</span>
        <div className="ml-auto flex max-w-[270px] flex-1 items-center">
          <Form.Item name={`template-${templateIndex}`} colon={false} className="mb-0 flex-1">
            <Select
              className="w-full"
              placeholder="Pilih Template SQEMP"
              popupMatchSelectWidth={false}
              options={templateOptions}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Button variant="text" size="sm" onClick={handleAddSQEMPTemplate}>
                    <div className="flex items-center space-x-2">
                      <PlusOutlined />
                      <span className="whitespace-nowrap">Tambah Template SQEMP Baru</span>
                    </div>
                  </Button>
                </>
              )}
              onChange={handleSelectTemplate}
            />
          </Form.Item>
          <button
            data-testid="delete-btn"
            className="cursor-pointer border-none bg-transparent"
            onClick={handleDeleteTemplate.bind(null, templateIndex)}>
            <Icon name="trash" />
          </button>
        </div>
      </div>

      {campaign && campaign?.content?.body && (
        <Flex className="mt-4" vertical gap={4}>
          <Flex className="relative rounded-lg border border-solid border-neutral-100 bg-neutral-50 p-2">
            <Paragraph className='!pr-3'>{campaign?.content?.body}</Paragraph>
            <span
              className="absolute right-1 top-1 cursor-pointer"
              onClick={handleEditSQEMPTemplate.bind(null, campaign)}>
              <Icon name="pen" />
            </span>
          </Flex>
          <Flex className="mt-4" vertical>
            {campaign?.precodings?.map((precodingKey: string) => (
              <div className="flex items-center gap-2">
                <label>{precodingKey}</label>
                <div className="ml-auto max-w-[270px] flex-1">
                  <Form.Item
                    name={`template-${templateIndex}-${precodingKey}`}
                    colon={false}
                    className="mb-2">
                    <Select
                      onChange={(value) => handleSelectPrecodingTemplate(precodingKey, value)}
                      className="w-full"
                      placeholder="Pilih Segmentasi">
                      {SQEMPPrecodings?.attributes?.length &&
                        SQEMPPrecodings?.attributes?.map((precoding: Precoding) => (
                          <Select.Option
                            key={precoding.displayNameKey}
                            value={precoding.displayNameKey}>
                            {precoding.displayName}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>
            ))}
          </Flex>
        </Flex>
      )}
    </div>
  );
};

export default TemplateItem;
