import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
  SortingState,
  getFilteredRowModel,
  getPaginationRowModel
} from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import SortIcon from '../../assets/icons/sort';
import { TablePropsInterface } from '../../static/analysis';
import { RightOutlined } from '@ant-design/icons';
import { Pagination, PaginationProps } from 'antd';

export default function Table({
  data,
  headerColumns,
  filter,
  totalData,
  numbered = false,
  showArrow = true,
  showPagination = false,
  resetPagination = false,
  pageSizeOptions = [10, 20, 50, 100]
}: TablePropsInterface) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [pageSize, setPageSize] = useState<number>(pageSizeOptions[0]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { filtering, setFiltering, filterData, setFilterData } = (filter as any) ?? {};

  const inboxId: string = window.location.pathname.split('/')[3];
  const columnHelper = createColumnHelper();
  const columns = headerColumns.map((column) => {
    return columnHelper.accessor(column.dataIndex as any, {
      cell: (info) => column.render(info.getValue()),
      header: () => <span>{column.title}</span>
    });
  });
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      globalFilter: filtering,
      pagination: {
        pageIndex: 0,
        pageSize
      }
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setFiltering,
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true
  });

  const onShowSizeChange: PaginationProps['onShowSizeChange'] = (_, pageSize) => {
    setPageSize(pageSize);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [resetPagination]);

  return (
    <div>
      <table className="w-full border-collapse border border-grey-20">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  className="border-0 border-b border-solid border-grey-20 p-0 text-left text-xs lg:p-4 lg:text-base"
                  key={header.id}>
                  <div
                    {...{
                      className: header.column.getCanSort() ? 'cursor-pointer select-none' : '',
                      onClick: header.column.getToggleSortingHandler()
                    }}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.header, header.getContext())}
                    {
                      {
                        asc: (
                          <SortIcon className="ml-1 md:ml-4 [&>svg>path]:fill-emerald-light-green" />
                        ),
                        desc: (
                          <SortIcon className="ml-1 md:ml-4 [&>svg>path]:fill-emerald-light-green" />
                        ),
                        false: <SortIcon className="ml-4" />
                      }[header.column.getIsSorted() as string]
                    }
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row, index) => (
            <tr key={row.id} className="group h-20 cursor-pointer hover:bg-grey-10">
              {row.getVisibleCells().map((cell: any, key) => (
                <td
                  className={`${key === 0 ? 'text-black' : 'text-gray-500'}
                  border-0 border-b border-solid border-grey-20 p-4 text-xs lg:text-sm`}
                  key={cell.id}>
                  <div className="flex flex-row items-center gap-2">
                    {key === 0 ? (
                      numbered ? (
                        <p className="m-0 p-0 text-xs text-black lg:text-sm">{index + 1}.</p>
                      ) : (
                        <div className="h-2 w-2 rounded-full bg-gray-200" />
                      )
                    ) : null}
                    <a
                      key={row.id}
                      className="text-black"
                      href={
                        cell.row.original!?.id &&
                        `/dashboard/analytics/${inboxId}/customer-activity?customerId=${
                          cell.row.original!?.id
                        }`
                      }>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </a>
                  </div>
                </td>
              ))}
              {showArrow && (
                <td>
                  <RightOutlined className="-ml-40 hidden group-hover:block" />
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      {showPagination && (
        <div className="mt-4 flex w-full flex-row items-center justify-center gap-2">
          <p className="m-0 p-0 text-base">Tampilan Baris</p>
          <Pagination
            defaultCurrent={1}
            total={totalData}
            onChange={(page, pageSize) => {
              setCurrentPage(page);
              setFilterData && setFilterData({ ...filterData, page: page, perPage: pageSize });
            }}
            showSizeChanger
            onShowSizeChange={onShowSizeChange}
            pageSize={pageSize}
            current={currentPage}
            responsive
            locale={{ items_per_page: '' }}
            pageSizeOptions={pageSizeOptions}
          />
        </div>
      )}
    </div>
  );
}
