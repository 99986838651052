import dayjs from 'dayjs';
import { ConversationInterface } from '../../../../../components/card/chat-message/chat-message.interface';
import {
  CommunicationTypeEnum,
  ConversationContextEnum,
  defaultCommunicationTypeEnum
} from '../../../../../static/communication-type';
import { Icon, Label, Paragraph } from '@squantumengine/horizon';
import ConversationWaitingTime from './conversation-waiting-time';
import { useChatListStore } from '../../../../../store/chat-list';
import { useCallStore } from '../../../../../store/call-state';
import { useSelectedCustomerStore } from '../../../../../store/customer-state';
import { setMeetingRoomId } from '../../../../../utils/webSocket';
import useAgentAnswerConv from '../../../../../api/hooks/inbox/useAgentAnswerConv';
import { ConversationStatusEnum, MessageEventEnum } from '../../../../../static/message-event';
import { useConversationListStore } from '../../../../../store/conversation-list-state';
import { UpdateConversationStatus } from '../../../../../api/call';
import { useAuthStore } from '../../../../../store/new/auth';
import useLastChat from '../../../../../hooks/useLastChat';
import { useEffect } from 'react';
import { useInboxStore } from '../../../../../store/new/inbox';
import { useConversationStore } from '../../../../../store/new/conversation';
import { IChatMessage } from '../../../../../types/new/conversation';
import { ChannelEnum } from '../../../../team/create-edit/type';
import { loadCustomerDetail } from '../../../../dashboard/dashboard.helper';
import { useWorkspaceSaveSummary } from '../../../../../api/hooks/workspace/useWorkspaceSaveSummary';

const CONVERSATION_DESCRIPTION_MAP = {
  INCOMING_VOICE_CALL: 'Meminta panggilan suara',
  ONGOING_VOICE_CALL: 'Panggilan suara berlangsung',
  INCOMING_VIDEO_CALL: 'Meminta panggilan video',
  ONGOING_VIDEO_CALL: 'Panggilan video berlangsung',
  INCOMING_CHAT: 'Chat baru masuk'
};

const ConversationItem = (conversation: ConversationInterface) => {
  const account = useAuthStore((state) => state.account);
  const { lastMessageMap, setLastMessageMap } = useConversationStore();
  const inbox = useInboxStore.getState().getInboxById(conversation.inboxId!);
  const { message: lastMessage } = useLastChat(conversation?.conversationId);
  const { mutate: handleAgentAnswerConv } = useAgentAnswerConv();
  const { activeConversationList, moveConversation, updateAnswerConversation } =
    useConversationListStore();
  const { selectedCall, unreadMessages, clearUnreadMessages, setSelectedCall } = useChatListStore();
  const { setIsManualKYCMode } = useCallStore();
  const { setMetadata } = useSelectedCustomerStore();
  const { mutate: updateSummary } = useWorkspaceSaveSummary();

  const isActiveConversation = conversation.conversationId === selectedCall?.conversationId;
  const isResolvedConv = conversation.conversationStatus === ConversationStatusEnum.RESOLVED;

  const handleConversationItemClick = () => {
    const currentConversation = { ...conversation };
    clearUnreadMessages(currentConversation.conversationId, currentConversation.inboxId!);

    // old logic: for Manual KYC case
    const isManualKYC =
      currentConversation?.inboxConfig?.conversationContext === ConversationContextEnum.KYC;
    setIsManualKYCMode(isManualKYC);
    setMeetingRoomId(currentConversation?.meetingRoomId as string);
    setMetadata(currentConversation?.metadata);

    // answer conversation
    if (!isResolvedConv && !conversation?.jawabAt) {
      const currentTimeStamp = Date.now().toString();
      currentConversation.event = MessageEventEnum.ACTIVE;
      currentConversation.jawabAt = currentTimeStamp;
      updateAnswerConversation(currentConversation?.conversationId, currentTimeStamp);
      handleAgentAnswerConv(conversation?.conversationId);
      if (
        !activeConversationList.some(
          (conv) => conv.conversationId === currentConversation.conversationId
        )
      ) {
        moveConversation(MessageEventEnum.ASSIGNED, MessageEventEnum.ACTIVE, currentConversation);
      }
      loadCustomerDetail(conversation, updateSummary);
    }
    UpdateConversationStatus(account?.accountId, currentConversation?.conversationId);
    setSelectedCall(currentConversation);
  };

  const getCommunicationType = () => {
    const inboxCommunicationType =
      conversation.inboxConfig?.mediaCallConfiguration?.defaultCommunicationType;
    const isVoiceCall = [
      defaultCommunicationTypeEnum.AUDIO,
      defaultCommunicationTypeEnum.AUDIO_VIDEO
    ].includes(inboxCommunicationType as defaultCommunicationTypeEnum);
    const withVideoCall =
      conversation.metadata?.communicationType === defaultCommunicationTypeEnum.VIDEO;

    return {
      isVoiceCall,
      isVideoCall: !isVoiceCall || (isVoiceCall && withVideoCall),
      isChat: conversation.communicationType === CommunicationTypeEnum.CHAT
    };
  };

  const getConversationIcon = () => {
    if (inbox?.channelType === ChannelEnum.WHATSAPP) return <Icon name="whatsapp" />;
    const { isVoiceCall, isVideoCall, isChat } = getCommunicationType();
    switch (true) {
      case isChat:
        return <Icon name="comment" />;
      case isVideoCall:
        return <Icon name="video" />;
      case isVoiceCall:
        return <Icon name="phone" />;
      default:
        return <Icon name="comment" />;
    }
  };

  const getConversationDescription = () => {
    const { isVoiceCall, isVideoCall, isChat } = getCommunicationType();
    let description: string | React.ReactNode = '';
    switch (true) {
      case isChat: {
        if (lastMessage?.attachment?.length! > 0) {
          description = (
            <div className="max-w-[192px] truncate">
              <Icon name="paperclip" size="sm" />
              <span>{lastMessage?.attachment?.[0].name}</span>
            </div>
          );
        } else if (!!lastMessage?.content) {
          description = lastMessage?.content;
        }
        break;
      }
      case isVideoCall:
        description = conversation.jawabAt
          ? CONVERSATION_DESCRIPTION_MAP.ONGOING_VIDEO_CALL
          : CONVERSATION_DESCRIPTION_MAP.INCOMING_VIDEO_CALL;
        break;
      case isVoiceCall:
        description = conversation.jawabAt
          ? CONVERSATION_DESCRIPTION_MAP.ONGOING_VOICE_CALL
          : CONVERSATION_DESCRIPTION_MAP.INCOMING_VOICE_CALL;
        break;
    }
    if (Object.values(CONVERSATION_DESCRIPTION_MAP).includes(String(description)))
      return <span className="italic">{description}</span>;
    return description;
  };

  const getConversationTimestamp = () => {
    const timestamp = lastMessage?.timestamp || conversation.createdAt;
    return dayjs(timestamp).isSame(dayjs(), 'day')
      ? dayjs(timestamp).format('HH:mm')
      : dayjs(timestamp).format('DD/MM/YYYY');
  };

  useEffect(() => {
    if (
      inbox &&
      conversation.communicationType === CommunicationTypeEnum.CHAT &&
      !lastMessageMap[conversation?.conversationId!]?.content
    ) {
      setLastMessageMap(conversation?.conversationId!, {
        content:
          (inbox.greetingEnabled && inbox?.greetingMessage) ||
          CONVERSATION_DESCRIPTION_MAP.INCOMING_CHAT
      } as IChatMessage);
    }
  }, []);

  return (
    <div
      className={`flex max-w-sm cursor-pointer items-center space-x-4 border p-4 shadow-sm ${
        isActiveConversation ? 'bg-blue-50' : ''
      }`}
      onClick={handleConversationItemClick}>
      <div className="relative h-12 w-12 rounded-full border border-solid border-gray-300 bg-white">
        <div className="flex h-full items-center justify-center">{getConversationIcon()}</div>
        {!conversation.jawabAt && (
          <Label className="absolute bottom-[-12px]" type="info" border label={'Baru'} />
        )}
      </div>
      <div className="flex min-w-0 flex-1 justify-between">
        <div className="w-full overflow-hidden truncate">
          <Paragraph className="truncate font-bold text-gray-900">{conversation.name}</Paragraph>
          <Paragraph className="truncate text-sm">{getConversationDescription()}</Paragraph>
          {!conversation.jawabAt && <ConversationWaitingTime conversation={conversation} />}
        </div>
        <div className="flex-shrink-0 space-y-1 text-right">
          <Paragraph className="text-xs text-gray-400">{getConversationTimestamp()}</Paragraph>
          {unreadMessages[conversation.conversationId] > 0 && (
            <span className="inline-flex h-5 w-5 items-center justify-center rounded-full bg-red-600 text-xs font-medium text-white">
              {unreadMessages[conversation.conversationId]}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default ConversationItem;
