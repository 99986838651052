import { Button, Form, message } from 'antd';
import TopicAgent from '../form/inbox/topic-agent';
import TextAreaWithLabel from '../form/input/text-area-with-label';
import { KYCPageEnum } from './kyc.interface';
import { isNullOrUndefined } from '../../utils/isNullUndefined';
import { WorkspaceSummary } from '../../api/param.interface';
import { useCallStore } from '../../store/call-state';
import { useChatListStore } from '../../store/chat-list';
import { useQueryClient } from 'react-query';
import { updateWorkspaceSummary } from '../../api/workspace';
import { getUserJSON } from '../../utils/user-json';
import { useWorkspaceSummary } from '../../api/hooks/workspace/useWorkspaceSummary';
import { useEffect } from 'react';
import { ConversationStatusEnum } from '../../static/message-event';

function KYCSummary({ setPage }: { setPage: React.Dispatch<React.SetStateAction<number>> }) {
  const { isManualKYCMode } = useCallStore();
  const { selectedCall, setIsReadyToClose } = useChatListStore();
  const { account } = getUserJSON();
  const conversationId = selectedCall?.conversationId;
  const { data, isLoading } = useWorkspaceSummary(conversationId as string);

  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const queryClient = useQueryClient();

  const topic = Form.useWatch('topic', form);
  const notes = Form.useWatch('notes', form);
  const isSentBtnDisabled: boolean = isNullOrUndefined(topic);
  const resolved = selectedCall?.conversationStatus === ConversationStatusEnum.RESOLVED;
  /**
   * Submits a summary of the workspace.
   *
   * @param accountId - The ID of the agent account
   * @param conversationId - The ID of the conversation.
   * @param topic - The topic of the KYC conversation
   * @param notes - The notes of the KYC conversation.
   */
  const submitSummary = async () => {
    const payload: WorkspaceSummary = {
      topic: topic || '',
      background: '-',
      specificRequest: '-',
      solution: '-',
      note: notes || '-'
    };
    setIsReadyToClose(true);

    try {
      await updateWorkspaceSummary(account!?.accountId, selectedCall!?.conversationId, payload);
    } catch {
      messageApi.open({
        type: 'error',
        content: 'Update data gagal!'
      });
    }
  };

  /**
   * Submits the KYC verdict.
   *
   * @param isManualKYCMode - Whether the KYC is in manual mode.
   * @param isApprovedKYC - Whether the KYC is approved.
   * @param rejectReason - The reason for rejecting the KYC.
   * @param nik - The government ID.
   * @param metadata - The metadata consist of NIK & Ref ID.
   * @param chooseKTP - The chosen KTP document.
   * @param chooseSelfie - The chosen selfie document.
   */
  const submitSummaryInfo = async () => {
    if (isManualKYCMode) {
      await submitSummary();
      queryClient.invalidateQueries('WorkspaceSummary');
      setPage(KYCPageEnum.SUBMITTED);
    }
  };
  useEffect(() => {
    form.setFieldsValue({
      topic: data?.topic !== '-' ? data?.topic : null,
      notes: data?.note !== '-' ? data?.note : null
    });
  }, [isLoading, data, form]);
  return (
    <>
      {contextHolder}
      <div className="customer-form my-2 flex w-full flex-col gap-2 rounded-2xl border border-solid border-black p-4">
        {resolved || (data?.topic && data?.topic !== '-') ? (
          <div className="break-words">
            <h3 className="m-0 text-sm">Topic</h3>
            <p className="m-0 text-sm">{data?.topic}</p>
            <h3 className="m-0 mt-2 text-sm">Catatan</h3>
            <p className="m-0 text-sm">{data?.note}</p>
          </div>
        ) : (
          <div>
            <Form
              id="customer-summary-form"
              layout="vertical"
              initialValues={{ size: 'default' }}
              form={form}
              disabled={false}
              className="customer-form w-full">
              <div className="mb-6 w-full">
                <TopicAgent form={form} />
                <p className="mt-4 text-orange-danger">
                  {!form?.getFieldValue('topic') && '* Wajib Diisi'}
                </p>
              </div>
              <TextAreaWithLabel
                id="notes"
                label="Catatan"
                placeholder="Tulis permintaan / pertanyaan / keluhan pelanggan"
                isShowCount
                charLimit={1000}
              />
            </Form>
            <Button
              type="primary"
              htmlType="submit"
              className={`${!isSentBtnDisabled ? `bg-bold-green` : `bg-grey-50`}`}
              disabled={isSentBtnDisabled}
              onClick={submitSummaryInfo}>
              <p className="text-white">Kirim</p>
            </Button>
          </div>
        )}
      </div>
    </>
  );
}

export default KYCSummary;
