import { Hook, Table, TableColumnTypeDef, TableRowTypeModifier } from '@squantumengine/horizon';
import DetailCardLayout from './components/DetailCardLayout';
import { useMemo } from 'react';
import { useAuthStore } from '../../../store/new/auth';
import { useQuery } from 'react-query';
import { fetchUserList } from '../../../api';
import { RoleEnum } from '../../../static/role';

type SupervisorTableType = {
  name: string;
  phoneNumber: string;
  email: string;
};

type ModifiedSupervisorTableType = TableRowTypeModifier<SupervisorTableType>;

export default function SupervisorTeamDetail() {
  const { account } = useAuthStore();
  const { accountId } = account;

  const { data: supervisorList, isLoading } = useQuery(['get-supervisors'], () =>
    fetchUserList(accountId, [RoleEnum.SUPERVISOR])
  );

  const supervisorTableData = supervisorList?.map((data) => ({
    name: `${data?.firstName ?? ''} ${data?.lastName ?? ''}`,
    phoneNumber: data.phone,
    email: data.email
  }));

  const tableColumns = useMemo(() => {
    const isEmailAvailable = supervisorTableData?.every((data) => !!data.email);
    const tableColumnsMain: TableColumnTypeDef<ModifiedSupervisorTableType> = [
      {
        header: 'Nama Supervisor',
        accessorKey: 'name',
        width: 300
      }
    ];
    const tableColumnsEmail: TableColumnTypeDef<ModifiedSupervisorTableType> = [
      {
        header: 'E-mail',
        accessorKey: 'email',
        enableSorting: false
      }
    ];
    const tableColumnsPhone: TableColumnTypeDef<ModifiedSupervisorTableType> = [
      {
        header: 'Nomor Telepon',
        accessorKey: 'phoneNumber',
        enableSorting: false
      }
    ];
    return isEmailAvailable
      ? [...tableColumnsMain, ...tableColumnsEmail]
      : [...tableColumnsMain, ...tableColumnsPhone];
  }, [supervisorTableData]);

  const table = Hook.useTable({
    data: supervisorTableData,
    columns: tableColumns,
    isLoading
  });

  return (
    <DetailCardLayout title="Daftar Supervisor">
      <Table
        data={supervisorTableData}
        table={table}
        columns={tableColumns}
        isLoading={isLoading}
      />
    </DetailCardLayout>
  );
}
