import axios, { InternalAxiosRequestConfig } from 'axios';
import { isDatadogLogEnabled, sendLogError, sendLogInfo } from '../datadog';
import HttpStatusCode from '../../types/enums/request';
import { PageLocation } from '../../types/enums/page';
import { LocalStorageKey } from '../../static/local-storage';
import { LoginPlatform, SSOTokenObject } from '../../shared/types/auth.interface';
import { local } from '../../utils/storage';
import { getCookie } from '../../utils/cookie-management';
import { CookieNameEnum } from '../../static/cookie-management';
import { useAuthStore } from '../../store/new/auth';
import { forceLogOut } from '../../utils/login';

const { CancelToken } = axios;
const source = CancelToken.source();

const axiosInstancev2 = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/v2/`,
  headers: {
    'Content-Type': 'application/json',
    authenticationPlatform: `${LoginPlatform.NATIVE}`,
    Authorization: `jwt ${localStorage.getItem('accessToken')?.replaceAll('"', '')}`
  }
});

const interceptorSQEID = (config: InternalAxiosRequestConfig) => {
  const tokenObj: SSOTokenObject = JSON.parse(
    local.getItem(LocalStorageKey.SQEID_TOKEN_OBJECT) || '{}'
  );
  const { access_token } = tokenObj;

  if (access_token) {
    config.headers.Authorization = `jwt ${access_token}`;
  }

  return config;
};

const interceptorNATIVE = (config: InternalAxiosRequestConfig) => {
  const nativeAccessToken = getCookie(CookieNameEnum.NATIVE_TOKEN);

  if (!nativeAccessToken) {
    forceLogOut();

    source.cancel('Token Expired');
    config.cancelToken = source.token;

    return config;
  }

  config.headers.Authorization = `jwt ${nativeAccessToken}`;

  return config;
};

axiosInstancev2.interceptors.request.use((config) => {
  const authProvider = useAuthStore.getState().authProvider;
  const isSQEIDLoggedIn = useAuthStore.getState().isSQEIDLoggedIn();

  if (isSQEIDLoggedIn || authProvider === LoginPlatform.SIMAS_ID) {
    config.headers.authenticationPlatform = LoginPlatform.SIMAS_ID;
    return interceptorSQEID(config);
  }

  config.headers.authenticationPlatform = LoginPlatform.NATIVE;
  return interceptorNATIVE(config);
});

axiosInstancev2?.interceptors.response.use(
  (response: any) => {
    if (isDatadogLogEnabled) {
      sendLogInfo(`[${response.status}] ${JSON.stringify(response.data)}`, {
        host: window.location.hostname,
        path: window.location.pathname,
        api_path: response?.config?.url,
        method: response?.config?.method,
        status_code: response?.status
      });
    }

    return response;
  },
  (error: any) => {
    const code = error.response?.status;
    if (
      error.response?.data?.statusCode === HttpStatusCode.UNAUTHORIZED
    ) {

      forceLogOut();
    } else if ([HttpStatusCode.REQUEST_TIMEOUT].includes(code)) {
      window.location.href = `${PageLocation.ERROR}/${code}`;
    }
    if (isDatadogLogEnabled) {
      sendLogError(`[${error?.response?.status}] ${JSON.stringify(error.response.data)}`, {
        host: window.location.hostname,
        path: window.location.pathname,
        api_path: error?.response?.config?.url,
        method: error?.response?.config?.method,
        status_code: error?.response?.status
      });
    }

    return Promise.reject(error);
  }
);

if (isDatadogLogEnabled) {
  axiosInstancev2.interceptors.request.use((request) => {
    sendLogInfo(`[${request.method?.toUpperCase()}] ${request.url}`, {
      host: window.location.hostname,
      path: window.location.pathname,
      api_path: request?.url || '',
      method: request?.method || '',
      request_body: request?.data
    });
    return request;
  });
}

export const setAuthorizationHeader = (token: string) => {
  axiosInstancev2.defaults.headers.Authorization = `jwt ${token}`;
};

export default axiosInstancev2;
