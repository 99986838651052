import { useMutation, useQuery, useQueryClient } from 'react-query';
import { createTopic, deleteTopic, getTopic } from '../../workspace';

export const useCreateTopic = () => {
  let queryClient = useQueryClient();
  return useMutation(createTopic, {
    onSuccess: () => queryClient.invalidateQueries(['get-topic'])
  });
};

export const useGetTopic = (inboxId: string) => {
  return useQuery(['get-topic', inboxId], () => getTopic(inboxId), { enabled: !!inboxId });
};

export const useDeleteTopic = () => {
  let queryClient = useQueryClient();
  return useMutation(deleteTopic, {
    onSuccess: () => queryClient.invalidateQueries(['get-topic'])
  });
};
