import React from 'react';

const BusyHourFooter = ({ text, bgColor }: { text: string; bgColor: string }) => {
  return (
    <div className="flex flex-row items-center gap-2">
      <div className={`h-3 w-3 rounded-full ${bgColor}`} />
      <p>{text}</p>
    </div>
  );
};

export default BusyHourFooter;
