import { Flex, Modal, Form, Row, Col, Input, Select, Radio, RadioChangeEvent, Space } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import {
  useAddInboxMemberSQEID,
  useGetInboxes,
  useRemoveInboxMemberSQEID,
  useUpdateUsrProfileSQEID
} from '../../../../api/hooks/sqeid/useSQEID';
import { useEffect, useState } from 'react';
import { useUserStatusStore } from '../../../../store/user-state';
import { useConnect3dParty, useGet3dPartyMapping } from '../../../../api/hooks/native/use3dParty';
import {
  InvTeamStatusEnum,
  LoginOptionsEnum,
  roleOptions,
  SendLoginOptionsEnum,
  thirdPartyOptions,
  UserEditModalInterface
} from '../users.interface';
import { useUpdateAgentCapacity } from '../../../../api/hooks/native/useUpdateAgentCap';
import { InboxListItemInterface } from '../../../../shared/types/inbox.interface';
import { extractPhoneNumber, removeLeadingZero } from '../../../../utils/phone-codes';
import { RoleEnum } from '../../../../static/role';
import { maxChatSelection } from '../../../../static/dashboard';
import PhoneInput from './phone-input';
import { Button, Paragraph, Title } from '@squantumengine/horizon';
import useCCToaster from '../../../../hooks/useCCToaster';

const UserEditModal = ({
  setIsModalOpen,
  isModalOpen,
  form,
  selectedUser
}: UserEditModalInterface) => {
  const { mutateAsync: addInboxMember } = useAddInboxMemberSQEID();
  const { mutateAsync: removeInboxMember } = useRemoveInboxMemberSQEID();
  const { data: inboxes } = useGetInboxes(selectedUser?.account?.accountId);
  const [isProfileCompleted, setIsProfileCompleted] = useState<boolean>(false);
  const { userRole } = useUserStatusStore();

  const { data: data3dParty, isError: isErrorGet3rdPartyMapping } = useGet3dPartyMapping(
    selectedUser?.agentId,
    isModalOpen,
    isProfileCompleted
  );

  const isStatusInvited = selectedUser?.status === InvTeamStatusEnum.INVITED;

  const { contextHolder, openNotification } = useCCToaster({ timeout: 6 });
  const { mutateAsync: updateProfile, isLoading: isLoadingUpdateProfile } =
    useUpdateUsrProfileSQEID(openNotification);
  const { mutateAsync: updateAgentCapacity } = useUpdateAgentCapacity();
  const { mutateAsync: connect3dParty } = useConnect3dParty();

  const transformInbox = (data: InboxListItemInterface[]) => {
    return data?.map(
      ({ name: label, inboxId: value, ...rest }: { name: string; inboxId: string }) => ({
        label,
        value,
        ...rest
      })
    );
  };

  const [callOptions, setSendLoginOption] = useState<number>(1);

  const onChangeCallOptions = (e: RadioChangeEvent) => {
    setSendLoginOption(e.target.value);
  };

  const filterOption = (input: string, option?: { label: string; value: string }) => {
    return (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  };

  const inboxOptions = transformInbox(inboxes?.inboxList!);
  const currentInbox = transformInbox(selectedUser?.inboxes);

  useEffect(() => {
    const {
      firstName,
      phoneNo,
      role,
      email,
      status,
      username,
      employeeId,
      emailExists,
      notification,
      account,
      capacity
    } = selectedUser ?? {};

    const { extUserService } = data3dParty?.extUserService?.[0] ?? {};
    const { serviceName } = data3dParty?.extUserService?.[0] ?? {};

    let payloadEdit = {
      name: firstName,
      phone_number: extractPhoneNumber(phoneNo),
      role: role,
      assign_team: currentInbox,
      email: email,
      status: status,
      username: username,
      login_options: emailExists ? LoginOptionsEnum.EMAIL : LoginOptionsEnum.USERNAME,
      employee_id: employeeId,
      login_settings: notification?.notificationType,
      business_unit: account?.accountName,
      centrix: isErrorGet3rdPartyMapping ? '' : extUserService,
      service_name: isErrorGet3rdPartyMapping ? null : serviceName
    } as any;

    if (selectedUser && Object.keys(selectedUser).length > 0) {
      const isAgent = role === RoleEnum.AGENT;
      const isSupervisor = role === RoleEnum.SUPERVISOR;

      if (isAgent) {
        const hasCapacity = Object.keys(capacity ?? {}).length > 0;
        setIsProfileCompleted(hasCapacity);

        payloadEdit = {
          ...payloadEdit,
          max_chat: hasCapacity ? capacity?.maxChatCapacity : maxChatSelection[0]?.value,
          max_video: hasCapacity ? capacity?.maxCallCapacity : callOptions
        };
      } else if (isSupervisor) {
        setIsProfileCompleted(!!selectedUser?.employeeId);
      }
    }

    form.setFieldsValue(payloadEdit);
  }, [isModalOpen, data3dParty, selectedUser]);

  const handleSelect = async (value: string) => {
    await addInboxMember({
      accountId: selectedUser?.account?.accountId,
      agentId: selectedUser?.agentId,
      inboxId: value
    }).catch((err) =>
      // eslint-disable-next-line no-console
      console.error(err)
    );
  };

  const handleDeSelect = async (value: string) => {
    await removeInboxMember({
      accountId: selectedUser?.account?.accountId,
      agentId: selectedUser?.agentId,
      inboxId: value
    }).catch((err) =>
      // eslint-disable-next-line no-console
      console.error(err)
    );
  };

  const onFinish = async (values: any) => {
    const { agentId, email, status, account, role } = selectedUser || {};
    const {
      name,
      role: roleForm,
      employee_id,
      status: statusForm,
      max_chat,
      max_video,
      service_name,
      centrix,
      phone_code,
      phone_number
    } = values;

    try {
      let payload = {
        agentId: agentId,
        email: email,
        firstName: name,
        phoneNo: `${phone_code}${removeLeadingZero(phone_number)}`,
        role: roleForm,
        employeeId: employee_id,
        status: status,
        accountId: account?.accountId
      } as any;

      if (status !== InvTeamStatusEnum.INVITED) {
        payload = {
          ...payload,
          status: statusForm
        };
      }

      if (role === RoleEnum.AGENT) {
        await updateAgentCapacity({
          accountId: account?.accountId,
          agentId: agentId,
          agentCapacity: {
            maxChatCustomers: max_chat,
            maxCallCustomers: max_video
          },
          isProfileCompleted
        });
      }

      const isCanFill3rdParty = isProfileCompleted && !!service_name;

      if (isCanFill3rdParty) {
        await connect3dParty({
          agentId: selectedUser?.agentId,
          extServiceName: 'CENTRIX',
          extUserName: centrix
        });
      }

      await updateProfile(payload).catch((err) =>
        // eslint-disable-next-line no-console
        console.error(err)
      );
    } catch (error) {
      openNotification({
        label: 'Gagal mengubah data pengguna',
        variant: 'secondary'
      });
    } finally {
      setIsModalOpen(false);
    }
  };

  const _renderSendLoginOptions = (loginOption: string, sendLoginOptions: string) => {
    const isEmailLoginOptions = loginOption === LoginOptionsEnum.EMAIL;
    const isCurrentUserSPV = userRole === RoleEnum.SUPERVISOR;

    return (
      <>
        <Col className="gutter-row" span={8}>
          <Form.Item
            name={`${isEmailLoginOptions ? 'email' : 'username'}`}
            rules={[
              {
                required: true,
                message: `${isEmailLoginOptions ? 'Email' : 'Username'} wajib diisi`
              }
            ]}
            label={
              <Paragraph className="m-0 text-base font-semibold">{`${
                isEmailLoginOptions ? 'Email' : 'Username'
              }`}</Paragraph>
            }>
            <Input
              disabled={true}
              placeholder={`Masukkan ${isEmailLoginOptions ? 'Email' : 'Username'}`}
            />
          </Form.Item>
        </Col>
        <Col className="gutter-row" span={8}>
          <Form.Item
            initialValue={'email'}
            name="login_settings"
            rules={[{ required: true, message: 'Kirim Pengaturan Login wajib diisi' }]}
            label={
              <Paragraph className="m-0 text-base font-semibold">
                Kirim Pengaturan Login ke
              </Paragraph>
            }>
            <Radio.Group disabled={true}>
              <Space direction="vertical">
                {isEmailLoginOptions && (
                  <Radio value={'email'}>Email {`${isCurrentUserSPV ? 'Agen' : ''}`}</Radio>
                )}
                <Radio value={'whatsapp'}>{`${
                  isCurrentUserSPV ? 'WhatsApp Agen' : 'WhatsApp'
                }`}</Radio>
                {isCurrentUserSPV && <Radio value={'email_spv'}>Email Supervisor</Radio>}
              </Space>
            </Radio.Group>
          </Form.Item>
        </Col>
      </>
    );
  };

  return (
    <>
      {contextHolder}
      <Modal
        width={1200}
        footer={
          <Form
            className="mt-10"
            name="edit_user"
            layout="vertical"
            form={form}
            autoComplete="off"
            onFinish={onFinish}
            size="large">
            <Flex justify="end" gap="middle">
              <Form.Item>
                <Button
                  variant="secondary"
                  onClick={() => setIsModalOpen(false)}
                  className={`h-10 w-32`}>
                  Batal
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  loading={isLoadingUpdateProfile}
                  form="edit_user"
                  key="submit"
                  className={`h-10 w-32`}>
                  Submit
                </Button>
              </Form.Item>
            </Flex>
          </Form>
        }
        title={
          <Flex justify="space-between" align="center">
            <Title className="m-0 p-0" level={3}>
              Lengkapi Profil User
            </Title>
            <CloseOutlined data-testid="close-btn-modal" onClick={() => setIsModalOpen(false)} />
          </Flex>
        }
        closable={false}
        open={isModalOpen}
        onOk={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}>
        <Form
          className="mt-10"
          name="edit_user"
          layout="vertical"
          form={form}
          autoComplete="off"
          size="large">
          <Title className="p-0 font-semibold" level={5}>
            Informasi Dasar
          </Title>
          <Row gutter={16}>
            <Col className="gutter-row" span={8}>
              <Form.Item
                name="name"
                required
                label={<Paragraph className="m-0 text-base font-semibold">Nama</Paragraph>}>
                <Input placeholder="Masukkan Nama" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <PhoneInput form={form} />
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item
                name="role"
                required
                label={<Paragraph className="m-0 text-base font-semibold">Jabatan</Paragraph>}>
                <Select disabled defaultValue="Agen" options={roleOptions} />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item
                name="business_unit"
                required
                label={<Paragraph className="m-0 text-base font-semibold">Bisnis Unit</Paragraph>}>
                <Select disabled />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item
                name="employee_id"
                rules={[{ required: true, message: 'ID Pegawai wajib diisi' }]}
                label={<Paragraph className="m-0 text-base font-semibold">ID Pegawai</Paragraph>}>
                <Input placeholder="Masukkan ID Pegawai" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item
                name="assign_team"
                label={<Paragraph className="text-base font-semibold">Tim</Paragraph>}>
                <Select
                  disabled={selectedUser?.role !== RoleEnum.AGENT}
                  filterOption={filterOption}
                  showSearch
                  onDeselect={handleDeSelect}
                  onSelect={handleSelect}
                  mode="multiple"
                  options={inboxOptions}
                />
              </Form.Item>
            </Col>
            {isProfileCompleted && (
              <Col className="gutter-row" span={8}>
                <Form.Item
                  name="status"
                  rules={[{ required: true, message: 'Please select a call setting!' }]}
                  label={<Paragraph className="m-0 text-base font-semibold">Status</Paragraph>}>
                  <Radio.Group disabled={isStatusInvited} onChange={onChangeCallOptions}>
                    <Space direction="horizontal">
                      <Radio value={InvTeamStatusEnum.ACTIVE}>Aktif</Radio>
                      <Radio value={InvTeamStatusEnum.NON_ACTIVE}>Nonaktif</Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </Col>
            )}
          </Row>
          {isProfileCompleted && (
            <>
              <Title className="p-0 font-semibold" level={5}>
                Pengaturan Login
              </Title>
              <Row gutter={16}>
                <Col className="gutter-row" span={8}>
                  <Form.Item
                    initialValue={'email'}
                    name="login_options"
                    rules={[{ required: true, message: 'Login Menggunakan wajib diisi' }]}
                    label={
                      <Paragraph className="m-0 text-base font-semibold">
                        Login Menggunakan
                      </Paragraph>
                    }>
                    <Radio.Group disabled={true}>
                      <Space direction="vertical">
                        <Radio value={'email'}>Email</Radio>
                        <Radio value={'username'}>Username</Radio>
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                {_renderSendLoginOptions(LoginOptionsEnum.EMAIL, SendLoginOptionsEnum.EMAIL_AGENT)}
              </Row>
            </>
          )}
          {selectedUser?.role === RoleEnum.AGENT && (
            <>
              <Title className="p-0 font-semibold" level={5}>
                Pengaturan Chat & Panggilan
              </Title>
              <Row gutter={16}>
                <Col className="gutter-row" span={8}>
                  <Form.Item
                    initialValue={maxChatSelection[0]?.value}
                    name="max_chat"
                    required
                    label={
                      <Paragraph className="m-0 text-base font-semibold">Maksimal Chat</Paragraph>
                    }>
                    <Select options={maxChatSelection} />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={8}>
                  <Form.Item
                    initialValue={callOptions}
                    name="max_video"
                    rules={[{ required: true, message: 'Pengaturan panggilan wajib diisi' }]}
                    label={
                      <Paragraph className="m-0 text-base font-semibold">
                        Pengaturan Panggilan
                      </Paragraph>
                    }>
                    <Radio.Group onChange={onChangeCallOptions}>
                      <Space direction="vertical">
                        <Radio value={1}>Bisa menerima panggilan</Radio>
                        <Radio value={0}>Tidak bisa menerima panggilan</Radio>
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
          {isProfileCompleted && (
            <>
              <Title className="p-0 font-semibold" level={5}>
                Pengaturan Akun Third-Party
              </Title>
              <Row gutter={16} align="bottom" justify="start">
                <Col className="gutter-row" span={8}>
                  <Form.Item
                    name="service_name"
                    label={
                      <Paragraph className="m-0 text-base font-semibold">
                        Akun Third Party &nbsp;
                        <span className="font-normal text-gray-500">(opsional)</span>
                      </Paragraph>
                    }>
                    <Select
                      filterOption={filterOption}
                      showSearch
                      mode="multiple"
                      options={thirdPartyOptions}
                    />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={8}>
                  <Form.Item
                    name="centrix"
                    required
                    label={
                      <Paragraph className="m-0 text-base font-semibold">
                        Akun Centerix &nbsp;
                        <span className="font-normal text-gray-500">(email atau username)</span>
                      </Paragraph>
                    }>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
        </Form>
      </Modal>
    </>
  );
};

export default UserEditModal;
