import axiosInstance from '../config/axios';
import { Error } from '../shared/types/error-handling.interface';
import {
  LoginRes,
  RequestResetPasswordRes,
  ResetPasswordPayload,
  ValidateResetPasswordPayload
} from '../shared/types/login.interface';
import { withErrorHandlingAsync } from '../utils/error-handling';

export const loginNATIVE: (
  username: string,
  password: string,
  clientId: string
) => Promise<LoginRes | Error> = async (username: string, password: string, clientId: string) => {
  const res = await withErrorHandlingAsync(async () => {
    const response = await axiosInstance.post<LoginRes>(`agent/login`, {
      username,
      password,
      clientId
    });
    return response.data;
  });

  return res;
};

export const requestResetPasswordNATIVE = async (
  keyValue: string,
  key: 'email' | 'uuid'
): Promise<RequestResetPasswordRes | Error> => {
  const res = await withErrorHandlingAsync(async () => {
    const response = await axiosInstance.post<any>(
      `/user/password/reset`,
      { [key]: keyValue },
      { headers: { Authorization: null } }
    );
    return response.data;
  });

  return res;
};

export const resetPasswordNATIVE = async (body: ResetPasswordPayload) => {
  const res = await withErrorHandlingAsync(async () => {
    const response = await axiosInstance.post<any | LoginRes>(`agent/password`, body);
    return response.data;
  });

  return res;
};

export const validateResetPasswordNATIVE = async (body: ValidateResetPasswordPayload) => {
  const res = await withErrorHandlingAsync(async () => {
    const response = await axiosInstance.post<any>(`user/validate/reset-link`, body);
    return response.data;
  });

  return res;
};
