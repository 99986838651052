export const LocalStorageKey = {
  SQEID_TOKEN_OBJECT: 'sqeidTokenObj',
  SQEID_OA_CODE_VERIFIER: 'oa_code_verifier',
  SQEID_OA_STATE: 'oa_state',
  USER: 'user',
  ACCESS_TOKEN: 'accessToken',
  TOKEN_CREATED_AT: 'tokenCreatedAt',
  SQEID_AUTH_CODE: 'sqeid_auth_code',
  FAST_LOGIN: 'fastLogin',
  SQECC_AUTH_PROVIDER: 'SQECC_AUTH_PROVIDER',
  SQECC_CLIENT_CODE: 'SQECC_CLIENT_CODE'
};
