import { Flex, Typography } from 'antd';
import React from 'react';
import { getAssetIcon } from '../utils/getIconUrl';

const NoAccessRole = () => {
  return (
    <Flex align="center" justify="center" vertical className="min-h-screen w-full text-center">
      <img
        className="h-72 cursor-pointer"
        alt="sqecc-logo"
        src={getAssetIcon('done.svg')}
      />
      <Typography.Title className="m-0 p-0 font-semibold">Hak akses</Typography.Title>
      <Typography.Text className="text-base">
        Maaf, akses Anda saat ini terbatas. Anda belum memiliki izin yang <br /> diperlukan untuk
        mengakses menu ini. Harap hubungi admin/supervisor <br /> untuk mendapatkan bantuan lebih
        lanjut.
      </Typography.Text>
    </Flex>
  );
};

export default NoAccessRole;
