import { useQuery } from 'react-query';

import { CurrentUserObjectInterface } from '../../../shared/types/user.interface';
import { fetchOfflineConversationsAPI } from '../../inbox';
import { OfflineConversationEnum } from '../../../shared/types/inbox.interface';

type useOfflineconversationProps = {
  page: number,
  email?: string,
  name?: string,
  ticketNumber?: string,
  phoneNumber?: string,
  startDate?: string,
  endDate?: string,
  status?: OfflineConversationEnum,
  limit?: number,
}

export const useOfflineconversation = ({
  page,
  email,
  name,
  ticketNumber,
  phoneNumber,
  startDate,
  endDate,
  status,
  limit
}: useOfflineconversationProps) => {
  const currentUser: CurrentUserObjectInterface = JSON.parse(localStorage.getItem('user') || '{}');
  const accountId: string = currentUser.account?.accountId || '';
  return useQuery(
    ['AllOfflineconversation', page, email, name, ticketNumber, phoneNumber, startDate, endDate, limit],
    () =>
      fetchOfflineConversationsAPI(
        accountId as string,
        page,
        status,
        email,
        name,
        ticketNumber,
        phoneNumber,
        startDate,
        endDate,
        limit
      ),
    { enabled: !!accountId } // Query will run only if inboxId is not null
  );
};
