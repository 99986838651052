import { Collapse, ConfigProvider } from 'antd';

type CollapseAgentProps = {
  onChange: () => void;
  label: JSX.Element | string;
  children: JSX.Element;
};

const CollapseAgent = ({ onChange, label, children }: CollapseAgentProps) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Collapse: {
            headerPadding: 0,
            contentPadding: 0
          }
        }
      }}>
      <Collapse
        ghost
        defaultActiveKey={['1']}
        onChange={onChange}
        items={[
          {
            key: '1',
            showArrow: false,
            label,
            children
          }
        ]}
      />
    </ConfigProvider>
  );
};

export default CollapseAgent;
