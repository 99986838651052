import { Modal, Button } from 'antd';
import { ErrorModalInterface } from './error-modal.interface';

import ExclamationTriangleIcon from '../../../assets/icons/exclamation-triangle';
import VideoIcon from '../../../assets/icons/video';

import './index.css';

function ErrorModal(props: ErrorModalInterface) {
  return (
    <>
      <Modal
        className="error-modal"
        centered
        open={props.modalState}
        footer={null}
        closable={false}
        width={384}>
        <div className="flex flex-row justify-center">
          <ExclamationTriangleIcon />
        </div>
        <div className="pt-7 text-center text-2xl font-semibold text-white">
          Pelanggan Telah Menunggu 00:10
        </div>
        <div className="pt-4 text-center text-base font-normal text-white">
          Jangan biarkan pelanggan menunggu terlalu lama, mohon segera jawab panggilan
        </div>
        <div className="mt-4 h-px border-none bg-white" />
        <div className="flex flex-row items-baseline pt-4">
          <VideoIcon className="scale-150" />
          <div className="pl-5 text-lg text-white">
            <div className="font-semibold">Tasya Kamila Pegipegi</div>
            <div>Tiket 924441</div>
          </div>
        </div>
        <div className="w-full pt-4">
          <Button
            shape="round"
            block
            className="flex h-12 flex-row items-center justify-center py-3 text-lg font-semibold"
            onClick={() => props.setModalState(false)}>
            Jawab
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default ErrorModal;
