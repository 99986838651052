import { Modal } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { setMeetingRoomId } from '../../../../utils/webSocket';
import { CallListInterface } from './call-list.interface';
import { useUserStatusStore } from '../../../../store/user-state';
import { RoleEnum } from '../../../../static/role';
import TimestampToString, { DurationToString } from '../../../../utils/timestamp';
import { useTimer } from '../../../../hooks/useTimer';
import { ConversationStatusEnum, MessageEventEnum } from '../../../../static/message-event';
import {
  CommunicationTypeEnum,
  ConversationContextEnum,
  confirmModalChangeCard,
  defaultCommunicationTypeEnum
} from '../../../../static/communication-type';
import { useChatListStore } from '../../../../store/chat-list';
import Copy from '../../copy';
import { loadCustomerDetail } from '../../../../pages/dashboard/dashboard.helper';
import { useCustomerDetails } from '../../../../api/hooks/customer/useCustomerDetails';
import { useWorkspaceSummary } from '../../../../api/hooks/workspace/useWorkspaceSummary';
import { useWorkspaceSaveSummary } from '../../../../api/hooks/workspace/useWorkspaceSaveSummary';
import { useCallStore } from '../../../../store/call-state';
import { useSelectedCustomerStore } from '../../../../store/customer-state';
import { CloseOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import useAgentAnswerConv from '../../../../api/hooks/inbox/useAgentAnswerConv';
import { Button, Icon, Paragraph } from '@squantumengine/horizon';
import { useInboxStore } from '../../../../store/new/inbox';
import KYCStatus from '../../kyc/kyc-status';
import { StatusKYCEnum } from '../../../kyc/kyc.interface';
import useLastChat from '../../../../hooks/useLastChat';

export const parseDuration = (
  second: number,
  inactive: boolean,
  startTime: number,
  endTime?: number
) => {
  if (endTime && startTime > endTime) {
    return DurationToString(0);
  }

  if (endTime) return DurationToString(Math.round((endTime - startTime) / 1000));

  if (inactive) return DurationToString(0);

  return DurationToString(second);
};

function CallListV2(props: CallListInterface) {
  const { data, type, customerName, handleAssignAgent } = props;
  const {
    conversationId,
    startTime,
    endTime,
    inboxName,
    kycStatus,
    agent,
    createdAt,
    conversationStatus,
    inboxConfig
  } = data;
  const agentName = `${agent?.firstName ?? ''} ${agent?.lastName ?? ''}`;
  const createdAtString = dayjs(createdAt).locale('id').format('DD MMM YY');
  // convert startTime delay to second based on current time
  const { second } = useTimer(Math.round((Date.now() - startTime) / 1000));
  const { mutate } = useWorkspaceSaveSummary();
  const { fileKTP, fileSelfie } = useSelectedCustomerStore();
  const { userRole } = useUserStatusStore();
  const { isSelectedInboxManualKyc } = useInboxStore();

  let firstRender = useRef(true);

  const {
    allChatList,
    selectedCall,
    setSelectedCall,
    setAllChatList,
    unreadMessages,
    clearUnreadMessages
  } = useChatListStore();
  const { setIsManualKYCMode } = useCallStore();
  const { setMetadata } = useSelectedCustomerStore();
  const { mutate: handleAgentAnswerConv } = useAgentAnswerConv();

  const isSelected: boolean | null =
    selectedCall && selectedCall.conversationId === data?.conversationId;
  const inactive = data.event === MessageEventEnum.INACTIVE;
  const [showModal, setShowModal] = useState(false);
  const isAgent = userRole === RoleEnum.AGENT;
  const isResolvedConv = data.conversationStatus === ConversationStatusEnum.RESOLVED;

  const { element, timestamp } = useLastChat(conversationId, isAgent);

  useEffect(() => {
    if (isSelected) {
      setMeetingRoomId(data?.meetingRoomId as string);
      if (isAgent && !isResolvedConv && !data?.jawabAt) handleAgentAnswerConv(data?.conversationId);
    }
    // eslint-disable-next-line
  }, [selectedCall?.conversationId]);

  const durationText = parseDuration(second, !!inactive, startTime, endTime);
  const { data: customerDetailsData, isFetched: isFetchedCustomerDetails } = useCustomerDetails(
    data.contactId as string,
    isSelected,
    selectedCall
  );
  const { data: workspaceSummaryData, isFetched: isFetchedWorkspaceSummary } = useWorkspaceSummary(
    data.conversationId as string,
    isSelected,
    selectedCall
  );

  useEffect(() => {
    if (
      isSelected &&
      firstRender.current &&
      isFetchedCustomerDetails &&
      isFetchedWorkspaceSummary
    ) {
      loadCustomerDetail(data, mutate, customerDetailsData, workspaceSummaryData);
      firstRender.current = false;
    }

    // eslint-disable-next-line
  }, [workspaceSummaryData, customerDetailsData, selectedCall]);
  const onConfirmation = async () => {
    const showConfirmModal = fileKTP !== null || fileSelfie !== null;
    showConfirmModal ? setShowModal(true) : onClickHandler();
  };
  const onClickHandler = async () => {
    setShowModal(false);
    const newData = { ...data };
    const isManualKYC = newData?.inboxConfig?.conversationContext === ConversationContextEnum.KYC;

    newData.isSummarySubmitted = false;
    setSelectedCall(newData);
    setIsManualKYCMode(isManualKYC);
    setMeetingRoomId(data?.meetingRoomId as string);
    setMetadata(data?.metadata);

    localStorage.setItem('selectedCall', JSON.stringify(data));
    data.inboxId && clearUnreadMessages(data?.conversationId, data.inboxId);

    const filteredChatlist = allChatList.filter(
      (dataArr) =>
        dataArr.conversationId !== data?.conversationId &&
        dataArr?.event === MessageEventEnum.CREATED
    );
    setAllChatList(filteredChatlist);
  };

  const isVoiceComType = type === CommunicationTypeEnum.VOICE; //  communcation type from inbox config
  const isVideoComType = type === CommunicationTypeEnum.VIDEO; //  communcation type from inbox config
  const isMetaDataComTypeAudioVideo =
    data.metadata?.communicationType === defaultCommunicationTypeEnum.AUDIO_VIDEO; //  communcation type from inbox metadata if user request switch from audio to video
  const isVoiceCall = isVoiceComType || (isVideoComType && isMetaDataComTypeAudioVideo);
  const unreadMessageCount = unreadMessages[data?.conversationId] || 0;
  const showReassignButton =
    userRole === RoleEnum.SUPERVISOR && data.event === MessageEventEnum.ACTIVE && handleAssignAgent;

  return (
    <>
      {unreadMessageCount !== 0 && !isSelected && !isAgent && (
        <div className="absolute -right-2 -top-2 flex h-8 w-8 items-center justify-center rounded-full border-2 border-solid border-orange-danger bg-orange-danger-light font-bold text-white">
          {unreadMessageCount > 9 ? '9+' : unreadMessageCount}
        </div>
      )}

      {isAgent && (
        <div
          onClick={() => {
            onConfirmation();
          }}
          className={`cursor-pointer text-neutral-950 ${isSelected ? 'bg-blue-50' : ''}`}>
          <div className="flex shrink-0 flex-row items-center gap-2 p-4">
            <div
              className={`flex h-[48px] w-[48px] shrink-0 flex-row items-center justify-center rounded-full border border-solid border-neutral-300 ${
                inactive ? 'bg-neutral-200' : 'bg-white'
              }`}>
              <Icon
                name={isVoiceCall ? 'phone' : isVideoComType ? 'video' : 'comment'}
                color={inactive ? 'white' : 'neutral-950'}
              />
            </div>
            <div className="flex-1">
              <div className="flex flex-row items-center justify-between gap-2">
                <Paragraph size="l" weight="semibold" className={`w-1 flex-1 truncate`}>
                  {customerName}
                </Paragraph>
                <Paragraph className={`max-w-full truncate text-neutral-600`}>
                  {inactive
                    ? dayjs(new Date(timestamp)).format('DD/MM/YY')
                    : TimestampToString(timestamp)}
                </Paragraph>
              </div>

              <div className="flex flex-row items-center justify-between gap-2">
                <Paragraph className={`w-1 flex-1 truncate`}>{element}</Paragraph>
                {unreadMessageCount !== 0 && (
                  <span className="hz-text-white hz-bg-red-500 hz-rounded-full hz-text-body-s-bold hz-w-4 hz-h-4 hz-flex hz-items-center hz-justify-center">
                    {unreadMessageCount}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {!isAgent && (
        <div
          onClick={() => {
            onConfirmation();
          }}
          className={`min-h-[210px] cursor-pointer rounded-lg border border-solid text-neutral-950
          ${inactive && !isSelected && 'bg-neutral-50'}
           ${isSelected ? 'border-blue-500 bg-blue-50' : 'border-neutral-100'}`}>
          <div className="flex shrink-0 flex-row items-center gap-1 p-4">
            <div className={`relative flex flex-row items-center ${inactive && 'opacity-50'}`}>
              {inactive && (
                <div className="absolute left-[-3px] top-[-3px] h-4 w-4 rounded-full bg-white">
                  <Icon name="check-circle" size="sm" />
                </div>
              )}
              <Icon name={isVoiceCall ? 'phone' : isVideoComType ? 'video' : 'comment'} />
            </div>
            <Paragraph
              size="l"
              weight="semibold"
              className={`max-w-full grow truncate ${inactive && 'text-neutral-600'}`}>
              {customerName}
            </Paragraph>
          </div>

          <hr className="m-0 border border-b-0 border-solid border-neutral-100" />

          <div className="px-4 py-3">
            <div className="mb-1 flex flex-row items-center justify-between">
              <Copy value={conversationId}>
                <Paragraph className="text-neutral-600">Tiket</Paragraph>
              </Copy>
              <Paragraph className="w-[80px] truncate">#{conversationId}</Paragraph>
            </div>

            {isSelectedInboxManualKyc() && inactive && (
              <KYCStatus
                kycStatus={kycStatus as StatusKYCEnum}
                conversationStatus={conversationStatus as ConversationStatusEnum}
                conversationContext={inboxConfig?.conversationContext as ConversationContextEnum}
              />
            )}

            <div className="mb-1 flex flex-row items-center justify-between">
              <Paragraph className="text-neutral-600">Durasi</Paragraph>
              <Paragraph>{durationText}</Paragraph>
            </div>

            {inactive && (
              <div className="flex flex-row items-center justify-between">
                <Paragraph className="text-neutral-600">Tanggal</Paragraph>
                <Paragraph className="truncate">{createdAtString}</Paragraph>
              </div>
            )}

            <div className="mt-3 flex flex-row items-center justify-center gap-1 truncate rounded-md border border-solid border-neutral-400 bg-neutral-100 px-1 py-0.5 text-base font-normal">
              <div className="flex flex-row items-center">
                <Icon name="contact-center" size="sm" color="#505D6E" />
              </div>
              <Paragraph
                weight="semibold"
                size="s"
                className="max-w-full truncate text-neutral-600">
                {agentName}
              </Paragraph>
            </div>
          </div>

          {inboxName && (
            <div
              className={`${
                inactive
                  ? 'border-grey-80 bg-grey-10'
                  : 'border-green-emerald bg-green-10 text-green-emerald'
              } mt-2 rounded-2xl border border-solid text-center`}>
              {inboxName}
            </div>
          )}

          {!inactive && <hr className="m-0 border border-b-0 border-solid border-neutral-100" />}

          {showReassignButton && (
            <div className="flex flex-row items-center justify-center py-1">
              <Button
                data-testid="button-alihkan"
                variant="text"
                onClick={() => handleAssignAgent?.(data)}>
                Ganti Agen
              </Button>
            </div>
          )}
        </div>
      )}

      <Modal
        title={
          <div className="flex items-center justify-between">
            <h3 className="m-0 p-0 text-2xl font-semibold">{confirmModalChangeCard.title}</h3>
            <div data-testid="button-modal-close" onClick={() => setShowModal(false)}>
              <CloseOutlined />
            </div>
          </div>
        }
        open={showModal}
        closable={false}
        footer={false}
        width={336}>
        <div className="space-y-2">
          <div className="text-md m-0 py-2">{confirmModalChangeCard.description} </div>
          <div className="flex flex-col space-y-3">
            <button
              className="cursor-pointer rounded-3xl border-0 bg-orange-danger py-2 font-sans text-base font-semibold text-white outline-none duration-200 ease-out hover:bg-[#b34307]"
              onClick={() => onClickHandler()}>
              {confirmModalChangeCard.okText}
            </button>
            <button
              className="cursor-pointer rounded-3xl bg-transparent py-2 font-sans text-base font-semibold duration-200 ease-out hover:bg-slate-200"
              onClick={() => setShowModal(false)}>
              {confirmModalChangeCard.cancelText}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default CallListV2;
