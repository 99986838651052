import { Row, Col, Divider, Button } from 'antd';
import { TourTooltipInterface } from './tour.interface';

function Tooltip({
  continuous,
  index,
  size,
  step,
  backProps,
  closeProps,
  primaryProps,
  tooltipProps
}: TourTooltipInterface) {
  const stepArr = Array(size).fill({ active: false });

  return (
    <div className="max-w-md rounded-lg bg-white p-6" {...tooltipProps}>
      {step.title && (
        <>
          <Row>
            <h2 className="m-0">{step.title}</h2>
          </Row>
          <Divider className="my-4" />
        </>
      )}
      <Row>
        <Col>{step.content}</Col>
      </Row>
      <Row justify="end" className="mt-4">
        <Col span={12} className="flex flex-row items-end">
          {stepArr.map((item: any, idx: number) => (
            <div
              className={`mr-1 h-2 w-2 rounded-full ${
                idx === index ? 'bg-green-emerald' : 'bg-grey-50'
              }`}
            />
          ))}
        </Col>
        <Col span={12}>
          <Row justify="end" className="mt-4">
            {continuous ? (
              <div
                className="
            m-1 w-fit rounded-md border border-solid 
            border-transparent bg-bold-green py-2 px-4 font-semibold text-white
            hover:cursor-pointer hover:bg-[#1a8263] 
            active:border-[#12a378] active:bg-[#197358]"
                {...primaryProps}>
                {primaryProps.title}
              </div>
            ) : (
              <Button {...closeProps}>
                <div id="close" />
              </Button>
            )}
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default Tooltip;
