import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const ChatSVG = () => (
  <svg width="1em" height="1em" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.00002 0.166748C3.3021 0.166748 0.333355 2.61466 0.333355 5.58341C0.333355 6.83341 0.828146 7.97925 1.68752 8.89071C1.32294 9.90633 0.515646 10.7917 0.489605 10.7917C0.307313 10.974 0.281271 11.2345 0.359396 11.4688C0.463563 11.7032 0.697938 11.8334 0.958355 11.8334C2.5469 11.8334 3.82294 11.1824 4.57815 10.6355C5.33335 10.8699 6.14065 11.0001 7.00002 11.0001C10.6719 11.0001 13.6667 8.57821 13.6667 5.58341C13.6667 2.61466 10.6719 0.166748 7.00002 0.166748ZM7.00002 9.75008C6.2969 9.75008 5.59377 9.64591 4.94273 9.43758L4.34377 9.25529L3.84898 9.61987C3.4844 9.88029 2.96356 10.1667 2.33856 10.3751C2.5469 10.0626 2.72919 9.698 2.8594 9.33341L3.14585 8.60425L2.59898 8.03133C2.13023 7.53654 1.58335 6.70321 1.58335 5.58341C1.58335 3.29175 4.00523 1.41675 7.00002 1.41675C9.96877 1.41675 12.4167 3.29175 12.4167 5.58341C12.4167 7.90112 9.96877 9.75008 7.00002 9.75008Z"
      fill="currentColor"
    />
  </svg>
);

const ChatIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={ChatSVG} {...props} />
);

export default ChatIcon;
