import { Popover, Typography } from 'antd';
import dayjs from 'dayjs';
import ReactJson from 'react-json-view';
import { toSentenceCase } from '../../../utils/formatter';
import { DEFAULT_DATE_RANGE } from '../dashboard/components/filter/datepicker';

export const AUDIT_TRAIL_TABLE_COLUMNS = [
  { title: 'ID', dataIndex: 'uuid', width: 360 },
  {
    title: 'Tanggal dan waktu',
    dataIndex: 'createdAt',
    width: 240,
    render: (dateString: string) => dayjs(dateString).format('DD MMM YYYY, HH:mm:ss')
  },
  { title: 'Email', render: (record: Record<string, any>) => record?.updatedBy?.email },
  {
    title: 'Aktivitas',
    dataIndex: 'changeType',
    render: (changeType: string, record: Record<string, any>) => {
      if (record?.newValues && Object.keys(record.newValues).length > 0) {
        return (
          <Popover
            content={
              <div className="flex max-h-96 max-w-[640px] space-x-2 overflow-y-scroll">
                <div>
                  <Typography.Title level={5}>Old Values</Typography.Title>
                  <ReactJson src={record?.oldValues || {}} />
                </div>
                <div>
                  <Typography.Title level={5}>New Values</Typography.Title>
                  <ReactJson src={record?.newValues || {}} />
                </div>
              </div>
            }>
            <span className="cursor-pointer font-medium">{toSentenceCase(changeType)}</span>
          </Popover>
        );
      }
      return toSentenceCase(changeType);
    }
  }
];

export enum AUDIT_TRAIL_ACTION_TYPE_ENUM {
  CREATE_BUSINESS_UNIT = 'CREATE_BUSINESS_UNIT',
  UPDATE_BUSINESS_UNIT = 'UPDATE_BUSINESS_UNIT',
  CREATE_USER = 'CREATE_USER',
  UPDATE_USER = 'UPDATE_USER',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGIN_FAILED = 'LOGIN_FAILED',
  LOGOUT = 'LOGOUT',
  UPDATE_TO_ONLINE = 'UPDATE_TO_ONLINE',
  UPDATE_TO_OFFLINE = 'UPDATE_TO_OFFLINE',
  ASSIGNED_CONVERSATION = 'ASSIGNED_CONVERSATION',
  SWITCH_CONVERSATION = 'SWITCH_CONVERSATION',
  FILL_TOPIC_AND_SUMMARY = 'FILL_TOPIC_AND_SUMMARY',
  RESOLVED_CONVERSATION = 'RESOLVED_CONVERSATION',
  APPROVE_VERDICT = 'APPROVE_VERDICT',
  REJECT_VERDICT = 'REJECT_VERDICT',
  VERDICT_MANUAL_KYC = 'VERDICT_MANUAL_KYC',
  CREATE_TEAM = 'CREATE_TEAM',
  UPDATE_TEAM = 'UPDATE_TEAM',
  ASSIGN_AGENT_TO_INBOX = 'ASSIGN_AGENT_TO_INBOX',
  REMOVE_AGENT_FROM_INBOX = 'REMOVE_AGENT_FROM_INBOX',
  DELETE_BUSINESS_UNIT = 'DELETE_BUSINESS_UNIT',
  DELETE_USER = 'DELETE_USER',
  DELETE_TEAM = 'DELETE_TEAM',
  RESET_PASSWORD = 'RESET_PASSWORD',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
  ENABLE_USER = 'ENABLE_USER',
  DISABLE_USER = 'DISABLE_USER',
  ASSIGN_ROLE_TO_USER = 'ASSIGN_ROLE_TO_USER',
  REMOVE_ROLE_FROM_USER = 'REMOVE_ROLE_FROM_USER',
  ASSIGN_TEAM_TO_USER = 'ASSIGN_TEAM_TO_USER',
  REMOVE_TEAM_FROM_USER = 'REMOVE_TEAM_FROM_USER',
  PRE_CLOSE_CONVERSATION = 'PRE_CLOSE_CONVERSATION'
}

export const sizeChangerOptions = [
  { label: '10 / page', value: 10 },
  { label: '20 / page', value: 20 },
  { label: '50 / page', value: 50 },
  { label: '100 / page', value: 100 }
];
export const DATE_FILTER_FORMAT = 'YYYY-MM-DDTHH:mm:ss.sssZ';

export const INITIAL_FILTER_VALUES = {
  'page-no': 1,
  'page-size': 10,
  startDate: DEFAULT_DATE_RANGE[0].format(DATE_FILTER_FORMAT),
  endDate: DEFAULT_DATE_RANGE[1].format(DATE_FILTER_FORMAT),
  email: '',
  action: ''
};
