import { useEffect, useState } from 'react';
import { Button, Flex, Modal, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { goToLogin } from '../../utils/oAuth';
import { RoleEnum } from '../../static/role';
import { LocalStorageKey } from '../../static/local-storage';
import { CloseOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Token } from '@squantumengine/horizon';
import { getAssetIcon } from '../../utils/getIconUrl';

const { Text } = Typography;
const { COLORS } = Token;

function SimasIDLogin() {
  const navigate = useNavigate();
  const { user } = useAuth();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const loginWithSQEID = () => {
    goToLogin();
  };

  useEffect(() => {
    const accessToken = localStorage.getItem(LocalStorageKey.ACCESS_TOKEN)?.replaceAll('"', '');
    if (accessToken && accessToken !== '') {
      const redirectUrl = user?.role === RoleEnum.ADMIN ? '/dashboard/team' : '/dashboard';
      navigate(redirectUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex align="center" justify="start" vertical className="h-screen w-full bg-blue-50">
      <img className="h-20" alt="sqecc-logo" src={getAssetIcon('sqecc-logo.svg')} />
      <Flex
        align="center"
        justify="center"
        gap="middle"
        vertical
        className="mt-32 w-3/12 rounded-2xl bg-white p-6 text-center">
        <img alt="simasid-logo" className="w-full" src={getAssetIcon('simas-id-bg.svg')} />
        <Text className="text-3xl font-semibold">Login via SIMAS ID</Text>
        <Text className="text-base">
          Anda akan diarahkan untuk masuk ke Simas ID dahulu, lalu diarahkan kembali ke halaman
          utama.
        </Text>
        <Button
          className="mt-4 h-12 w-full bg-bold-green text-lg font-semibold"
          type="primary"
          shape="round"
          size="large"
          htmlType="submit"
          onClick={() => loginWithSQEID()}>
          Oke, Masuk ke Simas ID
        </Button>
        <Button onClick={() => setIsModalOpen(true)} className="border-none outline-none">
          <Flex align="center" gap="small">
            <Text className="text-lg font-semibold text-gray-500">Apa itu SIMAS ID?</Text>
            <QuestionCircleOutlined
              style={{
                fontSize: '1.2rem',
                color: `${COLORS.neutral[600]}`
              }}
            />
          </Flex>
        </Button>
        <p className="m-0">
          Dengan login maka kamu menyetujui&nbsp;
          <a href="/privacy-policies" target="_blank" className="text-green-200">
            Persyaratan & Kebijakan privacy
          </a>
          &nbsp; SQECC
        </p>
      </Flex>
      <Modal
        style={{
          pointerEvents: 'auto'
        }}
        centered
        footer={null}
        title={<Text className="text-xl font-semibold">Apa itu SIMAS ID?</Text>}
        open={isModalOpen}
        modalRender={() => (
          <Flex vertical gap="small" className="relative rounded-3xl bg-white">
            <img alt="simasid-logo-2" src={getAssetIcon('simas-id-bg-2.svg')} />
            <div className="absolute left-5 right-5 top-5 z-50 flex flex-row items-center justify-between">
              <Text className="text-xl font-semibold">Apa itu SIMAS ID?</Text>
              <CloseOutlined
                className="cursor-pointer"
                onClick={() => setIsModalOpen(false)}
                style={{
                  fontSize: '1.5rem'
                }}
              />
            </div>
            <Flex className="p-5">
              <Text>
                Login menggunakan aplikasi pihak ketiga, khususnya dengan SIMASID, adalah metode
                autentikasi yang memungkinkan pengguna untuk mengakses platform atau layanan
                tertentu dengan menggunakan identitas dari SIMASID sebagai penyedia layanan
                eksternal. SIMASID, sebagai sistem otentikasi, dapat memberikan pengguna kemudahan
                akses tanpa perlu membuat akun baru pada setiap platform atau aplikasi yang
                mendukung SIMASID.
              </Text>
            </Flex>
          </Flex>
        )}></Modal>
    </Flex>
  );
}

export default SimasIDLogin;
