import React from 'react';
import { ChatFooterInterface } from './chat-footer.interface';
import { Button, Icon, Paragraph } from '@squantumengine/horizon';
import { Form } from 'antd';

const SendChat = ({ chat, addChat, handleChange }: ChatFooterInterface) => {
  const msgCounter = chat!.length;
  const maxCharacter = 1024;
  const isLimitCharacter = msgCounter > maxCharacter;

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      !isLimitCharacter && addChat(event);
    }
  };

  return (
    <Form name="chat-form" className="relative flex w-full p-4 pb-12">
      <textarea
        style={{ resize: 'none' }}
        className="w-full border-none font-['Inter'] focus:outline-none"
        placeholder="Tulis Pesan"
        rows={5}
        value={chat}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      />
      <Paragraph
        size="s"
        className={`absolute bottom-4 right-14 ${isLimitCharacter && 'text-red-700'}`}>
        {msgCounter}/{maxCharacter}
      </Paragraph>
      <Button
        onClick={(e: any) => addChat(e)}
        type="submit"
        disabled={isLimitCharacter}
        className={`absolute bottom-2 right-4 h-9 w-9 rounded-full  ${
          isLimitCharacter || !chat ? 'cursor-not-allowed bg-gray-400' : ' bg-blue-500'
        }`}>
        <Icon name="paper-plane" size="md" color="white" className="-translate-x-0.5" />
      </Button>
    </Form>
  );
};

export default SendChat;
