import { Icon, Paragraph } from '@squantumengine/horizon';
import useAgentActivity from '../../../../hooks/useAgentActivity';

const AgentList = () => {
  const { agentCapacityList } = useAgentActivity();

  const isOngoingChat = (agent: any) => {
    return agent.capacity.onGoingChatCapacity > 0;
  };

  const isOngoingCall = (agent: any) => {
    return agent.capacity.onGoingCallCapacity > 0;
  };

  return (
    <div className="flex max-h-44 flex-col gap-4 overflow-y-scroll">
      {agentCapacityList?.map((agent) => (
        <div key={agent.id} className="flex flex-row justify-between">
          <Paragraph size="r">
            {agent.firstName} {agent.lastName}
          </Paragraph>
          <div className="flex flex-row items-center gap-2">
            <div className="flex flex-row items-center justify-start gap-1">
              <Icon name="comment" />
              <Paragraph
                size="l"
                weight={isOngoingChat(agent) ? 'bold' : 'regular'}
                className={`${isOngoingChat(agent) ? 'text-blue-500' : 'text-neutral-600'}`}>
                {agent.capacity.onGoingChatCapacity}
              </Paragraph>
            </div>

            <div className="flex flex-row items-center justify-start gap-1">
              <Icon name="video" />
              <Paragraph
                size="l"
                weight={isOngoingCall(agent) ? 'bold' : 'regular'}
                className={`${isOngoingCall(agent) ? 'text-blue-500' : 'text-neutral-600'}`}>
                {agent.capacity.onGoingCallCapacity}
              </Paragraph>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AgentList;
