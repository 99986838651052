import { useState } from 'react';
import { message, notification } from 'antd';
import { TimestampToStringDateAndTime } from '../../../../utils/timestamp';
import { CommunicationTypeEnum } from '../../../../static/communication-type';
import { useConversationSummary } from '../../../../api/hooks/workspace/useWorkspaceSummary';
import { HistoryCardProps } from '../../../form/workspace-customer-files/history/workspace-customer-files-history.interface';
import { getVideoRecordingUrl } from '../../../../api/workspace';
import { downloadFileFromUrl } from '../../../../config/file-api-handler';
import { downloadChat } from '../../../form/workspace-customer-files/history/workspace-customer-files-history.helper';
import { contactConversationHistory } from '../../../../static/routeConstructor';
import { Button, Icon, Paragraph, Token } from '@squantumengine/horizon';

const HistoryItem = (props: HistoryCardProps) => {
  const { communicationType, createdAt, agent, conversationId, contact, inbox } = props;
  const [loadingDownloadConv, setLoadingDownloadConv] = useState<Record<string, any>>({});
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [loadingNotification, loadingNotifContext] = message.useMessage();
  const key = 'updatable';

  const toggleExpanded = () => setIsExpanded((prev) => !prev);
  const { data: summary } = useConversationSummary(conversationId, isExpanded);

  const isChat = communicationType === CommunicationTypeEnum.CHAT;
  const agentName = `${agent?.firstName || '-'} ${agent?.lastName || ''}`;
  const inboxId = inbox?.inboxId || '';

  const handleDownloadChat = () => downloadChat(conversationId, createdAt, contact.firstName);
  const handleDownloadVideo = async () => {
    setLoadingDownloadConv((prev) => ({ ...prev, [conversationId]: true }));

    try {
      const res = await getVideoRecordingUrl(conversationId);
      const { downloadUrl, filename, message } = res.data || {};
      if (downloadUrl) {
        loadingNotification.open({
          key,
          type: 'loading',
          content: 'Sedang mengunduh video...',
          duration: 0
        });
        await downloadFileFromUrl(downloadUrl, filename);
        loadingNotification.open({
          key,
          type: 'success',
          content: 'Unduh video selesai',
          duration: 2
        });
      } else if (message) notification.info({ message });
    } catch (error: any) {
      notification.error({
        message: error?.response?.data?.message || 'Failed to download video recording.'
      });
    }
    setLoadingDownloadConv((prev) => ({ ...prev, [conversationId]: false }));
  };

  return (
    <>
      {loadingNotifContext}
      <div className="w-full items-start space-y-4 rounded-2xl border-x-0 p-2">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-2">
            <div className="flex h-[48px] w-[48px] flex-row items-center justify-center rounded-full border border-solid border-neutral-300">
              <Icon name={isChat ? 'comment' : 'video'} />
            </div>
            <div className="flex-1">
              <a
                className="cursor-pointer text-[14px] font-semibold text-blue-500"
                href={contactConversationHistory({
                  inboxId,
                  conversationId,
                  contactId: contact?.id!!
                })}
                target="_blank"
                rel="noreferrer">
                ID #{conversationId?.substring(conversationId?.length - 5)}
              </a>
              <Paragraph>
                {`${TimestampToStringDateAndTime(new Date(createdAt))} - ${agentName}`}
              </Paragraph>
            </div>
          </div>
          <div className="cursor-pointer pt-1" onClick={toggleExpanded}>
            {isExpanded ? <Icon name="chevron-small-up" /> : <Icon name="chevron-small-down" />}
          </div>
        </div>
        {isExpanded && (
          <div className="space-y-2">
            <div>
              <Paragraph className="text-neutral-600">Topik</Paragraph>
              <Paragraph>{summary?.topic || '-'}</Paragraph>
            </div>
            <div>
              <Paragraph className="text-neutral-600">Catatan</Paragraph>
              <Paragraph className="break-words">{summary?.note || '-'}</Paragraph>
            </div>

            <Button size="sm" variant="secondary" full onClick={handleDownloadChat}>
              <Icon name="arrow-to-bottom" color={Token.COLORS.blue[500]} />
              Unduh Percakapan
            </Button>
            {!isChat && (
              <Button
                size="sm"
                variant="secondary"
                full
                onClick={handleDownloadVideo}
                disabled={loadingDownloadConv[conversationId]}>
                <Icon name="arrow-to-bottom" color={Token.COLORS.blue[500]} />
                Unduh Video
              </Button>
            )}
          </div>
        )}
        <div className="border-x-0 border-b border-t-0 border-solid border-neutral-100" />
      </div>
    </>
  );
};

export default HistoryItem;
