import { useEffect, useState } from 'react';
import Joyride, { CallBackProps } from 'react-joyride';

import CustomTooltip from './layout';
import { useTourStore } from '../../store/tour-state';
import { useTeamStore } from '../../store/team-state';
import { FormActionEnum, FormTypeEnum } from '../../static/team';

import { adminStep, supervisorStep } from './step';
import { RoleEnum } from '../../static/role';
import { CurrentUserObjectInterface } from '../../shared/types/user.interface';

function TourConfig() {
  const { run, steps, setRun, setStepIndex, setSteps } = useTourStore();
  const { setIsOpenForm, setFormAction, setFormType } = useTeamStore();
  const [currentUser, setCurrentUser] = useState<CurrentUserObjectInterface>(
    {} as CurrentUserObjectInterface
  );
  const [listenSubs, setListenSubs] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const inboxSubs = useTeamStore.subscribe(
    (state) => state.inbox,
    (inbox) => {
      if (!listenSubs) {
        setListenSubs(true);
        if (currentUser.role === RoleEnum.SUPERVISOR) {
          const accountId = inbox[0]?.accountId;
          const inboxId = inbox[0]?.inboxList[0]?.inboxId;
          setSteps(
            supervisorStep.map((step, idx) => {
              let target;
              switch (idx) {
                case 1:
                  target = `#inbox_list-create_button-${accountId}`;
                  break;
                case 4:
                  target = `#inbox_list-${accountId}`;
                  break;
                case 5:
                  target = `#inbox_detail-edit_button-${inboxId}`;
                  break;
                case 6:
                  target = `#inbox_detail-user_counter-${inboxId}`;
                  break;
                case 8:
                  target = `#inbox_list-create_user-${accountId}`;
                  break;
                case 10:
                  target = `#user_list-${inboxId}`;
                  break;
                default:
                  target = step.target;
                  break;
              }
              return {
                ...step,
                target
              };
            })
          );
          setListenSubs(false);
        }
      }
    }
  );

  const handleCallback = (data: CallBackProps) => {
    const { action, index, type } = data;

    if (action === 'next') {
      if (type === 'step:after') {
        switch (index) {
          case 1:
            setIsOpenForm(true);
            setFormAction(FormActionEnum.CREATE);
            if (currentUser.role === RoleEnum.ADMIN) {
              setFormType(FormTypeEnum.ACCOUNT);
            } else {
              setFormType(FormTypeEnum.INBOX);
            }
            break;
          case 6:
            setIsOpenForm(true);
            setFormAction(FormActionEnum.CREATE);
            setFormType(FormTypeEnum.USER);
            break;
          default:
            break;
        }
      }

      if (type === 'step:before') {
        if (index === 4) {
          setIsOpenForm(false);
        }
        if (currentUser.role === RoleEnum.SUPERVISOR && index === 10) {
          setIsOpenForm(false);
        }
      }
    }

    if (action === 'reset' || action === 'close') {
      setRun(false);
      setStepIndex(0);
      localStorage.setItem('isTourDone', 'true');
    }
  };

  useEffect(() => {
    const isTourDone = localStorage.getItem('isTourDone');
    const user: CurrentUserObjectInterface = JSON.parse(localStorage.getItem('user') || '{}');
    setCurrentUser(user);

    if (!isTourDone) {
      setRun(true);
    }

    if (user.role === RoleEnum.ADMIN) {
      setSteps(adminStep);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Joyride
      callback={handleCallback}
      continuous
      disableScrolling
      disableCloseOnEsc
      disableOverlayClose
      hideBackButton
      run={run}
      steps={steps}
      locale={{
        back: 'Kembali',
        close: 'Tutup',
        last: 'Selesai',
        next: 'Lanjut',
        skip: 'Lewati'
      }}
      styles={{
        options: {
          arrowColor: '#FFF',
          backgroundColor: '#FFF',
          primaryColor: '#1A936F',
          textColor: '#313131'
        }
      }}
      tooltipComponent={CustomTooltip}
    />
  );
}

export default TourConfig;
