import { Form, Input } from 'antd';
import { ProfileInputInterface } from './profile.interface';

const ProfileInput = ({ formAttribute, label, disabled }: ProfileInputInterface) => {
  return (
    <Form.Item
      label={label}
      name={formAttribute}
      rules={[{ required: !disabled, message: `Tolong masukkan ${label.toLowerCase()} Anda!` }]}>
      <Input disabled={disabled} />
    </Form.Item>
  );
};

export default ProfileInput;
