import FileUploadInterface from './chat-file-upload.interface';
import { Icon } from '@squantumengine/horizon';

export default function FileUpload(props: FileUploadInterface) {
  const { data } = props;
  const newUrl = data?.url;
  return (
    <div className={`relative h-[56px] rounded-lg bg-neutral-100`}>
      <a className="w-full" href={newUrl} rel="noreferrer" target="_blank">
        <div className="absolute h-[56px] gap-4 rounded-lg bg-green-100"></div>
        <div className="absolute flex h-full w-full place-items-center gap-4 px-5">
          <div className="flex w-full flex-row items-center gap-4 justify-self-start truncate">
            <Icon name="file" />
            <div className="w-full justify-self-start truncate text-ellipsis whitespace-nowrap text-neutral-950">
              {data?.name}
            </div>
          </div>
          <div className="flex rounded-full border border-solid border-neutral-950 p-1">
            <Icon name="arrow-down" />
          </div>
        </div>
      </a>
    </div>
  );
}
