import dayjs from 'dayjs';

export const startCountdown = (
  timestamp: number,
  duration: number,
  onChange: (value: string) => void,
  onEnd: () => void
) => {
  const endTime = dayjs(timestamp).add(duration, 'minute');

  const updateCountdown = () => {
    const now = dayjs();
    const remainingTime = endTime.diff(now);
    if (remainingTime >= 0) {
      const minutes = Math.floor(remainingTime / (1000 * 60));
      const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);
      onChange(`${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`);
    } else {
      clearInterval(countdownInterval);
      onChange('00:00');
      onEnd();
    }
  };

  const countdownInterval = setInterval(updateCountdown, 1000);
  return () => clearInterval(countdownInterval); // Clean up interval on component unmount
};
