import { useEffect, useState } from 'react';
import { Checkbox, Col, Form, Row, Switch, TimePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';

import { Icon, Paragraph } from '@squantumengine/horizon';
import { Token } from '@squantumengine/horizon';

import useMount from '../../../../hooks/useMounth';
import useTeamCreateEditStore from '../store';
import useCCToaster from '../../../../hooks/useCCToaster';
import '../../../login.css';

import { OperationalHour, WorkingHour } from '../../../../shared/types/operational-hour.interface';

const { COLORS } = Token;

interface DaysProps {
  dayOfWeek: number;
  name: string;
  openHour: Dayjs;
}

const FormOperationalHour = () => {
  const [form] = Form.useForm();
  const formValues = Form.useWatch((val) => val, form);
  const { setOperationalHour, operationalHour } = useTeamCreateEditStore();
  const { contextHolder, openNotification } = useCCToaster({ timeout: 10, position: 'top' });
  const [days, setDays] = useState<DaysProps[]>([
    { dayOfWeek: 1, name: 'Senin', openHour: dayjs() },
    { dayOfWeek: 2, name: 'Selasa', openHour: dayjs() },
    { dayOfWeek: 3, name: 'Rabu', openHour: dayjs() },
    { dayOfWeek: 4, name: 'Kamis', openHour: dayjs() },
    { dayOfWeek: 5, name: 'Jumat', openHour: dayjs() },
    { dayOfWeek: 6, name: 'Sabtu', openHour: dayjs() },
    { dayOfWeek: 7, name: 'Minggu', openHour: dayjs() }
  ]);

  const format = 'HH:mm';

  const isDayActive = (dayOfWeek: number) => {
    if (!formValues) return;
    return formValues[`dayOfWeek-${dayOfWeek}`].isActive;
  };

  const isOpenAllDay = (dayOfWeek: number) => {
    return formValues[`dayOfWeek-${dayOfWeek}`].openAllDay;
  };

  const copyForAllday = () => {
    const copy = formValues[`dayOfWeek-1`];

    const newValues: any = {};

    Object.keys(formValues).forEach((k) => {
      newValues[k] = { ...copy };
    });

    form.setFieldsValue(newValues);

    openNotification({
      label: 'Jam kerja diterapkan untuk semua hari buka.',
      variant: 'primary',
      buttonLabel: 'Tutup'
    });
  };

  const disabledDateTime = (date: Dayjs, day: DaysProps) => {
    return {
      disabledHours: () =>
        Array(parseInt(dayjs(day.openHour).format('HH')))
          .fill(0)
          .map((_, i) => i),
      disabledMinutes: (test: any) => {
        const openHour = dayjs(day.openHour).format('HH');
        const openMinutes = dayjs(day.openHour).format('mm');
        const closeHour = dayjs(date).format('HH');
        if (openHour === closeHour) {
          return Array(parseInt(openMinutes))
            .fill(0)
            .map((_, i) => i);
        }
        return [];
      },
      disabledSeconds: () => []
    };
  };

  const handleOpenAllDay = (checked: boolean, formItemName: string) => {
    if (checked) {
      form.setFieldValue([formItemName, 'openHour'], dayjs('00:00', format));
      form.setFieldValue([formItemName, 'closeHour'], dayjs('00:00', format));
    }
  };

  useEffect(() => {
    const formatedValues: WorkingHour[] = [];

    if (!formValues) return;

    const newDays = [...days];

    Object.keys(formValues).forEach((k, idx) => {
      formatedValues.push({
        dayOfWeek: idx + 1,
        openHour: dayjs(formValues[k].openHour || '00:00', format).get('hours'),
        openMinutes: dayjs(formValues[k].openHour || '00:00', format).get('minutes'),
        closeHour: dayjs(formValues[k].closeHour || '00:00', format).get('hours'),
        closeMinutes: dayjs(formValues[k].closeHour || '00:00', format).get('minutes'),
        openAllDay: formValues[k].openAllDay || false,
        closedAllDay: !formValues[k].isActive
      });

      newDays[idx].openHour = dayjs(formValues[k].openHour);
    });

    const value: OperationalHour = {
      outOfOfficeMessage: '',
      workingHoursEnabled: true,
      workingHours: formatedValues
    };

    setOperationalHour(value);
    setDays(newDays);
  }, [formValues, setOperationalHour]);

  useMount(() => {
    const defaultValue: any = {};

    days.forEach((d) => {
      const value = operationalHour.workingHours.find((w) => w.dayOfWeek === d.dayOfWeek);

      if (value) {
        defaultValue[`dayOfWeek-${d.dayOfWeek}`] = {
          isActive: !value.closedAllDay,
          openHour: dayjs(
            `${value.openHour.toString().padStart(2, '0')}:${value.openMinutes
              .toString()
              .padStart(2, '0')}`,
            format
          ),
          closeHour: dayjs(
            `${value.closeHour.toString().padStart(2, '0')}:${value.closeMinutes
              .toString()
              .padStart(2, '0')}`,
            format
          ),
          openAllDay: value.openAllDay
        };
      }
    });

    form.setFieldsValue(defaultValue);
  });

  return (
    <div className="space-y-4 px-4 pb-4">
      <div className="login-form space-y-4 rounded-xl bg-white p-6 shadow-md">
        {contextHolder}
        <h3 className="m-0 flex items-center space-x-2 text-lg font-semibold">
          Jadwal Operasional
        </h3>
        <div>
          <Form
            name="basic"
            layout="horizontal"
            autoComplete="off"
            form={form}
            requiredMark={false}>
            {days.map((day, index) => (
              <Row key={`dayOfWeek-${day.dayOfWeek}`}>
                <Col className="w-[120px]">
                  <Form.Item
                    name={[`dayOfWeek-${day.dayOfWeek}`, 'isActive']}
                    valuePropName="checked">
                    <Checkbox value={1} className="leading-8">
                      {day.name}
                    </Checkbox>
                  </Form.Item>
                </Col>
                {isDayActive(day.dayOfWeek) && (
                  <>
                    <Col className="w-[73px]">
                      <Form.Item name={[`dayOfWeek-${day.dayOfWeek}`, 'openHour']}>
                        <TimePicker
                          defaultValue={dayjs('00:00', format)}
                          format={format}
                          suffixIcon={undefined}
                          allowClear={false}
                          disabled={isOpenAllDay(day.dayOfWeek)}
                        />
                      </Form.Item>
                    </Col>
                    <Col className="mx-2 text-center leading-8">Hingga</Col>
                    <Col className="mr-2 w-[73px]">
                      <Form.Item name={[`dayOfWeek-${day.dayOfWeek}`, 'closeHour']}>
                        <TimePicker
                          defaultValue={dayjs('00:00', format)}
                          format={format}
                          suffixIcon={undefined}
                          allowClear={false}
                          disabled={isOpenAllDay(day.dayOfWeek)}
                          disabledTime={(date) => disabledDateTime(date, day)}
                        />
                      </Form.Item>
                    </Col>
                    <Col className="mr-2">
                      <div className="flex flex-row">
                        <Form.Item
                          name={[`dayOfWeek-${day.dayOfWeek}`, 'openAllDay']}
                          className="text-center"
                          valuePropName="checked">
                          <Switch
                            className="mr-1"
                            data-testid={`switch-dayOfWeek-${day.dayOfWeek}`}
                            onClick={(val) => handleOpenAllDay(val, `dayOfWeek-${day.dayOfWeek}`)}
                          />
                        </Form.Item>
                        <span className="leading-8"> 24 Jam</span>
                      </div>
                    </Col>
                    {index === 0 && (
                      <Col className="h-[56px] text-neutral-600">
                        <div
                          data-testid="button-terapkan-untuk-semua"
                          className="flex h-8 cursor-pointer flex-row items-center justify-center rounded-md p-2 hover:bg-neutral-100"
                          onClick={copyForAllday}>
                          <Icon name="copy" color={COLORS.neutral[600]} />
                          <Paragraph size="s">Terapkan untuk semua hari</Paragraph>
                        </div>
                      </Col>
                    )}
                  </>
                )}
              </Row>
            ))}
          </Form>
        </div>
      </div>
    </div>
  );
};

export default FormOperationalHour;
