interface KYCActivityFooterInterface {
  className: string;
  text: string;
}

const KYCActivityFooter = ({ className, text }: KYCActivityFooterInterface) => {
  return (
    <div className="flex flex-row items-center gap-2">
      <div className={`h-3 w-3 rounded-full ${className}`} />
      <p className="m-0 p-0 text-base">{text}</p>
    </div>
  );
};

export default KYCActivityFooter;
