export enum ItemKey {
  TODAY = 0,
  WEEK_AGO = 1,
  MONTH_AGO = 2,
  THREE_MONTH_AGO = 3,
  CHOSEN_DATE = 4
}

export const items = [
  {
    label: 'Hari Ini',
    key: ItemKey.TODAY
  },
  {
    label: '7 Hari Terakhir',
    key: ItemKey.WEEK_AGO
  },
  {
    label: '30 Hari Terakhir',
    key: ItemKey.MONTH_AGO
  },
  {
    label: '90 Hari Terakhir',
    key: ItemKey.THREE_MONTH_AGO
  },
  {
    label: 'Pilih Tanggal',
    key: ItemKey.CHOSEN_DATE
  }
];
