import { InboxMemberInterface, workingHour } from '../shared/types/inbox.interface';
import { InboxInterface } from '../types/new/inbox';
import request from '../utils/request';

export const getAccountInboxList = async (accountId: string) => {
  const response = await request.get<InboxInterface[]>(`/account/${accountId}/inbox`);
  return response.data;
};

export const getAgentInboxList = async (accountId: string, agentId: string) => {
  const response = await request.get<{
    accountId: string;
    inboxList: InboxInterface[];
  }>(`/account/${accountId}/agent/${agentId}/inbox`);
  return response.data;
};

export const createWebWidgetInbox = async (accountId: string, params: Record<string, any>) => {
  const response = await request.post<InboxInterface>(
    `/account/${accountId}/web-widget/inboxes`,
    params
  );
  return response.data;
};

export const createWhatsappInbox = async (accountId: string, params: Record<string, any>) => {
  const response = await request.post<InboxInterface>(
    `/account/${accountId}/whatsapp/inboxes`,
    params
  );
  return response.data;
};

export const editWhatsappInbox = async (
  accountId: string,
  inboxId: string,
  params: Record<string, any>
) => {
  const response = await request.put<InboxInterface>(
    `/account/${accountId}/inbox/${inboxId}/whatsapp/inboxes`,
    params
  );
  return response.data;
};

export const editWebWidgetInbox = async (
  accountId: string,
  inboxId: string,
  params: Record<string, any>
) => {
  const response = await request.put<InboxInterface>(
    `/account/${accountId}/inbox/${inboxId}/web-widget/inboxes`,
    params
  );
  return response.data;
};

export const getInboxDetail = async (accountId: string, inboxId: string) => {
  const response = await request.get<InboxInterface>(
    `/account/${accountId}/inbox/${inboxId}/inboxes`
  );
  return response.data;
};

export const updateWorkingHours: (
  accountId: string,
  inboxId: string,
  payload: {
    workingHoursEnabled: boolean;
    outOfOfficeMessage: string;
    workingHours: workingHour[];
  }
) => Promise<any[]> = async (accountId, inboxId, payload) => {
  const response = await request.put(
    `account/${accountId}/inbox/${inboxId}/working-hours`,
    payload
  );
  return response.data;
};

export const createTopic = async (inboxId: string, topic: string) => {
  const response = await request.post(`/inbox/${inboxId}/conversation-topic`, { topic });
  return response.data;
};

export const createKYCRejectReason = async (inboxId: string, reason: string) => {
  const response = await request.post(`/inbox/${inboxId}/reject/reason`, { reason });
  return response.data;
};

export const fetchInboxUserList: (
  accountId: string,
  inboxId: string
) => Promise<InboxMemberInterface> = async (accountId, inboxId) => {
  try {
    const response = await request.get(`account/${accountId}/inbox/${inboxId}/members`);
    return response.data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export const addInboxMembers: ({
  accountId,
  inboxId,
  agentIds
}: {
  accountId: string;
  inboxId: string;
  agentIds: string[];
}) => Promise<any[]> = async ({ accountId, inboxId, agentIds }) => {
  const response = await request.post(
    `account/${accountId}/inbox/${inboxId}/bulk/member?agentIds=${agentIds}`
  );
  return response.data;
};

export const removeInboxMember: ({
  accountId,
  inboxId,
  agentIds
}: {
  accountId: string;
  inboxId: string;
  agentIds: string[];
}) => Promise<any[]> = async ({ accountId, inboxId, agentIds }) => {
  const response = await request.delete(
    `account/${accountId}/inbox/${inboxId}/bulk/member?agentIds=${agentIds}`
  );
  return response.data;
};
