import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const ChatSlashSVG = () => (
  <svg width="1em" height="1em" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.3332 3.66662C19.0832 3.66662 22.9998 6.66661 22.9998 10.3333C22.9998 11.4166 22.6665 12.4166 22.0832 13.2916L23.6665 14.4999C24.4998 13.2916 24.9998 11.8749 24.9998 10.3333C24.9998 5.58328 20.2082 1.66661 14.3332 1.66661C12.2915 1.66661 10.4582 2.12495 8.83317 2.91661L10.6248 4.33328C11.7498 3.91662 12.9998 3.66662 14.3332 3.66662ZM14.3332 16.9999C13.2082 16.9999 12.0832 16.8333 11.0415 16.4999L10.0832 16.2083L9.2915 16.7916C8.70817 17.2083 7.87484 17.6666 6.87484 17.9999C7.20817 17.4999 7.49984 16.9166 7.70817 16.3333L8.1665 15.1666L7.2915 14.2499C6.5415 13.4583 5.6665 12.1249 5.6665 10.3333C5.6665 9.66661 5.7915 8.99995 6.0415 8.37495L4.4165 7.08328C3.9165 8.12495 3.6665 9.20828 3.6665 10.3333C3.6665 12.3333 4.45817 14.1666 5.83317 15.6249C5.24984 17.2499 3.95817 18.6666 3.9165 18.6666C3.62484 18.9583 3.58317 19.3749 3.70817 19.7499C3.87484 20.1249 4.24984 20.3333 4.6665 20.3333C7.20817 20.3333 9.24984 19.2916 10.4582 18.4166C11.6665 18.7916 12.9582 18.9999 14.3332 18.9999C15.8748 18.9999 17.3748 18.7499 18.7082 18.2499L16.7498 16.7499C15.9998 16.9166 15.1665 16.9999 14.3332 16.9999ZM27.4165 19.9583L21.0415 14.9999L20.7498 14.7916L2.49984 0.499948C2.20817 0.249948 1.7915 0.333281 1.5415 0.583281L1.12484 1.12495C0.916504 1.41661 0.958171 1.83328 1.24984 2.04161L26.1665 21.5416C26.4165 21.7499 26.8748 21.7083 27.0832 21.4166L27.4998 20.9166C27.7498 20.6249 27.6665 20.2083 27.4165 19.9583Z"
      fill="#949494"
    />
  </svg>
);

const ChatSlashIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={ChatSlashSVG} {...props} />
);

export default ChatSlashIcon;
