import { Row } from 'antd';
import { Skeleton } from '@squantumengine/horizon';

type SkeletonTicketProps = {
  isOfflineTicket?: boolean;
};

const SkeletonTicket = ({ isOfflineTicket }: SkeletonTicketProps) => {
  return (
    <Row className={`grid grid-cols-3 gap-4 bg-white p-4`}>
      {Array(9)
        .fill(1)
        .map((_, index) => index + 1)
        .map((_, idx) => (
          <div
            key={`skeleton-inactive-${idx}`}
            className={`min-h-[${
              isOfflineTicket ? '135px' : '210px'
            }] rounded-lg border border-solid border-neutral-100 bg-neutral-50`}>
            <Skeleton className="h-full w-full" />
          </div>
        ))}
    </Row>
  );
};

export default SkeletonTicket;
