import { Typography } from 'antd';
const { Text } = Typography;

export enum PrivacyPolicyEnum {
  PRIVACY_POLICY = 'Kebijakan Privasi',
  TERMS_AND_CONDITIONS = 'Syarat & Ketentuan'
}

export const menus = ['Kebijakan Privasi', 'Syarat & Ketentuan'];

export const privacyPolicies = [
  {
    title: 'Kebijakan Privasi',
    content: (
      <Text className="text-base">
        Berlaku mulai dari tanggal 30 Juli 2023
        <br />
        <br />
        Dengan menggunakan Layanan SQECC, Anda mengakui bahwa Anda telah menyetujui dan terikat
        dengan ketentuan yang terdapat dalam Kebijakan Privasi ini.  Definisi
        <br />
        1. “Data Pribadi” berarti data perseorangan yang diidentifikasi langsung atau melalui 
        kombinasi dengan Informasi lain.
        <br />
        2. “Hukum Yang Berlaku” berarti seluruh undang-undang, perundangan, peraturan, kebijakan
        peraturan, ordonansi, pengesahan, izin atau persyaratan dari peradilan, badan administratif,
        otoritas, atau badan pengawas apapun, yang berlaku dari waktu ke waktu.
        <br />
        <br />
        <span className="font-bold">Data Pribadi yang dikumpulkan SQECC</span>
        <br />
        Berikut ruang lingkup dan jenis Data Pribadi yang akan SQECC proses sehubungan dengan
        penggunaan Layanan SQECC oleh Anda (sebagaimana berlaku, yakni bergantung terhadap konteks
        atau keadaan dari pengumpulan dan sifat dari Layanan SQECC yang digunakan):
        <br />
        1. Data Kontak, termasuk namun tidak terbatas pada nama, alamat email, nomor telepon, dan
        lain-lain. <br />
        2. Data yang berhubungan dengan aktivitas Anda pada saat menggunakan Layanan SQECC, termasuk
        namun tidak terbatas pada channel yang Anda gunakan.
        <br />
        <br />
        SQECC menggunakan cookies dalam peningkatan kualitas layanan SQECC, meliputi manajemen sesi,
        personalisasi layanan, dan perekaman aktivitas pengguna. Anda dapat memilih untuk
        menonaktifkan cookies pada browser, tetapi apabila dilakukan, fungsi tertentu dari layanan
        SQECC akan menjadi terbatas.
        <br />
        <br />
        <span className="font-bold">Pengumpulan Data Pribadi oleh SQECC</span>
        <br />
        Pada saat SQECC mengumpulkan Data Pribadi Anda, SQECC juga dapat menggabungkan Data Pribadi
        yang telah dikumpulkan dengan informasi lain yang telah dimiliki SQECC atau yang telah
        diterima dari pihak lain yang bekerja sama dengan SQECC, berdasarkan hukum untuk memberikan
        Data Pribadi Anda kepada SQECC. Data Pribadi dikumpulkan melalui beberapa cara, yaitu:
        <br />
        1. Melalui pemberian oleh Anda secara langsung ketika mendaftar atau menggunakan Layanan
        SQECC. <br />
        2. Melalui platform, browser, dan perangkat yang Anda gunakan
        <br />
        <br />
        Apabila Anda menyediakan kepada SQECC Data Pribadi yang berkaitan dengan individu lain, Anda
        menyatakan bahwa Anda telah memperoleh persetujuan dari individu terkait untuk, dan dengan
        ini menyetujui atas nama individu tersebut untuk pengumpulan, penggunaan, pengungkapan, dan
        pengolahan Data Pribadi mereka oleh SQECC.
        <br />
        <br />
        <span className="font-bold">Penggunaan Data Pribadi</span>
        <br />
        SQECC menggunakan Data Pribadi Anda untuk membangun layanan SQECC yang lebih baik. Data
        pribadi yang dikumpulkan dari Anda akan disimpan dan digunakan oleh SQECC untuk tujuan
        berikut ataupun tujuan lain yang diizinkan oleh Hukum Yang Berlaku (“Tujuan”): <br />
        1. Menyediakan Layanan SQECC <br />
        2. Mendaftarkan Anda sebagai pengguna pada Platform SQECC, dan juga untuk
        mengadministrasikan dan/atau mengelola akun Anda <br />
        3. Memantau, memahami dan menganalisis aktivitas dan perilaku Anda termasuk kebiasaan dan
        penggunaan berbagai layanan yang tersedia di Platform SQECC
        <br />
        4. Menyediakan kepada afiliasi dan/atau pihak ketiga yang bekerja sama dengan SQECC (dalam
        hal ini yakni vendor, agen, pemasok, kontraktor, konsultan, mitra, dan pihak lain yang
        menyediakan layanan kepada SQECC atau Anda) yang secara teknis diperlukan untuk memproses
        layanan untuk Anda atau tujuan kerja sama SQECC dengan pihak ketiga tersebut (tergantung
        keadaannya) <br />
        5. Untuk menghasilkan informasi statistik dan data analitik untuk tujuan pengujian,
        penelitian, analisis, pengembangan produk
        <br />
        6. Tujuan lainnya yang SQECC beritahukan kepada Anda, dan/atau apabila disyaratkan oleh
        Hukum Yang Berlaku, sebagaimana SQECC akan memperoleh persetujuan dari Anda <br />
        <br /> Kami mungkin menggabungkan informasi yang SQECC kumpulkan di Layanan SQECC di seluruh
        perangkat Anda untuk Tujuan yang dijelaskan di atas. Bergantung pada setelan akun Anda,
        aktivitas Anda di situs dan aplikasi lain mungkin dikaitkan dengan Data Pribadi Anda untuk
        meningkatkan Layanan SQECC. <br />
        <br />
        Kami berhak untuk sewaktu-waktu mengubah atau memutus akses kepada sebagian atau seluruh
        Layanan Kami untuk sementara waktu dalam hal diperlukan perbaikan dan/atau pengelolaan lebih
        lanjut dalam rangka meningkatkan pelayanan atau dalam hal adanya dugaan kebocoran atau
        pelanggaran keamanan tanpa pemberitahuan terlebih dahulu kepada Anda.
        <br />
        <br />
        <span className="font-bold">Data Pribadi yang Dibagikan</span>
        <br />
        Hanya Anda, pihak ketiga dan/atau pihak-pihak lain yang telah diberikan persetujuan Anda,
        yang dapat mengakses dan/atau melihat informasi pribadi yang terdapat di dalam akun SQECC
        Anda. Dalam hal Anda memberikan persetujuan kepada pihak ketiga untuk menggunakan akun SQECC
        untuk mengakses atau menggunakan layanan yang disediakan oleh pihak ketiga, penggunaan Data
        Pribadi Anda oleh pihak ketiga tersebut tunduk kepada syarat dan ketentuan dan/atau
        kebijakan privasi yang berlaku pada pihak ketiga tersebut.
        <br />
        <br />
        <span className="font-bold">
          Pihak ketiga dan/atau pihak-pihak lain yang dimaksud antara lain dapat berupa:
        </span>
        <br />
        1. Individu, organisasi, entitas, atau otoritas pemerintahan, atau aparat penegak hukum
        dalam rangka proses penegakan hukum dan/atau pengambilan tindakan pencegahan sehubungan
        dengan kegiatan yang tidak sah, dugaan tindak pidana, pelanggaran hukum, atau peraturan
        perundang-undangan, dibuktikan dengan menunjukkan bahwa pihak tersebut adalah pihak yang
        berwenang dan/atau diwajibkan oleh aturan hukum dan/atau otoritas yang berwenang yang
        menimbulkan kewajiban hukum kepada SQECC untuk mengungkap Data Pribadi Anda kepada pihak
        tersebut dalam rangka pemenuhan kewajiban ketentuan Hukum Yang Berlaku
        <br />
        2. Unit Bisnis atau pihak ketiga yang bekerja sama dengan dan/atau menyediakan layanan
        kepada SQECC sehingga dapat menyediakan layanan kepada Anda sehubungan dengan penggunaan
        Data Pribadi Anda tersebut. Unit Bisnis atau pihak ketiga tersebut menyediakan layanan
        kepada SQECC dalam bentuk layanan verifikasi, autentikasi, analisis, promosi, deteksi
        penipuan, pembayaran, atau dukungan pelanggan lainnya. SQECC memastikan bahwa Unit Bisnis
        atau pihak ketiga tersebut hanya akan menggunakan Data Pribadi Pengguna sesuai keperluannya
        untuk mendukung layanan SQECC
        <br />
        3. Organisasi atau badan hukum dimana Anda diasosiasikan dan didaftarkan kepadanya melalui
        SQECC.
        <br />
        <br />
        SQECC tidak akan mengungkapkan Data Pribadi Anda dengan cara yang dianggap sebagai
        “penjualan” berdasarkan Hukum Yang Berlaku, kecuali terdapat perintah yang mengikat secara
        hukum untuk melakukan hal tersebut. Penyimpanan dan Keamanan Informasi Pribadi  SQECC
        menyimpan informasi pribadi Anda selama jangka waktu yang diperlukan untuk tujuan pemrosesan
        dan/atau sebagaimana diwajibkan sesuai dengan Hukum Yang Berlaku. Apabila terdapat
        pembatasan teknis yang menghalangi penghapusan atau anonimisasi, kami akan melindungi dan
        membatasi penggunaan aktif atas informasi pribadi tersebut Setiap informasi yang diunggah
        dan dikirim oleh Anda ke SQECC akan tersimpan secara aman dan terkirim secara rahasia dengan
        menggunakan standar industri untuk pengamanan informasi elektronik. Anda bertanggung jawab
        untuk menjaga kerahasiaan detail akun SQECC Anda dan bertanggung jawab atas keamanan
        perangkat yang Anda gunakan untuk mengakses layanan SQECC. Anda memiliki hak berikut:
        <br />
        1. Melakukan perubahan pada Data Pribadi yang terekam pada Platform SQECC
        <br />
        2. Menarik persetujuan Anda atas pemrosesan data pribadi (apabila pemrosesan data pribadi
        berdasarkan persetujuan Anda)
        <br />
        3. Menghapus Data Pribadi.
        <br />
        <br />
        Perubahan, penarikan persetujuan, atau penghapusan Data Pribadi Anda hanya dapat dilakukan
        dengan menyampaikan pemberitahuan kepada SQECC melalui kontak SQECC. SQECC berhak meminta
        dokumen pendukung dan/atau melakukan verifikasi terhadap perubahan, penarikan persetujuan,
        atau penghapusan Data Pribadi Anda sesuai dengan prosedur SQECC. Seluruh pemenuhan hak Anda
        oleh SQECC akan dilakukan sesuai dengan Hukum Yang Berlaku. Pembatasan Tanggung Jawab Anda
        akan melepaskan, membebaskan, memberikan ganti rugi, dan membela SQECC, termasuk setiap
        pejabat, direktur, karyawan, unit bisnis atau pihak ketiga yang menyediakan layanan kepada
        SQECC dari dan terhadap setiap dan segala permintaan, gugatan, klaim, kerugian, tanggung
        jawab, kewajiban, biaya, kerusakan, dan ongkos (termasuk tetapi tidak terbatas pada biaya
        hukum dan pengeluaran biaya ganti rugi) yang dihasilkan secara langsung atau tidak langsung
        dari: Segala kerugian akibat Data Pribadi yang dicegat, disadap, diakses, dicuri,
        diungkapkan, diubah atau dihancurkan oleh pihak ketiga yang tidak berwenang, karena
        faktor-faktor di luar kendali SQECC dan/atau yang bertentangan dengan Kebijakan Privasi ini
        dan Hukum Yang Berlaku, termasuk yang diakibatkan oleh kesalahan atau kelalaian Anda
        Pengumpulan, penggunaan, pemeliharaan, pembagian, atau pengungkapan data dan informasi yang
        dilaksanakan di situs atau aplikasi yang dimiliki, dikelola dan/atau dioperasikan oleh pihak
        ketiga.
        <br />
        <br />
        Kontak dan Pemberitahuan/Notifikasi Apabila ada pertanyaan terkait tentang Kebijakan Privasi
        atau pertanyaan terkait pemrosesan Data Pribadi Anda, Anda dapat menghubungi kami di&nbsp;
        <b>customer.support@sqe.co.id</b>
      </Text>
    )
  },
  {
    title: 'Syarat & Ketentuan',
    content: (
      <Text className="text-base">
        Berlaku mulai dari tanggal 30 Juli 2023
        <br />
        <br />
        Ketentuan Penggunaan ini mencerminkan cara kerja bisnis SQECC, hukum yang berlaku, dan
        hal-hal tertentu yang kami yakini benar. Oleh karena itu, Ketentuan Penggunaan ini membantu
        menentukan hubungan SQECC dengan Anda saat Anda berinteraksi dengan layanan kami. Dengan
        membaca Ketentuan Penggunaan ini Anda akan mengetahui:
        <br />
        1. Hal yang dapat Anda harapkan dari kami, yang menjelaskan cara kami menyediakan dan
        mengembangkan layanan kami
        <br />
        2. Hal yang kami harapkan dari Anda, yang menetapkan aturan tertentu untuk menggunakan
        layanan kami
        <br />
        3. Konten di layanan SQECC, yang menjelaskan hak atas kekayaan intelektual pada konten yang
        Anda temukan di layanan kami—apakah konten tersebut milik Anda, SQECC, atau orang lain 
        <br />
        4. Jika terjadi masalah atau ketidaksetujuan, yang menjelaskan hak hukum lainnya yang Anda
        miliki, dan apa yang diharapkan jika seseorang melanggar persyaratan in
        <br />
        <br />
        Penting bagi SQECC untuk Anda memahami persyaratan ini karena dengan membuat dan/atau
        menggunakan akun SQECC, Anda menyatakan telah membaca, memahami, dan menyetujui Ketentuan
        Penggunaan ini.
        <br />
        <br />
        Selain ketentuan ini, kami juga mempublikasikan Kebijakan Privasi. Meski kebijakan tersebut
        bukan bagian dari ketentuan ini, kami menghimbau Anda untuk membacanya agar lebih memahami
        cara untuk memperbarui, mengelola, dan menghapus informasi Anda.
        <br />
        <br />
        Ketentuan Penggunaan ini mungkin diubah atau diperbaharui baik sebagian maupun seluruhnya
        dari waktu ke waktu tanpa pemberitahuan sebelumnya kepada Anda, dan akan berlaku sejak SQECC
        diterbitkan. SQECC menyarankan agar Anda memeriksa situs untuk mengetahui perubahan apapun
        atas Ketentuan Penggunaan ini dari waktu ke waktu.
        <br />
        <br />
        <span className="font-bold">Definisi Umum</span>
        <br />
        1. Pengguna berarti perusahaan dan/atau usaha dimana Anda telah memberikan izin atau
        otorisasi untuk mewakili Anda <br />
        2. Produk berarti produk yang ditawarkan dan disediakan oleh SQECC, yaitu (i) Live chat,
        (ii) Video/Voice call , (iii) Manual KYC dan/atau (iv) Analytic dashboard <br />
        3. Quotation berarti sales order atau sales quotation atau dokumen serupa lainnya
        sebagaimana diterbitkan oleh SQECC secara terpisah kepada Pengguna yang mengatur terkait
        dengan paket Produk yang dipilih oleh Pengguna serta ketentuan komersial atau teknis lainnya
        sebagaimana relevan.
        <br />
        <br />
        <span className="font-bold">Rincian Paket, Biaya dan Pembayaran</span>
        <br />
        1. Pengguna mengakui, memahami, dan menyepakati bahwa rincian paket Produk yang dipilih oleh
        Pengguna adalah sebagaimana tertuang di dalam Quotation dan/atau Perjanjian Penggunaan
        (sebagaimana relevan). <br />
        2. Pengguna wajib untuk tunduk kepada seluruh ketentuan yang diatur di dalam Quotation
        dan/atau Perjanjian Penggunaan tersebut, termasuk ketentuan mengenai biaya penggunaan
        Produk, ketentuan pembayaran, dan ketentuan teknis lainnya sebagaimana relevan. Quotation
        dan/atau Perjanjian Penggunaan tersebut merupakan suatu dokumen terpisah namun merupakan
        satu kesatuan dengan Syarat dan Ketentuan ini. Apabila terdapat pertentangan atau perbedaan
        penafsiran antara Syarat dan Ketentuan serta Quotation dan/atau Perjanjian Penggunaan, maka
        yang berlaku adalah ketentuan dalam Quotation dan/atau Perjanjian Penggunaan tersebut.
        <br />
        3. Syarat dan Ketentuan ini akan terus berlaku apabila Pengguna menyepakati untuk
        memperpanjang Masa Aktif.
        <br />
        Apabila Anda menyediakan kepada SQECC Data Pribadi yang berkaitan dengan individu lain, Anda
        menyatakan bahwa Anda telah memperoleh persetujuan dari individu terkait untuk, dan dengan
        ini menyetujui atas nama individu tersebut untuk pengumpulan, penggunaan, pengungkapan, dan
        pengolahan Data Pribadi mereka oleh SQECC.
        <br />
        <br />
        <span className="font-bold">Batasan Penggunaan Layanan SQECC</span>
        <br />
        Anda setuju untuk tidak menggunakan layanan SQECC untuk melakukan transaksi atau perbuatan
        yang: <br />
        1. Melanggar hukum atau ketentuan peraturan perundang-undangan <br />
        2. Melanggar hak SQECC atau pihak ketiga, termasuk namun tidak terbatas pada hak privasi,
        hak cipta, merek, paten, rahasia dagang, atau hak kekayaan intelektual lainnya, serta hak
        kontraktual atau hak-hak lain yang dilindungi berdasarkan ketentuan peraturan perundang
        undangan yang berlaku <br />
        3. Dengan sengaja dan tanpa hak atau melawan hukum dengan cara apapun mengubah, menambah,
        mengurangi, melakukan transmisi, merusak, menghilangkan, memindahkan, menyembunyikan suatu
        informasi sehubungan dengan layanan SQECC
        <br />
        4. Dapat merusak atau mengganggu reputasi SQECC
        <br />
        <br />
        Kami berhak untuk sewaktu-waktu mengubah atau memutus akses kepada sebagian atau seluruh
        Layanan Kami untuk sementara waktu dalam hal diperlukan perbaikan dan/atau pengelolaan lebih
        lanjut dalam rangka meningkatkan pelayanan atau dalam hal adanya dugaan kebocoran atau
        pelanggaran keamanan tanpa pemberitahuan terlebih dahulu kepada Anda.
        <br />
        <br />
        <span className="font-bold">Pernyataan dan Jaminan Pengguna SQECC</span>
        <br />
        1. Pengguna secara hukum cakap dan berwenang untuk melakukan perbuatan hukum menurut hukum
        Negara Republik Indonesia, termasuk untuk mengikatkan diri pada dan melaksanakan seluruh
        syarat dan ketentuan dalam Ketentuan Penggunaan ini.
        <br />
        2. Dalam hal pengguna membuat SQECC, Akun SQECC dan/atau menggunakan layanan SQECC atas nama
        suatu persekutuan, firma, perusahaan, badan hukum, kementerian, lembaga atau organisasi,
        Pengguna memiliki kapasitas dan kewenangan untuk mewakili dan bertindak untuk dan atas nama
        persekutuan, firma, perusahaan, badan hukum, kementerian, lembaga atau organisasi tersebut,
        termasuk namun tidak terbatas untuk mengikatkannya untuk tunduk pada dan melaksanakan
        seluruh syarat dan ketentuan dalam Ketentuan Penggunaan ini.
        <br />
        3. Pengguna telah membaca dan memahami seluruh isi Ketentuan Penggunaan ini, dan karenanya
        secara sukarela setuju untuk tunduk pada dan melaksanakan seluruh syarat dan ketentuan dalam
        Ketentuan Penggunaan ini.
        <br />
        4. Pengguna dengan ini menyatakan dan menjamin penggunaan akun SQECC oleh pengguna hanya
        akan digunakan sesuai yang telah ditentukan dalam Ketentuan Penggunaan ini dengan itikad
        baik dan penuh kehati-hatian serta untuk tujuan yang sah dan tidak melanggar ketentuan hukum
        yang berlaku di Negara Republik Indonesia.
        <br />
        <br />
        Hak Milik dan Klausul Hak Kekayaan Intelektual Pengguna maupun perwakilan pengguna, tidak
        memperoleh hak milik atau hak kekayaan intelektual apapun, termasuk namun tidak terbatas
        pada paten, hak cipta, merek, rahasia dagang, atas konten yang tersedia di layanan SQECC
        (termasuk namun tidak terbatas pada seluruh informasi, perangkat lunak, informasi, teks,
        huruf, angka, susunan warna, gambar, logo, nama, video dan audio, fitur, dan pemilihan dan
        pengaturan desain). Pengguna setuju untuk tidak menggunakan hak kekayaan intelektual SQECC
        tanpa persetujuan tertulis terlebih dahulu dari SQECC. Pengguna mengakui bahwa setiap upaya
        atau pelanggaran nyata atas ketentuan terkait hak kekayaan intelektual ini akan
        mengakibatkan penghentian semua hak pengguna terkait layanan SQECC.
        <br />
        <br />
        <span className="font-bold">Keamanan dan Kerahasiaan</span>
        <br />
        1. SQECC melakukan upaya-upaya pengamanan dan penyimpanan dengan penuh kehati hatian guna
        melindungi kerahasiaan informasi pribadi pengguna dari waktu ke waktu.
        <br />
        2. Setiap konten yang diunggah dan dikirim oleh pengguna ke layanan SQECC akan tersimpan
        secara aman dan terkirim secara rahasia dengan menggunakan standar pengamanan informasi
        elektronik industri.
        <br />
        3. SQECC akan menyampaikan pemberitahuan kepada pengguna dalam hal terdapat kegagalan
        perlindungan rahasia informasi pribadi dalam sistem elektronik SQECC sesuai ketentuan
        peraturan perundang-undangan yang berlaku.
        <br />
        4. Pengguna memahami bahwa SQECC dapat memberikan akses atau mengungkapkan informasi pribadi
        pengguna dalam rangka memenuhi ketentuan hukum dan peraturan perundang-undangan, dalam
        rangka proses penegakan hukum atau pengambilan tindakan pencegahan lebih lanjut sehubungan
        dengan kegiatan yang tidak berwenang, dugaan tindak pidana atau pelanggaran hukum atau
        peraturan perundang-undangan.
        <br />
        <br />
        <span className="font-bold">Pembatasan dan Tanggung Jawab</span>
        <br />
        1. Pengguna memahami bahwa SQECC tidak memberikan pernyataan atau jaminan dalam bentuk
        apapun bahwa:
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp; a. Penggunaan SQECC akan selalu tepat waktu, selalu bekerja tanpa
        gangguan, atau selalu bebas dari perubahan, penambahan, pengurangan, transmisi, kerusakan,
        kehilangan, pemindahan, penyembunyian yang diakibatkan oleh tindakan suatu pihak dengan
        sengaja dan tanpa hak atau melawan hukum 2. Setiap konten yang diunggah dan dikirim oleh
        pengguna ke layanan SQECC akan tersimpan secara aman dan terkirim secara rahasia dengan
        menggunakan standar pengamanan informasi elektronik industri.
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp; b. SQECC dapat tetap beroperasi dan digunakan bersamaan dengan
        perangkat atau sistem pihak lainnya yang bukan disediakan atau dimiliki oleh SQECC
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp; c. SQECC akan selalu memenuhi harapan pengguna
        <br />
        2. Pengguna memahami bahwa SQECC dapat mengalami gangguan atau keterlambatan yang disebabkan
        karena ketidaktersediaan atau gangguan pada layanan pihak ketiga yang pengguna gunakan atau
        karena gangguan, ketidaktersediaan, atau tidak berfungsinya fitur tertentu pada perangkat
        yang pengguna gunakan. Pengguna setuju untuk membebaskan SQECC dari segala klaim, tuntutan
        atau gugatan sehubungan dengan tidak dilaksanakannya instruksi pengguna karena penyampaian
        informasi dari pengguna yang tidak lengkap atau adanya pembatalan atau perubahan instruksi
        yang disampaikan kepada SQECC, kecuali apabila tidak dilaksanakannya instruksi tersebut
        diakibatkan karena kesengajaan atau kelalaian berat SQECC
        <br />
        3. Pengguna setuju untuk membebaskan SQECC dari segala klaim, tuntutan, dan gugatan atas
        setiap akibat atau kerugian yang timbul sehubungan dengan adanya gangguan, keterlambatan,
        perubahan, atau ketidaktersediaan layanan SQECC (termasuk dalam hal SQECC tidak dapat
        melaksanakan atau melanjutkan sebagian atau seluruh instruksi atau komunikasi pengguna
        kepada SQECC melalui situs), yang disebabkan karena kejadian atau hal-hal di luar kekuasaan
        SQECC, termasuk namun tidak terbatas pada bencana alam, perang, huru-hara, kebijakan
        pemerintah, kegagalan sistem perbankan, krisis moneter, adanya perubahan, penambahan,
        pengurangan, transmisi, kerusakan, kehilangan, pemindahan, penyembunyian pada sistem,
        perangkat, atau layanan SQECC yang diakibatkan oleh tindakan suatu pihak dengan sengaja dan
        tanpa hak atau melawan hukum, gangguan atau ketidaktersediaan layanan pihak ketiga antara
        lain gangguan jaringan internet, daya, telekomunikasi dan arus listrik secara berkelanjutan,
        atau hal-hal lainnya di luar kekuasaan SQECC.
        <br />
        4. Pengguna setuju bahwa SQECC tidak bertanggung jawab atas setiap akibat atau kerugian yang
        timbul akibat hal-hal sebagaimana disebutkan di atas, termasuk namun tidak terbatas pada:
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp; a. Kehilangan data
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp; b. Kehilangan pendapatan, keuntungan, atau pemasukan lainnya
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp; c. Kehilangan, kerusakan atau cedera yang timbul dari penggunaan
        pengguna atas layanan SQECC.
        <br />
        5. Pengguna setuju untuk membebaskan SQECC dari segala klaim, tuntutan, gugatan, dan ganti
        rugi apapun dan dari pihak manapun yang timbul sehubungan dengan:
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp; a. Penggunaan informasi pribadi oleh SQECC berdasarkan Ketentuan
        Penggunaan ini, atau berdasarkan persetujuan, pengakuan, wewenang, kuasa, dan/atau hak yang
        telah pengguna berikan kepada SQECC
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp; b. Pemberian informasi pribadi oleh pengguna kepada SQECC yang
        dilakukan secara melanggar hukum atau peraturan perundang-undangan yang berlaku, melanggar
        hak (termasuk hak kekayaan intelektual) pihak lain manapun, atau melanggar kontrak,
        kesepakatan, pernyataan, keputusan, atau dokumen apapun dimana pengguna merupakan pihak atau
        terikat di dalamnya
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp; c. Penggunaan layanan SQECC secara tidak sah, melanggar hukum dan
        ketentuan peraturan perundang-undangan yang berlaku, hak (termasuk hak kekayaan intelektual)
        pihak lain manapun, atau melanggar kontrak, kesepakatan, pernyataan, keputusan, atau dokumen
        apapun dimana pengguna merupakan pihak atau terikat di dalamnya, atau melanggar Ketentuan
        Penggunaan ini
        <br />
        <br />
        <span className="font-bold">Pengakhiran Penggunaan Layanan SQECC</span>
        <br />
        Akun SQECC pengguna akan berlaku tanpa batasan waktu kecuali dalam hal pengguna mengirimkan
        pemberitahuan minimal 30 (tiga puluh) hari kalender sebelumnya SQECC berdasarkan
        pertimbangannya sendiri, dapat menutup atau mengakhiri penggunaan akun SQECC pengguna jika
        pengguna melanggar Ketentuan Penggunaan ini, melakukan aktivitas yang dapat menimbulkan
        kerugian pada SQECC, atau menghadirkan ancaman keamanan terhadap sistem SQECC ataupun
        pengguna lainnya.
        <br />
        <br />
        <span className="font-bold">Hukum yang Berlaku dan Penyelesaian Sengketa</span>
        <br />
        Kebijakan Privasi ini diatur dan ditafsirkan berdasarkan hukum Negara Republik Indonesia.
        SQECC dan Anda sepakat bahwa segala sengketa atau perselisihan yang timbul dari atau yang
        berkaitan dengan hal-hal yang diatur dalam Kebijakan Privasi ini (termasuk segala sengketa
        atau perselisihan yang disebabkan karena adanya perbuatan melawan hukum atau pelanggaran
        atas satu atau lebih syarat dan ketentuan dalam Kebijakan Privasi ini) ("Perselisihan”) akan
        diselesaikan dengan cara berikut ini:
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp; a. Salah satu pihak baik Anda atau SQECC (“Pihak Pertama”) wajib
        menyampaikan pemberitahuan tertulis kepada pihak lainnya (“Pihak Kedua”) atas telah
        terjadinya Perselisihan (“Pemberitahuan Perselisihan”).
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp; b. Perselisihan wajib diselesaikan secara musyawarah mufakat dalam
        waktu paling lama 30 (tiga puluh) hari kalender sejak tanggal Pemberitahuan Perselisihan
        (“Periode Penyelesaian Musyawarah”)
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp; c. Apabila Perselisihan tidak dapat diselesaikan secara musyawarah
        mufakat sampai dengan berakhirnya Periode Penyelesaian Musyawarah, Pihak Pertama dan Pihak
        Kedua sepakat bahwa Perselisihan akan dirujuk ke dan diselesaikan oleh Badan Arbitrase
        Nasional Indonesia (“BANI”) menurut Peraturan dan Prosedur Arbitrase BANI yang beralamat di
        Wahana Graha Lantai 1 dan 2, Jl. Mampang Prapatan No. 2 Jakarta 12760, dengan ketentuan
        berikut ini:
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp; a. Bahasa yang digunakan dalam arbitrase adalah bahasa Indonesia
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp; b. Tempat arbitrase adalah di Jakarta, Indonesia
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp; c. Pihak Pertama dan Pihak Kedua akan secara bersama-sama
        mengangkat satu (1) orang arbiter yang akan menjadi arbiter tunggal untuk menyelesaikan
        sengketa Biaya arbitrase dan biaya hukum wajib ditanggung oleh pihak yang kalah Putusan
        arbitrase bersifat final dan mengikat Pihak Pertama dan Pihak Kedua.
        <br />
        <br />
        <span className="font-bold">Keterpisahan</span>
        <br />
        Dalam hal sebagian dari ketentuan ini tidak dapat dijalankan, maka ketentuan lain yang
        tersisa tidaklah batal dan akan terus berlaku dengan kekuatan penuh. SQECC memiliki hak
        untuk mengganti dan/atau merubah ketentuan yang batal tersebut dengan ketentuan lain
        sepanjang diperbolehkan oleh peraturan perundang-undangan yang berlaku. Kontak dan
        Pemberitahuan/Notifikasi Apabila ada pertanyaan terkait tentang Ketentuan penggunaan atau
        Anda ingin menambahkan, mengubah atau menghapus informasi Anda atau ingin melaporkan setiap
        masalah kepada kami, Anda dapat menghubungi kami di <b>customer.support@sqe.co.id</b>
      </Text>
    )
  }
];
