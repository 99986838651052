import React, { useRef } from 'react';
import { DetailData } from './total-conversation.inteface';
import { Rating, RatingColor } from '../../../../static/analysis';
import { Icon } from '@squantumengine/horizon';

interface BarChartProps {
  data: {
    name: string;
    startDate: string;
    endDate: string;
    Arm1: number;
    Arm2: number;
    Arm3: number;
    Arm4: number;
    Arm5: number;
    Arm6: number;
    Arm7: number | null;
  }[];
  hideConvType: { all: boolean; average: boolean };
  onClick: (tooltipPosition: DetailData) => void;
}

const BarChart: React.FC<BarChartProps> = ({ data, hideConvType, onClick }) => {
  const maxValueAvg = Math.max(...data.map((item) => item.Arm7 || 0));
  const maxValue = Math.max(
    ...data.map((item) => item.Arm1 + item.Arm2 + item.Arm3 + item.Arm4 + item.Arm5 + item.Arm6)
  );
  const maxValueAvgMinutes: any = maxValueAvg / 60;

  const gridLineCount = 5; // Number of horizontal lines
  const gridLines = Array.from({ length: gridLineCount }, (_, i) => i);

  const handleClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    date: { startDate: string; endDate: string }
  ) => {
    const tooltipPosition: DetailData = {
      tooltipPosition: {
        x: event.clientX,
        y: event.clientY
      },
      date: date
    };
    onClick(tooltipPosition);
  };

  const validateNumber = (value: number) => {
    if (isNaN(value)) {
      return 0;
    } else {
      return value.toFixed(1);
    }
  };

  const scrollRef = useRef<HTMLDivElement>(null);

  const scrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: -200, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: 200, behavior: 'smooth' });
    }
  };

  return (
    <div className="relative w-full p-4">
      <button
        className="absolute left-20 top-1/2 z-20 -translate-y-1/2 transform cursor-pointer rounded-full border-none bg-white/30 p-2 backdrop-blur-sm"
        onClick={scrollLeft}>
        <Icon name="chevron-large-left" />
      </button>
      <div className="absolute inset-1 z-0 mb-[17px] flex flex-col justify-between">
        {gridLines.map((_, index) => (
          <div key={index} className="flex w-full items-center justify-center gap-2">
            <p className="flex w-14 justify-end">
              {validateNumber(Math.floor(maxValue - (maxValue / gridLineCount) * index))}
            </p>
            <div
              key={index}
              className="w-full border-0 border-b border-solid border-gray-200"></div>
            <p className="flex w-14 justify-end text-nowrap text-blue-400">
              {validateNumber(maxValueAvgMinutes - (maxValueAvgMinutes / gridLineCount) * index)}
              mn
            </p>
          </div>
        ))}
      </div>
      <div className="mx-14">
        <div className="w-full overflow-scroll" ref={scrollRef}>
          <div className="relative flex h-64 items-end justify-around gap-8">
            {data.map((item, index) => {
              const { Arm1, Arm2, Arm3, Arm4, Arm5, Arm6, Arm7, startDate, endDate, name } = item;
              const totalConv = Arm1 + Arm2 + Arm3 + Arm4 + Arm5 + Arm6;
              const date = { startDate: startDate, endDate: endDate };
              return (
                <div key={index} className="z-10 flex h-64 flex-col items-center justify-end">
                  <div className="flex h-64 items-end gap-2">
                    {!hideConvType.all && (
                      <div
                        onClick={(e) => handleClick(e, date)}
                        className="relative w-12 cursor-pointer"
                        style={{
                          height: `${(totalConv / maxValue) * 100}%`
                        }}>
                        <div className="absolute flex h-full flex-col-reverse">
                          <div
                            style={{
                              height: `${Arm1 ? (Arm1 / totalConv) * 100 : 0}%`,
                              background: RatingColor[Rating.Unrated]
                            }}
                            className="w-12"></div>
                          <div
                            style={{
                              height: `${Arm2 ? (Arm2 / totalConv) * 100 : 0}%`,
                              background: RatingColor[Rating.BAD]
                            }}
                            className="w-12"></div>
                          <div
                            style={{
                              height: `${Arm3 ? (Arm3 / totalConv) * 100 : 0}%`,
                              background: RatingColor[Rating.BelowAverage]
                            }}
                            className="w-12"></div>
                          <div
                            style={{
                              height: `${Arm4 ? (Arm4 / totalConv) * 100 : 0}%`,
                              background: RatingColor[Rating.Average]
                            }}
                            className="w-12"></div>
                          <div
                            style={{
                              height: `${Arm5 ? (Arm5 / totalConv) * 100 : 0}%`,
                              background: RatingColor[Rating.Good]
                            }}
                            className="w-12"></div>
                          <div
                            style={{
                              height: `${Arm6 ? (Arm6 / totalConv) * 100 : 0}%`,
                              background: RatingColor[Rating.Excellent]
                            }}
                            className="w-12"></div>
                        </div>
                      </div>
                    )}

                    {!hideConvType.average && (
                      <div
                        onClick={(e) => handleClick(e, date)}
                        className="w-12 cursor-pointer bg-blue-200"
                        style={{
                          height: `${Arm7 ? (Arm7 / maxValueAvg) * 100 : 0}%`
                        }}></div>
                    )}
                  </div>

                  <span className="mt-2 text-nowrap">{name}</span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <button
        className="absolute right-20 top-1/2 z-20 -translate-y-1/2 transform cursor-pointer rounded-full border-none bg-white/30 p-2 backdrop-blur-sm"
        onClick={scrollRight}>
        <Icon name="chevron-large-right" />
      </button>
    </div>
  );
};

export default BarChart;
