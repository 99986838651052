import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

interface IconProps extends Partial<CustomIconComponentProps> {
  id?: string;
  color?: string;
}

const pencil = (color: string | undefined) => (
  <svg
    className="transition-all duration-100"
    width="16"
    height="16"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.3438 2.3125L14.6563 0.625C13.8438 -0.1875 12.5001 -0.1875 11.6876 0.625L1.75006 10.5L1.00006 15.1562C0.906305 15.6562 1.34381 16.0938 1.84381 16L6.50006 15.25L16.3751 5.3125C17.1876 4.5 17.1876 3.15625 16.3438 2.3125ZM4.75006 9.4375L10.0626 4.15625L12.8438 6.9375L7.56256 12.25V10.9375H6.06256V9.4375H4.75006ZM3.56256 14.3438L2.65631 13.4375L3.06256 11.1562L3.62506 10.5625H4.93756V12.0625H6.43756V13.375L5.84381 13.9375L3.56256 14.3438ZM15.4063 4.34375L13.8751 5.875L11.1251 3.125L12.6563 1.59375C12.9376 1.3125 13.4063 1.3125 13.6876 1.59375L15.3751 3.28125C15.6876 3.59375 15.6876 4.0625 15.4063 4.34375Z"
      fill={color || '#313131'}
    />
  </svg>
);

const PencilIcon = (props: IconProps) => <Icon component={() => pencil(props.color)} {...props} />;

export default PencilIcon;
