import { useMutation, useQueryClient } from 'react-query';
import axiosInstance from '../../../config/axios';

export interface InviteUserInterface {
  username: string;
  role: string;
  accountId?: string;
  phoneNo: string;
  fullName: string;
  loginOptions: string;
  notificationType: string;
  notificationReceivers: Array<string>;
}

export const inviteUserNATIVE: (payload: InviteUserInterface) => Promise<any[]> = async (
  payload
) => {
  const response = await axiosInstance.post(`user/invite`, payload);
  return response.data;
};

export const useInvUserNATIVE = () => {
  const queryClient = useQueryClient();

  return useMutation(inviteUserNATIVE, {
    onSuccess: () => {
      queryClient.invalidateQueries('get-inv-user');
    }
  });
};
