import { useEffect, useMemo, useState } from 'react';
import DateFilter from '../../components/filter/date-filter';
import StatsAverageResponse from './components/stats-average-response';
import StatsRating from './components/stats-rating';
import { Dropdown, Menu, MenuProps, Space } from 'antd';
import { MenuItems, TabMenuEnum, ConversationItems as items } from '../../static/analysis';
import { DownOutlined } from '@ant-design/icons';
import BusyHour from './components/busy-hour';
import AnalyticRejectReason from './components/reject-reason';
import { useTeamStore } from '../../store/team-state';
import { ConversationContextEnum } from '../../static/communication-type';
import TypeConversation from './components/type-conversation';
import TotalConversation from './components/total-conversation';
import ActivityKYCAnalytic from './components/activity-kyc';
import dayjs, { Dayjs } from 'dayjs';
import { DEFAULT_DATE_RANGE } from '../../components/filter/date-filter';
import UserActivity from './components/user-activity';
import RepetitiveConversation from './components/repetitive-conversation';
import { useIsFetching, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

function AnalyticSupervisor() {
  const [startDate, setStartDate] = useState(DEFAULT_DATE_RANGE[0]);
  const [endDate, setEndDate] = useState(DEFAULT_DATE_RANGE[1]);
  const [currentMenu, setCurrentMenu] = useState(TabMenuEnum.ACTIVITY);
  const [currentConversation, setCurrentConversation] = useState(0);
  const { selectedInbox } = useTeamStore();
  const queryClient = useQueryClient();

  const isKYCInbox =
    selectedInbox?.inboxConfig?.conversationContext === ConversationContextEnum.KYC;

  const onChangeDate = (dateValues: [Dayjs, Dayjs]) => {
    setStartDate(dateValues[0]);
    setEndDate(dateValues[1]);
  };

  const onClickTab: MenuProps['onClick'] = (e) => {
    setCurrentMenu(JSON.parse(e.key));
  };

  const onClick = (e: any) => {
    setCurrentConversation(JSON.parse(e.key));
  };

  const filterValues = useMemo(() => {
    if (!startDate || !endDate) return {};
    return {
      start: startDate.format('YYYY-MM-DDTHH:mm:ss.sssZ'),
      end: endDate.format('YYYY-MM-DDTHH:mm:ss.sssZ')
    };
  }, [startDate, endDate]);

  const { inboxId } = useParams();
  const analyticQueryKeys = ['averageResponse', 'repetitiveConversation'];
  const isFetchingAnalytics = useIsFetching({
    predicate: (query) => analyticQueryKeys.some((q) => q === query.queryKey?.[0])
  });

  const latestUpdate = useMemo(() => {
    const analyticsQueries = queryClient.getQueriesData({
      predicate: (query) => analyticQueryKeys.some((q) => q === query.queryKey?.[0])
    });
    const sortedByEndTime: any = analyticsQueries.sort((query1: any, query2: any) =>
      dayjs(query2?.[1]?.metadata?.end).isAfter(dayjs(query1?.[1]?.metadata?.end)) ? 1 : -1
    );
    const latestEndTime =
      sortedByEndTime.length > 0 ? sortedByEndTime?.[0]?.[1]?.metadata?.end : undefined;
    if (!latestEndTime) return '';
    return dayjs(latestEndTime).format('DD MMMM YYYY - HH:mm');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inboxId, filterValues, isFetchingAnalytics]);

  useEffect(() => {
    if (isKYCInbox) {
      setCurrentMenu(0);
    }
  }, [isKYCInbox]);

  return (
    <div className="space-y-4 p-4">
      <h2>Analisis {selectedInbox?.name}</h2>
      <div className="flex w-full">
        <Menu
          className="w-full border-none bg-transparent focus:outline-none"
          onClick={onClickTab}
          selectedKeys={[`${currentMenu}`]}
          mode="horizontal"
          items={MenuItems.filter((items) => items.isKYCInbox === isKYCInbox || !isKYCInbox)}
        />
        <DateFilter startDate={startDate} endDate={endDate} onChangeDate={onChangeDate} />
        <Dropdown
          className="mx-2 h-10 w-full max-w-[190px] cursor-pointer rounded-full border-solid border-grey-80 px-4"
          menu={{ items, onClick }}>
          <Space className="flex w-full justify-between">
            <div className="w-full overflow-hidden truncate whitespace-nowrap text-base">
              {items?.[currentConversation]?.label}
            </div>
            <DownOutlined />
          </Space>
        </Dropdown>
      </div>
      <div className="font-medium">Data terakhir diperbarui: {latestUpdate}</div>
      {currentMenu === TabMenuEnum.ACTIVITY ? (
        <>
          {isKYCInbox && <ActivityKYCAnalytic filterValues={{ startDate, endDate }} />}
          <div className="space-y-4">
            <div className="flex space-x-4">
              <div className="flex-1">
                <div className="h-full rounded-xl border border-solid border-grey-80 bg-white">
                  <StatsAverageResponse filterValues={filterValues} />
                </div>
              </div>
              <div className="flex-1">
                <div className="rounded-xl border border-solid border-grey-80 bg-white">
                  {isKYCInbox ? (
                    <AnalyticRejectReason filterValues={filterValues} />
                  ) : (
                    <StatsRating filterValues={filterValues} />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="space-y-4">
            <UserActivity filterValues={filterValues} />
            {!isKYCInbox && (
              <div className="rounded-xl border border-solid border-grey-80 bg-white p-6">
                <TypeConversation filterValues={filterValues} />
              </div>
            )}
            <div className="rounded-xl border border-solid border-grey-80 bg-white p-6">
              <BusyHour filterValues={filterValues} />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="rounded-xl border border-solid border-grey-80 bg-white p-6">
            <RepetitiveConversation filterValues={filterValues} />
          </div>
          <div className="rounded-xl border border-solid border-grey-80 bg-white p-6">
            <TotalConversation filterValues={filterValues} />
          </div>
        </>
      )}
    </div>
  );
}

export default AnalyticSupervisor;
