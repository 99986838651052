import React from 'react';
import { TableProps, Tag, Typography } from 'antd';

import { Token } from '@squantumengine/horizon';
import { UserResponse } from '../components/card/user/user.interface';
import { RoleEnum } from './role';
import { UseMutateAsyncFunction } from 'react-query';
import { requestResetPasswordNATIVE } from '../api/native-login';
import { MessageInstance } from 'antd/es/message/interface';

const { COLORS } = Token;

export const InvTeamStatusEnum = {
  INVITED: 'INVITED',
  ACTIVE: 'ACTIVE',
  NON_ACTIVE: 'NONACTIVE',
  EXPIRED: 'EXPIRED',
  BLOCKED: 'BLOCKED',
  NULL: 'null'
};

export const _renderCell = (val: string) => <div className="text-gray-500">{val}</div>;

export const _renderCapacity = (val: any) => (
  <div className="text-gray-500">{val?.maxChatCapacity || '-'}</div>
);

function extractAndDisplayInboxNamesAsString(data: Array<any>) {
  let inboxNames: Array<string> = [];
  data.forEach((item) => {
    if (data.length > 0) {
      inboxNames.push(item.inboxName);
    }
  });

  return inboxNames.join(', ');
}

export const _renderTeam = (val: Array<any>) => (
  <>
    {val?.length > 0 ? (
      <p className="text-black">{extractAndDisplayInboxNamesAsString(val)}</p>
    ) : (
      <p className="text-gray-400">Belum ditugaskan</p>
    )}
  </>
);

export const _renderAgentName = (val: string) => {
  const nameMap: any = {
    [RoleEnum.AGENT]: 'Agen',
    [RoleEnum.SUPERVISOR]: 'Supervisor',
    [RoleEnum.ADMIN]: 'Admin'
  };

  return <div className="text-gray-500">{nameMap[val]}</div>;
};

export const _renderBUName = (val: any) => (
  <div className="text-gray-500">{val?.accountName || '-'}</div>
);

export const nameMap = {
  [InvTeamStatusEnum.INVITED]: 'Undangan Terkirim',
  [InvTeamStatusEnum.ACTIVE]: 'Aktif',
  [InvTeamStatusEnum.EXPIRED]: 'Undangan Kadaluarsa',
  [InvTeamStatusEnum.BLOCKED]: 'Terblokir Sementara',
  [InvTeamStatusEnum.NULL]: 'Tidak Terdefinisi'
};

export const _renderStatus = (val: string) => {
  const tagProps = { className: 'rounded-full', bordered: false };
  const textProps = { className: 'm-0 p-0 text-sm font-semibold' };
  const tagColorMap = {
    [InvTeamStatusEnum.INVITED]: { color: COLORS.yellow[100], textClass: 'text-yellow-700' },
    [InvTeamStatusEnum.ACTIVE]: { color: COLORS.green[100], textClass: 'text-green-700' },
    [InvTeamStatusEnum.NON_ACTIVE]: { color: COLORS.neutral[100], textClass: 'text-gray-700' },
    [InvTeamStatusEnum.EXPIRED]: { color: COLORS.red[100], textClass: 'text-red-700' },
    [InvTeamStatusEnum.BLOCKED]: { color: COLORS.red[100], textClass: 'text-red-700' },
    [InvTeamStatusEnum.NULL]: { color: COLORS.neutral[100], textClass: 'text-neutral-700' }
  };
  const { color, textClass } = tagColorMap?.[val] ?? {
    color: COLORS.neutral[100],
    textClass: 'text-neutral-700'
  };

  const nameMap = {
    [InvTeamStatusEnum.INVITED]: 'Undangan Terkirim',
    [InvTeamStatusEnum.ACTIVE]: 'Aktif',
    [InvTeamStatusEnum.NON_ACTIVE]: 'Non Aktif',
    [InvTeamStatusEnum.EXPIRED]: 'Undangan Kadaluarsa',
    [InvTeamStatusEnum.BLOCKED]: 'Terblokir Sementara',
    [InvTeamStatusEnum.NULL]: 'Tidak Terdefinisi'
  };

  return (
    <Tag {...tagProps} color={`${color}`}>
      <Typography.Text {...textProps} className={textClass}>
        {nameMap[val]}
      </Typography.Text>
    </Tag>
  );
};

export const invUserNATIVEColumns = (
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setSelectedData: React.Dispatch<React.SetStateAction<UserResponse>>,
  isAdmin: boolean,
  unblockUser?: UseMutateAsyncFunction<any[], unknown, string, unknown>,
  notification?: MessageInstance
) => {
  const invUserTeamColumns: TableProps<UserResponse>['columns'] = [
    {
      title: 'Nama User',
      dataIndex: 'firstName',
      key: 'firstName',
      render: _renderCell
    },
    {
      title: 'Tim',
      dataIndex: 'inboxes',
      key: 'inboxes',
      render: _renderTeam
    },
    ...(isAdmin
      ? [
          {
            title: 'Nama BU',
            dataIndex: 'account',
            key: 'account',
            render: _renderBUName
          }
        ]
      : []),
    {
      title: 'Jabatan',
      dataIndex: 'role',
      key: 'role',
      render: _renderAgentName
    },
    {
      title: 'Email/Username',
      dataIndex: 'email',
      key: 'email',
      render: _renderCell
    },
    {
      title: 'Maksimal Chat',
      key: 'max_chat',
      dataIndex: 'capacity',
      render: _renderCapacity
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: _renderStatus
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: 'status',
      render: (_, record) => {
        const { role, capacity, employeeId, status } = record;
        const hasCapacity = Object.keys(capacity).length > 0;
        const hasEmployeeId = !!employeeId;

        const isAgent = role === RoleEnum.AGENT;
        const isSupervisor = role === RoleEnum.SUPERVISOR;

        let statusLabel = '';

        if (isAgent) {
          statusLabel = hasCapacity ? 'Edit' : 'Lengkapi';
        } else if (isSupervisor) {
          statusLabel = hasEmployeeId ? 'Edit' : 'Lengkapi';
        }

        if (status === InvTeamStatusEnum.BLOCKED) {
          statusLabel = 'Unblock';
        } else if (status === InvTeamStatusEnum.EXPIRED) {
          statusLabel = 'Kirim Ulang';
        }

        return <div className="cursor-pointer font-semibold text-green-300">{statusLabel}</div>;
      },

      onCell: (data) => {
        return {
          onClick: async () => {
            const status = data?.status;
            if (status === InvTeamStatusEnum.BLOCKED) {
              try {
                await unblockUser!(data?.email);
              } catch (error) {
                // eslint-disable-next-line no-console
                console.log(error);
              }
            } else if (status === InvTeamStatusEnum.EXPIRED) {
              try {
                await requestResetPasswordNATIVE(data?.email, 'email');
                notification?.success({ content: 'Resend link sudah dikirim ulang' });
              } catch (error) {
                // eslint-disable-next-line no-console
                console.log(error);
              }
            } else {
              setSelectedData(data);
              setIsModalOpen(true);
            }
          }
        };
      }
    }
  ];

  return invUserTeamColumns;
};

export const invUserSQEIDColumns = (
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setSelectedData: React.Dispatch<React.SetStateAction<UserResponse>>,
  isAdmin: boolean
) => {
  const invUserTeamColumns: TableProps<UserResponse>['columns'] = [
    {
      title: 'Nama User',
      dataIndex: 'firstName',
      key: 'firstName',
      render: _renderCell
    },
    {
      title: 'Tim',
      dataIndex: 'inboxes',
      key: 'inboxes',
      render: _renderTeam
    },
    ...(isAdmin
      ? [
          {
            title: 'Nama BU',
            dataIndex: 'account',
            key: 'account',
            render: _renderBUName
          }
        ]
      : []),
    {
      title: 'Jabatan',
      dataIndex: 'role',
      key: 'role',
      render: _renderAgentName
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: _renderCell
    },
    {
      title: 'Maksimal Chat',
      key: 'max_chat',
      dataIndex: 'capacity',
      render: _renderCapacity
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: _renderStatus
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: 'action',
      render: () => <div className="cursor-pointer font-semibold text-green-300">Edit</div>,
      onCell: (data) => {
        return {
          onClick: () => {
            setSelectedData(data);
            setIsModalOpen(true);
          }
        };
      }
    }
  ];

  return invUserTeamColumns;
};
