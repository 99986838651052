export enum WhatsappCommunicationTypeEnum {
  GREETING_MESSAGE = 'GREETING_MESSAGE',
  LONG_QUEUE = 'LONG_QUEUE',
  OUTSIDE_WORKING_HOUR = 'OUTSIDE_WORKING_HOUR',
  CONNECTED_TO_AGENT = 'CONNECTED_TO_AGENT',
  WAITING_ROOM = 'WAITING_ROOM',
  CLOSING_REMARKS = 'CLOSING_REMARKS'
}

export enum WhatsAppSupportModeEnum {
  AGENT = 'AGENT',
  BOT = 'BOT',
  BOTH = 'BOTH'
}

export interface TemplateMessage {
  id?: string;
  name: string;
  campaign?: WhatsAppMessage;
}
export interface WhatsappAttribute {
  campaignId: string;
  dataAttributes: {
    [key: string]: string;
  };
  campaignType: WhatsappCommunicationTypeEnum;
}

export interface Precoding {
  displayName: string;
  displayNameKey: string;
}

interface Content {
  title: string;
  body: string;
}

export interface WhatsAppMessage {
  id: string;
  name: string;
  description: string;
  precodings: string[];
  createdAt: string;
  status: string;
  channel: string;
  content: Content;
  dataAttributes?: any;
}
