import { CloseOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { UserCardInterface } from './user.interface';
import { useTeamStore } from '../../../store/team-state';
import { RoleEnum } from '../../../static/role';
import { TeamActiveHandlerInterface } from '../../../shared/types/team.interface';

export default function UserCard(props: UserCardInterface) {
  const {
    id,
    agentId,
    inboxId,
    type,
    addPadding: additionalPadding,
    firstName,
    lastName,
    roleLabel,
    onUserAction
  } = props;

  const { teamActiveHandler, setSelectedUser, setTeamActiveHandler } = useTeamStore();

  const openUserInfo = () => {
    const newTeamActiveHandler: TeamActiveHandlerInterface = {
      ...useTeamStore.getState().teamActiveHandler,
      activeInboxId: inboxId,
      activeUserId: agentId,
      isEdit: true
    };

    setTeamActiveHandler(newTeamActiveHandler);
    setSelectedUser({
      agentId: agentId,
      firstName: firstName,
      lastName: lastName
    });
  };

  return (
    <Col
      onClick={openUserInfo}
      key={`user-${id}`}
      span={6}
      className={`${additionalPadding && 'pt-4'} mt-1`}>
      <div
        className={`cursor-pointer rounded-md
                px-4 py-2 ${
                  teamActiveHandler.activeUserId === agentId &&
                  teamActiveHandler.activeInboxId === inboxId &&
                  teamActiveHandler.isEdit
                    ? 'click-animation border-4 border-solid border-green-emerald-light px-[13px] py-[5px] text-base font-semibold text-black-90 hover:bg-grey-20 active:bg-grey-30'
                    : 'border border-solid border-grey-80'
                } hover:bg-grey-20 active:bg-grey-30`}>
        <Row>
          <Col span={20} className="truncate text-base font-semibold text-black-90">
            {firstName} {lastName ? lastName : ''}
          </Col>
          {type === RoleEnum.AGENT && (
            <Col span={4} className="text-right">
              <CloseOutlined
                className="transition-[transform] duration-100 hover:scale-110 active:scale-90"
                onClick={(e) => {
                  onUserAction && onUserAction(e, agentId);
                  e.stopPropagation();
                }}
              />
            </Col>
          )}
        </Row>
        <div className="text-base text-black-90">{roleLabel}</div>
      </div>
    </Col>
  );
}
