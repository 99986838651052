import TabFilter from '../../../../components/filter/tab-filter';
import {
  RepetitiveConversationTabEnum,
  RepetitiveConversationTablist,
  customerDetailColumns,
  customerPerfomanceListColumns,
  topicDetailColumns,
  topicPerfomanceListColumns
} from '../../../../static/analysis';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  getCustomerActivityAnalytics,
  getTopicSummaryByCustomer,
  getTopicSummaryByInbox
} from '../../../../api/analytics';
import { useQuery } from 'react-query';
import Table from '../../../../components/commons/Table';
import { ArrowLeftOutlined } from '@ant-design/icons';

const DEFAULT_PARAMS = { page: 1 };

type Props = { filterValues: Record<string, any> };

export default function RepetitiveConversation(props: Props) {
  const [activeTab, setActiveTab] = useState<string>(RepetitiveConversationTabEnum.TOPIC);
  const [conversationParams, setConversationParams] = useState(DEFAULT_PARAMS);
  const [customerParams, setCustomerParams] = useState(DEFAULT_PARAMS);
  const [topicParams, setTopicParams] = useState(DEFAULT_PARAMS);
  const [detailCustomer, setDetailCustomer] = useState<any>(null);
  const [detailTopic, setDetailTopic] = useState<any>(null);
  const { inboxId } = useParams();

  const repetitiveConversationFilter = useMemo(() => {
    const mergedFilter = { ...props.filterValues, ...conversationParams };
    const filteredEntries = Object.entries(mergedFilter).filter(([_, value]) => !!value);
    return Object.fromEntries(filteredEntries);
  }, [props.filterValues, conversationParams]);

  const topicFilter = useMemo(() => {
    const mergedFilter = {
      ...props.filterValues,
      ...topicParams,
      topic: detailTopic?.data?.topic || 'nil_topic_placeholder'
    };
    const filteredEntries = Object.entries(mergedFilter).filter(([_, value]) => !!value);
    return Object.fromEntries(filteredEntries);
  }, [props.filterValues, topicParams, detailTopic]);
  const customerFilter = useMemo(() => {
    const mergedFilter = {
      ...props.filterValues,
      ...customerParams,
      customerId: detailCustomer?.data?.customerId,
      topic: detailCustomer?.data?.topic || 'nil_topic_placeholder'
    };
    const filteredEntries = Object.entries(mergedFilter).filter(([_, value]) => !!value);
    return Object.fromEntries(filteredEntries);
  }, [props.filterValues, customerParams, detailCustomer]);

  const { data, isLoading: isLoadingConversationData } = useQuery(
    ['repetitiveConversation', inboxId, activeTab, repetitiveConversationFilter],
    () => {
      const asyncFn =
        activeTab === RepetitiveConversationTabEnum.TOPIC
          ? getTopicSummaryByInbox
          : getTopicSummaryByCustomer;
      return asyncFn(String(inboxId), repetitiveConversationFilter);
    },
    { enabled: !!inboxId }
  );

  const { data: topicData, isLoading: isLoadingTopicData } = useQuery(
    ['topicConversations', inboxId, topicFilter],
    () => getCustomerActivityAnalytics(String(inboxId), topicFilter),
    { enabled: !!inboxId && !!detailTopic }
  );

  const { data: customerData, isLoading: isLoadingCustomerData } = useQuery(
    ['customerConversations', inboxId, customerFilter],
    () => getCustomerActivityAnalytics(String(inboxId), customerFilter),
    { enabled: !!inboxId && !!detailCustomer }
  );

  // eslint-disable-next-line no-console
  const perfomanceTab =
    activeTab === RepetitiveConversationTabEnum.TOPIC
      ? topicPerfomanceListColumns.map((col) => {
          if (col.key === 'total') {
            return {
              ...col,
              render: (total: number) => (
                <div className="text-gray-500">
                  <span className="text-black">{total}</span>/<span>{data?.totalConversation}</span>{' '}
                  (
                  <span className="text-black">
                    {((total / data?.totalConversation) * 100).toFixed(2)}%
                  </span>
                  )
                </div>
              )
            };
          }
          return col;
        })
      : customerPerfomanceListColumns;
  const dataSource = data?.data || [];

  return (
    <div>
      <div className={`${(!!detailCustomer || !!detailTopic) && 'hidden'}`}>
        <h2 className="mt-0">Percakapan Berulang</h2>
        <div className={`flex justify-between`}>
          <div className="flex items-center gap-4">
            <p>Lihat Berdasarkan</p>
            <TabFilter
              Tablist={RepetitiveConversationTablist}
              setSelectedTab={(tab) => {
                setActiveTab(tab);
                setConversationParams(DEFAULT_PARAMS);
              }}
              selectedTab={activeTab}
            />
          </div>
        </div>
        <Table
          rowKey={(record) => record.id}
          key={activeTab}
          columns={perfomanceTab}
          dataSource={dataSource}
          loading={isLoadingConversationData || isLoadingCustomerData}
          rowClassName={
            activeTab === RepetitiveConversationTabEnum.CUSTOMER ? 'cursor-pointer' : ''
          }
          onRow={(record) => ({
            onClick:
              activeTab === RepetitiveConversationTabEnum.CUSTOMER
                ? () => setDetailCustomer({ data: record, type: activeTab })
                : () => setDetailTopic({ data: record, type: activeTab })
          })}
          onChange={(pagination, _, sorter) => {
            if (pagination.current !== conversationParams.page) {
              setConversationParams((prev) => ({ ...prev, page: Number(pagination.current) }));
            } else if (!Array.isArray(sorter) && sorter?.field) {
              setConversationParams((prev) => ({
                ...prev,
                page: 1,
                sortBy: sorter.order ? String(sorter.field) : '',
                sortDir: { ascend: 'asc', descend: 'desc' }[String(sorter.order)] || ''
              }));
            }
          }}
          pagination={{
            total: data?.metadata?.totalData || 0,
            current: conversationParams.page,
            showSizeChanger: false,
            position: ['bottomCenter']
          }}
        />
      </div>
      <div className={`${!!detailCustomer ? 'block space-y-4' : 'hidden'}`}>
        <div className="flex items-center space-x-2">
          <ArrowLeftOutlined
            className="text-xl"
            onClick={() => {
              setDetailCustomer(null);
              setCustomerParams(DEFAULT_PARAMS);
            }}
          />{' '}
          <h2 className="my-0 text-xl font-semibold">
            {detailCustomer?.data?.customerName} - ID {detailCustomer?.data?.customerId?.slice(-5)}{' '}
            {detailCustomer?.data?.topic ? `(${detailCustomer?.data?.topic})` : ''}
          </h2>
        </div>
        <Table
          rowKey={(record) => record.id}
          columns={customerDetailColumns}
          dataSource={customerData?.data}
          onChange={(pagination, _, sorter) => {
            if (pagination.current !== customerParams.page) {
              setCustomerParams((prev) => ({ ...prev, page: Number(pagination.current) }));
            } else if (!Array.isArray(sorter) && sorter?.field) {
              setCustomerParams((prev) => ({
                ...prev,
                page: 1,
                sortBy: sorter.order ? String(sorter.field) : '',
                sortDir: { ascend: 'asc', descend: 'desc' }[String(sorter.order)] || ''
              }));
            }
          }}
          pagination={{
            total: customerData?.metadata?.totalData || 0,
            current: customerParams.page,
            showSizeChanger: false,
            position: ['bottomCenter']
          }}
        />
      </div>
      <div className={`${!!detailTopic ? 'block space-y-4' : 'hidden'}`}>
        <div className="flex items-center space-x-2">
          <ArrowLeftOutlined
            className="text-xl"
            onClick={() => {
              setDetailTopic(null);
              setTopicParams(DEFAULT_PARAMS);
            }}
          />{' '}
          <h2 className="my-0 text-xl font-semibold">{detailTopic?.data?.topic}</h2>
        </div>
        <Table
          columns={topicDetailColumns}
          loading={isLoadingTopicData}
          dataSource={topicData?.data}
          onChange={(pagination, _, sorter) => {
            if (pagination.current !== topicParams.page) {
              setTopicParams((prev) => ({ ...prev, page: Number(pagination.current) }));
            } else if (!Array.isArray(sorter) && sorter?.field) {
              setTopicParams((prev) => ({
                ...prev,
                page: 1,
                sortBy: sorter.order ? String(sorter.field) : '',
                sortDir: { ascend: 'asc', descend: 'desc' }[String(sorter.order)] || ''
              }));
            }
          }}
          pagination={{
            total: topicData?.metadata?.totalData || 0,
            current: topicParams.page,
            showSizeChanger: false,
            position: ['bottomCenter']
          }}
        />
      </div>
    </div>
  );
}
