import { getBgColor } from './busy-hour.interface';

const BusyHourBarChart = ({
  item,
  total,
  onClick
}: {
  item: any;
  total: number;
  onClick: () => void;
}) => {
  let bgColor = getBgColor(item, total);
  const barWidth = 'w-36';

  return (
    <div
      data-testid="bar-hour"
      onClick={onClick}
      className={`h-4 ${barWidth} ${bgColor} cursor-pointer`}
    />
  );
};

export default BusyHourBarChart;
