import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { DocumentInterface } from '../../components/form/workspace-customer-files/document/workspace-customer-files-document';

//TODO: Temporary solution to pass cors issue till deploy our cors proxy server
export const getBlobFromUrl = (url: string): Promise<Blob> =>
  fetch(url).then((response) => response.blob());

export const downloadFileFromUrl = (url: string, fileName?: string) =>
  getBlobFromUrl(url).then((blob) => saveAs(blob, fileName));

export const downloadDocs = (files: DocumentInterface[], contactName?: string) => {
  const zip = new JSZip();
  const promises: Promise<void>[] = [];

  if (files.length > 1) {
    files.forEach((file) => {
      const promise = getBlobFromUrl(file.documentUrl).then((blob) => {
        zip.file(file.documentName, blob);
      });
      promises.push(promise);
    });

    Promise.all(promises).then(() => {
      zip.generateAsync({ type: 'blob' }).then((content) => {
        saveAs(content, `${contactName}.zip`);
      });
    });
  } else {
    getBlobFromUrl(files[0].documentUrl).then((blob) => {
      saveAs(blob, files[0].documentName);
    });
  }
};
