import { createContext, useContext, useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from './useLocalStorage';

import { RoleEnum } from '../static/role';
import { useUserStatusStore } from '../store/user-state';
import { useChatListStore } from '../store/chat-list';
import { useCallStore } from '../store/call-state';
import { useInboxDetailStore } from '../store/inbox-state';
import { CloseWebSocket } from '../utils/webSocket';
import { logoutSIMASID } from '../api';
import { useConversationListStore } from '../store/conversation-list-state';
import { SSOAgentProfile } from '../shared/types/auth.interface';
import { LocalStorageKey } from '../static/local-storage';
import { loginSIMASID } from '../api/user';
import { AuthSIMASIDContextProps } from '../shared/types/user.interface';
import { generateAvatarImage } from '../utils/fullNameParse';
import { local } from '../utils/storage';
import { clearAuthLocalStorage } from '../utils/local-storage';
import { useAuthStore } from '../store/new/auth';

export const AuthSIMASIDContext = createContext<AuthSIMASIDContextProps>({
  user: {} as SSOAgentProfile,
  accessToken: '',
  loginSSO: () => {},
  logout: () => {}
});

export const AuthSIMASIDProvider = ({ children }: { children: any }) => {
  const [user, setUser] = useLocalStorage<SSOAgentProfile>(
    LocalStorageKey.USER,
    {} as SSOAgentProfile
  );
  const [accessToken, setAccessToken] = useLocalStorage(LocalStorageKey.ACCESS_TOKEN, '');
  const navigate = useNavigate();
  const { setUserRole, setName, setImageUrl } = useUserStatusStore();
  const { clearChatListStore, setOfflineChatList, setSelectedCall, setSelectedOfflineChat } =
    useChatListStore();
  const { setMeetingRoomId, resetAttentionChat } = useCallStore();
  const { setInboxList, setInboxId } = useInboxDetailStore();
  const { clearConversationList } = useConversationListStore();
  const { setAccount } = useAuthStore();

  // call this function when you want to authenticate the user
  const loginSSO = async (username: string) => {
    const responseLogin = await loginSIMASID(
      localStorage.getItem(LocalStorageKey.SQEID_AUTH_CODE)!,
      localStorage.getItem(LocalStorageKey.SQEID_OA_CODE_VERIFIER)!,
      username
    );
    if (responseLogin.error) {
      navigate('/no-access');
    } else {
      const today = new Date();
      if (responseLogin.data) {
        const { agentProfile, tokenInfo } = responseLogin.data;

        localStorage.setItem(LocalStorageKey.TOKEN_CREATED_AT, today.getTime().toString());
        localStorage.setItem(LocalStorageKey.SQEID_TOKEN_OBJECT, JSON.stringify(tokenInfo));

        setUser(agentProfile);
        setAccessToken(tokenInfo.access_token);
        setAccount(agentProfile.account);
        navigate(`${agentProfile.role === RoleEnum.ADMIN ? '/dashboard/team' : '/dashboard'}`);
      }
    }
  };

  // call this function to sign out logged in user
  const logout = async () => {
    try {
      await logoutSIMASID();
    } catch {
      return;
    }
    clearChatListStore();
    clearConversationList();
    setUser({} as SSOAgentProfile);
    setAccessToken('');
    resetAttentionChat();
    setInboxList([]);
    setOfflineChatList([]);
    setSelectedCall(null);
    setSelectedOfflineChat(null);
    setMeetingRoomId('');
    setInboxId('');

    useCallStore.getState().setShowVideo(false);
    clearAuthLocalStorage();
    localStorage.removeItem('meetingRoomId');
    localStorage.removeItem('isSwicthed');
    localStorage.removeItem('selectedCall');
    CloseWebSocket();

    const clientCode = local.getItem(LocalStorageKey.SQECC_CLIENT_CODE);
    navigate(`/login?client=${clientCode}`, { replace: true });
  };

  const value = useMemo(
    () => ({
      user,
      accessToken,
      loginSSO,
      logout
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user]
  );

  useEffect(() => {
    if (!user.role) {
      return;
    }

    setUserRole(user.role as RoleEnum);
  }, [user.role, setUserRole]);

  useEffect(() => {
    if (user.firstName || user.lastName) {
      setName(`${user.firstName} ${user.lastName ? user.lastName : ''}`);
      setImageUrl(generateAvatarImage(`${user.firstName} ${user.lastName ? user.lastName : ''}`));
    }
  }, [setName, setImageUrl, user.firstName, user.lastName]);

  return <AuthSIMASIDContext.Provider value={value}>{children}</AuthSIMASIDContext.Provider>;
};

export const useAuthSIMASID = () => {
  return useContext(AuthSIMASIDContext);
};
