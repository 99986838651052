import { UnreadMessageData } from '../../types/new/account';
import request from '../../utils/request';

export const getAccountInfo = async (accountId: string) => {
  const response = await request.get(`/account/${accountId}`);
  return response.data;
};

export const fetchAccountUnreadMessage: (
  accountId: string
) => Promise<UnreadMessageData[]> = async (accountId) => {
  const response = await request.get(
    `account/${accountId}/conversation/un-read?page-no=1&size=100`
  );
  return response.data.data;
};

export const uploadVirtualBackground = async (accountId: string, file: File) => {
  const formData = new FormData();
  formData.append('files', file);
  const response = await request.post(`/account/${accountId}/media/virtualBackground`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
  return response.data;
};

export const getVirtualBackground = async (accountId: string) => {
  const response = await request.get(`account/${accountId}/media/virtualBackground`);
  return response?.data;
};

export const getConfigMessages = async (accountId: string) => {
  const response = await request.get(`account/${accountId}/config/messages`);
  return response?.data;
};
