import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const VoiceCallSVG = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.7292 1.16358L10.2813 0.6167C9.70833 0.486492 9.13542 0.77295 8.92708 1.29378L7.78125 3.95003C7.57292 4.41878 7.70313 4.9917 8.11979 5.33024L9.16146 6.18962C8.43229 7.54378 7.28646 8.66358 5.93229 9.41878L5.07292 8.37712C4.73438 7.96045 4.16146 7.83024 3.69271 8.03858L1.03646 9.15837C0.515626 9.39274 0.229168 9.96566 0.359376 10.5386L0.906251 12.9865C1.03646 13.5334 1.53125 13.898 2.07813 13.898C8.45833 13.898 13.6667 8.7417 13.6667 2.33545C13.6667 1.78858 13.276 1.29378 12.7292 1.16358ZM2.13021 12.648L1.58333 10.3042L4.13542 9.21045L5.59375 10.9813C8.17188 9.75733 9.5 8.4292 10.724 5.85108L8.95313 4.39274L10.0469 1.84066L12.4167 2.38753C12.3906 8.03858 7.78125 12.6219 2.13021 12.648Z"
      fill="currentColor"
    />
  </svg>
);

const VoiceCallIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={VoiceCallSVG} {...props} />
);

export default VoiceCallIcon;
