import { useState } from 'react';
import { Row } from 'antd';
import ErrorForm from '../../components/form/error-form/error-form';
import { Typography } from '@squantumengine/horizon';
import { Navigate, useLocation } from 'react-router-dom';
import CardHeading from '../../components/login/card-heading';
import ButtonEmailResetPassword from '../../components/login/button-email-reset-password';
import { NativeLoginWordingEnum } from '../../static/native-login';

function EmailResetPassword() {
  const [err, setErr] = useState<boolean>(false);
  const [errMsg, setErrorMsg] = useState<string | JSX.Element>('');
  const [emailSent, setEmailSent] = useState<boolean>(false);
  const location = useLocation();
  const { userEmail, isPasswordExpired } = location?.state || {};
  const shouldDisableButton = !userEmail;

  const showError = (msg: string) => {
    setErrorMsg(msg);
    setErr(true);
  };

  if (emailSent) return <Navigate to="/reset-link-sent" />;

  return (
    <Row
      className="login-form flex min-h-screen flex-col bg-alice-blue"
      justify="center"
      align="middle">
      <div className="w-96 rounded-lg bg-white p-[24px]">
        <CardHeading>
          {isPasswordExpired && (
            <CardHeading.Heading
              title={NativeLoginWordingEnum.updatePassword}
              description={
                <span>
                  {NativeLoginWordingEnum.updatePassword1}
                  <br />
                  {NativeLoginWordingEnum.updatePassword2}
                </span>
              }
            />
          )}

          {!isPasswordExpired && (
            <CardHeading.Heading
              title={NativeLoginWordingEnum.forgotPassword}
              description={
                <>
                  {NativeLoginWordingEnum.forgotPasswordDescription1} <br />
                  {NativeLoginWordingEnum.forgotPasswordDescription2}
                </>
              }
            />
          )}
        </CardHeading>

        <Typography.Paragraph size="l" weight="bold" className="mb-[24px] text-center">
          {userEmail}
        </Typography.Paragraph>

        {err && <ErrorForm isVisible={true} message={errMsg} className="my-[24px]" />}

        <ButtonEmailResetPassword
          value={userEmail}
          valueKey="email"
          onEmailSent={() => setEmailSent(true)}
          onError={showError}
          disabled={shouldDisableButton}
        />
      </div>
    </Row>
  );
}

export default EmailResetPassword;
