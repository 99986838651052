type Props = {
  color?: string;
} & JSX.IntrinsicElements['svg'];

const EngineWarning = (props: Props) => {
  const { color, ...rest } = props;
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}>
      <path
        d="M8.00016 3.33301C5.41683 3.33301 3.3335 5.43717 3.3335 7.99967C3.3335 10.583 5.41683 12.6663 8.00016 12.6663C10.5627 12.6663 12.6668 10.583 12.6668 7.99967C12.6668 5.43717 10.5627 3.33301 8.00016 3.33301ZM8.00016 11.6663C5.9585 11.6663 4.3335 10.0413 4.3335 7.99967C4.3335 5.97884 5.9585 4.33301 8.00016 4.33301C10.021 4.33301 11.6668 5.97884 11.6668 7.99967C11.6668 10.0413 10.021 11.6663 8.00016 11.6663ZM8.00016 9.33301C7.62516 9.33301 7.3335 9.64551 7.3335 9.99967C7.3335 10.3747 7.62516 10.6663 8.00016 10.6663C8.35433 10.6663 8.66683 10.3747 8.66683 9.99967C8.66683 9.64551 8.35433 9.33301 8.00016 9.33301ZM8.4585 5.33301H7.521C7.3335 5.33301 7.16683 5.52051 7.18766 5.70801L7.4585 8.37467C7.47933 8.54134 7.62516 8.66634 7.79183 8.66634H8.18766C8.35433 8.66634 8.50016 8.54134 8.521 8.37467L8.79183 5.70801C8.81266 5.52051 8.646 5.33301 8.4585 5.33301ZM2.3335 7.99967C2.3335 6.77051 2.72933 5.56217 3.47933 4.58301C3.5835 4.43717 3.56266 4.24967 3.43766 4.12467L3.18766 3.91634C3.04183 3.79134 2.81266 3.81217 2.68766 3.95801C1.81266 5.12467 1.3335 6.54134 1.3335 7.99967C1.3335 9.47884 1.81266 10.8955 2.68766 12.0622C2.81266 12.208 3.04183 12.2288 3.18766 12.1038L3.43766 11.8955C3.56266 11.7705 3.5835 11.583 3.47933 11.4372C2.72933 10.458 2.3335 9.24967 2.3335 7.99967ZM13.2502 3.91634C13.146 3.77051 12.9168 3.74967 12.771 3.87467L12.521 4.10384C12.3752 4.20801 12.3752 4.41634 12.4793 4.56217C13.2502 5.54134 13.6668 6.74967 13.6668 7.99967C13.6668 9.27051 13.2502 10.4788 12.4793 11.458C12.3752 11.6038 12.3752 11.8122 12.521 11.9163L12.771 12.1455C12.9168 12.2705 13.146 12.2497 13.2502 12.1038C14.1668 10.9372 14.6668 9.49967 14.6668 7.99967C14.6668 6.52051 14.1668 5.08301 13.2502 3.91634Z"
        fill={color || '#BAC3D1'}
      />
    </svg>
  );
};

export default EngineWarning;
