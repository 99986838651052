import { useMutation, useQueryClient } from 'react-query';
import axiosInstance from '../../../config/axios';

export const unblockUserNATIVE: (email: string) => Promise<any[]> = async (email) => {
  try {
    const response = await axiosInstance.post(`user/mark/active`, { email });
    return response?.data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const useUnblockUser = (openNotification: any) => {
  const queryClient = useQueryClient();

  return useMutation(unblockUserNATIVE, {
    onSuccess: () => {
      openNotification({
        label: 'Pengguna berhasil di unblock',
        variant: 'primary'
      });

      queryClient.invalidateQueries('get-inv-user');
    },
    onError: () => {
      openNotification({
        label: 'Gagal mengunblock pengguna',
        variant: 'secondary'
      });
    }
  });
};
