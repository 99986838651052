import { Device } from 'mediasoup-client';
import { mediaServerProducer, mySignaling, meetingRoomId, mySignalingWithoutRes } from '.';
import { MessageEventEnum } from '../../static/message-event';
import { createLocalVideoTrack } from 'twilio-video';
import { Pipeline, VirtualBackgroundProcessor } from '@twilio/video-processors';
import background from './background.jpeg';
import { useCallStore } from '../../store/call-state';
import { LeaveMeetingMetadata } from '../../types/video-call';

let producerIdVideo: string;
let producerIdAudio: string;
let producerIdScreen: string;
let rtpCapabilities: any;
let ReceivesendTransport: any;
let WebRTCConsumer: any;
let sendTransport: any;
let WebRTCProducer: any;
let videoTrack: any;

export let streamTrack: any;
export let remoteStreamVideoGlobal: any;
export let remoteStreamScreenGlobal: any;
export let remoteStreamAudioGlobal: any;
export let selfVideoGlobal: any;

export default async function VideoRequest({ audio, video }: { audio: boolean; video: boolean }) {
  let createdVideoConsumer: boolean;
  let createdAudioConsumer: boolean;
  const device: any = new Device();
  await mySignaling({ event: MessageEventEnum.JOIN_MEETING_ROOM, meetingRoomId });
  const getRTPCapabilities = await mySignaling({ event: 'GET_RTP_CAPABILITIES', meetingRoomId });
  const routerRtpCapabilities = await getRTPCapabilities.data.rtpCapabilities;
  rtpCapabilities = routerRtpCapabilities;
  await device.load({ routerRtpCapabilities });
  if (!device.canProduce('video')) {
    // eslint-disable-next-line no-console
    console.warn('cannot produce video');
  }
  WebRTCProducer = await mySignaling({
    event: MessageEventEnum.CREATE_WEBRTC_TRANSPORT,
    meetingRoomId
  });
  WebRTCConsumer = await mySignaling({
    event: MessageEventEnum.CREATE_WEBRTC_TRANSPORT,
    meetingRoomId
  });
  sendTransport = device.createSendTransport({
    ...WebRTCProducer.data.webrtcResponse
  });
  ReceivesendTransport = device.createRecvTransport({
    ...WebRTCConsumer.data.webrtcResponse
  });
  sendTransport.on('connect', async ({ dtlsParameters }: any, callback: any, errback: any) => {
    // Here we must communicate our local parameters to our remote transport.
    try {
      const { originalRequestId } = await mySignaling({
        event: MessageEventEnum.CONNECT_WEBRTC_TRANSPORT,
        meetingRoomId,
        transportId: WebRTCProducer.data.webrtcResponse.id,
        dtlsParameters
      });
      // Done in the server, tell our transport.
      const id = originalRequestId;
      callback({ id });
    } catch (error) {
      // Something was wrong in server side.
      errback(error);
    }
  });
  sendTransport.on(
    'produce',
    async ({ kind, rtpParameters, appData }: any, callback: any, errback: any) => {
      // Here we must communicate our local parameters to our remote transport.
      try {
        const res = await mySignaling({
          event: MessageEventEnum.CREATE_WEBRTC_TRANSPORT_PRODUCER,
          meetingRoomId,
          producerTransportId: WebRTCProducer.data.webrtcResponse.id,
          data: {
            kind: kind, //audio/video
            rtpParameters: rtpParameters,
            mediaType: appData.mediaType
          }
        });
        const id = res.originalRequestId;
        appData?.mediaType === 'screen'
          ? (producerIdScreen = res?.data?.producer.id)
          : appData.mediaType === 'video'
          ? (producerIdVideo = res?.data?.producer.id)
          : (producerIdAudio = res?.data?.producer.id);
        if (mediaServerProducer?.producers[0]?.meta?.length > 0) {
          mediaServerProducer.producers[0].meta.map(async (item: any) => {
            if (!createdAudioConsumer && kind === 'audio' && item.kind === 'audio') {
              createdAudioConsumer = true;
              const consumer = await createConsumer(item);
              Received(consumer, appData.mediaType);
            }
            if (!createdVideoConsumer && kind === 'video' && item.kind === 'video') {
              createdVideoConsumer = true;
              const consumer = await createConsumer(item);
              Received(consumer, appData.mediaType);
            }
          });
        }
        // Done in the server, tell our transport.
        callback({ id });
      } catch (error) {
        // Something was wrong in server side.
        errback(error);
      }
    }
  );
  //handle if connection change and do restartIce
  sendTransport.on('connectionstatechange', (connectState: any) => {
    switch (connectState) {
      case 'disconnected':
      case 'failed':
        restartIce(false);
        break;
      default:
        clearTimeout(sendRestartIce.timer || 0);
        break;
    }
  });
  ReceivesendTransport.on('connectionstatechange', (connectState: any) => {
    switch (connectState) {
      case 'disconnected':
      case 'failed':
        restartIce(true);
        break;
      default:
        clearTimeout(recvRestartIce.timer || 0);
        break;
    }
  });
  ReceivesendTransport.on(
    'connect',
    async ({ dtlsParameters }: any, callback: any, errback: any) => {
      // Here we must communicate our local parameters to our remote transport.
      try {
        const { originalRequestId } = await mySignaling({
          event: MessageEventEnum.CONNECT_WEBRTC_TRANSPORT,
          meetingRoomId,
          transportId: ReceivesendTransport.id,
          dtlsParameters
        });
        // Done in the server, tell our transport.
        const id = originalRequestId;
        callback({ id });
      } catch (error) {
        // Something was wrong in server side.
        errback(error);
      }
    }
  );
  video && CreateVideoProducer();
  audio && CreateAudioProducer();
}

export const CreateVideoProducer = async () => {
  let img: HTMLImageElement = new Image();
  const vbg: string = useCallStore.getState().virtualBackground as string;
  if (vbg) {
    img.src = vbg;
  }
  if (!vbg) {
    img.src = background;
  }
  img.crossOrigin = 'Anonymous';
  img.onload = async (e) => {
    videoTrack = await createLocalVideoTrack({
      width: 360,
      height: 640,
      frameRate: 24
    });
    const bg = new VirtualBackgroundProcessor({
      assetsPath: '/tsFlow/',
      backgroundImage: img,
      maskBlurRadius: 5,
      pipeline: Pipeline.WebGL2
    });

    await bg.loadModel();
    try {
      videoTrack.addProcessor(bg, {
        inputFrameBufferType: 'video',
        outputFrameBufferContextType: 'webgl2'
      });
    } catch (error) {
      //eslint-disable-next-line
      console.log('addProcessor', error);
    }
    videoTrack.attach();
    let selfStream: any = document.getElementById('self-video');
    const SelfStream = new MediaStream();
    SelfStream.addTrack(videoTrack.processedTrack);
    selfStream.srcObject = SelfStream;
    sendTransport.appData.mediaType = 'video';
    selfVideoGlobal = SelfStream;
    await sendTransport.produce({
      ...WebRTCProducer.data.webrtcResponse,
      track: videoTrack.processedTrack,
      appData: { mediaType: 'video' }
    });
  };
};
let streamAudio: any;
export const CreateAudioProducer = async () => {
  streamAudio = await navigator.mediaDevices?.getUserMedia({ video: false, audio: true });
  const webcamTrackAudio = streamAudio.getAudioTracks();

  await sendTransport.produce({
    ...WebRTCProducer.data.webrtcResponse,
    track: webcamTrackAudio[0],
    appData: { mediaType: 'audio' }
  });
};

export const closeProducer = async (param: any) => {
  mySignaling({
    event: MessageEventEnum.PRODUCER_CLOSE,
    meetingRoomId,
    producerId:
      param === 'screen' ? producerIdScreen : param === 'video' ? producerIdVideo : producerIdAudio
  });
  let selfStream: any = document.getElementById('self-video');
  if (param === 'audio' && streamAudio) {
    streamAudio.getAudioTracks().forEach((track: any) => track.stop());
  } else if (param === 'video' && selfStream?.srcObject) {
    videoTrack.mediaStreamTrack.stop();
    selfStream?.srcObject?.getVideoTracks().forEach((track: any) => track.stop());
    selfStream.srcObject = null;
  } else {
    screenStream && screenStream.getVideoTracks().forEach((track: any) => track.stop());
  }
};

const resume = async (consumer: any) => {
  await mySignaling({
    event: MessageEventEnum.RESUME_CONSUMER_STREAM_REQUEST,
    meetingRoomId,
    consumerId: consumer.id
  });
};
export const createConsumer = async (item: { id: string; mediaType: string }) => {
  const res = await mySignaling({
    event: MessageEventEnum.CREATE_WEBRTC_TRANSPORT_CONSUMER,
    meetingRoomId,
    consumerTransportId: WebRTCConsumer.data.webrtcResponse.id,
    producerId: item.id,
    data: {
      producerId: item.id,
      rtpCapabilities: rtpCapabilities,
      mediaType: item.mediaType
    }
  });
  return res.data.consumer;
};

export const Received = async (consumer: any, mediaType: string) => {
  const { track } = await ReceivesendTransport.consume({
    id: consumer.id,
    producerId: consumer.producerId,
    kind: consumer.kind,
    rtpParameters: consumer.rtpParameters,
    codecOptions: consumer.codecOptions
  });
  if (consumer.kind === 'video' && mediaType === 'video') {
    const remStream = new MediaStream();
    let remoteStream: any = document.getElementById('remote-video');
    useCallStore.getState().setRemoteVideo(true);
    remStream.addTrack(track);
    remoteStream.srcObject = remStream;
    remoteStreamVideoGlobal = remStream;
    streamTrack = track;
  } else if (consumer.kind === 'video' && mediaType === 'screen') {
    const remStreamscreen = new MediaStream();
    let remoteStream: any = document.getElementById('remote-screen');
    remStreamscreen.addTrack(track);
    remoteStream.srcObject = remStreamscreen;
    remoteStreamScreenGlobal = remStreamscreen;
    streamTrack = track;
  } else if (consumer.kind === 'audio' && mediaType === 'audio') {
    const remAudio = new MediaStream();
    let remoteStreamAudio: any = document.getElementById('remote-audio');
    useCallStore.getState().setRemoteSound(true);
    remAudio.addTrack(track);
    remoteStreamAudio.srcObject = remAudio;
    remoteStreamAudioGlobal = remAudio;
  }

  resume(consumer);
};
let screenStream: any;
export const screenShare = async () => {
  screenStream = await navigator.mediaDevices.getDisplayMedia({ video: true, audio: true });
  const webcamTrack = screenStream.getVideoTracks();
  await sendTransport.produce({
    ...WebRTCProducer.data.webrtcResponse,
    track: webcamTrack[0],
    appData: { mediaType: 'screen' }
  });
  screenStream.getVideoTracks()[0].onended = function () {
    closeProducer('screen');
    CreateVideoProducer();
    useCallStore.getState().setFullScreen(false);
  };
};

export const pauseProducer = async (param: any) => {
  await mySignaling({
    event: MessageEventEnum.PRODUCER_PAUSE,
    meetingRoomId,
    producerId: param === 'video' ? producerIdVideo : producerIdAudio
  });
  let selfStream: any = document.getElementById('self-video');
  if (selfStream.srcObject) {
    // let tracks = selfStream.srcObject.getTracks();
    // param === 'video' && tracks.forEach((track: any) => track.stop());
    selfStream.srcObject = param === 'video' && null;
  }
};
export const resumeProducer = async (param: any) => {
  await mySignaling({
    event: MessageEventEnum.PRODUCER_RESUME,
    meetingRoomId,
    producerId: param === 'video' ? producerIdVideo : producerIdAudio
  });
  if (param === 'video') {
    let selfStream: any = document.getElementById('self-video');
    const stream = await navigator.mediaDevices?.getUserMedia({ video: true, audio: false });
    selfStream.srcObject = stream;
  }
};
export const leaveMeeting = (metadata?: LeaveMeetingMetadata) => {
  if (metadata?.endCall) sessionStorage.setItem(`end-call-${meetingRoomId}`, 'true');

  return new Promise<void>((resolve) => {
    localStorage.removeItem('showCustomerOfflineAlert');
    localStorage.removeItem('hearthBeat');
    useCallStore.getState().setShowCustomerOfflineAlert(false);
    useCallStore.getState().setInstructionMode(null);
    producerIdVideo && closeProducer('video');
    producerIdAudio && closeProducer('audio');
    mySignalingWithoutRes({
      event: MessageEventEnum.LEAVE_MEETING_ROOM,
      meetingRoomId,
      ...metadata
    });
    resolve();
  });
};

export const checkVideoAudio = () => {
  navigator.mediaDevices
    ?.getUserMedia({ video: true, audio: true })
    .then(function (stream) {
      // Camera and microphone are available
      stream.getTracks().forEach(function (track) {
        track.stop(); // Turn off the camera and microphone
      });
    })
    .catch(function (error) {
      // Camera and/or microphone are not available
      //eslint-disable-next-line
      console.error('Error accessing camera and microphone:', error);
    });
};
const sendRestartIce: any = { timer: null, restarting: false };
const recvRestartIce: any = { timer: null, restarting: false };

export const restartIce = async (isRecievetransport: boolean) => {
  const transport = isRecievetransport ? ReceivesendTransport : sendTransport;
  const ice = isRecievetransport ? recvRestartIce : sendRestartIce;
  const delay = 2000;
  //eslint-disable-next-line
  console.log('restartIce() [transport:%o ice:%o delay:%d]', transport, ice, delay);

  if (!transport) {
    //eslint-disable-next-line
    console.log('restartIce(): missing valid transport object');
    return;
  }

  if (!ice) {
    //eslint-disable-next-line
    console.log('restartIce(): missing valid ice object');
    return;
  }

  clearTimeout(ice.timer);
  ice.timer = setTimeout(async () => {
    try {
      if (ice.restarting) {
        return;
      }
      ice.restarting = true;
      const res = await mySignaling({
        event: MessageEventEnum.RESTART_ICE,
        meetingRoomId,
        transportId: transport.id
      });
      const iceParameters = res.data.iceParameters;
      await transport.restartIce({ iceParameters });
      ice.restarting = false;
      //eslint-disable-next-line
      console.log('ICE restarted');
    } catch (error) {
      //eslint-disable-next-line
      console.log('restartIce failed', error);
      ice.restarting = false;
      ice.timer = setTimeout(() => {
        restartIce(isRecievetransport);
      }, delay);
    }
  }, delay);
};
