import { useAuthStore } from '../../store/new/auth';
import { getAgentInboxList } from '../../services/inbox';
import { useInboxStore } from '../../store/new/inbox';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getAccountConversationList } from '../../services/conversation';
import { useConversationStore } from '../../store/new/conversation';
import { HIDE_FEATURE_PROD } from '../../config/app';

const INITIAL_CONVERSATION_PARAMS = { page: 1, size: 10 };

export const useAgentDashboard = () => {
  const { inboxId } = useParams();
  const { account, user } = useAuthStore();
  const { selectedInboxId, setInboxes, setSelectedInboxId } = useInboxStore();
  const { setActiveConversationList, setInactiveConversationList } = useConversationStore();

  useQuery(
    ['inboxStore', account.accountId],
    () => getAgentInboxList(account.accountId, user.agentId),
    {
      enabled: !!account.accountId && !!user.agentId && !HIDE_FEATURE_PROD,
      onSuccess: (data) => {
        const inboxes = data.inboxList;
        setInboxes(inboxes);
        if (inboxId && inboxes.find((inbox) => inbox.inboxId === inboxId))
          setSelectedInboxId(inboxId);
        else if (inboxes.length > 0) setSelectedInboxId(inboxes[0].inboxId);
      }
    }
  );

  useQuery(
    ['conversationInactiveStore', account.accountId, selectedInboxId],
    () =>
      getAccountConversationList(account.accountId, {
        'page-no': INITIAL_CONVERSATION_PARAMS.page,
        size: INITIAL_CONVERSATION_PARAMS.size,
        inboxId: selectedInboxId,
        active: false
      }),
    {
      enabled: !!account.accountId && !!selectedInboxId && !HIDE_FEATURE_PROD,
      onSuccess: (data) => {
        const inactiveConversationList = data.data;
        setInactiveConversationList(inactiveConversationList);
      }
    }
  );

  useQuery(
    ['conversationActiveStore', account.accountId, selectedInboxId],
    () =>
      getAccountConversationList(account.accountId, {
        'page-no': INITIAL_CONVERSATION_PARAMS.page,
        size: INITIAL_CONVERSATION_PARAMS.size,
        active: true,
        inboxId: selectedInboxId
      }),
    {
      enabled: !!account.accountId && !!selectedInboxId && !HIDE_FEATURE_PROD,
      onSuccess: (data) => {
        const activeConversationList = data.data;
        setActiveConversationList(activeConversationList);
      }
    }
  );
};
