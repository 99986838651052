import { Col, Flex, Form, Modal, Row, Select } from 'antd';
import { useTeamStore } from '../../../store/team-state';
import { UserBUModalInterface } from './users.interface';
import { Button, Title } from '@squantumengine/horizon';

const UserBUModal = ({
  isModalOpen,
  setIsModalOpen,
  inviteUser,
  setSelectedBU
}: UserBUModalInterface) => {
  const [form] = Form.useForm();
  const { account } = useTeamStore();

  const handleInviteUser = () => {
    const organizationId = form.getFieldValue('organizationId');
    inviteUser(organizationId);
    setIsModalOpen(false);
  };

  const filterOption = (input: string, option?: any) => {
    return (option?.children ?? '').toLowerCase().includes(input.toLowerCase());
  };

  return (
    <Modal
      title={<Title level={4}>Undang User</Title>}
      closable={true}
      open={isModalOpen}
      onOk={() => setIsModalOpen(false)}
      onCancel={() => setIsModalOpen(false)}
      footer={
        <Flex gap="small" align="center" justify="end">
          <Button variant="secondary" className="w-32" onClick={() => setIsModalOpen(false)}>
            Batal
          </Button>
          <Button className="w-32" onClick={handleInviteUser}>
            Selanjutnya
          </Button>
        </Flex>
      }>
      <Form
        className="mt-4"
        name="select_bu"
        layout="vertical"
        form={form}
        autoComplete="off"
        requiredMark={false}
        size="large">
        <Row gutter={16}>
          <Col className="gutter-row" span={24}>
            <Form.Item
              name="organizationId"
              required
              label={<Title level={6}>Business Unit</Title>}>
              <Select
                onChange={(value, option) =>
                  setSelectedBU!({
                    key: value,
                    label: option?.children
                  })
                }
                showSearch
                placeholder="Pilih"
                filterOption={filterOption}>
                {account.accountList?.map((option) => (
                  <Select.Option key={option.accountId} value={option.accountId}>
                    {option.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default UserBUModal;
