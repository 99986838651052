import axiosInstance from '../config/axios';
import axiosInstanceSQEID from '../config/axios/axiosInstanceSQEID';
import { ClientAuthProviderRes, SSOLoginPayload, SSOTokenObject } from '../shared/types/auth.interface';
import { withErrorHandlingAsync } from '../utils/error-handling';

export const ssoLoginRequest: (payload: SSOLoginPayload) => Promise<SSOTokenObject> = async (
  payload
) => {
  const response = await axiosInstanceSQEID.post('oidc/token', payload);
  return response.data;
};

export const getClientAuthProvider = async (client: string) => {
  const res = await withErrorHandlingAsync(async () => {
    const response = await axiosInstance.get<ClientAuthProviderRes>(`account/client/${client}/authentication/provider`);
    return response.data;
  });

  return res;
};
