import {
  ConnectionNormalIcon,
  ConnectionStrongIcon,
  ConnectionWeakIcon
} from '../../assets/icons/connections';
import { useAppStore } from '../../store/user-state';

function getConnectionIcon(signalStrength: number): JSX.Element {
  switch (true) {
    case signalStrength >= 10:
      return <ConnectionStrongIcon />;
    case signalStrength >= 3:
      return <ConnectionNormalIcon />;
    default:
      return <ConnectionWeakIcon />;
  }
}

function SignalStrengthIndicator() {
  const { signalStrength } = useAppStore();
  const connectionIcon = getConnectionIcon(signalStrength);

  return <>{connectionIcon}</>;
}

export default SignalStrengthIndicator;
