import { getIconUrl } from '../../utils/getIconUrl';

const TabFilter = (props: {
  fullWidth?: boolean;
  selectedTab: string;
  setSelectedTab: (selectedTab: string) => void;
  Tablist: { name: string; icon?: string; key?: any | React.ReactNode }[];
  disabled?: boolean;
  compact?: boolean;
}) => {
  const { selectedTab, setSelectedTab, Tablist, fullWidth = false, disabled, compact } = props;
  return (
    <div
      className={`flex ${
        fullWidth ? 'w-full' : 'w-max'
      } max-w-full flex-row rounded-full bg-grey-10`}>
      {Tablist.map((data, key) => (
        <button
          disabled={disabled}
          key={key}
          onClick={() => setSelectedTab(data.key || data.name)}
          className={`${
            disabled && selectedTab !== (data.key || data.name)
              ? 'cursor-not-allowed '
              : 'cursor-pointer text-black'
          } ${
            selectedTab === (data.key || data.name)
              ? 'border-solid border-grey-10 bg-white'
              : 'border-solid border-transparent bg-transparent'
          } flex flex-row items-center justify-center whitespace-nowrap rounded-full px-4 py-3 font-semibold transition-colors xl:text-base
          ${compact ? 'w-full xl:px-4' : 'w-fit xl:px-8'}
          `}>
          {typeof data.icon === 'string' ? (
            <img
              className={`mr-2 ${
                disabled && selectedTab !== (data.key || data.name) && 'opacity-30'
              }`}
              src={getIconUrl(data.icon)}
              alt={data.name}
            />
          ) : (
            data.icon
          )}
          {data.name}
        </button>
      ))}
    </div>
  );
};
export default TabFilter;
