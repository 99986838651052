import Copy from '../../copy';
import { OfflineCardInterface } from './offline-card.interface';
import dayjs from 'dayjs';
import 'dayjs/locale/id';
import { useChatListStore } from '../../../../store/chat-list';
import { useSelectedCustomerStore } from '../../../../store/customer-state';
import { Icon, Paragraph } from '@squantumengine/horizon';
import { useUserStatusStore } from '../../../../store/user-state';
import { RoleEnum } from '../../../../static/role';

function OfflineCard(props: OfflineCardInterface) {
  const { data } = props;
  const { createdAt, messageId, customer, message } = data;
  const { selectedOfflineChat, setSelectedOfflineChat } = useChatListStore();
  const { setCustomerDetail, setIsPreClosedAvailable } = useSelectedCustomerStore();
  const { userRole } = useUserStatusStore();

  const isSelected = selectedOfflineChat?.messageId === messageId;
  const dateString = dayjs(createdAt).locale('id').format('DD MMM YY');
  const isAgent = userRole === RoleEnum.AGENT;

  const handleClick = () => {
    setSelectedOfflineChat(data);
    setCustomerDetail({
      contactId: '',
      name: data.customer.name || '',
      email: data.customer.email || '',
      phone: data.customer.phone || '',
      gender: '',
      dateOfBirth: '',
      motherMaidenName: '',
      note: ''
    });
    setIsPreClosedAvailable(false);
  };

  if (isAgent) {
    return (
      <div
        onClick={handleClick}
        className={`cursor-pointer text-neutral-950 ${isSelected ? 'bg-blue-50' : ''}`}>
        <div className="flex shrink-0 flex-row items-center gap-2 p-4">
          <div
            className={`flex h-[48px] w-[48px] shrink-0 flex-row items-center justify-center rounded-full border border-solid border-neutral-300 bg-white`}>
            <Icon name={'email'} />
          </div>
          <div className="flex-1">
            <div className="flex flex-row items-center justify-between gap-2">
              <Paragraph size="l" weight="semibold" className={`w-1 flex-1 truncate`}>
                {customer.name}
              </Paragraph>
              <Paragraph className={`max-w-full truncate text-neutral-600`}>
                {dayjs(createdAt).format('DD/MM/YY')}
              </Paragraph>
            </div>

            <div className="flex">
              <Paragraph className={`w-1 flex-1 truncate`}>{message}</Paragraph>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div
        data-testid={`offline-card-${customer.id}`}
        className={`relative cursor-pointer rounded-lg border border-solid 
          ${isSelected ? 'border-blue-500 bg-blue-50' : 'border-neutral-100'}`}
        onClick={handleClick}>
        <div className="flex shrink-0 flex-row items-center gap-1 p-4">
          <div className="relative flex flex-row items-center">
            <Icon name="comment" />
          </div>
          <Paragraph size="l" weight="semibold" className="max-w-full grow truncate">
            {customer.name}
          </Paragraph>
        </div>

        <hr className="m-0 border border-b-0 border-solid border-neutral-100" />

        <div className="px-4 py-3">
          <div className="mb-1 flex flex-row items-center justify-between">
            <Copy value={messageId}>
              <Paragraph className="text-neutral-600">Tiket</Paragraph>
            </Copy>
            <Paragraph className="w-[80px] truncate">#{messageId}</Paragraph>
          </div>

          <div className="mb-1 flex flex-row items-center justify-between">
            <Paragraph className="text-neutral-600">Waktu Tunggu</Paragraph>
            <Paragraph className="truncate">{dateString}</Paragraph>
          </div>
        </div>
      </div>
    </>
  );
}

export default OfflineCard;
