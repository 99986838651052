import { Hook, Table, TableColumnTypeDef, TableRowTypeModifier } from '@squantumengine/horizon';
import DetailCardLayout from './components/DetailCardLayout';
import useTeamCreateEditStore, { CreateEditSections } from '../create-edit/store';
import { CreateTeamFormSection } from '../create-edit/type';
import { useNavigate, useParams } from 'react-router-dom';

type AgentTableType = {
  day: string;
  time: string;
};

type ModifiedAgentTableType = TableRowTypeModifier<AgentTableType>;

export default function OperationalScheduleDetail() {
  const navigate = useNavigate();
  const { inboxId } = useParams();
  const { operationalHour, setSelectedSection } = useTeamCreateEditStore();
  const handleEdit = () => {
    setSelectedSection(
      CreateEditSections.findIndex(
        (section) => section.key === CreateTeamFormSection.FORM_OPERATIONAL_SCHEDULE
      )
    );
    navigate(`/v2/dashboard/team/edit/${inboxId}`);
  };

  const weekdays = ['Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu', 'Minggu'];

  const formatTime = (hour: number, minutes: number) => {
    const addZero = (num: number) => num.toString().padStart(2, '0');
    return `${addZero(hour)}:${addZero(minutes)}`;
  };

  const operationalHourTableData = operationalHour.workingHours
    .sort((a, b) => a.dayOfWeek - b.dayOfWeek)
    .map((data) => ({
      day: weekdays[data.dayOfWeek - 1],
      time: data.closedAllDay
        ? 'Libur'
        : `${formatTime(data.openHour, data.openMinutes)} - ${formatTime(
            data.closeHour,
            data.closeMinutes
          )}`
    }));

  const tableColumns: TableColumnTypeDef<ModifiedAgentTableType> = [
    {
      header: 'Hari',
      accessorKey: 'day',
      enableSorting: false
    },
    {
      header: 'Jam Buka - Jam Tutup',
      accessorKey: 'time',
      enableSorting: false
    }
  ];

  const table = Hook.useTable({
    data: operationalHourTableData,
    columns: tableColumns
  });

  return (
    <DetailCardLayout title="Jadwal Operasional" buttonAction={handleEdit}>
      <Table data={operationalHourTableData} table={table} columns={tableColumns} />
    </DetailCardLayout>
  );
}
