import { Fragment, useEffect, useMemo, useState } from 'react';
import {
  Row,
  Col,
  Input,
  Checkbox,
  Alert,
  Button,
  Form,
  notification,
  ConfigProvider,
  Spin,
  Collapse,
  Switch,
  message,
  Typography,
  Tooltip,
  RadioChangeEvent
} from 'antd';

import {
  InboxConfigEnum,
  constructPayload,
  defaultInboxValues,
  errMsgInbox,
  mainRadioList
} from '../../../static/inbox-config';
import { FormActionEnum } from '../../../static/team';
import ErrorForm from '../error-form/error-form';
import AccountInput from '../input/account-input';
import InboxSettings from './inbox-settings';
import { validateNumber } from '../../../utils/number';
import { createInbox, updateInbox } from '../../../api';
import { CreateInboxParam } from '../../../api/param.interface';
import {
  CommunicationTypeEnum,
  ConversationContextEnum,
  CredentialEnum,
  WebhookIdentifierEnum,
  communicationModeOptions,
  defaultCommunicationTypeEnum,
  defaultCommunicationTypeOptions
} from '../../../static/communication-type';
import { useTeamStore } from '../../../store/team-state';
import {
  InboxConfigInterface,
  AllowMediaCallInterface,
  InboxListItemInterface
} from '../../../shared/types/inbox.interface';
import { useValidateInbox } from '../../../hooks/useValidateInbox';
import { CloseCircleFilled, DownOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { UNUSUAL_TOTAL_CALL } from '../../../static/error-message';
import { CreateInboxResponse } from '../../../config/axios';
import Topic from './topic';
import InboxTooltip from './custom-tooltip';
import { MappingErrorAPI } from '../../../config/mapping';
import RejectReason from './reject-reason';
import RadioBtn from './radio-field';

function InboxDetailsForm(props: {
  selectedTab?: string;
  setSelectedTab?: React.Dispatch<React.SetStateAction<string>>;
  setDisableNextTab?: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [form] = Form.useForm();
  const [api, contextHolder] = notification.useNotification();
  const [apiError, contextHolderError] = notification.useNotification();
  const [, contextHolderMessage] = message.useMessage();

  useValidateInbox(form, api);
  const { Panel } = Collapse;
  const { Text } = Typography;

  const { selectedTab, setSelectedTab, setDisableNextTab } = props;

  const {
    formAction,
    selectedBU,
    selectedInbox,
    setSelectedInbox,
    fetchInbox,
    setIsOpenForm,
    resetTeamActiveHandler,
    setInboxResponse,
    inbox
  } = useTeamStore();
  const [loading, setLoading] = useState<boolean>(false);
  const [useGreeting, setUseGreeting] = useState<boolean>(false);
  const [showOperationalHour, setShowOperationalHour] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isMaxPanggilan, setIsMaxPanggilan] = useState<boolean>(false);

  const showInboxSettings = selectedTab === InboxConfigEnum.SETTING;
  const showInboxDetails = selectedTab === InboxConfigEnum.DETAIL;
  const conversationContext = Form.useWatch('conversationContext', form);
  const showTopic = formAction === FormActionEnum.EDIT;
  const showRejectReason =
    formAction === FormActionEnum.EDIT && conversationContext === ConversationContextEnum.KYC;
  const isManualKYC = conversationContext === ConversationContextEnum.KYC;

  const radioOptions = useMemo(
    () => mainRadioList(form),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [Form.useWatch('conversationContext', form)]
  );

  const submitInbox = async () => {
    try {
      setLoading(true);
      const isCreate: boolean = formAction === FormActionEnum.CREATE;
      const isEdit: boolean = formAction === FormActionEnum.EDIT;
      const payload: CreateInboxParam = constructPayload(form, showOperationalHour, selectedBU);

      if (isCreate) {
        doCreateInbox(payload);
        setDisableNextTab!(false);
      } else if (isEdit) doEditInbox(payload);
    } catch (error: any) {
      setErrorMessage(
        error.response
          ? error.response?.data?.message
          : 'Terjadi kesalahan, silahkan coba lagi nanti'
      );
    } finally {
      setLoading(false);
    }
  };

  const disableFormSubmit = () => {
    const formValues = form.getFieldsValue();
    if (showInboxDetails) {
      const disabled =
        !formValues.inboxName ||
        !formValues.websiteUrl ||
        !formValues.agentTarget ||
        isMaxPanggilan;

      setDisableNextTab && setDisableNextTab(disabled);

      return disabled;
    }

    if (showInboxSettings) {
      const disabled =
        !formValues.longQueueMessage ||
        !formValues.waitingRoomMessage ||
        !formValues.outsideWorkingHourMessage ||
        formValues.communicationModes?.length === 0 ||
        (useGreeting &&
          (form.getFieldValue('greetingMessage') === '' || !form.getFieldValue('greetingMessage')));

      setDisableNextTab && setDisableNextTab(true);
      return disabled;
    }
  };

  const validateTargetAgen = (e: any) => {
    const value = e.target.value;
    setIsMaxPanggilan(value > 2 ** 32);

    let validatedAgentTarget = validateNumber(value);
    form.setFieldsValue({ agentTarget: validatedAgentTarget });
  };

  useEffect(() => {
    const isSelectedInbox: boolean =
      Object.keys(selectedInbox).length !== 0 && formAction === FormActionEnum.EDIT;

    if (isSelectedInbox) {
      form.resetFields();
      const inboxConfig: InboxConfigInterface = selectedInbox?.inboxConfig;
      const mediaCall: AllowMediaCallInterface = selectedInbox?.inboxConfig?.mediaCallConfiguration;

      const workingHourIndex: number = selectedInbox.oooMessage?.indexOf(
        'Jam kerja hari ini adalah [jam operational].'
      );

      const foundWorkingHour = workingHourIndex !== -1;
      const workingHour: string = foundWorkingHour
        ? selectedInbox.oooMessage?.substring(0, workingHourIndex)
        : selectedInbox.oooMessage;
      setShowOperationalHour(foundWorkingHour);

      form.setFieldsValue({
        inboxName: selectedInbox.name,
        websiteUrl: selectedInbox.channel.websiteUrl,
        greeting: selectedInbox.greetingEnabled,
        greetingMessage: selectedInbox.greetingEnabled ? selectedInbox.greetingMessage : '',
        agentTarget: selectedInbox.target,
        accountName: selectedBU.name,
        communicationModes: inboxConfig?.communicationModes,
        conversationContext: inboxConfig?.conversationContext,
        defaultCommunicationType:
          mediaCall?.defaultCommunicationType || defaultCommunicationTypeEnum.AUDIO_VIDEO,
        allowTextMessage: inboxConfig?.allowTextMessage,
        allowAttachment: inboxConfig?.allowAttachment,
        allowMediaCall: inboxConfig?.allowMediaCall,
        allowRefId: inboxConfig?.allowRefId,
        allowConversationSummary: inboxConfig?.allowConversationSummary,
        allowConversationRating: inboxConfig?.allowConversationRating,
        webhookIdentifier: inboxConfig?.webhookIdentifier || WebhookIdentifierEnum.NONE,
        credential: inboxConfig?.credential || CredentialEnum.NONE,
        reminderIdleConversation: inboxConfig.reminderIdleConversation,
        durationReminder: inboxConfig.durationReminder,
        showVideoCallButton: mediaCall?.showVideoCallButton,
        showAudioCallButton: mediaCall?.showAudioCallButton,
        showScreenShareButton: mediaCall?.showScreenShareButton,
        showCapturePhoto: mediaCall?.showCapturePhoto,
        showSwitchCamera: mediaCall.switchCamera,
        autoConnect: mediaCall?.autoConnect,
        longQueueMessage: selectedInbox.longQueueMessage || defaultInboxValues.longQueueMessage,
        waitingRoomMessage:
          selectedInbox.waitingRoomMessage || defaultInboxValues.waitingRoomMessage,
        outsideWorkingHourMessage: workingHour || defaultInboxValues.outsideWorkingHourMessage
      });
      setUseGreeting(selectedInbox.greetingEnabled);
    }
    form.setFieldValue('businessUnit', selectedBU.name);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formAction, selectedInbox, selectedBU]);

  useEffect(() => {
    if (formAction === FormActionEnum.CREATE) {
      form.resetFields();
    }
  }, [form, formAction]);

  const doCreateInbox = async (payload: CreateInboxParam) => {
    try {
      switch (selectedTab) {
        case InboxConfigEnum.DETAIL:
          setSelectedTab && setSelectedTab(InboxConfigEnum.SETTING);
          break;
        case InboxConfigEnum.SETTING:
          try {
            const response: CreateInboxResponse = await createInbox(payload);
            setSelectedTab && setSelectedTab(InboxConfigEnum.SCHEDULE);
            setInboxResponse({
              inboxId: response.inboxId,
              oooMessage: payload.oooMessage!
            });
            await fetchInbox(selectedBU.accountId);
            const inboxIndex: number = inbox.findIndex(
              (inboxItem) => inboxItem.accountId === selectedBU.accountId
            );
            const inboxes: InboxListItemInterface[] = inbox[inboxIndex].inboxList;
            const newInbox = inboxes.find((inbox) => inbox.inboxId === response?.inboxId);
            newInbox && setSelectedInbox(newInbox);
          } catch (error: any) {
            apiError['error']({
              icon: <CloseCircleFilled style={{ color: 'red' }} />,
              message: 'Error Membuat Inbox',
              description: MappingErrorAPI(error?.response?.data?.message),
              placement: 'topRight'
            });
          }
          break;
        case InboxConfigEnum.SCHEDULE:
          setSelectedTab && setSelectedTab(InboxConfigEnum.DETAIL);
          form.resetFields();
          setIsOpenForm(false);
          await fetchInbox(selectedBU.accountId);
          resetTeamActiveHandler();
          break;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const doEditInbox = async (payload: CreateInboxParam) => {
    try {
      await updateInbox(selectedInbox.inboxId, payload);
      form.resetFields();
      setIsOpenForm(false);
      fetchInbox(selectedBU.accountId);
      resetTeamActiveHandler();
    } catch (error: any) {
      apiError['error']({
        icon: <CloseCircleFilled style={{ color: 'red' }} />,
        message: 'Error Mengubah Inbox',
        description: MappingErrorAPI(error?.response?.data?.message),
        placement: 'topRight'
      });
    }
  };

  const callModeOptions = [
    {
      title: 'defaultCommunicationType',
      value: 'Tipe Panggilan',
      options: defaultCommunicationTypeOptions(isManualKYC),
      event: (e: RadioChangeEvent) => {
        if (e.target.value !== defaultCommunicationTypeEnum.AUDIO) {
          form.setFieldsValue({ showVideoCallButton: true });
        }
      },
      tooltip: (
        <ul>
          <li>
            <b>Suara</b>: Pelanggan tidak dapat mengaktifkan kamera.
          </li>
          <li>
            <b>Video</b>: Pelanggan langsung terhubung ke agen melalui video.
          </li>
          <li>
            <b>Suara + Video</b>: Pelanggan dapat beralih dari panggilan suara ke video.
          </li>
        </ul>
      )
    }
  ];

  const shouldShowCallType = () => {
    const communicationModes = form?.getFieldValue('communicationModes');
    return communicationModes?.includes(CommunicationTypeEnum.VIDEO);
  };

  return (
    <>
      {contextHolderMessage}
      {contextHolderError}
      <ConfigProvider
        theme={{
          token: { colorText: 'white', colorBgElevated: '#2DB089', colorIcon: 'white' }
        }}>
        {contextHolder}
      </ConfigProvider>
      <Spin spinning={loading} wrapperClassName="w-full">
        <Form
          name="basic"
          layout="vertical"
          form={form}
          autoComplete="off"
          requiredMark={false}
          initialValues={defaultInboxValues}
          size="large">
          <div className={showInboxDetails ? '' : 'hidden'}>
            <Row align="middle">
              {radioOptions?.map((item) => {
                return <Fragment key={item?.title}>{RadioBtn(item)}</Fragment>;
              })}
            </Row>
            <Row align="middle">
              <Col span={24}>
                <Text className="text-base font-semibold">Metode percakapan</Text>
                <Tooltip
                  title={'Pilih metode percakapan yang dapat dilakukan oleh tim ini.'}
                  color="#1E252E"
                  overlayInnerStyle={{ fontSize: 16 }}>
                  <QuestionCircleOutlined className="ml-2 text-green-emerald-light" />
                </Tooltip>
              </Col>
              <Col span={24}>
                <Form.Item name="communicationModes" style={{ display: 'inline' }} required>
                  <Checkbox.Group options={communicationModeOptions(isManualKYC)} />
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle" className={shouldShowCallType() ? 'mb-2' : 'hidden'}>
              {callModeOptions?.map((item) => {
                return <Fragment key={item?.title}>{RadioBtn(item)}</Fragment>;
              })}
            </Row>
            <Row className="mb-2">
              {showTopic && <Topic form={form} />}
              {showRejectReason && <RejectReason form={form} />}
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="inboxName"
                  required
                  className="inbox_form-name mb-4"
                  label={<div className="text-base font-semibold">Nama Team</div>}>
                  <Input placeholder="Masukkan nama team" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <AccountInput disabled={true} type="view" />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="websiteUrl"
                  required
                  className="inbox_form-url mb-4"
                  label={<div className="text-base font-semibold">Tautan Situs</div>}>
                  <Input placeholder="Masukkan tautan ke situs" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="agentTarget"
                  required
                  className="inbox_form-target m-0"
                  label={<div className="text-base font-semibold">Total Panggilan Minggu ini</div>}>
                  <Input placeholder="Total panggilan" onChange={validateTargetAgen} />
                </Form.Item>
              </Col>
            </Row>
            <ErrorForm isVisible={isMaxPanggilan} message={UNUSUAL_TOTAL_CALL} />
          </div>

          <Row align="middle" justify="space-between" className={showInboxSettings ? '' : 'hidden'}>
            <Collapse
              className="advance-settings w-full"
              ghost
              defaultActiveKey={['automated-message']}
              expandIconPosition="end"
              expandIcon={(panelProps) => {
                return <DownOutlined rotate={panelProps.isActive ? 180 : 0} />;
              }}>
              <Panel
                forceRender
                key="automated-message"
                header={<span className="text-base font-semibold">Atur pesan otomatis</span>}>
                <Row>
                  <Col span={24}>
                    <Form.Item className="mb-1" name="greeting" valuePropName="checked">
                      <div
                        className="flex cursor-pointer flex-row justify-between"
                        onClick={() => setUseGreeting(!useGreeting)}>
                        <div className="text-base font-semibold">Tampilkan kata sambutan</div>
                        <Checkbox
                          checked={useGreeting}
                          onClick={() => setUseGreeting(!useGreeting)}
                        />
                      </div>
                    </Form.Item>
                  </Col>
                </Row>
                {useGreeting && (
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        label={
                          <>
                            <div className="text-base font-semibold">Kata Sambutan</div>
                            <InboxTooltip
                              title="Kata sambutan ini akan muncul pada saat pelanggan masuk ke halaman percakapan pertama kali."
                              images={['tooltip-greeting-2.svg']}
                            />
                          </>
                        }
                        name="greetingMessage"
                        rules={[
                          { max: 255, required: true, message: errMsgInbox.greetingMessage }
                        ]}>
                        <Input.TextArea
                          rows={3}
                          placeholder="Masukkan kata sambutan"
                          showCount
                          maxLength={255}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label={
                        <>
                          <div className="text-base font-semibold">Antrean Panjang</div>
                          <InboxTooltip
                            title="Pesan ini akan muncul pada saat percakapan belum dijawab oleh agen."
                            images={['tooltip-busy-hour.svg', 'tooltip-waiting-2.svg']}
                          />
                        </>
                      }
                      name="longQueueMessage"
                      rules={[{ max: 255, required: true, message: errMsgInbox.longQueueMessage }]}>
                      <Input.TextArea
                        rows={3}
                        placeholder="Masukkan kata diantrean panjang"
                        showCount
                        maxLength={255}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label={
                        <>
                          <div className="text-base font-semibold">Ruang Tunggu</div>
                          <InboxTooltip
                            title="Pesan menunggu antrean ini akan muncul pada saat pelanggan masuk ke ruang tunggu video call."
                            images={['tooltip-longqueue.svg']}
                          />
                        </>
                      }
                      name="waitingRoomMessage"
                      rules={[
                        { max: 255, required: true, message: errMsgInbox.waitingRoomMessage }
                      ]}>
                      <Input.TextArea
                        rows={3}
                        placeholder="Masukkan kata diruang tunggu"
                        showCount
                        maxLength={255}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label={
                        <>
                          <div className="text-base font-semibold">Diluar Jam Kerja</div>
                          <InboxTooltip
                            title="Pesan ini akan muncul pada saat pelanggan masuk diluar jam operasional."
                            images={['tooltip-outside-3.svg', 'tooltip-outside-2.svg']}
                          />
                        </>
                      }
                      name="outsideWorkingHourMessage"
                      rules={[
                        {
                          max: 255,
                          required: true,
                          message: errMsgInbox.outsideWorkingHourMessage
                        }
                      ]}>
                      <Input.TextArea
                        rows={3}
                        placeholder="Masukkan kata diluar jam kerja"
                        showCount
                        maxLength={255}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item valuePropName="showWorkingHour">
                      <div className="mb-4 text-base text-grey-100">
                        Jam kerja hari ini adalah [jam operational].
                      </div>
                      <ConfigProvider
                        theme={{
                          token: {
                            colorText: 'white',
                            colorPrimary: '#2DB089',
                            colorIcon: 'white'
                          }
                        }}>
                        <div className="flex flex-row">
                          <Switch
                            checked={showOperationalHour}
                            onChange={(checked: boolean) => setShowOperationalHour(checked)}
                          />
                          <div className="ml-2 text-base">
                            Tampilkan jam kerja per hari (sesuai setting)
                          </div>
                        </div>
                      </ConfigProvider>
                    </Form.Item>
                  </Col>
                </Row>
              </Panel>
            </Collapse>
          </Row>

          <div className={!showInboxSettings ? 'hidden' : ''}>
            <hr className="border-0 border-t border-solid border-grey-50" />
            <InboxSettings form={form} />
          </div>
          {errorMessage !== '' && (
            <Row>
              <Col span={24}>
                <Alert className="capitalize" message={errorMessage} type="error" showIcon />
              </Col>
            </Row>
          )}
          <Row className="mt-4">
            <Col span={24}>
              <Form.Item shouldUpdate>
                {() => (
                  <Button
                    className={`inbox_form-submit mt-2 rounded-3xl ${
                      disableFormSubmit()
                        ? 'bg-grey-50 text-grey-20'
                        : 'bg-green-emerald hover:bg-green-emerald-light active:bg-green-emerald'
                    }`}
                    disabled={disableFormSubmit()}
                    block
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    onClick={submitInbox}>
                    <strong>
                      {formAction === FormActionEnum.CREATE ? 'Simpan' : 'Ubah dan Simpan'}
                    </strong>
                  </Button>
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </>
  );
}

export default InboxDetailsForm;
