import { Button, Modal, message } from 'antd';
import Dropdown from '../../dropdown';
import { TextField } from '@squantumengine/horizon';
import { ConversationTopic, FullWorkspaceSummary } from '../../../api/param.interface';
import { useEffect, useState } from 'react';
import { useGetTopic } from '../../../api/hooks/inbox/useTopic';
import { getUserJSON } from '../../../utils/user-json';
import { useUpdateConversationSummary } from '../../../api/hooks/workspace/useWorkspaceSummary';

type Props = {
  conversationId: string;
  data: FullWorkspaceSummary | undefined;
  inboxId: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

function EditSummaryModal({ conversationId, data, inboxId, open, setOpen }: Readonly<Props>) {
  const [topic, setTopic] = useState<string>(data?.topic ?? '');
  const [note, setNote] = useState<string>(data?.note ?? '');
  const { data: topicList } = useGetTopic(inboxId);
  const { account } = getUserJSON();
  const [messageApi, contextHolder] = message.useMessage();
  const accountId = account?.accountId;

  const handleSubmitSuccess = () => {
    setOpen(false);
  };

  const { mutateAsync: updateWorkspaceSummary } = useUpdateConversationSummary(
    conversationId,
    handleSubmitSuccess
  );

  const options = topicList?.conversationTopics.map((topic: ConversationTopic) => topic.topic);

  useEffect(() => {
    if (data?.topic && data?.note) {
      setTopic(data.topic);
      setNote(data.note);
    }
  }, [data?.note, data?.topic]);

  const handleSubmit = async () => {
    if (accountId && conversationId) {
      try {
        await updateWorkspaceSummary({ ...data, topic: topic, note: note });
        messageApi.open({
          type: 'success',
          content: 'Update berhasil!'
        });
      } catch {
        messageApi.open({
          type: 'error',
          content: 'Update data gagal!'
        });
      }
    }
  };

  return (
    <Modal
      title="Edit Ringkasan"
      open={open}
      onOk={() => {}}
      footer={null}
      onCancel={() => {
        setOpen(false);
      }}>
      {contextHolder}
      <div className="flex flex-col gap-3">
        <Dropdown onChange={setTopic} options={options} placeholder="Pilih Opsi" value={topic} />
        <TextField
          animateLabel
          full
          label="Tulis Catatan (Opsional)"
          placeholder="Tulis Catatan (Opsional)"
          onChange={(e) => {
            setNote(e);
          }}
          value={note}
          showCounter
          maxLength={1000}
        />
        <Button type="primary" className="bg-bold-green" onMouseDown={handleSubmit}>
          Simpan
        </Button>
      </div>
    </Modal>
  );
}

export default EditSummaryModal;
