import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const SortSVG = () => (
  <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.125 11H3.75V0.5C3.75 0.25 3.5 0 3.25 0H2.75C2.46875 0 2.25 0.25 2.25 0.5V11H0.875H0.84375C0.65625 11 0.46875 11.1875 0.46875 11.375C0.46875 11.5 0.53125 11.5938 0.59375 11.6562L2.71875 13.9062C2.78125 13.9688 2.875 14 2.96875 14C3.09375 14 3.1875 13.9688 3.25 13.9062L5.375 11.6562C5.4375 11.5938 5.46875 11.5 5.46875 11.4062C5.46875 11.1875 5.3125 11.0312 5.125 11ZM11.375 2.375L9.25 0.125C9.1875 0.0625 9.09375 0 9 0C8.875 0 8.78125 0.0625 8.71875 0.125L6.59375 2.375C6.53125 2.4375 6.5 2.53125 6.5 2.625C6.5 2.84375 6.65625 3 6.875 3H8.25V13.5C8.25 13.7812 8.46875 14 8.75 14H9.25C9.5 14 9.75 13.7812 9.75 13.5V3H11.125C11.3125 3 11.4688 2.84375 11.4688 2.625C11.4688 2.53125 11.4375 2.4375 11.375 2.375Z"
      fill="#313131"
    />
  </svg>
);

type Props = { active?: boolean; onClick?: () => void } & Partial<CustomIconComponentProps>;
const SortIcon = ({ active, ...rest }: Props) => (
  <Icon
    className={`${active && '[&>svg>path]:fill-emerald-light-green'}`}
    component={SortSVG}
    {...rest}
  />
);

export default SortIcon;
