import { saveAs } from 'file-saver';
import axiosInstance from '../config/axios/analytics';
import { buildQueryString } from '../utils/string';
import { getAccountId } from '../utils/getAccountId';

export const getAverageRatingAnalytics = async (inboxId: string, params: Record<string, any>) => {
  const accountId = getAccountId();
  const response = await axiosInstance.get(
    `accounts/${accountId}/inboxes/${inboxId}/ratings?${buildQueryString(params)}`
  );
  return response.data;
};

export const getAverageResponseAnalytics = async (inboxId: string, params: Record<string, any>) => {
  const accountId = getAccountId();
  const response = await axiosInstance.get(
    `accounts/${accountId}/inboxes/${inboxId}/responses?${buildQueryString(params)}`
  );
  return response.data;
};

export const getRiwayatPercakapanAnalytics = async (
  inboxId: string,
  params: Record<string, any>
) => {
  const accountId = getAccountId();
  const response = await axiosInstance.get(
    `accounts/${accountId}/inboxes/${inboxId}/conversations/summary?${buildQueryString(params)}`
  );
  return response.data;
};

export const getCustomerActivityAnalytics = async (
  inboxId: string,
  params: Record<string, any>
) => {
  const accountId = getAccountId();
  const response = await axiosInstance.get(
    `accounts/${accountId}/inboxes/${inboxId}/conversations/customer?${buildQueryString(params)}`
  );
  return response.data;
};

export const getAgentActivityAnalytics = async (inboxId: string, params: Record<string, any>) => {
  const accountId = getAccountId();
  const response = await axiosInstance.get(
    `accounts/${accountId}/inboxes/${inboxId}/conversations/agent?${buildQueryString(params)}`
  );

  return response.data;
};

export const getBusyHourAnalytics = async (inboxId: string, params: Record<string, any>) => {
  const accountId = getAccountId();
  const response = await axiosInstance.get(
    `accounts/${accountId}/inboxes/${inboxId}/conversations/busy?${buildQueryString(params)}`
  );
  return response.data;
};

export const downloadAgentCustActivityAnalytics = async (
  inboxId: string,
  params: Record<string, any>,
  fileName: string
) => {
  const accountId = getAccountId();

  const response = await axiosInstance.get(
    `accounts/${accountId}/inboxes/${inboxId}/report/download?${buildQueryString(params)}`,
    {
      responseType: 'blob'
    }
  );

  saveAs(response?.data, fileName);
};

export const getTotalConversationsAnalytics = async (
  inboxId: string,
  params: Record<string, any>
) => {
  const accountId = getAccountId();
  const response = await axiosInstance.get(
    `accounts/${accountId}/inboxes/${inboxId}/conversations/type?${buildQueryString(params)}`
  );
  return response.data;
};

export const getTopicSummaryByInbox = async (inboxId: string, params: Record<string, any>) => {
  const accountId = getAccountId();
  const response = await axiosInstance.get(
    `accounts/${accountId}/inboxes/${inboxId}/topic?${buildQueryString(params)}`
  );
  return response.data;
};

export const getTopicSummaryByCustomer = async (inboxId: string, params: Record<string, any>) => {
  const accountId = getAccountId();
  const response = await axiosInstance.get(
    `accounts/${accountId}/inboxes/${inboxId}/topic/customer?${buildQueryString(params)}`
  );
  return response.data;
};

export const getConversationDurationByCustomer = async (
  inboxId: string,
  params: Record<string, any>
) => {
  const accountId = getAccountId();
  const response = await axiosInstance.get(
    `accounts/${accountId}/inboxes/${inboxId}/conversations/duration?${buildQueryString(params)}`
  );
  return response.data;
};

export const getConversationDurationByCustomerDetail = async (
  inboxId: string,
  params: Record<string, any>
) => {
  const accountId = getAccountId();
  const response = await axiosInstance.get(
    `accounts/${accountId}/inboxes/${inboxId}/conversations/duration/detail?${buildQueryString(
      params
    )}`
  );
  return response.data;
};
