import { Avatar } from 'antd';

import { getNameAlias } from '../../../../../utils/user';
import { AgentListItemInterface } from '../../../../../shared/types/agent.interface';
import { Icon } from '@squantumengine/horizon';

type ChipAgentProps = {
  onClick: () => void;
  agent: AgentListItemInterface;
  showCloseIcon?: boolean;
};

const ChipAgent = ({ onClick, agent, showCloseIcon }: ChipAgentProps) => {
  return (
    <div
      data-testid={`button-chip-agent-${agent.agentId}`}
      onClick={onClick}
      className="relative mb-2 flex cursor-pointer flex-row items-center gap-2 rounded-full border border-solid border-[#D2D2D2] px-[12px] py-[5px] hover:bg-blue-50">
      <Avatar className="h-[30px] w-[30px] flex-none uppercase">
        {getNameAlias(agent.firstName, agent.lastName)}
      </Avatar>
      <div className="flex-grow">{[agent.firstName, agent.lastName].join(' ')}</div>
      {showCloseIcon && (
        <span className="flex-none font-thin">
          <Icon name="close-large" size="md" />
        </span>
      )}
    </div>
  );
};

export default ChipAgent;
