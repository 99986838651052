import axiosInstance from '../config/axios';
import { getUserJSON } from '../utils/user-json';
import { VBGInterface } from '../shared/types/upload.interface';

export const uploadVBG: (accountId: string, file: any) => Promise<any> = async (
  accountId,
  file
) => {
  const formData = new FormData();
  formData.append('files', file);

  try {
    const response = await axiosInstance.post(
      `/account/${accountId}/media/virtualBackground`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

export const getVBG: (accountId: string) => Promise<VBGInterface> = async (accountId) => {
  const response = await axiosInstance.get(`account/${accountId}/media/virtualBackground`);
  return response?.data;
};

export const postDocument = async (file: any) => {
  const formData = new FormData();
  formData.append('files', file);

  try {
    const response = await axiosInstance.post(`agent/document`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response?.data;
  } catch (error) {
    return error;
  }
};

export const uploadFileByAgent = async (file: any) => {
  const formData = new FormData();
  formData.append('files', file);

  try {
    const response = await axiosInstance.post('/agent/document', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response?.data;
  } catch (error) {
    return error;
  }
};

export const getDocsByConversationId = async (conversationId: string) => {
  const user = getUserJSON();
  const accountId = user?.account?.accountId || '';
  try {
    const response = await axiosInstance.get(
      `account/${accountId}/conversation/${conversationId}/documents`
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};
