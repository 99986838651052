import { useEffect, useState } from 'react';

export const useTimer = (startSecond: number = 0) => {
  const [second, setSecond] = useState<number>(startSecond);

  useEffect(() => {
    setSecond(startSecond);

    const interval = setInterval(() => {
      setSecond((prev) => prev + 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [startSecond]);

  return { second };
};
