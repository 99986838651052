/* eslint-disable no-console */
import { Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import { getUrlParam } from '../../utils/url-search-params';
import { validateResetPasswordNATIVE } from '../../api/native-login';
import useMount from '../../hooks/useMounth';
import FormNewPassword from '../../components/login/form-new-password';

function ResetPassword() {
  const navigate = useNavigate();

  const validateResetLink = async () => {
    const uuid = getUrlParam('uuid');

    if (!uuid) return;

    const response = await validateResetPasswordNATIVE({
      uuid
    });

    if (response?.error) {
      navigate('/reset-link-expired', { replace: true, state: { userEmail: uuid } });
      return;
    }
  };

  useMount(() => {
    validateResetLink();
  });

  return (
    <Row
      className="login-form flex min-h-screen flex-col bg-alice-blue"
      justify="center"
      align="middle">
      <FormNewPassword />
    </Row>
  );
}

export default ResetPassword;
