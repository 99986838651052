import axiosInstance from '../config/axios';

import { AccountInterface } from '../shared/types/account.interface';
import { CreateAccountParam } from './param.interface';

interface AccountListResponse {
  data: AccountInterface[];
}

export const fetchAccountList: () => Promise<AccountListResponse> = async () => {
  const response = await axiosInstance.get('account');
  return response;
};

export const fetchAccountDetail: (accountId: string) => Promise<AccountInterface> = async (
  accountId
) => {
  const response = await axiosInstance.get(`account/${accountId}`);
  return response?.data;
};

export const createAccount = async (data: CreateAccountParam) => {
  const response = await axiosInstance.post('account', data);
  return response;
};

export const updateAccount = async (accountId: string, data: CreateAccountParam) => {
  const response = await axiosInstance.put(`account/${accountId}`, data);
  return response;
};
