import { Col } from 'antd';
import CallList from '../../../components/v2/card/call-list';
import IncomingCall from '../../../components/v2/card/incoming-call';
import { ConversationInterface } from '../../../components/card/chat-message/chat-message.interface';
import { useConversationListStore } from '../../../store/conversation-list-state';
import React from 'react';

export const createIncomingCallComponents = (
  handleActiveCall: (data: any) => void,
  handleAssignAgent?: (conversation: ConversationInterface) => void,
  conversations: ConversationInterface[] | null = null,
  isAgent: boolean = false
) => {
  const curTime = Date.now();
  const targetList = isAgent ? 'assignedConversationList' : 'createdConversationList';
  let incomingCallList = useConversationListStore.getState()[targetList];

  if (!incomingCallList.length && !conversations) {
    return null;
  }
  if (conversations) {
    incomingCallList = conversations;
  }

  return incomingCallList
    .sort((firstElem, secondElem) => firstElem.time - secondElem.time)
    .map((data, key) => (
      <Col key={key} className="w-full">
        <IncomingCall
          data={data}
          handleActiveCall={handleActiveCall}
          handleAssignAgent={handleAssignAgent}
          startTimer={isAgent ? 0 : Math.round((curTime - data.time) / 1000)}
        />
      </Col>
    ));
};

export const createCallListComponent = (
  conversations: ConversationInterface[],
  handleAssignAgent?: (item: ConversationInterface) => void,
  refetch?: () => void
) => {
  return conversations.map((item, idx) => (
    <Col className="w-full" key={idx}>
      <CallList
        customerName={item.name}
        type={item.communicationType}
        data={item}
        handleAssignAgent={handleAssignAgent}
        refetch={refetch}
      />
    </Col>
  ));
};

export const createCallListAgentComponent = ({
  conversations,
  lastItemElementRef
}: {
  conversations: ConversationInterface[];
  lastItemElementRef?: React.Ref<HTMLDivElement>;
}) => {
  return conversations.map((item, idx) => {
    if (conversations.length === idx + 1) {
      return (
        <Col ref={lastItemElementRef} className="w-full" key={idx}>
          <CallList customerName={item.name} type={item.communicationType} data={item} />
        </Col>
      );
    }

    return (
      <Col className="w-full" key={idx}>
        <CallList customerName={item.name} type={item.communicationType} data={item} />
      </Col>
    );
  });
};
