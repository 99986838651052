import { ButtonInterface } from './rounded-button.interface';

function RoundedButton(button: ButtonInterface) {
  return (
    <div className="justify-center text-center">
      <button
        className={`mx-1 flex cursor-pointer items-center justify-center rounded-full transition-[width-height] ${
          button.color
        } h-12 w-12 ${button.border ? 'border-solid border-white' : 'border-none'}`}
        type="submit"
        onClick={button.onClick}>
        <img src={button.imageSrc} alt="camera" />
      </button>
      <span className="mt-2 text-[11px]">{button.label}</span>
    </div>
  );
}

export default RoundedButton;
