/**
 * Extracts the phone number without the country code.
 *
 * @param phoneNumberWithCountryCode - The full phone number including the country code.
 * @returns The phone number without the country code.
 */
export const extractPhoneNumber = (phoneNumberWithCountryCode: string): string => {
  // Define the regex pattern to match the country code
  const countryCodePattern = /^\+?\d{1,2}/;

  // Replace the country code with an empty string to extract the phone number
  const phoneNumber = phoneNumberWithCountryCode?.replace(countryCodePattern, '').trim();

  return phoneNumber;
};

/**
 * Removes the leading zero from the first character of a number if it exists.
 *
 * @param numberString - The string representation of the number.
 * @returns The number string without a leading zero.
 */
export const removeLeadingZero = (numberString: string): string => {
  // Define the regex pattern to match a leading zero at the beginning of the string
  const leadingZeroPattern = /^0+(?!$)/;

  // Replace the leading zero with an empty string
  const cleanedNumber = numberString?.replace(leadingZeroPattern, '');

  return cleanedNumber;
};
