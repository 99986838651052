/**
 * Function to help get first letter of first name and last name.
 *
 * @param {string} firstName - First name.
 * @param {string} lastName - Last name.
 * @returns {string} Paul Walker => PW
 */
export const getNameAlias = (firstName: string, lastName: string) => {
  let alias = '';

  if (firstName) {
    alias = alias + firstName.charAt(0);
  }

  if (lastName) {
    alias = alias + lastName.charAt(0);
  }

  return alias;
};
