import { useState } from 'react';
import { Col, Row } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import { FormActionEnum } from '../../../static/team';
import { useTeamStore } from '../../../store/team-state';
import './inbox.css';
import { InboxConfigEnum, inboxConfigList } from '../../../static/inbox-config';
import TabFilter from '../../filter/tab-filter';
import WorkHourForm from '../work-hour';
import InboxDetailsForm from './inbox-details-form';

function InboxForm() {
  const { formAction, setIsOpenForm, resetTeamActiveHandler } = useTeamStore();
  const [selectedTab, setSelectedTab] = useState<string>(InboxConfigEnum.DETAIL);
  const [disbaleNextTab, setDisableNextTab] = useState<boolean>(false);

  const closeForm = () => {
    setIsOpenForm(false);
    resetTeamActiveHandler();
  };

  return (
    <>
      <Row id="inbox_form" className="rounded-xl bg-white p-6 pb-0">
        <Col span={24}>
          <Row justify="space-between" className="mb-4">
            <div className="text-2xl font-semibold">{`${
              selectedTab === InboxConfigEnum.DETAIL
                ? `${formAction === FormActionEnum.CREATE ? 'Buat Tim Baru' : 'Ubah Tim'}`
                : selectedTab === InboxConfigEnum.SETTING
                ? 'Pengaturan'
                : 'Jam Layanan'
            }`}</div>
            <CloseOutlined className="cursor-pointer" onClick={() => closeForm()} />
          </Row>
          <Row className="mb-3">
            <TabFilter
              disabled={disbaleNextTab && formAction === FormActionEnum.CREATE}
              fullWidth
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              Tablist={inboxConfigList}
              compact
            />
          </Row>
          <Row>
            {selectedTab === InboxConfigEnum.SCHEDULE && <WorkHourForm />}
            <InboxDetailsForm
              setDisableNextTab={setDisableNextTab}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default InboxForm;
