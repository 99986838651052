import { bsimClientConfig } from '../config/clients/bsim';
import { defaultClientConfig } from '../config/clients/default';
import { LocalStorageKey } from '../static/local-storage';
import { ClientConfig } from '../types/clientConfig';
import { bsimClient } from '../types/enums/clients';
import { local } from './storage';

export const getClientConfig = (): ClientConfig => {
  const clientCode = local.getItem(LocalStorageKey.SQECC_CLIENT_CODE);

  const isBSIM = bsimClient.findIndex((item) => item === clientCode) > -1;

  if (isBSIM) return bsimClientConfig;

  return defaultClientConfig;
};
