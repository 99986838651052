import request from '../utils/request';

export const getSQEMPTemplates = async (accountId: string) => {
  const response = await request.get(`/account/${accountId}/config/messages?page=1&limit=100`);
  return response.data;
};

export const getSQMPPrecodings = async (accountId: string) => {
  const response = await request.get(
    `/account/${accountId}/config/messages/attributes?page=1&limit=100`
  );
  return response.data;
};
