export const monthNames = [
  'Januari',
  'Februari',
  'Maret',
  'April',
  'Mei',
  'Juni',
  'Juli',
  'Agustus',
  'September',
  'Oktober',
  'November',
  'Desember'
];

export const monthNamesShort = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'Mei',
  'Jun',
  'Jul',
  'Agu',
  'Sep',
  'Okt',
  'Nov',
  'Des'
];

export const dayNames = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'];

// this function requires string input with format "dd/mm/yyyy"
export function separateDate(dateString: string) {
  const dateParts = dateString.split('/');
  const dayString = dateParts[0];
  const monthString = monthNamesShort[(parseInt(dateParts[1], 10) - 1)] ;
  const yearString = dateParts[2];
  return { dayString, monthString, yearString };
}

export function getMonthNumber(monthName: string) {
  const index = monthNamesShort.indexOf(monthName);
  if (index !== -1) {
    return index + 1;
  }
  return null;
}
