import axios from 'axios';
import { isDatadogLogEnabled, sendLogError, sendLogInfo } from '../datadog';
import HttpStatusCode from '../../types/enums/request';
import { local } from '../../utils/storage';
import { LocalStorageKey } from '../../static/local-storage';
import { forceLogOut } from '../../utils/login';

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_SQEID_API_URL}/v1/`,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
});

axiosInstance?.interceptors.response.use(
  (response: any) => {
    if (isDatadogLogEnabled) {
      sendLogInfo(`[${response?.status}] ${JSON.stringify(response?.data)}`, {
        host: window.location.hostname,
        path: window.location.pathname,
        api_path: response?.config?.url,
        method: response?.config?.method,
        status_code: response?.status
      });
    }
    return response;
  },
  (error: any) => {
    if (
      error.response?.data?.statusCode === HttpStatusCode.UNAUTHORIZED &&
      error.response?.data?.message === 'Either token is invalid or expired'
    ) {
      local.removeItem(LocalStorageKey.USER);
      local.removeItem(LocalStorageKey.ACCESS_TOKEN);
    }
    if (isDatadogLogEnabled) {
      sendLogError(`[${error.response.status}] ${JSON.stringify(error.response.data)}`, {
        host: window.location.hostname,
        path: window.location.pathname,
        api_path: error?.response?.config?.url,
        method: error?.response?.config?.method,
        status_code: error?.response?.status
      });
    }
    forceLogOut();
    return Promise.reject(error);
  }
);

if (isDatadogLogEnabled) {
  axiosInstance.interceptors.request.use((request) => {
    sendLogInfo(`[${request.method?.toUpperCase()}] ${request.url}`, {
      host: window.location.hostname,
      path: window.location.pathname,
      api_path: request?.url || '',
      method: request?.method || ''
    });
    return request;
  });
}

export const setAuthorizationHeader = (token: string) => {
  axiosInstance.defaults.headers.Authorization = `jwt ${token}`;
};

export default axiosInstance;
