import { useEffect, useRef, useState } from 'react';
import { Layout, Divider } from 'antd';
import { useNavigate } from 'react-router-dom';
import ErrorModal from '../../modal/error-modal';
import { useAuth } from '../../../hooks/useAuth';
import { useAppStore, useUserStatusStore } from '../../../store/user-state';
import { CurrentUserObjectInterface } from '../../../shared/types/user.interface';
import SignalStrengthIndicator from '../../signal-strength-indicator';
import { RoleEnum } from '../../../static/role';
import { useConversationListStore } from '../../../store/conversation-list-state';
import { receiveAgentStatus } from '../../../api';
import { getUserJSON } from '../../../utils/user-json';
import ChevronDownIcon from '../../../assets/icons/chevron-down';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { getAssetIcon, getIconUrl } from '../../../utils/getIconUrl';
import { LoginPlatform } from '../../../shared/types/auth.interface';
import { useAuthStore } from '../../../store/new/auth';
import StatusBox from '../../v2/card/status';

const { Header } = Layout;

function HeaderComponent() {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const { signalStrength } = useAppStore();
  const { assignedConversationList, activeConversationList } = useConversationListStore();
  const [openModal, setOpenModal] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [currentUser, setCurrentUser] = useState<CurrentUserObjectInterface | null>(null);
  const firstRender = useRef(true);
  const {
    isUserOnline,
    name: userName,
    imageUrl,
    setIsUserOnline,
    setImageUrl
  } = useUserStatusStore();
  const lowSignal = signalStrength < 2;
  const user = getUserJSON();
  const { authProvider } = useAuthStore();
  const isSIMASIDPlatform = authProvider === LoginPlatform.SIMAS_ID;

  const fetchAgentInfo = async () => {
    if (user.account && user.role === RoleEnum.AGENT) {
      const response = await receiveAgentStatus(user.account?.accountId);
      const firstName = response?.agent?.firstName;
      const lastName = response?.agent?.lastName;

      localStorage.setItem(
        'user',
        JSON.stringify({
          ...user,
          firstName,
          lastName,
          name: `${firstName} ${lastName}`,
          phone: response?.agent?.phone
        })
      );

      if (response?.agent?.profilePhoto) {
        const imageUrl = response.agent.profilePhoto.url;
        setImageUrl(imageUrl);
        const user = getUserJSON();
        localStorage.setItem(
          'user',
          JSON.stringify({
            ...user,
            image: imageUrl
          })
        );
      }
      if (response?.status.online) {
        setIsUserOnline(true);
      } else {
        setIsUserOnline(false);
      }
    }
  };

  useEffect(() => {
    if (firstRender) {
      firstRender.current = false;
      if (user) {
        if (user.role !== RoleEnum.ADMIN) {
          fetchAgentInfo();
        } else {
          setIsUserOnline(true);
        }
        setCurrentUser(user);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasActiveConversation =
    (assignedConversationList.length + activeConversationList.length > 0 || isUserOnline) &&
    user.role === RoleEnum.AGENT;

  const defaultImgProfile = isSIMASIDPlatform
    ? imageUrl
    : currentUser?.image
    ? imageUrl
    : getIconUrl('advisor-avatar.svg');

  return (
    <>
      <Header className="fixed top-0 z-[52] w-full bg-white px-6 shadow-[inset_0px_-1px_0px_rgba(0,0,0,0.25)]">
        <div className="inline-flex h-full w-full flex-row items-center justify-between">
          <div className="flex items-center text-3xl font-bold leading-[64px]">
            <img className="h-12" alt="sqecc-logo" src={getAssetIcon('sqecc-logo.svg')} />
          </div>
          <div className="flex items-center gap-2">
            <img
              className={`cursor-pointer rounded-full border-2 border-solid border-[#D2D2D2] object-cover ${
                currentUser?.image || 'bg-grey-10'
              }`}
              width={52}
              height={52}
              src={defaultImgProfile}
              alt="profile_pic"
              onClick={() => navigate('/dashboard/user')}
            />
            <div>
              <div className="flex gap-2 text-base">
                <p className="m-0 max-w-[195px] truncate font-semibold capitalize">{userName},</p>
                <p className="m-0 font-normal capitalize">{currentUser?.role?.toLowerCase()}</p>
              </div>
              <div className="flex gap-2 text-base">
                <StatusBox switchOnly />
                <div className="flex items-center justify-between">
                  <div className="pr-3">Koneksi:</div>
                  <span className="flex items-center justify-center gap-2 font-bold">
                    {lowSignal && <p className="m-0 text-orange-danger">Sangat Buruk</p>}
                    <SignalStrengthIndicator />
                  </span>
                </div>
              </div>
            </div>
            <button
              className="cursor-pointer border-none bg-transparent"
              onClick={() => setIsVisible(!isVisible)}>
              <ChevronDownIcon />
            </button>
          </div>
        </div>
      </Header>
      <ErrorModal modalState={openModal} setModalState={setOpenModal} />

      {/* logout modal */}
      {isVisible && (
        <div
          onClick={() => setIsVisible(false)}
          className="absolute left-0 top-0 z-10 h-screen w-screen "></div>
      )}
      <div
        id="logout-modal"
        className={`${
          isVisible ? 'translate-y-0' : '-translate-y-[500px]'
        } fixed right-4 top-16 z-[51] mt-2 flex h-fit w-[310px] flex-col gap-2 rounded-2xl border border-solid border-grey-50 bg-white p-4 shadow-lg transition-all duration-500 ease-in-out`}>
        <StatusBox />
        {lowSignal && (
          <span
            className="flex h-max flex-col gap-2 rounded-xl border border-solid border-orange-danger bg-red-imperial bg-opacity-10 p-4
            ">
            <div className="flex items-center justify-between">
              <div className="pr-3">Koneksi:</div>
              <span className="flex items-center justify-center gap-2 font-bold">
                <p className="m-0 font-normal text-orange-danger">Sangat Buruk</p>
                <SignalStrengthIndicator />
              </span>
            </div>
            <p className="m-0 font-semibold">Saran: ganti koneksi anda</p>
          </span>
        )}
        <Divider style={{ margin: '0' }} />

        <button
          disabled={hasActiveConversation}
          onClick={logout}
          className={` border-none bg-transparent text-center text-base font-semibold leading-normal  underline ${
            hasActiveConversation
              ? 'cursor-not-allowed text-grey-50'
              : 'cursor-pointer text-red-500'
          }`}>
          Keluar dari SQECC
        </button>
        {hasActiveConversation && (
          <span className="flex gap-2 text-red-imperial">
            <ExclamationCircleOutlined />
            <p className="text-[#677485]">
              Anda sedang berada di dalam percakapan dengan pelanggan.
            </p>
          </span>
        )}
      </div>
    </>
  );
}

export default HeaderComponent;
