import { create } from 'zustand';
import { RoleEnum } from '../static/role';
import {
  UserStatusStoreInterface,
  IdleTimeStoreInterface,
  MaxIdleTimeStoreInterface,
  AppStoreInterface
} from './user-state.interface';

export const useUserStatusStore = create<UserStatusStoreInterface>((set) => ({
  isUserOnline: true,
  userRole: null,
  name: '',
  imageUrl: '',
  setIsUserOnline: (isUserOnline: boolean) => set({ isUserOnline }),
  setUserRole: (userRole: RoleEnum) => set({ userRole }),
  setName: (userName: string) => set({ name: userName }),
  setImageUrl: (imageUrl: string) => set({ imageUrl })
}));

export const useIdleTimeStore = create<IdleTimeStoreInterface>((set) => ({
  idleTime: 0,
  incrementIdleTime: () => set((state) => ({ idleTime: state.idleTime + 1 })),
  resetIdleTime: () => set({ idleTime: 0 })
}));

export const useMaxIdleTimeStore = create<MaxIdleTimeStoreInterface>((set) => ({
  maxIdleTime: 1,
  setMaxIdleTime: (maxIdleTime: number) => set({ maxIdleTime })
}));

export const useAppStore = create<AppStoreInterface>((set) => ({
  signalStrength: 11,
  setSignalStrength: (signalStrength: number) => set({ signalStrength }),
  pingDate: Math.ceil(Date.now() / 1000 - 20),
  setPingDate: (value: number) => set({ pingDate: value }),
  heartBeat: Math.ceil(Date.now() / 1000 - 20),
  setHeartBeat: (value: number) => set({ heartBeat: value }),
  startHeartBeat: false,
  setStartHeartBeat: (value: boolean) => set({ startHeartBeat: value })
}));
