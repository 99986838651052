import { ConfigProvider } from 'antd';
import './App.css';

import Router from './Router';
import { themeConfig } from './antd.theme';

import '@squantumengine/horizon/lib/index.css';
import { useLocation } from 'react-router-dom';

function App() {
  const location = useLocation();
  const v2Path = location.pathname.startsWith('/v2');
  const theme = v2Path ? themeConfig.v2 : themeConfig.default;
  return (
    <ConfigProvider theme={theme}>
      <Router />
    </ConfigProvider>
  );
}

export default App;
