import KYCActivityBarTooltip from './kyc-bar-tootltip';
import KYCActivityStepTooltip from './kyc-step-tooltip';
import { useState } from 'react';
import { N_X_AXIS, N_Y_AXIS } from '../../../../static/analysis';
import KYCActivityBarChart from './kyc-bar-chart';
import KYCActivityFooter from './kyc-footer';
import KYCActivityStepChart from './kyc-step-chart';
import { useParams } from 'react-router-dom';
import { useGetFunnelKYC } from '../../../../api/hooks/kyc/useGetFunnelKYC';
import { FunnelHover } from '../../../../components/kyc/kyc.interface';

type Props = { filterValues: Record<string, any> };

function ActivityKYCAnalytic({ filterValues }: Props) {
  const [hover, setHover] = useState<FunnelHover>({
    type: 'avg-verif',
    isShown: false,
    totalDrop: 0
  });

  const { startDate, endDate } = filterValues;
  const { inboxId } = useParams();

  const startDateISO = startDate.toISOString();
  const endDateISO = endDate.toISOString();

  const { data } = useGetFunnelKYC(inboxId || '', startDateISO, endDateISO);

  const xAxis = Array.from(Array(N_X_AXIS).keys());
  const yAxis = Array.from(Array(N_Y_AXIS).keys());

  const isBarHover = hover.type === 'approve' && hover.isShown;
  const isStepHover = hover.type !== 'approve' && hover.isShown;
  const isShownAvgWait = hover.type === 'avg-wait';

  if (!data) return null;

  const { averageVerificationTime, averageWaitTime, queue, approved, inProgress, resolved } = data;

  const MAX_VAL = Math.max(queue, approved, inProgress, resolved);
  const HEIGHT_Y_AXIS = (MAX_VAL / 20) * 100;

  const rejected = resolved - approved;

  const kycData = [
    { label: 'Antrean KYC', count: queue, rejected, acc: queue },
    { label: 'KYC Berlangsung', count: inProgress, rejected, acc: inProgress },
    { label: 'KYC Selesai', count: resolved, rejected, acc: resolved },
    { label: 'Disetujui', count: approved, rejected, acc: approved }
  ];

  return (
    <div className="flex w-full flex-col gap-4 rounded-xl border border-solid border-black p-6">
      <h1 className="m-0 mb-4 p-0 text-2xl font-semibold">Aktivitas Verifikasi</h1>
      <div className="flex h-[24rem] w-full items-end">
        <div className="relative flex w-full flex-row items-end justify-end">
          <div className="relative flex w-11/12 flex-row items-end justify-end">
            {xAxis.map((index) => {
              return index % 2 === 0 ? (
                <KYCActivityBarChart
                  data={kycData}
                  indexItem={index}
                  maxVal={MAX_VAL}
                  key={index}
                  items={xAxis}
                />
              ) : (
                <KYCActivityStepChart
                  data={kycData}
                  indexItem={index}
                  setHover={setHover}
                  maxVal={MAX_VAL}
                  key={index}
                />
              );
            })}
          </div>

          <div className="absolute bottom-0 left-0 right-0 z-10 flex h-[20rem] w-full flex-col justify-between">
            {yAxis.reverse().map((index) => {
              return (
                <div
                  key={Math.random()}
                  style={{ height: `${index === 4 ? 0 : (MAX_VAL / HEIGHT_Y_AXIS) * 100}rem` }}
                  className="flex flex-row items-end gap-4">
                  <p className="m-0 p-0 text-xl font-semibold leading-none">
                    {Math.round((MAX_VAL / (N_Y_AXIS - 1)) * index)}
                  </p>
                  <hr
                    className="m-0 h-[1px] w-full border-none"
                    style={{
                      background:
                        'repeating-linear-gradient(120deg,#D2D2D2,#D2D2D2 6px,transparent 6px,transparent 12px)'
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
        {isBarHover && (
          <KYCActivityBarTooltip approved={approved} rejected={rejected} total={resolved} />
        )}
        {isStepHover && (
          <KYCActivityStepTooltip
            averageTime={isShownAvgWait ? averageWaitTime : averageVerificationTime}
            totalDrop={hover.totalDrop}
            label={`AVG waktu ${isShownAvgWait ? 'tunggu' : 'verifikasi'}`}
          />
        )}
      </div>
      <div className="flex w-1/2 flex-row items-center gap-4">
        <KYCActivityFooter className="bg-yellow-200" text="Konversi KYC" />
        <KYCActivityFooter className="bg-red-200" text="Ditolak" />
      </div>
    </div>
  );
}

export default ActivityKYCAnalytic;
