import { Flex } from 'antd';
import { useState } from 'react';
import { ChatFooterSummaryEnum } from '../../../../static/tab-list';
import { Paragraph } from '@squantumengine/horizon';
import { getIconUrl } from '../../../../utils/getIconUrl';
import useCCToaster from '../../../../hooks/useCCToaster';
import FormChatFooter from './form-chat-footer';

export default function Summary() {
  const [selectedMenus, setSelectedMenus] = useState<ChatFooterSummaryEnum>(
    ChatFooterSummaryEnum.TOPIC
  );
  const { contextHolder, openNotification } = useCCToaster({ timeout: 3 });

  return (
    <Flex vertical className="w-full px-4 py-2">
      {contextHolder}
      {selectedMenus === ChatFooterSummaryEnum.TOPIC ? (
        <FormChatFooter setSelectedMenus={setSelectedMenus} openNotification={openNotification} />
      ) : (
        <Flex vertical align="center">
          <img src={getIconUrl('check-splash-blue.svg')} alt="check-splash" className="w-16" />
          <Paragraph size="xl" className="m-0 p-0 font-semibold">
            Data berhasil disimpan
          </Paragraph>
          <Paragraph className="m-0 p-0">Terima kasih telah melengkapi data pelanggan</Paragraph>
        </Flex>
      )}
    </Flex>
  );
}
