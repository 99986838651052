import { Divider, Flex, Layout, Typography } from 'antd';
import {
  ArrowLeftOutlined,
  InstagramOutlined,
  LinkedinOutlined,
  WhatsAppOutlined
} from '@ant-design/icons';
import { PrivacyPolicyEnum, menus, privacyPolicies } from '../static/privacy-policies';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAssetIcon, getIconUrl } from '../utils/getIconUrl';

const { Header, Footer, Content } = Layout;
const { Text } = Typography;

const PrivacyPoliciesPage = () => {
  const [selected, setSelected] = useState<number>(0);
  const navigate = useNavigate();

  return (
    <Layout className="min-h-screen w-full bg-blue-50">
      <Header className="flex flex-row items-center justify-between bg-white px-32 py-10 shadow-lg">
        <img
          className="h-20 cursor-pointer"
          onClick={() => navigate('login')}
          alt="sqecc-logo"
          src={getAssetIcon('sqecc-logo.svg')}
        />
        <Flex align="center" gap="middle">
          <img
            className="cursor-pointer rounded-full border-2 border-solid border-grey-50 object-cover"
            width={52}
            height={52}
            src={getIconUrl('advisor-avatar.svg')}
            alt="profile_pic_modal"
          />
          <Text
            className="cursor-pointer text-lg font-bold text-green-400"
            onClick={() => navigate('login')}>
            Login
          </Text>
        </Flex>
      </Header>
      <Content className="flex flex-row items-start space-x-8 px-32 py-10">
        <Flex vertical className="w-2/12 rounded-2xl bg-white p-8">
          {menus.map((menu: string, index: number) => {
            return (
              <>
                <Text
                  onClick={() => setSelected(index)}
                  className={`cursor-pointer text-lg font-semibold ${
                    menu === menus[selected] ? 'text-green-400' : 'text-neutral-400'
                  }`}>
                  {menu}
                </Text>
                {menu !== PrivacyPolicyEnum.TERMS_AND_CONDITIONS && <Divider />}
              </>
            );
          })}
        </Flex>
        <Flex vertical gap="middle" className="min-h-screen w-9/12 rounded-2xl bg-white p-8">
          <Flex align="center" gap="middle">
            <ArrowLeftOutlined
              onClick={() => navigate('login')}
              style={{
                fontSize: '1.5rem'
              }}
            />
            <Text className="cursor-pointer text-2xl font-bold">
              {privacyPolicies[selected].title}
            </Text>
          </Flex>
          {privacyPolicies[selected].content}
        </Flex>
      </Content>
      <Footer className="flex flex-row items-start justify-evenly bg-neutral-1000 py-20">
        <Flex vertical gap="middle" className="w-2/12">
          <img alt="sqe-logo" src={getAssetIcon('sqe-logo.svg')} />
          <Text className="text-xl font-bold text-white">The Plaza Office Tower Lt. 41</Text>
          <Text className="text-white">
            JL. M.H. Thamrin, Kav. 28-30, Gondangdia, Kec. Menteng, Kota Jakarta Pusat, Daerah
            Khusus Ibukota Jakarta 10350
          </Text>
          <Text className="mt-6 text-white">2023 © PT Semesta Quantum Eterniti</Text>
        </Flex>
        <Flex vertical gap="middle" className="w-2/12">
          <Text className="text-2xl font-bold text-white">Hubungi kami</Text>
          <Flex gap="middle">
            <WhatsAppOutlined style={{ fontSize: '1.5rem', color: 'white' }} />
            <Text className="text-lg text-white">+62882000711456</Text>
          </Flex>
          <Flex gap="middle">
            <LinkedinOutlined style={{ fontSize: '1.5rem', color: 'white' }} />
            <Text className="text-lg text-white">SQE (S-Quantum Engine)</Text>
          </Flex>
          <Flex gap="middle">
            <InstagramOutlined style={{ fontSize: '1.5rem', color: 'white' }} />
            <Text className="text-lg text-white">@lifeatsqe</Text>
          </Flex>
        </Flex>
        <Flex vertical gap="middle" className="w-2/12">
          <Text className="text-2xl font-bold text-white">Legal</Text>
          <Text className="text-lg text-white">Kebijakan</Text>
          <Text className="text-lg text-white">Privasi</Text>
        </Flex>
      </Footer>
    </Layout>
  );
};

export default PrivacyPoliciesPage;
