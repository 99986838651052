import { Avatar } from 'antd';
import { Icon, Paragraph } from '@squantumengine/horizon';

import { AgentListItemInterface } from '../../../../../shared/types/agent.interface';

import { getNameAlias } from '../../../../../utils/user';

type PopoverAgentDetailProps = {
  agent: AgentListItemInterface;
};

const PopoverAgentDetail = ({ agent }: PopoverAgentDetailProps) => {
  const agentDetailFormat = [
    { name: 'ID Pegawai', key: 'employeeId' },
    { name: 'Jabatan', key: 'role' },
    { name: 'Manager', key: 'manager' },
    { name: 'Unit Bisnis', key: 'account' }
  ];

  const renderDetailValue = (agent: AgentListItemInterface, key: keyof AgentListItemInterface) => {
    if (key === 'account') return agent.account.accountName || '-';
    if (key === 'role') return agent.role === 'AGENT' ? 'Agen' : agent.role;
    return agent[key] || '-';
  };

  return (
    <div>
      <div className="flex flex-row items-center gap-2">
        <Avatar className="h-[64px] w-[64px] flex-none uppercase">
          {getNameAlias(agent.firstName, agent.lastName)}
        </Avatar>
        <div>
          <Paragraph size="l" weight="semibold">
            {[agent.firstName, agent.lastName].join(' ')}
          </Paragraph>

          <div className="flex flex-row items-center">
            <Icon name="email" />
            <Paragraph size="r">{agent.email}</Paragraph>
          </div>

          <div className="flex flex-row items-center">
            <Icon name="phone" />
            <Paragraph size="r">{agent.phone}</Paragraph>
          </div>
        </div>
      </div>

      <div className="mt-4">
        {agentDetailFormat.map((detail) => (
          <div className="mt-[4px] grid grid-flow-col grid-cols-2 gap-4">
            <Paragraph size="r" className="text-neutral-600">
              {detail.name}
            </Paragraph>
            <Paragraph size="r" className="text-neutral-950">
              {renderDetailValue(agent, detail.key as keyof AgentListItemInterface)}
            </Paragraph>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PopoverAgentDetail;
