import { useState } from 'react';
import { Col, Row, Spin } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import { addInboxMember } from '../../../api';
import { useTeamStore } from '../../../store/team-state';
import { AgentListItemInterface } from '../../../shared/types/agent.interface';

function UserForm() {
  const {
    selectedBU,
    selectedInbox,
    availableAgent,
    setIsOpenForm,
    fetchUserInbox,
    fetchAvailableAgentInbox,
    resetTeamActiveHandler
  } = useTeamStore();
  const [loading, setLoading] = useState<boolean>(false);

  const selectInboxMember = async (agentId: string) => {
    setLoading(true);
    try {
      await addInboxMember(selectedBU.accountId, selectedInbox.inboxId, agentId);
      await fetchUserInbox(selectedBU.accountId, selectedInbox.inboxId);
      await fetchAvailableAgentInbox(selectedBU.accountId, selectedInbox.inboxId);
    } catch (error: any) {
      // eslint-disable-next-line no-console
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const closeForm = () => {
    setIsOpenForm(false);
    resetTeamActiveHandler();
  };

  return (
    <>
      <Spin spinning={loading}>
        <Row id="user_form" className="rounded-xl bg-white p-6 pb-0">
          <Col span={24}>
            <Row justify="space-between" className="mb-4">
              <div className="text-2xl font-semibold">Pilih User</div>
              <CloseOutlined className="cursor-pointer" onClick={() => closeForm()} />
            </Row>
            <div className="my-6">
              {availableAgent.length === 0 && (
                <div className="text-center text-lg font-semibold text-grey-100">
                  Tidak ada agen yang bisa dipilih
                </div>
              )}
              <Row gutter={[16, 16]}>
                {availableAgent.map((user: AgentListItemInterface) => {
                  return (
                    <Col
                      key={user.agentId}
                      span={12}
                      onClick={() => selectInboxMember(user.agentId)}>
                      <div
                        className="click-animation truncate rounded-md border
                          border-solid border-grey-80 px-4 py-2 text-base text-black-90">
                        <p className="m-0 truncate p-0 font-semibold">
                          {user.firstName} {user.lastName}
                        </p>
                        <p className="m-0 truncate p-0">{user.employeeId}</p>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </div>
          </Col>
        </Row>
      </Spin>
    </>
  );
}

export default UserForm;
