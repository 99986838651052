import { useState, useEffect, ChangeEvent } from 'react';
import { Col, Row, Spin, message } from 'antd';

import { useTeamStore } from '../../store/team-state';
import { CurrentUserObjectInterface } from '../../shared/types/user.interface';
import { FormRenderer } from './team-form-renderer';

import InboxList from '../../components/card/list-inbox';
import { RoleEnum } from '../../static/role';
import { uploadVBG } from '../../api';
import { getVBG } from '../../api/upload';
import { getIconUrl } from '../../utils/getIconUrl';
import { ImgTypeEnum } from '../../static/dashboard';

function SPVTeamPage() {
  const {
    isOpenForm,
    formType,
    formAction,
    fetchInbox,
    fetchUserInbox,
    setIsOpenForm,
    fetchDetailBU
  } = useTeamStore();

  const [messageApi, contextHolder] = message.useMessage();

  const [loading, setLoading] = useState<boolean>(false);
  const [accountId, setAccountId] = useState<string>('');
  const [urlVBG, setUrlVBG] = useState<string>('');

  const initInbox = async (accId: string) => {
    await fetchInbox(accId);
    const inboxStore = useTeamStore.getState().inbox;
    if (!!inboxStore.length) {
      const inboxIndex = inboxStore.findIndex((inboxItem) => inboxItem.accountId === accId);
      const inboxItemList = inboxStore[inboxIndex]?.inboxList;

      if (inboxItemList) {
        for (let i = 0; i < inboxItemList.length; i++) {
          await fetchUserInbox(accId, inboxItemList[i].inboxId);
        }
      }
    }
    setLoading(false);
  };

  const fetchVBG = async () => {
    const defaultBgUrl = getIconUrl('default-vbg.svg');
    try {
      if (accountId) {
        const response = await getVBG(accountId);
        const documents = response?.documents;
        const bgUrl = documents[documents.length - 1];

        if (documents?.length > 0) {
          setUrlVBG(bgUrl?.url);
        } else {
          setUrlVBG(defaultBgUrl);
        }
      }
    } catch (error) {
      setUrlVBG(defaultBgUrl);
    }
  };

  useEffect(() => {
    setLoading(true);
    const currUser: CurrentUserObjectInterface = JSON.parse(localStorage.getItem('user') || '{}');
    setAccountId(currUser.account?.accountId || '');

    initInbox(currUser.account?.accountId || '');
    fetchDetailBU(currUser.account?.accountId || '');
    setIsOpenForm(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchVBG();
    // eslint-disable-next-line
  }, [accountId]);

  const handleFileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const { files } = e.target;
    const selectedFiles = files as FileList;
    const isImgTypeAllowed =
      selectedFiles[0].type === ImgTypeEnum.JPG || selectedFiles[0].type === ImgTypeEnum.JPEG;

    if (!isImgTypeAllowed) {
      messageApi.error({
        content: 'Only jpg/jpeg image format allowed'
      });
    } else {
      try {
        messageApi.loading({
          content: 'Uploading an image...',
          duration: 1
        });

        const response = await uploadVBG(accountId, selectedFiles?.[0]);
        messageApi.info({
          content: 'Image uploaded successfully'
        });
        setUrlVBG(response?.url);
      } catch (error) {
        messageApi.error({
          content: 'Something went wrong'
        });
      }
    }
  };

  return (
    <>
      {contextHolder}
      <Row className="h-full bg-white">
        <Col span={16} className="max-h-[calc(100vh-64px)] overflow-y-auto overflow-x-hidden">
          <Spin spinning={loading}>
            <Row className="p-6">
              <Col id={`inbox_list-${accountId}`} span={24}>
                <InboxList accountId={accountId} />
              </Col>
            </Row>
          </Spin>
          <Row className="p-6 pt-0">
            <Col span={24}>
              <p className="text-3xl font-semibold">Virtual Background</p>
              <div className={`relative h-[300px] w-[550px] rounded-3xl`}>
                <img
                  src={urlVBG}
                  className="absolute h-[300px] w-[550px] rounded-3xl"
                  alt="vbg-img"
                />
                <div className="absolute bottom-8 left-1/2 z-20 w-10/12 -translate-x-1/2 rounded-full bg-bold-green px-10 py-3 text-center hover:scale-110">
                  <input
                    type="file"
                    accept="image/jpg, image/jpeg"
                    id="files"
                    className="hidden"
                    onChange={(e) => handleFileUpload(e)}
                  />
                  <label
                    htmlFor="files"
                    className="cursor-pointer text-lg font-semibold text-white">
                    Ganti Background
                  </label>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col
          span={8}
          className="h-screen max-h-[calc(100vh-64px)] overflow-y-auto overflow-x-hidden bg-grey-10">
          <div className="p-6">
            <div>{FormRenderer(isOpenForm, formAction, formType, RoleEnum.SUPERVISOR)}</div>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default SPVTeamPage;
