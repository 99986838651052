import axiosInstance from '../config/axios';
import { buildQueryString } from '../utils/string';

export const fetchAuditTrail = async (accountId: string, params: Record<string, any>) => {
  const response = await axiosInstance.get(
    `/account/${accountId}/audit?${buildQueryString(params)}`
  );
  return response.data;
};

export const downloadAuditTrail = async (accountId: string, params: Record<string, any>) => {
  const response = await axiosInstance.get(
    `/account/${accountId}/audit/download?${buildQueryString(params)}`,
    { responseType: 'blob' }
  );
  return response.data;
};
