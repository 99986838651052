import axios from 'axios';
import { useAuthStore } from '../../store/new/auth';

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_ANALYTICS_URL}/v1/`,
  headers: {
    'Content-Type': 'application/json'
  }
});

axiosInstance.interceptors.request.use((request) => {
  const authProvider = useAuthStore.getState().authProvider;
  request.headers.authenticationPlatform = authProvider;
  if (localStorage.getItem('accessToken')) {
    request.headers.Authorization = `jwt ${localStorage
      .getItem('accessToken')
      ?.replaceAll('"', '')}`;
  }
  return request;
});

export default axiosInstance;
