import { Paragraph } from '@squantumengine/horizon';
import { useTimer } from '../../../../hooks/useTimer';
import { DurationToString } from '../../../../utils/timestamp';

type QueueListItemProps = {
  customerName?: string;
  startTimer: number;
};

export default function QueueItem({ customerName, startTimer }: QueueListItemProps) {
  const { second } = useTimer(Math.round((Date.now() - startTimer) / 1000));
  return (
    <div className="flex flex-row items-center justify-between">
      <Paragraph className="truncate">{customerName}</Paragraph>
      <Paragraph className={second > 30 ? 'text-red-500' : 'text-neutral-600'}>
        {'Waktu tunggu '}
        {DurationToString(second)}
      </Paragraph>
    </div>
  );
}
