import { SHA256, enc } from 'crypto-js';
import { local } from './storage';
import { LocalStorageKey } from '../static/local-storage';

const sqeIdUrl = process.env.REACT_APP_SQEID_LOGIN_URL;
const clientId = encodeURIComponent(process.env.REACT_APP_SQEID_CLIENT_ID || '');

interface RandomStringGenerator {
  length?: number;
  useSpecialChar?: boolean;
}

export const generateRandomSecret = (
  params: RandomStringGenerator = {
    length: 64,
    useSpecialChar: true
  }
) => {
  let charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  charset = params.useSpecialChar ? `${charset}-._~` : charset;
  let codeVerifier = '';

  if (!params.length) return '';

  for (let i = 0; i < params.length; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    codeVerifier += charset[randomIndex];
  }

  return codeVerifier;
};

export const generateCodeChallenge = (codeVerifier: string) => {
  const hashedCodeVerifier = SHA256(codeVerifier);
  const codeChallenge = hashedCodeVerifier
    .toString(enc.Base64)
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '');

  return codeChallenge;
};

export const goToLogin = () => {
  const codeVerifier = generateRandomSecret();
  const codeChallenge = encodeURIComponent(generateCodeChallenge(codeVerifier));
  const state = encodeURIComponent(
    generateRandomSecret({
      length: 43,
      useSpecialChar: false
    })
  );
  const redirectUri = encodeURIComponent(process.env.REACT_APP_SQEID_REDIRECT_URI || '');
  const sqeIdAuthRoute = '/v2/oidc/authorize';

  local.setItem(LocalStorageKey.SQEID_OA_CODE_VERIFIER, codeVerifier);
  local.setItem(LocalStorageKey.SQEID_OA_STATE, state);

  window.location.href = `${sqeIdUrl}${sqeIdAuthRoute}?state=${state}&scope=openid+profile+email&response_type=code&code_challenge=${codeChallenge}&code_challenge_method=SHA256&client_id=${clientId}&redirect_uri=${redirectUri}&flow=login&type=1`;
};

export const goToInvUser = (token: string) => {
  window.open(`${sqeIdUrl}/internal/user/invite?t=${token}&client_id=${clientId}`, '_blank');
};

export const goToUpdateProfile = (token: string) => {
  window.open(`${sqeIdUrl}/internal/user/edit?t=${token}&client_id=${clientId}`, '_blank');
};
