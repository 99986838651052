
export const getUrlSearchParams = () => {
  return new URLSearchParams(window.location.search);
};

/**
 * Get url parameter value
 *
 * @param paramName - string param name
 * @return string | null
 */
export const getUrlParam = (paramName: string) => {
  return getUrlSearchParams().get(paramName);
};
