import React, { useMemo } from 'react';
import { Table as AntdTable, TableProps } from 'antd';
import SortIcon from '../../assets/icons/sort';

type Props = TableProps<any>;

const Table = ({ columns, ...rest }: Props) => {
  const _renderTitleSort = ({ sortColumns, name, title }: any) => {
    const sortedColumn = sortColumns?.find(({ column }: any) => column.dataIndex === name);
    const isActive = sortedColumn && sortedColumn.order;
    return (
      <div className="flex items-center justify-between">
        {title}
        <SortIcon active={isActive} />
      </div>
    );
  };

  const formattedColummns = useMemo(() => {
    return columns?.map((col) => {
      if (!col.sorter) return col;
      return {
        ...col,
        title: ({ sortColumns }: any) =>
          _renderTitleSort({ sortColumns, title: col.title, name: col.key })
      };
    });
  }, [columns]);

  return <AntdTable className="analytics-table" columns={formattedColummns} {...rest} />;
};

export default Table;
