import { FormInstance, Select, Form, Typography } from 'antd';
import { useChatListStore } from '../../../store/chat-list';
import { useGetReason } from '../../../api/hooks/inbox/useRejectReason';

function RejectReasonAgent(props: { form?: FormInstance<any>; isZoom?: boolean }) {
  const { Text } = Typography;
  const { form, isZoom } = props;

  const { selectedCall } = useChatListStore();
  const { data: dataReason } = useGetReason(selectedCall!?.inboxId!);

  const mappedReason = dataReason!?.map(({ uuid: value, reason: label, ...rest }) => ({
    value,
    label,
    ...rest
  }));

  return (
    <Form.Item
      name="reject-reason"
      className="inbox_reject_reason mb-0 w-full"
      label={<Text className="text-base font-semibold">Alasan Penolakan</Text>}>
      <Select
        dropdownStyle={isZoom ? { zoom: '75%' } : {}}
        placeholder="Pilih Alasan Penolakan"
        className="mb-4 w-full"
        options={mappedReason}
        onChange={(_, option: any) => {
          form?.setFieldValue('reject-reason', {
            label: option!?.label,
            value: option!?.value
          });
        }}
      />
    </Form.Item>
  );
}

export default RejectReasonAgent;
