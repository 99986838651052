import { Button, Icon, TextField } from '@squantumengine/horizon';
import { DatePicker, TimeRangePickerProps } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useRef, useState } from 'react';

type IDateRange = [start: dayjs.Dayjs, end: dayjs.Dayjs];
type DatePickerProps = {
  onChange: (values: IDateRange) => void;
  renderSelectedPreset?: (value: string) => React.ReactNode;
};
export const DEFAULT_DATE_RANGE: IDateRange = [
  dayjs().add(-6, 'd').startOf('day'),
  dayjs().endOf('day')
];
export const DEFAULT_RANGE_PRESETS: TimeRangePickerProps['presets'] = [
  { label: 'Hari ini', value: [dayjs(), dayjs()] },
  { label: '7 Hari Terakhir', value: [dayjs().add(-6, 'd'), dayjs()] },
  { label: '30 Hari Terakhir', value: [dayjs().add(-29, 'd'), dayjs()] },
  { label: '90 Hari Terakhir', value: [dayjs().add(-89, 'd'), dayjs()] },
  { label: 'Pilih Tanggal', value: [null, null] }
];

export default function DatePickerComponent({ onChange, renderSelectedPreset }: DatePickerProps) {
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedRange, setSelectedRange] =
    useState<[start: dayjs.Dayjs, end: dayjs.Dayjs]>(DEFAULT_DATE_RANGE);
  const [tempSelectedPreset, setTempSelectedPreset] = useState<string>(
    String(DEFAULT_RANGE_PRESETS?.[1].label)
  );
  const [selectedPreset, setSelectedPreset] = useState<string>(
    String(DEFAULT_RANGE_PRESETS?.[1].label)
  );

  const handleCancel = () => setShowCalendar(false);
  const handleOk = () => {
    setSelectedPreset(tempSelectedPreset);
    onChange(selectedRange);
    setShowCalendar(false);
  };
  const _renderExtraFooter = () => {
    return (
      <div className="flex items-center justify-end space-x-2 py-2">
        <Button variant="text" className="text-black" onClick={handleCancel}>
          Batalkan
        </Button>
        <Button onClick={handleOk}>Simpan</Button>
      </div>
    );
  };

  const disabledDate = (current: Dayjs, { from }: { from?: Dayjs }) => {
    if (from) {
      return Math.abs(current.diff(from, 'days')) >= 90;
    }
    return current && current > dayjs().endOf('day');
  };

  const hasMounted = useRef(false);
  useEffect(() => {
    const elAntdPreset = document.querySelector('.ant-picker-presets');
    if (elAntdPreset && showCalendar && !hasMounted.current) {
      const elPresetHeader = document.createElement('div');
      elAntdPreset.className = `${elAntdPreset.className} p-4 min-w-[200px]`;
      elPresetHeader.textContent = 'Pilih Tanggal';
      elPresetHeader.className = 'text-base font-semibold mb-3';
      elAntdPreset.prepend(elPresetHeader);
      hasMounted.current = true;
    }
  }, [showCalendar]);

  const renderPreset = () => {
    if (renderSelectedPreset) {
      return renderSelectedPreset(selectedPreset);
    }
    return (
      <TextField
        full
        placeholder={selectedPreset}
        suffix={<Icon name="calendar" />}
        onKeyDown={(e) => e.preventDefault()}
      />
    );
  };

  return (
    <>
      <div className="cursor-pointer" onClick={(_) => setShowCalendar(true)}>
        {renderPreset()}
      </div>
      <div className="h-0">
        <DatePicker.RangePicker
          presets={DEFAULT_RANGE_PRESETS?.map((preset) => ({
            label: (
              <div
                className={`min-w-40 px-5 py-2 font-semibold ${
                  tempSelectedPreset === preset.label && 'bg-blue-50 text-blue-500'
                }`}
                onClick={setTempSelectedPreset.bind(null, String(preset.label))}>
                {preset.label}
              </div>
            ),
            value: preset.value
          }))}
          open={showCalendar}
          className="z-10 h-0 opacity-0"
          value={selectedRange}
          renderExtraFooter={_renderExtraFooter}
          disabledDate={disabledDate}
          onChange={(dates) => setSelectedRange(dates as IDateRange)}
        />
      </div>
    </>
  );
}
