import { getUserJSON } from "../utils/user-json";

export const offlineDuration = [
  {
    text: '10 menit',
    value: 10
  },
  {
    text: '20 menit',
    value: 20
  },
  {
    text: '30 menit',
    value: 30
  }
];

export const getUserInitialData = () => {
  const userData = getUserJSON();
  
  return {
    fullName: userData.name,
    role: userData.role,
    agentId: userData.agentId,
    image: userData.image,
    phone: userData.phone
  };
};
