import axiosInstance from '../config/axios';

export const DeleteCall: (
  accountId: string,
  conversationId: string,
  messageId: string
) => Promise<void> = async (accountId, conversationId, messageId) => {
  try {
    await axiosInstance.delete(
      `account/${accountId}/conversation/${conversationId}/message/${messageId}`
    );
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const PreClosureConversation: (
  accountId: string,
  conversationId: string
) => Promise<void> = async (accountId, conversationId) => {
  try {
    await axiosInstance.post(`account/${accountId}/conversation/${conversationId}/pre-closure`);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const CloseConversation: (
  accountId: string,
  conversationId: string
) => Promise<void> = async (accountId, conversationId) => {
  try {
    await axiosInstance.post(`account/${accountId}/conversation/${conversationId}/resolve`);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const UpdateConversationStatus: (
  accountId: string,
  conversationId: string
) => Promise<void> = async (accountId, conversationId) => {
  try {
    await axiosInstance.post(`account/${accountId}/conversation/${conversationId}/seen-at`);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};
