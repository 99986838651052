export const conversationLimit = 10;

export enum ItemKey {
  EMAIL = 0,
  TICKET_NUMBER = 1,
  PHONE_NUMBER = 2,
  NAME = 3
}

export enum ItemInvUsrKey {
  EMAIL = 0,
  NAME = 1
}

export const items = [
  { label: 'Email Pelanggan ', key: ItemKey.EMAIL },
  { label: 'Nomor Tiket', key: ItemKey.TICKET_NUMBER },
  { label: 'Nomor Telepon', key: ItemKey.PHONE_NUMBER },
  { label: 'Nama Pelanggan', key: ItemKey.NAME }
];

export const itemsInvUsr = [
  { label: 'Email Pelanggan ', key: ItemInvUsrKey.EMAIL },
  { label: 'Nama', key: ItemInvUsrKey.NAME }
];
