import { ConfigProvider, Image, List } from 'antd';
import { useWorkspaceDocuments } from '../../../../api/hooks/workspace/useWorkspaceDocuments';
import { useChatListStore } from '../../../../store/chat-list';
import { getIconUrl } from '../../../../utils/getIconUrl';
import { Paragraph, Token } from '@squantumengine/horizon';

const CustomerFiles = () => {
  const { selectedCall } = useChatListStore();

  const { data: documentDetail, isLoading: loadingDocuments } = useWorkspaceDocuments(
    selectedCall?.conversationId || ''
  );

  const handlePreviewDocument = (url: string) => window.open(url, '_blank');

  return (
    <ConfigProvider
      theme={{
        components: {
          Pagination: {
            colorBgContainer: Token.COLORS.blue[500],
            borderRadius: 2,
            controlHeight: 24,
            fontSize: 14
          },
          List: {
            margin: 8
          }
        }
      }}>
      <List
        pagination={
          documentDetail?.documents?.length > 0 && {
            position: 'bottom',
            align: 'center',
            pageSize: 6
          }
        }
        dataSource={documentDetail?.documents}
        grid={{ column: 3, gutter: 8 }}
        loading={loadingDocuments}
        locale={{
          emptyText: (
            <Paragraph className="text-center text-neutral-400" size="l">
              Belum ada berkas
            </Paragraph>
          )
        }}
        renderItem={(item: any) => (
          <List.Item className="box-border overflow-hidden rounded-[8px] border border-solid border-neutral-300">
            <div className="flex items-center justify-between p-2">
              <Paragraph className="truncate" weight="semibold">
                {item?.documentName}
              </Paragraph>
            </div>
            <div
              className="overflow-hidden"
              onClick={() => handlePreviewDocument(item?.documentUrl)}>
              <Image
                className="h-[100px] w-full cursor-pointer object-cover"
                width={'100%'}
                preview={false}
                src={item?.documentUrl}
                alt={item?.documentName}
                fallback={getIconUrl('img-fallback.png')}
              />
            </div>
          </List.Item>
        )}
      />
    </ConfigProvider>
  );
};

export default CustomerFiles;
