import { withErrorHandlingAsync } from '../../utils/error-handling';
import axiosInstance from '../axios';
import { IChatMessageHistoryResponse } from '../../shared/types/chat.interface';

export const messageHistory: (
  conversationId: string,
  lastId?: string,
  download?: boolean
) => Promise<IChatMessageHistoryResponse> = async (conversationId, lastId?, download?) => {
  return await withErrorHandlingAsync(async () => {
    const accountId = JSON.parse(localStorage.user).account.accountId;
    const withLastId = lastId ? `last-id=${lastId}&upward=false` : 'upward=false';
    const withDownload = download ? `&download=${download}` : '';
    const response = await axiosInstance.get(
      `/account/${accountId}/conversation/${conversationId}/message?${withLastId}${withDownload}`
    );
    return response.data;
  });
};
