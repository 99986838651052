import type { MenuItemType } from 'antd/es/menu/hooks/useItems';

interface IMenuItemType extends Omit<MenuItemType, 'label'> {
  label: string;
}

interface OperationalHourInterface {
  openHour: number;
  openMinutes: number;
  closeHour: number;
  closeMinutes: number;
}

export const defaultOperationalHour: OperationalHourInterface = {
  openHour: 9,
  openMinutes: 0,
  closeHour: 17,
  closeMinutes: 0
};

export const dayOfWeeks = [
  {
    dayofWeek: 1,
    label: 'Senin'
  },
  {
    dayofWeek: 2,
    label: 'Selasa'
  },
  {
    dayofWeek: 3,
    label: 'Rabu'
  },
  {
    dayofWeek: 4,
    label: 'Kamis'
  },
  {
    dayofWeek: 5,
    label: 'Jumat'
  },
  {
    dayofWeek: 6,
    label: 'Sabtu'
  },
  {
    dayofWeek: 7,
    label: 'Minggu'
  }
];

export const hourSelection: IMenuItemType[] = [
  { key: '0', label: '00' },
  { key: '1', label: '01' },
  { key: '2', label: '02' },
  { key: '3', label: '03' },
  { key: '4', label: '04' },
  { key: '5', label: '05' },
  { key: '6', label: '06' },
  { key: '7', label: '07' },
  { key: '8', label: '08' },
  { key: '9', label: '09' },
  { key: '10', label: '10' },
  { key: '11', label: '11' },
  { key: '12', label: '12' },
  { key: '13', label: '13' },
  { key: '14', label: '14' },
  { key: '15', label: '15' },
  { key: '16', label: '16' },
  { key: '17', label: '17' },
  { key: '18', label: '18' },
  { key: '19', label: '19' },
  { key: '20', label: '20' },
  { key: '21', label: '21' },
  { key: '22', label: '22' },
  { key: '23', label: '23' },
  { key: '24', label: '24' }
];
export const minuteSelection: IMenuItemType[] = [
  { key: '0', label: '00' },
  { key: '1', label: '01' },
  { key: '2', label: '02' },
  { key: '3', label: '03' },
  { key: '4', label: '04' },
  { key: '5', label: '05' },
  { key: '6', label: '06' },
  { key: '7', label: '07' },
  { key: '8', label: '08' },
  { key: '9', label: '09' },
  { key: '10', label: '10' },
  { key: '11', label: '11' },
  { key: '12', label: '12' },
  { key: '13', label: '13' },
  { key: '14', label: '14' },
  { key: '15', label: '15' },
  { key: '16', label: '16' },
  { key: '17', label: '17' },
  { key: '18', label: '18' },
  { key: '19', label: '19' },
  { key: '20', label: '20' },
  { key: '21', label: '21' },
  { key: '22', label: '22' },
  { key: '23', label: '23' },
  { key: '24', label: '24' },
  { key: '25', label: '25' },
  { key: '26', label: '26' },
  { key: '27', label: '27' },
  { key: '28', label: '28' },
  { key: '29', label: '29' },
  { key: '30', label: '30' },
  { key: '31', label: '31' },
  { key: '32', label: '32' },
  { key: '33', label: '33' },
  { key: '34', label: '34' },
  { key: '35', label: '35' },
  { key: '36', label: '36' },
  { key: '37', label: '37' },
  { key: '38', label: '38' },
  { key: '39', label: '39' },
  { key: '40', label: '40' },
  { key: '41', label: '41' },
  { key: '42', label: '42' },
  { key: '43', label: '43' },
  { key: '44', label: '44' },
  { key: '45', label: '45' },
  { key: '46', label: '46' },
  { key: '47', label: '47' },
  { key: '48', label: '48' },
  { key: '49', label: '49' },
  { key: '50', label: '50' },
  { key: '51', label: '51' },
  { key: '52', label: '52' },
  { key: '53', label: '53' },
  { key: '54', label: '54' },
  { key: '55', label: '55' },
  { key: '56', label: '56' },
  { key: '57', label: '57' },
  { key: '58', label: '58' },
  { key: '59', label: '59' }
];
