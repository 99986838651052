import { TeamBUModalInterface } from '../team-users.interface';
import { Button, Col, Flex, Form, Modal, Row, Select, Typography } from 'antd';
import { useTeamStore } from '../../../store/team-state';

const TeamBUModal = ({
  isModalOpen,
  setIsModalOpen,
  inviteUser,
  setSelectedBU
}: TeamBUModalInterface) => {
  const [form] = Form.useForm();
  const { account } = useTeamStore();

  const handleInviteUser = () => {
    const organizationId = form.getFieldValue('organizationId');
    inviteUser(organizationId);
    setIsModalOpen(false);
  };

  const filterOption = (input: string, option?: any) => {
    return (option?.children ?? '').toLowerCase().includes(input.toLowerCase());
  };

  return (
    <Modal
      title={<Typography.Title className="m-0 text-lg font-bold">Undang User</Typography.Title>}
      closable={true}
      open={isModalOpen}
      onOk={() => setIsModalOpen(false)}
      onCancel={() => setIsModalOpen(false)}
      footer={
        <Flex gap="small" align="center" justify="end">
          <Button
            onClick={() => setIsModalOpen(false)}
            className={`h-10 w-4/12 rounded-lg text-base font-semibold`}>
            Batal
          </Button>
          <Button
            onClick={handleInviteUser}
            className={`h-10 w-4/12 rounded-lg border-none bg-green-500 text-base font-semibold text-white`}>
            Selanjutnya
          </Button>
        </Flex>
      }>
      <Form
        className="mt-4"
        name="select_bu"
        layout="vertical"
        form={form}
        autoComplete="off"
        requiredMark={false}
        size="large">
        <Row gutter={16}>
          <Col className="gutter-row" span={24}>
            <Form.Item
              name="organizationId"
              required
              label={
                <Typography.Title className="m-0 text-base font-semibold">
                  Business Unit
                </Typography.Title>
              }>
              <Select
                onChange={(value, option) =>
                  setSelectedBU!({
                    key: value,
                    label: option?.children
                  })
                }
                showSearch
                placeholder="Pilih"
                filterOption={filterOption}>
                {account.accountList?.map((option) => (
                  <Select.Option key={option.accountId} value={option.accountId}>
                    {option.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default TeamBUModal;
