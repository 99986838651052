import { useMutation } from 'react-query';
import axiosInstance from '../../../config/axios';

export interface UpdateAgentCapacityInterface {
  agentCapacity: {
    maxChatCustomers: string;
    maxCallCustomers: string;
  };
  agentId: string;
  accountId: string;
  isProfileCompleted: boolean;
}

export const updateCapacityNATIVE: (
  payload: UpdateAgentCapacityInterface
) => Promise<any[]> = async (payload) => {
  const { agentCapacity, agentId, accountId, isProfileCompleted } = payload;

  try {
    const url = `/account/${accountId}/agent`;
    const data = { agentCapacity, agentId };
    let response;

    if (!isProfileCompleted) {
      response = await axiosInstance.post(url, data);
    } else {
      response = await axiosInstance.put(url, data);
    }

    return response.data;
  } catch (error) {
    throw new Error('Failed to update agent capacity');
  }
};

export const useUpdateAgentCapacity = () => {
  return useMutation(updateCapacityNATIVE);
};
