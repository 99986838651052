import axiosInstance, { CreateInboxResponse } from '../config/axios';
import {
  FetchAllConversationByInbox,
  FetchOfflineConversationInterface,
  InboxListInterface,
  OfflineConversationEnum,
  workingHour
} from '../shared/types/inbox.interface';
import { CreateInboxParam } from './param.interface';
import { InboxAnalysisInterface } from '../shared/types/inbox.interface';
import axiosInstancev2 from '../config/axios/axiosInstanceV2';
import { conversationLimit } from '../static/search-filter';

export const fetchInboxList: (accountId: string) => Promise<InboxListInterface> = async (
  accountId
) => {
  const response = await axiosInstance.get(`account/${accountId}/web-widget/inboxes`);
  return response.data;
};

export const addInboxMember: (
  accountId: string,
  inboxId: string,
  agentId: string
) => Promise<any[]> = async (accountId, inboxId, agentId) => {
  const response = await axiosInstance.post(
    `account/${accountId}/inbox/${inboxId}/member?agentId=${agentId}`
  );
  return response.data;
};

export const removeInboxMember: (
  accountId: string,
  inboxId: string,
  agentId: string
) => Promise<any[]> = async (accountId, inboxId, agentId) => {
  const response = await axiosInstance.delete(
    `account/${accountId}/inbox/${inboxId}/member?agentId=${agentId}`
  );
  return response.data;
};

export const createInbox: (data: CreateInboxParam) => Promise<CreateInboxResponse> = async (
  data
) => {
  const response = await axiosInstance.post(`account/${data.accountId}/web-widget/inboxes`, {
    inboxName: data.inboxName,
    websiteUrl: data.websiteUrl,
    greetingInfo: {
      greetingEnabled: data.greetingEnabled,
      greetingMessage: data.greetingMessage
    },
    target: data.target,
    inboxConfig: data.inboxConfig,
    longQueueMessage: data.longQueueMessage,
    waitingRoomMessage: data.waitingRoomMessage,
    oooMessage: data.oooMessage
  });

  return response.data;
};

export const updateInbox: (inboxId: string, data: CreateInboxParam) => Promise<any[]> = async (
  inboxId,
  data
) => {
  const response = await axiosInstance.put(
    `account/${data.accountId}/inbox/${inboxId}/web-widget/inboxes`,
    {
      inboxName: data.inboxName,
      websiteUrl: data.websiteUrl,
      greetingInfo: {
        greetingEnabled: data.greetingEnabled,
        greetingMessage: data.greetingMessage
      },
      target: data.target,
      inboxConfig: data.inboxConfig,
      longQueueMessage: data.longQueueMessage,
      waitingRoomMessage: data.waitingRoomMessage,
      oooMessage: data.oooMessage
    }
  );

  return response.data;
};

export const updateWorkingHours: (
  accountId: string,
  inboxId: string,
  workingHoursEnabled: boolean,
  outOfOfficeMessage: string,
  workingHours: workingHour[]
) => Promise<any[]> = async (
  accountId,
  inboxId,
  workingHoursEnabled,
  outOfOfficeMessage,
  workingHours
) => {
  const response = await axiosInstance.put(`account/${accountId}/inbox/${inboxId}/working-hours`, {
    workingHoursEnabled,
    outOfOfficeMessage,
    workingHours
  });
  return response.data;
};

export const fetchInboxAnalysis: (
  inboxId: string,
  agentId: string,
  duration: string
) => Promise<InboxAnalysisInterface> = async (inboxId, agentId, duration) => {
  const response = await axiosInstance.get(
    `account/${inboxId}/analysis/${agentId}?time=${duration}`
  );
  return response.data;
};

export const fetchInboxByAgent: (
  accountId: string,
  agentId: string
) => Promise<InboxListInterface> = async (accountId, agentId) => {
  const response = await axiosInstance.get(`account/${accountId}/agent/${agentId}/inbox`);
  return response.data;
};

export const fetchAllConversationInInbox: (
  inboxId: string,
  isActive: boolean,
  page: number,
  email?: string,
  name?: string,
  ticketNumber?: string,
  phoneNumber?: string,
  startDate?: string,
  endDate?: string,
  limit?: number
) => Promise<FetchAllConversationByInbox> = async (
  inboxId,
  isActive,
  page,
  email,
  name,
  ticketNumber,
  phoneNumber,
  startDate,
  endDate,
  limit = conversationLimit
) => {
  const accountId = JSON.parse(localStorage.getItem('user') || '{}').account?.accountId;
  let queryParams = new URLSearchParams({
    active: isActive.toString(),
    'page-no': page.toString()
  });
  if (inboxId) {
    queryParams.append('inboxId', inboxId);
  }
  if (email) {
    queryParams.append('email', email);
  }
  if (startDate) {
    queryParams.append('startDate', startDate);
  }
  if (endDate) {
    queryParams.append('endDate', endDate);
  }
  if (ticketNumber) {
    queryParams.append('conversationId', ticketNumber);
  }
  if (phoneNumber) {
    queryParams.append('phone', phoneNumber);
  }
  if (name) {
    queryParams.append('name', name);
  }
  queryParams.append('size', limit.toString());
  const response = await axiosInstancev2.get(`account/${accountId}/conversation?${queryParams}`);
  return response.data;
};

export const fetchOfflineConversationsAPI: (
  accountId: string,
  page: number,
  status?: OfflineConversationEnum | null,
  email?: string,
  name?: string,
  ticketNumber?: string,
  phoneNumber?: string,
  startDate?: string,
  endDate?: string,
  limit?: number
) => Promise<FetchOfflineConversationInterface> = async (
  accountId,
  page,
  status,
  email,
  name,
  ticketNumber,
  phoneNumber,
  startDate,
  endDate,
  limit = 9
) => {
  let queryParams = new URLSearchParams({
    'page-no': page.toString()
  });
  if (status) {
    queryParams.append('status', status);
  }
  if (email) {
    queryParams.append('email', email);
  }
  if (startDate) {
    queryParams.append('startDate', startDate);
  }
  if (endDate) {
    queryParams.append('endDate', endDate);
  }
  if (ticketNumber) {
    queryParams.append('messageId', ticketNumber);
  }
  if (phoneNumber) {
    queryParams.append('phone', phoneNumber);
  }
  if (name) {
    queryParams.append('name', name);
  }
  queryParams.append('page-size', limit.toString());
  const response = await axiosInstancev2.get(
    `account/${accountId}/offline-messages?${queryParams}`
  );
  return response.data;
};

export const getAllActiveAgents = async (accountId: string) => {
  const response = await axiosInstance.get(`account/${accountId}/agent/active`);
  return response.data;
};

export const postAgentAnswerConv = async (conversationId: string) => {
  const response = await axiosInstance.post(`conversation/${conversationId}/agent/activity/JAWAB`);
  return response.data;
};
