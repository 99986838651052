import axios, { InternalAxiosRequestConfig } from 'axios';
import { LoginPlatform } from '../shared/types/auth.interface';
import { useAuthStore } from '../store/new/auth';
import { getCookie } from './cookie-management';
import { CookieNameEnum } from '../static/cookie-management';

const request = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/v1/`,
  headers: {
    'Content-Type': 'application/json',
    authenticationPlatform: `${LoginPlatform.NATIVE}`,
    Authorization: `jwt ${localStorage.getItem('accessToken')?.replaceAll('"', '')}`
  }
});

export const requestV2 = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/v2/`,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `jwt ${localStorage.getItem('accessToken')?.replaceAll('"', '')}`
  }
});

const setHeaderAuthenticationPlatform = (request: InternalAxiosRequestConfig) => {
  const authProvider = useAuthStore.getState().authProvider;
  const isSQEIDLoggedIn = useAuthStore.getState().isSQEIDLoggedIn();

  if (isSQEIDLoggedIn || authProvider === LoginPlatform.SIMAS_ID) {
    const accessToken = localStorage.getItem('accessToken')?.replaceAll('"', '') || '';
    request.headers.authenticationPlatform = LoginPlatform.SIMAS_ID;
    request.headers.Authorization = `jwt ${accessToken}`;
    return request;
  } else {
    const nativeAccessToken = getCookie(CookieNameEnum.NATIVE_TOKEN);
    if (typeof nativeAccessToken === 'string') {
      request.headers.authenticationPlatform = LoginPlatform.NATIVE;
      request.headers.Authorization = `jwt ${nativeAccessToken}`;
      return request;
    }
  }

  return request;
};

request.interceptors.request.use((request) => {
  return setHeaderAuthenticationPlatform(request);
});

requestV2.interceptors.request.use((request) => {
  return setHeaderAuthenticationPlatform(request);
});

export default request;
