import { useEffect, useState } from 'react';
import { Paragraph } from '@squantumengine/horizon';
import { useChatListStore } from '../../store/chat-list';
import { useAppStore } from '../../store/user-state';
import warningIcon from '../../assets/icons/warning.svg';
import { startCountdown } from '../../utils/count-down';

const CustomerDisconnectBar = () => {
  const [timeLeft, setTimeLeft] = useState('');
  const [showTimeLeft, setShowTimeLeft] = useState(true);

  const { selectedCall } = useChatListStore();
  const durationReminder = selectedCall?.inboxConfig?.durationReminder || 15;
  const showAlert = selectedCall?.inboxConfig?.reminderIdleConversation;

  const startHeartBeat =
    localStorage.getItem('startHeartBeat') || useAppStore.getState().startHeartBeat;

  const handleChangeTime = (value: string) => {
    setTimeLeft(value);
  };

  const handleEndTime = () => {
    setTimeLeft('00:00');
    setShowTimeLeft(true);
  };

  useEffect(() => {
    if (startHeartBeat) {
      const timeStamp = localStorage.getItem('hearthBeat') || useAppStore.getState().heartBeat;
      startCountdown(Number(timeStamp) * 1000, durationReminder, handleChangeTime, handleEndTime);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startHeartBeat]);
  return (
    <div className="z-0 flex h-max w-full items-start bg-yellow-50 ">
      <img src={warningIcon} alt="warning" />
      <div className="p-3">
        <Paragraph className="font-semibold " size="xl">
          Pelanggan Terputus
        </Paragraph>
        <Paragraph className="" size="l">
          Mohon tunggu {showTimeLeft && showAlert && <strong>{timeLeft}</strong>} atau akhiri sesi.
        </Paragraph>
      </div>
    </div>
  );
};

export default CustomerDisconnectBar;
