import { Error } from '../shared/types/error-handling.interface';
import { withErrorHandling } from './error-handling';

/**
 * Function to help set cookie.
 * Cookie max age 400 days.
 *
 * @param name - Cookie name.
 * @param value - Cookie value.
 * @param duration - How many seconds before cookie expired.
 * @param expireAt - Date and time when cookie expired in UTC format.
 */
export const setCookie = (name: string, value: string, duration?: number, expireAt?: string) => {
  let maxAge = `;max-age=${duration || expireAt}`;
  document.cookie = name + '=' + value + maxAge + ';path=/;SameSite=Lax;Secure';
};

/**
 * Function to help get cookie
 *
 * @param name - Cookie name.
 * @returns string | {error: boolean, message: string}
 */
export const getCookie = (name: string): string | Error => {
  return withErrorHandling(() => {
    let value = '; ' + document.cookie;
    let parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop()!.split(';').shift() || '';
    return '';
  });
};


/**
 * Function to help delete cookie
 *
 * @param name - Cookie name.
 */
export const deleteCookie = (name: string) => {
  let maxAge = `;max-age=0`;
  document.cookie = name + '=' + maxAge + ';path=/;SameSite=Lax;Secure';
};
