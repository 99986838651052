import { Typography } from '@squantumengine/horizon';
import { ReactNode } from 'react';
import { getAssetIcon } from '../../utils/getIconUrl';

type HeadingProps = {
  title: string;
  description: string | JSX.Element;
};

type CardHeadingProps = {
  children: ReactNode;
};

const CardHeading: React.FC<CardHeadingProps> & { Heading: React.FC<HeadingProps> } = ({
  children
}) => (
  <div className="flex flex-col items-center justify-center text-center">
    <div className="mb-[24px]">
      <img
        src={getAssetIcon('sqecc-logo.svg')}
        alt="sqecc-logo"
        height="48"
      />
    </div>

    {children}
  </div>
);

const Heading: React.FC<HeadingProps> = ({ title, description }) => (
  <>
    <Typography.Title level={4} className="mb-[8px]">
      {title}
    </Typography.Title>
    <Typography.Paragraph size="r" weight="regular" className="mb-[24px] text-center">
      {description}
    </Typography.Paragraph>
  </>
);

CardHeading.Heading = Heading;

export default CardHeading;
