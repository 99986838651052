import React from 'react';
import frameKtp from '../../assets/frame/frameKtp.svg';
import frameKtpSelfie from '../../assets/frame/frameKtpSelfie.svg';
import frameSelfie from '../../assets/frame/frameSelfie.svg';
import { PhotoInstructionEnum } from '../kyc/kyc.interface';

export default function Frame(props: { frame: string }) {
  const { frame } = props;
  const onlyId = frame === PhotoInstructionEnum[1].value;
  const onlyFace = frame === PhotoInstructionEnum[2].value;
  const idAndFace = frame === PhotoInstructionEnum[3].value;

  const selectedFrame = () => {
    switch (true) {
      case onlyId:
        return frameKtp;
      case onlyFace:
        return frameSelfie;
      case idAndFace:
        return frameKtpSelfie;
      default:
        return '';
    }
  };

  return (
    <div className="absolute left-1/2 z-10 flex h-full w-max  -translate-x-1/2  flex-col items-center justify-center">
      {selectedFrame() && (
        <div className="absolute bottom-0 z-10 flex h-full justify-center ">
          <img className="h-full !max-w-fit" src={selectedFrame()} alt={frame} />
        </div>
      )}
    </div>
  );
}
