export function formatTime(seconds: number) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  const finalMinutes = minutes < 10 ? '0' + minutes : minutes;
  const finalRemainingSeconds = remainingSeconds < 10 ? '0' + remainingSeconds : remainingSeconds;
  const formattedTime = finalMinutes + ':' + finalRemainingSeconds;
  return formattedTime;
}

export function formatSecondToHHMMSS(seconds: number) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds - hours * 3600) / 60);
  const secondsRemaining = seconds - hours * 3600 - minutes * 60;
  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');
  const formattedSeconds = secondsRemaining.toString().padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}

export function removeEmptyKeys(obj: any) {
  return Object.fromEntries(Object.entries(obj).filter(([key, value]) => value !== ''));
}

export function toSentenceCase(str: string, separator = '_') {
  return str
    .split(separator)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
}
