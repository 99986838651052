import { useEffect, useMemo, useState } from 'react';
import { DatePicker, Skeleton } from 'antd';
import dayjs from 'dayjs';
import { useCustomerDetails } from '../../../api/hooks/customer/useCustomerDetails';
import { useChatListStore } from '../../../store/chat-list';
import Copy from '../../copy';
import PencilIcon from '../../../assets/icons/pencil';
import { patchCustomerDetails } from '../../../api/customer';
import { normalizeIDNString } from '../../../utils/string';
import { ConversationStatusEnum } from '../../../static/message-event';
import { useSelectedCustomerStore } from '../../../store/customer-state';
import { useUserStatusStore } from '../../../store/user-state';
import { RoleEnum } from '../../../static/role';

const FIELDS_INFO_DASAR = [
  ['name', 'Nama'],
  ['contactId', 'ID Pelanggan'],
  ['email', 'Email'],
  ['phone', 'No Telepon']
];

const BasicInfo = () => {
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [dobDelayed, setDobDelayed] = useState('');
  const { userRole } = useUserStatusStore();
  const { selectedCall, selectedOfflineChat } = useChatListStore();
  const { setDob } = useSelectedCustomerStore();
  const isAllowedEditDob = useMemo(
    () =>
      !(
        selectedCall?.conversationStatus === ConversationStatusEnum.RESOLVED ||
        !!selectedOfflineChat
      ),
    [selectedCall?.conversationStatus, selectedOfflineChat]
  );
  const { data: customerDetail, isLoading: loadingCustomerDetail } = useCustomerDetails(
    selectedCall?.contactId || '',
    !!selectedCall?.contactId
  );

  const currentCustomer = useMemo(
    () =>
      customerDetail || {
        ...selectedOfflineChat?.customer,
        contactId: selectedOfflineChat?.customer?.id
      },
    [customerDetail, selectedOfflineChat]
  );

  useEffect(() => {
    setDobDelayed('');
  }, [selectedCall, selectedOfflineChat]);

  const excludedMetadataDisplay = ['reference_id', 'communicationType', 'status'];

  return (
    <div>
      <div className="grid gap-2 overflow-hidden lg:grid-cols-2 lg:gap-4">
        {FIELDS_INFO_DASAR.map(([key, label]) => {
          // @ts-ignore: unparseable key
          const value = (currentCustomer && currentCustomer[key]) || '-';
          return (
            <div key={key}>
              <h5 className="heading my-0 text-xs xl:text-sm">
                {{
                  contactId: (
                    <div className="flex items-center justify-between">
                      <span>{label}</span>
                      <span className="text-xs">
                        <Copy className="text-xs" value={value} />
                      </span>
                    </div>
                  )
                }[key] || label}
              </h5>
              <div className="truncate">
                {(loadingCustomerDetail && (
                  <Skeleton active title={{ width: '100%', className: 'my-1' }} paragraph={false} />
                )) ||
                  value}
              </div>
            </div>
          );
        })}
        <div>
          <h5 className="heading my-0 flex items-center space-x-1 text-xs xl:text-sm">
            <span className="xl:whitespace-nowrap">Tanggal Lahir</span>
            {userRole === RoleEnum.AGENT && isAllowedEditDob && (
              <button
                className="cursor-pointer border-none bg-transparent"
                onClick={() => setOpenDatePicker(true)}>
                <PencilIcon />
              </button>
            )}
            <DatePicker
              disabledDate={(current) => {
                let customDate = dayjs().add(1, 'day').format('YYYY-MM-DD');
                return current && current > dayjs(customDate, 'YYYY-MM-DD');
              }}
              open={openDatePicker}
              onOpenChange={setOpenDatePicker}
              onChange={(value) => {
                const formatedDate = value?.format('YYYY-MM-DD');
                setDob(formatedDate as string);
                try {
                  patchCustomerDetails(selectedCall?.contactId as string, {
                    dateOfBirth: formatedDate
                  });
                  setDobDelayed(formatedDate as string);
                } catch (error) {}
                setOpenDatePicker(false);
              }}
              className="invisible"
            />
          </h5>
          <div>
            {(loadingCustomerDetail && (
              <Skeleton active title={{ width: '100%', className: 'my-0' }} paragraph={false} />
            )) ||
              dobDelayed ||
              (customerDetail && customerDetail.dateOfBirth) ||
              '-'}
          </div>
        </div>
      </div>
      {selectedCall?.metadata && (
        <div>
          <h4 className="heading">Informasi Tambahan</h4>
          <div className="grid gap-2 overflow-hidden lg:grid-cols-2 lg:gap-4">
            {Object.entries(selectedCall?.metadata)
              .filter(([key, value]) => !excludedMetadataDisplay.includes(key) && value!!) // exclude reference_id, communicationType, and empty value
              .map(([key, value]) => (
                <div>
                  <h5 className="heading my-0 text-xs xl:text-sm">{normalizeIDNString(key)}</h5>
                  <div className="truncate">
                    {(loadingCustomerDetail && (
                      <Skeleton
                        active
                        title={{ width: '100%', className: 'my-0' }}
                        paragraph={false}
                      />
                    )) ||
                      value}
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default BasicInfo;
