import { indonesiaDocument } from '../static/abbreviations';

export function buildQueryString(obj: Record<string, string | number | boolean>): string {
  return Object.entries(obj)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');
}

export function normalizeIDNString(value: string) {
  let formattedValue = value;
  if (value.includes('_')) {
    formattedValue = value.replace(/_/g, ' ');
  }
  formattedValue = convertStringWithAbbreviations(formattedValue, indonesiaDocument);
  return formattedValue;
}

const convertStringWithAbbreviations = (input: string, abbreviations: string[]): string =>
  // Split the input string into words using regex
  // Breakdown of the regex:
  // [A-Z]?[a-z]+  : Matches words starting with optional capital letter followed by lowercase
  // |             : OR
  // [A-Z]+(?=[A-Z]|\s|$) : Matches consecutive uppercase letters until another uppercase or space or end
  (input.match(/[A-Z]?[a-z]+|[A-Z]+(?=[A-Z]|\s|$)/g) || [])
    .map((word) =>
      abbreviations.includes(word.toUpperCase())
        ? // Keep abbreviations in uppercase
          word.toUpperCase()
        : // Capitalize first letter, lowercase the rest
          word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    )
    // Join the words back into a string with spaces
    .join(' ');
