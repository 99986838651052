import { Paragraph } from '@squantumengine/horizon';
import { useTimer } from '../../../../../hooks/useTimer';
import { MessageEventEnum } from '../../../../../static/message-event';
import { RoleEnum } from '../../../../../static/role';
import { useChatListStore } from '../../../../../store/chat-list';
import { useUserStatusStore } from '../../../../../store/user-state';
import ConfirmationModal from '../../../../modal/confirmation-modal';
import Copy from '../../../copy';
import { parseDuration } from '../../call-list';
import CloseSessionButton from '../../close-session';
import { CustomerActionInterface } from './customer-action.interface';

export default function CustomerAction(props: CustomerActionInterface) {
  const { addNotesHandler, endTicketHandler, handleEndCall, setShowModal, showModal } = props;
  const { selectedCall, isUserTyping } = useChatListStore();
  const { userRole } = useUserStatusStore();
  const conversationId = selectedCall?.conversationId || '';
  const isActiveCall = selectedCall?.event === MessageEventEnum.ACTIVE;
  const inactive = selectedCall?.event === MessageEventEnum.INACTIVE;
  const showAkhiriSesiButton = userRole === RoleEnum.AGENT && selectedCall;
  const { second } = useTimer(Math.round((Date.now() - Number(selectedCall?.startTime)) / 1000));
  const durationText = parseDuration(
    second,
    !!inactive,
    Number(selectedCall?.startTime),
    selectedCall?.endTime
  );

  return (
    <div className="flex w-full flex-col items-center justify-start border-0 border-b border-solid border-b-grey-50  px-6 py-4">
      <div className="flex w-full flex-col items-start justify-start">
        <div className="flex w-full items-center justify-between text-lg font-semibold">
          <div className="flex flex-1 items-center justify-center gap-6 overflow-hidden">
            <div
              className={`h-2 w-2 min-w-[8px] truncate rounded-full ${
                isActiveCall ? 'bg-bold-green' : 'bg-chat-gray'
              } `}
            />
            <div className="w-full truncate">{selectedCall?.name || 'Pelanggan'}</div>
          </div>
          {showAkhiriSesiButton && (
            <div className="ml-2">
              <CloseSessionButton
                onClickAddNotes={addNotesHandler}
                onClickEndTicket={endTicketHandler}
              />
            </div>
          )}
          <ConfirmationModal
            modalState={showModal}
            setModalState={setShowModal}
            handleConfirm={handleEndCall}
          />
        </div>
        {isUserTyping && isActiveCall && <div className="pl-8">typing...</div>}
      </div>
      <div className="flex w-full items-center gap-1 text-sm text-[#677485]">
        <Copy value={conversationId} />
        <Paragraph className="max-w-[100px] truncate">
          ID #{selectedCall?.conversationId?.slice(-5)}
        </Paragraph>
        |<Paragraph className="max-w-max truncate">Durasi {durationText}</Paragraph>
      </div>
    </div>
  );
}
