import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { Col, Divider, Form, FormInstance, Modal, Row, Select, Typography, message } from 'antd';
import { useState } from 'react';
import { useCreateTopic, useDeleteTopic, useGetTopic } from '../../../api/hooks/inbox/useTopic';
import { useTeamStore } from '../../../store/team-state';
import usePrevious from '../../../hooks/usePrevious';

function Topic(props: { form?: FormInstance<any> }) {
  const { form } = props;
  const { Text, Title } = Typography;
  const [, contextHolderMessage] = message.useMessage();

  const [selectedTopic, setSelectedTopic] = useState<{ uuid: string; topic: string } | null>();
  const [search, setSearch] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const { selectedInbox } = useTeamStore();
  const prevSearch = usePrevious(search);

  const { mutate: addTopic } = useCreateTopic();
  const { mutate: deleteTopic } = useDeleteTopic();
  const { data } = useGetTopic(selectedInbox?.inboxId);

  const handleOk = async () => {
    setOpen(false);
    deleteTopic({ inboxId: selectedInbox?.inboxId, topicId: selectedTopic?.uuid });
    form!.resetFields(['topic']);
  };

  const handleCancel = () => {
    setOpen(false);
    form!.resetFields(['topic']);
  };

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  const createTopic = () => {
    if (prevSearch !== search) {
      addTopic({ inboxId: selectedInbox?.inboxId, topic: search });
    }
  };

  return (
    <>
      {contextHolderMessage}
      <Modal
        title={
          <Title level={4} className="m-0 p-0">
            Hapus Topik
          </Title>
        }
        open={open}
        onOk={() => setOpen(false)}
        width={300}
        footer={null}
        centered
        onCancel={handleCancel}>
        <Text className="text-md">Yakin ingin menghapus topik dari daftar?</Text>
        <button
          onClick={handleOk}
          className={`my-3 flex w-full cursor-pointer flex-row items-center justify-center rounded-full border-none bg-orange-danger py-3 text-white transition-[transform] duration-100 hover:scale-105 active:scale-90`}>
          Hapus Topik
        </button>
        <button
          onClick={handleCancel}
          className={`my-3 flex w-full cursor-pointer flex-row items-center justify-center rounded-full  border-2 bg-white-background  py-3 text-black transition-[transform] duration-100 hover:scale-105 active:scale-90`}>
          Batalkan
        </button>
      </Modal>
      <Form.Item
        name="topic"
        required
        className="inbox_topic m-0 w-full"
        label={<Text className="text-base font-semibold">Topik</Text>}>
        <Select
          showSearch
          allowClear
          showArrow
          onSearch={handleSearch}
          onClear={() => setSelectedTopic(null)}
          onSelect={(value, option) => {
            setSelectedTopic({
              uuid: option!?.key,
              topic: option!?.value as string
            });
          }}
          filterOption={(input, option) => {
            // @ts-ignore
            return (
              option!
                ?.value!?.toString()
                ?.toLowerCase()
                ?.indexOf(input!?.toString()?.toLowerCase()) >= 0
            );
          }}
          dropdownRender={(menu) => (
            <div className="p-2">
              <Row align="middle" justify="space-between">
                <Col span={18}>
                  <Text className="text-base font-semibold">List Topik</Text>
                </Col>
                <Text
                  onClick={createTopic}
                  className="cursor-pointer text-base font-bold text-emerald-light-green">
                  Tambah
                </Text>
              </Row>
              <Divider className="my-2" />
              {menu}
            </div>
          )}
          placeholder={
            <>
              <SearchOutlined />
              &nbsp; Cari Topik
            </>
          }>
          {data?.conversationTopics.map((option) => {
            return (
              <Select.Option key={option!?.uuid} value={option?.topic}>
                <Row align="middle" justify="space-between">
                  <Col span={20}>
                    <Text>{option!?.topic}</Text>
                  </Col>

                  {selectedTopic?.uuid !== option?.uuid && (
                    <CloseOutlined className=" hover:scale-100" onClick={() => setOpen(true)} />
                  )}
                </Row>
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
    </>
  );
}

export default Topic;
