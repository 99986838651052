import { useEffect, useState } from 'react';
import { Typography, Button, Table, Form, Flex } from 'antd';
import TeamUsersModal from './team-users-modal';
import { useGetUsers, useInvUsrSQEID } from '../../api/hooks/sqeid/useSQEID';
import { goToInvUser } from '../../utils/oAuth';
import { UserResponse } from '../card/user/user.interface';
import SearchFilter from './search-filter';
import { useUserStatusStore } from '../../store/user-state';
import { RoleEnum } from '../../static/role';
import { InviteUserParams, itemsInvUsr } from './team-users.interface';
import TeamBUModal from './team-bu-modal';
import useToaster from '../../hooks/useCCToaster';
import { ErrorMsgEnum } from '../../static/error-message';
import { invUserSQEIDColumns } from '../../static/teams';

const { Title } = Typography;

const ManageUsers = () => {
  const [isModalEditOpen, setIsModalEditOpen] = useState<boolean>(false);
  const [isModalBUOpen, setIsModalBUOpen] = useState<boolean>(false);
  const [selectedData, setSelectedData] = useState<UserResponse>({} as UserResponse);
  const [filterData, setFilterData] = useState<UserResponse[] | undefined>();
  const { userRole } = useUserStatusStore();
  const { contextHolder, openNotification } = useToaster({ timeout: 3 });

  const { mutateAsync } = useInvUsrSQEID();
  const { data: invUserData, isLoading: isInvUserDataLoading } = useGetUsers();

  const [form] = Form.useForm();

  const doInviteUser = async (organizationId?: string) => {
    let payload = {
      tokenType: 'invite-user'
    } as InviteUserParams;

    if (userRole === RoleEnum.ADMIN) {
      payload = {
        ...payload,
        organizationId
      };
    }

    let error = false;

    const response: any = await mutateAsync(payload).catch((err) => {
      // eslint-disable-next-line no-console
      console.error(err);
      error = true;
    });

    if (error) {
      openNotification({
        label: ErrorMsgEnum.general,
        buttonLabel: 'Coba Lagi',
        variant: 'secondary'
      });
      return;
    }

    goToInvUser(response?.token);
  };

  const handleInviteUser = async () => {
    if (userRole === RoleEnum.SUPERVISOR) {
      doInviteUser();
    } else {
      setIsModalBUOpen(true);
    }
  };

  useEffect(() => {
    setFilterData(invUserData);
  }, [invUserData]);

  return (
    <>
      {contextHolder}
      <TeamUsersModal
        data={selectedData}
        form={form}
        setIsModalOpen={setIsModalEditOpen}
        isModalOpen={isModalEditOpen}
      />
      <TeamBUModal
        inviteUser={doInviteUser}
        setIsModalOpen={setIsModalBUOpen}
        isModalOpen={isModalBUOpen}
      />
      <Flex gap="middle" vertical>
        <Flex justify="space-between" align="center">
          <Title className="m-0 p-0" level={2}>
            Manage Users
          </Title>
          <Flex justify="space-between" align="center" gap="middle" className="w-5/12">
            <div className="w-8/12">
              <SearchFilter setData={setFilterData} data={invUserData} items={itemsInvUsr} />
            </div>
            <Button
              onClick={handleInviteUser}
              className={`h-10 w-4/12 rounded-lg border-none bg-green-500 text-base font-semibold text-white`}>
              Tambah User Baru
            </Button>
          </Flex>
        </Flex>
        <Table
          loading={isInvUserDataLoading}
          columns={invUserSQEIDColumns(
            setIsModalEditOpen,
            setSelectedData,
            userRole === RoleEnum.ADMIN
          )}
          dataSource={filterData}
        />
      </Flex>
    </>
  );
};

export default ManageUsers;
