import dayjs from 'dayjs';
import { BusyHourRangeColor } from '../../../../static/analysis';

export const hourDetail = [
  '23:00-24:00',
  '22:00-23:00',
  '21:00-22:00',
  '20:00-21:00',
  '19:00-20:00',
  '18:00-19:00',
  '17:00-18:00',
  '16:00-17:00',
  '15:00-16:00',
  '14:00-15:00',
  '13:00-14:00',
  '12:00-13:00',
  '11:00-12:00',
  '10:00-11:00',
  '09:00-10:00',
  '08:00-09:00',
  '07:00-08:00',
  '06:00-07:00',
  '05:00-06:00',
  '04:00-05:00',
  '03:00-04:00',
  '02:00-03:00',
  '01:00-02:00',
  '00:00-01:00'
];

export interface BusyHourDaily {
  hourly: number[];
  total: number;
}

export interface BusyHourConversation {
  [date: string]: BusyHourDaily;
}

export enum BusyHourType {
  DAY = 'day',
  WEEK = 'week',
  YEAR = 'year'
}

export interface DetailHour {
  hour: string;
  total: number;
}

export interface DetailBusyHourProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedData: BusyHourConversation;
}

type DataItem = {
  [key: string]: {
    total: number;
    hourly: number[];
  };
};

export enum ItemKeyBusyHour {
  TODAY = 0,
  WEEK = 1,
  MONTH = 2
}

export enum ChunkBusyHourType {
  DAY = 1,
  WEEK = 7,
  MONTH = 30
}

export const itemsBusyHour = [
  {
    label: 'Hari',
    key: ItemKeyBusyHour.TODAY
  },
  {
    label: 'Minggu',
    key: ItemKeyBusyHour.WEEK
  },
  {
    label: 'Bulan',
    key: ItemKeyBusyHour.MONTH
  }
];

/**
 * Splits the input data into chunks based on the specified interval.
 *
 * @param data - The input data to be chunked
 * @param interval - The interval at which to split the data
 *
 * @returns An array of objects representing the chunked data
 */
export const chunkByDay = (data: Record<string, any>, interval: number): Record<string, any>[] => {
  const values = Object.values(data);
  const final: Record<string, any>[] = [];
  let counter = 0;
  let portion: Record<string, any> = {};

  for (const key in data) {
    if (counter !== 0 && counter % interval === 0) {
      final.push(portion);
      portion = {};
    }
    portion[key] = values[counter];
    counter++;
  }
  final.push(portion);

  return final;
};

/**
 * Groups data items by date and calculates the total and hourly values for each date.
 * @param data - The data to be grouped by date.
 * @returns An object containing the total and hourly values for each date.
 */
export const groupByDay = (
  data: DataItem[],
  type: ChunkBusyHourType
): { [key: string]: { total: number; hourly: number[] } } => {
  const getChunkByWeek = chunkByDay(data, type);
  const groupedData: { [key: string]: { total: number; hourly: number[] } } = {};

  // eslint-disable-next-line array-callback-return
  getChunkByWeek?.map((item) => {
    let total = 0;
    const hourly = Array(24).fill(0);
    let startDate = '';
    let endDate = '';

    // eslint-disable-next-line array-callback-return
    Object.keys(item).map((key, index) => {
      if (index === 0) startDate = key;
      if (index === 6) endDate = key;

      total += item[key]?.total;

      for (let i = 0; i < 24; i++) {
        hourly[i] += item[key]?.hourly[i];
      }
    });

    switch (type) {
      case ChunkBusyHourType.WEEK:
        startDate = dayjs(startDate).locale('id').format('DD MMM');
        endDate = endDate !== '' ? `- ${dayjs(endDate).locale('id').format('DD MMM')}` : '';
        break;
      case ChunkBusyHourType.MONTH:
        startDate = dayjs(startDate).locale('id').format('MMM');
        endDate = '';
        break;
      default:
        startDate = dayjs(startDate).locale('id').format('dddd DD/MM');
        endDate = '';
        break;
    }

    groupedData[`${startDate}${endDate}`] = {
      total: total,
      hourly: hourly
    };
  });

  return groupedData;
};

export const getBgColor = (item: any, total: number) => {
  let bgColor = BusyHourRangeColor[0];

  const percentage = (item / total) * 100;

  switch (true) {
    case percentage < 1:
      bgColor = BusyHourRangeColor[0];
      break;
    case percentage >= 1 && percentage <= 15:
      bgColor = BusyHourRangeColor[1];
      break;
    case percentage >= 16 && percentage <= 30:
      bgColor = BusyHourRangeColor[2];
      break;
    case percentage >= 31 && percentage <= 45:
      bgColor = BusyHourRangeColor[3];
      break;
    case percentage >= 46 && percentage <= 60:
      bgColor = BusyHourRangeColor[4];
      break;
    case percentage >= 61 && percentage <= 75:
      bgColor = BusyHourRangeColor[5];
      break;
    case percentage >= 76 && percentage <= 100:
      bgColor = BusyHourRangeColor[6];
      break;
    default:
      break;
  }

  return bgColor;
};
