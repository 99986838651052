import { useEffect, useState } from 'react';

import { CurrentUserObjectInterface } from '../../shared/types/user.interface';
import { RoleEnum } from '../../static/role';
import { useNavigate, Outlet } from 'react-router-dom';

import TourConfig from '../../components/tour';
import { Col, Divider, Menu, MenuProps, Row, Typography } from 'antd';
import { TeamMenuEnum, TeamMenuItems } from '../../static/analysis';
import { useTeamStore } from '../../store/team-state';
import { PlusOutlined } from '@ant-design/icons';
import { LoginPlatform } from '../../shared/types/auth.interface';
import { FormActionEnum, FormTypeEnum } from '../../static/team';
import { TeamActiveHandlerInterface } from '../../shared/types/team.interface';
import { AccountInterface } from '../../shared/types/account.interface';
import { useUserStatusStore } from '../../store/user-state';
import ManageUsersNATIVE from '../../components/team-users-native';
import ManageUsersSQEID from '../../components/team-users';
import { useAuthStore } from '../../store/new/auth';

const { Title } = Typography;

function TeamPage() {
  const navigate = useNavigate();
  const [currentMenu, setCurrentMenu] = useState(TeamMenuEnum.TEAM);

  const {
    selectedBU,
    isOpenForm,
    formType,
    formAction,
    account,
    setIsOpenForm,
    setFormAction,
    setFormType,
    setSelectedBU,
    setTeamActiveHandler,
    resetTeamActiveHandler
  } = useTeamStore();

  const { isNativeLoggedIn, authProvider } = useAuthStore();

  const { userRole } = useUserStatusStore();

  useEffect(() => {
    const currUser: CurrentUserObjectInterface = JSON.parse(localStorage.getItem('user') || '{}');
    switch (currUser.role) {
      case RoleEnum.ADMIN:
        navigate('admin');
        break;
      case RoleEnum.SUPERVISOR:
        navigate('supervisor');
        break;
      default:
        navigate('/dashboard');
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickTab: MenuProps['onClick'] = (e) => {
    setCurrentMenu(JSON.parse(e.key));
  };

  const createNewInbox = () => {
    const currUser: CurrentUserObjectInterface = JSON.parse(localStorage.getItem('user') || '{}');

    let selectedAccount = {} as AccountInterface;
    resetTeamActiveHandler();

    const toggleAction = FormActionEnum.CREATE;
    const openForm = !(
      isOpenForm &&
      formAction === toggleAction &&
      formType === FormTypeEnum.INBOX
    );

    resetTeamActiveHandler();

    const newTeamActiveHandler: TeamActiveHandlerInterface = {
      ...useTeamStore.getState().teamActiveHandler,
      activeAccountId: openForm ? currUser?.account?.accountId! : ''
    };

    newTeamActiveHandler['isCreateInbox'] = openForm;

    selectedAccount =
      account.accountList.find(
        (accountItem) => accountItem.accountId === currUser!?.account?.accountId
      ) || ({} as AccountInterface);

    setTeamActiveHandler({ ...newTeamActiveHandler });

    setIsOpenForm(openForm);
    setFormAction(toggleAction);
    setFormType(FormTypeEnum.INBOX);
    setSelectedBU(selectedAccount);
  };

  return (
    <div className="p-4">
      <Row className="w-full items-center">
        <Col span={8}>
          <Title level={2}>{selectedBU.name}</Title>
        </Col>
        {currentMenu === TeamMenuEnum.TEAM && userRole === RoleEnum.SUPERVISOR && (
          <Col span={8} className="flex justify-end">
            <div
              onClick={() => createNewInbox()}
              className="team-action-button w-48 border border-black-90 px-0.5 py-3">
              Buat Tim Baru&nbsp;
              <PlusOutlined />
            </div>
          </Col>
        )}
      </Row>
      <div className="flex w-full">
        <Menu
          className="w-full border-none bg-transparent focus:outline-none"
          onClick={onClickTab}
          selectedKeys={[`${currentMenu}`]}
          mode="horizontal"
          items={TeamMenuItems}
        />
      </div>
      <Divider className="mb-4 mt-0" />
      {currentMenu === TeamMenuEnum.TEAM ? (
        <>
          <Outlet />
          <TourConfig />
        </>
      ) : isNativeLoggedIn() || authProvider === LoginPlatform.NATIVE ? (
        <ManageUsersNATIVE />
      ) : (
        <ManageUsersSQEID />
      )}
    </div>
  );
}

export default TeamPage;
