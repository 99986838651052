import { FC, useEffect, useRef, useState } from 'react';
import ErrorForm from '../form/error-form/error-form';
import dayjs from 'dayjs';
import useMount from '../../hooks/useMounth';
import { DurationToString } from '../../utils/timestamp';
import useStateRef from '../../hooks/useStateRef';

type ErrorTtlProps = {
  prefix: string;
  milliseconds: number;
};

const ErrorTtl: FC<ErrorTtlProps> = ({ milliseconds, prefix }) => {
  const [timeLeft, setTimeLeft, ref] = useStateRef(0);
  const [startCount, setStartCount] = useState(false);
  const interval = useRef<any>(null);

  useEffect(() => {
    if (startCount && !interval.current) {
      interval.current = setInterval(() => {
        if (ref.current <= 0) {
          setTimeLeft(0);
          setStartCount(false);
          clearInterval(interval.current);
        } else {
          setTimeLeft((prev) => prev - 1);
        }
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startCount]);

  useMount(() => {
    const endTime = dayjs().add(milliseconds, 'milliseconds');
    const now = dayjs();
    const secondsLeft = endTime.diff(now, 'seconds');

    setTimeLeft(secondsLeft);
    setStartCount(true);
  });

  const ttlErroMessage = `${
    prefix.endsWith('.') ? prefix : prefix + '.'
  } Mohon tunggu ${DurationToString(timeLeft)} menit untuk coba lagi.`;

  return <ErrorForm isVisible={true} message={ttlErroMessage} className="my-[24px]" />;
};

export default ErrorTtl;
