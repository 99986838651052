import React from 'react';
import { ChatFooterSummaryEnum } from '../../../../static/tab-list';

export interface ChatFooterInterface {
  chat?: string;
  setChat?: (chat: string) => void;
  addChat: (
    event: React.FormEvent<HTMLFormElement> | React.KeyboardEvent<HTMLTextAreaElement>
  ) => void;
  handleFileChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onTabChange?: (key: string) => void;
}

export const TabMenuEnum = {
  SEND_CHAT: 'send-chat',
  SEND_SUMMARY: 'send-summary'
};

export const TypeSummaryEnum = {
  END_CHAT: 'end-chat',
  FILL: 'fill'
};

export interface ChatFooterFormInterface {
  setSelectedMenus?: React.Dispatch<React.SetStateAction<ChatFooterSummaryEnum>>;
  openNotification: (payload: any) => void;
  setOpenModalConfirm?: React.Dispatch<React.SetStateAction<boolean>>;
  type?: string;
  onClickEndTicket?: () => void;
}
