import CardHeading from '../../components/login/card-heading';
import { NativeLoginWordingEnum } from '../../static/native-login';
import { getAssetIcon } from '../../utils/getIconUrl';

const ResetPasswordSent = () => {
  return (
    <div className="flex h-screen flex-col items-center justify-center">
      <img alt="sqecc-logo" src={getAssetIcon('done.svg')} height={200} />
      <CardHeading.Heading
        title={NativeLoginWordingEnum.resetPasswordSentTitle}
        description={
          <>
            {NativeLoginWordingEnum.resetPasswordSent1} <br />
            {NativeLoginWordingEnum.resetPasswordSent2}
          </>
        }
      />
    </div>
  );
};

export default ResetPasswordSent;
