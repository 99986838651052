import type { Step } from 'react-joyride';
import { useTeamStore } from '../../store/team-state';

export const adminStep: Step[] = [
  {
    target: 'body',
    placement: 'center',
    title: 'Selamat Datang di Team Setting!',
    content: (
      <>
        <div>
          Team Setting adalah menu untuk setup Customer Center sehingga kita bisa menerima chat atau
          panggilan dari user.
        </div>
      </>
    )
  },
  {
    target: '#business_unit_list-create_button',
    title: 'Buat Business Unit Baru',
    content: (
      <>
        <div>
          Buat <i>Business Unit</i> baru untuk menampung inbox yang akan menjadi tempat untuk
          mengumpulkan chat atau panggilan
        </div>
      </>
    ),
    disableBeacon: true
  },
  {
    target: 'body',
    placement: 'center',
    title: 'Buat Business Unit Baru',
    content: (
      <>
        <div>
          Untuk membuat <i>Business Unit</i> baru, kita perlu mengisi beberapa informasi seperti
          nama dan deskripsi dari Business Unit tersebut.
        </div>
      </>
    ),
    disableBeacon: true
  },
  {
    target: '#business_unit_form',
    placement: 'left',
    title: 'Buat Business Unit Baru',
    content: (
      <>
        <div>
          Untuk membuat <i>Business Unit</i> baru, kita perlu mengisi beberapa informasi seperti
          nama, deskripsi, dan negara dari Business Unit tersebut.
        </div>
      </>
    ),
    disableBeacon: true
  },
  {
    target: '#business_unit_list',
    title: 'Daftar Business Unit Baru',
    content: (
      <>
        <div>
          <i>Business Unit</i> yang baru dibuat, akan masuk kedalam daftar ini.
        </div>
      </>
    ),
    disableBeacon: true
  },
  {
    target: 'body',
    placement: 'center',
    title: 'Selamat!',
    content: (
      <>
        <div>Kamu sudah selesai melewati tutorial untuk Administrator Team Setting.</div>
      </>
    ),
    disableBeacon: true
  }
];

export const supervisorStep: Step[] = [
  {
    target: 'body',
    placement: 'center',
    title: 'Selamat Datang di Team Setting!',
    content: (
      <>
        <div>
          Team Setting adalah menu untuk setup Customer Center sehingga kita bisa menerima chat atau
          panggilan dari user.
        </div>
      </>
    )
  },
  {
    target: `#inbox_list-create_button-${useTeamStore.getState().inbox[0]?.accountId}`,
    title: 'Buat Team Baru',
    content: (
      <>
        <div>
          Buat Team baru untuk menampung agent yang nantinya akan menerima panggilan dari customer.
        </div>
      </>
    ),
    disableBeacon: true
  },
  {
    target: 'body',
    placement: 'center',
    title: 'Buat Team Baru',
    content: (
      <>
        <div>
          Untuk membuat Team baru, kita perlu mengisi beberapa informasi seperti nama dan deskripsi
          dari Team tersebut.
        </div>
      </>
    ),
    disableBeacon: true
  },
  {
    target: '#inbox_form',
    placement: 'left',
    title: 'Buat Team Baru',
    content: (
      <>
        <div>
          Untuk membuat Team baru, kita perlu mengisi beberapa informasi seperti nama, situs, dan
          total panggilan mingguan dari Team tersebut.
        </div>
      </>
    ),
    disableBeacon: true
  },
  {
    target: '#inbox_list',
    title: 'Daftar Team',
    content: (
      <>
        <div>Team yang baru dibuat, akan masuk kedalam daftar ini.</div>
      </>
    ),
    disableBeacon: true
  },
  {
    target: `#inbox_detail-edit_button-${useTeamStore.getState().inbox[0]?.inboxList[0].inboxId}`,
    title: 'Ubah Team',
    content: (
      <>
        <div>Gunakan icon ini untuk melakukan perubahan pada Team.</div>
      </>
    ),
    disableBeacon: true
  },
  {
    target: `#inbox_detail-user_counter-${useTeamStore.getState().inbox[0]?.inboxList[0].inboxId}`,
    title: 'Agent Dalam Team',
    content: (
      <>
        <div>Indikator ini menandakan jumlah agent yang ada didalam suatu Team.</div>
      </>
    ),
    disableBeacon: true
  },
  {
    target: 'body',
    placement: 'center',
    title: 'Buat Agent Baru',
    content: (
      <>
        <div>
          Setelah selesai membuat Team, kita bisa membuat agent untuk menerima panggilan dari
          customer.
        </div>
      </>
    ),
    disableBeacon: true
  },
  {
    target: `#inbox_list-create_button-${useTeamStore.getState().inbox[0]?.accountId}`,
    title: 'Buat Agent Baru',
    placement: 'right',
    content: (
      <>
        <div>
          Untuk membuat agent baru, kita bisa menggunakan tombol ini. Dengan menggunakan tombol ini.
        </div>
      </>
    ),
    disableBeacon: true
  },
  {
    target: '#user_form',
    placement: 'left',
    title: 'Buat Agent Baru',
    content: (
      <>
        <div>
          Untuk membuat Agent baru, kita perlu mengisi beberapa informasi dari Agent tersebut.
        </div>
      </>
    ),
    disableBeacon: true
  },
  {
    target: `#user_list-${useTeamStore.getState().inbox[0]?.inboxList[0].inboxId}`,
    title: 'Daftar Agent',
    content: (
      <>
        <div>Agent yang dibuat akan masuk kedalam daftar ini.</div>
      </>
    ),
    disableBeacon: true
  },
  {
    target: 'body',
    placement: 'center',
    title: 'Selamat!',
    content: (
      <>
        <div>Kamu sudah selesai melewati tutorial untuk Supervisor Team Setting.</div>
      </>
    ),
    disableBeacon: true
  }
];
