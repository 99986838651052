export enum CreateTeamFormSection {
  FORM_TEAM,
  FORM_ASSIGN_AGENT,
  FORM_OPERATIONAL_SCHEDULE,
  FORM_DISPLAY
}

export enum ChannelEnum {
  WEB_WIDGET = 'WEB_WIDGET',
  WHATSAPP = 'WHATSAPP'
}
