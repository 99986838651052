import { useQuery } from 'react-query';
import { fetchCustomerDetails } from '../../customer';
import { ConversationInterface } from '../../../components/card/chat-message/chat-message.interface';
import { useSelectedCustomerStore } from '../../../store/customer-state';
import { getAccountId } from '../../../utils/getAccountId';

export const useCustomerDetails = (
  contactId: string,
  isSelected?: boolean | null,
  selectedCall?: ConversationInterface | null
) => {
  const { setDob } = useSelectedCustomerStore();
  const accountId = getAccountId();

  return useQuery(
    ['CustomerDetails', contactId, selectedCall, accountId],
    () => fetchCustomerDetails(accountId, contactId),
    {
      enabled: isSelected !== null ? isSelected : false,
      onSuccess: (data) => {
        setDob(data.dateOfBirth);
      }
    } // Query will run only if inboxId is not null
  );
};
