export interface SetTimeoutInterface {
  onSetTimeout: () => void;
  interval: number;
}

export default function handleSetTimeout(props: SetTimeoutInterface) {
  const { interval, onSetTimeout } = props;

  setTimeout(onSetTimeout, interval);
}
