import { Typography } from '@squantumengine/horizon';

type CustomLabelProps = {
  label: string;
};

const CustomLabel: React.FC<CustomLabelProps> = ({ label }) => (
  <Typography.Paragraph size="r" weight="bold">
    {label}
  </Typography.Paragraph>
);

export default CustomLabel;
