import { Icon, TextField } from '@squantumengine/horizon';
import { Select } from 'antd';
import React, { useEffect, useRef } from 'react';
import DatePickerComponent from './datepicker';
import { debounce } from '../../../../../utils/rate-limit';
import dayjs from 'dayjs';
import { useUserStatusStore } from '../../../../../store/user-state';
import { RoleEnum } from '../../../../../static/role';

const DEFAULT_FILTER_OPTIONS = [
  { label: 'E-mail', value: 'email' },
  { label: 'Nomor Tiket', value: 'ticket' },
  { label: 'Nomor Telepon', value: 'phone' },
  { label: 'Nama Pelanggan', value: 'name' }
];

interface FilterProps {
  onChange: (values: Record<string, any>) => void;
}

export default function Filter({ onChange }: FilterProps) {
  const [filters, setFilters] = React.useState({
    filterBy: { key: DEFAULT_FILTER_OPTIONS[0].value, value: '' },
    startDate: dayjs().add(-6, 'd').startOf('day'),
    endDate: dayjs().endOf('day')
  });

  const selectedFilterStyle = { borderLeft: '2px solid #006CEB' };
  const { userRole } = useUserStatusStore();
  const hasMounted = useRef(false);
  useEffect(() => {
    if (hasMounted.current) onChange(filters);
    else if (!hasMounted.current) hasMounted.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  return (
    <div className="flex space-x-4">
      <TextField
        prefix={<Icon name="search" />}
        placeholder="Cari"
        full
        suffix={
          <Select
            className="mb-[2px] h-10"
            popupMatchSelectWidth={false}
            defaultValue={filters.filterBy.key}
            onChange={(value) => {
              setFilters((prev) => ({
                ...prev,
                filterBy: { ...prev.filterBy, key: value }
              }));
            }}
            popupClassName="p-0">
            {DEFAULT_FILTER_OPTIONS.map((option) => (
              <Select.Option
                style={filters.filterBy.key === option.value ? selectedFilterStyle : {}}
                key={option.value}
                value={option.value}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        }
        onChange={debounce((value: string) => {
          setFilters((prev) => ({
            ...prev,
            filterBy: { ...prev.filterBy, value }
          }));
        }, 300)}
      />
      {userRole !== RoleEnum.AGENT && (
        <div className="min-w-[240px]">
          <DatePickerComponent
            onChange={([startDate, endDate]) => {
              setFilters((prev) => ({ ...prev, startDate, endDate }));
            }}
          />
        </div>
      )}
    </div>
  );
}
