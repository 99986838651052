import { useState, useEffect } from 'react';
import { Col, Row, Spin } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { AccountInterface } from '../../shared/types/account.interface';
import { FormTypeEnum, FormActionEnum } from '../../static/team';
import { useTeamStore } from '../../store/team-state';
import { FormRenderer } from './team-form-renderer';

import ListBusinessUnit from '../../components/card/list-business-unit';
import { RoleEnum } from '../../static/role';

function AdminTeamPage() {
  const {
    isOpenForm,
    formAction,
    formType,
    setIsOpenForm,
    setFormAction,
    setFormType,
    setSelectedBU,
    fetchAccount,
    resetTeamActiveHandler
  } = useTeamStore();
  const [loading, setLoading] = useState(false);
  const isCreateAccount = useTeamStore(
    (state) => state.formAction === FormActionEnum.CREATE && state.formType === FormTypeEnum.ACCOUNT
  );

  const toggleCreateAccount = () => {
    // Reset selected BU when user click create button
    setSelectedBU({} as AccountInterface);
    // resetAllCreateEditState();
    const openBUForm = !(
      isOpenForm &&
      formAction === FormActionEnum.CREATE &&
      formType === FormTypeEnum.ACCOUNT
    );
    resetTeamActiveHandler();
    setIsOpenForm(openBUForm);
    setFormAction(FormActionEnum.CREATE);
    setFormType(FormTypeEnum.ACCOUNT);
  };

  const initAccount = async () => {
    setLoading(true);
    await fetchAccount();
    setLoading(false);
  };

  useEffect(() => {
    initAccount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Row className="bg-white">
        <Col span={16} className="max-h-[calc(100vh-64px)] overflow-y-auto overflow-x-hidden">
          <Spin spinning={loading}>
            <Row className="p-6">
              <Col span={8}>
                <div className="text-3xl font-semibold">Unit Bisnis</div>
              </Col>
              <Col span={16} className="flex flex-row justify-end">
                {/* <div
                  className={`mr-6 flex h-12 w-52 flex-row justify-center rounded-3xl 
                border border-solid border-black-90 py-3 align-middle text-base font-semibold 
                hover:cursor-pointer hover:bg-grey-20
                active:bg-grey-30`}>
                  Butuh Persetujuan (5)
                </div> */}
                <div
                  id="business_unit_list-create_button"
                  className={`${
                    isCreateAccount
                      ? 'border-4 border-green-emerald-light py-2.5'
                      : 'border border-black-90 py-3'
                  } flex h-12 w-52 flex-row items-center justify-center 
                rounded-3xl border-solid text-base font-semibold 
                transition-all duration-75
                hover:cursor-pointer hover:bg-grey-20
                active:bg-grey-30`}
                  onClick={() => toggleCreateAccount()}>
                  Tambah Unit Bisnis&nbsp;&nbsp;
                  <PlusOutlined />
                </div>
              </Col>
            </Row>
            <Row className="p-6">
              <Col id="business_unit_list" span={24}>
                <ListBusinessUnit />
              </Col>
            </Row>
          </Spin>
        </Col>
        <Col
          span={8}
          className="h-screen max-h-[calc(100vh-64px)] overflow-y-auto overflow-x-hidden bg-grey-10">
          <div className="p-6">
            <div>{FormRenderer(isOpenForm, formAction, formType, RoleEnum.ADMIN)}</div>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default AdminTeamPage;
