export enum MessageEventEnum {
  CUST_HEART_BEAT = 'CUST_HEART_BEAT',
  ASSIGNED = 'ASSIGNED_CONVERSATION',
  UNASSIGNMENT = 'UNASSIGNED_CONVERSATION',
  ACTIVE = 'ACTIVE_CONVERSATION',
  SESSION_CLOSED = 'SESSION_CLOSED',
  INACTIVE = 'INACTIVE_CONVERSATION',
  CREATED = 'MESSAGE_CREATED',
  NETWORK_SIGNAL_CHANGE = 'NETWORK_SIGNAL_CHANGE',
  NEW_WEBRTC_TRANSPORT_PRODUCER = 'NEW_WEBRTC_TRANSPORT_PRODUCER',
  MEDIA_SERVER_PRODUCERS = 'MEDIA_SERVER_PRODUCERS',
  CONSUMER_PAUSED = 'CONSUMER_PAUSED',
  CONSUMER_RESUMED = 'CONSUMER_RESUMED',
  CONSUMER_CLOSED = 'CONSUMER_CLOSED',
  JOIN_MEETING_ROOM = 'JOIN_MEETING_ROOM',
  USER_JOINED_MEETING_ROOM = 'USER_JOINED_MEETING_ROOM',
  WEBRTC_TRANSPORT = 'WEBRTC_TRANSPORT',
  CREATE_WEBRTC_TRANSPORT = 'CREATE_WEBRTC_TRANSPORT',
  CONNECT_WEBRTC_TRANSPORT = 'CONNECT_WEBRTC_TRANSPORT',
  CREATE_WEBRTC_TRANSPORT_PRODUCER = 'CREATE_WEBRTC_TRANSPORT_PRODUCER',
  RESUME_CONSUMER_STREAM_REQUEST = 'RESUME_CONSUMER_STREAM_REQUEST',
  CREATE_WEBRTC_TRANSPORT_CONSUMER = 'CREATE_WEBRTC_TRANSPORT_CONSUMER',
  PRODUCER_PAUSE = 'PRODUCER_PAUSE',
  PRODUCER_CLOSE = 'PRODUCER_CLOSE',
  PRODUCER_CLOSED = 'PRODUCER_CLOSED',
  PRODUCER_RESUME = 'PRODUCER_RESUME',
  LEAVE_MEETING_ROOM = 'LEAVE_MEETING_ROOM',
  CONTACT = 'CONTACT',
  IN_COMING = 'IN_COMING',
  OUT_GOING = 'OUT_GOING',
  MEDIA_CALL = 'MEDIA_CALL',
  CALL = 'CALL',
  FILE = 'FILE',
  TYPING = 'TYPING',
  TEXT = 'TEXT',
  PING = 'PING',
  OFFLINE = 'OFFLINE',
  SENT_CONVERSATION_SUMMARY = 'SENT_CONVERSATION_SUMMARY',
  GET_RTP_CAPABILITIES = 'GET_RTP_CAPABILITIES',
  RTP_CAPABILITIES = 'RTP_CAPABILITIES',
  USER_LEFT_MEETING_ROOM = 'USER_LEFT_MEETING_ROOM',
  CLOSURE_CONVERSATION = 'CLOSURE_CONVERSATION',
  INCOMING_QUEUE = 'QUEUE_NUMBER_CHANGE',
  PRE_QUEUE = 'PRE_QUEUE',
  RESTART_ICE = 'RESTART_ICE',
  AGENT_ONLINE = 'AGENT_ONLINE',
  AGENT_OFFLINE = 'AGENT_OFFLINE',
  DROPCALL = 'DROPPED_CONVERSATION',
  AGENT_CAPACITY_CHANGE = 'AGENT_CAPACITY_CHANGE_EVENT',
  REQUEST_VIDEO_CALL_EVENT = 'REQUEST_VIDEO_CALL_EVENT'
}

export enum ConversationStatusEnum {
  PRE_CLOSURE = 'PRE_CLOSURE',
  OPEN = 'OPEN',
  RESOLVED = 'RESOLVED',
  IN_PROGRESS = 'IN_PROGRESS'
}

export enum CloseConversationEnum {
  PRECLOSE = 'PRECLOSE',
  CLOSE = 'CLOSE'
}

export enum OfflineConversationEnum {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED'
}

export interface ResolveConversationInterface {
  topic: string;
  background: string;
  request?: string;
  solution: string;
  notes: string;
}
