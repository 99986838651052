import { Chip } from '@squantumengine/horizon';
import { TabConversation } from '../../dashboard-supevisor.interface';
import { useUserStatusStore } from '../../../../../store/user-state';
import { RoleEnum } from '../../../../../static/role';
type Props = {
  onChange: (value: TabConversation) => void;
  value: TabConversation;
};

export default function TabFilterConversation({ onChange, value }: Props) {
  const { userRole } = useUserStatusStore();
  const items = Object.entries(TabConversation).map(([_, value]) => ({
    label: value,
    value: value as TabConversation
  }));
  const isAgent = userRole === RoleEnum.AGENT;

  return (
    <div className={`${isAgent && 'px-2'} no-scrollbar flex space-x-2 overflow-scroll`}>
      {items.map((item) => (
        <Chip
          key={item.value}
          label={item.label}
          isActive={item.value === value}
          onClick={() => onChange(item.value)}
        />
      ))}
    </div>
  );
}
