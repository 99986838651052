import { Paragraph } from '@squantumengine/horizon';

const ViewNoAgent = () => {
  return (
    <div className="space-y-4 overflow-scroll pb-16">
      <div className="space-y-4 rounded-xl bg-white p-6 shadow-md">
        <h3 className="m-0 flex items-center space-x-2 text-lg font-semibold">Tugaskan Agent</h3>
        <div className="h-[360px]">
          <div className="h-full rounded-lg border border-solid border-gray-300 p-4">
            <Paragraph size="l" weight="bold">
              Agen Tersedia (0)
            </Paragraph>

            <div className="flex h-full flex-col items-center justify-center">
              <Paragraph size="l" className="text-center">
                Tidak ada Agen Tersedia.
              </Paragraph>
              <Paragraph size="l" className="text-center">
                Silakan undang Agen baru lewat menu{' '}
                <span className="cursor-pointer font-bold text-blue-500">Atur Agen</span>.
              </Paragraph>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewNoAgent;
