import { useEffect, useState } from 'react';
import WorkspaceSubmitted from '../form/workspace-submitted';
import { KYCPageEnum, KYCPageInterface } from './kyc.interface';
import KYCSummary from './kyc-summary';
import ManualKYC from './kyc-manual';
import { useChatListStore } from '../../store/chat-list';

function KYCPage(props: KYCPageInterface) {
  const { defaultPage = KYCPageEnum.KYC } = props;
  const { selectedCall } = useChatListStore();
  const conversationId = selectedCall?.conversationId;
  const [page, setPage] = useState<number>(defaultPage);

  useEffect(() => {
    setPage(defaultPage);
    // eslint-disable-next-line
  }, [conversationId]);

  switch (page) {
    case KYCPageEnum.KYC:
      return <ManualKYC setPage={setPage} />;
    case KYCPageEnum.SUMMARY:
      return <KYCSummary setPage={setPage} />;
    case KYCPageEnum.SUBMITTED:
      return (
        <div className="customer-form rounded-2xl border border-solid border-black p-6">
          <WorkspaceSubmitted />
        </div>
      );
    default:
      return <></>;
  }
}

export default KYCPage;
