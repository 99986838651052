import { useQuery } from 'react-query';
import { getVerdictKYC } from '../../kyc';
import { ConversationInterface } from '../../../components/card/chat-message/chat-message.interface';
import { ConversationContextEnum } from '../../../static/communication-type';
import { isNullOrUndefined } from '../../../utils/isNullUndefined';
import { useUserStatusStore } from '../../../store/user-state';
import { RoleEnum } from '../../../static/role';
import { ConversationStatusEnum } from '../../../static/message-event';

export const useGetVerdictKYC = (selectedCall: ConversationInterface | null) => {
  const conversationId = selectedCall!?.conversationId;
  const { userRole } = useUserStatusStore();
  const isManualKYC =
    selectedCall!?.inboxConfig?.conversationContext === ConversationContextEnum.KYC;
  const refId = selectedCall?.metadata?.reference_id;
  const checkRefId =
    (!isNullOrUndefined(refId) && userRole === RoleEnum.AGENT) || userRole === RoleEnum.SUPERVISOR;
  const isResolved = selectedCall?.conversationStatus === ConversationStatusEnum.RESOLVED;
  const isOpened = selectedCall?.conversationStatus === ConversationStatusEnum.OPEN;

  const isSubmitted = localStorage.getItem('isKYCSubmitted') === 'true';

  return useQuery(['get-verdict-kyc', conversationId], () => getVerdictKYC(conversationId), {
    enabled:
      checkRefId &&
      !isNullOrUndefined(conversationId) &&
      isManualKYC &&
      (isResolved || isSubmitted || (isOpened && !isSubmitted)),
    retry: 1
  });
};
