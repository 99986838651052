import { messageHistory } from '../../../../config/message-history';
import { MessageEventEnum } from '../../../../static/message-event';
import { saveAs } from 'file-saver';
import { TimestampToStringDateAndTime } from '../../../../utils/timestamp';

export const downloadChat = async (
  conversationId: string,
  createdAt: number,
  customerName: string
) => {
  const res = await messageHistory(conversationId, undefined, true);
  const chatList = res.messageList;

  let txtData = '';
  chatList.reverse().forEach((chat: any) => {
    const { sender, content, timestamp, documents, senderType } = chat;
    const time = TimestampToStringDateAndTime(new Date(timestamp));
    const senderName = sender.name;
    const senderCategory = senderType === MessageEventEnum.CONTACT ? 'Customer' : 'Agent';
    const msg = !content && documents ? `${documents[0].name} <File>` : content;

    txtData += `${time} - ${senderName} (${senderCategory}): ${msg}\n`;
  });

  saveAs(
    new Blob([txtData], { type: 'text/plain;charset=utf-8' }),
    `transcript__${customerName}__${createdAt}.txt`
  );
};
