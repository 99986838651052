import { Sidebar, SidebarItemProps } from '@squantumengine/horizon';
import { InboxListItemInterface } from '../../../shared/types/inbox.interface';
import { SidebarItemEnum, sideBarRoutesV2 } from '../../../static/routes';
import { CurrentUserObjectInterface } from '../../../shared/types/user.interface';
import { RoleEnum } from '../../../static/role';
import { useEffect, useMemo, useState } from 'react';
import { useChatListStore } from '../../../store/chat-list';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSidebarStore } from '../../../store/new/sidebar';

type SideBarV2Props = {
  inboxList: InboxListItemInterface[] | undefined;
  currentUser: CurrentUserObjectInterface | undefined;
  handleInboxItemClick: (menu: SidebarItemEnum, inbox: InboxListItemInterface) => void;
};

const SidebarV2 = ({ inboxList, currentUser, handleInboxItemClick }: SideBarV2Props) => {
  const [selectedSidebarId, setSelectedSidebarId] = useState<string>(SidebarItemEnum.HOME);
  const [defaultOpenMenus, setDefaultOpenMenus] = useState<string[]>([SidebarItemEnum.HOME]);
  const { unreadMessagesInboxId } = useChatListStore();
  const navigate = useNavigate();
  const { setIsExpanded } = useSidebarStore();
  const location = useLocation();
  let { inboxId: urlInboxId } = useParams();

  const sidebarItems = useMemo(
    () =>
      sideBarRoutesV2
        .filter((item) => item.allow?.includes(currentUser?.role as RoleEnum))
        .map((item) => {
          if ([SidebarItemEnum.HOME, SidebarItemEnum.ANALYTICS].includes(item.id)) {
            /**
             * for analytics only show General Inquiry inbox since KYC related analytics still in development
             */
            return {
              ...item,
              children: inboxList?.map((inbox) => {
                const subMenuItem: any = {
                  id: `${item.id}||${inbox.inboxId}`,
                  text: <div className="leading-tight">{inbox.name}</div>
                };
                if (item.id === SidebarItemEnum.HOME && unreadMessagesInboxId[inbox.inboxId]) {
                  const unreadMessageCount = unreadMessagesInboxId[inbox.inboxId] || 0;
                  if (unreadMessageCount > 0)
                    subMenuItem.alert = (
                      <span className="hz-text-white hz-bg-red-500 hz-rounded-full hz-text-body-s-bold hz-w-4 hz-h-4 hz-flex hz-items-center hz-justify-center">
                        {unreadMessageCount}
                      </span>
                    );
                }
                return subMenuItem;
              })
            };
          }
          return {
            ...item
          };
        }),
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [sideBarRoutesV2, inboxList, currentUser, unreadMessagesInboxId, selectedSidebarId]
  );

  const onMenuItemClick = (val: SidebarItemProps) => {
    let menuId = '';
    let inboxId = '';

    if (val.id.includes('||')) {
      const id = val.id.split('||');
      menuId = id[0];
      inboxId = id[1];
    }

    if (inboxId) {
      const inbox = inboxList!.find((inbox) => inbox.inboxId === inboxId);
      handleInboxItemClick(menuId as SidebarItemEnum, inbox!);
    } else {
      navigate(val.url!);
    }

    setSelectedSidebarId(val.id);
  };

  useEffect(() => {
    const currentActiveMenu = sidebarItems.find((item) => location.pathname.includes(item.url));

    if (currentActiveMenu) {
      setDefaultOpenMenus([currentActiveMenu.id]);

      if (currentActiveMenu.id === SidebarItemEnum.TEAM) {
        const childActiveMenu = currentActiveMenu.children!.find((item) =>
          location.pathname.includes(item.url)
        );

        if (childActiveMenu) setSelectedSidebarId(childActiveMenu.id);
        return;
      }

      if (currentActiveMenu.children?.length) {
        setSelectedSidebarId(`${currentActiveMenu.id}||${urlInboxId}`);
        return;
      }

      setSelectedSidebarId(currentActiveMenu.id);
    }
  }, [urlInboxId, sidebarItems, location.pathname]);

  return (
    <Sidebar
      collapsible
      defaultOpenMenus={defaultOpenMenus}
      items={sidebarItems}
      selectedMenu={selectedSidebarId}
      width={268}
      onClick={onMenuItemClick}
      onExpanded={setIsExpanded}
    />
  );
};

export default SidebarV2;
