import { Typography } from '@squantumengine/horizon';
import WrongUrlIcon from '../assets/icons/wrong-url';

const WrongUrlPage = () => {
  return (
    <div className="flex h-screen w-full flex-col items-center justify-center">
      <WrongUrlIcon width={250} height={200}></WrongUrlIcon>
      <Typography.Title level={4} className="text-neutral-950">
        Link Login Salah
      </Typography.Title>
      <Typography.Paragraph className='max-w-[480px] text-center text-neutral-950'>
        Harap hubungi admin atau supervisor untuk mendapatkan link login yang benar atau bantuan
        lebih lanjut.
      </Typography.Paragraph>
    </div>
  );
};

export default WrongUrlPage;
