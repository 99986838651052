import { useQuery } from 'react-query';
import axiosInstance from '../config/axios';
import { buildQueryString } from '../utils/string';

const accountId = JSON.parse(localStorage.user || '{}')?.account?.accountId;

export const fetchHistoryChat = async (contactId: string, filter: Record<string, any>) => {
  return axiosInstance.get(
    `/account/${accountId}/contact/${contactId}/conversation?${buildQueryString(filter)}`
  );
};

export const useConversationList = (
  contactId: string,
  filter: Record<string, any>,
  options?: Record<string, any>
) =>
  useQuery(
    ['useConversationHistory', contactId, filter],
    () => fetchHistoryChat(contactId, filter),
    options
  );
