import { Flex, Modal } from 'antd';
import { Paragraph, Title } from '@squantumengine/horizon';
import useCCToaster from '../../../../hooks/useCCToaster';
import FormChatFooter from '../chat-footer/form-chat-footer';
import { CloseOutlined } from '@ant-design/icons';
import { confirmModalEndSession } from '../../../../static/communication-type';
import { SummaryModalInterface } from './close-session.interface';
import { TypeSummaryEnum } from '../chat-footer/chat-footer.interface';

export default function SummaryModal({
  openModalConfirm,
  setOpenModalConfirm,
  onClickEndTicket
}: SummaryModalInterface) {
  const { contextHolder, openNotification } = useCCToaster({ timeout: 3 });

  return (
    <Modal
      title={
        <Flex align="center" justify="space-between">
          <Title level={4}>{confirmModalEndSession.title}</Title>
          <CloseOutlined onClick={() => setOpenModalConfirm(false)} />
        </Flex>
      }
      open={openModalConfirm}
      footer={false}
      closable={false}
      width={600}>
      {contextHolder}
      <Flex gap={6} vertical className="w-full">
        <Paragraph>Mohon isi ringkasan untuk menyelesaikan tiket.</Paragraph>
        <FormChatFooter
          setOpenModalConfirm={setOpenModalConfirm}
          openNotification={openNotification}
          onClickEndTicket={onClickEndTicket}
          type={TypeSummaryEnum.END_CHAT}
        />
      </Flex>
    </Modal>
  );
}
