import { useEffect, useState } from 'react';
import DetailBusyHour from './detail';
import { Dropdown, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';

import { BusyHourRangeColor, BusyHourStatus, FilterTimeEnum } from '../../../../static/analysis';
import { getBusyHourAnalytics } from '../../../../api/analytics';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import BusyHourFooter from './footer';
import BusyHourBarChart from './bar-chart';
import {
  BusyHourConversation,
  ChunkBusyHourType,
  ItemKeyBusyHour,
  groupByDay,
  itemsBusyHour
} from './busy-hour.interface';

type Props = { filterValues?: Record<string, any> };

function BusyHour(props: Props) {
  const { filterValues } = props;
  const xAxis = Array.from(Array(5).keys());
  const [selectedMenu, setSelectedMenu] = useState<number>(FilterTimeEnum.DAY);
  const [open, setOpen] = useState<boolean>(false);
  const [data, setData] = useState<BusyHourConversation>({});
  const [selectedData, setSelectedData] = useState<BusyHourConversation>({});

  const { inboxId } = useParams();

  /**
   * Renders the hour at the specified index.
   *
   * @param index - The index of the hour to render.
   * @returns The hour at the specified index as a string.
   */
  const renderHour = (index: number): string => {
    const hours: string[] = ['23.59', '18.00', '12.00', '06.00', '00.00'];
    return hours[index];
  };

  const {
    data: busyHourData,
    isLoading,
    isFetched
  } = useQuery(
    ['agentListData', inboxId, filterValues],
    () => getBusyHourAnalytics(String(inboxId), filterValues!),
    {
      enabled: !!inboxId
    }
  );

  /**
   * Handles the onClick event for the menu.
   *
   * @param e - The event object.
   */
  const onClick = (e: any): void => {
    const menu = JSON.parse(e.key);
    const busyHourItem = busyHourData?.dailyConversationCount;

    const groupByType = {
      [ItemKeyBusyHour.TODAY]: ChunkBusyHourType.DAY,
      [ItemKeyBusyHour.WEEK]: ChunkBusyHourType.WEEK,
      [ItemKeyBusyHour.MONTH]: ChunkBusyHourType.MONTH
    };

    setData(groupByDay(busyHourItem, groupByType[menu as ItemKeyBusyHour]));
    setSelectedMenu(menu);
  };

  useEffect(() => {
    const busyHourItem = busyHourData?.dailyConversationCount;

    if (isFetched && !!busyHourItem) {
      Object.keys(busyHourItem).forEach((key) => {
        return {
          [`${key}`]: {
            ...busyHourItem[key],
            hourly: busyHourItem[key].hourly?.reverse()
          }
        };
      });

      setData(groupByDay(busyHourItem, ChunkBusyHourType.DAY));
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [busyHourData]);

  return (
    <div className="mt-4 w-full">
      <h1 className="m-0 p-0 text-2xl">Jam Ramai</h1>
      <DetailBusyHour selectedData={selectedData} open={open} setOpen={setOpen} />
      <div className={`mt-4 flex w-full flex-row items-start justify-between gap-4`}>
        <div className="flex flex-col">
          {xAxis.map((_, index) => (
            <p
              key={index}
              data-testid="hour-label"
              className={`m-0 p-0 ${index !== 4 && 'h-[7.5rem]'} ${index === 3 && 'h-[6rem]'}`}>
              {renderHour(index)}
            </p>
          ))}
        </div>
        <div
          className={`flex w-full flex-row ${
            Object.keys(data).length <= 7 ? 'justify-evenly' : 'justify-between'
          } gap-4 overflow-x-auto`}>
          {!isLoading &&
            Object.keys(data).map((keyName, i) => {
              return (
                <div key={keyName} className="flex flex-col items-center gap-1">
                  {data[keyName]?.hourly?.map((item: any, idx: number) => (
                    <BusyHourBarChart
                      key={`${i}-${keyName}-${item}-${idx}`}
                      item={item}
                      total={data[keyName]?.total}
                      onClick={() => {
                        setSelectedData({
                          [`${keyName}`]: data[keyName]
                        });
                        setOpen(true);
                      }}
                    />
                  ))}
                  <p>{keyName}</p>
                </div>
              );
            })}
        </div>
      </div>
      <div className="flex w-full flex-row items-center justify-between">
        <div className="flex w-8/12 flex-row items-center gap-4">
          {<BusyHourFooter text="0%" bgColor={BusyHourRangeColor[BusyHourStatus.Empty]} />}
          {<BusyHourFooter text="1% - 15%" bgColor={BusyHourRangeColor[BusyHourStatus.Sparse]} />}
          {
            <BusyHourFooter
              text="16% - 30%"
              bgColor={BusyHourRangeColor[BusyHourStatus.Populated]}
            />
          }
          {
            <BusyHourFooter
              text="31% - 45%"
              bgColor={BusyHourRangeColor[BusyHourStatus.Moderate]}
            />
          }
          {<BusyHourFooter text="46% - 60%" bgColor={BusyHourRangeColor[BusyHourStatus.Crowded]} />}
          {<BusyHourFooter text="61% - 75%" bgColor={BusyHourRangeColor[BusyHourStatus.Packed]} />}
          {
            <BusyHourFooter
              text="76% - 100%"
              bgColor={BusyHourRangeColor[BusyHourStatus.OverCrowded]}
            />
          }
          <p>(Percakapan)</p>
        </div>
        <div className="flex w-4/12 flex-row items-center justify-end gap-2">
          <p className="m-0 p-0">Tampilan Data</p>
          <Dropdown
            className="mx-2 h-10 w-full max-w-[159px] cursor-pointer rounded-full border-solid border-grey-80 px-4"
            menu={{ items: itemsBusyHour, onClick }}>
            <Space className="flex w-full justify-between">
              <div className="w-full overflow-hidden truncate whitespace-nowrap">
                {itemsBusyHour?.[selectedMenu]?.label}
              </div>
              <DownOutlined />
            </Space>
          </Dropdown>
        </div>
      </div>
    </div>
  );
}

export default BusyHour;
