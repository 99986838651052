import { WorkspaceSummary } from '../api/param.interface';
import { SSOTokenObject } from '../shared/types/auth.interface';
import { CurrentUserObjectInterface } from '../shared/types/user.interface';
import { LocalStorageKey } from '../static/local-storage';

export const getUserJSON: () => CurrentUserObjectInterface = () => {
  const userJson = localStorage.getItem(LocalStorageKey.USER) || '{}';
  let user;
  if (userJson !== null) {
    try {
      user = JSON.parse(userJson);
      if (!user.name && (user.firstName || user.lastName)) {
        user.name = `${user.firstName} ${user.lastName}`;
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error parsing user JSON:', e);
    }
  }
  return user;
};

export const getUserAccessTokenObj: () => SSOTokenObject = () => {
  const accessToken = localStorage.getItem(LocalStorageKey.SQEID_TOKEN_OBJECT) || '{}';
  let accessTokenObj;
  if (accessToken !== null) {
    try {
      accessTokenObj = JSON.parse(accessToken);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error parsing accessToken JSON:', e);
    }
  }
  return accessTokenObj;
};

export const getSelectedCallJSON: () => any = () => {
  const selectedCallLocalStorage = localStorage.getItem('selectedCall') || '{}';
  let selectedCallJSON;
  if (selectedCallLocalStorage !== null) {
    try {
      selectedCallJSON = JSON.parse(selectedCallLocalStorage);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error parsing selectedCall JSON:', e);
    }
  }

  return selectedCallJSON;
};

export const setSelectedCallJSON = (newSelectedCall: string) => {
  localStorage.setItem('selectedCall', newSelectedCall);
};

export const getCustomerDetailsJSON: () => WorkspaceSummary = () => {
  const customerDetailsJson = localStorage.getItem('customerDetails') || '{}';
  let customerDetails;
  if (customerDetailsJson !== null) {
    try {
      customerDetails = JSON.parse(customerDetailsJson);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error parsing user JSON:', e);
    }
  }
  return customerDetails;
};

export const setCustomerDetailsJSON = (newCustomerDetails: string) => {
  localStorage.setItem('customerDetails', newCustomerDetails);
};
