import { Menu, MenuProps } from 'antd';
import { getIconUrl } from '../../../utils/getIconUrl';
import { ChatFooterInterface } from './chat-footer.inteface';
import { useEffect, useState } from 'react';
import Summary from './summary';
import { useChatListStore } from '../../../store/chat-list';
import { MessageEventEnum } from '../../../static/message-event';
import { ChatFooterEnum } from '../../../static/tab-list';
import { useCallStore } from '../../../store/call-state';

const ChatFooter = ({ chat, addChat, handleChange }: ChatFooterInterface) => {
  const { selectedCall } = useChatListStore();
  const { isManualKYCMode } = useCallStore();
  const [currentMenu, setCurrentMenu] = useState(0);
  const msgCounter = chat!.length;
  const maxCharacter = 1000;
  const warningMaxCharacter = 950;
  const isLimitCharacter = msgCounter > maxCharacter;
  const isPreClosure = selectedCall?.event === MessageEventEnum.SESSION_CLOSED;
  const onClickTab: MenuProps['onClick'] = (e) => {
    setCurrentMenu(JSON.parse(e.key));
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      !isLimitCharacter && addChat(event);
    }
  };
  const isAllowTxtMsg = selectedCall?.inboxConfig?.allowTextMessage;

  useEffect(() => {
    setCurrentMenu(
      isPreClosure || (!isAllowTxtMsg && !isManualKYCMode)
        ? ChatFooterEnum.SUMMARY
        : ChatFooterEnum.MESSAGE
    );
  }, [isPreClosure, isAllowTxtMsg, isManualKYCMode]);

  const ChatFooterMenus = [
    {
      label: 'Kirim pesan',
      key: ChatFooterEnum.MESSAGE,
      disabled: isPreClosure || (!isAllowTxtMsg && !isManualKYCMode)
    },
    {
      label: 'Isi Ringkasan',
      key: ChatFooterEnum.SUMMARY,
      disabled: false
    }
  ];

  return (
    <div className="min-h-[170px] bg-white p-4">
      <Menu
        className="w-full border-none bg-transparent focus:outline-none"
        onClick={onClickTab}
        selectedKeys={[`${currentMenu}`]}
        mode="horizontal"
        items={ChatFooterMenus}
      />
      {currentMenu === ChatFooterEnum.SUMMARY ? (
        <Summary />
      ) : (
        <form
          onSubmit={addChat}
          className="relative mt-2 flex h-24 w-full flex-row items-center gap-4 bg-white py-4">
          <div
            className={`mt-4 flex h-max flex-1 flex-row items-center overflow-hidden rounded-xl border border-solid ${
              isLimitCharacter ? 'border-red-700' : 'border-border-gray'
            } bg-white`}>
            <textarea
              style={{ resize: 'none' }}
              className="mb-9 h-full w-full border-none bg-white px-6 py-4 font-['Inter'] focus:outline-none"
              placeholder="Tulis Pesan"
              value={chat}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
            />
          </div>
          {msgCounter > warningMaxCharacter && (
            <span
              className={`absolute bottom-0 right-12 text-xs ${
                isLimitCharacter && 'text-red-700'
              }`}>
              {msgCounter}/{maxCharacter}
            </span>
          )}
          <button
            className={`absolute -bottom-2 right-1 flex aspect-square h-8 cursor-pointer items-center justify-center rounded-full border-none  ${
              isLimitCharacter ? 'cursor-not-allowed bg-gray-400' : 'bg-bold-green'
            }`}
            type="submit"
            disabled={isLimitCharacter}>
            <img
              src={getIconUrl('paper-plane.svg')}
              className="-translate-x-0.5"
              alt="PaperPlane"
            />
          </button>
        </form>
      )}
    </div>
  );
};
export default ChatFooter;
