import { WorkingHour } from '../../shared/types/operational-hour.interface';

export interface InboxInterface {
  inboxId: string;
  name: string;
  greetingEnabled: boolean;
  greetingMessage: string;
  enableAutoAssignment: boolean;
  workingHoursEnabled: boolean;
  oooMessage: string;
  timezone: string;
  channelType: string;
  target: number;
  inboxConfig: InboxConfig;
  longQueueMessage: string;
  waitingRoomMessage: string;
  workingHours: WorkingHour[];
  automatic_messages?: AutomaticMessages;
}

export interface InboxConfig {
  communicationModes: string[];
  allowTextMessage: boolean;
  allowAttachment: boolean;
  allowMediaCall: boolean;
  allowRefId: boolean;
  allowConversationSummary: boolean;
  allowConversationRating: boolean;
  mediaCallConfiguration: MediaCallConfiguration;
  conversationContext: ConversationContext;
  webhookIdentifier: string;
  credential: string;
  supportMode: string | null;
  decisionTreeId: string | null;
}

export interface MediaCallConfiguration {
  showVideoCallButton: boolean;
  showAudioCallButton: boolean;
  showScreenShareButton: boolean;
  autoConnect: boolean;
  showCapturePhoto: boolean;
}

export enum ConversationContext {
  GENERAL = 'GENERAL',
  MANUAL_KYC = 'MANUAL_KYC'
}

export interface AutomaticMessages {
  connected_to_agent: AutomaticMessage;
  greeting_message: AutomaticMessage;
  long_queue: AutomaticMessage;
  outside_working_hour: AutomaticMessage;
  waiting_room: AutomaticMessage;
  closing_remarks: AutomaticMessage;
}

export interface AutomaticMessage {
  id: string;
  name: string;
  description: string;
  precodings: Precodings[];
  createdAt: string;
  status: string;
  channel: string;
  content: {
    title: string;
    body: string;
  };
}

export interface Precodings {
  templatePrecodingKey: string;
  templatePrecodingValueKey: string;
}
