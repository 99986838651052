import { create } from 'zustand';
import { ConversationContext, InboxInterface } from '../../../types/new/inbox';
import { InboxStoreInterface } from './interface';

export const useInboxStore = create<InboxStoreInterface>((set, get) => ({
  inboxes: [],
  selectedInboxId: '',
  setInboxes: (inboxes: InboxInterface[]) => set({ inboxes }),
  getInboxById: (inboxId: string) => get().inboxes.find((inbox) => inbox.inboxId === inboxId),
  setSelectedInboxId: (selectedInboxId: string) => set({ selectedInboxId }),
  getSelectedInbox: () => get().inboxes.find((inbox) => inbox.inboxId === get().selectedInboxId),
  isSelectedInboxManualKyc: () =>
    get().getSelectedInbox()?.inboxConfig.conversationContext === ConversationContext.MANUAL_KYC
}));
