import { Col, Collapse, Row } from 'antd';
import { DownOutlined, PlusOutlined } from '@ant-design/icons';

import PencilIcon from '../../../assets/icons/pencil';
import { useTeamStore } from '../../../store/team-state';
import { AccountInterface } from '../../../shared/types/account.interface';
import { FormTypeEnum, FormActionEnum } from '../../../static/team';

import InboxList from '../list-inbox';
import ListSupervisor from '../list-supervisor';
import {
  TeamActiveHandlerInterface,
  TeamActiveHandlerKeysEnum
} from '../../../shared/types/team.interface';

export default function ListBusinessUnit() {
  const { Panel } = Collapse;
  const {
    isOpenForm,
    formAction,
    formType,
    account,
    selectedBU,
    teamActiveHandler,
    setIsOpenForm,
    setFormAction,
    setFormType,
    setSelectedBU,
    setTeamActiveHandler,
    fetchInbox,
    fetchUserInbox,
    resetTeamActiveHandler
  } = useTeamStore();

  const { IS_EDIT, IS_CREATE_INBOX, IS_CREATE_USER } = TeamActiveHandlerKeysEnum;

  const toggleAccountValue = (accountId: string, type: 'account' | 'inbox' | 'user') => {
    let selectedAccount = {} as AccountInterface;

    const toggleType =
      type === 'account'
        ? FormTypeEnum.ACCOUNT
        : type === 'inbox'
        ? FormTypeEnum.INBOX
        : FormTypeEnum.USER;
    const toggleAction = type === 'account' ? FormActionEnum.EDIT : FormActionEnum.CREATE;
    const toggleKey: typeof IS_EDIT | typeof IS_CREATE_INBOX | typeof IS_CREATE_USER =
      type === 'account' ? IS_EDIT : type === 'inbox' ? IS_CREATE_INBOX : IS_CREATE_USER;

    const openForm = !(
      isOpenForm &&
      formAction === toggleAction &&
      formType === toggleType &&
      selectedBU.accountId === accountId
    );

    resetTeamActiveHandler();

    const newTeamActiveHandler: TeamActiveHandlerInterface = {
      ...useTeamStore.getState().teamActiveHandler,
      activeAccountId: openForm ? accountId : ''
    };

    newTeamActiveHandler[toggleKey] = openForm;

    selectedAccount =
      account.accountList.find((accountItem) => accountItem.accountId === accountId) ||
      ({} as AccountInterface);

    setTeamActiveHandler({ ...newTeamActiveHandler });

    // Close form only if the user is edit business unit
    setIsOpenForm(openForm);
    setFormAction(toggleAction);
    setFormType(toggleType);
    setSelectedBU(selectedAccount);
  };

  const editBussinessUnit = (event: any, accountId: string) => {
    event.stopPropagation();
    toggleAccountValue(accountId, 'account');
  };

  const togglePanel = async (activeIndexes: string | string[], accountId: string) => {
    if (activeIndexes.length > 0) {
      await fetchInbox(accountId);
      const inboxStore = useTeamStore.getState().inbox;
      if (!!inboxStore.length) {
        const inboxIndex = inboxStore.findIndex((inboxItem) => inboxItem.accountId === accountId);
        const inboxItemList = inboxStore[inboxIndex]?.inboxList;

        if (inboxItemList) {
          for (let i = 0; i < inboxItemList.length; i++) {
            await fetchUserInbox(accountId, inboxItemList[i].inboxId);
          }
        }
      }
    }
  };

  const isEditAccount =
    teamActiveHandler.isEdit &&
    teamActiveHandler.activeInboxId === '' &&
    teamActiveHandler.activeUserId === '';

  return (
    <>
      {account.accountList &&
        account.accountList.length > 0 &&
        account.accountList.map((accountItem, idx) => (
          <Collapse
            key={`business-unit-${idx}`}
            className="mt-4"
            ghost
            expandIconPosition="end"
            expandIcon={(panelProps) => {
              return <DownOutlined rotate={panelProps.isActive ? 180 : 0} />;
            }}
            onChange={(idxs) => togglePanel(idxs, accountItem.accountId)}>
            <Panel
              className={`account-list-panel ${
                teamActiveHandler.activeAccountId === accountItem.accountId
                  ? 'border-4 border-green-emerald-light'
                  : 'border border-grey-80'
              } rounded-xl border-solid p-4 transition-all duration-100`}
              header={
                <>
                  <Row justify="space-between" align="middle" className="items-center">
                    <Col>
                      <div className="text-lg font-semibold">
                        {`${accountItem.name} `}
                        <span onClick={(e) => editBussinessUnit(e, accountItem.accountId)}>
                          <PencilIcon
                            className="ml-3 cursor-pointer transition-all duration-100 active:scale-90"
                            color={
                              isEditAccount &&
                              teamActiveHandler.activeAccountId === accountItem.accountId
                                ? '#2DB089'
                                : undefined
                            }
                          />
                        </span>
                      </div>
                      <div className="text-base">{accountItem.countryCode}</div>
                    </Col>
                  </Row>
                  <Row className="mt-4 text-base text-grey-100">{accountItem.description}</Row>
                </>
              }
              key={idx}>
              <Row className="justify-between">
                <Col
                  flex={1}
                  offset={1}
                  id={`inbox_list-create_button-${accountItem.accountId}`}
                  className={`${
                    teamActiveHandler.isCreateInbox &&
                    teamActiveHandler.activeAccountId === accountItem.accountId
                      ? 'border-4 border-green-emerald-light py-3.5'
                      : 'border border-black-90 px-0.5 py-4'
                  } team-action-button`}
                  onClick={() => toggleAccountValue(accountItem.accountId, 'inbox')}>
                  Tambah Team&nbsp;
                  <PlusOutlined />
                </Col>
              </Row>
              <ListSupervisor accountId={accountItem.accountId} />
              <InboxList accountId={accountItem.accountId} />
            </Panel>
          </Collapse>
        ))}
    </>
  );
}
