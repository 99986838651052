import { Col, Switch, Form, FormInstance, Row, Select, Flex } from 'antd';
import { MethodConvEnum, TypeCallEnum, TypeConvEnum } from '.';
import CustomSmallTooltip from '../../../../../components/form/inbox/custom-small-tooltip';
import {
  AccountIdentityEnum,
  WebhookIdentifierEnum,
  credentialOptions,
  webhookIdentifierOptions
} from '../../../../../static/communication-type';
import { ChannelEnum } from '../../type';
import { useEffect } from 'react';
import { useGetAccountDetail } from '../../../../../api/hooks/inbox/useFetchAccountDetail';

type Props = {
  form: FormInstance;
};

export default function TeamConfig(props: Props) {
  const { form } = props;

  const channelForm = Form.useWatch('channelType', form);
  const methodConvForm = Form.useWatch('communicationModes', form);
  const typeCallForm = Form.useWatch('defaultCommunicationType', form);
  const typeConversationForm = Form.useWatch('conversationContext', form);

  const isChatCall =
    methodConvForm?.includes(MethodConvEnum.CALL) && methodConvForm?.includes(MethodConvEnum.CHAT);
  const isChatCallVideoOnly = isChatCall && typeCallForm === TypeCallEnum.VIDEO;
  const isChatCallVoiceVideo = isChatCall && typeCallForm === TypeCallEnum.VOICE_VIDEO;
  const isChatOnly = !methodConvForm?.includes(MethodConvEnum.CALL);
  const isVideoOnly = !methodConvForm?.includes(MethodConvEnum.CHAT);
  const isChatAndVideo = methodConvForm?.includes(MethodConvEnum.CALL, MethodConvEnum.CHAT);
  const isWebWidget = channelForm === ChannelEnum.WEB_WIDGET;
  const isWhatsApp = channelForm === ChannelEnum.WHATSAPP;
  const isManualKYC = typeConversationForm === TypeConvEnum.MANUAL_KYC;
  const isGeneralEnquiry = typeConversationForm === TypeConvEnum.GENERAL;

  const { data: account } = useGetAccountDetail();
  const isAccountIdentityPhone = account?.accountIdentity === AccountIdentityEnum.PHONE;

  useEffect(() => {
    if (isAccountIdentityPhone) {
      form.setFieldsValue({
        allowConversationSummary: false
      });
    }
  }, [account]);

  useEffect(() => {
    if (isVideoOnly || isManualKYC) {
      form.setFieldsValue({
        allowTextMessage: false
      });
    } else if (isChatOnly || isChatAndVideo) {
      form.setFieldsValue({
        allowTextMessage: true
      });
    }
  }, [methodConvForm]);

  return (
    <>
      {isWebWidget && (
        <Row gutter={[16, 24]}>
          <Col span={isChatOnly ? 24 : 12}>
            <div className="rounded-xl border-[2px] border-solid border-gray-200 p-4">
              <h3 className="m-0 p-0 font-bold">Izin Akses Pelanggan</h3>
              {isGeneralEnquiry && (
                <Row justify="space-between" align="middle">
                  <Col span={12}>
                    <p className="m-0 p-0">Mengirim Lampiran</p>
                  </Col>
                  <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Form.Item
                      initialValue={true}
                      name="allowAttachment"
                      required
                      label={null}
                      valuePropName="checked"
                      className="m-0 p-0">
                      <Switch defaultChecked />
                    </Form.Item>
                  </Col>
                </Row>
              )}
              <Row justify="space-between" align="middle">
                <Col span={12}>
                  <p className="m-0 p-0">Meminta Ringkasan</p>
                </Col>
                <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Form.Item
                    initialValue={true}
                    name="allowConversationSummary"
                    required
                    valuePropName="checked"
                    label={null}
                    className="m-0 p-0">
                    <Switch defaultChecked disabled={isAccountIdentityPhone} />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="space-between" align="middle">
                <Col span={12}>
                  <p className="m-0 p-0">Mengirim Ulasan</p>
                </Col>
                <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Form.Item
                    initialValue={true}
                    name="allowConversationRating"
                    required
                    valuePropName="checked"
                    label={null}
                    className="m-0 p-0">
                    <Switch defaultChecked />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="space-between" align="middle">
                <Col span={12}>
                  <p className="m-0 p-0">Mengirim chat/teks</p>
                </Col>
                <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Form.Item
                    initialValue={true}
                    name="allowTextMessage"
                    required
                    valuePropName="checked"
                    label={null}
                    className="m-0 p-0">
                    <Switch disabled={isVideoOnly || isChatAndVideo || isChatOnly} defaultChecked />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Col>
          {(isChatCall || isManualKYC || (isGeneralEnquiry && isVideoOnly)) && (
            <Col span={12}>
              <div className="rounded-xl border-[2px] border-solid border-gray-200 p-4">
                <h3 className="m-0 p-0 font-bold">Pengaturan Panggilan</h3>
                {isManualKYC && (
                  <>
                    <Row justify="space-between" align="middle">
                      <Col span={12}>
                        <p className="m-0 p-0">Tombol ambil foto</p>
                      </Col>
                      <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Form.Item
                          initialValue={true}
                          name="showCapturePhoto"
                          required
                          valuePropName="checked"
                          label={null}
                          className="m-0 p-0">
                          <Switch defaultChecked />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row justify="space-between" align="middle">
                      <Col span={12}>
                        <p className="m-0 p-0">Tombol ganti kamera</p>
                      </Col>
                      <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Form.Item
                          initialValue={true}
                          name="switchCamera"
                          required
                          valuePropName="checked"
                          label={null}
                          className="m-0 p-0">
                          <Switch defaultChecked />
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                )}
                <Row justify="space-between" align="middle">
                  <Col span={12}>
                    <p className="m-0 p-0">Tombol aktifkan/matikan audio</p>
                  </Col>
                  <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Form.Item
                      initialValue={true}
                      name="showAudioCallButton"
                      required
                      valuePropName="checked"
                      label={null}
                      className="m-0 p-0">
                      <Switch defaultChecked />
                    </Form.Item>
                  </Col>
                </Row>
                <Row justify="space-between" align="middle">
                  <Col span={12}>
                    <p className="m-0 p-0">Tombol pilih audio</p>
                  </Col>
                  <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Form.Item
                      initialValue={true}
                      name="showChooseAudioButton"
                      required
                      valuePropName="checked"
                      label={null}
                      className="m-0 p-0">
                      <Switch defaultChecked />
                    </Form.Item>
                  </Col>
                </Row>
                {(isChatCallVideoOnly ||
                  isChatCallVoiceVideo ||
                  isManualKYC ||
                  (isVideoOnly &&
                    (typeCallForm === TypeCallEnum.VIDEO ||
                      typeCallForm === TypeCallEnum.VOICE_VIDEO))) && (
                  <>
                    <Row justify="space-between" align="middle">
                      <Col span={12}>
                        <p className="m-0 p-0">Tombol aktifkan/matikan video</p>
                      </Col>
                      <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Form.Item
                          initialValue={true}
                          name="showVideoCallButton"
                          required
                          valuePropName="checked"
                          label={null}
                          className="m-0 p-0">
                          <Switch defaultChecked />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row justify="space-between" align="middle">
                      <Col span={12}>
                        <p className="m-0 p-0">Tombol bagikan layar</p>
                      </Col>
                      <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Form.Item
                          initialValue={true}
                          name="showScreenShareButton"
                          required
                          valuePropName="checked"
                          label={null}
                          className="m-0 p-0">
                          <Switch defaultChecked />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row justify="space-between" align="middle">
                      <Col span={12}>
                        <p className="m-0 p-0">Terhubung otomatis</p>
                      </Col>
                      <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Form.Item
                          initialValue={true}
                          name="autoConnect"
                          required
                          label={null}
                          valuePropName="checked"
                          className="m-0 p-0">
                          <Switch defaultChecked />
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                )}
              </div>
            </Col>
          )}
          <Col span={24}>
            <div className="rounded-xl border-[2px] border-solid border-gray-200 p-4">
              <h3 className="m-0 p-0 font-bold">Pengaturan Tambahan</h3>
              <Row justify="space-between" align="middle">
                <Col span={12}>
                  <Flex align="center" gap="4">
                    <p className="m-0 p-0">Mengirim ID Refrensi</p>
                    <CustomSmallTooltip text="Tim atau Business Unit dapat mengirimkan data tambahan ke sistem contact center." />
                  </Flex>
                </Col>
                <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Form.Item
                    initialValue={true}
                    name="allowRefId"
                    required
                    label={null}
                    className="m-0 p-0">
                    <Switch defaultChecked />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="space-between" align="top">
                <Col span={12}>
                  <Flex align="center" gap="4">
                    <p className="m-0 p-0">Penggunaan Webhook</p>
                    <CustomSmallTooltip text="Webhook dapat digunakan untuk mengirimkan " />
                  </Flex>
                </Col>
                <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Form.Item
                    className="w-8/12"
                    name="webhookIdentifier"
                    initialValue={WebhookIdentifierEnum.NONE}>
                    <Select options={webhookIdentifierOptions} />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="space-between" align="top">
                <Col span={12}>
                  <Flex align="center" gap="4">
                    <p className="m-0 p-0">Identitas Kredensial</p>
                    <CustomSmallTooltip text="Identitas yang digunakan saat melakukan permintaan ke service lainnya." />
                  </Flex>
                </Col>
                <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Form.Item
                    className="w-8/12"
                    name="credential"
                    initialValue={WebhookIdentifierEnum.NONE}>
                    <Select options={credentialOptions} />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      )}
      {isWhatsApp && (
        <Row gutter={[16, 24]}>
          <Col span={24}>
            <div className="rounded-xl border-[2px] border-solid border-gray-200 p-4">
              <h3 className="m-0 p-0 font-bold">Izin Akses Agen</h3>

              <Row justify="space-between" align="middle">
                <Col span={12}>
                  <p className="m-0 p-0">Melihat Lampiran yang dikirim Pelanggan</p>
                </Col>
                <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Form.Item
                    initialValue={true}
                    name="allowAttachment_WA"
                    required
                    label={null}
                    className="m-0 p-0">
                    <Switch defaultChecked />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      )}
    </>
  );
}
