import { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ConfigProvider, Spin, Typography } from 'antd';
import { local } from '../../utils/storage';

import { useAuth } from '../../hooks/useAuth';
import { LocalStorageKey } from '../../static/local-storage';
import { AuthSIMASIDContextProps } from '../../shared/types/user.interface';

const { Title } = Typography;

function SSOCallbackPage() {
  const { loginSSO } = useAuth() as AuthSIMASIDContextProps;
  const [params] = useSearchParams();
  const code = params.get('code') || '';
  const username = params.get('username') || '';
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      local.setItem(LocalStorageKey.SQEID_AUTH_CODE, code);
      loginSSO(username);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex h-screen w-screen items-center justify-center">
      <div className="flex flex-col text-center">
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: '#2DB089'
            }
          }}>
          <Spin size="large" className="mb-[34px]" />
        </ConfigProvider>
        <Title level={5}>Menunggu Verifikasi...</Title>
      </div>
    </div>
  );
}

export default SSOCallbackPage;
