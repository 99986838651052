import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const ConnectionSVG = () => (
  <svg
    width="14"
    height="13"
    data-testid="connection-strength"
    viewBox="0 0 14 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fill="#2DB089"
      d="M 5.5 6 L 4.5 6 C 4.34375 6 4.25 6.125 4.25 6.25 L 4.25 12.75 C 4.25 12.9062 4.34375 13 4.5 13 L 5.5 13 C 5.625 13 5.75 12.9062 5.75 12.75 L 5.75 6.25 C 5.75 6.125 5.625 6 5.5 6 Z"
    />
    <path
      fill="#2DB089"
      d="M 13.5 0 L 12.5 0 C 12.3438 0 12.25 0.125 12.25 0.25 L 12.25 12.75 C 12.25 12.9062 12.3438 13 12.5 13 L 13.5 13 C 13.625 13 13.75 12.9062 13.75 12.75 L 13.75 0.25 C 13.75 0.125 13.625 0 13.5 0 Z"
    />
    <path
      fill="#2DB089"
      d="M 9.5 3 L 8.5 3 C 8.34375 3 8.25 3.125 8.25 3.25 L 8.25 12.75 C 8.25 12.9062 8.34375 13 8.5 13 L 9.5 13 C 9.625 13 9.75 12.9062 9.75 12.75 L 9.75 3.25 C 9.75 3.125 9.625 3 9.5 3 Z"
    />
    <path
      fill="#2DB089"
      d="M 1.5 9 L 0.5 9 C 0.34374999999999994 9 0.25 9.125 0.25 9.25 L 0.25 12.75 C 0.25 12.9062 0.34374999999999994 13 0.5 13 L 1.5 13 C 1.625 13 1.75 12.9062 1.75 12.75 L 1.75 9.25 C 1.75 9.125 1.625 9 1.5 9 Z"
    />
  </svg>
);

const ConnectionIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={ConnectionSVG} {...props} />
);

export default ConnectionIcon;
