import { useChatListStore } from '../../../../store/chat-list';
import { useUserStatusStore } from '../../../../store/user-state';
import TimestampToString from '../../../../utils/timestamp';

function OfflineMessageChatBox() {
  const { selectedOfflineChat } = useChatListStore();
  const { userRole } = useUserStatusStore();

  if (!selectedOfflineChat) return null;

  const { createdAt, customer, message } = selectedOfflineChat;
  const isSupervisor = userRole === 'SUPERVISOR';

  return (
    <>
      <div className="flex items-center justify-between">
        <p className="m-0 text-3xl font-semibold">Live Chat</p>
      </div>
      <div className="flex h-full min-h-[400px] flex-col overflow-hidden rounded-lg border-solid border-grey-80 bg-white-background">
        <div className="flex w-full flex-row items-center justify-start px-6 py-4 shadow-[0px_4px_16px_rgba(0,0,0,0.15)]">
          <div className="flex w-full flex-col items-start justify-start">
            <div className="flex w-full items-center justify-between text-lg font-semibold">
              <div className="flex items-center justify-center gap-6 overflow-hidden">
                <div className="h-2 w-2 min-w-[8px] rounded-full bg-red-imperial" />
                <div className="w-full truncate">{customer.name || 'Pelanggan'}</div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="chat-container"
          className="relative flex h-full min-h-[300px] w-full flex-col overflow-y-auto pb-4 pt-4">
          <div
            className={`mb-2 ml-6 mr-[72px] flex h-auto flex-col rounded-b-2xl
                rounded-r-2xl border border-solid border-border-gray bg-white p-4
                text-left transition-all duration-200`}>
            <div className="mb-2 flex flex-col whitespace-pre-line break-normal break-words text-base	font-normal">
              {isSupervisor && <div className="font-semibold">Pesan</div>}
              {message}
            </div>
            <div className="text-sm font-normal text-chat-gray">
              {TimestampToString(new Date(createdAt).getTime())}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OfflineMessageChatBox;
