import { create } from 'zustand';
import { WSAgentCapacityChange } from '../utils/webSocket/websocket-interfaces';
import {
  AgentAvailabilityInterface,
  InboxMemberListWithCapacityInterface
} from '../shared/types/inbox.interface';

export type AgentActivityState = {
  agentList: InboxMemberListWithCapacityInterface[];
  agentCapacityList: any[];
  setAgentList: (agentList: InboxMemberListWithCapacityInterface[]) => void;
  setAgentCapacityList: (agentCapacityList: AgentAvailabilityInterface[]) => void;
  addAgentCapacity: (agentId: string) => void;
  removeAgentCapacity: (agentId: string) => void;
  updateAgentCapacity: (agentActivity: WSAgentCapacityChange) => void;
};

const useAgentActivity = create<AgentActivityState>((set) => ({
  agentList: [],
  agentCapacityList: [],
  setAgentList: (agentList: InboxMemberListWithCapacityInterface[]) => set({ agentList }),
  addAgentCapacity: (agentId: string) => {
    set((state) => {
      const agent = state.agentList.find((agent) => {
        return agent.agentId === agentId;
      });
      const newList = [...state.agentCapacityList].filter((agent) => agent?.agentId !== agentId);
      return { agentCapacityList: [...newList, agent].filter((a) => !!a) };
    });
  },
  removeAgentCapacity: (agentId: string) => {
    set((state) => ({
      agentCapacityList: state.agentCapacityList.filter((agent) => agent?.agentId !== agentId)
    }));
  },
  setAgentCapacityList: (agentCapacityList: AgentAvailabilityInterface[]) =>
    set({ agentCapacityList }),
  updateAgentCapacity: ({
    agentId,
    onGoingCallCustomers: onGoingCallCapacity,
    onGoingChatCustomers: onGoingChatCapacity
  }: WSAgentCapacityChange) => {
    set((state) => ({
      agentCapacityList: state.agentCapacityList.map((agent) =>
        agent.agentId === agentId
          ? {
              ...agent,
              capacity: { ...agent.capacity, onGoingChatCapacity, onGoingCallCapacity }
            }
          : agent
      )
    }));
  }
}));

export default useAgentActivity;
