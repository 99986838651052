import { Form, Input } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getUrlParam } from '../../utils/url-search-params';
import { ResetPasswordPayload } from '../../shared/types/login.interface';
import { resetPasswordNATIVE } from '../../api/native-login';
import { local } from '../../utils/storage';
import { LocalStorageKey } from '../../static/local-storage';
import { getFirstFieldErrorMessage } from '../../utils/form';
import CardHeading from './card-heading';
import CustomLabel from './custom-input-label';
import ErrorForm from '../form/error-form/error-form';
import { Button } from '@squantumengine/horizon';
import { useAuthStore } from '../../store/new/auth';
import { AuthNATIVEContextProps } from '../../shared/types/user.interface';
import { useAuth } from '../../hooks/useAuth';
import {
  NativeLoginErrorMsgEnum,
  NativeLoginWordingEnum,
  NativePlaceholderEnum
} from '../../static/native-login';
import { deleteCookie } from '../../utils/cookie-management';
import { CookieNameEnum } from '../../static/cookie-management';
import { clearAuthLocalStorage } from '../../utils/local-storage';

const FormNewPassword = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [loginErr, setLoginErr] = useState<boolean>(false);
  const [errMsg, setErrorMsg] = useState<string | JSX.Element>('');
  const { user } = useAuthStore();
  const { login } = useAuth() as AuthNATIVEContextProps;

  const [form] = Form.useForm();
  const navigate = useNavigate();

  const onFinish = async (e: any) => {
    setLoading(true);

    const uuid = getUrlParam('uuid');

    if (uuid) {
      // to make sure no token send if coming from reset password link
      deleteCookie(CookieNameEnum.NATIVE_TOKEN);
      clearAuthLocalStorage();
    }

    if ((!uuid && !user.agentId) || !e.password) {
      setErrorMsg(NativeLoginErrorMsgEnum.general);
      setLoginErr(true);
      setLoading(false);
      return;
    }

    const payload: ResetPasswordPayload = {
      uuid: uuid || user.agentId,
      password: e.password
    };

    if (user.email && !uuid) {
      payload.username = user.email;
    }

    const response = await resetPasswordNATIVE(payload);

    if (response.error) {
      setErrorMsg(response.data.message || response.message);
      setLoginErr(true);
      setLoading(false);
      return;
    }

    setLoading(false);

    // if reset password first login success, login to dashboard
    // if there is token, it means that reset password for first login
    // if first login reset password, it should redirect to dashboard
    if (response.token) {
      login(response);
      return;
    }

    const clientCode = local.getItem(LocalStorageKey.SQECC_CLIENT_CODE);
    navigate(`/login?client=${clientCode}`, { state: { resetPassword: true } });
  };

  const onFinishFailed = () => {
    setErrorMsg(getErrorMsg());
    setLoginErr(true);
    setLoading(false);
  };

  const getErrorMsg = () => {
    const msg = getFirstFieldErrorMessage(form);
    if (msg === 'sqecc_password_error') return passwordErrMessage();
    return msg;
  };

  const passwordErrMessage = () => {
    const messages = [
      NativeLoginErrorMsgEnum.minEightCharacter,
      NativeLoginErrorMsgEnum.nonAlfanumerik,
      NativeLoginErrorMsgEnum.twoLowercaseChar,
      NativeLoginErrorMsgEnum.twoUppercaseChar,
      NativeLoginErrorMsgEnum.number
    ];
    return (
      <div>
        <div>Password harus berisi:</div>
        <ol className="m-0 list-inside list-decimal p-0">
          {messages.map((msg, i) => (
            <li key={i}>{msg}</li>
          ))}
        </ol>
      </div>
    );
  };

  const passwordValidator = (_: any, value: string) => {
    const regex = /^(?=.*\W)(?=(?:.*[A-Z]){2})(?=(?:.*[a-z]){2})(?=.*\d).{8,}$/;
    if (!regex.test(value) || !value) {
      return Promise.reject('sqecc_password_error');
    }
    return Promise.resolve();
  };

  const confirmPasswordValidator = (_: any, value: string) => {
    if ((value && value !== form.getFieldValue('password')) || !value) {
      return Promise.reject(new Error(NativeLoginErrorMsgEnum.passwordNotSame));
    }
    return Promise.resolve();
  };

  return (
    <Form
      name="basic"
      className="w-96 rounded-lg bg-white p-6 pb-0"
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      form={form}
      requiredMark={false}
      onChange={() => form.validateFields()}>
      <CardHeading>
        <CardHeading.Heading
          title={NativeLoginWordingEnum.createNewPassword}
          description=""></CardHeading.Heading>
      </CardHeading>

      <Form.Item
        label={<CustomLabel label={NativeLoginWordingEnum.password} />}
        name="password"
        rules={[{ validator: passwordValidator }]}
        help="">
        <Input.Password
          data-testid="input-password"
          type="password"
          size="large"
          placeholder={NativePlaceholderEnum.password}
        />
      </Form.Item>

      <Form.Item
        label={<CustomLabel label={NativeLoginWordingEnum.confirmNewPassword} />}
        name="confirmPassword"
        rules={[{ validator: confirmPasswordValidator }]}
        help="">
        <Input.Password
          data-testid="input-confirm-password"
          type="password"
          size="large"
          placeholder={NativePlaceholderEnum.password}
        />
      </Form.Item>

      <div className="my-[24px]">
        <ErrorForm isVisible={loginErr} message={errMsg} />
      </div>

      <Form.Item>
        <Button data-testid="button-simpan" variant="primary" type="submit" full loading={loading}>
          Simpan
        </Button>
      </Form.Item>
    </Form>
  );
};

export default FormNewPassword;
