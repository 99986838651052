import { useMemo } from 'react';
import { PieChart, Pie, ResponsiveContainer } from 'recharts';
import { Rating, RatingColor } from '../../../static/analysis';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getAverageRatingAnalytics } from '../../../api/analytics';

type Props = { filterValues: Record<string, any> };

type RatingItem = { name: string; value: number; fill?: string };

const DEFAULT_RATING_DATA = {
  rating: [0, 0, 0, 0, 0, 0],
  averageRating: 0,
  totalRating: 0,
  totalConversation: 0
};

const StatsRating = (props: Props) => {
  const { inboxId } = useParams();
  const { data = DEFAULT_RATING_DATA } = useQuery(
    ['averageRating', inboxId, props.filterValues],
    () => getAverageRatingAnalytics(String(inboxId), props.filterValues),
    { enabled: !!inboxId }
  );
  const avgRating = data?.averageRating ?? 0;

  /**
   * transform array of rating data into grouped rating with percentage value of total data
   * (e.g Rating 5, Rating 4, Rating 3, etc)
   */
  const ratingItems: RatingItem[] = useMemo(() => {
    const ratings = data?.rating || DEFAULT_RATING_DATA.rating;
    const result = ratings.map((count: number, ratingValue: number) => {
      const percentage = (count / data?.totalRating) * 100 || 0;
      const fill = RatingColor[Number(ratingValue) as Rating];
      return { name: `Rating ${ratingValue}`, value: Math.round(percentage * 100) / 100, fill };
    });
    // .slice(1) to skip rating value of 0
    return result.slice(1).reverse();
  }, [data]);

  const { innerOuterRadiusProps, styleAverageRating } = useMemo(() => {
    const screenWidth = window.screen.width;
    let fontSize = 48;
    let innerRadius = 68.5;
    let outerRadius = 95;
    if (screenWidth < 1025) {
      innerRadius = 50;
      outerRadius = 70;
      fontSize = 24;
    }
    if (screenWidth < 700) {
      innerRadius = 40; // Adjusted value for smaller screens
      outerRadius = 60; // Adjusted value for smaller screens
      fontSize = 20; // Adjusted value for smaller screens
    }

    return {
      innerOuterRadiusProps: { innerRadius, outerRadius },
      styleAverageRating: { fontSize, fontWeight: 'bold' }
    };
  }, [window.screen.width]);

  const _renderRatingItem = (item: RatingItem) => (
    <div className="xl:text-md flex items-center space-x-2" key={item.name}>
      <div className="h-2 w-2 rounded-full" style={{ background: item.fill }}></div>
      <div>
        {item.name} ({item.value}%)
      </div>
    </div>
  );

  return (
    <div className="flex min-h-[240px] space-x-8 px-4 py-2">
      <div className="relative min-h-[144px] min-w-[144px] xl:min-h-[192px] xl:min-w-[192px] 2xl:min-h-[256px] 2xl:min-w-[256px]">
        <div className="relative top-4 2xl:top-[-8px]">
          <ResponsiveContainer className="absolute min-h-[144px] min-w-[144px] xl:min-h-[192px] xl:min-w-[192px] 2xl:min-h-[256px] 2xl:min-w-[256px]">
            <PieChart>
              <Pie data={ratingItems} paddingAngle={0} dataKey="value" {...innerOuterRadiusProps} />
              <text
                x={'50%'}
                y={'50%'}
                textAnchor="middle"
                dominantBaseline="middle"
                style={styleAverageRating}>
                {avgRating?.toFixed(2).replace(/\.?0+$/, '')}
              </text>
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>

      <div className="flex-1 space-y-3">
        <h2 className="mb-0 text-base xl:text-2xl">Kepuasan Pelanggan</h2>
        <div className="space-y-2">
          <h3 className="mb-0 text-xs xl:text-base">
            {data?.totalRating} Rating / {data?.totalConversation} Percakapan
          </h3>
          <div className="space-y-2">{ratingItems.map(_renderRatingItem)}</div>
        </div>
      </div>
    </div>
  );
};

export default StatsRating;
