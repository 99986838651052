import { Icon, Paragraph } from '@squantumengine/horizon';

type LabelAgentTicketOfflineProps = {
  expand: boolean;
  count: number;
};

const LabelAgentTicketOffline = ({ expand, count }: LabelAgentTicketOfflineProps) => {
  return (
    <div className="flex items-center justify-between  bg-neutral-50 px-4 py-2">
      <Paragraph size="r" weight="bold">
        Ticket Offline ({count})
      </Paragraph>
      <Icon name={expand ? 'chevron-small-up' : 'chevron-small-down'} />
    </div>
  );
};

export default LabelAgentTicketOffline;
