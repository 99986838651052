import { Icon, Paragraph, Title } from '@squantumengine/horizon';

const NoChat = () => {
  return (
    <div className="flex h-full w-full flex-col items-center justify-center">
      <Icon name="comment-slash" size="lg" />
      <Title level={5}>Belum ada chat</Title>
      <Paragraph size="r" className="text-center">
        Pilih chat di samping untuk melihat riwayat chat.
      </Paragraph>
    </div>
  );
};

export default NoChat;
