import { Button } from '@squantumengine/horizon';
import { useState } from 'react';
import { requestResetPasswordNATIVE } from '../../api/native-login';
import { NativeLoginWordingEnum } from '../../static/native-login';

type ButtonEmailResetPasswordProps = {
  value: string;
  valueKey: 'email' | 'uuid';
  onError?: (msg: string) => void;
  onEmailSent?: () => void;
  disabled?: boolean;
};

const ButtonEmailResetPassword: React.FC<ButtonEmailResetPasswordProps> = ({
  value,
  valueKey,
  onError,
  onEmailSent,
  disabled
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleSendResetPassword = async () => {
    setLoading(true);

    const response = await requestResetPasswordNATIVE(value, valueKey);

    if (typeof response === 'object' && 'error' in response) {
      if (onError) onError(response.data.message || response.message);
      setLoading(false);
      return;
    }

    if (onEmailSent) onEmailSent();
    setLoading(false);
    return;
  };

  return (
    <Button
      data-testid="button-request-reset-password"
      variant="primary"
      type="submit"
      full
      loading={loading}
      disabled={disabled || !value}
      onClick={handleSendResetPassword}>
      {NativeLoginWordingEnum.sendResetPassword}
    </Button>
  );
};

export default ButtonEmailResetPassword;
