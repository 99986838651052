import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getWorkspaceSummary, updateWorkspaceSummary } from '../../workspace';
import { ConversationInterface } from '../../../components/card/chat-message/chat-message.interface';
import { getAccountId } from '../../../utils/getAccountId';
import { WorkspaceSummary } from '../../param.interface';

export const useWorkspaceSummary = (
  conversationId: string,
  isSeleceted?: boolean | null,
  selectedCall?: ConversationInterface | null
) => {
  const accountId = getAccountId();

  return useQuery(
    ['WorkspaceSummary', conversationId],
    () => getWorkspaceSummary(accountId as string, conversationId),
    {
      enabled:
        !!accountId &&
        !!conversationId &&
        (isSeleceted !== null ? isSeleceted : false) &&
        !!selectedCall?.inboxId
    } // Query will run only if inboxId is not null
  );
};

export const useConversationSummary = (conversationId: string, enabled?: boolean) => {
  const accountId = getAccountId();

  return useQuery(
    ['conversationSummary', conversationId],
    () => getWorkspaceSummary(accountId, conversationId),
    { enabled }
  );
};

export const useUpdateConversationSummary = (conversationId: string, onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  const accountId = getAccountId();

  return useMutation(
    async (payload: WorkspaceSummary) => {
      await updateWorkspaceSummary(accountId, conversationId, payload);
      return payload;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['conversationSummary', conversationId]);
        if (onSuccess) onSuccess();
      }
    }
  );
};
