import { Col, Form, Typography, Select } from 'antd';
import { DropdownInterface } from '../../../shared/types/inbox.interface';
import CustomSmallTooltip from './custom-small-tooltip';

function DropDown(props: DropdownInterface) {
  const { value, title, tooltip, options } = props;
  const { Text } = Typography;

  return (
    <>
      <Col span={24}>
        <Form.Item
          className="w-full"
          name={title}
          required
          label={
            <>
              <Text className="text-base font-semibold">{value}</Text>
              {!!tooltip && <CustomSmallTooltip text={tooltip} />}
            </>
          }>
          <Select className="w-full" key={title} options={options} />
        </Form.Item>
      </Col>
    </>
  );
}

export default DropDown;
