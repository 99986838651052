import { Flex, Modal, Form, Row, Col, Input, Select, Radio, RadioChangeEvent, Space } from 'antd';
import { useEffect, useState } from 'react';
import { RoleEnum } from '../../../../static/role';
import { roleOptions, TeamUserModalInterface, UpdateUserPayloadParam } from '../users.interface';
import { InboxListItemInterface } from '../../../../shared/types/inbox.interface';
import { useUpdateAgentCapacity } from '../../../../api/hooks/native/useUpdateAgentCap';
import {
  useAddInboxMemberSQEID,
  useDeleteUsrSQEID,
  useGetInboxes,
  useRemoveInboxMemberSQEID,
  useUpdateUsrProfileSQEID
} from '../../../../api/hooks/sqeid/useSQEID';
import { Button, Paragraph, Title } from '@squantumengine/horizon';
import useCCToaster from '../../../../hooks/useCCToaster';
import { maxChatSelection } from '../../../../static/dashboard';
import { useAuthStore } from '../../../../store/new/auth';

const UserModal = ({ data, setIsModalOpen, isModalOpen, form }: TeamUserModalInterface) => {
  const { mutateAsync: addInboxMember } = useAddInboxMemberSQEID();
  const { mutateAsync: removeInboxMember } = useRemoveInboxMemberSQEID();
  const { data: inboxes } = useGetInboxes(data?.account?.accountId);

  const [inboxOptions, setInboxOptions] = useState<Array<any>>([]);
  const { contextHolder, openNotification } = useCCToaster({ timeout: 6 });

  const { mutateAsync: updateProfile, isLoading } = useUpdateUsrProfileSQEID(openNotification);
  const { mutateAsync: delUserSQEID } = useDeleteUsrSQEID(openNotification);
  const { mutateAsync: updateAgentCapacity } = useUpdateAgentCapacity();
  const user = useAuthStore();

  const transformInbox = (data: InboxListItemInterface[]) => {
    return data?.map(
      ({ name: label, inboxId: value, ...rest }: { name: string; inboxId: string }) => ({
        label,
        value,
        ...rest
      })
    );
  };

  const [value, setValue] = useState<number>(1);

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    const agentNameMap: any = {
      [RoleEnum.AGENT]: 'Agen',
      [RoleEnum.SUPERVISOR]: 'Supervisor',
      [RoleEnum.ADMIN]: 'Admin'
    };

    const inboxOptions = transformInbox(inboxes?.inboxList!);
    const currentInbox = transformInbox(data?.inboxes);

    form.setFieldsValue({
      name: `${data?.firstName} ${data?.lastName ? data?.lastName : ''}`,
      phone: data?.phoneNo,
      email: data?.email,
      employee_id: data?.employeeId,
      role: agentNameMap[data?.role],
      assign_team: currentInbox,
      max_video: data?.capacity?.maxCallCapacity,
      max_chat: data?.capacity?.maxChatCapacity
    });

    setInboxOptions(inboxOptions);
  }, [data, inboxes, isModalOpen]);

  useEffect(() => {
    form.setFieldsValue({
      business_unit: user?.account?.accountName
    });
  }, [isModalOpen]);

  const onFinish = async () => {
    try {
      await form.validateFields();
      const isProfileCompleted = Object.keys(data.capacity).length !== 0;

      let payload = {
        agentId: data?.agentId,
        firstName: data?.firstName,
        lastName: data?.lastName,
        email: form.getFieldValue('email'),
        phonNumber: form.getFieldValue('phone'),
        role: data?.role,
        employeeId: form.getFieldValue('employee_id'),
        status: 'ACTIVE',
        accountId: data?.account?.accountId
      } as UpdateUserPayloadParam;

      if (data?.role === RoleEnum.AGENT) {
        await updateAgentCapacity({
          accountId: data?.account?.accountId,
          agentId: data?.agentId,
          agentCapacity: {
            maxChatCustomers: form.getFieldValue('max_chat'),
            maxCallCustomers: form.getFieldValue('max_video')
          },
          isProfileCompleted
        });
      }

      await updateProfile(payload).catch((err) =>
        // eslint-disable-next-line no-console
        console.error(err)
      );
    } catch (error) {
      openNotification({
        label: 'Mohon isi semua kolom',
        variant: 'secondary'
      });
    }
  };

  const handleSelect = async (value: string) => {
    await addInboxMember({
      accountId: data?.account?.accountId,
      agentId: data?.agentId,
      inboxId: value
    }).catch((err) =>
      // eslint-disable-next-line no-console
      console.error(err)
    );
  };

  const handleDeSelect = async (value: string) => {
    await removeInboxMember({
      accountId: data?.account?.accountId,
      agentId: data?.agentId,
      inboxId: value
    }).catch((err) =>
      // eslint-disable-next-line no-console
      console.error(err)
    );
  };

  const handleDelUsrSQEID = () => {
    setIsModalOpen(false);
    delUserSQEID(data?.email).catch((err) =>
      // eslint-disable-next-line no-console
      console.error(err)
    );
  };

  const filterOption = (input: string, option?: { label: string; value: string }) => {
    return (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  };

  return (
    <>
      {contextHolder}
      <Modal
        width={1200}
        footer={
          <Flex justify="end" align="center">
            <Flex gap="middle" align="center">
              <Button className="w-32" variant="secondary" onClick={handleDelUsrSQEID}>
                Hapus User
              </Button>
              <Button className="w-32" loading={isLoading} onClick={onFinish}>
                Simpan
              </Button>
            </Flex>
          </Flex>
        }
        title={
          <Title className="m-0 p-0" level={4}>
            Edit User
          </Title>
        }
        closable={true}
        open={isModalOpen}
        onOk={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}>
        <Form
          className="mt-10"
          name="basic"
          layout="vertical"
          form={form}
          autoComplete="off"
          size="large">
          <Title className="p-0 font-semibold" level={4}>
            Informasi Dasar
          </Title>
          <Row gutter={16}>
            <Col className="gutter-row" span={8}>
              <Form.Item name="name" required label={<Paragraph>Nama Lengkap</Paragraph>}>
                <Input disabled placeholder="Firman Meowji" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item
                name="phone"
                required
                label={
                  <Paragraph>
                    No Telepon &nbsp;
                    <span className="text-gray-500">(terhubung dengan WhatsApp)</span>
                  </Paragraph>
                }>
                <Input disabled />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item name="role" required label={<Paragraph>Jabatan</Paragraph>}>
                <Select disabled defaultValue="Agen" options={roleOptions} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col className="gutter-row" span={8}>
              <Form.Item
                name="business_unit"
                rules={[{ required: true, message: 'Bisnis Unit wajib diisi' }]}
                label={<Paragraph>Bisnis Unit</Paragraph>}>
                <Select disabled />
              </Form.Item>
            </Col>
            {data?.role === RoleEnum.AGENT && (
              <>
                <Col className="gutter-row" span={8}>
                  <Form.Item
                    name="employee_id"
                    rules={[{ required: true, message: 'Please input ID Pegawai' }]}
                    className="user_form-employee_id"
                    label={<Paragraph>ID Pegawai</Paragraph>}>
                    <Input
                      disabled={!!data?.employeeId}
                      placeholder="ID Pegawai"
                      type="number"
                      autoComplete="new-id"
                    />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={8}>
                  <Form.Item
                    name="assign_team"
                    rules={[{ required: true, message: 'Please assign agent to the Team' }]}
                    label={<Paragraph>Tugaskan Ke Tim</Paragraph>}>
                    <Select
                      filterOption={filterOption}
                      showSearch
                      onDeselect={handleDeSelect}
                      onSelect={handleSelect}
                      mode="multiple"
                      options={inboxOptions}
                    />
                  </Form.Item>
                </Col>
              </>
            )}
          </Row>
          <Title className="p-0 font-semibold" level={5}>
            Pengaturan Chat & Panggilan
          </Title>
          <Row gutter={16}>
            <Col className="gutter-row" span={8}>
              <Form.Item
                initialValue={maxChatSelection[0]?.value}
                name="max_chat"
                required
                label={<Paragraph>Maksimal Chat</Paragraph>}>
                <Select options={maxChatSelection} />
              </Form.Item>
            </Col>
            {data?.role === RoleEnum.AGENT && (
              <Col className="gutter-row" span={8}>
                <Form.Item
                  name="max_video"
                  rules={[{ required: true, message: 'Please select a call setting!' }]}
                  label={<Paragraph>Pengaturan Panggilan</Paragraph>}>
                  <Radio.Group value={value} onChange={onChange}>
                    <Space direction="vertical">
                      <Radio value={1}>Bisa menerima panggilan</Radio>
                      <Radio value={0}>Tidak bisa menerima panggilan</Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </Col>
            )}
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default UserModal;
