import { Button, Modal, Typography } from 'antd';
import { ScreenshotModalInterface } from './kyc.interface';
import dataURLtoFile from '../../utils/dataUrltoFile';
import { DocumentInterface } from '../form/workspace/workspace.interface';
import { useSelectedCustomerStore } from '../../store/customer-state';

function ScreenshotModal(props: ScreenshotModalInterface) {
  const {
    open,
    handleOk,
    handleCancel,
    confirmLoading,
    canvasRefP,
    canvasRefL,
    setFileKTP,
    setFileSelfie,
    img
  } = props;

  const { Title, Text } = Typography;

  const { setChooseKTP, setChooseSelfie } = useSelectedCustomerStore();

  const onChooseKTP = () => {
    let file = dataURLtoFile(img!?.src, 'ktp.png');
    setFileKTP(file);
    let selectedSS: DocumentInterface = {
      documentId: file.lastModified.toString(),
      documentName: file.name,
      fileSize: file.size,
      documentUrl: img!?.src
    };

    setChooseKTP(selectedSS);
    handleOk();
  };

  const onChooseSelfie = () => {
    let file = dataURLtoFile(img!?.src, 'selfie.png');
    setFileSelfie(file);
    let selectedSS: DocumentInterface = {
      documentId: file.lastModified.toString(),
      documentName: file.name,
      fileSize: file.size,
      documentUrl: img!?.src
    };

    setChooseSelfie(selectedSS);
    handleOk();
  };

  return (
    <Modal
      title={
        <Title level={3} className="m-0 p-0">
          Cek hasil screenshot
        </Title>
      }
      open={open}
      onOk={handleOk}
      width={600}
      confirmLoading={confirmLoading}
      footer={null}
      forceRender
      centered
      onCancel={handleCancel}>
      <div className="flex flex-col gap-4">
        <Text className="text-base">
          Sebelum menyimpan, pastikan bagian KTP dan wajah pelanggan tidak blur atau terpotong.
        </Text>
        <div className="w-full">
          <div id="snapshot" className="flex h-80 w-full justify-center rounded-3xl"></div>
          <canvas
            width={640}
            height={480}
            ref={canvasRefL}
            id="capture"
            className="hidden"></canvas>
          <canvas
            width={480}
            height={640}
            ref={canvasRefP}
            id="capture"
            className="hidden"></canvas>
        </div>
        <div className="flex w-full flex-row items-center justify-between gap-4">
          <Button
            onClick={onChooseKTP}
            type="primary"
            shape="default"
            htmlType="button"
            className="manual-kyc-btn w-1/2 self-center rounded-full border-black hover:text-white">
            <p className={`manual-kyc-p text-base font-semibold`}>Pilih sebagai KTP</p>
          </Button>
          <Button
            onClick={onChooseSelfie}
            type="primary"
            shape="default"
            htmlType="button"
            className="manual-kyc-btn w-1/2 self-center rounded-full border-black hover:text-white">
            <p className={`manual-kyc-p text-base font-semibold`}>Pilih sebagai Selfie</p>
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default ScreenshotModal;
