import { useQuery } from 'react-query';

import { fetchAllConversationInInbox } from '../../inbox';

export const useAllConversationInInboxQuery = (
  inboxId: string,
  isActive: boolean,
  page: number,
  refetchInterval?: number,
  email?: string,
  name?: string,
  ticketNumber?: string,
  phoneNumber?: string,
  startDate?: string,
  endDate?: string,
  limit?: number
) => {
  return useQuery(
    [
      'AllConversationInInbox',
      inboxId,
      isActive,
      page,
      email,
      name,
      ticketNumber,
      phoneNumber,
      startDate,
      endDate,
      limit
    ],
    () =>
      fetchAllConversationInInbox(
        inboxId,
        isActive,
        page,
        email,
        name,
        ticketNumber,
        phoneNumber,
        startDate,
        endDate,
        limit
      ),
    { enabled: !!inboxId, refetchInterval: refetchInterval ? refetchInterval : undefined } // refetchInterval will be set if it's provided as a parameter // Query will run only if inboxId is not null
  );
};
