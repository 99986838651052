import { MessageEventEnum } from '../static/message-event';

export const addInitialHeight = (id: string) => {
  const section = document.getElementById(id);
  const sectionHeight = section?.scrollHeight;
  if (section && sectionHeight) {
    section.style.height = `${sectionHeight}px`;
  }
};

export const expandedChatAnimation = (id: string) => {
  const section = document.getElementById(id);
  const changedSectionHeight = section?.scrollHeight;
  if (section && changedSectionHeight && section.style.height !== `${changedSectionHeight + 2}px`) {
    section.style.height = `${changedSectionHeight + 22}px`;
  }
};

export const isActiveCall = (event: string) => {
  return event === MessageEventEnum.ACTIVE;
};
