import React, { useEffect, useState } from 'react';
import { useMemo } from 'react';
import { PieChart, Pie, ResponsiveContainer } from 'recharts';
import { Rating, RatingColor } from '../../../static/analysis';
import { Button, Input, Modal } from 'antd';
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import Table from '../../../components/table/table';
import { ConversationDetail } from '../../../static/analysis';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import {
  getConversationDurationByCustomerDetail,
  getCustomerActivityAnalytics
} from '../../../api/analytics';
import { convertSeconds } from './total-conversation/date-converter';
import dayjs from 'dayjs';
type RatingItem = {
  name: string;
  value: number;
  fill?: string;
};

interface Conversation {
  customerName: string;
  conversationId: string;
  date: string;
  duration: number;
  agentName: string;
  communicationType: string;
  rating: any;
  topic: string | null;
  responseTime: number;
}

const StatsRatingPerformance = ({ data, setShowDetailData }: any) => {
  const [search, setSearch] = useState<string>('');
  const [rating, setRating] = useState<RatingItem[]>();
  const [filterData, setFilterData] = useState<any>({ page: 1, perPage: 5 });
  const [showTable, setShowTable] = useState<boolean>(false);
  const { inboxId } = useParams();

  const filter = {
    start: data?.date?.startDate,
    end: data?.date?.endDate,
    page: filterData.page,
    perPage: filterData.perPage,
    ...(search && { q: search })
  };
  const { data: detaiLdata, isFetched } = useQuery(
    ['customerDuration', inboxId, filter],
    () => {
      const asyncFn = getConversationDurationByCustomerDetail;
      return asyncFn(String(inboxId), filter);
    },
    { enabled: !!inboxId }
  );

  const { data: customerListData } = useQuery(
    ['customerListData', inboxId, filter],
    () => getCustomerActivityAnalytics(String(inboxId), filter),
    {
      enabled: !!inboxId && !!showTable
    }
  );
  const truncateString = (str: string, maxLength: number): string => {
    return str.length > maxLength ? str.substring(0, maxLength - 3) + '...' : str;
  };

  const truncateNames = (array: Conversation[]): Conversation[] => {
    return array?.map((item) => ({
      ...item,
      customerName: truncateString(item.customerName, 45),
      agentName: truncateString(item.agentName, 45)
    }));
  };
  const truncatedData = truncateNames(customerListData?.data);

  useEffect(() => {
    if (detaiLdata) {
      const result = detaiLdata.rating?.map((data: number, index: number) => {
        const fill = RatingColor[Number(index) as Rating];
        if (index === 0) {
          return { name: `Unrated`, fill, value: data };
        }
        return { name: `Rating ${index}`, fill, value: data };
      });

      const resultReverse = result?.reverse();
      setRating(resultReverse);
    }
    //eslint-disable-next-line
  }, [detaiLdata]);

  const { innerOuterRadiusProps, styleAverageRating } = useMemo(() => {
    const screenWidth = window.screen.width;
    let fontSize = 27.5;
    let innerRadius = 39.2447916668;
    let outerRadius = 54.4270833335;
    if (screenWidth < 1024) {
      innerRadius = 0;
      outerRadius = 40.1041666668;
      fontSize = 13.75;
    }
    return {
      innerOuterRadiusProps: { innerRadius, outerRadius },
      styleAverageRating: { fontSize, fontWeight: 'bold' }
    };
  }, []);
  const handleOnSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };
  const _renderRatingItem = (item: RatingItem) => (
    <div className="flex items-center space-x-2 " key={item.name}>
      <div>{item.name}</div>
      <div className="h-2 w-2 rounded-full" style={{ background: item.fill }}></div>
      <div>{item.value}</div>
    </div>
  );

  // centerscreen to know location in the right or left chart bar
  const centerScreen = window.screen.width / 2;
  const leftPosition =
    data?.tooltipPosition?.x > centerScreen
      ? data?.tooltipPosition.x - 600
      : data?.tooltipPosition.x + 40;
  const startDate = `${dayjs(detaiLdata?.metadata?.start).locale('id').format('DD MMMM YYYY')}`;
  const endDate = `${dayjs(detaiLdata?.metadata?.end).locale('id').format('DD MMMM YYYY')}`;
  const selectedDate = startDate !== endDate ? startDate + ' - ' + endDate : endDate;

  return (
    <div
      style={{
        left: `${leftPosition}px`,
        bottom: '80px',
        boxShadow: '0px 2px 32px 0px rgba(0, 0, 0, 0.15)'
      }}
      className="absolute z-20 w-[536px] rounded-lg bg-white p-4">
      <div className="flex justify-between">
        <h2 className="mb-0 text-[16px]">{data?.name}</h2>
        <button
          onClick={() => setShowDetailData(false)}
          className="cursor-pointer border-none bg-transparent">
          <CloseOutlined />
        </button>
      </div>
      <div className="flex ">
        <div className="w-full">
          <h2 className="mb-0 text-sm">Rating & Percakapan</h2>
          <h3 className="mt-0 text-sm text-gray-500">
            {detaiLdata?.totalRating} Rating / Total {detaiLdata?.totalConversation} Percakapan
          </h3>
          <div className="flex gap-1 rounded-xl border border-solid border-gray-50 bg-white px-2 py-2">
            <div className="relative min-h-[110px] min-w-[110px] ">
              <div className="relative top-4 2xl:top-[-8px]">
                {isFetched && (
                  <ResponsiveContainer className="absolute min-h-[110px] min-w-[110px] ">
                    <PieChart>
                      <Pie
                        data={rating}
                        paddingAngle={0}
                        dataKey="value"
                        {...innerOuterRadiusProps}
                      />
                      <text
                        x={'50%'}
                        y={'50%'}
                        textAnchor="middle"
                        dominantBaseline="middle"
                        style={styleAverageRating}>
                        {detaiLdata?.averageRating?.toFixed(1)}
                      </text>
                    </PieChart>
                  </ResponsiveContainer>
                )}
              </div>
            </div>
            <div className="flex-1 space-y-3">
              <div className="space-y-2">
                <div className="space-y-2">{rating?.reverse()?.map(_renderRatingItem)}</div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full">
          <h2 className="text-sm">Durasi Percakapan</h2>
          <div className="grid grid-cols-2 grid-rows-2 gap-4">
            <div className="flex h-[57px] max-w-[125px] flex-col items-center justify-center rounded-lg border border-solid border-grey-80">
              <p className="m-0 text-xs">Rata-Rata Semua</p>
              <p className="m-0 font-semibold text-[#5EA2F2]">
                {convertSeconds(detaiLdata?.averageDuration?.toFixed(0))}
              </p>
            </div>
            <div className="flex h-[57px] max-w-[125px] flex-col items-center justify-center rounded-lg border border-solid border-grey-80">
              <p className="m-0 text-xs">Terlama</p>
              <p className="m-0 font-semibold text-[#5EA2F2]">
                {convertSeconds(detaiLdata?.longestDuration?.toFixed(0))}
              </p>
            </div>
            <div className="flex h-[57px] max-w-[125px] flex-col items-center justify-center rounded-lg border border-solid border-grey-80">
              <p className="m-0 text-xs">Rata-Rata Video</p>
              <p className="m-0 font-semibold text-[#5EA2F2]">
                {convertSeconds(detaiLdata?.averageVideoDuration?.toFixed(0))}
              </p>
            </div>
            <div className="flex h-[57px] max-w-[125px] flex-col items-center justify-center rounded-lg border border-solid border-grey-80">
              <p className="m-0 text-xs">Rata-Rata Chat</p>
              <p className="m-0 font-semibold text-[#5EA2F2]">
                {convertSeconds(detaiLdata?.averageChatDuration?.toFixed(0))}
              </p>
            </div>
          </div>
          <div className="mt-4 flex items-end justify-end">
            <Button
              onClick={() => setShowTable(true)}
              className="rounded-full bg-green-emerald text-white">
              Cek Detail Aktivitas
            </Button>
          </div>
        </div>
      </div>
      <Modal className="w-2/3" footer={null} open={showTable} onCancel={() => setShowTable(false)}>
        <div className="flex justify-between p-4">
          <h1 className="text-[18px]">Detail percakapan: {selectedDate}</h1>
          <div className="flex h-10 w-[483px] items-center rounded-2xl border border-solid border-grey-50">
            <Input
              value={search}
              allowClear
              onChange={handleOnSearch}
              placeholder="Cari"
              variant="borderless"
              prefix={<SearchOutlined className="text-grey-80" />}
            />
          </div>
        </div>
        <div className="overflow-scroll">
          <Table
            filter={{
              filtering: search,
              setFiltering: setSearch,
              setFilterData: setFilterData,
              filterData: filterData
            }}
            headerColumns={ConversationDetail}
            data={truncatedData || []}
            showArrow={false}
            showPagination={true}
            totalData={customerListData?.metadata?.totalData}
            pageSizeOptions={[5, 10, 20, 50, 100]}
          />
        </div>
      </Modal>
    </div>
  );
};

export default StatsRatingPerformance;
