import { Popover, TableProps } from 'antd';
import { formatSecondToHHMMSS } from '../utils/formatter';
import Copy from '../components/copy';
import dayjs from 'dayjs';
import ChatIcon from '../assets/icons/chat';
import VideoIcon from '../assets/icons/video';
import { Sorter } from '../utils/table';

import { Token } from '@squantumengine/horizon';
import { FunnelHover, StatusKYCEnum } from '../components/kyc/kyc.interface';

const { COLORS } = Token;

export interface KYCActivityInterface {
  startDate: dayjs.Dayjs;
  endDate: dayjs.Dayjs;
}

export interface KYCActivityBarChartInterface {
  indexItem: any;
  data: any;
  maxVal: number;
  items?: Array<any>;
}

export interface KYCActivityStepChartInterface extends KYCActivityBarChartInterface {
  setHover: React.Dispatch<React.SetStateAction<FunnelHover>>;
}

export interface KYCActivityTooltipInterface {
  rejected: number;
  approved: number;
  total: number;
}

export enum AnalysisDurationEnum {
  DAY = 'daily',
  WEEK = 'weekly',
  MONTH = 'monthly'
}
export enum DurationKeyEnum {
  DAY = 0,
  WEEK = 1,
  MONTH = 2
}

export interface TablePropsInterface {
  data: any[];
  headerColumns: any[];
  numbered?: boolean;
  totalData?: number;
  showArrow?: boolean;
  showPagination?: boolean;
  pageSizeOptions?: number[];
  resetPagination?: boolean;
  filter?: {
    filtering: string;
    filterData?: Record<string, any>;
    setFiltering: React.Dispatch<React.SetStateAction<string>>;
    setFilterData?: React.Dispatch<React.SetStateAction<any>>;
  };
}

export enum ActivityTabEnum {
  CUSTOMER = 'Pelanggan',
  AGENT = 'Agen'
}
export enum RepetitiveConversationTabEnum {
  TOPIC = 'Topik',
  CUSTOMER = 'Pelanggan'
}

export enum TotalConversationTypeEnum {
  AVERAGE = 6,
  ALL = 0
}

export enum FilterTimeEnum {
  DAY = 0,
  WEEK = 1,
  MONTH = 2
}

export enum Rating {
  Unrated = 0,
  BAD = 1,
  BelowAverage = 2,
  Average = 3,
  Good = 4,
  Excellent = 5
}

export enum BusyHourStatus {
  Empty = 0,
  Sparse = 1,
  Populated = 2,
  Moderate = 3,
  Crowded = 4,
  Packed = 5,
  OverCrowded = 6
}

export const RatingColor: Record<Rating, string> = {
  [Rating.BAD]: '#F2696E',
  [Rating.BelowAverage]: '#F6989C',
  [Rating.Average]: '#FCCB6B',
  [Rating.Good]: '#42DBA6',
  [Rating.Excellent]: '#21C48B',
  [Rating.Unrated]: '#A2AAB8'
};

export const BusyHourRangeColor: Record<BusyHourStatus, string> = {
  [BusyHourStatus.Empty]: 'bg-[#BAC3D1]',
  [BusyHourStatus.Sparse]: 'bg-blue-50',
  [BusyHourStatus.Populated]: 'bg-blue-100',
  [BusyHourStatus.Moderate]: 'bg-blue-200',
  [BusyHourStatus.Crowded]: 'bg-blue-300',
  [BusyHourStatus.Packed]: 'bg-blue-400',
  [BusyHourStatus.OverCrowded]: 'bg-blue-500'
};

export const TabMenuEnum = {
  ACTIVITY: 0,
  REPORT_PERFORMANCE: 1
};

export const TeamMenuEnum = {
  TEAM: 0,
  USERS: 1
};

export const MenuItems = [
  {
    label: 'Aktivitas Krusial',
    key: 0,
    isKYCInbox: true
  },
  { label: 'Laporan Performa', key: 1, isKYCInbox: false }
];

export const TeamMenuItems = [
  { label: 'Tim', key: 0 },
  { label: 'Users', key: 1 }
];

export const ConversationItems = [
  {
    label: 'Semua percakapan',
    key: 0
  }
];

export const colorCodes = [
  '#A2AAB8',
  `${COLORS.red[200]}`,
  `${COLORS.red[100]}`,
  `${COLORS.yellow[300]}`,
  `${COLORS.green[200]}`,
  `${COLORS.green[300]}`,
  `${COLORS.blue[200]}`
];

export const colorCodesKYCActivity = [`${COLORS.yellow[200]}`, `${COLORS.red[200]}`];

export const barLabel = [
  { label: 'Arm1' },
  { label: 'Arm2' },
  { label: 'Arm3' },
  { label: 'Arm4' },
  { label: 'Arm5' },
  { label: 'Arm6' },
  { label: 'Arm7' }
];

export const _renderCell = (val: string) => <div className="text-gray-500">{val || '-'}</div>;

export const _renderEllipsis = (val: string, maxLength: number) => {
  const truncatedVal = val?.length > maxLength ? `${val?.slice(0, maxLength)}...` : val;
  return <p className="text-gray-500">{truncatedVal || '-'}</p>;
};

export const HEIGHT_BAR = 20;
export const N_Y_AXIS = 5;
export const N_X_AXIS = 7;

export const _renderPercentage = (total: number, totalConversation: number) => {
  return (
    <p className="m-0 p-0 font-semibold">
      {total}/<span className="text-gray-400">{totalConversation}</span> (
      {((total / totalConversation) * 100).toFixed(2)} %)
    </p>
  );
};

export const _renderRating = (val: number) => {
  const avgVal = Number(val);
  let color = RatingColor[Rating.BAD];
  switch (true) {
    case avgVal >= Rating.BAD && avgVal < Rating.BelowAverage:
      color = RatingColor[Rating.BAD];
      break;
    case avgVal >= Rating.BelowAverage && avgVal < Rating.Average:
      color = RatingColor[Rating.BelowAverage];
      break;
    case avgVal >= Rating.Average && avgVal < Rating.Good:
      color = RatingColor[Rating.Average];
      break;
    case avgVal >= Rating.Good && avgVal < 4.5:
      color = RatingColor[Rating.Good];
      break;
    case avgVal >= 4.5 && avgVal <= Rating.Excellent:
      color = RatingColor[Rating.Excellent];
      break;
  }

  return (
    <div className="font-semibold" style={{ color }}>
      {avgVal ? avgVal.toFixed(2).replace(/\.?0+$/, '') : '-'}
    </div>
  );
};

export const conversationColumns = [
  {
    title: 'Date',
    key: 'date',
    dataIndex: 'date',
    sorter: { compare: Sorter.DATE },
    render: (val: string) => <div className="font-semibold">{dayjs(val).format('DD-MM-YYYY')}</div>
  },
  {
    title: 'Percakapan Masuk',
    key: 'totalConversation',
    dataIndex: 'totalConversation',
    sorter: { compare: Sorter.DEFAULT },
    render: _renderCell
  },
  {
    title: 'Percakapan Dialihkan',
    key: 'reassignedConversation',
    dataIndex: 'reassignedConversation',
    sorter: { compare: Sorter.DEFAULT },
    render: _renderCell
  },
  {
    title: 'Percakapan Selesai',
    key: 'resolvedConversation',
    dataIndex: 'resolvedConversation',
    sorter: { compare: Sorter.DEFAULT },
    render: _renderCell
  },
  {
    title: 'Waktu Interaksi',
    key: 'totalDuration',
    dataIndex: 'totalDuration',
    sorter: { compare: Sorter.DEFAULT },
    render: formatSecondToHHMMSS
  },
  {
    title: 'Waktu Respon',
    key: 'averageResponseTime',
    dataIndex: 'averageResponseTime',
    sorter: true,
    render: formatSecondToHHMMSS
  },
  {
    title: 'Rating Rata-rata',
    key: 'averageRating',
    dataIndex: 'averageRating',
    sorter: true,
    render: (val: number) =>
      val ? (
        _renderRating(val)
      ) : (
        <div className="font-semibold text-orange-danger">Tidak Tersedia</div>
      )
  }
];

export const customerListColumns = [
  {
    title: 'Nama Pelanggan',
    key: 'customerName',
    dataIndex: 'customerName',
    sorter: true,
    render: (val: string) => <div className="font-semibold">{val}</div>
  },
  {
    title: 'Nomor Tiket',
    key: 'conversationId',
    dataIndex: 'conversationId',
    sorter: true,
    render: (val: string) => (
      <Popover content={<Copy value={val}>{val}</Copy>}>
        ID #{val?.substring(val?.length - 5)}
      </Popover>
    )
  },
  {
    title: 'Topik',
    key: 'topic',
    dataIndex: 'topic',
    sorter: true,
    render: _renderCell
  },
  {
    title: 'Durasi Percakapan',
    key: 'duration',
    dataIndex: 'duration',
    sorter: true,
    render: formatSecondToHHMMSS
  },
  {
    title: 'Waktu Respon',
    key: 'responseTime',
    dataIndex: 'responseTime',
    sorter: true,
    render: formatSecondToHHMMSS
  },
  {
    title: 'Rating',
    key: 'rating',
    dataIndex: 'rating',
    sorter: true,
    render: (val: number) =>
      val ? (
        _renderRating(val)
      ) : (
        <div className="font-semibold text-orange-danger">Tidak Tersedia</div>
      )
  }
];

export const topicDetailColumns = [
  {
    title: 'Nama Pelanggan',
    key: 'customerName',
    dataIndex: 'customerName',
    sorter: true,
    render: (val: string) => <div className="font-semibold">{val}</div>
  },
  {
    title: 'ID Percakapan',
    key: 'conversationId',
    dataIndex: 'conversationId',
    sorter: true,
    render: (val: string) => (
      <Popover content={<Copy value={val}>{val}</Copy>}>
        ID #{val?.substring(val?.length - 5)}
      </Popover>
    )
  },
  {
    title: 'Tanggal',
    key: 'date',
    dataIndex: 'date',
    sorter: true,
    render: (val: string) => <div className="font-semibold">{dayjs(val).format('DD-MM-YYYY')}</div>
  },
  {
    title: 'Nama Agen',
    key: 'agentName',
    dataIndex: 'agentName',
    sorter: true,
    render: (val: string) => <div className="font-semibold">{val}</div>
  },
  {
    title: 'Metode Percakapan',
    key: 'communicationType',
    dataIndex: 'communicationType',
    sorter: true,
    render: (val: 'CHAT' | 'AUDIO_VIDEO') =>
      ({
        CHAT: <ChatIcon className="!text-gray-500" />,
        AUDIO_VIDEO: <VideoIcon className="!text-gray-500" />
      }[val])
  },
  {
    title: 'Rating',
    key: 'rating',
    dataIndex: 'rating',
    sorter: true,
    render: (val: number) =>
      val ? (
        _renderRating(val)
      ) : (
        <div className="font-semibold text-orange-danger">Tidak Tersedia</div>
      )
  }
];

export const customerDetailColumns = [
  {
    title: 'ID Percakapan',
    key: 'conversationId',
    dataIndex: 'conversationId',
    sorter: true,
    render: (val: string) => (
      <Popover content={<Copy value={val}>{val}</Copy>}>
        ID #{val?.substring(val?.length - 5)}
      </Popover>
    )
  },
  {
    title: 'Tanggal',
    key: 'date',
    dataIndex: 'date',
    sorter: true,
    render: (val: string) => <div className="font-semibold">{dayjs(val).format('DD-MM-YYYY')}</div>
  },
  {
    title: 'Nama Agen',
    key: 'agentName',
    dataIndex: 'agentName',
    sorter: true,
    render: (val: string) => <div className="font-semibold">{val}</div>
  },
  {
    title: 'Metode Percakapan',
    key: 'communicationType',
    dataIndex: 'communicationType',
    sorter: true,
    render: (val: 'CHAT' | 'AUDIO_VIDEO') =>
      ({
        CHAT: <ChatIcon className="!text-gray-500" />,
        AUDIO_VIDEO: <VideoIcon className="!text-gray-500" />
      }[val])
  },
  {
    title: 'Rating',
    key: 'rating',
    dataIndex: 'rating',
    sorter: true,
    render: (val: number) =>
      val ? (
        _renderRating(val)
      ) : (
        <div className="font-semibold text-orange-danger">Tidak Tersedia</div>
      )
  }
];

export const AgentListColumns = [
  {
    title: 'Nama Agen',
    key: 'agentName',
    dataIndex: 'agentName',
    sorter: true,
    render: (val: string) => <div className="font-semibold">{val}</div>
  },
  {
    title: 'Durasi Percakapan',
    key: 'totalBusyTime',
    dataIndex: 'totalBusyTime',
    sorter: true,
    render: formatSecondToHHMMSS
  },
  {
    title: 'Waktu Kosong',
    key: 'totalIdleTime',
    dataIndex: 'totalIdleTime',
    sorter: true,
    render: formatSecondToHHMMSS
  },
  {
    title: 'Waktu Respond',
    key: 'averageResponseTime',
    dataIndex: 'averageResponseTime',
    sorter: true,
    render: formatSecondToHHMMSS
  },
  {
    title: 'Percakapan Selesai',
    key: 'totalConversation',
    dataIndex: 'totalConversation',
    sorter: true,
    render: _renderCell
  },
  {
    title: 'Rating Rata-rata',
    key: 'averageRating',
    dataIndex: 'averageRating',
    sorter: true,
    render: (val: number) =>
      val ? (
        _renderRating(val)
      ) : (
        <div className="font-semibold text-orange-danger">Tidak Tersedia</div>
      )
  }
];

export const GENERAL_INQUIRY_ACTIVITY_COLUMNS = [
  {
    title: 'Nama Pelanggan',
    key: 'customerName',
    dataIndex: 'customerName',
    sorter: true,
    render: (val: string) => <div className="font-semibold">{val}</div>
  },
  {
    title: 'Nomor Tiket',
    key: 'conversationId',
    dataIndex: 'conversationId',
    sorter: true,
    render: (val: string) => (
      <Popover content={<Copy value={val}>{val}</Copy>}>
        ID #{val?.substring(val?.length - 5)}
      </Popover>
    )
  },
  {
    title: 'Tanggal',
    key: 'date',
    dataIndex: 'date',
    sorter: true,
    render: (val: string) => <div className="font-semibold">{dayjs(val).format('DD-MM-YYYY')}</div>
  },
  {
    title: 'Durasi Percakapan',
    key: 'duration',
    dataIndex: 'duration',
    sorter: true,
    render: formatSecondToHHMMSS
  },
  {
    title: 'Nama Agen',
    key: 'agentName',
    dataIndex: 'agentName',
    sorter: true,
    render: _renderCell
  },
  {
    title: 'Rating',
    key: 'rating',
    dataIndex: 'rating',
    sorter: true,
    render: (val: number) =>
      val ? (
        _renderRating(val)
      ) : (
        <div className="font-semibold text-orange-danger">Tidak Tersedia</div>
      )
  }
];

export const MANUAL_KYC_ACTIVITY_COLUMNS = [
  {
    title: 'Nama Pelanggan',
    key: 'customerName',
    dataIndex: 'customerName',
    sorter: true,
    render: (val: string) => _renderEllipsis(val, 15)
  },
  {
    title: 'Nomor Tiket',
    key: 'conversationId',
    dataIndex: 'conversationId',
    sorter: true,
    render: (val: string) => (
      <Popover content={<Copy value={val}>{val}</Copy>}>
        ID #{val?.substring(val?.length - 5)}
      </Popover>
    )
  },
  {
    title: 'Tanggal',
    key: 'date',
    dataIndex: 'date',
    sorter: true,
    render: (val: string) => <div className="font-semibold">{dayjs(val).format('DD-MM-YYYY')}</div>
  },
  {
    title: 'Nama Agen',
    key: 'agentName',
    dataIndex: 'agentName',
    sorter: true,
    render: (val: string) => _renderEllipsis(val, 15)
  },
  {
    title: 'Status',
    key: 'kycStatus',
    dataIndex: 'kycStatus',
    sorter: true,
    render: (value: StatusKYCEnum): JSX.Element => {
      const colorClass = {
        COMPLETED: 'text-green-500',
        FAILED: 'text-red-500',
        PENDING: 'text-yellow-500'
      };

      const renderedValue = {
        COMPLETED: 'Disetujui',
        FAILED: 'Ditolak',
        PENDING: 'Pending'
      };

      const valueKey = value === null ? 'PENDING' : value;

      return <p className={`font-semibold ${colorClass[valueKey]}`}>{renderedValue[valueKey]}</p>;
    }
  },
  {
    title: 'Alasan',
    key: 'rejectReason',
    dataIndex: 'rejectReason',
    sorter: true,
    render: (val: string) => _renderEllipsis(val, 15)
  }
];

export const rejectReasonColumns: TableProps<any>['columns'] = [
  {
    title: 'Alasan',
    key: 'rejectReason',
    dataIndex: 'rejectReason',
    sorter: (a, b) => a.rejectReason?.localeCompare(b.rejectReason),
    render: (val: string) => _renderEllipsis(val, 50)
  },
  {
    title: 'Jumlah',
    key: 'total',
    dataIndex: 'total',
    sorter: (a, b) => a.total - b.total,
    render: (_, record) => {
      return _renderPercentage(record?.total, record?.totalConversation || 0);
    }
  }
];

export const ActiveTablist = [
  {
    key: ActivityTabEnum.AGENT,
    name: ActivityTabEnum.AGENT
  },
  {
    key: ActivityTabEnum.CUSTOMER,
    name: ActivityTabEnum.CUSTOMER
  }
];
export const RepetitiveConversationTablist = [
  {
    key: RepetitiveConversationTabEnum.TOPIC,
    name: RepetitiveConversationTabEnum.TOPIC
  },
  {
    key: RepetitiveConversationTabEnum.CUSTOMER,
    name: RepetitiveConversationTabEnum.CUSTOMER
  }
];

export const ConversationDetail = [
  {
    title: 'Nama Pelanggan',
    key: 'customerName',
    dataIndex: 'customerName',
    sorter: true,
    render: (val: string) => <div className="font-semibold">{val}</div>
  },
  {
    title: 'Topik',
    key: 'topic',
    dataIndex: 'topic',
    sorter: true,
    render: _renderCell
  },
  {
    title: 'Waktu Respond',
    key: 'responseTime',
    dataIndex: 'responseTime',
    sorter: true,
    render: formatSecondToHHMMSS
  },
  {
    title: 'Durasi',
    key: 'duration',
    dataIndex: 'duration',
    sorter: true,
    render: formatSecondToHHMMSS
  },
  {
    title: 'Rating',
    key: 'rating',
    dataIndex: 'rating',
    sorter: true,
    render: _renderRating
  },
  {
    title: 'Agen',
    key: 'agentName',
    dataIndex: 'agentName',
    sorter: true,
    render: _renderCell
  },
  {
    title: 'Metode  percakapan',
    key: 'communicationType',
    dataIndex: 'communicationType',
    sorter: true,
    render: (val: 'CHAT' | 'AUDIO_VIDEO') =>
      ({
        CHAT: <ChatIcon className="!text-gray-500" />,
        AUDIO_VIDEO: <VideoIcon className="!text-gray-500" />
      }[val])
  }
];

export const customerPerfomanceListColumns = [
  {
    title: 'Nama Pelanggan',
    key: 'customerName',
    dataIndex: 'customerName',
    sorter: true,
    render: (val: string) => <div className="font-semibold">{val}</div>
  },
  {
    title: 'ID Pelanggan',
    key: 'customerId',
    dataIndex: 'customerId',
    sorter: true,
    render: (val: string) => (
      <Popover content={<Copy value={val}>{val}</Copy>}>{val?.slice(-5)}</Popover>
    )
  },
  {
    title: 'Topik',
    key: 'topic',
    dataIndex: 'topic',
    sorter: true,
    render: _renderCell
  },
  {
    title: 'Metode Percakapan',
    key: 'conversation',
    dataIndex: 'conversation',
    sorter: true,
    render: (val: any) => (
      <div className="flex gap-4 !text-gray-500">
        <span className="flex gap-2">
          <VideoIcon />
          {val?.video}
        </span>
        <span className="flex gap-2 ">
          <ChatIcon /> {val?.chat}
        </span>
      </div>
    )
  },
  {
    title: 'Jumlah',
    key: 'total',
    dataIndex: 'total',
    sorter: true,
    render: (val: number) => <span className="text-gray-500">{val ?? 0} kali</span>
  }
];
export const topicPerfomanceListColumns = [
  {
    title: 'Topik',
    key: 'topic',
    dataIndex: 'topic',
    sorter: true,
    render: (val: string) => <div className="font-semibold">{val}</div>
  },
  {
    title: 'Metode Percakapan',
    key: 'conversation',
    dataIndex: 'conversation',
    sorter: true,
    render: (val: any) => (
      <div className="flex gap-4 !text-gray-500">
        <span className="flex gap-2">
          <VideoIcon />
          {val?.video}
        </span>
        <span className="flex gap-2 ">
          <ChatIcon /> {val?.chat}
        </span>
      </div>
    )
  },
  {
    title: 'Jumlah',
    key: 'total',
    dataIndex: 'total',
    sorter: true
  }
];
