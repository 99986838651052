import { Button, Icon } from '@squantumengine/horizon';
import { useNavigate, useParams } from 'react-router-dom';

import TeamInformationDetail from './TeamInformationDetail';
import SupervisorTeamDetail from './SupervisorTeamDetail';
import AgentTeamDetail from './AgentTeamDetail';
import OperationalScheduleDetail from './OperationalScheduleDetail';
import { useQuery } from 'react-query';
import { fetchInboxUserList, getInboxDetail } from '../../../services/inbox';
import { InboxInterface } from '../../../types/new/inbox';
import { useAuthStore } from '../../../store/new/auth';
import useTeamCreateEditStore from '../create-edit/store';
import { ChannelEnum } from '../create-edit/type';

export default function DetailTeam() {
  const navigate = useNavigate();
  const { inboxId } = useParams();
  const { account } = useAuthStore();
  const store = useTeamCreateEditStore();

  useQuery(['inbox', inboxId], () => getInboxDetail(account?.accountId, inboxId!!), {
    enabled: !!inboxId,
    refetchOnWindowFocus: false,
    onSuccess: (data: any) => {
      const inbox = data?.inbox as InboxInterface;
      const {
        inboxConfig,
        greetingEnabled,
        greetingMessage,
        longQueueMessage,
        waitingRoomMessage,
        oooMessage,
        workingHours,
        workingHoursEnabled
      } = inbox;
      const { mediaCallConfiguration, ...restConfig } = inboxConfig;
      const formTeamConfiguration: any = {
        inboxName: inbox.name,
        ...mediaCallConfiguration,
        ...restConfig,
        websiteUrl: '',
        greetingInfo: undefined,
        target: '',
        channel: inbox.channelType,
        conversationContext: inbox.inboxConfig?.conversationContext
      };
      const formDisplay = {
        greetingEnabled,
        greetingMessage,
        longQueueMessage,
        waitingRoomMessage,
        oooMessage,
        vbg: ''
      };
      store.setChannelType(inbox.channelType as ChannelEnum);
      store.setFormTeamConfiguration(formTeamConfiguration);
      store.setFormDisplay(formDisplay);
      store.setOperationalHour({
        outOfOfficeMessage: oooMessage,
        workingHoursEnabled,
        workingHours
      });
      store.setInboxId(inboxId ?? '');
    }
  });

  useQuery(['inbox', inboxId, 'members'], () => fetchInboxUserList(account.accountId, inboxId!), {
    enabled: !!inboxId,
    onSuccess: (data) => {
      const members = data?.inboxMembersList?.map((data) => data.agentId);
      store.setAssignAgent(members);
    }
  });

  const { formTeamConfiguration } = store;
  const { inboxName } = formTeamConfiguration;

  const handleBack = () => {
    store.resetStore();
    navigate('/v2/dashboard/team/list');
  };

  return (
    <div className="flex h-fit min-h-[calc(100vh-4rem)] flex-col gap-6 bg-[#F9FBFF] p-8">
      <div className="flex items-center gap-5">
        <Button
          variant="text"
          className="h-8 w-8 rounded border-[1px] border-solid border-neutral-100 p-0"
          onClick={handleBack}
          aria-label="back-button">
          <Icon name="arrow-left" />
        </Button>
        <h2 className="m-0 text-xl">Detail Tim {inboxName}</h2>
      </div>
      <div className="flex flex-col gap-6">
        <TeamInformationDetail />
        <SupervisorTeamDetail />
        <AgentTeamDetail />
        <OperationalScheduleDetail />
      </div>
    </div>
  );
}
