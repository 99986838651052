import { Dropdown, Form } from 'antd';

import { hourSelection, minuteSelection } from '../../../static/working-hour';
import { NumberToTimeString } from '../../../utils/timestamp';

function TimeSelector(props: { form: any; timeName: string; disabled?: boolean; onChange?: any }) {
  const { form, timeName, disabled, onChange } = props;
  const defaultDropdownItems = timeName.includes('Hour') ? hourSelection : minuteSelection;

  return (
    <Dropdown
      trigger={['click']}
      menu={{
        items: defaultDropdownItems,
        onClick: (e: any) => {
          return onChange ? onChange(timeName, e.key) : form.setFieldValue(timeName, e.key);
        }
      }}
      disabled={disabled}
      overlayClassName="max-h-96 overflow-y-scroll rounded-md">
      <Form.Item
        shouldUpdate
        className={`${
          disabled && 'text-grey-100'
        } cursor-pointer rounded-md border border-solid border-grey-80 text-center text-sm font-semibold`}>
        {() => {
          return <span>{NumberToTimeString(form.getFieldValue(timeName))}</span>;
        }}
      </Form.Item>
    </Dropdown>
  );
}

export default TimeSelector;
