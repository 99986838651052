import { useMemo, useState } from 'react';
import { Modal } from 'antd';
import { RightOutlined } from '@ant-design/icons';

import TimeProgressBar from '../../../components/commons/TimeProgressBar';
import Table from '../../../components/commons/Table';
import { conversationColumns, customerListColumns } from '../../../static/analysis';
import { useQuery } from 'react-query';
import {
  getAverageResponseAnalytics,
  getCustomerActivityAnalytics,
  getRiwayatPercakapanAnalytics
} from '../../../api/analytics';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { removeEmptyKeys } from '../../../utils/formatter';

const DEFAULT_PARAMS = { sortBy: '', sortDir: '', page: 1 };

type Props = { filterValues: Record<string, any> };

const StatsAverageResponse = (props: Props) => {
  const { inboxId } = useParams();
  const [showDetail, setShowDetail] = useState(false);
  const [showCustomerList, setShowCustomerList] = useState({ showModal: false, selectedDate: '' });
  const [riwayatParam, setRiwayatParam] = useState(DEFAULT_PARAMS);
  const [customerParam, setCustomerParam] = useState(DEFAULT_PARAMS);

  const { data } = useQuery(
    ['averageResponse', inboxId, props.filterValues],
    () => getAverageResponseAnalytics(String(inboxId), props.filterValues),
    { enabled: !!inboxId }
  );

  const riwayatFilter = useMemo(
    () => removeEmptyKeys({ ...props.filterValues, ...riwayatParam }),
    [props.filterValues, riwayatParam]
  );
  const { data: riwayatData, isLoading: loadingRiwayat } = useQuery(
    ['riwayatPercakapan', inboxId, riwayatFilter],
    () => getRiwayatPercakapanAnalytics(String(inboxId), riwayatFilter),
    {
      enabled: !!inboxId && showDetail
    }
  );

  const customerFilter = useMemo(
    () =>
      removeEmptyKeys({
        start: dayjs(new Date(showCustomerList.selectedDate)).format('YYYY-MM-DDTHH:mm:ss.sssZ'),
        end:
          showCustomerList.selectedDate &&
          dayjs(new Date(showCustomerList.selectedDate))
            .add(1, 'day')
            .format('YYYY-MM-DDTHH:mm:ss.sssZ'),
        ...customerParam
      }),
    [showCustomerList, customerParam]
  );
  const { data: customerListData, isLoading: loadingCustomerList } = useQuery(
    ['customerListData', inboxId, customerFilter],
    () => getCustomerActivityAnalytics(String(inboxId), customerFilter),
    {
      enabled: !!inboxId && !!showCustomerList.selectedDate && !!showCustomerList.showModal
    }
  );

  const toggleShowDetail = () => setShowDetail((prev) => !prev);
  const showCustomerListModal = (selectedDate: string) =>
    setShowCustomerList({ showModal: true, selectedDate });

  const conversationColumnsWithAction = [
    ...conversationColumns,
    {
      title: '',
      width: 48,
      render: (row: Record<string, any>) => (
        <span className="btn-show-customer hidden">
          <RightOutlined
            className="text-gray-500"
            onClick={() => showCustomerListModal(row.date)}
          />
        </span>
      )
    }
  ];

  const _formatDateRiwayat = (date: string) => dayjs(date).format('DD MMM');

  return (
    <div className="stats-average-response min-h-[240px] space-y-4 px-4 py-2">
      <div className="flex items-center justify-between">
        <h3 className="text-xl">Waktu Respon Rata-Rata</h3>
        <div className="cursor-pointer font-semibold underline" onClick={toggleShowDetail}>
          Lihat Detail
        </div>
      </div>
      <div>
        <TimeProgressBar
          labels={[
            { label: '10 detik', value: 10 },
            { label: '30 detik', value: 30 },
            { label: '1 menit', value: 60, isTarget: true }
          ]}
          value={data?.averageResponseTime || 0}
          min={0}
          max={90}
        />
      </div>
      {showDetail && (
        <Modal
          open={showDetail}
          width="80%"
          footer={false}
          destroyOnClose
          onCancel={() => {
            toggleShowDetail();
            setRiwayatParam(DEFAULT_PARAMS);
          }}>
          <h3>
            Riwayat Percakapan Periode {_formatDateRiwayat(props.filterValues.start)} -{' '}
            {_formatDateRiwayat(props.filterValues.end)}
          </h3>
          <Table
            rowClassName="[&>td>.btn-show-customer]:hover:!block"
            columns={conversationColumnsWithAction}
            dataSource={riwayatData?.data || []}
            pagination={{
              total: riwayatData?.metadata?.totalData || 0,
              current: riwayatParam.page,
              position: ['bottomCenter'],
            }}
            loading={loadingRiwayat}
            scroll={{ x: 1024 }}
            onChange={(pagination, _, sorter) => {
              if (pagination.current !== riwayatParam.page) {
                setRiwayatParam((prev) => ({ ...prev, page: Number(pagination.current) }));
              } else if (!Array.isArray(sorter) && sorter?.field) {
                setRiwayatParam((prev) => ({
                  ...prev,
                  page: 1,
                  sortBy: sorter.order ? String(sorter.field) : '',
                  sortDir: { ascend: 'asc', descend: 'desc' }[String(sorter.order)] || ''
                }));
              }
            }}
          />
        </Modal>
      )}
      {showCustomerList && (
        <Modal
          open={showCustomerList.showModal}
          width="80%"
          footer={false}
          destroyOnClose
          onCancel={() => {
            setShowCustomerList({ showModal: false, selectedDate: '' });
            setCustomerParam(DEFAULT_PARAMS);
          }}>
          <h3>{}</h3>
          <Table
            columns={customerListColumns}
            dataSource={customerListData?.data || []}
            loading={loadingCustomerList}
            pagination={{
              total: customerListData?.metadata?.totalData || 0,
              current: customerParam.page,
              position: ['bottomCenter'],
            }}
            scroll={{ x: 1024 }}
            onChange={(pagination, _, sorter) => {
              if (pagination.current !== customerParam.page) {
                setCustomerParam((prev) => ({ ...prev, page: Number(pagination.current) }));
              } else if (!Array.isArray(sorter) && sorter?.field) {
                setCustomerParam((prev) => ({
                  ...prev,
                  page: 1,
                  sortBy: sorter.order ? String(sorter.field) : '',
                  sortDir: { ascend: 'asc', descend: 'desc' }[String(sorter.order)] || ''
                }));
              }
            }}
          />
        </Modal>
      )}
    </div>
  );
};

export default StatsAverageResponse;
