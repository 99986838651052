import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { getKYCFunnel } from '../../kyc';

export const useGetFunnelKYC = (inboxId: string, startDateISO: string, endDateISO: string) => {
  const user = localStorage.getItem('user');
  const accountId = JSON.parse(user || '').account.accountId;

  const queryInfo = useQuery(
    ['get-funnel-kyc', accountId, inboxId, startDateISO, endDateISO],
    () => getKYCFunnel(accountId, inboxId, startDateISO, endDateISO),
    {
      enabled: false,
      retry: 1
    }
  );

  useEffect(() => {
    queryInfo.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDateISO, endDateISO]);

  return queryInfo;
};
