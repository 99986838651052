import { useQuery } from 'react-query';

import { fetchInboxUserList } from '../../user';
import { getAccountId } from '../../../utils/getAccountId';

export const useInboxUserList = (inboxId: string, isOpen?: boolean) => {
  const accountId = getAccountId();
  return useQuery(
    ['AllInboxUserList', inboxId, isOpen, accountId],
    () => fetchInboxUserList(accountId as string, inboxId),
    { enabled: !!inboxId && !!accountId } // Query will run only if inboxId and accountID is not null
  );
};
