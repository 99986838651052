import { useCallback, useRef } from 'react';

const useInfiniteScroll = ({
  isFetching,
  hasMore,
  loadMore
}: {
  isFetching: boolean;
  hasMore: boolean;
  loadMore: () => void;
}) => {
  const observer = useRef<any>();
  const lastItemElementRef = useCallback(
    (node: any) => {
      if (isFetching) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          loadMore();
        }
      });
      if (node) observer.current.observe(node);
    },
    [isFetching, hasMore, loadMore]
  );

  return {
    lastItemElementRef
  };
};

export default useInfiniteScroll;
