import { useEffect, useState } from 'react';
import { Modal, Popover } from 'antd';
import { Button, Paragraph, SearchBar } from '@squantumengine/horizon';
import { useQuery } from 'react-query';

import { fetchUserList } from '../../../../../api/user';
import { useAuthStore } from '../../../../../store/new/auth';
import { RoleEnum } from '../../../../../static/role';

import PopoverAgentDetail from './popover-agent-detail';
import ChipAgent from './chip-agent';
import useMount from '../../../../../hooks/useMounth';
import useTeamCreateEditStore from '../../store';
import ViewNoAgent from './view-no-agent';

import { AgentListItemInterface } from '../../../../../shared/types/agent.interface';

export default function FormAssignAgent() {
  const { account } = useAuthStore();
  const [searchValue, setSearchValue] = useState('');
  const [searchSelectedValue, setSearchSelectedValue] = useState('');
  const [selectedAgents, setSelectedAgents] = useState<string[]>([]);
  const { assignAgent, setAssignAgent } = useTeamCreateEditStore();

  const { data: agents } = useQuery<AgentListItemInterface[]>({
    queryKey: ['get-agents'],
    queryFn: () => fetchUserList(account.accountId, [RoleEnum.AGENT])
  });

  const filteredAgents = agents
    ? agents.filter((agent) => {
        const name = `${agent.firstName} ${agent.lastName}`.toLowerCase();
        if (selectedAgents.includes(agent.agentId)) return false;
        return name.includes(searchValue.toLowerCase());
      })
    : [];

  const filteredSelectedAgents = agents
    ? agents.filter((agent) => {
        const name = `${agent.firstName} ${agent.lastName}`.toLowerCase();
        return (
          selectedAgents.includes(agent.agentId) && name.includes(searchSelectedValue.toLowerCase())
        );
      })
    : [];

  const selectAgent = (id: string) => {
    setSelectedAgents((prev) => [...prev, id]);
  };

  const deselectAgent = (id: string) => {
    setSelectedAgents((prev) => prev.filter((p) => p !== id));
  };

  const deleteAll = async () => {
    const modal = Modal.confirm({
      title: 'Hapus Semua?',
      content: 'List Agen terpilih akan dikosongkan.',
      icon: null,
      closable: true,
      footer: (
        <div className="flex flex-col space-y-2 pt-4">
          <Button
            data-testid="button-ya-hapus-semua"
            variant="primary"
            onClick={() => {
              setSelectedAgents([]);
              modal.destroy();
            }}>
            Ya, Hapus Semua
          </Button>
          <Button data-testid="button-batalkan" variant="secondary" onClick={() => modal.destroy()}>
            Batalkan
          </Button>
        </div>
      )
    });
  };

  useEffect(() => {
    setAssignAgent(selectedAgents);
  }, [selectedAgents, setAssignAgent]);

  useMount(() => {
    setSelectedAgents(assignAgent);
  });

  if (!agents || agents.length === 0) return <ViewNoAgent />;

  return (
    <div className="h-full space-y-4 px-4 pb-4">
      <div className="space-y-4 rounded-xl bg-white p-6 shadow-md">
        <h3 className="m-0 flex items-center space-x-2 text-lg font-semibold">Tugaskan Agent</h3>
        <div className="grid grid-flow-col grid-cols-2 gap-4">
          <div className="flex h-full flex-col overflow-hidden rounded-lg border border-solid border-gray-300 p-4">
            <Paragraph size="l" weight="bold">
              Agen Tersedia ({filteredAgents.length})
            </Paragraph>

            <SearchBar
              value={searchValue}
              onChange={setSearchValue}
              className="my-4 w-full"
              size="compact"
            />

            <div className="h-[300px] overflow-auto">
              {filteredAgents.map((agent) => {
                return (
                  <Popover
                    key={agent.agentId}
                    placement="left"
                    content={<PopoverAgentDetail agent={agent} />}>
                    <>
                      <ChipAgent onClick={() => selectAgent(agent.agentId)} agent={agent} />
                    </>
                  </Popover>
                );
              })}
            </div>
          </div>
          <div className="rounded-lg border border-solid border-gray-300 p-4">
            <div className="flex flex-col justify-between lg:flex-row lg:items-center">
              <Paragraph size="l" weight="bold">
                Agen Terpilih ({filteredSelectedAgents.length})
              </Paragraph>

              <Paragraph
                data-testid="button-hapus-semua"
                size="r"
                weight="bold"
                className="cursor-pointer text-blue-500"
                onClick={deleteAll}>
                Hapus Semua
              </Paragraph>
            </div>

            <SearchBar
              value={searchSelectedValue}
              onChange={setSearchSelectedValue}
              className="my-4 w-full"
              size="compact"
            />

            <div className="h-[300px] overflow-y-scroll ">
              {filteredSelectedAgents.map((agent) => {
                return (
                  <ChipAgent
                    onClick={() => deselectAgent(agent.agentId)}
                    key={agent.agentId}
                    agent={agent}
                    showCloseIcon
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
