import { forwardRef, useImperativeHandle, useMemo, useState } from 'react';

import useConversation from '../../../../../hooks/useConversation';

import { ConversationInterface } from '../../../../../components/card/chat-message/chat-message.interface';
import { useConversationListStore } from '../../../../../store/conversation-list-state';
import { useUserStatusStore } from '../../../../../store/user-state';
import { RoleEnum } from '../../../../../static/role';
import SkeletonTicket from '../../components/skeleton-ticket';
import { TabConversation } from '../../dashboard-supevisor.interface';
import ConversationItem from './conversation-item';
import useInboxId from '../../../../../hooks/useInboxId';

type TicketActiveProps = {
  selectedTab: TabConversation;
  filter: {
    email: string;
    name: string;
    ticketNumber: string;
    phoneNumber: string;
    startDate: string;
    endDate: string;
  };
  handleActiveCall: (conversation: ConversationInterface) => void;
};

export type TicketActiveComponent = {
  refetchTicketActive: () => any;
  isFetching: () => boolean;
};

const TicketActive = forwardRef<TicketActiveComponent, TicketActiveProps>(
  ({ filter, selectedTab, handleActiveCall }: TicketActiveProps, ref) => {
    const [page, setPage] = useState(1);
    const currentInboxId = useInboxId();

    const { activeConversationList, assignedConversationList } = useConversationListStore();

    const conversationList = useMemo(() => {
      const conversations = [...assignedConversationList, ...activeConversationList]
        .reduce<ConversationInterface[]>((acc, current) => {
          const duplicated = acc.find((item) => item.id === current.id);
          return duplicated ? acc : acc.concat([current]);
        }, [])
        .filter((item) => item.inboxId === currentInboxId);

      if (selectedTab === TabConversation.ONGOING)
        return conversations.filter((item) => !!item.jawabAt);
      else if (selectedTab === TabConversation.CREATED)
        return conversations.filter((item) => !item.jawabAt);
      return conversations;
    }, [activeConversationList, assignedConversationList, selectedTab, currentInboxId]);

    const { userRole } = useUserStatusStore();

    const { refetch, isFetching } = useConversation({
      filter,
      active: true,
      page,
      limit: 9
    });
    const isAgent = userRole === RoleEnum.AGENT;

    const reInitialize = () => {
      setPage(() => {
        refetch();
        return 1;
      });
    };

    useImperativeHandle(ref, () => ({
      refetchTicketActive: reInitialize,
      isFetching: () => isFetching
    }));

    return (
      <div>
        {isFetching && !isAgent && <SkeletonTicket />}
        <div>
          {conversationList.map((item) => (
            <ConversationItem key={item.conversationId} {...item} />
          ))}
        </div>
      </div>
    );
  }
);

export default TicketActive;
