import { useNavigate, useParams } from 'react-router-dom';
import ChatIcon from '../../../assets/icons/chat';
import VideoIcon from '../../../assets/icons/video';
import VoiceCallIcon from '../../../assets/icons/voice-call';
import DetailCardLayout from './components/DetailCardLayout';
import useTeamCreateEditStore from '../create-edit/store';
import { ChannelEnum, CreateTeamFormSection } from '../create-edit/type';
import { ConversationContext } from '../../../types/new/inbox';
import { CommunicationTypeEnum } from '../../../static/communication-type';
import { CreateEditSections } from '../create-edit/store';
import { useGetTopic } from '../../../api/hooks/inbox/useTopic';
import { Paragraph } from '@squantumengine/horizon';
import { useGetReason } from '../../../api/hooks/inbox/useRejectReason';

const channelLabel = {
  [ChannelEnum.WEB_WIDGET]: 'Web Widget',
  [ChannelEnum.WHATSAPP]: 'WhatsApp'
};

const typeConversationLabel = {
  [ConversationContext.GENERAL]: 'Umum',
  [ConversationContext.MANUAL_KYC]: 'Manual KYC'
};

const methodConversationChip = {
  [CommunicationTypeEnum.CHAT]: {
    label: 'Chat',
    icon: <ChatIcon />
  },
  [CommunicationTypeEnum.CHAT_INA]: {
    label: 'Chat',
    icon: <ChatIcon />
  },
  [CommunicationTypeEnum.VIDEO]: {
    label: 'Panggilan Video',
    icon: <VideoIcon />
  },
  [CommunicationTypeEnum.VIDEO_INA]: {
    label: 'Chat',
    icon: <ChatIcon />
  },
  [CommunicationTypeEnum.VOICE]: {
    label: 'Panggilan Suara',
    icon: <VoiceCallIcon />
  }
};

export default function TeamInformationDetail() {
  const navigate = useNavigate();
  const { inboxId } = useParams();
  const { formTeamConfiguration, channelType, setSelectedSection } = useTeamCreateEditStore();
  const { inboxName, conversationContext, communicationModes } = formTeamConfiguration;

  const { data: inboxTopics } = useGetTopic(inboxId ?? '');
  const { data: inboxReason } = useGetReason(inboxId ?? '');

  const handleEdit = () => {
    setSelectedSection(
      CreateEditSections.findIndex((section) => section.key === CreateTeamFormSection.FORM_TEAM)
    );
    navigate(`/v2/dashboard/team/edit/${inboxId}`);
  };

  return (
    <DetailCardLayout title="Informasi Tim" buttonAction={handleEdit}>
      <div className="grid grid-cols-[250px,auto] gap-y-4 text-sm">
        <>
          <Paragraph className="text-neutral-600">Nama Tim</Paragraph>
          <Paragraph>{inboxName}</Paragraph>
        </>
        <>
          <Paragraph className="text-neutral-600">Channel</Paragraph>
          <Paragraph>{channelLabel[channelType as ChannelEnum]}</Paragraph>
        </>
        <>
          <Paragraph className="text-neutral-600">Tipe Percakapan</Paragraph>
          <Paragraph>{typeConversationLabel[conversationContext as ConversationContext]}</Paragraph>
        </>
        <>
          <Paragraph className="flex h-full items-center text-neutral-600">
            Metode Percakapan
          </Paragraph>
          <div className="flex gap-2 text-gray-500">
            {communicationModes?.map(
              (type, index) =>
                type && <ConversationMethodChip key={index} type={type as CommunicationTypeEnum} />
            )}
          </div>
        </>
        <>
          <Paragraph className="text-neutral-600">Topik Percakapan</Paragraph>
          <Paragraph>
            {inboxTopics?.conversationTopics.map((topic, index, arr) => (
              <span key={index}>
                {topic.topic}
                {arr.length - 1 !== index && ', '}
              </span>
            ))}
          </Paragraph>
        </>
        {conversationContext === ConversationContext.MANUAL_KYC && (
          <>
            <Paragraph className="text-neutral-600">Alasan Penolakan KYC</Paragraph>
            <Paragraph>
              {inboxReason?.map((reason, index, arr) => (
                <span key={index}>
                  {reason.reason}
                  {arr.length - 1 !== index && ', '}
                </span>
              ))}
            </Paragraph>
          </>
        )}
      </div>
    </DetailCardLayout>
  );
}

const ConversationMethodChip = ({ type }: { type: CommunicationTypeEnum }) => {
  const { label, icon } = methodConversationChip[type];
  return (
    <div className="flex w-fit gap-1 rounded-md border border-solid border-gray-300 bg-neutral-100 px-2 py-0.5">
      {icon}
      <Paragraph className="font-semibold">{label}</Paragraph>
    </div>
  );
};
