import { Typography } from '@squantumengine/horizon';
import EmptyIcon from '../../assets/icons/empty';
import ButtonEmailResetPassword from '../../components/login/button-email-reset-password';
import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { NativeLoginWordingEnum } from '../../static/native-login';
import ErrorForm from '../../components/form/error-form/error-form';

const ExpiredResetLink = () => {
  const [errMsg, setErrorMsg] = useState<string | JSX.Element>('');
  const [emailSent, setEmailSent] = useState(false);
  const location = useLocation();
  const { userEmail } = location?.state || {};

  const showError = (msg: string) => {
    setErrorMsg(msg);
  };

  if (emailSent) return <Navigate to="/reset-link-sent" />;

  return (
    <div className="flex h-screen w-full flex-col items-center justify-center">
      <EmptyIcon width={250} height={200}></EmptyIcon>
      <Typography.Title level={4} className="text-neutral-950">
        {NativeLoginWordingEnum.resetLinkExpired}
      </Typography.Title>
      <Typography.Paragraph className="max-w-[480px] text-center text-neutral-950">
        {NativeLoginWordingEnum.resetLinkExpiredDescription}
      </Typography.Paragraph>

      {errMsg && <ErrorForm isVisible={true} message={errMsg} className="mt-[24px]" />}

      <div className="mt-[24px] w-[330px]">
        <ButtonEmailResetPassword
          value={userEmail}
          valueKey="uuid"
          onEmailSent={() => setEmailSent(true)}
          onError={showError}
          disabled={!userEmail}
        />
      </div>
    </div>
  );
};

export default ExpiredResetLink;
