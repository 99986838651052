import { ExclamationCircleFilled, PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Paragraph } from '@squantumengine/horizon';
import { Flex, Form, Select, Tooltip } from 'antd';
import { Precoding } from '../../../../../../shared/types/whatsapp.interface';

interface FormItemWhatsAppProps {
  item: any;
  index: number;
  templateOptions: any;
  selectedAutoMessage: any;
  SQEMPPrecodings: any;
  handleAddSQEMPTemplate: () => void;
  handleSelecteTemplateChange: (name: string, value: string) => void;
  handleSelectPrecodingAutoMessage: (name: string, precodingKey: string, value: string) => void;
}

const FormItemWhatsApp = ({
  item,
  index,
  templateOptions,
  selectedAutoMessage,
  SQEMPPrecodings,
  handleAddSQEMPTemplate,
  handleSelecteTemplateChange,
  handleSelectPrecodingAutoMessage
}: FormItemWhatsAppProps) => {
  const labelWithTooltip = (
    <span className="font-semibold">
      {item.label}
      {item.tooltip && (
        <Tooltip title={item.tooltip} className="ml-1 fill-black">
          <QuestionCircleOutlined />
        </Tooltip>
      )}
    </span>
  );

  const renderRequiredMessage = (label: string) => (
    <div className="mt-1 flex items-center space-x-1 text-red-500">
      <ExclamationCircleFilled /> <span>{label}</span>
    </div>
  );

  return (
    <div className="space-y-4 rounded-xl border border-solid border-gray-100 p-4">
      <div className="flex items-center gap-2">
        {labelWithTooltip}
        <div className="ml-auto max-w-[270px] flex-1">
          <Form.Item
            name={item.name}
            key={index}
            colon={false}
            className="mb-0 w-full"
            rules={[
              { required: true, message: renderRequiredMessage('Template SQEMP wajib diisi') }
            ]}>
            <Select
              data-testid={'select-template-' + index}
              onChange={(value) => {
                handleSelecteTemplateChange(item.name, value);
              }}
              className="w-full"
              placeholder="Pilih Template SQEMP"
              options={templateOptions}
              popupMatchSelectWidth={false}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Button variant="text" size="sm" onClick={handleAddSQEMPTemplate}>
                    <div className="flex items-center space-x-2">
                      <PlusOutlined />
                      <span className="whitespace-nowrap">Tambah Template SQEMP Baru</span>
                    </div>
                  </Button>
                </>
              )}
            />
          </Form.Item>
        </div>
      </div>

      {selectedAutoMessage[item.name] && selectedAutoMessage[item.name]?.content?.body && (
        <Flex className="mt-4" vertical gap={4}>
          <Flex className="rounded-lg border border-solid border-neutral-100 bg-neutral-50 p-2">
            <Paragraph>{selectedAutoMessage[item.name]?.content?.body}</Paragraph>
          </Flex>
          <Flex className="mt-4" vertical>
            {selectedAutoMessage[item.name]?.precodings?.map(
              (precodingKey: string, index: number) => (
                <div className="flex items-center gap-2">
                  <label>{precodingKey}</label>
                  <div className="ml-auto max-w-[270px] flex-1">
                    <Form.Item
                      name={`${item.name}-${precodingKey}`}
                      key={index}
                      colon={false}
                      className="mb-2">
                      <Select
                        data-testid={'select-precoding-' + index}
                        onChange={(value) =>
                          handleSelectPrecodingAutoMessage(item.name, precodingKey, value)
                        }
                        className="w-full"
                        placeholder="Pilih Segmentasi">
                        {SQEMPPrecodings?.attributes?.length &&
                          SQEMPPrecodings?.attributes?.map((precoding: Precoding) => (
                            <Select.Option
                              key={precoding.displayNameKey}
                              value={precoding.displayNameKey}>
                              {precoding.displayName}
                            </Select.Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              )
            )}
          </Flex>
        </Flex>
      )}
    </div>
  );
};

export default FormItemWhatsApp;
