import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { Checkbox, Dropdown, Space } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { DownOutlined } from '@ant-design/icons';
import StatsRatingPerformance from '../stats-rating-performance';
import { itemsBusyHour } from '../busy-hour/busy-hour.interface';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getConversationDurationByCustomer } from '../../../../api/analytics';
import CustomBarChart from './bar-chart';
import { convertToDay, convertToMonthlyData, convertToWeeklyData } from './date-converter';
import { DetailData } from './total-conversation.inteface';
import { DurationKeyEnum } from '../../../../static/analysis';

type Props = { filterValues: Record<string, any> };

function TotalConversation(props: Props) {
  const [detailData, setDetailData] = useState<DetailData>();
  const [data, setData] = useState<any>(null);
  const [showDetailData, setShowDetailData] = useState<boolean>(false);
  const [selectedMenu, setSelectedMenu] = useState<number>(0);
  const [hideConvType, setHideConvType] = useState<any>({
    all: false,
    average: false
  });
  const { inboxId } = useParams();

  const totalConversationFilter = useMemo(() => {
    const mergedFilter = { ...props.filterValues };
    const filteredEntries = Object.entries(mergedFilter).filter(([_, value]) => !!value);
    return Object.fromEntries(filteredEntries);
  }, [props.filterValues]);

  const { data: TotalConvData } = useQuery(
    ['customerDuration', inboxId, totalConversationFilter],
    () => getConversationDurationByCustomer(String(inboxId), totalConversationFilter),
    { enabled: !!inboxId }
  );

  /**
   * Renders the footer conversation element.
   * @param text The text to display.
   * @param dataKey The data key.
   * @param bgColor The background color.
   * @returns The JSX element.
   */
  useEffect(() => {
    if (TotalConvData?.dailyRatingAndDurations) {
      const dataDaily = convertToDay(TotalConvData?.dailyRatingAndDurations);
      const dataWeekLy = convertToWeeklyData(dataDaily);
      const dataMontly = convertToMonthlyData(dataDaily);
      setData(
        selectedMenu === DurationKeyEnum.DAY
          ? dataDaily
          : selectedMenu === DurationKeyEnum.WEEK
          ? dataWeekLy
          : dataMontly
      );
    }
    //eslint-disable-next-line
  }, [TotalConvData]);

  const renderFooterConversation = (): JSX.Element => {
    const handleCheckboxChange = (e: CheckboxChangeEvent, type: string) => {
      setHideConvType({
        ...hideConvType,
        [type]: !e.target.checked
      });
    };

    return (
      <>
        <div className="flex flex-row items-center gap-2">
          <div className="flex flex-row">
            <div className={`h-3 w-3 rounded-full bg-[#A2AAB8]`} />
            <div className={`-ml-[7px] h-3 w-3 rounded-full bg-blue-200`} />
            <div className={`-ml-[7px] h-3 w-3 rounded-full bg-red-300`} />
            <div className={`-ml-[7px] h-3 w-3 rounded-full bg-red-200`} />
            <div className={`-ml-[7px] h-3 w-3 rounded-full bg-yellow-300`} />
            <div className={`-ml-[7px] h-3 w-3 rounded-full bg-green-200`} />
            <div className={`-ml-[7px] h-3 w-3 rounded-full bg-green-300`} />
          </div>
          <p>Jumlah Percakapan & Kepuasan Pelanggan</p>
          <Checkbox
            data-testid="checkbox-satisfaction"
            defaultChecked
            onChange={(e) => handleCheckboxChange(e, 'all')}
          />
        </div>
        <div className="flex flex-row items-center gap-2">
          <div className={`bg-sqeui-blue-200 h-3 w-3 rounded-full`} />

          <p>Durasi Rata-Rata</p>
          <Checkbox defaultChecked onChange={(e) => handleCheckboxChange(e, 'average')} />
        </div>
      </>
    );
  };

  const onClick = (e: { key: string }): void => {
    setSelectedMenu(Number(e.key));
    const dataDaily = convertToDay(TotalConvData?.dailyRatingAndDurations);
    const dataWeekLy = convertToWeeklyData(dataDaily);
    const dataMontly = convertToMonthlyData(dataDaily);
    setData(
      Number(e.key) === DurationKeyEnum.DAY
        ? dataDaily
        : Number(e.key) === DurationKeyEnum.WEEK
        ? dataWeekLy
        : dataMontly
    );
  };

  const handleClickBar = (param: DetailData) => {
    setShowDetailData(true);
    setDetailData(param);
  };
  return (
    <div className="relative mt-4 w-full">
      <h1 className="m-0 p-0 text-3xl font-semibold">Jumlah dan Durasi Percakapan</h1>
      <div className="my-10 flex w-full flex-row">
        <div className="w-1/2">
          <h3 className="m-0 p-0 text-xl font-normal">Jumlah Rata-Rata</h3>
          <h3 className="m-0 p-0 text-3xl font-semibold">
            {TotalConvData?.averageConversation} Percakapan
          </h3>
        </div>
        <div className="w-1/2">
          <h3 className="m-0 p-0 text-xl font-normal">Durasi Rata-Rata</h3>
          <h3 className="m-0 p-0 text-3xl font-semibold text-blue-400">
            {(TotalConvData?.averageDuration / 60).toFixed(0)} Menit
          </h3>
        </div>
      </div>
      {data && <CustomBarChart data={data} hideConvType={hideConvType} onClick={handleClickBar} />}

      {showDetailData && (
        <StatsRatingPerformance data={detailData} setShowDetailData={setShowDetailData} />
      )}
      <div className="mt-5 flex w-full flex-row items-center justify-between">
        <div className="flex w-8/12 flex-row items-center gap-4">{renderFooterConversation()}</div>
        <div className="flex w-4/12 flex-row items-center justify-end gap-2">
          <p className="m-0 p-0">Tampilan Data</p>
          <Dropdown
            className="mx-2 h-10 w-full max-w-[159px] cursor-pointer rounded-full border-solid border-grey-80 px-4"
            menu={{ items: itemsBusyHour, onClick }}>
            <Space className="flex w-full justify-between">
              <div className="w-full overflow-hidden truncate whitespace-nowrap">
                {itemsBusyHour?.[selectedMenu]?.label}
              </div>
              <DownOutlined />
            </Space>
          </Dropdown>
        </div>
      </div>
    </div>
  );
}

export default TotalConversation;
