import { Icon, Paragraph } from '@squantumengine/horizon';

type TitleCollapseProps = {
  icon: string;
  title: string;
  actionEl?: JSX.Element;
};

const TitleCollapse = ({ icon, title, actionEl }: TitleCollapseProps) => {
  return (
    <div className="flex flex-row items-center space-x-1">
      <div className="flex items-center">
        <Icon name={icon}></Icon>
      </div>
      <Paragraph size="xl" weight="bold">
        {title}
      </Paragraph>
      {actionEl}
    </div>
  );
};

export default TitleCollapse;
