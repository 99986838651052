import { useMemo, useRef, useState } from 'react';
import ChatBox from '../../../../components/v2/card/chatbox/conversation';
import { useDashboardPage } from '../../../../hooks/useDashboardPage';
import { useChatListStore } from '../../../../store/chat-list';
import TicketActive, { TicketActiveComponent } from './ticket-active';
import TicketInactive, { TicketInactiveComponent } from '../components/ticket-inactive';
import TicketOffline, { TicketOfflineComponent } from '../components/ticket-offline';
import Filter from '../components/filter';
import NoChat from '../../../../components/v2/card/chatbox/no-chat';
import TabFilterConversation from '../components/filter/tab-filter-conversation';
import { TabConversation } from '../dashboard-supevisor.interface';
import { Typography } from '@squantumengine/horizon';
import { useConversationListStore } from '../../../../store/conversation-list-state';
import OfflineMessageChatBox from '../../../../components/v2/card/chatbox/offline-message';
import { ConversationInterface } from '../../../../components/v2/card/chat-message/chat-message.interface';
import AgentWorkspace from '../../../../components/v2/workspace/agent';
import IncomingNotification from '../components/notification/incoming-notification';

function DashboardAgentV2() {
  const { selectedOfflineChat, selectedCall } = useChatListStore();
  const { handleEndCall, handleActiveCall } = useDashboardPage();
  const [selectedTab, setSelectedTab] = useState<TabConversation>(TabConversation.ALL);
  const [filters, setFilters] = useState<Record<string, any>>({
    filterBy: {
      key: '',
      value: ''
    }
  });

  const ticketActiveRef = useRef<TicketActiveComponent>(null);
  const ticketOfflineRef = useRef<TicketOfflineComponent>(null);
  const ticketInactive = useRef<TicketInactiveComponent>(null);

  const { assignedConversationList, activeConversationList, closedConversationList } =
    useConversationListStore();

  const { incomingConversationList, onGoingConversationList } = useMemo(() => {
    const conversations = [...assignedConversationList, ...activeConversationList].reduce<
      ConversationInterface[]
    >((acc, current) => {
      const duplicated = acc.find((item) => item.id === current.id);
      return duplicated ? acc : acc.concat([current]);
    }, []);
    return {
      incomingConversationList: conversations.filter((item) => !item.jawabAt),
      onGoingConversationList: conversations.filter((item) => !!item.jawabAt)
    };
  }, [assignedConversationList, activeConversationList, selectedTab]);

  const getFiltersValues = () => {
    const filter: any = {
      email: '',
      name: '',
      phoneNumber: '',
      ticketNumber: ''
    };

    if (filters.filterBy.key) {
      if (filters.filterBy.key === 'ticket') {
        filter.ticketNumber = filters.filterBy.value;
      }
      if (filters.filterBy.key === 'phone') {
        filter.phoneNumber = filters.filterBy.value;
      }
      filter[filters.filterBy.key] = filters.filterBy.value;
    }

    return filter;
  };

  const showIncomingTicket =
    [TabConversation.ALL, TabConversation.CREATED].includes(selectedTab) &&
    incomingConversationList.length > 0;

  const showOngoingTicket =
    [TabConversation.ALL, TabConversation.ONGOING].includes(selectedTab) &&
    onGoingConversationList.length > 0;

  const showTicketOffline =
    [TabConversation.ALL, TabConversation.OFFLINE].includes(selectedTab) &&
    ticketOfflineRef?.current?.total;
  const showTicketResolved =
    [TabConversation.ALL, TabConversation.RESOLVED].includes(selectedTab) &&
    closedConversationList.length > 0;

  const isEmptyConversation =
    !showIncomingTicket && !showOngoingTicket && !showTicketOffline && !showTicketResolved;

  return (
    <div className="flex bg-white">
      <IncomingNotification handleActiveCall={handleActiveCall} />
      <div className="flex h-[calc(100vh-64px)] w-[360px] flex-col">
        <div className="flex flex-col space-y-4 px-2 py-4">
          <Filter onChange={setFilters} />
        </div>
        <div className="pb-4">
          <TabFilterConversation onChange={setSelectedTab} value={selectedTab} />
        </div>
        {isEmptyConversation && (
          <div className="flex justify-center pt-8 text-gray-500">
            <Typography.Paragraph>Tidak ada tiket ditemukan</Typography.Paragraph>
          </div>
        )}
        <div className="flex h-full flex-col overflow-x-scroll">
          <div className={`${!showIncomingTicket && !showOngoingTicket && 'hidden'} flex-col`}>
            <TicketActive
              ref={ticketActiveRef}
              selectedTab={selectedTab}
              filter={getFiltersValues()}
              handleActiveCall={handleActiveCall}
            />
          </div>
          <div className={`${!showTicketOffline && 'hidden'} flex-col`}>
            <TicketOffline ref={ticketOfflineRef} filter={getFiltersValues()} />
          </div>

          <div className={`${!showTicketResolved && 'hidden'} flex-col`}>
            <TicketInactive ref={ticketInactive} filter={getFiltersValues()} />
          </div>
        </div>
      </div>

      {(selectedCall || selectedOfflineChat) && (
        <div className="flex h-[calc(100vh-64px)] w-full min-w-[470px] flex-col gap-4 overflow-y-auto border-y-0 border-l border-r-0 border-solid border-l-grey-50 bg-white">
          {selectedOfflineChat && <OfflineMessageChatBox />}
          {selectedCall && <ChatBox onClose={handleEndCall} allowRefresh />}
        </div>
      )}

      <div className="flex h-[calc(100vh-64px)] w-full min-w-[394px] flex-col gap-4 overflow-y-auto border-y-0 border-l border-r-0 border-solid border-l-grey-50 bg-white">
        {!selectedCall && !selectedOfflineChat && <NoChat />}
        {(selectedCall || selectedOfflineChat) && <AgentWorkspace />}
      </div>
    </div>
  );
}

export default DashboardAgentV2;
