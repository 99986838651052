import { useEffect, useState } from 'react';
import { Col, Row, Spin } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { removeInboxMember } from '../../../api';
import { ListUserInboxInterface } from './list-user-inbox.interface';
import {
  TeamActiveHandlerInterface,
  TeamUserListInterface
} from '../../../shared/types/team.interface';
import { FormTypeEnum, FormActionEnum } from '../../../static/team';

import { useTeamStore } from '../../../store/team-state';
import UserCard from '../user';
import { RoleEnum } from '../../../static/role';

export default function ListUserInbox(props: ListUserInboxInterface) {
  const { accountId, inboxId } = props;
  const {
    isOpenForm,
    formAction,
    formType,
    selectedInbox,
    teamActiveHandler,
    setIsOpenForm,
    setFormAction,
    setFormType,
    setSelectedBU,
    setSelectedInbox,
    fetchUserInbox,
    fetchAvailableAgentInbox,
    setTeamActiveHandler,
    resetTeamActiveHandler
  } = useTeamStore();

  const accountListItem = useTeamStore((state) => state.account);
  const inboxListItem = useTeamStore((state) => state.inbox);
  const userListItem = useTeamStore((state) => state.user);
  const [userList, setUserList] = useState<TeamUserListInterface>({
    accountId: '',
    inboxId: '',
    isCreate: false,
    userList: []
  } as TeamUserListInterface);
  const [loading, setLoading] = useState<boolean>(false);

  const addAgent = async () => {
    const inboxListIndex = inboxListItem.findIndex(
      (inboxItem) => inboxItem.accountId === accountId
    );

    const openForm = !(
      isOpenForm &&
      formAction === FormActionEnum.CHOOSE &&
      formType === FormTypeEnum.USER &&
      selectedInbox.inboxId === inboxId
    );

    resetTeamActiveHandler();

    const newTeamActiveHandler: TeamActiveHandlerInterface = {
      ...useTeamStore.getState().teamActiveHandler,
      activeInboxId: openForm ? inboxId : '',
      isAddAgent: openForm
    };

    setTeamActiveHandler(newTeamActiveHandler);

    // Close form only if the user is create inbox
    // Close form only if the user is edit business unit
    setIsOpenForm(openForm);
    setFormAction(FormActionEnum.CHOOSE);
    setFormType(FormTypeEnum.USER);
    // Set selected business unit and inbox
    const newSelectedInbox = inboxListItem[inboxListIndex].inboxList.find(
      (inbox) => inbox.inboxId === inboxId
    );
    const newSelectedBU = accountListItem.accountList.find(
      (account) => account.accountId === accountId
    );

    newSelectedInbox && setSelectedInbox(newSelectedInbox);
    newSelectedBU && setSelectedBU(newSelectedBU);
    await fetchAvailableAgentInbox(accountId, inboxId);
  };

  const userAction = async (e: any, agentId: string) => {
    try {
      await removeInboxMember(accountId, inboxId, agentId);
      await fetchUserInbox(accountId, inboxId);
      await fetchAvailableAgentInbox(accountId, inboxId);
      if (formAction !== FormActionEnum.CHOOSE && formType !== FormTypeEnum.USER) {
        resetTeamActiveHandler();
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  useEffect(() => {
    setLoading(true);
    const currentUser = userListItem.find(
      (userItem) => userItem.accountId === accountId && userItem.inboxId === inboxId
    );
    if (currentUser) {
      setUserList(currentUser);
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userListItem]);

  const isOpen = formAction === FormActionEnum.CHOOSE && formType === FormTypeEnum.USER;

  return (
    <Spin spinning={loading}>
      <Row id={`user_list-${inboxId}`} gutter={[16, 16]}>
        <Col span={6}>
          <div
            id={`user_list-create_user-${inboxId}`}
            className={`${
              isOpen && teamActiveHandler.activeInboxId === inboxId
                ? 'border-4 border-green-emerald-light px-[13px] py-[5px]'
                : 'border border-grey-80 px-4 py-2'
            } click-animation mt-1 rounded-md
               border-solid text-base
            font-semibold text-black-90 hover:bg-grey-20 active:bg-grey-30`}
            onClick={addAgent}>
            <div>
              <PlusOutlined />
            </div>
            <div>Tugaskan User</div>
          </div>
        </Col>
        {!!userList?.userList?.length &&
          userList.userList.map((user, idx) => (
            <UserCard
              key={idx}
              type={RoleEnum.AGENT}
              agentId={user.agentId}
              inboxId={inboxId}
              firstName={user.firstName}
              lastName={user.lastName}
              id={`user-${idx}`}
              onUserAction={userAction}
              roleLabel="Agent"
            />
          ))}
      </Row>
    </Spin>
  );
}
