import { useEffect, useState } from 'react';
import { Col, Row, Collapse, notification, ConfigProvider } from 'antd';
import { CheckCircleOutlined, DownOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';

import { updateWorkingHours } from '../../../api';
import { useTeamStore } from '../../../store/team-state';
import { workingHour } from '../../../shared/types/inbox.interface';
import { NumberToTimeString } from '../../../utils/timestamp';
import { dayOfWeeks } from '../../../static/working-hour';

import OperationalHourSelectorForm from '../operational-hour-selector';
import { defaultInboxValues } from '../../../static/inbox-config';

function WorkHourForm() {
  const { Panel } = Collapse;
  const [api, contextHolder] = notification.useNotification();
  const { selectedBU, selectedInbox, fetchInbox, inboxResponse, setIsOpenForm } = useTeamStore();
  const [openedPanel, setOpenedPanel] = useState<string[]>([]);
  const [settingAllDay, setSettingAllDay] = useState<boolean>(false);

  const [workingHour] = useState<workingHour>({
    closedAllDay: false,
    openAllDay: false,
    openHour: 9,
    openMinutes: 0,
    closeHour: 17,
    closeMinutes: 0
  } as workingHour);
  const [selectedDays, setSelectedDays] = useState<number[]>([]);
  const [allWorkingHour, setAllWorkingHour] = useState<workingHour[]>([]);

  const operationalHourText = (workingHour: workingHour) => {
    if (workingHour.closedAllDay) {
      return 'Tutup';
    }
    if (workingHour.openAllDay) {
      return 'Buka 24 jam';
    }
    return `${NumberToTimeString(workingHour.openHour || 0)}:${NumberToTimeString(
      workingHour.openMinutes || 0
    )} - ${NumberToTimeString(workingHour.closeHour || 0)}:${NumberToTimeString(
      workingHour.closeMinutes || 0
    )}`;
  };

  const selectDay = (idx: number) => {
    if (selectedDays.includes(idx)) {
      setSelectedDays(selectedDays.filter((day) => day !== idx));
    } else {
      setSelectedDays([...selectedDays, idx]);
    }
  };

  const updateDayWorkingHour = async (workingHour: workingHour[]) => {
    const newWorkingHours = allWorkingHour?.map((day) => {
      const newWorkingHour = workingHour?.find((wh) => wh.dayOfWeek === day.dayOfWeek);
      return newWorkingHour || day;
    });
    newWorkingHours?.sort((a, b) => a.dayOfWeek - b.dayOfWeek);
    try {
      await updateWorkingHours(
        selectedBU.accountId,
        selectedInbox?.inboxId || inboxResponse?.inboxId || '',
        true,
        selectedInbox?.oooMessage ||
          inboxResponse.oooMessage ||
          defaultInboxValues.outsideWorkingHourMessage,
        newWorkingHours
      );
      await fetchInbox(selectedBU.accountId);
      closeForm();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const closeForm = () => {
    api.success({
      icon: <CheckCircleOutlined style={{ color: 'white' }} />,
      message: 'Jam Layanan berhasil diubah',
      placement: 'topRight'
    });

    setTimeout(() => {
      setIsOpenForm(false);
    }, 1000);
  };

  useEffect(() => {
    if (selectedInbox) {
      setAllWorkingHour(selectedInbox.workingHours);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedInbox]);

  return (
    <>
      <ConfigProvider
        theme={{
          token: { colorText: 'white', colorBgElevated: '#2DB089', colorIcon: 'white' }
        }}>
        {contextHolder}
      </ConfigProvider>
      <Row id="inbox_form" className="w-full rounded-xl bg-white">
        <Col span={24}>
          {selectedInbox && settingAllDay ? (
            <div className="rounded-xl bg-grey-10 p-4">
              <Row gutter={[8, 12]} className="mb-4">
                {dayOfWeeks.map((day, idx) => (
                  <Col key={`inbox_list-${idx + 1}`} span={12}>
                    <div
                      className={`${
                        selectedDays.includes(idx + 1)
                          ? 'border-4 border-green-emerald py-[1px]'
                          : 'border border-black-90 py-1'
                      } click-animation flex flex-row items-center
                        justify-center rounded-full border border-solid `}
                      onClick={() => selectDay(idx + 1)}>
                      <div className="text-base font-semibold">{day.label}</div>
                    </div>
                  </Col>
                ))}
              </Row>
              <OperationalHourSelectorForm
                {...workingHour}
                selectedDays={selectedDays}
                updateDayWorkingHour={updateDayWorkingHour}
              />
            </div>
          ) : (
            dayOfWeeks.map((day, idx) => (
              <Collapse
                key={`inbox_list-${idx}`}
                className="work-schedule mt-4 transition-all duration-100"
                ghost
                onChange={(key) => setOpenedPanel(key as string[])}
                expandIconPosition="end"
                expandIcon={(panelProps) => {
                  return <DownOutlined rotate={panelProps.isActive ? 180 : 0} />;
                }}>
                <Panel
                  className="rounded-xl bg-grey-10 p-4"
                  key={idx}
                  header={
                    <>
                      <Row justify="space-between" className="items-start">
                        <Col>
                          <div className="text-lg font-semibold">{day.label}</div>
                          {openedPanel.includes(idx.toString()) || (
                            <div className="text-base text-black-90">
                              {operationalHourText(
                                selectedInbox.workingHours?.find(
                                  (wh) => wh.dayOfWeek === idx + 1
                                ) || ({} as workingHour)
                              )}
                            </div>
                          )}
                        </Col>
                      </Row>
                    </>
                  }>
                  <OperationalHourSelectorForm
                    {...(selectedInbox.workingHours?.find((wh) => wh.dayOfWeek === idx + 1) ||
                      ({} as workingHour))}
                    selectedDays={[idx + 1]}
                    updateDayWorkingHour={updateDayWorkingHour}
                  />
                </Panel>
              </Collapse>
            ))
          )}
          {selectedInbox && settingAllDay ? (
            <div
              id="workhour-edit_all_schedule"
              className="team-action-button border border-black-90 px-0.5 py-3"
              onClick={() => setSettingAllDay(false)}>
              <LeftOutlined />
              &nbsp; Atur Jam Satuan
            </div>
          ) : (
            <div
              id="workhour-edit_all_schedule"
              className="team-action-button border border-black-90 px-0.5 py-3"
              onClick={() => setSettingAllDay(true)}>
              Atur Jam Sekaligus&nbsp;
              <RightOutlined />
            </div>
          )}
        </Col>
      </Row>
    </>
  );
}

export default WorkHourForm;
