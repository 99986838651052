import { Icon } from '@squantumengine/horizon';
import { CopyInterface } from './copy.interface';
import { message } from 'antd';

function Copy({ value, children }: CopyInterface) {
  const [messageApi, contextHolder] = message.useMessage();

  const copyToClipboard = (e: any) => {
    e.stopPropagation();
    navigator.clipboard.writeText(value);
    messageApi.open({
      type: 'success',
      content: 'Copied to your clipboard.'
    });
  };

  return (
    <div
      className="flex cursor-pointer flex-row items-center justify-center"
      onClick={copyToClipboard}>
      {contextHolder}
      <div>{children}</div>
      <div className={`flex items-center opacity-50`}>
        <Icon name="copy" className="opacity-50" />
      </div>
    </div>
  );
}

export default Copy;
