import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
const { version } = require('../package.json');

const setupDatadog = () => {
  const { REACT_APP_DATADOG_RUM_APP_ID, REACT_APP_DATADOG_CLIENT_TOKEN, REACT_APP_ENV } =
    process.env;

  const initConfig = {
    clientToken: REACT_APP_DATADOG_CLIENT_TOKEN ?? '',
    site: 'datadoghq.com',
    service: `sqecc-advisor-${REACT_APP_ENV}`,
    env: REACT_APP_ENV
  };

  datadogLogs.init({ ...initConfig, forwardErrorsToLogs: true, sessionSampleRate: 100 });

  if (REACT_APP_DATADOG_RUM_APP_ID) {
    datadogRum.init({
      ...initConfig,
      applicationId: REACT_APP_DATADOG_RUM_APP_ID,
      version,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 10,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input'
    });
    datadogRum.startSessionReplayRecording();
  }
};

export default setupDatadog;
