import { useMutation, useQuery, useQueryClient } from 'react-query';
import axiosInstance from '../../../config/axios';

export interface Connect3dPartyInterface {
  extServiceName: string;
  agentId: string;
  extUserName: string;
}

export const connect3dParty: (payload: Connect3dPartyInterface) => Promise<any[]> = async (
  payload
) => {
  const response = await axiosInstance.post(`/external/user/mapping`, payload);
  return response.data;
};

export const useConnect3dParty = () => {
  const queryClient = useQueryClient();

  return useMutation(connect3dParty, {
    onSuccess: () => {
      queryClient.invalidateQueries('get-3rd-party');
    }
  });
};

export const get3dParty: (agentId: string) => Promise<any> = async (agentId) => {
  const response = await axiosInstance.get(`/external/user/mapping/agent/${agentId}`);
  return response.data;
};

export const useGet3dPartyMapping = (
  agentId: string,
  isModalOpen: boolean,
  isProfileCompleted: boolean
) => {
  return useQuery({
    queryFn: () => get3dParty(agentId),
    queryKey: ['get-3rd-party', isModalOpen, agentId],
    enabled: isProfileCompleted && !!agentId,
    retry: 0
  });
};
