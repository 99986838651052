/* eslint-disable no-console */
import axios, { InternalAxiosRequestConfig } from 'axios';
import { isDatadogLogEnabled, sendLogError, sendLogInfo } from '../datadog';
import HttpStatusCode from '../../types/enums/request';
import { PageLocation } from '../../types/enums/page';
import axiosInstancev2 from './axiosInstanceV2';
import { LocalStorageKey } from '../../static/local-storage';
import { LoginPlatform, SSOTokenObject } from '../../shared/types/auth.interface';
import { local } from '../../utils/storage';
import axiosInstanceSQEID from './axiosInstanceSQEID';
import { getCookie } from '../../utils/cookie-management';
import { CookieNameEnum } from '../../static/cookie-management';
import { forceLogOut, isUrlExcluded } from '../../utils/login';
import { useAuthStore } from '../../store/new/auth';
import { ErrorCodeEnum } from '../../static/error';
import { maskString } from '../../utils/maskString';

const { CancelToken } = axios;
const source = CancelToken.source();

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/v1/`,
  headers: {
    'Content-Type': 'application/json',
    authenticationPlatform: `${LoginPlatform.NATIVE}`,
    Authorization: `jwt ${localStorage.getItem('accessToken')?.replaceAll('"', '')}`
  }
});

const interceptorSQEID = (config: InternalAxiosRequestConfig) => {
  const tokenObj: SSOTokenObject = JSON.parse(
    local.getItem(LocalStorageKey.SQEID_TOKEN_OBJECT) || '{}'
  );
  const { access_token, expires_in, refresh_token, refresh_expires_in } = tokenObj;
  const tokenCreatedDate: string | null = local.getItem(LocalStorageKey.TOKEN_CREATED_AT);
  const isTokenExistAndValid =
    Object.keys(tokenObj).length > 0 && tokenCreatedDate && expires_in && refresh_expires_in;

  if (access_token) {
    config.headers.Authorization = `jwt ${access_token}`;
  }

  if (isTokenExistAndValid) {
    const dateExpired = parseInt(tokenCreatedDate) + expires_in * 1000;
    const dateRefreshExpired = parseInt(tokenCreatedDate) + refresh_expires_in * 1000;

    if (Date.now() >= dateExpired) {
      // Check if refresh token is expired
      if (Date.now() >= dateRefreshExpired) {
        forceLogOut();
      } else {
        // Refresh token is not expired, call refresh token API
        axiosInstanceSQEID
          .post('/oidc/token', {
            grant_type: 'refresh_token',
            client_id: process.env.REACT_APP_SQEID_CLIENT_ID,
            client_secret: process.env.REACT_APP_SQEID_CLIENT_SECRET,
            refresh_token
          })
          .then((response) => {
            const today = new Date();
            localStorage.setItem(LocalStorageKey.TOKEN_CREATED_AT, today.getTime().toString());
            const newTokenObj: SSOTokenObject = response.data;
            local.setItem(LocalStorageKey.SQEID_TOKEN_OBJECT, JSON.stringify(newTokenObj));
            local.setItem(LocalStorageKey.ACCESS_TOKEN, JSON.stringify(newTokenObj.access_token));
            config.headers.Authorization = `jwt ${newTokenObj.access_token}`;

            const tokenData = {
              ...response?.data,
              access_token: maskString(response?.data?.access_token, 5, 5),
              id_token: maskString(response?.data?.id_token, 5, 5),
              refresh_token: maskString(response?.data?.access_token, 5, 5)
            };

            sendLogInfo(`[${response?.status}] Refresh Token`, {
              host: window.location.hostname,
              path: window.location.pathname,
              api_path: response?.config?.url || '',
              method: response?.config?.method || '',
              status_code: response?.status,
              request_body: tokenData
            });
          })
          .catch((err) => {
            forceLogOut();
          });
      }
    }
  }
  return config;
};

const interceptorNATIVE = (config: InternalAxiosRequestConfig) => {
  const nativeAccessToken = getCookie(CookieNameEnum.NATIVE_TOKEN);
  const excludeApi = [
    'agent/login',
    'user/password/reset',
    'agent/password',
    'authentication/provider',
    'user/validate/reset-link'
  ];

  if (!nativeAccessToken) {
    config.headers.Authorization = null;

    if (isUrlExcluded(config?.url || '', excludeApi)) return config;

    forceLogOut();

    source.cancel('Token Expired');
    config.cancelToken = source.token;

    return config;
  }

  config.headers.Authorization = `jwt ${nativeAccessToken}`;

  return config;
};

axiosInstance.interceptors.request.use(async (config) => {
  const authProvider = useAuthStore.getState().authProvider;
  const isSQEIDLoggedIn = useAuthStore.getState().isSQEIDLoggedIn();

  if (isSQEIDLoggedIn || authProvider === LoginPlatform.SIMAS_ID) {
    config.headers.authenticationPlatform = LoginPlatform.SIMAS_ID;
    return interceptorSQEID(config);
  }

  config.headers.authenticationPlatform = LoginPlatform.NATIVE;
  return interceptorNATIVE(config);
});

axiosInstance?.interceptors.response.use(
  (response: any) => {
    if (isDatadogLogEnabled) {
      sendLogInfo(`[${response?.status}] ${JSON.stringify(response?.data)}`, {
        host: window.location.hostname,
        path: window.location.pathname,
        api_path: response?.config?.url,
        method: response?.config?.method,
        status_code: response?.status
      });
    }

    return response;
  },
  (error: any) => {
    const code = error.response?.status;
    if (isDatadogLogEnabled) {
      sendLogError(`[${error.response.status}] ${JSON.stringify(error.response.data)}`, {
        host: window.location.hostname,
        path: window.location.pathname,
        api_path: error?.response?.config?.url,
        method: error?.response?.config?.method,
        status_code: error?.response?.status
      });
    }

    if (error?.code === ErrorCodeEnum.ERR_NETWORK) {
      return Promise.reject(error);
    }

    if (
      error.response?.data?.statusCode === HttpStatusCode.UNAUTHORIZED
    ) {
      forceLogOut();
    } else if ([HttpStatusCode.REQUEST_TIMEOUT].includes(code)) {
      window.location.href = `${PageLocation.ERROR}/${code}`;
    }

    return Promise.reject(error);
  }
);

if (isDatadogLogEnabled) {
  axiosInstance.interceptors.request.use((request) => {
    sendLogInfo(`[${request.method?.toUpperCase()}] ${request.url}`, {
      host: window.location.hostname,
      path: window.location.pathname,
      api_path: request?.url || '',
      method: request?.method || '',
      request_body: request?.data
    });
    return request;
  });
}

export const setAuthorizationHeader = (token: string) => {
  axiosInstance.defaults.headers.Authorization = `jwt ${token}`;
  axiosInstancev2.defaults.headers.Authorization = `jwt ${token}`;
};

export default axiosInstance;

export interface AxiosResponse<T> {
  data?: T;
  status: number;
}

export interface CreateInboxResponse {
  channelType: string;
  inboxId: string;
  message: string;
}
