import { getIconUrl } from '../../../utils/getIconUrl';
import { ErrorFormInterface } from './error-form.interface';

function ErrorForm(props: ErrorFormInterface) {
  const { message, isVisible, className } = props;

  if (!isVisible) return null;
  return (
    <>
      {isVisible && (
        <div className={`mt-1 flex flex-row items-start justify-start gap-1 ${className}`}>
          <img className="twcc-h-4 twcc-w-4" src={getIconUrl('error-form.svg')} alt="error-icon" />
          <span className={`p-0 text-red-400`}>{message}</span>
        </div>
      )}
    </>
  );
}

export default ErrorForm;
