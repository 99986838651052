import { CloseOutlined } from '@ant-design/icons';
import { Modal, Typography, message } from 'antd';
import { DetailBusyHourProps, getBgColor, hourDetail } from './busy-hour.interface';

function DetailBusyHour(props: DetailBusyHourProps) {
  const { Title, Text } = Typography;
  const [, contextHolder] = message.useMessage();

  const { open, setOpen, selectedData } = props;

  /**
   * Renders the hour with its corresponding total conversations.
   *
   * @param item - The detail hour object containing the hour and total conversations.
   * @returns The JSX element representing the hour with its total conversations.
   */
  const renderHour = (item: number, index: number): JSX.Element => {
    let bgColor = getBgColor(item, totalData);

    return (
      <div key={index} className="flex w-full flex-row items-center justify-between">
        <div className="flex flex-row items-center gap-2">
          <div className={`h-3 w-3 rounded-full ${bgColor}`} />
          <p className="m-0 p-0 text-chat-gray">{hourDetail[index]}</p>
        </div>

        <p className={`m-0 p-0`}>{item} percakapan</p>
      </div>
    );
  };

  const selectedDataKey = Object.keys(selectedData);
  const totalData = selectedData[`${selectedDataKey}`]?.total;
  const hourlyData = selectedData[`${selectedDataKey}`]?.hourly;

  return (
    <>
      {contextHolder}
      <Modal
        title={
          <Title level={4} className="m-0 p-0 font-bold">
            {selectedDataKey}
          </Title>
        }
        open={open}
        onOk={() => setOpen(true)}
        width={500}
        confirmLoading={false}
        footer={null}
        centered
        closeIcon={<CloseOutlined className="mt-1 text-black" style={{ fontSize: '1.5rem' }} />}
        onCancel={() => setOpen(false)}>
        <Text className="text-base font-bold">Total Percakapan: {totalData}</Text>
        <div className="mt-2 grid grid-cols-2 gap-4">
          {hourlyData?.map((item: number, index: number) => item !== 0 && renderHour(item, index))}
        </div>
      </Modal>
    </>
  );
}

export default DetailBusyHour;
