import { Flex, Modal } from 'antd';
import { MessageEventEnum } from '../../../../static/message-event';
import { useChatListStore } from '../../../../store/chat-list';
import { CloseSessionProps } from './close-session.interface';
import { CloseOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { confirmModalEndSession } from '../../../../static/communication-type';
import { useWorkspaceSummary } from '../../../../api/hooks/workspace/useWorkspaceSummary';
import { Button, Paragraph, Title } from '@squantumengine/horizon';
import SummaryModal from './summary-modal';
import useCCToaster from '../../../../hooks/useCCToaster';

const CloseSessionButton = ({ onClickAddNotes, onClickEndTicket }: CloseSessionProps) => {
  const { selectedCall, setIsReadyToClose } = useChatListStore();
  const { data } = useWorkspaceSummary(selectedCall?.conversationId as string);
  const [openModalConfirm, setOpenModalConfirm] = useState<boolean>(false);
  const [openModalSummaryConfirm, setOpenModalSummaryConfirm] = useState<boolean>(false);
  const isActive = selectedCall?.event === MessageEventEnum.ACTIVE;
  const isPreClosure = selectedCall?.event === MessageEventEnum.SESSION_CLOSED;
  const conversationId = selectedCall?.conversationId;
  const { data: summaryData, refetch } = useWorkspaceSummary(conversationId as string);
  const { contextHolder, openNotification } = useCCToaster({ timeout: 3 });

  const handleShowModalConfirm = () => {
    if (isActive) {
      refetch();
      onClickAddNotes();
      setOpenModalConfirm(true);
    }
  };

  const handleCloseModalConfirm = () => setOpenModalConfirm(false);

  const handleConfirmEndCall = () => {
    if (summaryData?.topic !== '-') {
      onClickEndTicket();
      openNotification({
        label: `Sesi dengan ${selectedCall?.name} sudah selesai.`,
        variant: 'primary'
      });
    } else {
      setOpenModalSummaryConfirm(true);
    }

    handleCloseModalConfirm();
  };

  useEffect(() => {
    setIsReadyToClose(Boolean(data?.topic && data?.topic !== '-') && isPreClosure);
    // eslint-disable-next-line
  }, [selectedCall]);

  return (
    <div className="group relative flex h-full min-w-fit cursor-pointer items-center">
      {contextHolder}
      <div className="flex items-center justify-between gap-2 rounded-3xl text-base font-semibold duration-200 ease-out hover:border-bold-green hover:text-bold-green">
        <Paragraph
          onClick={handleShowModalConfirm}
          weight="bold"
          className={`${!isActive ? 'text-neutral-400' : 'text-red-400'}`}>
          Akhiri Sesi
        </Paragraph>
      </div>
      <SummaryModal
        openModalConfirm={openModalSummaryConfirm}
        setOpenModalConfirm={setOpenModalSummaryConfirm}
        onClickEndTicket={onClickEndTicket}
      />
      <Modal
        title={
          <Flex align="center" justify="space-between">
            <Title level={4}>{confirmModalEndSession.title}</Title>
            <CloseOutlined onClick={handleCloseModalConfirm} />
          </Flex>
        }
        open={openModalConfirm}
        closable={false}
        footer={false}
        width={500}>
        <div className="space-y-2">
          <div className="text-md m-0 py-2">{confirmModalEndSession.description} </div>
          <Flex gap={10} justify="end">
            <Button variant="secondary" onClick={handleCloseModalConfirm}>
              {confirmModalEndSession.cancelText}
            </Button>
            <Button onClick={handleConfirmEndCall}>{confirmModalEndSession.okText}</Button>
          </Flex>
        </div>
      </Modal>
    </div>
  );
};

export default CloseSessionButton;
