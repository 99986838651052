import dayjs from 'dayjs';
import { local } from './storage';
import { LocalStorageKey } from '../static/local-storage';
import { withErrorHandling } from './error-handling';
import { Error as ErrorRes } from '../shared/types/error-handling.interface';
import { deleteCookie } from './cookie-management';
import { CookieNameEnum } from '../static/cookie-management';
import { useAuthStore } from '../store/new/auth';
import { clearAuthLocalStorage } from './local-storage';

/**
 * Get expired date from given last reset date.
 * Password expired 90 days after last reset.
 *
 * @param dateUTC - date in UTC format.
 * @return expired date in UTC format.
 */
export const getExpiredDate = (dateUTC?: string): string | ErrorRes => {
  return withErrorHandling(() => {
    if (typeof dateUTC !== 'string') throw new Error('invalid date');

    const inputTime = dayjs(new Date(dateUTC));

    if (!inputTime.isValid()) throw new Error('invalid date');

    return inputTime.add(90, 'day').toISOString();
  });
};

/**
 * Calculates number of days before password expired.
 * Password expired 90 days after last reset.
 *
 * @param dateUTC - date in UTC format.
 * @return number of day before expired.
 */
export const getDaysBeforeExpired = (dateUTC: string): number | ErrorRes => {
  return withErrorHandling(() => {
    const expiredDateStr = getExpiredDate(dateUTC);
    if (typeof expiredDateStr !== 'string') return expiredDateStr;

    const expiredDate = dayjs(expiredDateStr);
    const now = dayjs().subtract(1, 'minute');

    return expiredDate.diff(now, 'day');
  });
};

/**
 * Return true if password expired.
 * Password expired 90 days after last reset.
 *
 * @param dateUTC - date in UTC format.
 * @return boolean.
 */
export const isPasswordExpired = (dateUTC: string): boolean | ErrorRes => {
  return withErrorHandling(() => {
    const expiredDateStr = getExpiredDate(dateUTC);
    const daysBeforeExpired = getDaysBeforeExpired(dateUTC);

    if (typeof expiredDateStr !== 'string') return expiredDateStr;

    const expiredDate = dayjs(expiredDateStr);

    const now = dayjs();

    return now.isAfter(expiredDate) || (daysBeforeExpired as number) <= 0;
  });
};

/**
 * Return true if password is between 7 days before expired date.
 * Password expired 90 days after last reset.
 *
 * @param dateUTC - Date in UTC format.
 * @return boolean.
 */
export const shouldUpdatePassword = (dateUTC: string): boolean | ErrorRes => {
  return withErrorHandling(() => {
    const expiredDateStr = getExpiredDate(dateUTC);
    if (typeof expiredDateStr !== 'string') return expiredDateStr;

    const expiredDate = dayjs(expiredDateStr);
    const now = dayjs();

    if (now.isAfter(expiredDate)) return true;

    if (now.isBefore(expiredDate)) {
      const daysDiff = getDaysBeforeExpired(dateUTC);
      if (typeof daysDiff === 'number' && daysDiff <= 7) return true;
    }
    return false;
  });
};

/**
 * Return true if url in excluded array
 *
 * @param url - string
 * @param exclude - string[]
 * @return boolean.
 */
export const isUrlExcluded = (url: string, excludes: string[]) => {
  for (const exclude of excludes) {
    if (url.includes(exclude)) return true;
  }
  return false;
};

export const redirectToLogin = () => {
  const clientCode = local.getItem(LocalStorageKey.SQECC_CLIENT_CODE);
  window.location.href = `/login?client=${clientCode}`;
};

export const forceLogOut = () => {
  deleteCookie(CookieNameEnum.NATIVE_TOKEN);
  useAuthStore.getState().reset();
  redirectToLogin();
  clearAuthLocalStorage();
};
