import { Collapse, CollapseProps } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import './index.css';

type CollapsibleProps = {
  itemKey?: string;
  label?: string | JSX.Element;
  trailing?: string | JSX.Element;
  children: JSX.Element;
} & CollapseProps;
const Collapsible = ({
  itemKey = 'default',
  label,
  trailing,
  children,
  collapsible = 'icon',
  ...rest
}: CollapsibleProps) => {
  return (
    <Collapse
      defaultActiveKey={[itemKey]}
      collapsible={collapsible}
      expandIconPosition="right"
      expandIcon={({ isActive }: any) => (
        <div className="flex items-center space-x-2 font-semibold">
          {trailing && <span className="text-lg">{trailing}</span>}
          <span>{isActive ? <UpOutlined /> : <DownOutlined />}</span>
        </div>
      )}
      bordered={false}
      className="cc-collapsible"
      {...rest}>
      <Collapse.Panel header={label} key={itemKey}>
        {children}
      </Collapse.Panel>
    </Collapse>
  );
};

export default Collapsible;
