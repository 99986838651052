import { FormInstance } from 'antd';
import { UserResponse } from '../card/user/user.interface';

export interface TeamUserModalInterface {
  data: UserResponse;
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  form: FormInstance<any>;
}

export interface TeamBUModalInterface {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  inviteUser: (organizationId: string) => Promise<void>;
  setSelectedBU?: React.Dispatch<
    React.SetStateAction<
      | {
          key: string;
          label: string;
        }
      | undefined
    >
  >;
}

export interface InviteUserParams {
  tokenType: string;
  organizationId?: string;
}

export const teamOptions = [
  { value: 'smmf', label: 'SMMF' },
  { value: 'bsim', label: 'BSIM' },
  { value: 'asj', label: 'ASJ' }
];

export const thirdPartyEnum = {
  CENTERIX: 'centrix'
};

export const thirdPartyOptions = [{ value: 'CENTRIX', label: 'CENTRIX' }];

export const roleOptions = [
  { value: 'AGENT', label: 'Agen' },
  { value: 'SUPERVISOR', label: 'Supervisor' }
];

export enum ItemInvUsrKey {
  EMAIL = 0,
  NAME = 1
}

export const itemsInvUsr = [
  { label: 'Email Pelanggan ', key: ItemInvUsrKey.EMAIL },
  { label: 'Nama', key: ItemInvUsrKey.NAME }
];

export interface searchFilterInterface {
  setData: React.Dispatch<React.SetStateAction<UserResponse[] | undefined>>;
  data: UserResponse[] | undefined;
  items: {
    label: string;
    key: ItemInvUsrKey;
  }[];
}
