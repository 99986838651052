import { getIconUrl } from '../../../utils/getIconUrl';
import { ChatFilterProps, FilterListProps } from './chat-filter.interface';

const ChatFilter = ({ filterOption, active, setActive }: ChatFilterProps) => {
  return (
    <div className="group relative flex h-full min-w-fit cursor-pointer items-center">
      <div className="flex items-center justify-between gap-2 rounded-3xl border-[1px] border-solid border-black px-4 text-base font-semibold duration-200 ease-out hover:border-bold-green hover:text-bold-green">
        <span className="text-xs">{active}</span>
        <span>
          <img
            src={getIconUrl('chevron-down.svg')}
            alt="chevron-down"
            className="w-2 -translate-y-[3px]"
          />
        </span>
      </div>
      <ul className="absolute right-0 top-[100%] z-50 m-0 hidden list-none flex-col rounded-2xl rounded-tr-none bg-white p-0 px-5 shadow-xl group-hover:flex">
        {filterOption.map((el, i) => (
          <FilterList
            key={i}
            label={el}
            last={i === filterOption.length - 1}
            active={active === el}
            setActive={() => setActive(el)}
          />
        ))}
      </ul>
    </div>
  );
};

const FilterList = ({ label, last, active, setActive }: FilterListProps) => {
  return (
    <>
      <li
        className={`flex w-32 cursor-pointer items-center justify-between bg-white py-4 duration-200 ease-out hover:text-bold-green ${
          !last && 'border-0 border-b-[1px] border-solid border-b-black/40'
        }`}
        onClick={() => setActive(label)}>
        {label}
        {active && (
          <span>
            <img src={getIconUrl('check.svg')} alt="check" className="w-3 -translate-y-[1px]" />
          </span>
        )}
      </li>
    </>
  );
};

export default ChatFilter;
