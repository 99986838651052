import React from 'react';
import { StatusKYCEnum } from '../../kyc/kyc.interface';
import { ConversationStatusEnum } from '../../../static/message-event';
import { ConversationContextEnum } from '../../../static/communication-type';
import { Paragraph } from '@squantumengine/horizon';

interface Props {
  kycStatus: StatusKYCEnum;
  conversationStatus: ConversationStatusEnum;
  conversationContext: ConversationContextEnum;
}

const KYCStatus: React.FC<Props> = ({ kycStatus, conversationStatus, conversationContext }) => {
  const getStatusText = () => {
    if (
      conversationStatus === ConversationStatusEnum.RESOLVED &&
      conversationContext === ConversationContextEnum.KYC
    ) {
      switch (kycStatus) {
        case StatusKYCEnum.COMPLETED:
          return 'Disetujui';
        case StatusKYCEnum.FAILED:
          return 'Ditolak';
        default:
          return 'Ditunda';
      }
    }

    return '';
  };

  const getStatusClass = () => {
    if (kycStatus) {
      switch (kycStatus) {
        case StatusKYCEnum.COMPLETED:
          return 'text-blue-500';
        case StatusKYCEnum.FAILED:
          return 'text-red-500';
        default:
          return '';
      }
    }
    return '';
  };

  return (
    <div className="mb-1 flex flex-row items-center justify-between">
      <Paragraph className="text-neutral-600">Status KYC</Paragraph>
      <Paragraph className={`truncate ${getStatusClass()}`}>{getStatusText()}</Paragraph>
    </div>
  );
};

export default KYCStatus;
