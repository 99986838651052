import { Icon, Paragraph } from '@squantumengine/horizon';
import { useChatListStore } from '../../../../../store/chat-list';
import TimestampToString from '../../../../../utils/timestamp';
import Copy from '../../../copy';
import Divider from '../divider';
import { useInboxStore } from '../../../../../store/new/inbox';

function OfflineMessageChatBox() {
  const { selectedOfflineChat } = useChatListStore();
  const { selectedInboxId } = useInboxStore();

  if (!selectedOfflineChat) return null;

  const { createdAt, customer, message, messageId } = selectedOfflineChat;

  return (
    <>
      <div className="flex h-full min-h-[550px] flex-col overflow-hidden bg-white">
        <div className="flex w-full flex-row items-center justify-start border-0 border-b border-solid border-b-grey-50 px-6 py-4">
          <div className="flex w-full flex-col items-start justify-start">
            <div className="flex w-full items-center justify-between gap-4 text-lg font-semibold">
              <div className="flex items-center justify-center gap-6 overflow-hidden">
                <div className="h-2 w-2 min-w-[8px] rounded-full bg-neutral-500" />
                <div className="w-full truncate">{customer.name || 'Pelanggan'}</div>
              </div>
              <Paragraph weight="bold" className="shrink-0 text-neutral-400">
                Sesi Selesai
              </Paragraph>
            </div>
            <div className="flex items-center gap-2">
              <Copy value={messageId} />
              <Paragraph className="w-full truncate text-[#677485]">
                ID #{messageId.slice(-5)}
              </Paragraph>
            </div>
          </div>
        </div>
        <div
          id="chat-container"
          className="relative flex h-full min-h-[300px] w-full flex-col overflow-y-auto pb-4 pt-4">
          <Divider
            time={createdAt}
            conversationId={messageId}
            contactId=""
            inboxId={selectedInboxId}
            isOfflineOrResolverChat
            className="mb-2"
          />
          <div
            className={`mb-2 ml-6 mr-[72px] flex h-auto flex-col rounded-b-2xl rounded-r-2xl bg-[#F9FAFC] p-4 text-left transition-all duration-200`}>
            <div className="mb-2 flex flex-row items-start justify-between whitespace-pre-line break-normal break-words text-base	font-normal">
              {message}
              <button className="cursor-pointer border-none bg-transparent">
                <Icon name="kebab-vertical" size="sm" />
              </button>
            </div>
            <div className="flex items-center justify-between text-sm font-normal text-chat-gray">
              <Paragraph>Pesan Offline</Paragraph>
              {TimestampToString(new Date(createdAt).getTime())}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OfflineMessageChatBox;
