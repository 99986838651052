const TimestampToString = (timestamp: number): string => {
  let date = new Date(timestamp);
  let hour = date.getHours();
  let minute = date.getMinutes();
  let hourString = hour.toString().padStart(2, '0');
  let minuteString = minute.toString().padStart(2, '0');
  return `${hourString}:${minuteString}`;
};

export const TimestampToStringDateAndTime = (date: Date): string => {
  const dateString = date.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit'
  });
  const timeString = date.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true
  });
  return `${dateString} ${timeString}`; // return sample : 05/5/22 5:15 PM
};

export const DurationToString = (duration: number): string => {
  let hour = Math.floor(duration / 3600);
  let minute = Math.floor(duration / 60) % 60;
  let second = duration % 60;

  let hourString = hour.toString().padStart(2, '0');
  let minuteString = minute.toString().padStart(2, '0');
  let secondString = second.toString().padStart(2, '0');
  return `${hour > 0 ? `${hourString}:` : ''}${minuteString}:${secondString}`;
};

export const NumberToTimeString = (number: number): string => {
  if (!number) {
    return '00';
  }
  return number.toString().padStart(2, '0');
};

export const StringDateToNumber = (date?: string) => {
  if (!date) return undefined;
  return new Date(date).getTime();
};

export const calculateFormattedDuration = (startTime: string, endTime: string) => {
  const startTimestamp = new Date(startTime).getTime();
  const endTimestamp = new Date(endTime).getTime();

  const duration = endTimestamp - startTimestamp;

  const durationInSeconds = Math.floor(duration / 1000);

  const hours = Math.floor(durationInSeconds / 3600);
  const minutes = Math.floor((durationInSeconds % 3600) / 60);
  const seconds = durationInSeconds % 60;
  const formattedDuration = `${hours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

  return formattedDuration;
};

export default TimestampToString;
