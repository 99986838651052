import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const CommentSlashSVG = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13 6.49993C16.5625 6.49993 19.5 8.74993 19.5 11.4999C19.5 12.3124 19.25 13.0624 18.8125 13.7187L20 14.6249C20.625 13.7187 21 12.6562 21 11.4999C21 7.93743 17.4062 4.99993 13 4.99993C11.4688 4.99993 10.0938 5.34368 8.875 5.93743L10.2188 6.99993C11.0625 6.68743 12 6.49993 13 6.49993ZM13 16.4999C12.1562 16.4999 11.3125 16.3749 10.5312 16.1249L9.8125 15.9062L9.21875 16.3437C8.78125 16.6562 8.15625 16.9999 7.40625 17.2499C7.65625 16.8749 7.875 16.4374 8.03125 15.9999L8.375 15.1249L7.71875 14.4374C7.15625 13.8437 6.5 12.8437 6.5 11.4999C6.5 10.9999 6.59375 10.4999 6.78125 10.0312L5.5625 9.06243C5.1875 9.84368 5 10.6562 5 11.4999C5 12.9999 5.59375 14.3749 6.625 15.4687C6.1875 16.6874 5.21875 17.7499 5.1875 17.7499C4.96875 17.9687 4.9375 18.2812 5.03125 18.5624C5.15625 18.8437 5.4375 18.9999 5.75 18.9999C7.65625 18.9999 9.1875 18.2187 10.0938 17.5624C11 17.8437 11.9688 17.9999 13 17.9999C14.1562 17.9999 15.2812 17.8124 16.2812 17.4374L14.8125 16.3124C14.25 16.4374 13.625 16.4999 13 16.4999ZM22.8125 18.7187L18.0312 14.9999L17.8125 14.8437L4.125 4.12493C3.90625 3.93743 3.59375 3.99993 3.40625 4.18743L3.09375 4.59368C2.9375 4.81243 2.96875 5.12493 3.1875 5.28118L21.875 19.9062C22.0625 20.0624 22.4062 20.0312 22.5625 19.8124L22.875 19.4374C23.0625 19.2187 23 18.9062 22.8125 18.7187Z"
      fill="white"
    />
  </svg>
);

const CommentSlashIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={CommentSlashSVG} {...props} />
);

export default CommentSlashIcon;
