import { Alert, Button, ConfigProvider, Input, Spin } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { HistoryCardProps } from '../../form/workspace-customer-files/history/workspace-customer-files-history.interface';
import { useChatListStore } from '../../../store/chat-list';
import { fetchHistoryChat } from '../../../api/conversation';
import { pageLimit } from '../../../utils/pageLimit';
import { getIconUrl } from '../../../utils/getIconUrl';
import HistoryItem from './history-item';
import { ConversationStatusEnum } from '../../../static/message-event';
import { useInfiniteQuery } from 'react-query';

function HistoryList() {
  const [search, setSearch] = useState('');
  const { selectedCall } = useChatListStore();
  const [currentContactId, setCurrentContactId] = useState('');
  const { data, isLoading, hasNextPage, isFetchingNextPage, isFetching, isError, fetchNextPage } =
    useInfiniteQuery(
      ['useHistory', currentContactId],
      async ({ pageParam = 1, queryKey: [_, contactId] }) => {
        const res = await fetchHistoryChat(contactId as string, {
          'page-no': pageParam,
          active: false
        });
        const historyItems = (res.data.data || []) as HistoryCardProps[];
        return historyItems;
      },
      {
        retry: false,
        enabled: !!currentContactId,
        getNextPageParam: (lastPage, allPages) => {
          const nextPage = lastPage?.length === pageLimit ? allPages?.length + 1 : undefined;
          return nextPage;
        }
      }
    );

  const handleSearch = (e: any) => setSearch(e.target.value);

  const historyList = useMemo(() => {
    return data?.pages?.reduce(
      (acc, curr) => [
        ...acc,
        ...curr.filter(
          ({ conversationStatus }) => conversationStatus === ConversationStatusEnum.RESOLVED
        )
      ],
      []
    );
  }, [data]);

  const historyItems = useMemo(() => {
    if (!search) return historyList;
    return historyList?.filter(
      (item: HistoryCardProps) =>
        item.conversationId.includes(search) ||
        `${item?.agent?.firstName} ${item?.agent?.lastName}`
          .toLowerCase()
          .includes(search?.toLowerCase())
    );
  }, [historyList, search]);

  const isEmpty = !isLoading && historyList?.length === 0;
  const isFirstLoading = isFetching && !isFetchingNextPage;

  useEffect(() => {
    setCurrentContactId(selectedCall?.contactId || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCall?.contactId]);

  return (
    <div className="space-y-2">
      <div>
        <Input
          className="rounded-2xl border-grey-50 bg-transparent p-2 px-4 text-chat-gray [&>input]:bg-transparent"
          prefix={<SearchOutlined />}
          placeholder="Cari nama agen / tiket ID"
          onChange={handleSearch}
        />
      </div>
      {!isFirstLoading &&
        historyItems?.map((item: HistoryCardProps) => (
          <HistoryItem key={item.conversationId} {...item} />
        ))}
      {isEmpty && (
        <div
          className="mt-4 flex items-center justify-center rounded-2xl
      border border-dashed border-chat-gray p-4 text-base font-semibold text-chat-gray">
          <img src={getIconUrl('file-search-gray.svg')} alt="empty-history" className="mr-1" />
          Belum ada riwayat
        </div>
      )}
      {(isFirstLoading || (isLoading && hasNextPage)) && (
        <div className="flex items-center justify-center pt-4">
          <Spin />
        </div>
      )}
      {isError && <Alert className="text-center" type="error" message="Gagal memuat data" />}
      {hasNextPage && !isEmpty && (
        <ConfigProvider
          theme={{
            components: {
              Button: {
                colorPrimaryHover: '#2DB089',
                colorPrimaryActive: '#2DB089'
              }
            }
          }}>
          <Button
            size="small"
            className="flex w-full items-center justify-center rounded-full py-4"
            onClick={() => fetchNextPage()}>
            Muat Lebih Banyak
          </Button>
        </ConfigProvider>
      )}
    </div>
  );
}

export default HistoryList;
