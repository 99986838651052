import { Avatar } from 'antd';
import ChatIcon from '../../../assets/icons/chat';
import { useUserStatusStore } from '../../../store/user-state';
import { RoleEnum } from '../../../static/role';
import { getUserJSON } from '../../../utils/user-json';
import Copy from '../../copy';
import { OfflineCardInterface } from './offline-card.interface';
import dayjs from 'dayjs';
import 'dayjs/locale/id';
import { useChatListStore } from '../../../store/chat-list';
import { useSelectedCustomerStore } from '../../../store/customer-state';

function OfflineCard(props: OfflineCardInterface) {
  const { data } = props;
  const { createdAt, messageId, customer } = data;
  const { account } = getUserJSON();
  const { accountName } = account || {};
  const { userRole } = useUserStatusStore();
  const { selectedOfflineChat, setSelectedOfflineChat } = useChatListStore();
  const { setCustomerDetail, setIsPreClosedAvailable } = useSelectedCustomerStore();

  const isSelected = selectedOfflineChat?.messageId === messageId;
  const dateString = dayjs(createdAt).locale('id').format('DD MMM YYYY');

  const handleClick = () => {
    setSelectedOfflineChat(data);
    setCustomerDetail({
      contactId: '',
      name: data.customer.name || '',
      email: data.customer.email || '',
      phone: data.customer.phone || '',
      gender: '',
      dateOfBirth: '',
      motherMaidenName: '',
      note: ''
    });
    setIsPreClosedAvailable(false);
  };

  return (
    <>
      <div
        className={`relative cursor-pointer rounded-lg bg-white
          p-4 shadow-lg
          ${userRole === RoleEnum.SUPERVISOR && 'border-2 border-solid border-grey-50 shadow-none'}
          ${isSelected && 'ring-2 ring-green-600'}`}
        onClick={handleClick}>
        <div className="absolute -right-2 -top-2 flex h-8 w-8 items-center justify-center rounded-full border-2 border-solid border-green-600 bg-green-emerald-light font-bold text-white">
          1
        </div>
        <div className="flex flex-row items-center">
          <Avatar
            size={48}
            className={`flex h-12 w-12 flex-row justify-center bg-green-emerald-light`}
            icon={<ChatIcon />}
          />
          <span className="flex-1 truncate pl-4 text-lg font-semibold">{customer.name}</span>
        </div>
        <hr className="my-4 h-px border-none bg-grey-20" />
        <div className="truncate text-base font-normal">
          <b>{accountName}</b>
        </div>
        <div className="inline-block text-base font-normal">
          <Copy value={messageId}>Salin Tiket ID</Copy>
        </div>
        <div className="text-grey-100">{dateString}</div>
      </div>
    </>
  );
}

export default OfflineCard;
