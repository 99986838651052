import { AccountInterface } from './account.interface';
import { InboxListItemInterface, InboxMemberListInterface } from './inbox.interface';

export interface TeamAccountListInterface {
  accountList: AccountInterface[];
}

export interface TeamInboxListInterface {
  accountId: string;
  inboxList: InboxListItemInterface[];
}

export interface TeamUserListInterface {
  accountId: string;
  inboxId: string;
  userList: InboxMemberListInterface[];
}

export interface TeamActiveHandlerInterface {
  activeAccountId: string;
  activeInboxId: string;
  activeUserId: string;
  isCreate: boolean;
  isEdit: boolean;

  isCreateInbox: boolean;
  isCreateUser: boolean;
  isEditSchedule: boolean;
  isAddAgent: boolean;
}

export enum TeamActiveHandlerKeysEnum {
  ACTIVE_ACCOUNT_ID = 'activeAccountId',
  ACTIVE_INBOX_ID = 'activeInboxId',
  ACTIVE_USER_ID = 'activeUserId',
  IS_CREATE = 'isCreate',
  IS_EDIT = 'isEdit',
  IS_CREATE_INBOX = 'isCreateInbox',
  IS_CREATE_USER = 'isCreateUser',
  IS_EDIT_SCHEDULE = 'isEditSchedule',
  IS_ADD_AGENT = 'isAddAgent'
}
