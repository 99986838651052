import { Button } from '@squantumengine/horizon';
import { Form, Checkbox, Input, Tooltip } from 'antd';
import { ExclamationCircleFilled, QuestionCircleOutlined } from '@ant-design/icons';
import { useEffect, useRef, useState } from 'react';
import { getIconUrl, getImageUrl } from '../../../../../utils/getIconUrl';
import useTeamCreateEditStore, { FormTypeEnum } from '../../store';
import { getVirtualBackground } from '../../../../../services/account';
import { useAuthStore } from '../../../../../store/new/auth';
import { ImgTypeEnum } from '../../../../../static/dashboard';
import { ConversationContext } from '../../../../../types/new/inbox';
import useCCToaster from '../../../../../hooks/useCCToaster';
const { TextArea } = Input;

type Props = {
  getFormRef: (form: any) => void;
};

export default function FormWebWidget({ getFormRef }: Props) {
  const [hasGreetingMessage, setHasGreetingMessage] = useState(false);
  const [vbg, setVbg] = useState(getImageUrl('vbg-default.png'));
  const { formTeamConfiguration, formDisplay, setFormDisplay, type } = useTeamCreateEditStore();
  const isManualKYCInbox =
    formTeamConfiguration?.conversationContext === ConversationContext.MANUAL_KYC;
  const [form] = Form.useForm();
  const { contextHolder, openNotification } = useCCToaster({ position: 'top', timeout: 3 });

  const renderRequiredMessage = (label: string) => (
    <div className="flex items-center space-x-1 text-red-500">
      <ExclamationCircleFilled /> <span>{label}</span>
    </div>
  );

  const formItemsWebWidget = [
    {
      name: 'greetingMessage',
      type: 'greeting-message',
      label: 'Kata Sambutan',
      placeholder: 'Masukkan kata sambutan',
      tooltip: (
        <div className="px-4">
          <p>
            Kata sambutan ini akan muncul pada saat pelanggan masuk ke halaman percakapan pertama
            kali.
          </p>
          <img src={getIconUrl('tooltip-greeting-2.svg')} alt="info" />
        </div>
      ),
      checked: false,
      rules: [
        {
          required: hasGreetingMessage,
          message: renderRequiredMessage('Kata sambutan wajib diisi')
        }
      ]
    },
    {
      name: 'waitingRoomMessage',
      type: 'textarea',
      label: 'Ruang Tunggu',
      value: 'Mikrofon dan kamera Anda akan aktif untuk berbicara dengan staf kami',
      tooltip: (
        <div className="px-4">
          <p>
            Pesan menunggu antrean ini akan muncul pada saat pelanggan masuk ke ruang tunggu video
            call.
          </p>
          <img src={getIconUrl('tooltip-longqueue.svg')} alt="info" />
        </div>
      ),
      rules: [{ required: true, message: renderRequiredMessage('Kata ruang tunggu wajib diisi') }]
    },
    {
      name: 'longQueueMessage',
      type: 'textarea',
      label: 'Antrean Panjang',
      value: 'Mohon maaf, Advisor kami masih sibuk. Apakah Anda bersedia menunggu?',
      tooltip: (
        <div className="px-4">
          <p>Pesan ini akan muncul pada saat percakapan belum dijawab oleh agen.</p>
          <img src={getIconUrl('tooltip-busy-hour.svg')} alt="info" />
        </div>
      ),
      rules: [
        { required: true, message: renderRequiredMessage('Kata antrean panjang wajib diisi') }
      ]
    },
    {
      name: 'oooMessage',
      type: 'textarea',
      label: 'Di Luar Jam Kerja',
      value: 'Mohon maaf, kami belum dapat melayani Anda di luar jam operasional hari ini.',
      tooltip: (
        <div className="px-4">
          <p>Pesan ini akan muncul pada saat pelanggan masuk diluar jam operasional.</p>
          <img src={getIconUrl('tooltip-outside-3.svg')} alt="info" />
        </div>
      ),
      rules: [
        { required: true, message: renderRequiredMessage('Kata diluar jam kerja wajib diisi') }
      ]
    }
  ].filter((item) => {
    if (item.type === 'greeting-message') return !isManualKYCInbox;
    return true;
  });

  const _renderItemWebWidget = (item: any, index: number) => {
    const labelWithTooltip = (
      <span className="text-base font-semibold">
        {item.label}
        {item.tooltip && (
          <Tooltip title={item.tooltip} className="ml-1 fill-black">
            <QuestionCircleOutlined />
          </Tooltip>
        )}
      </span>
    );
    if (item.type === 'greeting-message') {
      return (
        <>
          <Form.Item name="greetingEnabled" className="mb-1" valuePropName="checked">
            <div className="flex items-center space-x-2">
              <Checkbox
                className="m-0"
                onChange={(e) => {
                  const checked = e.target.checked;
                  setHasGreetingMessage(checked);
                  if (checked) {
                    form.setFieldValue(
                      'greetingMessage',
                      formDisplay.greetingMessage ||
                        'Halo, terimakasih telah terhubung dengan kami. Senang bertemu dengan Anda. Mohon tunggu sebentar ya.'
                    );
                    setFormDisplay({
                      ...formDisplay,
                      greetingEnabled: checked,
                      greetingMessage:
                        formDisplay.greetingMessage ||
                        'Halo, terimakasih telah terhubung dengan kami. Senang bertemu dengan Anda. Mohon tunggu sebentar ya.'
                    });
                  } else {
                    form.setFieldValue('greetingMessage', formDisplay.greetingMessage || '');
                    form.validateFields(['greetingMessage']);
                    setFormDisplay({
                      ...formDisplay,
                      greetingEnabled: checked
                    });
                  }
                }}
                checked={hasGreetingMessage}
              />
              {labelWithTooltip}
            </div>
          </Form.Item>

          <Form.Item name={item.name} key={index} rules={item?.rules}>
            <TextArea
              placeholder={item.placeholder}
              maxLength={255}
              showCount={true}
              disabled={!hasGreetingMessage}
            />
          </Form.Item>
        </>
      );
    }
    return (
      <Form.Item name={item.name} key={index} label={labelWithTooltip} rules={item?.rules}>
        <TextArea defaultValue={item.value} maxLength={255} showCount={true} />
      </Form.Item>
    );
  };

  const handleImageUpload = (event: any) => {
    const file = event.target.files[0];
    if (![ImgTypeEnum.JPG, ImgTypeEnum.JPEG].includes(file.type)) {
      openNotification({
        label: 'Hanya format gambar jpg/jpeg yang diizinkan.',
        variant: 'secondary',
        buttonLabel: 'Tutup'
      });
      return;
    }
    const reader = new FileReader();
    reader.onloadend = () => {
      setVbg(reader.result as string);
      form.setFieldValue('vbg', file);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const isRendered = useRef(false);
  useEffect(() => {
    if (isRendered.current) return;
    isRendered.current = true;
    form.setFieldsValue(formDisplay);
    getFormRef(form);
    setHasGreetingMessage(formDisplay.greetingEnabled);
    if (type === FormTypeEnum.EDIT) {
      getVirtualBackground(useAuthStore.getState().account.accountId).then((res) => {
        const vbg = res?.documents?.[res.documents.length - 1]?.url;
        if (vbg) setVbg(vbg);
      });
    }
  }, [form, formDisplay, getFormRef, setFormDisplay, type]);

  return (
    <Form
      form={form}
      layout="vertical"
      requiredMark={false}
      onValuesChange={(data) => {
        if (Object.keys(data)[0] === 'greetingEnabled') return;
        setFormDisplay({ ...formDisplay, ...data });
      }}>
      {contextHolder}
      <div className="space-y-4 px-4 pb-4">
        <div className="space-y-4 rounded-xl bg-white p-6 shadow-md">
          <h3 className="m-0 text-lg font-semibold">Virtual Background</h3>
          <div>
            <div className="w-1/2 rounded-lg border border-solid border-[#BAC3D1]">
              <div className="flex h-[150px] justify-center border border-b border-l-0 border-r-0 border-t-0 border-solid border-[#BAC3D1]">
                <img src={vbg} className="h-auto w-full object-contain" alt="Placeholder" />
              </div>
              <div className="py-2">
                <Form.Item name="vbg" className="m-0">
                  <input
                    type="file"
                    accept="image/jpg, image/jpeg"
                    style={{ display: 'none' }}
                    id="backgroundImageUpload"
                    onChange={handleImageUpload}
                  />
                  <Button
                    variant="text"
                    className="w-full"
                    onClick={() => document.getElementById('backgroundImageUpload')?.click()}>
                    Ganti Background
                  </Button>
                </Form.Item>
              </div>
            </div>
          </div>
        </div>
        <div className="space-y-4 rounded-xl bg-white p-6 shadow-md">
          <h3 className="m-0 text-lg font-semibold">Pesan Otomatis</h3>
          <div>{formItemsWebWidget.map((item, index) => _renderItemWebWidget(item, index))}</div>
        </div>
      </div>
    </Form>
  );
}
