import { Button } from '@squantumengine/horizon';

export default function DetailCardLayout({
  children,
  title,
  buttonAction
}: {
  children: React.ReactNode;
  title: string;
  buttonAction?: () => void;
}) {
  return (
    <div className="flex flex-col gap-4 rounded-lg bg-white p-6 shadow-md">
      <div className="flex items-center justify-between">
        <h3 className="m-0 font-bold">{title}</h3>
        {buttonAction && (
          <Button variant="secondary" onClick={() => buttonAction()} aria-label="edit-button">
            Edit
          </Button>
        )}
      </div>
      <div>{children}</div>
    </div>
  );
}
