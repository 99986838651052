export const Tablist = [
  { name: 'Semua' },
  { name: 'Masuk' },
  { name: 'Antrean' },
  { name: 'Pesan Offline' },
  { name: 'Selesai' }
];

export enum TablistEnum {
  ALL = 'Semua',
  INCOMING = 'Masuk',
  QUEUE = 'Antrean',
  END = 'Selesai',
  OFFLINE_MESSAGE = 'Pesan Offline'
}

export enum ChatFooterEnum {
  MESSAGE = 0,
  SUMMARY = 1,
  DONE = 2
}
export enum ChatFooterSummaryEnum {
  TOPIC = 0,
  NOTES = 1,
  DONE = 2
}
