export enum NativeLoginErrorMsgEnum {
  general = 'Something is wrong.',
  passwordExpired = 'Password has expired, please reset',
  minEightCharacter = 'Minimal 8 karakter',
  nonAlfanumerik = 'Karakter Non-Alfanumerik',
  twoLowercaseChar = '2 Huruf kecil (‘a’ - ‘z’)',
  twoUppercaseChar = '2 Huruf besar (‘A’ - ‘Z’)',
  number = 'Angka (‘0’ - ‘9’)',
  passwordNotSame = 'Konfirmasi password tidak sama. Mohon periksa dan coba lagi.',
  emailRequired = 'Tolong masukan email!',
  wrongEmail = 'Format email salah',
  passwordRequired = 'Tolong masukan password!'
}

export enum NativeLoginWordingEnum {
  welcome = 'Selamat datang!',
  welcomeDescription = 'Masuk ke dashboard SQE Contact Center menggunakan akun yang sudah didaftarkan.',
  password = 'Password',
  email = 'Email atau username',
  createNewPassword = 'Buat password baru',
  confirmNewPassword = 'Konfirmasi password baru',
  forgotPassword = 'Lupa password?',
  forgotPasswordDescription1 = 'Kami akan mengirimkan link reset password',
  forgotPasswordDescription2 = 'melalui email:',
  sendResetPassword = 'Kirim Link Reset Password',
  resetLinkExpired = 'Link reset password sudah tidak aktif',
  resetLinkExpiredDescription = 'Dapatkan link reset password yang baru dengan klik tombol di bawah.',
  resetPasswordSentTitle = 'Link reset password sudah dikirim',
  resetPasswordSent1 = 'Mohon cek Email atau WhatsApp Anda dan klik link yang tertera ',
  resetPasswordSent2 = 'untuk membuat password baru.',
  updatePassword = 'Ubah password berkala',
  updatePassword1 = 'Untuk dapat masuk ke SQE Contact Center,',
  updatePassword2 = 'Anda wajib mengubah password setiap 90 hari.',
  changePasswordNow = 'Ubah Password Sekarang',
  skip = 'Lewati',
  forSecurityReason = 'Untuk alasan keamanan, password harus diubah secara berkala setiap',
  changePasswordSuccess = 'Password berhasil diubah. Silakan login dengan password baru.'
}

export enum NativePlaceholderEnum {
  email = 'Masukkan email atau username',
  password = 'Masukkan password'
}

export enum NativeLoginAction {
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS'
}
