import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const ExclamationCircleSVG = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 4.25C7.71875 4.25 4.25 7.75 4.25 12C4.25 16.2812 7.71875 19.75 12 19.75C16.25 19.75 19.75 16.2812 19.75 12C19.75 7.75 16.25 4.25 12 4.25ZM12 18.25C8.53125 18.25 5.75 15.4688 5.75 12C5.75 8.5625 8.53125 5.75 12 5.75C15.4375 5.75 18.25 8.5625 18.25 12C18.25 15.4688 15.4375 18.25 12 18.25ZM13.3125 15C13.3125 14.2812 12.7188 13.6875 12 13.6875C11.25 13.6875 10.6875 14.2812 10.6875 15C10.6875 15.75 11.25 16.3125 12 16.3125C12.7188 16.3125 13.3125 15.75 13.3125 15ZM10.75 8.40625L10.9688 12.6562C10.9688 12.8438 11.1562 13 11.3438 13H12.625C12.8125 13 13 12.8438 13 12.6562L13.2188 8.40625C13.2188 8.1875 13.0625 8 12.8438 8H11.125C10.9062 8 10.75 8.1875 10.75 8.40625Z"
      fill="#949494"
    />
  </svg>
);

const ExclamationCircleIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={ExclamationCircleSVG} {...props} />
);

export default ExclamationCircleIcon;
