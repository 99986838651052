/**
 * Antd global theme config
 * @usage to configure specific antd component (e.g color, pseudo-classes, component props)
 * that align with our design guideline to apply globally
 * - @gunawansqe
 */

import { ThemeConfig } from 'antd';

const defaultTheme: ThemeConfig = {
  components: {
    Pagination: {
      colorBgContainer: '#2DB089',
      colorPrimary: '#ffffff',
      colorPrimaryActive: '#ffffff',
      colorPrimaryHover: '#ffffff'
    },
    Spin: {
      colorPrimary: '#2DB089'
    }
  }
};

const v2Theme: ThemeConfig = {
  components: {
    Pagination: {
      colorPrimary: '#ffffff',
      itemActiveBg: '#006CEB',
      colorPrimaryActive: '#ffffff',
      colorPrimaryHover: '#ffffff'
    },
    Spin: {
      colorPrimary: '#006CEB'
    }
  }
};

const themeConfig = {
  default: defaultTheme,
  v2: v2Theme
};

export { themeConfig };
