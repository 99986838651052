import { useEffect } from 'react';
import { SendNotificationInterface, ValidateInboxInterface } from '../shared/types/inbox.interface';
import { InfoCircleOutlined } from '@ant-design/icons';
import {
  CommunicationTypeEnum,
  ConversationContextEnum,
  defaultCommunicationTypeEnum
} from '../static/communication-type';
import { FormInstance } from 'antd';
import { NotificationInstance } from 'antd/es/notification/interface';
import { useWatch } from 'antd/lib/form/Form';

export const useValidateInbox = (form: FormInstance, api: NotificationInstance) => {
  const formChanges = useWatch([], form);

  useEffect(() => {
    validateInbox({ form, api });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formChanges]);
};

const sendNotification = ({
  api,
  value,
  condition,
  form,
  message,
  description
}: SendNotificationInterface) => {
  api.open({
    icon: <InfoCircleOutlined style={{ color: 'white' }} />,
    message,
    description,
    placement: 'topRight'
  });

  setTimeout(() => form.setFieldValue(value, condition), 500);
};

const validateInbox = ({ form, api }: ValidateInboxInterface) => {
  const validate = form.getFieldsValue();

  if (!validate?.communicationModes.includes(CommunicationTypeEnum.VIDEO)) {
    form.setFieldsValue({
      showVideoCallButton: false,
      showAudioCallButton: false,
      showScreenShareButton: false,
      showCapturePhoto: false,
      autoConnect: false
    });
  }

  if (
    validate?.conversationContext === ConversationContextEnum.KYC &&
    (validate?.communicationModes.includes(CommunicationTypeEnum.CHAT) ||
      validate?.defaultCommunicationType !== defaultCommunicationTypeEnum.VIDEO)
  ) {
    form.setFieldsValue({
      communicationModes: [CommunicationTypeEnum.VIDEO],
      defaultCommunicationType: defaultCommunicationTypeEnum.VIDEO
    });
  }

  if (
    validate?.allowTextMessage &&
    !validate?.communicationModes?.includes(CommunicationTypeEnum.CHAT)
  ) {
    sendNotification({
      api,
      value: 'allowTextMessage',
      condition: false,
      form,
      message: 'Izinkan pengiriman chat dinonaktifkan',
      description:
        'Izinkan pengiriman chat dinonaktifkan secara otomatis karena pada metode percakapan anda tidak memilih Chat'
    });
  } else if (
    validate?.conversationContext === ConversationContextEnum.KYC &&
    !validate?.communicationModes.includes(CommunicationTypeEnum.VIDEO)
  ) {
    const communicationModes = validate.communicationModes;
    communicationModes.push(CommunicationTypeEnum.VIDEO);
    sendNotification({
      api,
      value: 'communicationModes',
      condition: communicationModes,
      form,
      message: 'Panggilan Video Tidak Bisa Dinonaktifkan',
      description:
        'Panggilan Video tidak bisa dinonaktifkan karena metode percakapan yang dipilih adalah Manual KYC'
    });
  } else if (
    !validate?.allowTextMessage &&
    validate?.communicationModes?.includes(CommunicationTypeEnum.CHAT)
  ) {
    sendNotification({
      api,
      value: 'allowTextMessage',
      condition: true,
      form,
      message: 'Izinkan pengiriman chat diaktifkan',
      description:
        'Izinkan pengiriman chat diaktifkan secara otomatis karena pada metode percakapan anda memilih Chat'
    });
  } else if (
    validate?.showVideoCallButton &&
    validate?.communicationModes?.includes(CommunicationTypeEnum.VIDEO) &&
    validate?.defaultCommunicationType === defaultCommunicationTypeEnum.AUDIO
  ) {
    sendNotification({
      api,
      value: 'showVideoCallButton',
      condition: false,
      form,
      message: 'Izinkan tampilkan tombol video dinonaktifkan',
      description:
        'Izinkan tampilkan tombol video dinonaktifkan secara otomatis karena pada tipe panggilan anda memilih panggilan suara'
    });
  }
};
