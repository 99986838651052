import { Button } from 'antd';
import HttpStatusCode from '../../types/enums/request';
import { getIconUrl } from '../../utils/getIconUrl';

type Props = {
  className?: string;
  status: string | number;
  title?: string;
  subTitle?: string;
  thumbnail?: string;
  extra?: JSX.Element;
  okText?: string;
  onOk?: () => void;
};

export const Result = ({
  className,
  status,
  title,
  subTitle,
  thumbnail = getIconUrl('ic-page-error.png'),
  extra,
  okText = 'Continue',
  onOk
}: Props) => {
  const titleFromStatus = {
    [HttpStatusCode.REQUEST_TIMEOUT]: 'Request Timeout',
    [HttpStatusCode.INTERNAL_SERVER_ERROR]: 'Internal Server Error'
  }[status];

  return (
    <div className={`flex flex-col items-center ${className}`}>
      {thumbnail && <img src={thumbnail} alt={titleFromStatus} />}
      <h2>{title || titleFromStatus}</h2>
      {subTitle && <p>{subTitle}</p>}
      {
        <Button
          type="primary"
          className="w-full rounded-full bg-bold-green font-semibold"
          onClick={onOk}>
          {okText}
        </Button>
      }
      {extra}
    </div>
  );
};
