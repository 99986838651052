import { Col, notification } from 'antd';
import CallList from '../../components/card/call-list';
import IncomingCall from '../../components/card/incoming-call';
import { MessageEventEnum } from '../../static/message-event';
import {
  FetchedConversationInterface,
  FetchedSupervisorConversationInterface
} from '../../utils/webSocket/websocket-interfaces';
import { fetchAllConversationInInbox } from '../../api';
import { InboxListItemInterface } from '../../shared/types/inbox.interface';
import { useUserStatusStore } from '../../store/user-state';
import { RoleEnum } from '../../static/role';
import { getUserJSON } from '../../utils/user-json';
import { fetchOfflineConversationsAPI } from '../../api/inbox';
import { ConversationInterface } from '../../components/card/chat-message/chat-message.interface';
import { fetchCustomerDetails } from '../../api/customer';
import { getWorkspaceSummary } from '../../api/workspace';
import { useSelectedCustomerStore } from '../../store/customer-state';
import { UpdateConversationStatus } from '../../api/call';
import { useInboxDetailStore } from '../../store/inbox-state';
import { CommunicationTypeEnum } from '../../static/communication-type';
import { StringDateToNumber } from '../../utils/timestamp';
import { useConversationListStore } from '../../store/conversation-list-state';
import { ConversationType } from '../../store/conversation-list-state.interface';
import { SelectedCustomerInterface } from '../../shared/types/customer.interface';
import { FullWorkspaceSummary, SaveWorkspaceParam } from '../../api/param.interface';
import { UseMutateFunction } from 'react-query';

interface ParseConversationInterface {
  item: FetchedConversationInterface;
  currentTime: number;
  event: ConversationType;
}

const parseConversation = ({
  item,
  currentTime,
  event
}: ParseConversationInterface): Partial<ConversationInterface> => {
  const inboxConfig = item?.inbox?.inboxConfig;
  const callConfig = item?.inbox?.inboxConfig?.mediaCallConfiguration;

  return {
    id: item.conversationId,
    isUser: false,
    type: item.communicationType === CommunicationTypeEnum.VIDEO ? 'video' : 'text',
    name: item?.contact?.firstName,
    conversationId: item.conversationId,
    conversationStatus: item?.conversationStatus,
    meetingRoomId: item?.meetingRoomId,
    event: event,
    contactId: item?.contact?.id,
    startTime: StringDateToNumber(item?.startTime) || currentTime,
    endTime: StringDateToNumber(item?.endTime),
    communicationType: item.communicationType,
    agent: item?.agent,
    metadata: item?.metadata,
    inboxConfig: {
      allowAttachment: inboxConfig?.allowAttachment,
      allowMediaCall: inboxConfig?.allowMediaCall,
      allowRefId: inboxConfig?.allowRefId,
      allowScreenShare: inboxConfig?.allowScreenShare,
      allowTextMessage: inboxConfig?.allowTextMessage,
      allowConversationRating: inboxConfig?.allowConversationRating,
      allowConversationSummary: inboxConfig?.allowConversationSummary,
      webhookIdentifier: inboxConfig?.webhookIdentifier,
      credential: inboxConfig?.credential,
      communicationModes: inboxConfig?.communicationModes,
      conversationContext: inboxConfig?.conversationContext,
      durationReminder: inboxConfig?.durationReminder,
      reminderIdleConversation: inboxConfig?.reminderIdleConversation,
      mediaCallConfiguration: {
        defaultCommunicationType: callConfig?.defaultCommunicationType,
        showVideoCallButton: callConfig?.showVideoCallButton,
        showAudioCallButton: callConfig?.showAudioCallButton,
        showScreenShareButton: callConfig?.showScreenShareButton,
        autoConnect: callConfig?.autoConnect,
        showCapturePhoto: callConfig?.showCapturePhoto
      }
    },
    jawabAt: item?.jawabAt
  };
};

export const parseAgentConversation = (
  props: ParseConversationInterface
): ConversationInterface => {
  const { item, currentTime, event } = props;

  return {
    ...(parseConversation({ item, currentTime, event }) as ConversationInterface),
    message: '',
    time: Date.now(),
    unreadMessage: item?.unreadMessage,
    inboxId: item?.inbox?.inboxId,
    metadata: item?.metadata,
    createdAt: item?.createdAt,
    jawabAt: item?.jawabAt
  };
};

const parseSupervisorConversation = (props: ParseConversationInterface): ConversationInterface => {
  const { item, currentTime, event } = props;

  return {
    ...(parseConversation({ item, currentTime, event }) as ConversationInterface),
    time: new Date(item.createdAt).getTime() || Date.now(),
    inboxName: (item as FetchedSupervisorConversationInterface)?.inboxName,
    inboxId: (item as FetchedSupervisorConversationInterface)?.inboxId,
    kycStatus: (item as FetchedSupervisorConversationInterface)?.kycStatus,
    createdAt: item?.createdAt,
    metadata: item?.metadata,
    jawabAt: item?.jawabAt
  };
};

export const createIncomingCallComponents = (
  handleActiveCall: (data: any) => void,
  handleAssignAgent?: (conversation: ConversationInterface) => void,
  conversations: ConversationInterface[] | null = null
) => {
  const isAgent = useUserStatusStore.getState().userRole === RoleEnum.AGENT;
  const curTime = Date.now();
  const targetList = isAgent ? 'assignedConversationList' : 'createdConversationList';
  let incomingCallList = useConversationListStore.getState()[targetList];

  if (!incomingCallList.length && !conversations) {
    return null;
  }
  if (conversations) {
    incomingCallList = conversations;
  }
  return incomingCallList
    .sort((firstElem, secondElem) => firstElem.time - secondElem.time)
    .map((data, key) => (
      <Col key={key} className="w-full">
        <IncomingCall
          data={data}
          handleActiveCall={handleActiveCall}
          handleAssignAgent={handleAssignAgent}
          startTimer={isAgent ? 0 : Math.round((curTime - data.time) / 1000)}
        />
      </Col>
    ));
};

export const createCallListComponent = (
  conversations: ConversationInterface[],
  handleAssignAgent?: (item: ConversationInterface) => void
) => {
  return conversations.map((item, idx) => (
    <Col className="w-full" key={idx}>
      <CallList
        customerName={item.name}
        type={item.communicationType}
        data={item}
        handleAssignAgent={handleAssignAgent}
      />
    </Col>
  ));
};

const fetchAllConversationsByInboxStatus = async (
  inbox: InboxListItemInterface,
  isActive: boolean,
  page: number
) => {
  try {
    const conversationList: FetchedSupervisorConversationInterface[] = [];

    const { data } = await fetchAllConversationInInbox(inbox.inboxId, isActive, page);

    if (data?.length) {
      const dataWithInbox: FetchedSupervisorConversationInterface[] = data.map((item) => ({
        ...item,
        isActive,
        inboxName: inbox.name,
        inboxId: inbox.inboxId
      }));
      conversationList.push(...dataWithInbox);
    }

    return conversationList;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    return [];
  }
};

export const fetchSupervisorConversations = async (isActive: boolean) => {
  try {
    const inboxList = useInboxDetailStore.getState().inboxList;
    // for now, supervisor only fetches the first page of each inbox
    const promises = inboxList.map((inbox) =>
      fetchAllConversationsByInboxStatus(inbox, isActive, 1)
    );
    const response = await Promise.all(promises);
    return response;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    return [];
  }
};

export const fetchSupervisorActiveConversations = async (reFetch: boolean = false) => {
  const activeConversationList = await fetchSupervisorConversations(true);
  const currentTime = Date.now();

  const createdConversationList: ConversationInterface[] = [];
  const assignedConversationList: ConversationInterface[] = [];

  activeConversationList.forEach((conversationList) => {
    conversationList.forEach((item) => {
      // previously, there was no startTime parameter for assigned conversation
      // these data are removed in this implementation
      if (item.agent && !item.startTime) return;

      const isAssigned = !!item.agent;
      const event: ConversationType = isAssigned
        ? MessageEventEnum.ACTIVE
        : MessageEventEnum.CREATED;
      const props = { item, currentTime, event };
      const parsedConversation = parseSupervisorConversation(props);
      const targetArray = isAssigned ? assignedConversationList : createdConversationList;
      targetArray.push(parsedConversation);
    });
  });

  assignedConversationList.sort(
    (firstElem, secondElem) => secondElem.startTime - firstElem.startTime
  );
  reFetch && useConversationListStore.getState().clearSPVConversation();
  useConversationListStore
    .getState()
    .appendConversationList(MessageEventEnum.CREATED, createdConversationList);
  useConversationListStore
    .getState()
    .appendConversationList(MessageEventEnum.ACTIVE, assignedConversationList);
};

export const parsedConversation = (activeData: any, inboxId: string, isActive: boolean) => {
  const createdConversationListArr: ConversationInterface[] = [];
  const assignedConversationList: ConversationInterface[] = [];
  const inactiveList: ConversationInterface[] = [];

  activeData
    ?.filter(
      (item: ConversationInterface) => item?.conversationStatus !== MessageEventEnum.PRE_QUEUE
    )
    .forEach((item: ConversationInterface) => {
      const currentTime = Date.now();
      const isAssigned = !!item?.agent;
      const event: ConversationType = !isActive
        ? MessageEventEnum.INACTIVE
        : isAssigned
        ? MessageEventEnum.ACTIVE
        : MessageEventEnum.CREATED;
      const props: any = { item, currentTime, event };
      let parsed = parseSupervisorConversation(props);
      parsed.inboxId = inboxId;
      const targetArray = !isActive
        ? inactiveList
        : isAssigned
        ? assignedConversationList
        : createdConversationListArr;
      targetArray.push(parsed);
    });
  return { createdConversationListArr, assignedConversationList, inactiveList };
};
export const fetchSupervisorInactiveConversations = async () => {
  const inactiveConversationList = await fetchSupervisorConversations(false);
  const currentTime = Date.now();

  const inactiveList: ConversationInterface[] = [];

  inactiveConversationList.forEach((conversationList) => {
    conversationList.forEach((item) => {
      const props = { item, currentTime, event: MessageEventEnum.INACTIVE as ConversationType };
      const parsedConversation = parseSupervisorConversation(props);
      inactiveList.push(parsedConversation);
    });
  });

  useConversationListStore
    .getState()
    .appendConversationList(MessageEventEnum.INACTIVE, inactiveList);
};

export const fetchOfflineConversations = async (page: number) => {
  const user = getUserJSON();
  const accountId = user?.account?.accountId || '';

  try {
    const response = await fetchOfflineConversationsAPI(accountId, page);
    return response;
  } catch (err) {
    notification.error({ message: `Failed to get inactive list: ${err}` });
    return {
      offlineMessages: [],
      size: 0,
      pageSize: 0
    };
  }
};

const dashToUndefinedHandler: any = (input: string | undefined) => {
  if (input === '-') return undefined;
  return input;
};

export const loadCustomerDetail = async (
  conversation: ConversationInterface,
  mutate: UseMutateFunction<void, unknown, SaveWorkspaceParam, unknown>,
  customerDetailsData?: SelectedCustomerInterface,
  workspaceSummaryData?: FullWorkspaceSummary
) => {
  const user = getUserJSON();
  const accountId = user?.account?.accountId || '';
  const contactId = conversation?.contactId || '';
  let customerDetails: any = customerDetailsData
    ? customerDetailsData
    : await fetchCustomerDetails(accountId, contactId);

  const conversationId = conversation?.conversationId || '';
  let workspaceSummary: any = workspaceSummaryData
    ? workspaceSummaryData
    : await getWorkspaceSummary(accountId, conversationId);

  const { topic, background, specificRequest, solution, note, documents } = await workspaceSummary;

  const isDataExisted = topic || background || specificRequest || solution || note;
  if (isDataExisted) {
    customerDetails = {
      ...customerDetails,
      topic: dashToUndefinedHandler(topic),
      background: dashToUndefinedHandler(background),
      specificRequest: dashToUndefinedHandler(specificRequest),
      solution: dashToUndefinedHandler(solution),
      note: dashToUndefinedHandler(note),
      documents: documents
    };
  } else {
    mutate({
      accountId,
      conversationId,
      payload: {
        topic: '-',
        background: '-',
        specificRequest: '-',
        solution: '-',
        note: '-'
      }
    });
  }

  localStorage.setItem('customerDetails', JSON.stringify(customerDetails));
  useSelectedCustomerStore.getState().setCustomerDetail({ ...customerDetails });
  if (accountId) UpdateConversationStatus(accountId, conversationId);
};
