import React from 'react';
import {
  AgentInterface,
  ConversationInterface
} from '../../../card/chat-message/chat-message.interface';
import { Flex, Modal, message } from 'antd';
import { Typography } from '@squantumengine/horizon';
import { CloseOutlined } from '@ant-design/icons';
import AssignAgentModalContentV2 from './modal-content';
import { InboxMemberListWithCapacityInterface } from '../../../../shared/types/inbox.interface';
import { useInboxUserList } from '../../../../api/hooks/inbox/useInboxUserList';
import { CommunicationTypeEnum } from '../../../../static/communication-type';
import { CurrentUserObjectInterface } from '../../../../shared/types/user.interface';
import { AssignAgentDataInterface } from '../../../modal/assign-agent-modal/assign-agent-modal.interface';
import {
  assignAgentToConversation,
  getAgentMaxChat,
  unassignAgentFromConversation
} from '../../../../api/user';
import { useMutation } from 'react-query';

const { Title } = Typography;

interface Props {
  conversation: ConversationInterface | null;
  isOpen: boolean;
  onClose: () => void;
  refetch: () => void;
}

const AssignAgentModalV2: React.FC<Props> = ({ conversation, isOpen, onClose }) => {
  const inboxId = conversation?.inboxId as string;
  const { data: agents, isLoading, refetch: refetchMember } = useInboxUserList(inboxId, isOpen);

  const [messageApi, contextHolder] = message.useMessage();

  const assignMutation = useMutation(assignAgentToConversation, {
    onSuccess: () => {
      refetchMember();
      messageApi.open({
        type: 'success',
        content: 'Agent telah dihubungkan dengan pelanggan.'
      });
    },
    onError(error: any) {
      showError(error.response.data.message || 'Manual assign gagal');
      refetchMember();
    }
  });

  const unAssignMutation = useMutation(unassignAgentFromConversation, {
    onSuccess: (data, variables: AssignAgentDataInterface) => {
      assignMutation.mutate(variables);
      messageApi.open({
        type: 'info',
        content: 'Berhasil meng-unassign agent sebelumnya.'
      });
    }
  });

  const showError = (message: string) => {
    messageApi.open({
      type: 'error',
      content: message
    });
  };

  const checkIsAgentAvailable = async (agentId: string) => {
    try {
      const { available, capacity } = await getAgentMaxChat(agentId);
      if (!available) {
        showError('Agent baru saja mulai beristirahat.');
        return false;
      }

      const { onGoingChatCapacity, maxChatCapacity, onGoingCallCapacity, maxCallCapacity } =
        capacity;

      if (onGoingChatCapacity >= maxChatCapacity || !maxChatCapacity) {
        showError('Agent tidak memiliki kapasitas untuk menerima percakapan.');
        return false;
      }

      if (onGoingCallCapacity >= maxCallCapacity || !maxCallCapacity) {
        showError(
          'Agent sedang dalam panggilan atau tidak memiliki kapasitas untuk menerima panggilan.'
        );
        return false;
      }

      return true;
    } catch (err: any) {
      showError(
        `Gagal memeriksa status agen: ${
          err.response?.data?.message || 'Kesalahan tidak diketahui.'
        } `
      );
      return false;
    }
  };

  const assignAgent = async (
    conversationId: string,
    agent: AgentInterface,
    communicationType: CommunicationTypeEnum,
    isUnassign?: boolean
  ) => {
    const currentUser: CurrentUserObjectInterface = JSON.parse(
      localStorage.getItem('user') || '{}'
    );
    const accountId: string = currentUser.account?.accountId || '';
    const agentId = agent.id;
    const data: AssignAgentDataInterface = {
      accountId,
      conversationId,
      agentId,
      communicationType
    };

    const isAssignable = await checkIsAgentAvailable(agent.id);
    if (!isAssignable) return;
    onClose();
    isUnassign ? unAssignMutation.mutate(data) : assignMutation.mutate(data);
  };

  if (!conversation) return null;

  return (
    <>
      {contextHolder}
      <Modal centered open={isOpen} footer={null} closable={false} width={700}>
        <Flex justify="space-between" align="center" className="relative">
          <Title level={5} className="font-semibold">
            Pilih Agen atau Tim
          </Title>
          <CloseOutlined onClick={onClose} className="cursor-pointer text-xl" />
        </Flex>
        <Flex vertical className="mt-6 w-full">
          <AssignAgentModalContentV2
            agents={agents?.inboxMembersList as InboxMemberListWithCapacityInterface[]}
            conversation={conversation!}
            isLoading={isLoading}
            onAssign={assignAgent}
          />
        </Flex>
      </Modal>
    </>
  );
};

export default AssignAgentModalV2;
