import { useMutation, useQueryClient } from 'react-query';
import { submitKYC } from '../../kyc';

export const useSubmitKYC = () => {
  const queryClient = useQueryClient();

  return useMutation(submitKYC, {
    onSuccess: () => {
      queryClient.invalidateQueries('WorkspaceSummary');
      queryClient.invalidateQueries(['get-verdict-kyc']);
    },
    onError: () => {
      queryClient.invalidateQueries(['get-verdict-kyc']);
    }
  });
};
