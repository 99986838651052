import { useEffect, useState } from 'react';
import { Paragraph } from '@squantumengine/horizon';
import { useChatListStore } from '../../store/chat-list';
import { useAppStore } from '../../store/user-state';
import { useCallStore } from '../../store/call-state';
import { startCountdown } from '../../utils/count-down';

const CustomerDisconnect = ({
  showModal,
  duration
}: {
  showModal: () => void;
  duration: number;
}) => {
  const [timeLeft, setTimeLeft] = useState('');
  const [showTimeLeft, setShowTimeLeft] = useState(true);

  const { fullScreen } = useCallStore();
  const { selectedCall } = useChatListStore();
  const showAlert = selectedCall?.inboxConfig?.reminderIdleConversation;
  const startHeartBeat =
    localStorage.getItem('startHeartBeat') || useAppStore.getState().startHeartBeat;

  const handleChangeTime = (value: string) => {
    setTimeLeft(value);
  };

  const handleEndTime = () => {
    setTimeLeft('00:00');
    showAlert && showModal();
    setShowTimeLeft(true);
  };

  useEffect(() => {
    if (startHeartBeat) {
      const timeStamp = localStorage.getItem('hearthBeat') || useAppStore.getState().heartBeat;
      startCountdown(Number(timeStamp) * 1000, duration, handleChangeTime, handleEndTime);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startHeartBeat, duration]);

  return (
    <div
      className={
        !fullScreen
          ? 'hidden'
          : 'absolute left-1/2 top-1/2 z-10 flex h-max w-max -translate-x-1/2 flex-col items-center justify-center rounded-xl bg-black-rich bg-opacity-80 p-6'
      }>
      <Paragraph className="font-semibold text-white" size="xl">
        Pelanggan Terputus
      </Paragraph>
      <Paragraph className="text-white" size="l">
        Mohon tunggu {showTimeLeft && showAlert && <strong>{timeLeft}</strong>} atau akhiri sesi.
      </Paragraph>
    </div>
  );
};

export default CustomerDisconnect;
