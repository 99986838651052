import { useEffect, useState } from 'react';
import { Row, Spin } from 'antd';

import { ListSupervisorInterface } from './list-supervisor.interface';

import { useTeamStore } from '../../../store/team-state';
import UserCard from '../user';
import { SupervisorInterface } from '../../../shared/types/user.interface';
import { RoleEnum } from '../../../static/role';

export default function ListSupervisor({ accountId }: ListSupervisorInterface) {
  const [supervisors, setSupervisors] = useState<SupervisorInterface[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const { supervisorItemList, selectedInbox, fetchSupervisorItemList } = useTeamStore();

  const fetchSupervisorListAndSet = async () => {
    try {
      await fetchSupervisorItemList(accountId);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchSupervisorListAndSet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId]);

  useEffect(() => {
    const newSupervisorList = supervisorItemList.find(
      (item) => item.accountId === accountId
    )?.supervisorList;
    if (newSupervisorList) setSupervisors(newSupervisorList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supervisorItemList]);

  return (
    <Spin spinning={isLoading}>
      <Row id={`supervisor_list-${accountId}`} gutter={16} className="pt-4">
        {supervisors.length > 0 &&
          supervisors.map((supervisor, idx) => (
            <UserCard
              type={RoleEnum.SUPERVISOR}
              key={`supervisor-${accountId}-${idx}`}
              addPadding
              agentId={supervisor.agentId}
              inboxId={selectedInbox.inboxId}
              firstName={supervisor.firstName}
              lastName={supervisor.lastName}
              id={`supervisor-${accountId}-${idx}`}
              roleLabel="Supervisor"
            />
          ))}
      </Row>
    </Spin>
  );
}
