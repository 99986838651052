import { CheckCircleFilled } from '@ant-design/icons';
import { Button, Flex, Modal, Typography } from 'antd';
import { UserResponse } from '../../card/user/user.interface';
import { useGetUsers } from '../../../api/hooks/sqeid/useSQEID';

interface TeamBUModalInterface {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsModalEditOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedData: React.Dispatch<React.SetStateAction<UserResponse>>;
  selectedData: UserResponse;
}

const { Title } = Typography;

const TeamConfirmationModal = ({
  isModalOpen,
  setIsModalOpen,
  setIsModalEditOpen,
  setSelectedData
}: TeamBUModalInterface) => {
  const { data: invUserData } = useGetUsers();

  return (
    <Modal
      title={
        <Flex justify="start" align="middle" gap="middle">
          <CheckCircleFilled className="text-3xl text-green-500" />
          <Title className="m-0" level={3}>
            Undangan Berhasil Dikirim
          </Title>
        </Flex>
      }
      closable={false}
      open={isModalOpen}
      footer={
        <Flex justify="end" gap="middle">
          <Button
            onClick={() => setIsModalOpen(false)}
            className={`h-10 rounded-lg border-2 border-solid border-green-500 px-4 text-base font-semibold text-green-500`}>
            Lengkapi Nanti
          </Button>
          <Button
            onClick={() => {
              setIsModalOpen(false);
              setIsModalEditOpen(true);
              setSelectedData(invUserData![0]);
            }}
            className={`h-10 rounded-lg border-none bg-green-500 px-4 text-base font-semibold text-white`}>
            Lengkapi Sekarang
          </Button>
        </Flex>
      }>
      <Title className="font-normal" level={5}>
        Langkah selanjutnya adalah melengkapi profil user. Silakan lengkapi sekarang atau bisa
        lengkapi nanti
      </Title>
    </Modal>
  );
};

export default TeamConfirmationModal;
