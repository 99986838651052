import { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import {
  ChatMessageInterface,
  ConversationInterface
} from '../components/card/chat-message/chat-message.interface';
import {
  CloseConversationEnum,
  ConversationStatusEnum,
  MessageEventEnum
} from '../static/message-event';
import ConnectWebSocket, { CloseWebSocket } from '../utils/webSocket';
import { WSMessageCreatedInterface } from '../utils/webSocket/websocket-interfaces';
import { loadCustomerDetail } from '../pages/dashboard/dashboard.helper';
import { useCallStore } from '../store/call-state';
import { useAppStore, useUserStatusStore } from '../store/user-state';
import { useChatListStore } from '../store/chat-list';
import { CloseConversation, PreClosureConversation } from '../api/call';
import { RoleEnum } from '../static/role';
import { fetchInboxByAgent } from '../api';
import { useInboxDetailStore } from '../store/inbox-state';
import { CurrentUserObjectInterface } from '../shared/types/user.interface';
import { heartBeatInterval, heartBeatThreshold, pingInterval } from '../static/ping-interval';
import { CommunicationTypeEnum } from '../static/communication-type';
import { useConversationListStore } from '../store/conversation-list-state';
import { message, notification } from 'antd';
import { ConversationType } from '../store/conversation-list-state.interface';
import { getConversationListKey } from '../store/conversation-list.helper';
import { useWorkspaceSaveSummary } from '../api/hooks/workspace/useWorkspaceSaveSummary';
import { conversationByAgent } from '../config/conversation-agent';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelectedCustomerStore } from '../store/customer-state';
import { useAuth } from './useAuth';
import { useQueryClient } from 'react-query';
import {
  playChatNotification,
  playVideoNotification,
  stopVideoNotification
} from '../utils/notification';
import useAgentAnswerConv from '../api/hooks/inbox/useAgentAnswerConv';
import { inboxDashboard } from '../static/routeConstructor';
import { useAuthStore } from '../store/new/auth';
import { fetchAccountUnreadMessage } from '../services/account';

export const useDashboardPage = (setLoading?: Dispatch<SetStateAction<boolean>>) => {
  const queryClient = useQueryClient();
  const { logout } = useAuth();
  const { mutate } = useWorkspaceSaveSummary();
  const {
    setShowVideo,
    setRemoteMediaType,
    removeAttentionChat,
    websocketConnected,
    setShowCustomerOfflineAlert
  } = useCallStore();
  const {
    appendChatList,
    selectedCall,
    setSelectedCall,
    incrementUnreadMessage,
    setOfflineChatList
  } = useChatListStore();
  const {
    activeConversationList,
    preClosureConversationList,
    moveConversation,
    removeConversation,
    updateLastReplyConversation
  } = useConversationListStore();

  const { userRole } = useUserStatusStore();
  const { setInboxList } = useInboxDetailStore();
  const { setMetadata } = useSelectedCustomerStore();
  const { mutate: handleAgentAnswerConv } = useAgentAnswerConv();
  const location = useLocation();
  const v2 = location.pathname.includes('v2');

  const changeIconToVideo = (data: WSMessageCreatedInterface, listKey: ConversationType) => {
    const conversationList = useConversationListStore.getState();
    const currentList = [
      ...(conversationList[getConversationListKey(listKey)] as ConversationInterface[])
    ];

    const conversationIndex = currentList.findIndex(
      (conversation) => conversation.conversationId === data.conversation.conversationId
    );
    if (conversationIndex !== -1) {
      const conversation: ConversationInterface = {
        ...currentList[conversationIndex],
        event: MessageEventEnum.ACTIVE,
        communicationType: CommunicationTypeEnum.VIDEO,
        type: MessageEventEnum.CALL
      };
      currentList[conversationIndex] = conversation;
      conversationList.setConversationList(listKey, currentList);
    }
  };

  const onMessage = async (message: WSMessageCreatedInterface) => {
    const { data, conversation, event } = message;
    const newChat: ChatMessageInterface = {
      id: data.messageId,
      documents: data?.attachment,
      message: data?.content || '',
      time: data?.timestamp || Date.now(),
      isUser: data?.senderType === MessageEventEnum.CONTACT,
      type:
        data?.attachment && data?.attachment?.length > 0
          ? MessageEventEnum.FILE
          : data?.messageType === MessageEventEnum.MEDIA_CALL
          ? MessageEventEnum.CALL
          : MessageEventEnum.TEXT,
      name: data?.sender?.name || '',
      conversationId: conversation.conversationId,
      event: event as ConversationType
    };

    if (data?.messageType === MessageEventEnum.MEDIA_CALL) {
      const isFromChatConversation =
        useConversationListStore
          .getState()
          .activeConversationList?.find(
            (item) => item.conversationId === conversation.conversationId
          )?.communicationType === CommunicationTypeEnum.CHAT;
      isFromChatConversation && playVideoNotification();
      changeIconToVideo(message, MessageEventEnum.ASSIGNED);
      changeIconToVideo(message, MessageEventEnum.ACTIVE);
    }
    incrementUnreadMessage(conversation.conversationId, conversation.inboxId);
    if (data?.messageType === MessageEventEnum.IN_COMING) {
      updateLastReplyConversation(conversation.conversationId, data.timestamp);
    }

    const isCurrentSelectedConv =
      conversation.conversationId === useChatListStore.getState().selectedCall?.conversationId;
    const isUserInAnotherTab = isCurrentSelectedConv && document.hidden;
    const shouldPlayChatNotification =
      userRole === RoleEnum.AGENT && (!isCurrentSelectedConv || isUserInAnotherTab);
    if (shouldPlayChatNotification) playChatNotification();
    appendChatList([newChat]);
  };
  const navigate = useNavigate();

  const handleActiveCall = async (data: ConversationInterface) => {
    removeAttentionChat(data.conversationId);
    const res = await conversationByAgent(true, 1, data.inboxId);
    const newObject: ConversationInterface = {
      ...data,
      id: data.conversationId,
      message: '',
      time: Date.now(),
      isUser: false,
      type: data?.type || 'text',
      name: data?.name,
      meetingRoomId: data?.meetingRoomId,
      event: MessageEventEnum.ACTIVE,
      contactId: data?.contactId,
      metadata: res?.data?.[0]?.metadata,
      startTime: Date.now(),
      jawabAt: Date.now().toString()
    };
    setMetadata(newObject?.metadata);
    newObject['inboxConfig'] = res?.data?.[0]?.inbox?.inboxConfig;
    if (!activeConversationList.some((conv) => conv.conversationId === newObject.conversationId)) {
      moveConversation(MessageEventEnum.ASSIGNED, MessageEventEnum.ACTIVE, newObject);
    } else {
      removeConversation(MessageEventEnum.ASSIGNED, newObject.conversationId);
    }
    loadCustomerDetail(newObject, mutate);
    setSelectedCall(newObject);
    setOfflineChatList([]);
    navigate(inboxDashboard({ inboxId: data?.inboxId!! }, v2));
    stopVideoNotification();
    handleAgentAnswerConv(data.conversationId);
  };

  const handleEndCall = async (closingType: CloseConversationEnum) => {
    let failedEndCall = false;
    const currentUser: CurrentUserObjectInterface = JSON.parse(
      localStorage.getItem('user') || '{}'
    );
    const accountId = currentUser.account?.accountId || '';

    const targetList =
      closingType === CloseConversationEnum.PRECLOSE
        ? activeConversationList
        : preClosureConversationList;
    const conversationList = [...targetList];
    const conversationIndex = conversationList.findIndex(
      (conversation) => conversation.conversationId === selectedCall?.conversationId
    );

    if (conversationIndex === -1) return true;

    switch (closingType) {
      case CloseConversationEnum.PRECLOSE: {
        try {
          const conversation: ConversationInterface = {
            ...conversationList[conversationIndex],
            event: MessageEventEnum.SESSION_CLOSED
          };
          if (accountId) {
            await PreClosureConversation(accountId, conversation.conversationId);
          }
          moveConversation(MessageEventEnum.ACTIVE, MessageEventEnum.SESSION_CLOSED, conversation);
          setSelectedCall(conversation);
        } catch (error: any) {
          // eslint-disable-next-line no-console
          console.log(error);
          failedEndCall = true;
          message.error(error?.response?.data?.message || 'Gagal mengakhiri panggilan');
        } finally {
          break;
        }
      }

      case CloseConversationEnum.CLOSE: {
        const conversation: ConversationInterface = {
          ...conversationList[conversationIndex],
          event: MessageEventEnum.INACTIVE,
          endTime: Date.now()
        };
        moveConversation(MessageEventEnum.SESSION_CLOSED, MessageEventEnum.INACTIVE, conversation);

        if (accountId) {
          try {
            await CloseConversation(accountId, conversation.conversationId);
            setSelectedCall({
              ...conversation,
              conversationStatus: ConversationStatusEnum.RESOLVED
            });
            queryClient.invalidateQueries(['useHistory', conversation?.contactId]);
          } catch (error) {}
        }
        break;
      }
    }
    return failedEndCall;
  };
  const getUnreadMessage = async (accountId: string) => {
    const unreadConv = await fetchAccountUnreadMessage(accountId);
    unreadConv.forEach((item: any) => {
      for (let i = 0; i < item.unreadMessage; i++) {
        incrementUnreadMessage(item.conversationId, item.inboxId);
      }
    });
  };

  // by default, hooks are called twice inside react development mode due to strict mode,
  // adding a boolean useRef will prevent web socket initiation from being called twice
  const firstRender = useRef(true);
  useEffect(() => {
    // prevents re-connect after navigating pages
    if (
      firstRender.current &&
      !websocketConnected &&
      (userRole === RoleEnum.AGENT || userRole === RoleEnum.SUPERVISOR)
    ) {
      ConnectWebSocket({ onMessage, setShowVideo, setRemoteMediaType, logout });
      getUnreadMessage(useAuthStore.getState().account.accountId);
      firstRender.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRole]);

  const firstOffline = useRef(true);
  const firstOnline = useRef(true);
  const attemps = useRef(0);
  const interval = 10000;

  window.addEventListener('offline', () => {
    if (firstOffline.current) {
      firstOffline.current = false;
      firstOnline.current = true;
      let reconnectIntervalId: any;
      CloseWebSocket();
      // eslint-disable-next-line
      console.log('WebSocket disconnected client network offline.');
      reconnectIntervalId = setInterval(() => {
        if (!navigator.onLine && attemps.current < 3) {
          attemps.current++;
          ConnectWebSocket({ onMessage, setShowVideo, setRemoteMediaType, logout });
          clearInterval(reconnectIntervalId);
        }
      }, interval);
    }
  });

  window.addEventListener('online', () => {
    if (firstOnline.current) {
      firstOnline.current = false;
      firstOffline.current = true;
      CloseWebSocket();
      ConnectWebSocket({ onMessage, setShowVideo, setRemoteMediaType, logout });
      // eslint-disable-next-line
      console.log('WebSocket back to online.');
    }
  });

  useEffect(() => {
    const schedulePing = setInterval(() => {
      const interval = Math.ceil(Date.now() / 1000) - useAppStore.getState().pingDate;
      if (interval > 20 && userRole === RoleEnum.AGENT) {
        CloseWebSocket();
        ConnectWebSocket({ onMessage, setShowVideo, setRemoteMediaType, logout });
      }
    }, pingInterval * 1000);
    return () => clearInterval(schedulePing);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let scheduleHeartBeat: NodeJS.Timeout | undefined;
    const localStartHearthBeat = localStorage.getItem('startHeartBeat');
    if (useAppStore.getState().startHeartBeat || localStartHearthBeat) {
      scheduleHeartBeat = setInterval(() => {
        const interval = Math.ceil(Date.now() / 1000) - useAppStore.getState().heartBeat;
        if (interval > heartBeatThreshold && userRole === RoleEnum.AGENT) {
          useAppStore.getState().setStartHeartBeat(false);
          setShowCustomerOfflineAlert(true);
        } else {
          setShowCustomerOfflineAlert(false);
        }
      }, heartBeatInterval * 1000);
    } else {
      clearInterval(scheduleHeartBeat);
    }
    // eslint-disable-next-line
  }, [useAppStore.getState().startHeartBeat]);

  const fetchInboxes = async (accountId: string, agentId: string) => {
    if (!accountId) return;

    let response;

    try {
      if (userRole === RoleEnum.AGENT) {
        response = await fetchInboxByAgent(accountId, agentId);
      }
      response && setInboxList(response.inboxList);

      // if the inbox is not empty, FE will start fetching conversations
      // so the loading state should continue
      if (!response?.inboxList?.length) {
        setLoading?.(false);
      }
    } catch {
      notification.error({ message: 'Gagal memuat daftar inbox' });
      setLoading?.(false);
    }
  };

  useEffect(() => {
    if (selectedCall) return;

    const currentUser: CurrentUserObjectInterface = JSON.parse(
      localStorage.getItem('user') || '{}'
    );
    const accountId = currentUser.account?.accountId || '';
    const agentId = currentUser.agentId || '';
    if (accountId && agentId) {
      fetchInboxes(accountId, agentId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCall]);

  useEffect(() => {
    const localStorageSelectedCall = localStorage.getItem('selectedCall');
    if (userRole === RoleEnum.SUPERVISOR && localStorageSelectedCall) {
      localStorage.removeItem('selectedCall');
    }

    localStorageSelectedCall && setSelectedCall(JSON.parse(localStorageSelectedCall));
  }, [userRole, setSelectedCall]);

  return {
    handleEndCall,
    handleActiveCall
  };
};
