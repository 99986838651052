import { ExpandAltOutlined } from '@ant-design/icons';
import { useState, useMemo } from 'react';

import { Button, Modal, Pagination, PaginationProps, Typography } from 'antd';
import { pageSizeKYC } from '../../utils/pageLimit';
import { GalleryModalInterface } from './kyc.interface';
import { DocumentInterface } from '../form/workspace/workspace.interface';
import { useSelectedCustomerStore } from '../../store/customer-state';
import dayjs from 'dayjs';
import { getFileFromUrl } from '../../utils/file';

function GalleryModal(props: GalleryModalInterface) {
  const { open, handleOk, handleCancel, documents, confirmLoading, setIsNewAttachment } = props;
  const { Title, Text } = Typography;

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [choose, setChoose] = useState<DocumentInterface | null>(null);
  const { setChooseKTP, setFileKTP, setChooseSelfie, setFileSelfie } = useSelectedCustomerStore();

  const dataKYC = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSizeKYC;
    const lastPageIndex = firstPageIndex + pageSizeKYC;

    const filteredDocuments = documents
      .slice(firstPageIndex, lastPageIndex)
      .filter((doc) => {
        const extension = doc?.documentName?.split('.')?.pop()?.toLowerCase();
        const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff', 'webp'];
        return imageExtensions.includes(extension!);
      })
      .reverse();

    return filteredDocuments;

    // eslint-disable-next-line
  }, [currentPage, documents]);

  const onChange: PaginationProps['onChange'] = (page) => {
    setCurrentPage(page);
  };

  const itemRender: PaginationProps['itemRender'] = (page, type, originalElement) => {
    if (type === 'page') {
      return (
        <div
          className={`ant-pagination-item-active rounded-lg  ${
            page === currentPage ? 'bg-green-emerald-light text-white' : 'text-gray-300'
          }`}>
          {page}
        </div>
      );
    }

    return originalElement;
  };

  return (
    <Modal
      title={
        <Title level={3} className="m-0 p-0">
          Pilih foto
        </Title>
      }
      open={open}
      onOk={handleOk}
      width={600}
      confirmLoading={confirmLoading}
      footer={null}
      centered
      onCancel={handleCancel}>
      <div className="flex flex-col gap-4">
        <Text className="text-lg font-semibold text-gray-400">Foto tersimpan</Text>
        <div className="grid w-full grid-cols-2 items-center justify-items-end gap-4">
          {dataKYC?.map((item: DocumentInterface, index: number) => {
            return (
              <div className="relative" key={index}>
                <a href={item?.documentUrl} target="_blank" rel="noreferrer">
                  <ExpandAltOutlined className="absolute right-4 top-4 cursor-pointer text-white transition-[transform] duration-100 hover:scale-150" />
                </a>
                <img
                  alt="kyc-img"
                  width={270}
                  key={item?.documentId}
                  src={item?.documentUrl}
                  className={`cursor-pointer rounded-3xl ${
                    item?.documentId === choose?.documentId &&
                    'border-2 border-solid border-green-emerald-light'
                  }`}
                  onClick={() => {
                    setChoose(item);
                  }}
                />
                <p className="m-0 p-0 text-grey-100">{dayjs(item?.createdAt).format('HH:mm')}</p>
              </div>
            );
          })}
        </div>
        <Pagination
          defaultPageSize={pageSizeKYC}
          className="self-center"
          total={documents.length}
          showSizeChanger={false}
          onChange={onChange}
          current={currentPage}
          defaultCurrent={1}
          itemRender={itemRender}
        />
      </div>
      <div className="mt-4 flex w-full flex-row justify-between gap-4">
        <Button
          onClick={async () => {
            if (choose?.documentUrl) {
              const file = await getFileFromUrl(choose?.documentUrl, 'ktp.png');
              setFileKTP(file);
              setChooseKTP(choose);
            }
            handleOk();
            setIsNewAttachment(false);
          }}
          type="primary"
          shape="default"
          htmlType="button"
          className="manual-kyc-btn w-1/2 self-center rounded-full border-black hover:text-white">
          <p className={`manual-kyc-p text-base font-semibold`}>Pilih sebagai KTP</p>
        </Button>
        <Button
          onClick={async () => {
            if (choose?.documentUrl) {
              const file = await getFileFromUrl(choose?.documentUrl, 'selfie.png');
              setFileSelfie(file);
              setChooseSelfie(choose!);
            }
            handleOk();
            setIsNewAttachment(false);
          }}
          type="primary"
          shape="default"
          htmlType="button"
          className="manual-kyc-btn w-1/2 self-center rounded-full border-black hover:text-white">
          <p className={`manual-kyc-p text-base font-semibold`}>Pilih sebagai Selfie</p>
        </Button>
      </div>
    </Modal>
  );
}

export default GalleryModal;
