import { Form, Input } from 'antd';
import { TextAreaWithLabelInterface } from './text-area-with-label.interface';

const { TextArea } = Input;

function TextAreaWithLabel({
  id,
  label,
  placeholder,
  charLimit,
  isShowCount
}: TextAreaWithLabelInterface) {
  const characterLimit = charLimit || 255;

  return (
    <Form.Item label={label}>
      <Form.Item name={id} noStyle rules={[{ max: characterLimit }]}>
        <TextArea
          autoSize
          placeholder={placeholder}
          showCount={isShowCount}
          maxLength={characterLimit}
        />
      </Form.Item>
    </Form.Item>
  );
}

export default TextAreaWithLabel;
