import { useEffect, useState } from 'react';
import { Col, Row, Form, Input, Button, Select, Spin, Alert } from 'antd';
import { CloseOutlined, InfoCircleOutlined } from '@ant-design/icons';

import AccountInput from '../input/account-input';

import { createUser, setUserPassword, onBoardAgent } from '../../../api';
import { validateNumber } from '../../../utils/number';
import { RoleEnum } from '../../../static/role';
import { useTeamStore } from '../../../store/team-state';
import { FormActionEnum } from '../../../static/team';
import { maxChatSelection, selectItem } from '../../../static/dashboard';

function UserForm() {
  const [form] = Form.useForm();
  const {
    formAction,
    selectedBU,
    selectedInbox,
    selectedUser,
    setIsOpenForm,
    fetchSupervisorItemList,
    resetTeamActiveHandler
  } = useTeamStore();
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedRole, setSelectedRole] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [roleSelection, setRoleSelection] = useState<selectItem[]>([
    { label: 'Admin', value: RoleEnum.ADMIN },
    { label: 'Supervisor', value: RoleEnum.SUPERVISOR },
    { label: 'Agen', value: RoleEnum.AGENT }
  ]);

  const validatePhoneNumber = (e: any) => {
    const value = e.target.value;
    let validatedPhone = validateNumber(value);
    if (validatedPhone.length > 13) {
      validatedPhone = validatedPhone.slice(0, 13);
    }
    form.setFieldsValue({ phoneNumber: validatedPhone });
  };

  const disableFormSubmit = () => {
    const formValues = form.getFieldsValue();
    const validationItems = [
      'firstName',
      'lastName',
      'phoneNumber',
      'email',
      'employeeId',
      'manager',
      'role',
      'businessUnit'
    ];

    if (formAction === FormActionEnum.CREATE) validationItems.push('password');
    if (selectedRole === RoleEnum.AGENT) validationItems.push('maxChatCap');

    const NoEmptyInput = validationItems.some(
      (item) => formValues[item] === '' || formValues[item] === undefined
    );
    const validEmail = form.getFieldError('email').length > 0;
    return NoEmptyInput || validEmail;
  };

  const resetInboxForm = () => {
    form.resetFields();
    setIsOpenForm(false);
    resetTeamActiveHandler();
  };

  const submitAccount = async () => {
    setLoading(true);
    setErrorMessage('');
    try {
      const userResponse = await createUser({
        firstName: form.getFieldValue('firstName'),
        lastName: form.getFieldValue('lastName'),
        email: form.getFieldValue('email'),
        phone: form.getFieldValue('phoneNumber'),
        employeeId: form.getFieldValue('employeeId'),
        accountId: selectedBU.accountId,
        role: form.getFieldValue('role'),
        manager: form.getFieldValue('manager')
      });
      await setUserPassword({
        username: form.getFieldValue('email'),
        password: form.getFieldValue('password')
      });
      if (form.getFieldValue('role') === RoleEnum.AGENT) {
        await onBoardAgent({
          accountId: selectedBU.accountId,
          agentId: userResponse.agentId,
          maxChat: form.getFieldValue('maxChatCap')
        });
      } else if (form.getFieldValue('role') === RoleEnum.SUPERVISOR)
        fetchSupervisorItemList(selectedBU.accountId);
      resetInboxForm();
    } catch (error: any) {
      setErrorMessage('Terjadi kesalahan, silahkan coba lagi nanti');
      if (error.response) {
        switch (error.response?.data?.status) {
          case 409:
            setErrorMessage('User sudah terdaftar');
            break;
          default:
            break;
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const closeForm = () => {
    form.resetFields();
    setIsOpenForm(false);
    resetTeamActiveHandler();
  };

  useEffect(() => {
    setLoading(true);
    form.resetFields();
    form.setFieldsValue({
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: '',
      employeeId: '',
      manager: '',
      password: '',
      businessUnit: selectedBU.name
    });
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formAction, selectedUser, selectedInbox, selectedBU]);

  useEffect(() => {
    const currUser = JSON.parse(localStorage.getItem('user') || '{}');
    if (currUser.role === RoleEnum.SUPERVISOR) {
      setRoleSelection([{ label: 'Agen', value: RoleEnum.AGENT }]);
    }
  }, []);

  return (
    <>
      <Spin spinning={loading}>
        <Row id="user_form" className="rounded-xl bg-white p-6 pb-0">
          <Col span={24}>
            <Row justify="space-between" className="mb-4">
              <div className="text-2xl font-semibold">{`${
                formAction === FormActionEnum.CREATE ? 'Tambah' : 'Edit'
              } User`}</div>
              <CloseOutlined className="cursor-pointer" onClick={() => closeForm()} />
            </Row>
            <Form
              name="basic"
              layout="vertical"
              form={form}
              autoComplete="off"
              requiredMark={false}
              size="large">
              <Row>
                <Col span={11}>
                  <Form.Item
                    name="firstName"
                    required
                    className="user_form-first_name mb-4"
                    label={<div className="text-base font-semibold">Nama Depan</div>}>
                    <Input placeholder="Nama Depan" autoComplete="new-name" />
                  </Form.Item>
                </Col>
                <Col span={11} offset={2}>
                  <Form.Item
                    name="lastName"
                    required
                    className="user_form-last_name mb-4"
                    label={<div className="text-base font-semibold">Nama Belakang</div>}>
                    <Input placeholder="Nama Belakang" autoComplete="new-name" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="role"
                    required
                    className="user_form-role mb-4"
                    label={<div className="text-base font-semibold">Jabatan</div>}>
                    <Select
                      placeholder="Pilih Jabatan"
                      onChange={setSelectedRole}
                      options={roleSelection}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="phoneNumber"
                    required
                    className="user_form-phone_number mb-4"
                    label={<div className="text-base font-semibold">Nomor Telepon</div>}>
                    <Input
                      placeholder="Masukkan nomor telepon"
                      onChange={validatePhoneNumber}
                      autoComplete="new-phone"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="email"
                    required
                    className="user_form-email mb-4"
                    label={<div className="text-base font-semibold">Email</div>}
                    rules={[{ type: 'email', message: 'Format email tidak sesuai!' }]}>
                    <Input placeholder="Masukkan Email" autoComplete="new-email" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={11}>
                  <Form.Item
                    name="employeeId"
                    required
                    className="user_form-employee_id mb-4"
                    label={<div className="text-base font-semibold">ID Pegawai</div>}>
                    <Input placeholder="ID Pegawai" autoComplete="new-id" />
                  </Form.Item>
                </Col>
                <Col span={11} offset={2}>
                  <Form.Item
                    name="manager"
                    required
                    className="user_form-manager mb-4"
                    label={<div className="text-base font-semibold">Manager</div>}>
                    <Input placeholder="Manager" autoComplete="new-manager" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={11}>
                  <AccountInput disabled={true} type="view" />
                </Col>
                {selectedRole === RoleEnum.AGENT && (
                  <Col span={11} offset={2}>
                    <Form.Item
                      name="maxChatCap"
                      required
                      className="user_form-max_chat_capacity mb-4"
                      label={<div className="text-base font-semibold">Maksimal Chat</div>}>
                      <Select
                        placeholder="5 Chat"
                        onChange={(value) => form.setFieldsValue({ maxChatCap: value })}
                        options={maxChatSelection}
                      />
                    </Form.Item>
                  </Col>
                )}
              </Row>
              {formAction === FormActionEnum.CREATE && (
                <Row>
                  <Col span={24}>
                    <Form.Item
                      name="password"
                      required
                      className="user_form-password mb-4"
                      label={<div className="text-base font-semibold">Password</div>}>
                      <Input.Password placeholder="Masukkan Password" autoComplete="new-password" />
                    </Form.Item>
                  </Col>
                </Row>
              )}
              <Row className="mb-4">
                <Col span={24}>
                  <InfoCircleOutlined />
                  &nbsp; Maksimal 1 panggilan video
                </Col>
              </Row>
              {errorMessage !== '' && (
                <Row className="mb-4">
                  <Col span={24}>
                    <Alert
                      className="error-alert text-base font-semibold capitalize"
                      message={errorMessage}
                      type="error"
                      showIcon
                      closable
                    />
                  </Col>
                </Row>
              )}
              <Row>
                <Col span={24}>
                  <Form.Item shouldUpdate>
                    {() => (
                      <Button
                        className={`inbox_form-submit rounded-3xl ${
                          disableFormSubmit()
                            ? 'bg-grey-50 text-grey-20'
                            : 'bg-green-emerald hover:bg-green-emerald-light active:bg-green-emerald'
                        }`}
                        disabled={disableFormSubmit()}
                        block
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                        onClick={submitAccount}>
                        <strong>
                          {formAction === FormActionEnum.CREATE ? '' : 'Ubah dan '} Simpan
                        </strong>
                      </Button>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Spin>
    </>
  );
}

export default UserForm;
