import { FC } from 'react';
import { Icon } from '@squantumengine/horizon';

type ArrowPaginationProps = {
  disablePrev: boolean;
  disableNext: boolean;
  onClickPrev: () => void;
  onClickNext: () => void;
};

export const ArrowPaginationV2: FC<ArrowPaginationProps> = (props) => {
  const { disableNext, disablePrev, onClickNext, onClickPrev } = props;

  return (
    <div className="flex gap-2">
      <span data-testid="arrow-left" className="cursor-pointer" onClick={onClickPrev}>
        <Icon name="arrow-left" disabled={disablePrev} size="sm" />
      </span>

      <span data-testid="arrow-right" className="cursor-pointer" onClick={onClickNext}>
        <Icon name="arrow-right" disabled={disableNext} size="sm" />
      </span>
    </div>
  );
};

export default ArrowPaginationV2;
