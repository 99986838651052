import { conversationLimit } from '../../static/search-filter';
import axiosInstancev2 from '../axios/axiosInstanceV2';
export const conversationByAgent = async (
  active: boolean,
  pageNo: number = 1,
  inboxId?: string,
  email?: string,
  ticketNumber?: string,
  phoneNumber?: string,
  name?: string,
  limit: number = conversationLimit
) => {
  const accountId = JSON.parse(localStorage.user || '{}')?.account?.accountId;
  let queryParams = new URLSearchParams({
    active: active.toString(),
    'page-no': pageNo.toString()
  });
  if (inboxId) {
    queryParams.append('inboxId', inboxId);
  }
  if (ticketNumber) {
    queryParams.append('conversationId', ticketNumber);
  }
  if (email) {
    queryParams.append('email', email);
  }
  if (phoneNumber) {
    queryParams.append('phone', phoneNumber);
  }
  if (limit) {
    queryParams.append('size', limit.toString());
  }
  if (name) {
    queryParams.append('name', name);
  }
  try {
    const response = await axiosInstancev2.get(`/account/${accountId}/conversation?${queryParams}`);
    return response.data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return undefined;
  }
};

export const getActiveConversationByAgent = (pageNo?: number) => conversationByAgent(true, pageNo);
export const getInactiveConversationByAgent = (pageNo?: number) =>
  conversationByAgent(false, pageNo);
