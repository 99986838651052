export async function getFileFromUrl(url: string, name: string) {
  try {
    const response = await fetch(url);
    const data = await response.blob();
    return new File([data], name, { type: data.type });
  } catch (error) {
    return null;
  }
}

export async function downloadFromBlob(blob: Blob, filename: string) {
  const a = document.createElement('a');
  a.href = URL.createObjectURL(blob);
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(a.href);
}
