import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const ExclamationTriangleSVG = () => (
  <svg width="55" height="48" viewBox="0 0 55 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M24.2499 19.2187L24.9061 29.7187C24.9061 30.375 25.3749 30.75 26.0311 30.75H29.8749C30.5311 30.75 30.9999 30.2812 30.9999 29.7187L31.6561 19.2187C31.6561 18.5625 31.1874 18 30.5311 18H25.3749C24.7186 18 24.2499 18.5625 24.2499 19.2187ZM31.9374 36C31.9374 33.8437 30.1561 32.0625 27.9999 32.0625C25.7499 32.0625 24.0624 33.8437 24.0624 36C24.0624 38.25 25.7499 39.9375 27.9999 39.9375C30.1561 39.9375 31.9374 38.25 31.9374 36ZM31.8436 2.24999C30.1561 -0.656263 25.7499 -0.750013 24.0624 2.24999L1.56239 41.3437C-0.125114 44.25 2.03114 48 5.49989 48H50.4061C53.8749 48 56.0311 44.3437 54.3436 41.3437L31.8436 2.24999ZM5.96864 42.75L27.4374 5.43749C27.7186 5.06249 28.1874 5.06249 28.4686 5.43749L49.9374 42.75C50.2186 43.125 49.9374 43.5 49.4686 43.5H6.43739C5.96864 43.5 5.68739 43.125 5.96864 42.75Z"
      fill="currentColor"
    />
  </svg>
);

const ExclamationTriangleIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={ExclamationTriangleSVG} {...props} />
);

export default ExclamationTriangleIcon;
