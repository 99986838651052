import { DocumentInterface } from '../form/workspace/workspace.interface';
import { LegacyRef } from 'react';

export interface ModeTypeInterface {
  KTP: boolean;
  SELFIE: boolean;
}

export interface GalleryModalInterface {
  open: boolean;
  confirmLoading: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  documents: DocumentInterface[];
  setIsNewAttachment: (isNewAttachments: boolean) => void;
}

export interface ScreenshotModalInterface {
  open: boolean;
  confirmLoading: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  canvasRefP: LegacyRef<HTMLCanvasElement> | undefined;
  canvasRefL: LegacyRef<HTMLCanvasElement> | undefined;
  setFileKTP: (chooseKTP: File | null) => void;
  setFileSelfie: (chooseSefile: File | null) => void;
  chooseKTP: DocumentInterface | null;
  chooseSelfie: DocumentInterface | null;
  img: HTMLImageElement | null;
}

export interface ChoosItemInterface {
  type: string;
  onOpenSS?: () => void;
  onOpenGallery?: () => void;
  disabled?: boolean;
  label: string;
}

export interface ChooseResultInterface {
  data: DocumentInterface;
  name: string;
  isFinishKYC: boolean;
  setData: () => void;
}

export enum ChoosItemEnum {
  GALLERY = 'Gallery',
  SCREENSHOT = 'Screenshot'
}

export enum ChooseTypeEnum {
  KTP = 'KTP',
  SELFIE = 'Selfie'
}

export enum StatusKYCEnum {
  FAILED = 'FAILED',
  COMPLETED = 'COMPLETED',
  PENDING = 'PENDING'
}

export enum StatusKYCResultEnum {
  FAILED = 'Ditolak',
  APPROVED = 'Disetujui'
}
export const PhotoInstructionEnum = [
  { label: 'NORMAL', value: 'NORMAL' },
  { label: 'KTP', value: 'KTP' },
  { label: 'Selfie', value: 'SELFIE' },
  { label: 'KTP & Selfie', value: 'BOTH' }
];

export interface KYCVerdictResponse {
  email: string;
  fullName: string;
  ktpObjectName: string;
  mobile: string;
  refUserId: string;
  selfieObjectName: string;
  status: string;
  userId: string;
  reason: string;
  manualKyc: boolean;
}

export enum KYCPageEnum {
  KYC = 0,
  SUMMARY = 1,
  SUBMITTED = 2
}

export interface KYCAlertInterface {
  text: string;
  type: 'ERROR' | 'SUCCESS';
}

export interface ManualKYCInterface {
  setPage?: React.Dispatch<React.SetStateAction<number>>;
}

export interface KYCPageInterface {
  defaultPage?: number;
}

export interface ManualKYCFunnelInterface {
  queue: number; // status dropped and resolved
  inProgress: number; // resolved with or without verdict and reference id
  resolved: number; // resolved with verdict
  approved: number; // resolved with verdict and approved
  metadata: MetaDataKYFunnelInterface;
  averageWaitTime: number; // unix_time_in_second
  averageVerificationTime: number; // unix_time_in_second
}

export interface TopRejectReasonInterface {
  data: Array<{
    rejectReason: string;
    total: number;
  }>;
  totalConversation: number;
  metadata: {
    start: string;
    end: string;
    responseTime: number;
    timestamp: string;
  };
}
export interface MetaDataKYFunnelInterface {
  start: number;
  end: number;
  responseTime: number;
  timestamp: number;
}

export interface FunnelHover {
  type: 'avg-wait' | 'avg-verif' | 'approve';
  isShown: boolean;
  totalDrop: number;
}
