import { useParams } from 'react-router-dom';
import { useGetTopRejectReason } from '../../../api/hooks/kyc/useGetTopRejectReason';
import { rejectReasonColumns } from '../../../static/analysis';
import { TopRejectReasonInterface } from '../../../components/kyc/kyc.interface';
import Table from '../../../components/commons/Table';

type Props = { filterValues: Record<string, any> };

function AnalyticRejectReason({ filterValues }: Props) {
  const { inboxId } = useParams();
  const { data: rejectReasonData } = useGetTopRejectReason(inboxId!, filterValues);

  function processData(inputData: TopRejectReasonInterface) {
    const { data, totalConversation } = inputData || {};

    const processedData = data?.map((item) => ({
      ...item,
      totalConversation
    }));

    return {
      ...inputData,
      data: processedData
    };
  }

  const processedData = processData(rejectReasonData!);

  return (
    <div className="no-scrollbar flex w-full flex-col justify-center gap-4 overflow-y-auto rounded-xl px-6 py-4">
      <h3 className="m-0 text-xl">Top 5 Alasan Penolakan</h3>

      <div className="h-60">
        <Table
          rowClassName="[&>td>.btn-show-customer]:hover:!block"
          pagination={false}
          columns={rejectReasonColumns}
          dataSource={processedData?.data || []}
        />
      </div>
    </div>
  );
}

export default AnalyticRejectReason;
