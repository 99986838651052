import { LoginPlatform } from '../shared/types/auth.interface';
import { useAuthStore } from '../store/new/auth';
import { AuthNativeProvider, useAuthNative } from './useAuthNative';
import useAuthProvider from './useAuthProvider';
import { AuthSIMASIDProvider, useAuthSIMASID } from './useAuthSimasID';

export const AuthProvider = ({ children }: { children: any }) => {
  useAuthProvider();
  const { authProvider } = useAuthStore();

  switch (authProvider) {
    case LoginPlatform.SIMAS_ID:
      return <AuthSIMASIDProvider>{children}</AuthSIMASIDProvider>;
    default:
      return <AuthNativeProvider>{children}</AuthNativeProvider>;
  }
};

export const useAuth = () => {
  const { authProvider } = useAuthStore();
  const authNative = useAuthNative();
  const authSIMASID = useAuthSIMASID();

  switch (authProvider) {
    case LoginPlatform.SIMAS_ID:
      return authSIMASID;
    default:
      return authNative;
  }
};
