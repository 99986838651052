import { getClientAuthProvider } from '../api/auth';
import useMount from './useMounth';

import { local } from '../utils/storage';
import { getUrlParam } from '../utils/url-search-params';
import { useAuthStore } from '../store/new/auth';
import { LocalStorageKey } from '../static/local-storage';
import { LoginPlatform } from '../shared/types/auth.interface';
import { useNavigate } from 'react-router-dom';

const useAuthProvider = () => {
  const { setAuthProvider, isSQEIDLoggedIn, isNativeLoggedIn } = useAuthStore();
  const navigate = useNavigate();

  const clientCode = getUrlParam('client');

  const isSQEIDRoutes = () => {
    const route = window.location.href;
    if (route.includes('sso-callback')) return true;
    return false;
  };

  const isNativeRoutes = () => {
    const route = window.location.href;
    if (route.includes('reset?uuid')) return true;
    return false;
  };

  const checkClientProvider = async () => {
    if (!clientCode) return;

    local.setItem(LocalStorageKey.SQECC_CLIENT_CODE, clientCode);

    const res = await getClientAuthProvider(clientCode);

    if (typeof res !== 'object') return;

    if ('error' in res) {
      if (res.status === 404) navigate('/wrong-url', { replace: true });
      return;
    }

    setAuthProvider(res.authenticationProvider);
  };

  useMount(() => {
    if (isSQEIDRoutes() || isSQEIDLoggedIn()) {
      setAuthProvider(LoginPlatform.SIMAS_ID);
      return;
    }

    if (isNativeRoutes() || isNativeLoggedIn()) {
      setAuthProvider(LoginPlatform.NATIVE);
      return;
    }

    setAuthProvider(LoginPlatform.LOADING);
    checkClientProvider();
  });
};

export default useAuthProvider;
