import {
  Flex,
  Typography,
  Button,
  Modal,
  Form,
  Row,
  Col,
  Input,
  Select,
  message,
  Radio,
  RadioChangeEvent,
  Space
} from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { TeamUserModalInterface, roleOptions } from './team-users.interface';
import { useEffect, useState } from 'react';
import {
  useAddInboxMemberSQEID,
  useDeleteUsrSQEID,
  useGetInboxes,
  useRemoveInboxMemberSQEID,
  useUpdateUsrProfileSQEID
} from '../../api/hooks/sqeid/useSQEID';
import { RoleEnum } from '../../static/role';
import { InboxListItemInterface } from '../../shared/types/inbox.interface';
import { useUpdateAgentCapacity } from '../../api/hooks/native/useUpdateAgentCap';

const { Title } = Typography;

const TeamUsersModal = ({ data, setIsModalOpen, isModalOpen, form }: TeamUserModalInterface) => {
  const { mutateAsync: addInboxMember } = useAddInboxMemberSQEID();
  const { mutateAsync: removeInboxMember } = useRemoveInboxMemberSQEID();
  const { data: inboxes } = useGetInboxes(data?.account?.accountId);

  const [inboxOptions, setInboxOptions] = useState<Array<any>>([]);
  const [notification, contextHolder] = message.useMessage();
  const { mutateAsync: updateProfile, isLoading } = useUpdateUsrProfileSQEID(notification);
  const { mutateAsync: delUserSQEID } = useDeleteUsrSQEID(notification);
  const { mutateAsync: updateAgentCapacity } = useUpdateAgentCapacity();

  const transformInbox = (data: InboxListItemInterface[]) => {
    return data?.map(
      ({ name: label, inboxId: value, ...rest }: { name: string; inboxId: string }) => ({
        label,
        value,
        ...rest
      })
    );
  };

  const [value, setValue] = useState<number>(1);

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    const agentNameMap: any = {
      [RoleEnum.AGENT]: 'Agen',
      [RoleEnum.SUPERVISOR]: 'Supervisor',
      [RoleEnum.ADMIN]: 'Admin'
    };

    const inboxOptions = transformInbox(inboxes?.inboxList!);
    const currentInbox = transformInbox(data?.inboxes);

    form.setFieldsValue({
      name: `${data?.firstName} ${data?.lastName ? data?.lastName : ''}`,
      phone: data?.phoneNo,
      email: data?.email,
      employee_id: data?.employeeId,
      role: agentNameMap[data?.role],
      assign_team: currentInbox,
      max_video: data?.capacity?.maxCallCapacity,
      max_chat: data?.capacity?.maxChatCapacity
    });

    setInboxOptions(inboxOptions);
  }, [data, inboxes, isModalOpen]);

  const onFinish = async () => {
    try {
      await form.validateFields();
      const isProfileCompleted = Object.keys(data.capacity).length !== 0;

      let payload = {
        agentId: data?.agentId,
        firstName: data?.firstName,
        lastName: data?.lastName,
        email: form.getFieldValue('email'),
        phonNumber: form.getFieldValue('phone'),
        role: data?.role,
        employeeId: form.getFieldValue('employee_id'),
        status: 'ACTIVE',
        accountId: data?.account?.accountId
      } as any;

      if (data?.role === RoleEnum.AGENT) {
        await updateAgentCapacity({
          accountId: data?.account?.accountId,
          agentId: data?.agentId,
          agentCapacity: {
            maxChatCustomers: form.getFieldValue('max_chat'),
            maxCallCustomers: form.getFieldValue('max_video')
          },
          isProfileCompleted
        });
      }

      await updateProfile(payload).catch((err) =>
        // eslint-disable-next-line no-console
        console.error(err)
      );
    } catch (error) {
      notification.error({ content: 'Mohon isi semua kolom' });
    }
  };

  const handleSelect = async (value: string) => {
    await addInboxMember({
      accountId: data?.account?.accountId,
      agentId: data?.agentId,
      inboxId: value
    }).catch((err) =>
      // eslint-disable-next-line no-console
      console.error(err)
    );
  };

  const handleDeSelect = async (value: string) => {
    await removeInboxMember({
      accountId: data?.account?.accountId,
      agentId: data?.agentId,
      inboxId: value
    }).catch((err) =>
      // eslint-disable-next-line no-console
      console.error(err)
    );
  };

  const handleDelUsrSQEID = () => {
    setIsModalOpen(false);
    delUserSQEID(data?.email).catch((err) =>
      // eslint-disable-next-line no-console
      console.error(err)
    );
  };

  const filterOption = (input: string, option?: { label: string; value: string }) => {
    return (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  };

  return (
    <>
      {contextHolder}
      <Modal
        width={1000}
        footer={null}
        title={
          <Flex justify="space-between" align="center">
            <Title className="m-0 p-0" level={3}>
              {data?.firstName}
            </Title>
            <Flex gap="middle" align="center">
              <Button
                onClick={handleDelUsrSQEID}
                className={`h-10 w-32 rounded-lg border-2 border-solid border-red-300 text-base font-semibold text-red-300`}>
                Hapus User
              </Button>
              <Button
                loading={isLoading}
                onClick={onFinish}
                className={`h-10 w-32 rounded-lg border-none bg-green-500 text-base font-semibold text-white`}>
                Simpan
              </Button>
              <CloseOutlined data-testid="btn-close-modal" onClick={() => setIsModalOpen(false)} />
            </Flex>
          </Flex>
        }
        closable={false}
        open={isModalOpen}
        onOk={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}>
        <Form
          className="mt-10"
          name="basic"
          layout="vertical"
          form={form}
          autoComplete="off"
          size="large">
          <Row gutter={16}>
            <Col className="gutter-row" span={8}>
              <Form.Item
                name="name"
                required
                label={<Title className="m-0 text-base font-semibold">Nama Lengkap</Title>}>
                <Input disabled placeholder="Firman Meowji" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item
                name="email"
                required
                label={<Title className="m-0 text-base font-semibold">Email</Title>}>
                <Input disabled placeholder="socakromiafif@gmail.com" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item
                name="phone"
                required
                label={<Title className="m-0 text-base font-semibold">No Telepon</Title>}>
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col className="gutter-row" span={8}>
              <Form.Item
                name="role"
                required
                label={<Title className="m-0 text-base font-semibold">Jabatan</Title>}>
                <Select disabled defaultValue="Agen" options={roleOptions} />
              </Form.Item>
            </Col>
            {data?.role === RoleEnum.AGENT && (
              <>
                <Col className="gutter-row" span={8}>
                  <Form.Item
                    name="max_chat"
                    rules={[{ required: true, message: 'Please input Max Chat' }]}
                    label={<Title className="m-0 text-base font-semibold">Maksimal Chat</Title>}>
                    <Input type="number" />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={8}>
                  <Form.Item
                    name="assign_team"
                    rules={[{ required: true, message: 'Please assign agent to the Team' }]}
                    label={<Title className="m-0 text-base font-semibold">Tugaskan Ke Tim</Title>}>
                    <Select
                      filterOption={filterOption}
                      showSearch
                      onDeselect={handleDeSelect}
                      onSelect={handleSelect}
                      mode="multiple"
                      options={inboxOptions}
                    />
                  </Form.Item>
                </Col>
              </>
            )}
            <Col className="gutter-row" span={8}>
              <Form.Item
                name="employee_id"
                rules={[{ required: true, message: 'Please input ID Pegawai' }]}
                className="user_form-employee_id"
                label={<div className="text-base font-semibold">ID Pegawai</div>}>
                <Input
                  disabled={!!data?.employeeId}
                  placeholder="ID Pegawai"
                  type="number"
                  autoComplete="new-id"
                />
              </Form.Item>
            </Col>
            {data?.role === RoleEnum.AGENT && (
              <Col className="gutter-row" span={8}>
                <Form.Item
                  name="max_video"
                  rules={[{ required: true, message: 'Please select a call setting!' }]}
                  label={
                    <Title className="m-0 text-base font-semibold">Pengaturan Panggilan</Title>
                  }>
                  <Radio.Group value={value} onChange={onChange}>
                    <Space direction="vertical">
                      <Radio value={1}>Bisa menerima panggilan</Radio>
                      <Radio value={0}>Tidak bisa menerima panggilan</Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </Col>
            )}
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default TeamUsersModal;
