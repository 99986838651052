import { Typography } from 'antd';

interface UserInfoInterface {
  label: string;
  value: string;
}

function UserInfo(props: UserInfoInterface) {
  const { Text, Title } = Typography;
  const { label, value } = props;

  return (
    <div className="my-4 flex flex-col">
      <Title level={5} className="m-0 p-0">
        {label}
      </Title>
      <Text className="text-md">{value}</Text>
    </div>
  );
}

export default UserInfo;
