import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { Col, Divider, Form, FormInstance, Modal, Row, Select, Typography, message } from 'antd';
import { useState } from 'react';
import { useTeamStore } from '../../../store/team-state';
import usePrevious from '../../../hooks/usePrevious';
import {
  useCreateReason,
  useDeleteReason,
  useGetReason
} from '../../../api/hooks/inbox/useRejectReason';

function RejectReason(props: { form?: FormInstance<any> }) {
  const { form } = props;
  const { Text, Title } = Typography;
  const [, contextHolderMessage] = message.useMessage();

  const [selectedRejectReason, setSelectedRejectReason] = useState<{
    uuid: string;
    reason: string;
  } | null>();
  const [search, setSearch] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const { selectedInbox } = useTeamStore();
  const prevSearch = usePrevious(search);

  const { mutate: addReason } = useCreateReason();
  const { mutate: deleteReason } = useDeleteReason();
  const { data: dataReasons } = useGetReason(selectedInbox?.inboxId);

  const handleOk = async () => {
    setOpen(false);
    deleteReason({ inboxId: selectedInbox?.inboxId, reasonId: selectedRejectReason?.uuid });
    form!.resetFields(['reject-reason']);
  };

  const handleCancel = () => {
    setOpen(false);
    form!.resetFields(['reject-reason']);
    setSelectedRejectReason(null);
  };

  const handleSearch = (value: string) => setSearch(value.substring(0, 255));

  const createReason = () => {
    const isExist = dataReasons!?.some((item) => item.reason.includes(search));
    if (prevSearch !== search && !isExist) {
      addReason({ inboxId: selectedInbox?.inboxId, reason: search });
    }
  };

  return (
    <>
      {contextHolderMessage}
      <Modal
        title={
          <Title level={4} className="m-0 p-0">
            Hapus Alasan
          </Title>
        }
        open={open}
        onOk={() => setOpen(false)}
        width={300}
        footer={null}
        centered
        onCancel={handleCancel}>
        <Text className="text-md">Yakin ingin menghapus alasan dari daftar?</Text>
        <button
          onClick={handleOk}
          className={`my-3 flex w-full cursor-pointer flex-row items-center justify-center rounded-full border-none bg-orange-danger py-3 text-white transition-[transform] duration-100 hover:scale-105 active:scale-90`}>
          Hapus Alasan
        </button>
        <button
          onClick={handleCancel}
          className={`my-3 flex w-full cursor-pointer flex-row items-center justify-center rounded-full  border-2 bg-white-background  py-3 text-black transition-[transform] duration-100 hover:scale-105 active:scale-90`}>
          Batalkan
        </button>
      </Modal>
      <Form.Item
        name="reject-reason"
        required
        className="inbox_topic mt-4 w-full"
        label={<Text className="text-base font-semibold">Alasan Penolakan KYC</Text>}>
        <Select
          showSearch
          allowClear
          onSearch={handleSearch}
          onClear={() => setSelectedRejectReason(null)}
          onSelect={(_, option) => {
            setSelectedRejectReason({
              uuid: option!?.key,
              reason: option!?.value as string
            });
          }}
          dropdownRender={(menu) => (
            <div className="p-2">
              <Row align="middle" justify="space-between">
                <Col span={18}>
                  <Text className="text-base font-semibold">List Alasan</Text>
                </Col>
                <Text
                  onClick={createReason}
                  className="cursor-pointer text-base font-bold text-emerald-light-green">
                  Tambah
                </Text>
              </Row>
              <Divider className="my-2" />
              {menu}
            </div>
          )}
          placeholder={
            <>
              <SearchOutlined />
              &nbsp; Cari Alasan
            </>
          }>
          {dataReasons?.map((option) => {
            return (
              <Select.Option key={option!?.uuid} value={option?.reason}>
                <Row align="middle" justify="space-between">
                  <Col span={20}>
                    <p className="m-0 overflow-hidden text-ellipsis">{option!?.reason}</p>
                  </Col>
                  {selectedRejectReason?.uuid !== option?.uuid && (
                    <CloseOutlined className=" hover:scale-100" onClick={() => setOpen(true)} />
                  )}
                </Row>
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
    </>
  );
}

export default RejectReason;
