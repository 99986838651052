import { useEffect, useState } from 'react';
import { Form, Input, Select } from 'antd';

import { fetchAccountList } from '../../../../api/account';

import { CurrentUserObjectInterface } from '../../../../shared/types/user.interface';
import { RoleEnum } from '../../../../static/role';

interface selectItem {
  label: string;
  value: string;
}

function AccountInput({ disabled, type }: { disabled: boolean; type?: 'view' | string }) {
  const [accountList, setAccountList] = useState<selectItem[]>([]);
  const [currentUser] = useState<CurrentUserObjectInterface>(
    JSON.parse(localStorage.getItem('user') || '{}')
  );

  const getAccountList = async () => {
    const response = await fetchAccountList();
    const accountList = response.data.map((account: any) => ({
      label: account.name,
      value: account.accountId
    }));
    setAccountList(accountList);
  };

  useEffect(() => {
    if (currentUser.role === RoleEnum.ADMIN && type !== 'view') getAccountList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Form.Item
        label={<div className="text-base font-semibold">Unit Bisnis</div>}
        name="businessUnit"
        required
        initialValue={
          currentUser?.role === RoleEnum.ADMIN ? null : currentUser?.account?.accountName
        }
        rules={[{ required: true, message: 'Tolong pilih unit bisnis!' }]}>
        {currentUser?.role === RoleEnum.SUPERVISOR || type === 'view' ? (
          <Input disabled />
        ) : (
          <Select
            showSearch
            optionFilterProp="label"
            placeholder="Pilih Unit Bisnis"
            options={accountList}
            disabled={disabled}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
          />
        )}
      </Form.Item>
    </>
  );
}

export default AccountInput;
