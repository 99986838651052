import CopyIcon from '../../assets/icons/copy';
import { CopyInterface } from './copy.interface';
import { message } from 'antd';

function Copy({ className, value, children }: CopyInterface) {
  const [messageApi, contextHolder] = message.useMessage();

  const copyToClipboard = (e: any) => {
    e.stopPropagation();
    navigator.clipboard.writeText(value);
    messageApi.open({
      type: 'success',
      content: 'Copied to your clipboard.'
    });
  };

  return (
    <div className="cursor-pointer" onClick={copyToClipboard}>
      {contextHolder}
      {children}
      <div className={`${className && 'ml-2'} inline-block opacity-50`}>
        <CopyIcon />
      </div>
    </div>
  );
}

export default Copy;
