import { AxiosError } from 'axios';
import { isDatadogLogEnabled, sendLogCommonError } from '../config/datadog';
import { Error } from '../shared/types/error-handling.interface';
import { NativeLoginErrorMsgEnum } from '../static/native-login';

/**
 * Function to help error handling
 *
 * @param {string} callback - Callback function.
 * @returns Returns anything that callback function return.
 * @returns {Error} Returns error.
 */
export const withErrorHandling = <T>(callback: () => T): T | Error => {
  try {
    return callback();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('>>>  ~ error:', error);
    datadogLogError(error as SyntaxError);

    return {
      error: true,
      message: getErrorMsg(error as SyntaxError),
      status: getErrorStatus(error as SyntaxError),
    };
  }
};

/**
 * Function to help error handling (Async)
 *
 * @param {Function} callback - Callback function.
 * @returns {any} Returns anything that callback function return.
 */
export const withErrorHandlingAsync = async <T>(callback: () => Promise<T>): Promise<T | Error> => {
  try {
    return await callback();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('>>>  ~ error:', error);

    datadogLogError(error as SyntaxError);

    return {
      error: true,
      message: getErrorMsg(error as SyntaxError),
      status: getErrorStatus(error as SyntaxError),
      data: (error as AxiosError).response?.data || {}
    };
  }
};

const datadogLogError = (error: SyntaxError) => {
  const errorMsg = getErrorMsg(error);

  if (isDatadogLogEnabled) {
    sendLogCommonError(`[withErrorHandling] ${errorMsg}`, {
      message: errorMsg,
      data: error
    });
  }
};

export const getErrorMsg = (error: any) => {
  return error?.message || error?.response?.data?.message || NativeLoginErrorMsgEnum.general;
};

export const getErrorStatus = (error: any) => {
  return error?.status || error?.response?.data?.status || 0
};
