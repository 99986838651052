import axiosInstance from '../axios';

export function sendTypingStatus(typingOn: boolean, conversationId: string) {
  const accountId = JSON.parse(localStorage.getItem('user')!).account.accountId;
  try {
    const res = axiosInstance.post(`/account/${accountId}/conversation/${conversationId}/typing`, {
      typingOn
    });
    return res;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
}
