import { useEffect } from 'react';
import { useAllConversationInInboxQuery } from '../api/hooks/inbox/useAllConversationInInboxQuery';
import { parsedConversation } from '../pages/dashboard/dashboard.helper';
import { useConversationListStore } from '../store/conversation-list-state';
import { MessageEventEnum } from '../static/message-event';
import { RoleEnum } from '../static/role';
import { ConversationType } from '../store/conversation-list-state.interface';
import { useUserStatusStore } from '../store/user-state';
import useInboxId from './useInboxId';

type UseConversationProps = {
  page: number;
  limit: number;
  active: boolean;
  filter: {
    email: string;
    name: string;
    ticketNumber: string;
    phoneNumber: string;
    startDate: string;
    endDate: string;
  };
  shouldAppendConversation?: boolean;
};

const useConversation = ({
  filter,
  page,
  limit,
  active,
  shouldAppendConversation
}: UseConversationProps) => {
  const { userRole } = useUserStatusStore();
  const { email, endDate, name, phoneNumber, startDate, ticketNumber } = filter;
  const { setConversationList, shouldRefetchConv, setShouldRefetchConv, appendConversationList } =
    useConversationListStore();
  const refetchInterval = 0; //10seconds

  const inboxId = useInboxId();

  const { data, refetch, isFetching } = useAllConversationInInboxQuery(
    inboxId,
    active,
    page,
    refetchInterval,
    email,
    name,
    ticketNumber,
    phoneNumber,
    startDate,
    endDate,
    limit
  );

  const activeCallListArr = parsedConversation(data?.data, inboxId, true);
  const inActiveCallListArr = parsedConversation(data?.data, inboxId, false);
  const messageTypeByRole =
    ({
      [RoleEnum.AGENT]: MessageEventEnum.ACTIVE,
      [RoleEnum.SUPERVISOR]: MessageEventEnum.ASSIGNED,
      [RoleEnum.ADMIN]: MessageEventEnum.ASSIGNED
    }[userRole!] as ConversationType) || MessageEventEnum.ASSIGNED;

  useEffect(() => {
    if (shouldAppendConversation && page > 1) {
      if (active) {
        appendConversationList(messageTypeByRole, activeCallListArr.assignedConversationList);
        appendConversationList(
          MessageEventEnum.CREATED,
          activeCallListArr.createdConversationListArr
        );
      } else {
        appendConversationList(MessageEventEnum.INACTIVE, inActiveCallListArr.inactiveList);
      }
      return;
    }

    if (active) {
      setConversationList(messageTypeByRole, activeCallListArr.assignedConversationList);
      setConversationList(MessageEventEnum.CREATED, activeCallListArr.createdConversationListArr);
    } else {
      setConversationList(MessageEventEnum.INACTIVE, inActiveCallListArr.inactiveList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (shouldRefetchConv) {
      refetch();
      setShouldRefetchConv(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRefetchConv]);

  return {
    refetch,
    data,
    incomingConversation: activeCallListArr.createdConversationListArr,
    ongoingConversation: activeCallListArr.assignedConversationList,
    isFetching
  };
};

export default useConversation;
