import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const VideoSVG = () => (
  <svg width="1em" height="1em" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.6406 0.833333C14.4844 0.833333 14.3281 0.885417 14.1719 0.989583L11.3333 2.78646V1.25C11.3333 0.572917 10.7083 0 9.97917 0H1.82812C1.09896 0 0.5 0.572917 0.5 1.25V8.77604C0.5 9.45312 1.09896 10 1.82812 10H9.97917C10.7083 10 11.3333 9.45312 11.3333 8.77604V7.23958L14.1719 9.03646C14.3281 9.14062 14.4844 9.16667 14.6406 9.16667C15.0833 9.16667 15.5 8.82812 15.5 8.35938V1.66667C15.5 1.17188 15.0833 0.833333 14.6406 0.833333ZM10.0833 3.54167V8.72396C10.0573 8.75 10.0312 8.75 9.97917 8.75H1.82812C1.77604 8.75 1.75 8.75 1.75 8.72396V1.30208C1.75 1.27604 1.77604 1.25 1.82812 1.25H9.97917C10.0312 1.25 10.0573 1.27604 10.0833 1.30208V3.54167ZM14.25 7.60417L11.3333 5.78125V4.24479L14.25 2.42188V7.60417Z"
      fill="currentColor"
    />
  </svg>
);

const VideoIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={VideoSVG} {...props} />
);

export default VideoIcon;
