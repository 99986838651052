import { useCoordinates } from '../../../../hooks/useCoordinates';
import { StatusKYCEnum } from '../../../../components/kyc/kyc.interface';
import { KYCActivityTooltipInterface } from '../../../../static/analysis';

const KYCActivityBarTooltip = ({ rejected, approved, total }: KYCActivityTooltipInterface) => {
  const coords = useCoordinates();

  const left = `${coords.x - 350}px`;
  const top = `${coords.y - 150}px`;

  const getPercentage = (status: StatusKYCEnum.COMPLETED | StatusKYCEnum.FAILED) => {
    const number = status === StatusKYCEnum.COMPLETED ? approved : rejected;
    return total === 0 ? 0 : Math.round((number / total) * 100);
  };

  return (
    <div
      style={{ left, top }}
      className="absolute z-50 flex w-[20rem] flex-col items-center justify-center gap-4 rounded-2xl bg-white px-8 py-4 shadow-lg">
      <div className="flex w-full flex-row items-center justify-between">
        <p className="m-0 p-0 text-lg font-semibold">Aktivitas KYC: Disetujui</p>
      </div>
      <div className="grid w-full grid-cols-2 gap-4">
        {renderContent(
          StatusKYCEnum.COMPLETED,
          approved,
          total,
          getPercentage(StatusKYCEnum.COMPLETED)
        )}
        {renderContent(StatusKYCEnum.FAILED, rejected, total, getPercentage(StatusKYCEnum.FAILED))}
      </div>
    </div>
  );
};

export default KYCActivityBarTooltip;

const renderContent = (
  type: StatusKYCEnum.COMPLETED | StatusKYCEnum.FAILED,
  count: number,
  total: number,
  percentage: number
) => {
  const renderText = type === StatusKYCEnum.COMPLETED ? 'Disetujui' : 'Ditolak';
  const renderColor = type === StatusKYCEnum.COMPLETED ? 'text-yellow-400' : 'text-red-400';

  return (
    <div className="w-full rounded-xl border border-solid border-gray-400 p-4">
      <p className="m-0 p-0 font-semibold text-gray-400">{renderText}</p>
      <p className="m-0 p-0">
        <span className={`mr-1 text-lg font-semibold ${renderColor}`}>{count}</span>
        &#8725;
        <span className={`ml-1 text-base font-semibold text-gray-400`}>{total}</span>
      </p>
      <hr />
      <p className="m-0 p-0 font-semibold text-gray-400">% {renderText}</p>
      <p className="m-0 p-0">
        <span className={`text-lg font-semibold ${renderColor}`}>{percentage}%</span>
      </p>
    </div>
  );
};
