import axiosInstance from '../axios';
export const postWidgetMessage = async (
  content: string,
  documents: any,
  contentType: string,
  conversationId: string
) => {
  const accountId = JSON.parse(localStorage.getItem('user')!)?.account?.accountId;

  try {
    const response = await axiosInstance.post(
      `/account/${accountId}/conversation/${conversationId}/message`,
      {
        content,
        documents,
        contentType
      }
    );
    return response;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};
