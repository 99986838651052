import React from 'react';
import { Tabs, Typography } from '@squantumengine/horizon';
import {
  AgentInterface,
  ConversationInterface
} from '../../../../card/chat-message/chat-message.interface';
import { CommunicationTypeEnum } from '../../../../../static/communication-type';
import { InboxMemberListWithCapacityInterface } from '../../../../../shared/types/inbox.interface';
import { Flex } from 'antd';
import AgentCardV2 from '../agent-card';

const { Paragraph } = Typography;

interface onAssignInterface {
  (
    conversationId: string,
    agent: AgentInterface,
    communicationType: CommunicationTypeEnum,
    isReassign?: boolean
  ): void;
}

interface Props {
  agents: InboxMemberListWithCapacityInterface[];
  conversation: ConversationInterface;
  isLoading: boolean;
  onAssign: onAssignInterface;
}

const tabItems = (
  filteredAgent: InboxMemberListWithCapacityInterface[],
  conversation: ConversationInterface,
  onAssign: onAssignInterface,
  isAssigned: boolean
) => [
  {
    children: (
      <Flex vertical gap={16} className="no-scrollbar max-h-[500px] w-full overflow-auto">
        {filteredAgent.map((agent: InboxMemberListWithCapacityInterface) => (
          <AgentCardV2
            key={agent.agentId}
            agent={agent}
            onAssign={() =>
              onAssign(
                conversation.conversationId,
                {
                  id: agent.agentId,
                  firstName: agent.firstName,
                  lastName: agent.lastName
                },
                conversation.communicationType as CommunicationTypeEnum,
                isAssigned
              )
            }
          />
        ))}
      </Flex>
    ),
    key: 'agent',
    label: 'Agen'
  }
  // FOR NOW HIDE TEAM TAB SINCE BE IS NOT READY YET
  // {
  //   children: (
  //     <Flex vertical gap={16} className="no-scrollbar max-h-[500px] w-full overflow-auto">
  //       {teams.map((team: TeamsInterface) => (
  //         <TeamCardV2 teams={team} />
  //       ))}
  //     </Flex>
  //   ),
  //   key: 'team',
  //   label: 'Tim'
  // }
];

const getAgentPriority = (agent: InboxMemberListWithCapacityInterface) => {
  const { capacity, available } = agent;
  const { maxChatCapacity, onGoingChatCapacity, maxCallCapacity, onGoingCallCapacity } =
    capacity || {};

  if (!available) return -2;
  if (maxCallCapacity && onGoingCallCapacity >= maxCallCapacity) return -1;
  if (!onGoingChatCapacity && maxChatCapacity) return Infinity;
  return maxChatCapacity - onGoingChatCapacity;
};

const AssignAgentModalContentV2: React.FC<Props> = ({
  agents,
  conversation,
  isLoading,
  onAssign
}) => {
  if (isLoading) return <Paragraph>Memuat...</Paragraph>;
  if (!agents?.length) return <Paragraph>Tidak ada agent yang tersedia.</Paragraph>;

  if (agents?.length === 1 && agents[0].agentId === conversation.agent?.id) {
    return (
      <Paragraph>
        Satu-satunya agent dalam Inbox ini telah di-assign ke dalam percakapan ini.
      </Paragraph>
    );
  }

  const isAssigned = !!conversation.agent;

  const sortedAgent = agents.sort((a, b) => getAgentPriority(b) - getAgentPriority(a));
  const filteredAgent = isAssigned
    ? sortedAgent.filter((agent) => conversation.agent?.id !== agent.agentId)
    : sortedAgent;

  return (
    <Flex justify="space-between" align="center" vertical className="w-full">
      <Tabs
        items={tabItems(filteredAgent, conversation, onAssign, isAssigned)}
        size="lg"
        className="w-full"
        centered
        tabItemClassName="w-full text-center"
      />
    </Flex>
  );
};

export default AssignAgentModalContentV2;
