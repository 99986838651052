import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useInboxStore } from '../store/new/inbox';

const useInboxId = () => {
  const { selectedInboxId } = useInboxStore();
  const { inboxId: inboxIdParam } = useParams();
  const inboxId = useMemo(() => inboxIdParam || selectedInboxId, [selectedInboxId, inboxIdParam]);

  return inboxId;
};

export default useInboxId;
