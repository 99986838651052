import { CloseOutlined } from '@ant-design/icons';
import { ChooseResultInterface } from './kyc.interface';
import { useChatListStore } from '../../store/chat-list';
import { ConversationStatusEnum } from '../../static/message-event';

function ChooseResult(item: ChooseResultInterface) {
  const { setData, data, name, isFinishKYC } = item;
  const { selectedCall } = useChatListStore();
  const isPrecloseOrResolved =
    selectedCall?.conversationStatus === ConversationStatusEnum.PRE_CLOSURE ||
    selectedCall?.conversationStatus === ConversationStatusEnum.RESOLVED;

  const onCancel = () => {
    setData();
  };

  return (
    <>
      {data && (
        <div className="flex w-full flex-col items-start gap-2">
          <p className={`manual-kyc-p text-lg font-semibold`}>Foto {name}</p>
          <div className="flex w-full flex-row items-center gap-4 rounded-xl bg-grey-20 pr-4 text-center">
            {!data?.documentUrl && isPrecloseOrResolved ? (
              <p className="ml-4"> No Image</p>
            ) : (
              <>
                <img
                  src={data?.documentUrl}
                  alt="choose-item"
                  className="h-20 w-auto rounded-l-xl object-cover"
                />
                <p className={`manual-kyc-p text-lg`}>{data?.documentName}</p>
                {!isFinishKYC && (
                  <CloseOutlined
                    className="ml-auto h-16 cursor-pointer text-black transition-[transform] duration-100 hover:scale-150"
                    onClick={onCancel}
                  />
                )}
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default ChooseResult;
