import { ConversationInterface } from '../types/conversation';
import { requestV2 } from '../utils/request';
import { buildQueryString } from '../utils/string';

export const getAccountConversationList = async (
  accountId: string,
  params: Record<string, any>
) => {
  const response = await requestV2.get<{
    page: number;
    size: number;
    data: ConversationInterface[];
  }>(`/account/${accountId}/conversation?${buildQueryString(params)}`);
  return response.data;
};
